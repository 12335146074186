import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'TRAFO3',
  objectType: EDeviceObjectType.Transformer3,
  Locked: false,
  deviceType: EDeviceType.TRAFO3,
  COUPLING_2_LIST: ['Star-grounded'],
  PHASES_2_LIST: ['LLLN', 'LLL'],
  DISTRIBUTION_SYSTEM_2_LIST: ['TN-S', 'TN-C', 'TT', 'IT'],
  Sr2: 50000,
  Sr3: 50000,
  uRr12: 2.05,
  uRr13: 2.05,
  ukr12: 6,
  ukr13: 6,
  TertiaryWindingUn: 400,
  TertiaryWindingLineType: ELvLineType.LLLN,
  TertiaryWindingEarthingSystem: EEarthingSystem.TNS,
  TertiaryWindingCouplingType: 'Star-grounded',
};
