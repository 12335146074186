// import {
//   changeBreakerObjectType,
// } from 'constants';
import Device from 'devices/device';
import { changeBreakerObjectType } from 'devices/utils';
import { manageFilters } from 'filters/manager';
import { ICircuitBreaker } from 'types/devices/circuitBreaker';
import { AfterUpdateCallBackFunc, BeforeUpdateCallBackFunc, TDeviceEvent } from 'types/devices/device';
import { EBreakerTypes, EDeviceType } from 'types/devices/enums.d';
import { IMMS } from 'types/devices/manualMotorStarter';
import { ThunkAction } from 'types/known-actions';

export const onFocusOutHandler =
  (event: TDeviceEvent, elementId?: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.onFocusOutHandler(event, inputUpdateHandler, EDeviceType.MMS, elementId));
  };

export const inputUpdateHandler =
  (event: TDeviceEvent, selectedDeviceId?: string): ThunkAction<void> =>
  (dispatch) => {
    const breakerObjectType: BeforeUpdateCallBackFunc = (name, value, selectedDevice, additionalParams) => () => {
      const device = selectedDevice as ICircuitBreaker['selectedDevice'];
      switch (name) {
        case 'TypeFilter':
          Object.assign(additionalParams, changeBreakerObjectType(value.value.toString() as EBreakerTypes, device, true));
          break;
        default:
          break;
      }
    };
    const manageBreakerRequest: AfterUpdateCallBackFunc = (name, value, selectedDevice, additionalData, deviceId) => () => {
      switch (name) {
        case 'PolesFilter':
        case 'FamilyFilter':
        case 'ReleaseFilter':
        case 'TypeFilter':
        case 'LineType':
        case 'EarthingSystem':
        case 'DeviceId':
          dispatch(manageFilters(selectedDevice));
          break;
        case 'familyOutput':
        case 'polesOutput':
        case 'sizeOutput':
        case 'versionOutput':
        case 'icuOutput':
        case 'inOutput':
        case 'releaseOutput':
          dispatch(getBreakerFilters(deviceId, name));
          break;
        case 'terminalsOutput':
          dispatch(storeCircuitBreaker(deviceId, name));
          break;
        default:
          break;
      }
    };
    dispatch(Device.inputUpdateHandler(event, EDeviceType.MMS, selectedDeviceId, breakerObjectType, manageBreakerRequest));
  };

export const getBreakerFilters =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.getDeviceFilters(deviceId, changed, EDeviceType.MMS, storeCircuitBreaker));
  };

export const storeCircuitBreaker =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDevice = state.devices[deviceId] as IMMS['selectedDevice'];
    const selectedTerminal = selectedDevice.terminalsOutputList
      ? selectedDevice.terminalsOutputList.find((item) => item.Description === selectedDevice.terminalsOutput)
      : undefined;
    const deviceKey1 = selectedTerminal ? selectedTerminal.LowVoltageCiruitBreakerId : undefined;
    dispatch(Device.storeDevice(deviceId, changed, EDeviceType.MMS, undefined, deviceKey1));
  };
