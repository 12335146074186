import Konva from 'konva';
import { DEFAULT_CURVE_LINE_CONFIG, DEFAULT_CURRENTS_LINE_COLOR } from 'curves/Constants';
import GridSizeConfig from 'curves/GridSizeConfig';
import { LineConfig } from 'konva/lib/shapes/Line';

export default class CurrentLine extends Konva.Line {
  curveShapeName: string;

  constructor(point: number, sizeConfig: GridSizeConfig, dash?: Array<number>) {
    const pointX = sizeConfig.adjustXPoint(point);
    super({
      ...(DEFAULT_CURVE_LINE_CONFIG as LineConfig),
      stroke: DEFAULT_CURRENTS_LINE_COLOR,
      points: [pointX, 0, pointX, sizeConfig.height],
      dash,
    });
    this.curveShapeName = 'CurrentLine';
  }
}
