import Device from 'devices/device';
import { manageCoordinationCombo } from 'devices/protectedObjects/actions';
import { changeBreakerObjectType } from 'devices/utils';
import { manageFilters } from 'filters/manager';
import { ICircuitBreaker } from 'types/devices/circuitBreaker';
import { AfterUpdateCallBackFunc, BeforeUpdateCallBackFunc, TDeviceEvent } from 'types/devices/device';
import { EBreakerTypes, EDeviceType } from 'types/devices/enums.d';
import { ThunkAction } from 'types/known-actions';
// import {manageCoordinationFilter} from 'devices/protectedObjects/actions';
// import buildNetwork from 'project/buildNetwork';

export const onFocusOutHandler =
  (event: TDeviceEvent, elementId?: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.onFocusOutHandler(event, inputUpdateHandler, EDeviceType.CB_TM, elementId));
  };

export const inputUpdateHandler =
  (event: TDeviceEvent, selectedDeviceId?: string): ThunkAction<void> =>
  (dispatch) => {
    const processBreakerInputs: BeforeUpdateCallBackFunc = (name, value, selectedDevice, additionalParams) => () => {
      const device = selectedDevice as ICircuitBreaker['selectedDevice'];
      switch (name) {
        case 'TypeFilter':
          Object.assign(additionalParams, changeBreakerObjectType(value.value as EBreakerTypes, device, true));
          break;
        case 'VersionFilter':
        case 'versionOutput':
          Object.assign(additionalParams, changeBreakerObjectType(value.value as EBreakerTypes, device));
          break;
        case 'IdnFilter':
          Object.assign(additionalParams, { IdnFilter: value.value === 'All possibilities' ? -1 : value.value });
          break;
        default:
          break;
      }
    };
    const manageBreakerRequest: AfterUpdateCallBackFunc = (name, value, selectedDevice, additionalData, deviceId) => () => {
      switch (name) {
        case 'PolesFilter':
        case 'FamilyFilter':
        case 'VersionFilter':
        case 'ReleaseFilter':
        case 'TypeFilter':
        case 'LineType':
        case 'EarthingSystem':
        case 'RcdTypeFilter':
        case 'RcdVersionFilter':
        case 'IdnFilter':
          dispatch(manageFilters(selectedDevice as ICircuitBreaker['selectedDevice']));
          break;
        case 'CoordinationFilter':
          // dispatch(manageCoordinationFilter(deviceId, value.toString()));
          dispatch(manageCoordinationCombo(selectedDevice as ICircuitBreaker['selectedDevice'], value.toString(), deviceId));
          break;
        case 'familyOutput':
        case 'frameOutput':
        case 'polesOutput':
        case 'sizeOutput':
        case 'versionOutput':
        case 'icuOutput':
        case 'inOutput':
        case 'releaseOutput':
        case 'rcClassOutput':
        case 'rcVersionOutput':
        case 'tripUnitTypeOutput':
        case 'RcbId':
        case 'Uref':
        case 'DeviceId': {
          dispatch(getBreakerFilters(deviceId, name));
          break;
        }
        case 'terminalsOutput':
          dispatch(storeCircuitBreaker(deviceId, name));
          break;
        default:
          break;
      }
    };
    dispatch(Device.inputUpdateHandler(event, EDeviceType.CB_TM, selectedDeviceId, processBreakerInputs, manageBreakerRequest));
  };

export const getBreakerFilters =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.getDeviceFilters(deviceId, changed, EDeviceType.CB_TM, storeCircuitBreaker));
  };

export const storeCircuitBreaker =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDevice = state.devices[deviceId] as ICircuitBreaker['selectedDevice'];
    // if (
    //   !selectedDevice ||
    //   (state.modal.params &&
    //     state.modal.params.selectedDevice &&
    //     !breakerSelectionChanged(
    //       selectedDevice as unknown as CircuitBreaker,
    //       state.modal.params.selectedDevice as CircuitBreaker
    //     ))
    // ) {
    //   return;
    // }
    const deviceKey2 =
      selectedDevice.Filter && (selectedDevice.Filter.IsRCR || selectedDevice.Filter.IsInstant)
        ? selectedDevice.RcbId
        : undefined;
    const deviceData = selectedDevice.DevicesList
      ? selectedDevice.DevicesList.find((item) => item.DeviceId === selectedDevice.DeviceId)
      : undefined;
    const deviceKey1 = deviceData ? (deviceData.LowVoltageCiruitBreakerId as string) : undefined;
    dispatch(Device.storeDevice(deviceId, changed, EDeviceType.CB_TM, deviceKey2, deviceKey1));
  };

export const getDeviceWishes =
  (deviceData: ICircuitBreaker['selectedDevice'], deviceId: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.getDeviceWishes(deviceData, EDeviceType.CB_TM, deviceId));
  };

// const breakerSelectionChanged = (currentBreaker: CircuitBreaker, oldBreaker: CircuitBreaker) => {
//   const currentCircuitBreakerSelection = currentBreaker.terminalsOutputList?.find(
//     (term) => term.Description === currentBreaker.terminalsOutput
//   );
//   const currentCircuitBreakerSelectionId = currentCircuitBreakerSelection
//     ? currentCircuitBreakerSelection.LowVoltageCiruitBreakerId
//     : 0;
//   const oldCircuitBreakerSelection = oldBreaker.terminalsOutputList?.find(
//     (term) => term.Description === currentBreaker.terminalsOutput
//   );
//   const oldCircuitBreakerSelectionId = oldCircuitBreakerSelection ? oldCircuitBreakerSelection.LowVoltageCiruitBreakerId : -1;
//   return currentCircuitBreakerSelectionId !== oldCircuitBreakerSelectionId;
//   // return !isEqual(currentBreaker, oldBreaker);
// };
