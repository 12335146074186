/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { ModalKnownActions } from 'types/known-actions';
import * as ModalNamespace from 'types/modal';
import * as ActionConstants from 'types/constants.d';

export const initialState: ModalNamespace.State = {
  name: '',
  tabName: '',
  params: null,
  dialogParams: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: ModalKnownActions): ModalNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionConstants.MODALS.SHOW_MODAL:
        if (action.payload.name.length > 0) {
          draft.name = action.payload.name;
          draft.params = action.payload.params;
        } else {
          return initialState;
        }
        break;
      case ActionConstants.MODALS.UPDATE_PARAMS:
        draft.params = action.payload.params;
        break;
      case ActionConstants.MODALS.CHANGE_TAB:
        draft.tabName = action.payload.tabName;
        break;
      case ActionConstants.MODALS.SHOW_MODAL_DIALOG:
        draft.dialogParams = action.payload.dialogParams;
        break;
      default:
        break;
    }
  });
