import Device from 'devices/device';
import { manageFilters } from 'filters/manager';
import { AfterUpdateCallBackFunc, TDeviceEvent } from 'types/devices/device';
import { EDeviceType } from 'types/devices/enums.d';
import { ThunkAction } from 'types/known-actions';

export const onFocusOutHandler =
  (event: TDeviceEvent, elementId?: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.onFocusOutHandler(event, inputUpdateHandler, EDeviceType.RCCB, elementId));
  };

export const inputUpdateHandler =
  (event: TDeviceEvent, selectedDeviceId?: string): ThunkAction<void> =>
  (dispatch) => {
    const manageRCDRequest: AfterUpdateCallBackFunc = (name, value, selectedDevice, additionalData, deviceId) => () => {
      switch (name) {
        case 'FamilyFilter':
        case 'VersionFilter':
        case 'PolesFilter':
        case 'IdnFilter':
        case 'RcdTypeFilter':
        case 'RcdVersionFilter':
          if (name === 'IdnFilter') {
            Object.assign(additionalData, { IdnFilter: value === 'All possibilities' ? -1 : value });
          }
          dispatch(manageFilters(selectedDevice));
          break;
        case 'familyOutput':
        case 'polesOutput':
        case 'irOutput':
        case 'rcVersionOutput':
        case 'rcClassOutput':
        case 'idnOutput':
          dispatch(getResidualCurrentFilters(deviceId, name));
          break;
        case 'DeviceId':
          dispatch(Device.storeDevice(deviceId, name, EDeviceType.RCCB));
          break;
        default:
      }
    };
    dispatch(Device.inputUpdateHandler(event, EDeviceType.RCCB, selectedDeviceId, undefined, manageRCDRequest));
  };

export const getResidualCurrentFilters =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.getDeviceFilters(deviceId, changed, EDeviceType.RCCB));
  };
