import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'ARRIVAL',
  objectType: EDeviceObjectType.LinkIn,
  deviceType: EDeviceType.ARRIVAL,
  ports: [],

  LinkName: 'Not connected',
  UserDescription1: '',

  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
};
