/* eslint-disable react/no-danger */
import React, { FunctionComponent, useState } from 'react';
import { Icon } from '@abb/abb-common-ux-react';
import { ITableHeader } from 'types/components/table';
import './style.scss';

const TableHeader: FunctionComponent<ITableHeader> = ({
  children,
  onClick,
  onHeaderClick,
  sort,
  attr,
  width,
  dangerous,
  noHover,
}): React.ReactElement => {
  const [ascending, setAscending] = useState(false);

  const click = () => {
    if (onClick) onClick();

    setAscending(!ascending);
    if (onHeaderClick) onHeaderClick(Array.isArray(attr) ? '' : attr ?? '', ascending);
  };
  const sortIcon = ascending ? 'sort-ascending' : 'sort-descending';
  return (
    <th
      key={children as string}
      className={noHover ? 'DOCweb_CommonUX__TableHeader__NoHover' : 'DOCweb_CommonUX__TableHeader'}
      onClick={click}
      style={{ width: width ? `${width}` : 'auto' }}
    >
      {dangerous ? <span dangerouslySetInnerHTML={{ __html: children as string }} /> : children}
      {sort === attr ? <Icon name={`abb/${sortIcon}`} sizeClass="small" className="DOCweb_CommonUX__TableHeader_Icon" /> : null}
    </th>
  );
};

export default TableHeader;
