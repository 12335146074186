/* eslint-disable react/jsx-props-no-spreading */
// TODO: Rewrite spread operator to smth normal...
import React, { FunctionComponent } from 'react';
import { IDOCwebInput } from 'types/components/inputContainer';
import { IInputGrid } from 'types/components/inputContainer/grid';
import Grid from './Grid';
import Input from './Input';

const DOCwebInput: FunctionComponent<IDOCwebInput & IInputGrid> = (props): React.ReactElement => {
  return (
    <Grid {...props}>
      <Input {...props} />
    </Grid>
  );
};

export default DOCwebInput;
