import React, { FunctionComponent } from 'react';
import { IGridColumn } from 'types/components/grid';
import './style.scss';

const GridColumn: FunctionComponent<IGridColumn> = ({
  children,
  col,
  offset,
  verticalAlignment,
  horizontalAlignment,
  className,
  hidden,
  style,
  onBlur,
  onFocus,
  onClick,
  id,
  draggable,
  onTouchStart,
  onDragStart,
}): React.ReactElement => {
  return (
    <div
      id={id}
      onBlur={onBlur}
      style={style}
      onFocus={onFocus}
      onClick={onClick}
      hidden={hidden}
      draggable={draggable}
      onTouchStart={draggable ? onTouchStart : undefined}
      onDragStart={draggable ? onDragStart : undefined}
      className={`DOCweb__CommonUX_GridColumn${col || ``} ${offset ? `DOCweb__CommonUX_GridColumn_Offset${offset}` : ``} ${
        className || ''
      } ${verticalAlignment ? `DOCweb__CommonUX_GridColumn_${verticalAlignment}` : ''} ${
        horizontalAlignment ? `DOCweb__CommonUX_GridRow_${horizontalAlignment}` : ''
      }`}
      role="presentation"
    >
      {children}
    </div>
  );
};

export default GridColumn;
