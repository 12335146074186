/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { FunctionComponent } from 'react';
import CheckBox from 'components/generic/CheckBox';
import { Icon } from '@abb/abb-common-ux-react';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { useSelector, useDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import { IFeederPropertiesGrid } from 'types/components/FeederPropertiesGrid';
import { IRelationItem } from 'types/devices/protectedObjects';
import { UnitsType } from 'types/calculations';
import EditableSelect from './editableSelect';

/**
 * Use this class to create input field
 * @class Input
 * @param {array} cols - specify number of cols in boostrap layout. Array length should be maxium 4.(always required)
 *  cols[0] = {integer} => represents main div col
 *  cols[1] = {integer} => represents label col
 *  cols[2] = {integer} => represents select col
 *  cols[3] = {integer} => represents error label col
 * @param {string} divStyle - main div style
 * @param {string} labelDivStyle - style for div of label
 * @param {string} errorDivStyle - style for div of error
 * @param {string} className - class name for select field
 * @param {label} label - text that is shown left side from input field
 * @param {string} name - name attribute of select
 * @param {string} type - basic input type
 * @param {func} onCheckBoxChange - Function that called when input changes
 * @param {bool} disabled - enables or disables input field
 * @param {object} error - place where errors are stored
 * @param {object} data - place where input is storing value
 * @param {func} onFocusOut - func that should be performed after focus
 */

const FeederPropertiesGrid: FunctionComponent<IFeederPropertiesGrid> = ({
  cols,
  icons,
  colsAlignment,
  colsNames,
  colsItems,
  displayKeys,
  displayType,
  onCheckBoxChange,
  onSelectChange,
  onSelectFocusOut,
  getDeviceIdBySign,
  cut,
  ProtectionAutoAssignOff,
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const devices = useSelector((state) => state.devices);
  const generateTableHeader = (): React.ReactElement => {
    return (
      <GridRow>
        <GridColumn col={cols[0]}>{generateIcons()}</GridColumn>
        <GridColumn col={cols[1]}>{generateTableLabels()}</GridColumn>
      </GridRow>
    );
  };

  const generateIcons = (): React.ReactElement => {
    return (
      <GridRow>
        {icons.map((icon, key) => (
          <GridColumn
            key={`Icon${key}`}
            className="object-properties-name object-properties-left-border object-properties-protected label-header"
          >
            <Icon name={`abb/${icon}`} sizeClass="large" />
          </GridColumn>
        ))}
      </GridRow>
    );
  };
  // TODO Finish Implementation
  const generateTableLabels = (): React.ReactElement => {
    return (
      <GridRow>
        {colsAlignment.map((value, key) => (
          <GridColumn
            key={`TableLabel${key}`}
            col={value}
            verticalAlignment="middle"
            className="object-properties-name label-header"
          >
            <span dangerouslySetInnerHTML={{ __html: t(colsNames[key]) }} />
          </GridColumn>
        ))}
      </GridRow>
    );
  };

  const generateTableContent = (): React.ReactElement[] => {
    return colsItems.map((item, key) =>
      devices[item.id] ? (
        <GridRow key={`TableContent${key}`}>
          <GridColumn col={cols[0]}>{generateCheckboxes(item)}</GridColumn>
          <GridColumn col={cols[1]}>{generateColumnsContent(item)}</GridColumn>
        </GridRow>
      ) : (
        <></>
      )
    );
  };

  const generateColumnsContent = (item: IRelationItem): React.ReactElement => {
    let displayMethod;
    const toGenerate = (index: number) => {
      if (displayType[index] === 'Input') {
        displayMethod = generateDiscriminationInput;
      } else {
        displayMethod = generateLabels;
      }
      return displayMethod(index, item);
    };

    return (
      <GridRow>
        {colsAlignment.map((alignment, index) => (
          <GridColumn
            verticalAlignment={displayType[index] === 'Label' ? 'middle' : undefined}
            key={`ColumnContent${index}`}
            col={alignment}
            className={`object-properties-name object-properties-name-value
                ${displayType[index] === 'Input' ? 'editableSelectColumnDiscrimination' : ''}`}
          >
            {toGenerate(index)}
          </GridColumn>
        ))}
      </GridRow>
    );
  };

  const checked = (id: string, link: { property: string }): boolean => {
    if (id && link && devices[id][`${link.property}`] && devices[id][`${link.property}New`]) {
      return devices[id][`${link.property}`] === devices[id][`${link.property}New`];
    }
    return false;
  };

  const generateCheckboxes = (item: IRelationItem): React.ReactElement => {
    return (
      <GridRow>
        {item.links.map((link, key) => {
          // const checked = checked(item.id, link);
          return (
            <GridColumn className="object-properties-left-border padding-top-0 label-checkbox" key={key}>
              <CheckBox
                name={link.property}
                key={`Checkbox${key}`}
                onChange={(e) =>
                  dispatch(
                    onCheckBoxChange(
                      item.id,
                      link.property,
                      e.value
                        ? (devices[item.id][`${link.property}New`] as string)
                        : (devices[item.id][`${link.property}Old`] as string),
                      false,
                      item,
                      link,
                      e.value,
                      e
                    )
                  )
                }
                value={checked(item.id, link)}
                disabled={!link.allowed || !ProtectionAutoAssignOff}
              />
            </GridColumn>
          );
        })}
      </GridRow>
    );
  };

  const generateLabels = (index: number, item: IRelationItem): React.ReactElement => {
    if (Number.isNaN(item[displayKeys[index] as keyof typeof item])) {
      return <span>{t(item[displayKeys[index] as keyof typeof item] as string)}</span>;
    }
    if (item[displayKeys[index] as keyof typeof item] === -1) {
      let label;
      if (checked(item.id, item.links[0])) {
        label = 'None';
      } else {
        label = '';
      }
      return <span>{t(label)}</span>;
    }
    // ??? add check for isNaN? check DISCRIMINATION after adding locize to check this
    return (
      <span>{t(cut(displayKeys[index] as UnitsType, item[displayKeys[index] as keyof typeof item] as string) as string)}</span>
    );
  };

  const generateDiscriminationInput = (index: number, item: IRelationItem): React.ReactElement => {
    // todo make this function more generice if in future such table will be used somewhere else with select
    // const checked = checked(item.id, item.links[0]);
    let discriminationValuesList = [];
    if (item.discrimDBLevel < 1) {
      discriminationValuesList = ['Full'];
    } else {
      discriminationValuesList = ['Full'].concat(
        Array.from(Array(Math.floor(item.discrimDBLevel + 1)).keys())
          .map((discLevel) => discLevel.toString())
          .splice(1)
      );
    }
    return (
      <EditableSelect
        cols={[8]}
        type="string"
        onChange={(e) => onSelectChange(e, item, checked(item.id, item.links[index]))}
        onFocusOut={(e) => onSelectFocusOut(e, item, checked(item.id, item.links[index]))}
        // editableSelectClassName="editable-select-column"
        name={displayKeys[index]}
        data={getDeviceIdBySign ? devices[dispatch(getDeviceIdBySign(item.userId))] : devices[item.id]}
        selectList={discriminationValuesList}
        valueList={discriminationValuesList}
        disabled={!checked(item.id, item.links[0])}
      />
    );
  };

  return (
    <Grid>
      {generateTableHeader()}
      {generateTableContent()}
    </Grid>
  );
};

export default FeederPropertiesGrid;
