export default {
  type: 'app.LINE',
  size: {
    width: 50,
    height: 1,
  },
  ports: {
    items: [],
  },
  position: {
    x: 65,
    y: 85,
  },
  angle: 0,
  id: '79a0f0e8-f39e-454e-b519-be2e6cbf89c2',
  z: 4,
  attrs: {},
};
