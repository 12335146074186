/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
import { Icon, TabControl, TabItem } from '@abb/abb-common-ux-react';
import { curvesActions, setCurvesDiagram } from 'curves/actions';
import { diagramComboNames } from 'curves/CurvesTranslations';
import { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { ICurveDiagram, ICustomCurve } from 'types/curves';
import { ApplicationState } from 'types/store';
import { EDeviceType } from 'types/devices/enums.d';
import { customCurvesDiagrams } from 'curves/Constants';
import { cloneDeep } from 'lodash';
import CurveType from './CurveType';

const TAB_WIDTH = 191;

const DiagramTabs: FunctionComponent<{ selectedDeviceId: string }> = ({ selectedDeviceId }): React.ReactElement => {
  const curves = useSelector((state) => state.curves);
  const devices = useSelector((state) => state.devices);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(true);
  const [eventListenerAdded, setEventListenerAdded] = useState(false);
  useEffect(() => {
    if (!eventListenerAdded && curves.curve2Show && curves.curve2Show.length > 0) {
      setEventListenerAdded(true);
      window.addEventListener('resize', () => {
        disableScrolls(canTabGo('left', curves.curve2Show), canTabGo('right', curves.curve2Show));
      });
      setTimeout(() => disableScrolls(canTabGo('left', curves.curve2Show), canTabGo('right', curves.curve2Show)));
    }
  }, [curves]);

  const canTabGo = (where: string, curves2Show: ApplicationState['curves']['curve2Show']) => {
    const scrollingUl = $('.DOCweb_CommonUX_TabControl__root_custom').find('ul');
    const visibleWidthScroll = $(scrollingUl).width() || 0.0000001;
    let itemsWidth = 0;
    const tabs = $('.DOCweb_CommonUX_TabControl__root_custom').find('ul').find('li');
    if (curves2Show && tabs) {
      curves2Show.forEach((diagram, key) => {
        itemsWidth += $(tabs[key]).width() || 0;
      });
    }
    const currentScroll = $($('.DOCweb_CommonUX_TabControl__root_custom')[0]).scrollLeft() || 0;
    if (where === 'right') {
      return itemsWidth - currentScroll - 5 > visibleWidthScroll;
    }
    if (where === 'left') {
      return currentScroll > 0;
    }
    return false;
  };

  const disableScrolls = (canGoLeft: boolean, canGoRight: boolean) => {
    if (!canGoLeft) {
      setLeftDisabled(true);
    } else {
      setLeftDisabled(false);
    }
    if (!canGoRight) {
      setRightDisabled(true);
    } else {
      setRightDisabled(false);
    }
  };

  const moveTab = (sign: string) => {
    $('.DOCweb_CommonUX_TabControl__root_custom').animate({ scrollLeft: `${sign}=${TAB_WIDTH}px` }, 'fast', () => {
      disableScrolls(canTabGo('left', curves.curve2Show), canTabGo('right', curves.curve2Show));
    });
  };

  let customDevice: ICustomCurve | undefined;
  const selectedDevice = devices[selectedDeviceId];
  if (!selectedDevice && curves.checkedCurves.length > 0) {
    customDevice = curves.customCurves.devices[selectedDeviceId];
  }

  if (!selectedDevice && !customDevice) {
    return (
      <TabControl
        key={'mainTabs'}
        allowAdd={false}
        type="primary"
        containerId={'diagram-tabs-container'}
        allowRemove={false}
        className={`DOCweb_CommonUX_TabControl__root_custom`}
        activeTab={0}
      >
        <TabItem key={`empty tab`} title={t('CURVES_WEB_STRINGS_DEVICE_IS_NOT_SELECTED')}></TabItem>
      </TabControl>
    );
  }

  const curve2Show: ICurveDiagram[] = [];
  if (curves.curve2Show && selectedDevice) {
    curve2Show.push(
      ...curves.curve2Show.filter(
        (curveShow) =>
          Object.keys(CurveType).includes(curveShow.id) ||
          Object.keys(CurveType).includes(curveShow.name) ||
          curveShow.id === 'PEAK'
      )
    );
    curve2Show.unshift(
      ...curves.curve2Show
        .filter(
          (curveShow) =>
            !Object.keys(CurveType).includes(curveShow.id) &&
            !Object.keys(CurveType).includes(curveShow.name) &&
            curveShow.id !== 'PEAK'
        )
        .sort((cS1, cS2) => {
          if (cS1.sign && cS2.sign) {
            const firstSign = parseFloat(cS1.sign.replace(/[^0-9]/g, ''));
            const nextSign = parseFloat(cS2.sign.replace(/[^0-9]/g, ''));
            if (firstSign < nextSign) {
              return -1;
            }
            if (firstSign > nextSign) {
              return 1;
            }
            return 0;
          }
          return 0;
        })
    );
  }

  let diagram = selectedDevice ? selectedDevice.diagram : customDevice?.selectedDiagram;
  if (customDevice && !diagram) {
    diagram = Object.keys(customDevice.curves).find(
      (diagramName) => customDevice && customDevice.curves[diagramName].points.length > 0
    );
  }
  if (curve2Show.length > 0 && selectedDevice) {
    if (!diagram) {
      const lastDiagram = curve2Show[curve2Show.length - 1].id;
      if (selectedDevice.deviceType === EDeviceType.CB_TM && lastDiagram !== 'PEAK') {
        diagram = lastDiagram;
      } else {
        diagram = curve2Show[0].id;
      }
    }
  }

  if (!diagram) {
    return <></>;
  }

  return (
    <>
      {leftDisabled || !curve2Show.length ? (
        <></>
      ) : isMobile ? (
        <></>
      ) : (
        <Icon
          key={'leftArrow'}
          sizeClass={'large'}
          name="abb/caret-left"
          style={{ float: 'left', marginTop: '4px' }}
          onClick={() => moveTab('-')}
          className="DOCweb_CommonUX_Icon_Hover"
        />
      )}
      <TabControl
        key={'mainTabs'}
        allowAdd={false}
        type="primary"
        containerId={'diagram-tabs-container'}
        allowRemove={false}
        className={`DOCweb_CommonUX_TabControl__root_custom ${
          rightDisabled ? 'DOCweb_CommonUX_TabControl__root_custom_marginless' : ''
        } 
        ${isMobile ? 'diagram-tabs-scroll' : ''}`}
        activeTab={(() => {
          if (!selectedDevice && customDevice) {
            return customCurvesDiagrams.indexOf(diagram);
          }
          return curve2Show.findIndex((curve) => curve.id === diagram);
        })()}
        onTabChange={(oldIndex, newIndex) => {
          if (customDevice && !selectedDevice) {
            const customCurves = cloneDeep(curves.customCurves);
            customCurves.devices[selectedDeviceId].selectedDiagram = customCurvesDiagrams[newIndex];
            dispatch(curvesActions.setCustomCurves({ customCurves }));
          } else {
            dispatch(setCurvesDiagram(curve2Show[newIndex].id));
          }
        }}
      >
        {curve2Show.length > 0 && selectedDevice
          ? curve2Show.map((curveDiagram, key) => (
              <TabItem
                key={`${key}tabItem`}
                title={
                  diagramComboNames[curveDiagram.name as keyof typeof diagramComboNames]
                    ? t(diagramComboNames[curveDiagram.name as keyof typeof diagramComboNames], {
                        'value[0]': [curveDiagram.sign],
                      })
                    : curveDiagram.name + (curveDiagram.sign != null ? ` ${curveDiagram.sign}` : '')
                }
              ></TabItem>
            ))
          : customCurvesDiagrams.map((diagramName, key) => (
              <TabItem
                key={`${key}tabItem`}
                title={t(diagramComboNames[diagramName as keyof typeof diagramComboNames])}
              ></TabItem>
            ))}
      </TabControl>
      {rightDisabled ? (
        <></>
      ) : isMobile ? (
        <></>
      ) : (
        <Icon
          key={'CurvesDiagramTabControl'}
          sizeClass={'large'}
          name="abb/caret-right"
          style={{ float: 'right' }}
          onClick={() => moveTab('+')}
          className="DOCweb_CommonUX_Icon_Hover DOCweb_CommonUX_TabControl__right_arrow"
        />
      )}
    </>
  );
};

export default DiagramTabs;
