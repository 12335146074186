import { ELvLineType } from 'types/devices/enums.d';
import { getVh } from './generalCalculations';

const STANDARD_R_S_RATIO = 0.627;
// TODO: Convert function types to interfaces.
export const calculateRatedCurrent = (Pn: number, V: number, cosPhi: number, eta: number, lineType: ELvLineType): number => {
  return Pn < 0 || V < 1e-9 || cosPhi < 1e-9 || eta < 1e-9 ? -1 : Pn / V / cosPhi / eta / getVh(lineType);
};

export const calculateMaxForcedCosPhi = (eta: number, IoIn: number, cosPhi: number): number => {
  return eta >= -1 ? Math.cos(Math.atan(0.9 * IoIn - (eta * Math.sqrt(1 - cosPhi * cosPhi)) / (eta * cosPhi))) : 1;
};

export const checkForcedCosPhi = (forcedCosPhi: number, maxForcedCosPhi: number): number => {
  return forcedCosPhi > maxForcedCosPhi ? maxForcedCosPhi : forcedCosPhi;
};

export const calculateReactivePower = (Pn: number, cosPhi: number, forcedCosPhi: number): number => {
  return Pn * (Math.tan(Math.acos(cosPhi)) - Math.tan(Math.acos(forcedCosPhi)));
};

export const calculateRm = (Rr: number, Rs: number): number => {
  return Rr + Rs;
};

export const calculateXm = (Xr: number, Xs: number): number => {
  return Xr + Xs;
};

export const calculateRs = (Rm: number): number => {
  return Rm / (1 + STANDARD_R_S_RATIO);
};

export const calculateRr = (Rm: number): number => {
  return (Rm / (1 + STANDARD_R_S_RATIO)) * STANDARD_R_S_RATIO;
};

export const calculateXs = (Xm: number): number => {
  return Xm / (1 + STANDARD_R_S_RATIO);
};

export const calculateXr = (Xm: number): number => {
  return (Xm / (1 + STANDARD_R_S_RATIO)) * STANDARD_R_S_RATIO;
};

export const calculateRmXmRatio = (Rm: number, Xm: number): number => {
  return Xm < 1e-9 ? -1 : Rm / Xm;
};

export const calculateRatedPower = (Eta: number, Pn: number): number => {
  return Eta !== 0 ? Pn / Eta : 0;
};

export const calculateMechanicalPower = (Eta: number, P: number): number => {
  return P * Eta;
};
