import { createAction } from 'redux-actions';
import { UNDO } from 'types/constants.d';
import { Actions } from 'types/undo';

export const undoActions = {
  addPreviousState: createAction<Actions['addPreviousState']['payload']>(UNDO.ADD_PREVIOUS_STATE),
  addNextState: createAction<Actions['addNextState']['payload']>(UNDO.ADD_NEXT_STATE),
  removeUndo: createAction<Actions['removeUndo']['payload']>(UNDO.REMOVE_UNDO),
  undoDeletePage: createAction<Actions['undoDeletePage']['payload']>(UNDO.UNDO_DELETE_PAGE),
};
