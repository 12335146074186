import { IValidateXMLProject, IValidateXMLProjectResponse } from 'types/api/projectExport';
import ApiCalls from './ApiCalls';

export default class ExportProjectAPI {
  static async convertProjectToDXF(request: { preparedDXF: string }): Promise<string> {
    const { data } = await ApiCalls.post<string, { preparedDXF: string }>('ConvertProjectToDXF', request);
    return data;
  }

  static async validateXMLproject(param: Partial<IValidateXMLProject>): Promise<IValidateXMLProjectResponse> {
    const { data } = await ApiCalls.post<IValidateXMLProjectResponse, Partial<IValidateXMLProject>>(
      'XsdValidation/ValidateXML',
      param
    );
    return data;
  }
}
