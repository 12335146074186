export const BASIC_APIS = ['propagateRunning', 'computeRunning'] as const;

export const REQUESTS_API_DEPENDENCY = {
  deviceFilters: BASIC_APIS,
  storeDevice: BASIC_APIS,
  deviceCurves: BASIC_APIS,
  motorCoordination: BASIC_APIS,
  typicalUnits: BASIC_APIS,
  printPreview: BASIC_APIS,
  relations: BASIC_APIS,
  computeProject: ['onOffRunning', 'relationsRunning', 'propagateRunning', 'motorCoordinationIsRunning', 'typicalUnitsRunning'],
  propagate: [],
} as const;
