/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { inputUpdateHandler as breakerInputUpdateHandler } from 'devices/circuitBreaker/actions';
import { inputUpdateHandler as fuseInputUpdateHandler } from 'devices/fuse/actions';
import { inputUpdateHandler as mmsInputUpdateHandler } from 'devices/manualMotorStarter/actions';
import { inputUpdateHandler as rccbInputUpdateHandler } from 'devices/residualCurrentCircuitBreaker/actions';
import { EDeviceType } from 'types/devices/enums.d';

import { changeTab, showModal } from 'ui/modals/actions';
import { useTranslation } from 'react-i18next';
import ModalWindow from 'ui/modals/ModalWindow';
import ConfigurationTabCommon from 'devices/commonProperties/ConfigurationTab';
import ProtectedObjectTab from 'devices/protectedObjects/component';
import DiscriminationTab from 'devices/protectedObjects/discriminationComponent';
import { CircuitBreaker } from 'types/devices/circuitBreaker';
import { Device, PowerDevice } from 'types/devices/device';
import { ProtectedObject } from 'types/devices/protectedObjects';
import { ConfigurationTabCommon as TConfigurationTabCommon } from 'types/devices/commonProperties';
import CustomCurvesDeviceManager from 'ui/modals/customCurveDevices/deviceManager';
import { isEqual } from 'lodash';
import DeviceParameters from 'ui/modals/customCurveDevices/deviceParameters';
import DevicesTab from './DevicesTab';
import { addDevice } from './actions';
import PropertiesTab from './PropertiesTab';

const WizardDialog: FunctionComponent = (): React.ReactElement => {
  const variables = useSelector((state) => state.variables);
  const modal = useSelector((state) => state.modal);
  const project = useSelector((state) => state.project);
  const devices = useSelector((state) => state.devices);
  const customSelectedDeviceId = useSelector((state) => state.curves.customCurves.selectedDeviceId, isEqual);
  // const [customDeviceTab, selectCustomDeviceTab] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (variables.MainStandard === 'UL' && !modal.params?.selectedDevice) {
      dispatch(addDevice('Miniature Circuit'));
    }
  }, []);

  const selectedDevice = project.selectedDeviceId ? devices[project.selectedDeviceId] : null;

  const typeToInputHandler = {
    [EDeviceType.CB_TM]: breakerInputUpdateHandler,
    [EDeviceType.MMS]: mmsInputUpdateHandler,
    [EDeviceType.RCCB]: rccbInputUpdateHandler,
    [EDeviceType.FUSEBASE]: fuseInputUpdateHandler,
  };

  const switchTab = (tab: number) => {
    dispatch(changeTab(Object.keys(tabs)[tab], false));
  };

  const onPrevPage = () => {
    const currentPage = modal.tabName ? Object.keys(tabs).indexOf(modal.tabName) : 0;
    // eslint-disable-next-line no-empty
    if (currentPage === 0) {
    } else {
      switchTab(currentPage - 1);
      if (currentPage === 1) {
        window.storeDeviceFunc = undefined;
      }
    }
  };

  const onNextPage = () => {
    const currentPage = modal.tabName ? Object.keys(tabs).indexOf(modal.tabName) : 0;
    if (modal.tabName === 'STRINGS_CURVES_MODAL_PROPERTIES_TAB') {
      if (window.storeDeviceFunc) {
        window.storeDeviceFunc();
        window.storeDeviceFunc = undefined;
      }

      dispatch(showModal());
      dispatch(changeTab('', false));
    } else {
      switchTab(currentPage + 1);
    }
  };

  let tabs: Record<string, React.ReactElement> = {
    STRINGS_CURVES_MODAL_DEVICES_TAB: <DevicesTab />,
    STRINGS_CURVES_MODAL_CUSTOM_TAB: <CustomCurvesDeviceManager />,
  };

  if (customSelectedDeviceId) {
    tabs.STRINGS_CURVES_MODAL_CUSTOM_DEVICES_TAB = <DeviceParameters />;
    switchTab(Object.keys(tabs).indexOf('STRINGS_CURVES_MODAL_CUSTOM_DEVICES_TAB'));
  } else if (selectedDevice) {
    tabs.STRINGS_IDD_CONFIGS = (
      <ConfigurationTabCommon
        selectedDevice={selectedDevice as Device<TConfigurationTabCommon & PowerDevice>}
        selectedDeviceId={project.selectedDeviceId as string}
        inputUpdateHandler={typeToInputHandler[selectedDevice.deviceType as keyof typeof typeToInputHandler]}
      />
    );
    tabs.STRINGS_CURVES_MODAL_PROPERTIES_TAB = <PropertiesTab />;
    if (!customSelectedDeviceId) {
      delete tabs.STRINGS_CURVES_MODAL_CUSTOM_TAB;
    }
  }

  if (selectedDevice && selectedDevice.deviceType === EDeviceType.WIRELV && tabs.STRINGS_IDD_CONFIGS) {
    delete tabs.STRINGS_IDD_CONFIGS;
  }
  // delete first tab with device selections in case of UL
  if (variables.MainStandard === 'UL') {
    delete tabs.STRINGS_CURVES_MODAL_DEVICES_TAB;
  }
  const devicesType = [EDeviceType.CB_TM, EDeviceType.FUSEBASE, EDeviceType.RCCB, EDeviceType.MMS];
  if (modal.params?.selectedDevice) {
    delete tabs.STRINGS_CURVES_MODAL_DEVICES_TAB;
    devicesType.forEach((type) => {
      if (selectedDevice && selectedDevice.deviceType === type) {
        tabs = {
          ...tabs,
          'Protected Objects': (
            <ProtectedObjectTab
              selectedDevice={selectedDevice as Device<ProtectedObject>}
              selectedDeviceId={project.selectedDeviceId as string}
            />
          ),
          Selectivity: (
            <DiscriminationTab
              selectedDevice={selectedDevice as Device<CircuitBreaker & PowerDevice>}
              selectedDeviceId={project.selectedDeviceId as string}
            />
          ),
        };
      }
    });
  }
  let currentTab = modal.tabName ? Object.keys(tabs).indexOf(modal.tabName) : 0;

  if (currentTab === -1) {
    currentTab = 0;
  }
  if (
    currentTab === Object.keys(tabs).indexOf('STRINGS_IDD_CONFIGS') ||
    currentTab === Object.keys(tabs).indexOf('STRINGS_CURVES_MODAL_PROPERTIES_TAB')
  ) {
    delete tabs.STRINGS_CURVES_MODAL_CUSTOM_TAB;
  }

  const blueButtonText = modal.params?.selectedDevice
    ? null
    : currentTab === Object.keys(tabs).length - 1
    ? t('STRINGS_IDC_CONFIGURATION_SAVE')
    : t('DOCWEB_FOOTER_BUTTON_NEXT_STEP');
  const greyButtonText = modal.params?.selectedDevice ? null : t('DOCWEB_FOOTER_BUTTON_PREV_STEP');

  return (
    <ModalWindow
      tabs={tabs}
      blueButtonAction={onNextPage}
      blueButtonText={blueButtonText ?? undefined}
      disableBlueButton={!selectedDevice}
      greyButtonAction={onPrevPage}
      greyButtonText={greyButtonText ?? undefined}
      disableGreyButton={currentTab === 0}
      showCloseButton={selectedDevice && modal.params?.selectedDevice ? !selectedDevice.requestRunning : true}
      tutorialCheckbox={true}
      isLoading={selectedDevice ? (selectedDevice.requestRunning as boolean) : false}
      footer={
        currentTab !== Object.keys(tabs).indexOf('STRINGS_CURVES_MODAL_CUSTOM_DEVICES_TAB') &&
        currentTab !== Object.keys(tabs).indexOf('STRINGS_CURVES_MODAL_CUSTOM_TAB') &&
        currentTab !== Object.keys(tabs).indexOf('STRINGS_CURVES_MODAL_DEVICES_TAB') ? (
          <></>
        ) : (
          'without'
        )
      }
    />
  );
};

export default WizardDialog;
