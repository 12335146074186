export default {
  type: 'app.TEXTLINK',
  connector: {
    name: 'normal',
  },
  id: '7b576aba-38fd-4647-8265-2cb213703807',
  z: 6,
  attrs: {
    line: {
      stroke: '#222138',
      strokeDasharray: '0',
      strokeWidth: 'inherit',
      targetMarker: {
        type: 'circle',
        r: 1.5,
        stroke: 'none',
      },
    },
  },
  source: {
    id: '14e3fe95-002c-4d6e-851d-ca5efbef67f2',
    magnet: 'circle',
    port: '5930370d-6f0f-4801-81dc-28359c77892b',
  },
};
