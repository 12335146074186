import {
  IAddUser,
  IAddUserResponse,
  ICheckProjectName,
  IGetInternalUserDataResponse,
  IGetProjectDataResponse,
  IGetUserProjects,
  ISaveUserProject,
  ISaveUserProjectResponse,
  ISendEmailHelp,
  ISendEmailResponse,
  IUpdateDefaultProjectProperties,
  IUpdateProjectSession,
  IUpdateShowUserDemo,
  IUpdateUserCountry,
  IUpdateUserPreferredLanguage,
  IUpdateUserProject,
} from 'types/api/user';
import { ApplicationState } from 'types/store';
import ApiCalls from './ApiCalls';

export default class UserAPI {
  static async addUser(userData: IAddUser): Promise<IAddUserResponse> {
    const { data } = await ApiCalls.post<IAddUserResponse, IAddUser>(`Users/AddUser/`, userData);
    return data;
  }

  static async getInternalUserData(d: { email: string }): Promise<IGetInternalUserDataResponse> {
    const { data } = await ApiCalls.post<IGetInternalUserDataResponse, { email: string }>(`Users/GetUser/`, d);
    return data;
  }

  static async saveUserProject(projectData: ISaveUserProject): Promise<ISaveUserProjectResponse> {
    const { data } = await ApiCalls.post<ISaveUserProjectResponse, ISaveUserProject>(`Users/AddUserProject/`, projectData);
    return data;
  }

  static async updateUserShowDemo(userData: IUpdateShowUserDemo): Promise<void> {
    await ApiCalls.put<void, IUpdateShowUserDemo>(`Users/UpdateUserShowDemo/`, userData);
  }

  static async updateUserDefaultProjectProperties(userId: string, data: IUpdateDefaultProjectProperties): Promise<void> {
    await ApiCalls.put<void, IUpdateDefaultProjectProperties>(`Users/UpdateUserDefaultProjectProperties/${userId}`, data);
  }

  static async updateUserCountry(userId: number, data: IUpdateUserCountry): Promise<void> {
    await ApiCalls.put<void, IUpdateUserCountry>(`Users/UpdateUserCountry/${userId}`, data);
  }

  static async updateUserPreferredLanguage(userId: number, data: IUpdateUserPreferredLanguage): Promise<void> {
    await ApiCalls.put<void, IUpdateUserPreferredLanguage>(`Users/UpdateUserPreferredLanguage/${userId}`, data);
  }

  static async updateUserProject(projectId: string, projectData: IUpdateUserProject): Promise<void> {
    await ApiCalls.put<void, IUpdateUserProject>(`Users/UpdateUserProject/${projectId}`, projectData);
  }

  static async getUserProjects(userId: number): Promise<Array<IGetUserProjects>> {
    const { data } = await ApiCalls.get<Array<IGetUserProjects>>(`Users/GetUserProjects/${userId}`);
    return data;
  }

  static async getProjectData(
    id: string,
    sessionId: string,
    sessionLastModified: Date,
    forceUnlock: string
  ): Promise<IGetProjectDataResponse> {
    const projectInfo = {
      ProjectId: id,
      SessionId: sessionId,
      SessionLastModified: sessionLastModified,
    };
    const { data } = await ApiCalls.post<IGetProjectDataResponse, typeof projectInfo>(
      `Users/GetProjectData/${forceUnlock}`,
      projectInfo
    );
    return data;
  }

  static async deleteProject(projectId: number): Promise<void> {
    await ApiCalls.delete<Promise<void>, void>(`Users/DeleteProject/${projectId}`);
  }

  static async getRecentProjects(userId: number): Promise<Array<IGetUserProjects>> {
    const { data } = await ApiCalls.get<Array<IGetUserProjects>>(`Users/GetRecenetProjects/${userId}`);
    return data;
  }

  static async checkProjectName(project: ICheckProjectName): Promise<boolean> {
    const { data } = await ApiCalls.post<boolean, ICheckProjectName>('Users/CheckProjectName/', project);
    return data;
  }

  static async getValidProjectName(userId: string, translationString: string): Promise<string> {
    const { data } = await ApiCalls.get<string>(`Users/GetValidProjectName/${userId}/${translationString}`);
    return data;
  }

  static async updateProjectSession(id: string, sessionId: string, sessionLastModified: Date): Promise<boolean> {
    const projectInfo: IUpdateProjectSession = {
      ProjectId: id,
      SessionId: sessionId,
      SessionLastModified: sessionLastModified,
    };
    const { data } = await ApiCalls.post<boolean, IUpdateProjectSession>(`Users/UpdateProjectSession/`, projectInfo);
    return data;
  }

  static async convertJson(projectData: ApplicationState): Promise<string> {
    const { data } = await ApiCalls.post<string, ApplicationState>(`Users/ConvertJSON/`, projectData);
    return data;
  }

  static async authenticate(): Promise<void> {
    await ApiCalls.get<void>(`authenticate`);
  }

  static async sendHelpMail(helpInformation: ISendEmailHelp): Promise<ISendEmailResponse> {
    const { data } = await ApiCalls.post<ISendEmailResponse, ISendEmailHelp>(`Users/SendHelpMail/`, helpInformation);
    return data;
  }
}
