/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FunctionComponent } from 'react';
import { inputUpdateHandler } from 'devices/circuitBreaker/actions';
import { ICircuitBreaker } from 'types/devices/circuitBreaker';
import { useSelector, useDispatch } from 'store';

import { useTranslation } from 'react-i18next';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import { Table, TableHeader } from 'components/generic/table';

const SelectionTab: FunctionComponent<ICircuitBreaker> = ({ selectedDevice }): React.ReactElement => {
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.variables);
  const { t } = useTranslation();
  const filters = (): React.ReactElement[] => {
    const standard = selectedDevice.Filter && selectedDevice.Filter.Standard ? selectedDevice.Filter.Standard : 0;
    let icuLabel = 'STRINGS_FILTER_ICU_PRE';
    switch (standard) {
      case 0:
        icuLabel = 'STRINGS_FILTER_ICU_PRE';
        break;
      case 1:
        icuLabel = 'Ics';
        break;
      case 2:
        icuLabel = 'STRINGS_FILTER_ICN_PRE';
        break;
      default:
        break;
    }
    const sizeList = [...selectedDevice.sizeOutputList];
    sizeList[0] = 'All possibilities';
    const icuList = [...selectedDevice.icuOutputList];
    icuList[0] = 'All possibilities';
    const inList = [...selectedDevice.inOutputList];
    inList[0] = 'All possibilities';
    const idnList = [...selectedDevice.idnFilterList];
    idnList[0] = 'All possibilities';
    const obsoleteFamilies = variables.ObsoleteFamilies.name;
    const disabledFamilies = variables.DisabledProductFamilies.name;
    const familyList = selectedDevice.familyOutputList;
    const isRCB = selectedDevice.Filter && selectedDevice.Filter.IsRCR;
    const rcbModelName = isRCB && selectedDevice.RcbModelName ? selectedDevice.RcbModelName : '';
    const familyColor: ICircuitBreaker['familyColor'] = {};
    if (variables.ObsoleteFamilies && variables.DisabledProductFamilies) {
      disabledFamilies.forEach((family) => {
        familyColor[family] = 'grey';
      });
      obsoleteFamilies.forEach((family) => {
        familyColor[family] = '#CB6745';
      });
    }
    const sortedfamilyOutputList: Array<string> = [];
    const ObsoleteFamilies: Array<string> = [];
    const DisabledProductFamilies: Array<string> = [];
    const familyFilterList: Array<string> = [];
    obsoleteFamilies.forEach((value) => (familyList.includes(value) ? ObsoleteFamilies.push(value) : null));
    disabledFamilies.forEach((value) =>
      familyList.includes(value) && !sortedfamilyOutputList.includes(value) && !obsoleteFamilies.includes(value)
        ? DisabledProductFamilies.push(value)
        : null
    );
    familyList.forEach((value) =>
      !ObsoleteFamilies.includes(value) && !DisabledProductFamilies.includes(value) ? familyFilterList.push(value) : null
    );
    sortedfamilyOutputList.push(...familyFilterList, ...DisabledProductFamilies, ...ObsoleteFamilies);
    return [
      <GridRow key={5}>
        <GridColumn col={2} offset={6} horizontalAlignment="center">
          <label className="labelBold">{`${selectedDevice.ProductDescription as string} ${rcbModelName}`}</label>
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={4}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="familyOutput"
            label="STRINGS_SUMTAB_FAMILY"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={sortedfamilyOutputList}
            selectList={sortedfamilyOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
            style={familyColor}
          />
        </GridColumn>
        <GridColumn col={4}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="frameOutput"
            label="DOCWEB_SELECTIONTAB_FRAME"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            // valueList={frame}
            selectList={selectedDevice.frameOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
            style={familyColor}
            namespace="Frames"
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={2}>
        <GridColumn col={4}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="tripUnitTypeOutput"
            label="DOCWEB_SELECTIONTAB_TRIPUNITTYPE"
            type="string"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.tripUnitTypeOutputList}
            selectList={selectedDevice.tripUnitTypeOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={4}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="releaseOutput"
            label="STRINGS_FILTER_RELEASE_PRE"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.releaseOutputList}
            selectList={selectedDevice.releaseOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={3}>
        <GridColumn>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="icuOutput"
            label={icuLabel}
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.icuOutputList}
            selectList={icuList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn>
          <Select
            cols={[6, 8, 0]}
            className="info-project-input"
            name="sizeOutput"
            label="STRING_SIZE"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.sizeOutputList}
            selectList={sizeList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="inOutput"
            label="STRING_IN"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.inOutputList}
            selectList={inList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn>
          <Select
            cols={[6, 8, 0]}
            className="info-project-input"
            name="polesOutput"
            label="STRING_POLES"
            type="text"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.polesOutputList}
            selectList={selectedDevice.polesOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
      </GridRow>,
      isRCB ? (
        <GridRow key={6}>
          <GridColumn offset={1} col={1}>
            <Select
              cols={[6, 8, 0]}
              className="info-project-input"
              name="rcClassOutput"
              label="DOCWEB_CIRCUITBREAKER_MODAL_RCCLASS"
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              selectList={selectedDevice.rcClassOutputList}
              valueList={selectedDevice.rcClassOutputList}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning}
            />
          </GridColumn>
          <GridColumn col={2}>
            <Select
              cols={[6, 8, 0]}
              className="info-project-input"
              name="rcVersionOutput"
              label="DOCWEB_CIRCUITBREAKER_MODAL_RCVERSION"
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              selectList={selectedDevice.rcVersionOutputList}
              valueList={selectedDevice.rcVersionOutputList}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning}
            />
          </GridColumn>
          <GridColumn col={2}>
            <Select
              cols={[7, 8, 0]}
              className="info-project-input"
              name="IdnFilter"
              label="DOCWEB_CIRCUITBREAKER_MODAL_RCIDN"
              type="text"
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              valueList={selectedDevice.idnFilterList}
              selectList={idnList}
              data={selectedDevice}
              disabled
            />
          </GridColumn>
        </GridRow>
      ) : (
        <span key="span1" />
      ),
    ];
  };
  const breakerTable = (): React.ReactElement => {
    const isRCB = selectedDevice.Filter && selectedDevice.Filter.IsRCR;

    const specificColumnWidth =
      variables.MainStandard === 'UL' ? ['auto', '115px', '115px'] : ['auto', '80px', '80px', '80px', '80px', '95px'];

    return (
      <Grid>
        <GridRow>
          <GridColumn col={8}>
            <Table
              data={selectedDevice.DevicesList}
              selectedValue={selectedDevice.DeviceId}
              valueAttr="DeviceId"
              disabled={selectedDevice.requestRunning}
              onRowClick={(e) => dispatch(inputUpdateHandler(e))}
              maxHeight={150}
              specificColumnWidth={specificColumnWidth}
            >
              {variables.MainStandard === 'UL'
                ? [
                    <TableHeader attr={'Description'} key={'SelectionTabCircuitBreakerTableDescription'}>
                      {t('STRINGS_MODELNAME_HDR')}
                    </TableHeader>,
                    <TableHeader attr={'In'} key={'SelectionTabCircuitBreakerTableIn'}>
                      {t('DOCCURVES_UL_MODAL_IN_CURR')}
                    </TableHeader>,
                    <TableHeader attr={'Icu'} key={'SelectionTabCircuitBreakerTableIcu'}>
                      {t('DOCCURVES_UL_MODAL_ICU_INTRT')}
                    </TableHeader>,
                  ]
                : [
                    <TableHeader attr={'Description'} key={'SelectionTabCircuitBreakerTableDescription'}>
                      {t('STRINGS_MODELNAME_HDR')}
                    </TableHeader>,
                    <TableHeader attr={'In'} key={'SelectionTabCircuitBreakerTableIn'}>
                      {t('STRING_IB')}
                    </TableHeader>,
                    <TableHeader attr={'Ics'} key={'SelectionTabCircuitBreakerTableIcs'}>
                      {t('STRINGS_FILTER_ICS_PRE')}
                    </TableHeader>,
                    <TableHeader attr={'Icu'} key={'SelectionTabCircuitBreakerTableIcu'}>
                      {t('STRINGS_FILTER_ICU_PRE')}
                    </TableHeader>,
                    <TableHeader attr={'Icn'} key={'SelectionTabCircuitBreakerTableIcn'}>
                      {t('STRINGS_FILTER_ICN_PRE')}
                    </TableHeader>,
                    <TableHeader attr={'Icw1s'} key={'SelectionTabCircuitBreakerTableIcw1s'}>
                      {t('DOCWEB_CIRCUITBREAKER_ICW1S')}
                    </TableHeader>,
                  ]}
            </Table>
            {isRCB ? (
              <div style={{ marginTop: '10px' }}>
                <label>{t('DOCWEB_CIRCUITBREAKER_RCBSELECTIONLABEL')}</label>
                <Table
                  data={selectedDevice.BreakersRcbList}
                  selectedValue={selectedDevice.RcbId}
                  valueAttr="RcbId"
                  disabled={selectedDevice.requestRunning}
                  onRowClick={(e) => dispatch(inputUpdateHandler(e))}
                  maxHeight={150}
                  specificColumnWidth={['auto', '50px']}
                >
                  <TableHeader attr="Description" key={6}>
                    {t('STRINGS_MODELNAME_HDR')}
                  </TableHeader>
                  <TableHeader attr="Idn" key={7}>
                    {t('STRING_IDN')}
                  </TableHeader>
                </Table>
              </div>
            ) : null}
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };
  return (
    <Grid>
      {filters()}
      <hr />
      {breakerTable()}
    </Grid>
  );
};

export default SelectionTab;
