/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';

import Input from 'components/generic/inputContainer';
import Select from 'components/generic/selectContainer';
import EditableSelect from 'components/generic/editableSelect';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { isMobileOnly } from 'react-device-detect';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { getIkMaxMinOfDevice } from 'devices/utils';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { isProjectFromDocWeb } from 'project/actions';
import { IConfigurationTabCommon } from 'types/devices/commonProperties';
import { CableIbPairs } from 'types/devices/lvCable';
import { Device } from 'types/devices/device';
import { projectCommonData } from '../../constants';

const ConfigurationTabCommon: React.FunctionComponent<IConfigurationTabCommon> = ({
  selectedDevice,
  inputUpdateHandler,
}): React.ReactElement => {
  const standards = useSelector((state) => state.standards);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const generalInfo = (): React.ReactElement => {
    const disabled = true;
    return (
      <>
        <GridRow key={0}>
          <GridColumn col={8}>
            <h4>{t('DOCWEB_COMMONPROPS_HEADER_GENERAL')}</h4>
          </GridColumn>
        </GridRow>
        <GridRow key={1}>
          <GridColumn col={isMobileOnly ? 4 : 3}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'text'}
              onChange={(e) => {
                if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
              }}
              className={'info-project-input'}
              name={'Name'}
              label={'DOCWEB_COMMONPROPS_NAME'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning}
            />
          </GridColumn>
          <GridColumn col={isMobileOnly ? 4 : 3}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'text'}
              onChange={(e) => {
                if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
              }}
              className={'info-project-input'}
              name={'UserDescription1'}
              label={'DOCWEB_COMMONPROPS_DESCRIPTION'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning}
            />
          </GridColumn>
        </GridRow>
        <GridRow key={2}>
          <GridColumn col={isMobileOnly ? 4 : 3}>
            {/* <SwitchboardSelect
              inModal={true}
              deviceId={selectedDeviceId}
              data={selectedDevice}
              isDisabled={selectedDevice.requestRunning}
            /> */}
          </GridColumn>
          <GridColumn col={isMobileOnly ? 4 : 3}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'text'}
              disabled={disabled}
              onChange={(e) => {
                if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
              }}
              // disabled={selectedDevice.requestRunning}
              className={disabled ? 'hidden-configuration-tab' : 'info-project-input'}
              name={'scStandard'}
              label={'DOCWEB_STRING_STANDARD'}
              data={standards}
            />
          </GridColumn>
        </GridRow>
        <GridRow key={3}>
          <GridColumn col={isMobileOnly ? 8 : 2}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'text'}
              disabled={disabled}
              onChange={(e) => {
                if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
              }}
              className={disabled ? 'hidden-configuration-tab' : 'info-project-input'}
              data={standards}
              name={'Symbol'}
              label={'STRINGS_IDC_BLOCK_TXT'}
            />
          </GridColumn>
          <GridColumn col={isMobileOnly ? 4 : 2}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'text'}
              disabled={disabled}
              // disabled={selectedDevice.requestRunning}
              onChange={(e) => {
                if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
              }}
              className={disabled ? 'hidden-configuration-tab' : 'info-project-input'}
              name={'InIbRatio'}
              data={standards}
              label={'DOCWEB_COMMONPROPS_MININIBRATIO'}
            />
          </GridColumn>
          <GridColumn col={isMobileOnly ? 4 : 2} style={isMobileOnly ? undefined : { marginLeft: '10px' }}>
            <Input
              cols={isMobileOnly ? [7, 0, 0, 0] : [6, 0, 0, 0]}
              type={'text'}
              disabled={disabled}
              onChange={(e) => {
                if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
              }}
              // disabled={selectedDevice.requestRunning}
              className={disabled ? 'hidden-configuration-tab' : 'info-project-input'}
              data={standards}
              name={'BreakingCapcity'}
              label={'STRINGS_IDC_INTERPOWER_TXT'}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const values = (): React.ReactElement[] => {
    const { scMaxValue, scMaxValueName, scMin } = getIkMaxMinOfDevice(selectedDevice as Device & CableIbPairs);
    return [
      <GridRow key={0}>
        <GridColumn col={8}>
          <h4>{t('DOCWEB_COMMONPROPS_VALUES')}</h4>
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn className="DOCweb_CommonUX_Select_margin" col={isMobileOnly ? 4 : 1} verticalAlignment={'bottom'}>
          <Select
            cols={[7, 0, 0]}
            className={'info-project-input'}
            name={'LineType'}
            label={''}
            onChange={(e) => {
              if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
            }}
            selectList={projectCommonData.lineType}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={isMobileOnly ? 4 : 1} verticalAlignment={'bottom'}>
          <EditableSelect
            cols={[7, 8, 0]}
            className={'info-project-input'}
            type="number"
            name={'Uref'}
            label={'DOCWEB_DIMENSIONS_V'}
            onChange={(e) => {
              if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
            }}
            selectList={projectCommonData.voltageLow}
            valueList={projectCommonData.voltageLow}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn className="DOCweb_CommonUX_Select_margin" col={isMobileOnly ? 4 : 1} verticalAlignment={'bottom'}>
          <Input
            cols={[7, 0, 0, 0]}
            type={'number'}
            className={'info-project-input'}
            onChange={(e) => {
              if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
            }}
            name={'LoadCurrent'}
            label={'CURVESWEB_CONFIGURATION_TAB_IB'}
            data={selectedDevice}
            // disabled={true}
            disabled={selectedDevice.requestRunning || dispatch(isProjectFromDocWeb())}
          />
        </GridColumn>
        <GridColumn className="DOCweb_CommonUX_Select_margin" col={isMobileOnly ? 4 : 1} verticalAlignment={'bottom'}>
          <Input
            cols={[7, 0, 0, 0]}
            type={'number'}
            className={'info-project-input'}
            onChange={(e) => {
              if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
            }}
            name={scMaxValueName}
            value={scMaxValue?.toString()}
            label={'CURVESWEB_STRING_MAX_SHORTCURCUIT_CURRENT'}
            data={selectedDevice}
            // disabled={true}
            disabled={selectedDevice.requestRunning || dispatch(isProjectFromDocWeb())}
            // cut={UnitsDataAdapter.cutUnits}
            convert={UnitsDataAdapter.convertMilliToBase}
          />
        </GridColumn>
        <GridColumn className="DOCweb_CommonUX_Select_margin" col={isMobileOnly ? 4 : 1} verticalAlignment={'bottom'}>
          <Input
            cols={[7, 0, 0, 0]}
            type={'number'}
            className={'info-project-input'}
            onChange={(e) => {
              if (inputUpdateHandler) dispatch(inputUpdateHandler(e));
            }}
            name={'ScIEC60909LPEIbMin'}
            value={scMin.toString()}
            label={'CURVESWEB_STRING_MIN_SHORTCURCUIT_CURRENT'}
            data={selectedDevice}
            // disabled={true}
            disabled={selectedDevice.requestRunning || dispatch(isProjectFromDocWeb())}
            // cut={UnitsDataAdapter.cutUnits}
            convert={UnitsDataAdapter.convertMilliToBase}
          />
        </GridColumn>
      </GridRow>,
    ];
  };

  if (!selectedDevice) {
    return <></>;
  }
  return (
    <Grid>
      {generalInfo()}
      {(selectedDevice.deviceType as unknown) !== 'app.FUSEMV' &&
      (selectedDevice.deviceType as unknown) !== 'app.CIRCUITBREAKERMV'
        ? values()
        : null}
    </Grid>
  );
};

export default ConfigurationTabCommon;
