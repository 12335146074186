// @ts-nocheck
import React, { FunctionComponent } from 'react';
import Switch from 'components/generic/Switch';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { IRadioDoubleStringCombo } from 'types/curves/components';
import Combo from './Combo';

const RadioDoubleStringCombo: FunctionComponent<IRadioDoubleStringCombo> = ({
  title,
  bEnabled,
  on,
  options,
  options1,
  options2,
  value,
  value1,
  value2,
  onValueChange,
  onValue1Change,
  onValue2Change,
  onOnChange,
  curvesRequestIsRunning,
  editable,
  onFocus,
}): React.ReactElement => {
  return (
    <Grid className="curve-function">
      <GridRow className="curve-function-row">
        <GridColumn col={3}>
          <Switch
            id="ComboDoubleComboCurveId"
            name="ComboDoubleComboCurve"
            textAlign="right"
            disabled={!bEnabled || curvesRequestIsRunning}
            value={on}
            onChange={(event) => onOnChange(event.value)}
            label={title}
          />
        </GridColumn>
      </GridRow>
      <Combo
        width={8}
        // className="curve-function-row"
        on={on}
        options={options}
        value={value}
        onChange={onValueChange}
        onFocus={onFocus}
        isString
        curvesRequestIsRunning={curvesRequestIsRunning}
        title={`${title}0`}
      />

      <GridRow className="curve-function-row">
        <GridColumn col={4}>
          <Combo
            width={8}
            title={`${title}1`}
            on={on}
            options={options1}
            value={value1}
            onChange={onValue1Change}
            onFocus={onFocus}
            isString={false}
            curvesRequestIsRunning={curvesRequestIsRunning}
            editable
          />
        </GridColumn>
        <GridColumn col={4}>
          <Combo
            width={8}
            title={`${title}2`}
            on={on}
            options={options2}
            value={value2}
            onChange={onValue2Change}
            onFocus={onFocus}
            isString
            curvesRequestIsRunning={curvesRequestIsRunning}
            editable={editable}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default RadioDoubleStringCombo;
