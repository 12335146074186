/* eslint-disable consistent-return */
import CurveTypes from 'curves/curveType';
import { TDeviceEvent } from 'types/devices/device';
import { ThunkAction } from 'types/known-actions';
import _, { cloneDeep } from 'lodash';
import { uuid } from 'actions/utils';
import { utils, writeFile, read } from 'xlsx';
import { pushNotification } from 'compute/actions';
import { Result } from 'react-file-reader-input';
import { fileTypeValidation, toJson } from 'project/excelImport';
import { ECustomCurveOption } from 'types/curves/enums.d';
import UserCurvesAPI from 'api/userCurves';
import { ICustomCurve } from 'types/curves';
import { curvesActions } from 'curves/actions';
import { changeTab } from '../actions';

export const updatePoint =
  (
    event: TDeviceEvent,
    point: 'x' | 'y',
    selectedDeviceId: string,
    diagramName: CurveTypes,
    pointIndex: number
  ): ThunkAction<void> =>
  (dispatch, getState) => {
    const { curves } = getState();
    const customCurves = _.cloneDeep(curves.customCurves);
    if (!customCurves) return;
    customCurves.devices[selectedDeviceId].curves[diagramName].points[pointIndex][point] = parseFloat(event.value as string);
    customCurves.devices[selectedDeviceId].selectedDiagram = diagramName;

    dispatch(curvesActions.setCustomCurves({ customCurves }));
  };

export const removeDevice =
  (deviceId: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const { customCurves } = _.cloneDeep(getState().curves);
    if (!customCurves) return;
    delete customCurves.devices[deviceId];
    dispatch(curvesActions.setCustomCurves({ customCurves }));
    dispatch(saveUserCurves());
  };

export const editDevice =
  (id: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const { customCurves } = _.cloneDeep(getState().curves);
    if (!customCurves) return;
    customCurves.selectedDeviceId = id;
    dispatch(curvesActions.setCustomCurves({ customCurves }));
  };

export const saveDevice =
  (deviceId: string | undefined, device: ICustomCurve, deviceName: string, voltage: number): ThunkAction<void> =>
  (dispatch, getState) => {
    if (!deviceId) return;
    const { curves } = getState();
    const customCurves = _.cloneDeep(curves.customCurves);
    if (!customCurves) return;
    const deviceCopy = _.cloneDeep(device);
    deviceCopy.curves[device.selectedDiagram].points = [];
    device.curves[device.selectedDiagram].points.forEach((point) => {
      if ((point.x || point.x === 0) && (point.y || point.y === 0)) {
        deviceCopy.curves[device.selectedDiagram].points.push(point);
      }
    });
    customCurves.devices[deviceId === 'new' ? uuid() : deviceId] = {
      ...deviceCopy,
      name: deviceName,
      voltage,
      selectedDiagram: '',
    } as ICustomCurve;
    customCurves.selectedDeviceId = '';
    dispatch(
      curvesActions.setCustomCurves({
        customCurves,
      })
    );
    dispatch(saveUserCurves());
  };

export const duplicateDevice =
  (deviceId: string): ThunkAction<void> =>
  (dispatch, getState) => {
    if (!deviceId) return;
    const customCurves = _.cloneDeep(getState().curves.customCurves);
    if (!customCurves) return;
    const deviceToCopy = customCurves.devices[deviceId];
    const newDeviceName = `${deviceToCopy.name} copy`;
    customCurves.devices[uuid()] = { ...deviceToCopy, name: newDeviceName };
    dispatch(
      curvesActions.setCustomCurves({
        customCurves,
      })
    );
    dispatch(saveUserCurves());
  };

export const fileReaderImportExcelFunction =
  (
    rABS: boolean,
    file: ProgressEvent<FileReader>,
    afterCheckFunction: (importedEntries: Record<string, Array<Record<string, unknown>>>) => ICustomCurve,
    range?: number
  ): ThunkAction<ICustomCurve | undefined> =>
  (dispatch) => {
    let data = file.target?.result;
    if (!rABS && data) {
      data = new Uint8Array(data as ArrayBufferLike);
    }
    let workbook;
    try {
      // if XLSX gets renamed .zip archive - it will crash... anyway if it's crashed probably provided file is not valid
      workbook = read(data, { type: rABS ? 'binary' : 'array' }); // read data from excelFile
    } catch (err) {
      console.warn('sheetJs.XLSX.read crash');
      // dispatch(updateElementParams(elementId, { importError: 'File is not a valid excel file or damaged' }, true));
      setTimeout(() => {
        // dispatch(updateElementParams(elementId, { importError: '' }, true));
      }, 5000);
      return undefined;
    }
    // check if provided file is a valid workbook (excel file produces far more complex object that most of other files)
    // if XLSX output objject with only 'SheetNames' and 'Sheets' - it's in most cases not valid excel file
    if (!workbook.Workbook) {
      console.warn('imported file have different structure than a valid excel document');
      dispatch(pushNotification('alarm', 'imported file have different structure than a valid excel document'));
      // dispatch(updateElementParams(elementId, { importError: 'File is not a valid excel file or damaged' }, true));
      return undefined;
    }
    const importedEntries = toJson(workbook, range, true) as Record<string, Array<Record<string, unknown>>>; // convert data to JSON
    if (!importedEntries.DeviceData) {
      // providing empty excel file will result in file be readen, but sheets will be empty objects.
      // dispatch(updateElementParams(elementId, { importError: 'File is empty or damaged' }, true));
      dispatch(pushNotification('alarm', 'File is empty or damaged'));
      // setTimeout(() => {
      //   dispatch(updateElementParams(elementId, { importError: '' }, true));
      // }, 5000);
      return undefined;
    }
    return afterCheckFunction(importedEntries);
    // return undefined;
  };

const checkEntries = (importedEntries: Record<string, Record<string, unknown>[]>): string | undefined => {
  let message;
  Object.keys(importedEntries).forEach((entryName) => {
    if (entryName === 'DeviceData') {
      if (!importedEntries[entryName] || importedEntries[entryName].length === 0) {
        message = 'DOCWEB_CUSTOMCURVESEXCELCHECK_NODEVICEDATA';
      }
      ['Name', 'Type', 'Un'].forEach((paramName) => {
        if (paramName === 'Type') {
          const paramValue = importedEntries[entryName][0][paramName] as string;
          if (typeof paramValue !== 'string') {
            message = 'DOCWEB_EXCELCHECK_WRONGNAMEPROVIDED';
          }

          if (!['CURVE', 'AREA (UP)', 'AREA (DOWN)'].includes(paramValue.toUpperCase())) {
            message = 'DOCWEB_EXCEL_INVALIDCURVETYPEPROVIDED';
          }
        }
        if (paramName === 'Un') {
          const param = importedEntries[entryName][0][paramName];
          if (typeof param !== 'number' || param <= 0) {
            message = 'DOCWEB_EXCEL_INVALIDVOLTAGEPROVIDED';
          }
        }
      });
    }
  });

  return message;
};

const curveToCurveType = {
  curve: ECustomCurveOption.Curve,
  'area (up)': ECustomCurveOption.AreaUp,
  'area (down)': ECustomCurveOption.AreaLow,
};

const processData = (importedEntries: Record<string, Record<string, unknown>[]>): ICustomCurve => {
  const device: ICustomCurve = {} as ICustomCurve;
  // console.log(importedEntries);
  device.name = importedEntries.DeviceData[0].Name as string;
  device.option = curveToCurveType[(importedEntries.DeviceData[0].Type as string).toLowerCase() as keyof typeof curveToCurveType];
  device.voltage = importedEntries.DeviceData[0].Un as number;

  device.curves = {
    EFFF: { points: [] as Array<{ x: number; y: number }> },
    EFN: { points: [] as Array<{ x: number; y: number }> },
    TFFF: { points: [] as Array<{ x: number; y: number }> },
    TFN: { points: [] as Array<{ x: number; y: number }> },
    PEAK: { points: [] as Array<{ x: number; y: number }> },
  };

  importedEntries['LetThroughEnergy Live']?.forEach((point) => {
    device.curves.EFFF.points.push({ x: point.ShortCircuitCurrent as number, y: point.LetThroughEnergy as number });
  });
  importedEntries['LetThroughEnergy Neutral']?.forEach((point) => {
    device.curves.EFN.points.push({ x: point.ShortCircuitCurrent as number, y: point.LetThroughEnergy as number });
  });
  importedEntries['Time-Current Live']?.forEach((point) => {
    device.curves.TFFF.points.push({ x: point.Current as number, y: point.Time as number });
  });
  importedEntries['Time-Current Neutral']?.forEach((point) => {
    device.curves.TFN.points.push({ x: point.Current as number, y: point.Time as number });
  });
  importedEntries.Peak?.forEach((point) => {
    device.curves.PEAK.points.push({ x: point.ShortCircuitCurrent as number, y: point.PeakCurrent as number });
  });

  return device as unknown as ICustomCurve;
};

export const excelHandler =
  (
    e: React.ChangeEvent<HTMLInputElement>,
    results: Array<Result>,
    setDeviceState: React.Dispatch<React.SetStateAction<ICustomCurve>>,
    setVoltage: React.Dispatch<React.SetStateAction<number>>,
    setDevicename: React.Dispatch<React.SetStateAction<string>>
  ): ThunkAction<ICustomCurve> =>
  (dispatch) => {
    const rABS = true; // read as binary string
    const file = results[0][1]; // file is in second array of result
    const validation = fileTypeValidation(file);

    const afterCheckFunction = (importedEntries: Record<string, Array<Record<string, unknown>>>): ICustomCurve => {
      const message = checkEntries(importedEntries);
      if (message) {
        dispatch(pushNotification('alarm', message));
        return {} as unknown as ICustomCurve;
      }
      return processData(importedEntries);
    };
    if (validation.isValid) {
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = function (data) {
        const importedDeviceState = dispatch(fileReaderImportExcelFunction(rABS, data, afterCheckFunction, 1));
        if (!importedDeviceState) {
          pushNotification('alarm', 'DOCWEB_NOTIFICATION_NODATAINSIDEFILE');
          return;
        }
        pushNotification('success', 'DOCWEB_CUSTOMCURVE_IMPORTSUCCESS');
        setDeviceState(importedDeviceState);
        setDevicename(importedDeviceState.name);
        setVoltage(importedDeviceState.voltage);
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } else {
      let message;
      if (validation.error === 'size') {
        message = 'File size cannot exceed 10MB';
      } else if (validation.error === 'type') {
        message = 'Wrong FileFormat';
      }
      if (message) dispatch(pushNotification('alarm', message));
    }
    return {} as unknown as ICustomCurve;
  };

export const getUserCurves = (): ThunkAction<void> => (dispatch, getState) => {
  UserCurvesAPI.getUserCurves(getState().user.userInternalId)
    .then((data) => {
      if (data) {
        const currentDevices = getState().curves.customCurves.devices;
        const devices = JSON.parse(data.curvesData) as Record<string, ICustomCurve>;
        if (Object.keys(currentDevices).length > 0) {
          Object.keys(currentDevices).forEach((devId) => {
            if (devices[devId]) {
              devices[devId].checked = currentDevices[devId].checked;
            }
          });
        }
        dispatch(
          curvesActions.setCustomCurves({
            customCurves: {
              devices,
              selectedDeviceId: '',
            },
          })
        );
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch(pushNotification('alarm', 'DOCWEB_CUSTOM_CURVES_GET_FAILURE'));
    });
};

export const saveUserCurves = (): ThunkAction<void> => (dispatch, getState) => {
  const state = getState();
  const customCurvesDevices = cloneDeep(state.curves.customCurves?.devices);
  Object.keys(customCurvesDevices).forEach((customId) => {
    customCurvesDevices[customId].checked = false;
  });
  const requestBody = {
    userId: state.user.userInternalId,
    curvesData: JSON.stringify(customCurvesDevices),
  };
  return UserCurvesAPI.saveNewCurves(requestBody)
    .then((response) => {
      dispatch(pushNotification('success', 'DOCWEB_CUSTOM_CURVES_SAVED_SUCCESS'));
      dispatch(changeTab('STRINGS_CURVES_MODAL_CUSTOM_TAB', false));
    })
    .catch((err) => {
      dispatch(pushNotification('alarm', 'DOCWEB_CUSTOM_CURVES_SAVED_FAILURE'));
      console.error(err);
    });
};

export const exportExcelDevice = (deviceState: ICustomCurve): void => {
  const headerDeviceArray = [
    ['Device Name', 'Rated Voltage Un [V]', 'Curve Type:\nCurve,\nArea (up),\nArea (down)'],
    ['Name', 'Un', 'Type'],
  ];
  const deviceDataSheet = utils.aoa_to_sheet(headerDeviceArray);

  deviceDataSheet['!cols'] = [
    { wch: 11, level: 1 },
    { wch: 21, level: 1 },
    { wch: 11, level: 1 },
  ];

  deviceDataSheet['!rows'] = [{ hpt: 69, level: 1 }];

  const deviceDataArray = [[deviceState.name, deviceState.voltage, deviceState.option]];
  utils.sheet_add_aoa(deviceDataSheet, deviceDataArray, { origin: -1 });

  const timeCurrentHeader = [
    ['Current [A]', 'Tripping Time [s]'],
    ['Current', 'Time'],
  ];

  const timeCurrentLiveSheet = utils.aoa_to_sheet(timeCurrentHeader);
  const timeCurrentNeutralSheet = utils.aoa_to_sheet(timeCurrentHeader);

  timeCurrentLiveSheet['!cols'] = [
    { wch: 11, level: 1 },
    { wch: 17, level: 1 },
  ];

  timeCurrentLiveSheet['!rows'] = [{ hpt: 69, level: 1 }];

  timeCurrentNeutralSheet['!cols'] = [
    { wch: 11, level: 1 },
    { wch: 17, level: 1 },
  ];

  timeCurrentNeutralSheet['!rows'] = [{ hpt: 69, level: 1 }];

  const point2arrayCallback = (point: { x: number; y: number }) => {
    if (!point) return [];
    return [point.x, point.y];
  };

  if (deviceState.curves.TFFF) {
    utils.sheet_add_aoa(timeCurrentLiveSheet, deviceState.curves.TFFF.points.map(point2arrayCallback), { origin: -1 });
  }
  if (deviceState.curves.TFN) {
    utils.sheet_add_aoa(timeCurrentNeutralSheet, deviceState.curves.TFN.points.map(point2arrayCallback), { origin: -1 });
  }
  const letThroughEnergyHeader = [
    ['Fault Current [kA]', 'Let-through Energy [MA2s]'],
    ['ShortCircuitCurrent', 'LetThroughEnergy'],
  ];
  const letThroughtLiveSheet = utils.aoa_to_sheet(letThroughEnergyHeader);
  const letThroughNeutralSheet = utils.aoa_to_sheet(letThroughEnergyHeader);

  letThroughtLiveSheet['!cols'] = [
    { wch: 18, level: 1 },
    { wch: 24, level: 1 },
  ];

  letThroughtLiveSheet['!rows'] = [{ hpt: 69, level: 1 }];

  letThroughNeutralSheet['!cols'] = [
    { wch: 18, level: 1 },
    { wch: 24, level: 1 },
  ];

  letThroughNeutralSheet['!rows'] = [{ hpt: 69, level: 1 }];

  if (deviceState.curves.EFFF) {
    utils.sheet_add_aoa(letThroughtLiveSheet, deviceState.curves.EFFF.points.map(point2arrayCallback), { origin: -1 });
  }
  if (deviceState.curves.EFN) {
    utils.sheet_add_aoa(letThroughNeutralSheet, deviceState.curves.EFN.points.map(point2arrayCallback), { origin: -1 });
  }

  const peakHeader = [
    ['Fault Current [kA]', 'Limited peak Current [kA]'],
    ['ShortCircuitCurrent', 'PeakCurrent'],
  ];

  const peakSheet = utils.aoa_to_sheet(peakHeader);

  peakSheet['!cols'] = [
    { wch: 18, level: 1 },
    { wch: 25, level: 1 },
  ];

  peakSheet['!rows'] = [{ hpt: 69, level: 1 }];

  if (deviceState.curves.PEAK) {
    utils.sheet_add_aoa(peakSheet, deviceState.curves.PEAK.points.map(point2arrayCallback), { origin: -1 });
  }

  const worksheet = {
    SheetNames: [
      'DeviceData',
      'Time-Current Live',
      'Time-Current Neutral',
      'LetThroughEnergy Live',
      'LetThroughEnergy Neutral',
      'Peak',
    ],
    Sheets: {
      DeviceData: deviceDataSheet,
      'Time-Current Live': timeCurrentLiveSheet,
      'Time-Current Neutral': timeCurrentNeutralSheet,
      'LetThroughEnergy Live': letThroughtLiveSheet,
      'LetThroughEnergy Neutral': letThroughNeutralSheet,
      Peak: peakSheet,
    },
  };
  writeFile(worksheet, `${deviceState.name} ${deviceState.voltage} [V].xlsx`);
};
