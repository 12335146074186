/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { produce } from 'immer';
import { CurvesKnownActions } from 'types/known-actions';
import * as CurvesNamespace from 'types/curves';
import { CURVES, PROJECT } from 'types/constants.d';
import { ECurvesFilters } from 'types/curves/enums.d';

export const initialState: CurvesNamespace.State = {
  curves: [],
  params: {},
  curve2Show: [],
  customCurves: {
    selectedDeviceId: '',
    devices: {},
  },
  curvesControl: {
    selectedFilter: ECurvesFilters.All,
    curvesList: [],
  },
  checkedCurves: [],

  selectedCurveId: '',
  curvesRequestRunning: false,
  coordinationRequestRunning: false,
  curvesRequestCounter: 0,
};

// TODO: assign proper type to action
export const reducer = (state = initialState, action: CurvesKnownActions): CurvesNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case PROJECT.LOAD_PROJECT:
        return initialState;
      case CURVES.SET_CURVES_REQUEST_RUNNING:
        draft.curvesRequestRunning = action.payload.running;
        draft.curvesRequestCounter = action.payload.counter ? action.payload.counter : state.curvesRequestCounter;
        break;
      case CURVES.SET_CURRENT_CURVES:
        return { ...state, ...action.payload };
      case CURVES.SET_CUSTOM_CURVES:
        return { ...state, ...action.payload };
      case CURVES.SET_CURVES_CONTROL:
        return {
          ...state,
          curvesControl: {
            selectedFilter: action.payload.selectedFilter,
            curvesList: action.payload.curvesList ? action.payload.curvesList : state.curvesControl.curvesList,
          },
        };
      case CURVES.SET_SELECTED_CURVE:
        return { ...state, selectedCurveId: action.payload.curveId };
      case CURVES.SET_CHECKED_CURVES:
        return { ...state, checkedCurves: action.payload.checkedCurves };
      default:
        return draft;
    }
  });
