import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  objectType: EDeviceObjectType.MVTypicalUnit,
  deviceType: EDeviceType.FEEDER,
  Locked: false,

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  AutomaticSelection: true,
  mvBreaker: [],
  mvDisconnector: [],
  mvFuse: [],
  currentTransformer: [],
  homopolarTransformer: [],
  tripUnit: [],
  voltageTransformer: [],
  trafoEnclose: [],
  selection: [],
};
