/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { IBreakerFilters } from 'types/deviceFilters';
import { ThunkAction } from 'types/known-actions';
import _ from 'lodash';
import { updateElementParams } from 'devices/actions';
import { EDeviceType, EPoles } from 'types/devices/enums.d';
import { Device } from 'types/devices/device';
import { IRelationPropagate } from 'types/devices/protectedObjects';
import { getFilterKeys } from './constants';
import { polesTable } from '../constants';
import { manageAllPossibilities, managePreferredPoles, idnNotPresentInList, getIdnList, mergeFilter } from './utils';

export const manageFilters =
  (device: Device<Record<string, unknown>>): ThunkAction<void> =>
  (dispatch, getState) => {
    const filters = getState().deviceFilters[device.deviceType];
    if (filters && device) {
      const newDeviceFilters: Record<string, Array<string> | string | number> = {};
      const { filterKeys, filterListKeys } = getFilterKeys(device.deviceType);
      let filter: IBreakerFilters | Array<string>;
      if (
        [EDeviceType.RCCB, EDeviceType.DISCONNECTORLV, EDeviceType.CONTACTOR, EDeviceType.OLR, EDeviceType.FUSEBASE].includes(
          device.deviceType
        )
      ) {
        filter = filters;
      } else {
        filter = filters[device.TypeFilter as string];
      }
      filterKeys.forEach((filterKey, index) => {
        if (
          filterKey === 'PolesFilter' &&
          device.deviceType !== EDeviceType.RCCB &&
          device.deviceType !== EDeviceType.CONTACTOR &&
          device.deviceType !== EDeviceType.OLR
        ) {
          const availablePoles = polesTable[device.LineType][device.EarthingSystem];
          let newFilter: IBreakerFilters | Array<string> = {};
          if (Array.isArray(filter)) {
            filter.forEach((pole, index2) => {
              if (index2 === 0) {
                newFilter = [];
              }
              if (availablePoles.indexOf(pole as EPoles) !== -1 && (newFilter as Array<string>).indexOf(pole as EPoles) === -1) {
                (newFilter as Array<string>).push(pole);
              }
            });
          } else {
            Object.keys(filter).forEach((fk) => {
              if (availablePoles.indexOf(fk as EPoles) !== -1) {
                if (!Array.isArray(filter) && !Array.isArray(newFilter)) {
                  newFilter[fk] = filter[fk];
                }
              }
            });
          }
          filter = newFilter;
        }
        filter = collectFilter(filter, device, index, newDeviceFilters, filterKeys, filterListKeys);
        if (newDeviceFilters[filterListKeys[index]] && filterKey !== 'PolesFilter') {
          manageAllPossibilities(newDeviceFilters, filterKeys, filterListKeys, index);
        } else if (filterKey === 'PolesFilter' && device.isFreshlyInserted) {
          managePreferredPoles(device, getState(), newDeviceFilters);
        }
      });
      // Manage coordination filter list
      if (device.AvailableDiscrimination || device.AvailableBackup) {
        newDeviceFilters.CoordinationFilterList = _.cloneDeep(device.CoordinationFilterList as Array<string>);
        if (
          device.AvailableDiscrimination &&
          (device.AvailableDiscrimination as { Relation: Array<IRelationPropagate> }).Relation &&
          (device.AvailableDiscrimination as { Relation: Array<IRelationPropagate> }).Relation.length > 0 &&
          !newDeviceFilters.CoordinationFilterList.includes('Selectivity')
        ) {
          newDeviceFilters.CoordinationFilterList.push('Selectivity');
        }
        if (
          device.AvailableBackup &&
          (device.AvailableBackup as { Relation: Array<IRelationPropagate> }).Relation &&
          (device.AvailableBackup as { Relation: Array<IRelationPropagate> }).Relation.length > 0 &&
          !newDeviceFilters.CoordinationFilterList.includes('Back-up')
        ) {
          newDeviceFilters.CoordinationFilterList.push('Back-up');
        }
      }
      // console.log(newDeviceFilters);
      dispatch(updateElementParams(device.json.id, { ...newDeviceFilters, requestRunning: false, initialized: true }, true));
    }
  };

const collectFilter = (
  filter: IBreakerFilters | Array<string>,
  device: Device<Record<string, unknown>>,
  filterIndex: number,
  newDeviceFilters: Record<string, Array<string | number> | string | number>,
  filterKeys: Array<string>,
  filterListKeys: Array<string>
): IBreakerFilters | Array<string> => {
  // Last depth is Array
  if (Array.isArray(filter)) {
    if (filterKeys[filterIndex] === 'IdnFilter') {
      filter = _.union(...filter.map((l) => l.split(';').map((k) => parseFloat(k)))) as unknown as Array<string>;
    }
    newDeviceFilters[filterListKeys[filterIndex]] = filter;
    if (filterKeys[filterIndex] === 'IdnFilter') {
      if (idnNotPresentInList(newDeviceFilters[filterListKeys[filterIndex]], device[filterKeys[filterIndex]])) {
        newDeviceFilters[filterKeys[filterIndex]] = 'All possibilities';
      }
    } else if (
      filter.indexOf(device[filterKeys[filterIndex]] as string) === -1 &&
      device[filterKeys[filterIndex]] !== 'All possibilities'
    ) {
      newDeviceFilters[filterKeys[filterIndex]] = filterKeys[filterIndex] === 'PolesFilter' ? filter[0] : 'All possibilities';
    }
    return filter;
  }
  // No filter for this kind of device
  if (Object.keys(filter)[0] === '') {
    return filter[Object.keys(filter)[0]];
  }

  if (filterKeys[filterIndex] === 'IdnFilter') {
    newDeviceFilters[filterListKeys[filterIndex]] = getIdnList(filter);
    if (
      device[filterKeys[filterIndex]] &&
      device[filterKeys[filterIndex]] !== -1 &&
      device[filterKeys[filterIndex]] !== 'All possibilities' &&
      idnNotPresentInList(newDeviceFilters[filterListKeys[filterIndex]], device[filterKeys[filterIndex]])
    ) {
      newDeviceFilters[filterKeys[filterIndex]] = 'All possibilities';
    }
    return mergeFilter(filter);
  }
  if (
    device[filterKeys[filterIndex]] === 'All possibilities' ||
    device[filterKeys[filterIndex]] === -1 ||
    device[filterKeys[filterIndex]] === '' ||
    (!filter[device[filterKeys[filterIndex]] as string] && filterKeys[filterIndex] !== 'PolesFilter')
  ) {
    newDeviceFilters[filterListKeys[filterIndex]] = Array.isArray(filter) ? filter : Object.keys(filter);
    newDeviceFilters[filterKeys[filterIndex]] =
      filterKeys[filterIndex] === 'PolesFilter' ? Object.keys(filter)[0] : 'All possibilities';
    if (Array.isArray(filter[Object.keys(filter)[0]])) {
      const arrayFilters = Object.keys(filter).map((f) => (filter as IBreakerFilters)[f]) as unknown as Array<string>;
      return _.union(...arrayFilters);
    }
    return mergeFilter(filter);
  }
  newDeviceFilters[filterListKeys[filterIndex]] = Object.keys(filter);
  if (filter[device[filterKeys[filterIndex]] as string]) {
    return filter[device[filterKeys[filterIndex]] as string];
  }
  newDeviceFilters[filterKeys[filterIndex]] = Object.keys(filter)[0];
  return filter[Object.keys(filter)[0]];
};
