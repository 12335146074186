// import {initialState} from 'reducers/variables';
import { initialState } from 'store/variables/store';
import VariablesAPI from 'api/variables';
import { VariablesActions } from 'store/variables/actions';
import { Variables } from 'types/variables/store';
import { ThunkAction } from 'types/known-actions';
import { pushNotification } from 'compute/actions';
import _ from 'lodash';

export const SET_SC_STANDARD = 'SET_SC_STANDARD';
export const SET_CABLE_STANDARD = 'SET_CABLE_STANDARD';
export const SET_VARIABLES = 'SET_VARIABLES';

export const setScenario =
  (newScenarios: Variables.State['Scenarios']): ThunkAction<void> =>
  (dispatch) => {
    const payload = { Scenarios: { ...newScenarios } };
    dispatch(VariablesActions.setVariables(payload));
  };

export const getDefaultPropertyValue = (
  propertyName: keyof Variables.State
): Variables.State[keyof Variables.State] | undefined => {
  if (initialState[propertyName]) {
    return initialState[propertyName];
  }
  const tripDefaultValue = initialState.IndirectTouchTripTimes.time.find((time) => time['@'].id === propertyName);
  if (tripDefaultValue) {
    return tripDefaultValue['#'];
  }
  const scTime = Object.keys(initialState.ShortCircuitTimesObject).find(
    (time) => time === propertyName
  ) as keyof typeof initialState.ShortCircuitTimesObject;
  if (scTime) {
    return initialState.ShortCircuitTimesObject[scTime].value;
  }
  // TODO: inputValidation check this function
  return undefined;
};

export const getObsoleteFamilies =
  (initial: boolean): ThunkAction<void> =>
  (dispatch, getState) => {
    VariablesAPI.getObsoleteFamilies()
      .then((data) => {
        dispatch(
          VariablesActions.setVariables({
            ObsoleteFamilies: {
              name: data.jsonOutput.obsolette,
            },
            AllProductsFamilies: { ...data.jsonOutput },
          })
        );
        if (initial === true) {
          const disabledProductFamilies = _.cloneDeep(getState().variables.DisabledProductFamilies);
          data.jsonOutput.obsolette.forEach((item) => {
            if (disabledProductFamilies.name.indexOf(item) === -1) {
              disabledProductFamilies.name.push(item);
            }
          });
          dispatch(VariablesActions.setVariables({ DisabledProductFamilies: { ...disabledProductFamilies } }));
        }
      })
      .catch(() => dispatch(pushNotification('alarm', 'DOCWEB_ERROR_GETFAMILIESERROR')));
  };
