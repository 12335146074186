class FieldsValidation {
  static validateRange(lowerValue: number, higherValue: number, value: number | string, name: string): string | undefined {
    if (
      (!value && value !== 0) ||
      (value as number) < lowerValue ||
      (value as number) > higherValue ||
      !isNumber(value as string) ||
      isEmptyString(value as string)
    ) {
      return `${name} should be between ${lowerValue} & ${higherValue}`;
    }
    return undefined;
  }

  static validateRangeWithExatLower(
    lowerValue: number,
    higherValue: number,
    value: number | string,
    name: string
  ): string | undefined {
    if (
      (!value && value !== 0) ||
      (value as number) <= lowerValue ||
      (value as number) > higherValue ||
      !isNumber(value as string) ||
      isEmptyString(value as string)
    ) {
      return `${name} should be higher than ${lowerValue} & lower or equal to ${higherValue}`;
    }
    return undefined;
  }

  static validateExactRange(lowerValue: number, higherValue: number, value: number | string, name: string): string | undefined {
    if (
      !value ||
      (value as number) <= lowerValue ||
      (value as number) >= higherValue ||
      !isNumber(value as string) ||
      isEmptyString(value as string)
    ) {
      return `${name} should be between ${lowerValue} and ${higherValue}. ${name} should not reach points ${lowerValue} and ${higherValue}`;
    }
    return undefined;
  }

  static validateHigherValue(higherValue: number, value: number | string, name: string): string | undefined {
    if (!value || (value as number) > higherValue || !isNumber(value as string) || isEmptyString(value as string)) {
      return `${name} 'should be lower than' ${higherValue}`;
    }
    return undefined;
  }

  static validateLowerValueWithSpecifiedText(lowerValue: number, value: number | string, text: string): string | undefined {
    if (!value || (value as number) < lowerValue || !isNumber(value as string) || isEmptyString(value as string)) {
      return `${text}`;
    }
    return undefined;
  }

  static validateLowerValue(lowerValue: number, value: number | string, name: string): string | undefined {
    if (!value || !isNumber(value as string) || (value as number) < lowerValue || isEmptyString(value as string)) {
      return `${name} should be greater or equal to ${lowerValue}`;
    }
    return undefined;
  }

  static validateExactLowerValue(lowerValue: number, value: number | string, name: string): string | undefined {
    if (!value || (value as number) <= lowerValue || !isNumber(value as string) || isEmptyString(value as string)) {
      return `${name} should be greater than ${lowerValue}`;
    }
    return undefined;
  }

  static validateIntegerTypeField(value: number | string, name: string): string | undefined {
    if (!isNumber(value as string) || !Number.isInteger(value) || isEmptyString(value as string)) {
      return `${name} should be integer`;
    }
    return undefined;
  }

  static validateInputIsNotNan(value: number | string, name: string): string | undefined {
    if (Number.isNaN(value)) {
      return `${name} 'should be number}`;
    }
    return undefined;
  }

  static validateEmptyField(string: string, name: string): string | undefined {
    if (string === '') {
      return `${name} should not be empty`;
    }
    return undefined;
  }

  static validateAlreadyExisting(bool: boolean, name: string): string | undefined {
    if (!bool) {
      return `${name} already exists`;
    }
    return undefined;
  }

  static validateUniqueValue(values: Array<string>, value: string): string | undefined {
    if (values.some((val) => val === value)) {
      return this.validateAlreadyExisting(false, value);
    }
    return undefined;
  }

  static validateForValidNumber(value: number | string): string | undefined {
    if (value || value === 0) {
      if (Number.isNaN(Number(value))) {
        return 'Entered Value is not a valid number';
      }
    } else {
      return 'Entered Value is not a valid number';
    }
    return undefined;
  }
}

const isNumber = (value: string): boolean => {
  if (Number(value) === Infinity || Number.isNaN(value)) {
    return false;
  }
  return Number(value) === parseFloat(value);
};

const isEmptyString = (value: string): boolean => {
  if (typeof value === 'string') {
    return value.trim() === '';
  }
  return false;
};
export default FieldsValidation;
