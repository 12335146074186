import ApiCalls from './ApiCalls';

export default class UserCurvesAPI {
  static async getUserCurves(userId: number): Promise<IUserCurves> {
    const { data } = await ApiCalls.get<IUserCurves>(`UserCurves/UserCurvesController/GetUserCurves/${userId}`);
    return data;
  }

  static async saveNewCurves(requestBody: IUserCurves): Promise<void | string> {
    await ApiCalls.post<void, IUserCurves>(`UserCurves/UserCurvesController/SaveUserCurves`, requestBody);
  }
}

interface IUserCurves {
  userId: number;
  curvesData: string;
}
