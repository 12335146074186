/* eslint-disable @typescript-eslint/unbound-method */
import React, { FunctionComponent } from 'react';
import { Slider } from '@abb/abb-common-ux-react';
import { correctTheValue } from 'curves/actions/functionValues';
import Switch from 'components/generic/Switch';
import Input from 'components/generic/inputContainer';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { IRange } from 'types/curves/range';

const Range: FunctionComponent<IRange> = ({
  title,
  bEnabled,
  on,
  min,
  max,
  value,
  onValueChange,
  onFocusOut,
  onFocus,
  onSliderChange,
  curvesRequestIsRunning,
}): React.ReactElement => {
  const getProperValues = (propMin: number, propMax: number) => {
    const minIsFloat = typeof propMin === 'number' && !Number.isInteger(propMin);
    const maxIsFloat = typeof propMax === 'number' && !Number.isInteger(propMax);
    const minimum = minIsFloat ? Math.floor(propMin) : propMin;
    const maximum = maxIsFloat ? Math.ceil(propMax) : propMax;
    return {
      minimum,
      maximum,
    };
  };

  const stepCalculator = (maximum: number, minimum: number): number => {
    return ((maximum - minimum) * 5) / 100;
  };

  // TODO should be checked that the enabled condition is exactly as below?
  const enabled = min !== max && !curvesRequestIsRunning;
  const { minimum, maximum } = getProperValues(min, max);
  const newValue = typeof value !== 'number' ? value.value : value;
  return (
    <Grid>
      <GridRow>
        <GridColumn col={8}>
          <Switch
            disabled={!bEnabled || curvesRequestIsRunning}
            value={on}
            textAlign="right"
            label={`${title}`}
            className="range-switch"
            name={title}
            onChange={() => {}}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={8}>
          <Slider
            showTooltip="never"
            disabled={!enabled}
            min={minimum}
            max={maximum}
            value={correctTheValue(newValue, min, max)}
            onChange={onSliderChange}
            step={0.5}
            highlight="low"
            type="single"
          />
        </GridColumn>
      </GridRow>
      <GridRow horizontalAlignment="center" style={{ marginTop: '0px', marginBottom: '5px' }}>
        <GridColumn col={3}>
          <Input
            cols={[0, 0, 0]}
            name="CurveRange"
            disabled={!enabled}
            type="number"
            step={stepCalculator(max, min)}
            value={newValue.toString()}
            min={min}
            max={max}
            onFocus={(event) => onFocus(event)}
            onFocusOut={(event) => {
              const correctedValue = correctTheValue(parseFloat(event.value), min, max);
              onFocusOut(correctedValue);
            }}
            onChange={(event) => {
              onValueChange(parseFloat(event.value));
            }}
            cut={UnitsDataAdapter.cutUnits}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default Range;
