/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-properties */
import React, { useState, FunctionComponent } from 'react';

import { visibleDiagrams, deviceHasCurves } from 'curves/Constants';
import CurveTypeIndex from 'curves/curveType/CurveTypeIndex';
import {
  curvesActions,
  getDeviceCurves,
  setCurveLabelText,
  setCurveLabelTextChecked,
  setCurveCurrentChecked,
} from 'curves/actions';
import { updateElementParams } from 'devices/actions';
import translations from 'translations/DOC-en';
import { TabControl, TabItem } from '@abb/abb-common-ux-react';

import { Device } from 'types/devices/device';
import { EDeviceObjectType, EDeviceType } from 'types/devices/enums.d';
import { ICurveDiagram, ILabelChecks } from 'types/curves';
import { useDispatch, useSelector } from 'store';
import { IGenericCurveFunction } from 'types/curves/functions';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Functions from 'curves/actions/Functions';
import { getCurrentCheckValue } from 'curves/actions/functionValues';
import { getCurrentUnitValue, getCurveLabelText } from 'curves/actions/utils';
import { getObjectSign } from 'store/devices/utils';
import { isFeeder, getSelectedId } from 'actions/utils';
import LabelInput from './LabelInput';

const CurvesToolbar: FunctionComponent<{ inModal?: boolean }> = (): React.ReactElement => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [active, setActive] = useState(0);

  const project = useSelector((state) => state.project);
  const devices = useSelector((state) => state.devices);
  const curves = useSelector((state) => state.curves);

  if (!project.selectedDeviceId) {
    return <span />;
  }

  const selectedDevice = devices[project.selectedDeviceId];
  let selectedDeviceId = project.selectedDeviceId as string | undefined;

  if (!selectedDevice || (!deviceHasCurves(selectedDevice.deviceType) && selectedDevice.objectType !== 'MVTypicalUnit')) {
    return <span />;
  }

  if (
    (selectedDevice && isFeeder(selectedDevice.deviceType) && selectedDevice.objectType === EDeviceObjectType.FEEDER) ||
    (selectedDevice &&
      selectedDevice.objectType === EDeviceObjectType.MVTypicalUnit &&
      deviceHasCurves(selectedDevice.deviceType))
  ) {
    selectedDeviceId =
      selectedDevice.objectType === EDeviceObjectType.MVTypicalUnit
        ? dispatch(getSelectedId(EDeviceType.WIREMV)) ||
        dispatch(getSelectedId(EDeviceType.CIRCUITBREAKERMV)) ||
        dispatch(getSelectedId(EDeviceType.CIRCUITBREAKERMVW)) ||
        dispatch(getSelectedId(EDeviceType.MVSWITCH)) ||
        dispatch(getSelectedId(EDeviceType.FUSEMV))
        : dispatch(getSelectedId(EDeviceType.WIRELV));
  }
  const deviceId = selectedDeviceId as string;
  let diagram = devices[deviceId] && devices[deviceId].diagram ? devices[deviceId].diagram ?? '' : '';

  const propertiesTab = (diagram1: string): React.ReactElement => {
    if (!curves.curve2Show || curves.curve2Show.length === 0) {
      return <span />;
    }
    const currentDiagram = diagram1 || curves.curve2Show[0].id;
    const currentCurve = curves.curve2Show.find((curve) => curve.id === currentDiagram);
    if (!currentCurve) {
      return <span />;
    }
    if (currentCurve.driven.length < 2 && active === 1) {
      setActiveTab(0);
      setActive(0);
    } else if (currentCurve.driven.length > 1 && active === 0) {
      setActiveTab(1);
      setActive(1);
    }

    return (
      <TabControl
        activeTab={activeTab}
        onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
        className={activeTab === 0 && (currentCurve.driven.length > 1 || isFeeder(selectedDevice.deviceType)) ? 'curves-tab' : ''}
        id={currentCurve.driven.length > 3 ? 'CurvesTabsRightPanel' : undefined}
      >
        {[
          ...currentCurve.driven.map((drivenId, key) => {
            const objectSign = getObjectSign(devices[drivenId]);
            const detectClass = objectSign !== getObjectSign(selectedDevice) ? 'firstCurves' : 'secondCurves';
            return (
              <TabItem className={`${detectClass}`} key={`${key}curveTab`} id={`${objectSign}`} title={`SET ${objectSign}`}>
                {renderSettings(drivenId, currentCurve.drivenIndex[key])}
              </TabItem>
            );
          }),
          <TabItem title="Show" key="renderShow">
            {renderShow()}
          </TabItem>,
        ]}
      </TabControl>
    );
  };

  const renderCurrents = (
    renderedChecks: Array<ILabelChecks>,
    curvesState: typeof curves,
    element: Device,
    currentCurve: ICurveDiagram
  ): void => {
    if (curvesState.curves && currentCurve && window.miniCurves) {
      for (let i = 0; i < (currentCurve.bUpstream /* && show related off */ ? 1 : currentCurve.pointsIndices.length); i += 1) {
        const { curve2Show } = curvesState;
        const curve = curvesState.curves[currentCurve.pointsIndices[i]];
        const curveType = visibleDiagrams[curve2Show && element.diagram && currentCurve ? currentCurve.curveType : 0];
        if (curve) {
          const curveDevice = devices[curve.id];
          const currents = curve.currents[CurveTypeIndex[curveType]];
          currentCurve.currents2Show.forEach((current) => {
            if (current.currents && curveDevice) {
              current.currents.forEach((ind) => {
                const point = currents[ind];
                if (point > -40) {
                  const { value, unit } = getCurrentUnitValue(point);
                  const checked = getCurrentCheckValue(curveDevice, current);
                  const title = translations[current.title as keyof typeof translations]
                    ? translations[current.title as keyof typeof translations]([])
                    : translations[current.title as keyof typeof translations];
                  const labelData = {
                    text: `${curveDevice.TypicalUnitSign || curveDevice.ObjectSign} ${title as string}[${unit}]`,
                    value,
                    checked,
                    readonly: true,
                    enabled: false,
                    deviceId: curve.id,
                    title: current.title,
                    onCheckedChange: (checked1: boolean) => dispatch(setCurveCurrentChecked(curve.id, current, checked1)),
                  };
                  if (!renderedChecks.some((item) => item.deviceId === labelData.deviceId && item.title === current.title)) {
                    renderedChecks.push({ ...labelData });
                  }
                }
              });
            } else if (!current.currents && curveDevice && currentCurve.selectivityLevel) {
              const point = currentCurve.selectivityLevel;
              if (point > -40) {
                const { value, unit } = getCurrentUnitValue(point);
                const checked = getCurrentCheckValue(curveDevice, current);
                const title = translations[current.title as keyof typeof translations]
                  ? translations[current.title as keyof typeof translations]([])
                  : translations[current.title as keyof typeof translations];
                const labelData = {
                  text: `${curveDevice.ObjectSign ?? ''} ${title as string}[${unit}]`,
                  value,
                  checked,
                  readonly: true,
                  enabled: false,
                  deviceId: curve.id,
                  title: current.title,
                  onCheckedChange: (checked1: boolean) => dispatch(setCurveCurrentChecked(curve.id, current, checked1)),
                };
                if (!renderedChecks.some((item) => item.title === current.title)) {
                  renderedChecks.push({ ...labelData });
                }
              }
            }
          });
        }
      }
    }
  };

  const renderLabels = (element: Device, renderedChecks: Array<ILabelChecks>, curvesIdsToProcess: Array<string>): void => {
    curvesIdsToProcess.forEach((id) => {
      const label = getCurveLabelText(devices[id]);
      const objectSign = getObjectSign(devices[id]);
      const labelData = {
        text: 'Label',
        value: label.text,
        checked: label.checked,
        onTextChange: (text: string) => dispatch(setCurveLabelText(id ?? '', objectSign, text)),
        onCheckedChange: (checked: boolean) => dispatch(setCurveLabelTextChecked(id ?? '', objectSign, checked)),
      };
      renderedChecks.push({ ...labelData });
    });
  };

  const renderShow = (): React.ReactElement => {
    if (!devices[selectedDeviceId ?? '']) {
      return <span />;
    }
    // commented code related only to 'Show curves of protected devices' check in show tab of diagram
    // const id = this.selectedDeviceId;
    const element = devices[deviceId];
    const curve2Show = curves.curve2Show ? curves.curve2Show : null;
    // let bUpstream = false;
    let currentCurve;
    // const curvesOfProtectedDevicesChecked = element.curvesOfProtectedDevicesChecked === false ? false : true;
    if ((deviceHasCurves(element.deviceType) || element.objectType === EDeviceObjectType.MVTypicalUnit) && curve2Show) {
      diagram = element.diagram || curve2Show[0].id;
      currentCurve = curve2Show.find((curve) => curve.id === diagram);
      // if (currentCurve && Object.keys(currentCurve).length) {
      //   bUpstream = currentCurve.bUpstream;
      // }
    } else {
      return <span />;
    }
    const renderedChecks = new Array<ILabelChecks>();
    // if (bUpstream) {
    //   renderedChecks.push({
    //     text: 'Show curves of protected devices',
    //     checked: curvesOfProtectedDevicesChecked,
    //     onlyLabelCheckbox: true,
    //     onCheckedChange: (checked) => setCurvesOfProtectedDevicesChecked(id, checked),
    //   });
    // }
    if (currentCurve && curves.curves !== undefined) {
      const curvesIdsToProcess = new Array<string>();
      let processedCurvesUpToIndex = currentCurve.pointsIndices.length - 1;
      if (currentCurve.bUpstream) {
        processedCurvesUpToIndex = 0;
      }
      currentCurve.pointsIndices.forEach((indexOfCurve, index) => {
        if (index <= processedCurvesUpToIndex && curves.curves?.[indexOfCurve] !== null) {
          const id = curves.curves?.[indexOfCurve].id;
          if (id) {
            curvesIdsToProcess.push(id);
          }
        }
      });

      renderLabels(element, renderedChecks, curvesIdsToProcess);
      renderCurrents(renderedChecks, curves, element, currentCurve);
      return (
        <div>
          {renderedChecks.map((item, index) => {
            return (
              <LabelInput
                key={`${index} CurvesChecks`}
                text={item.text}
                value={item.value ? item.value : undefined}
                readonly={item.readonly ? item.readonly : undefined}
                enabled={item.enabled ? item.enabled : undefined}
                onTextChange={item.onTextChange ? item.onTextChange : undefined}
                checked={item.checked}
                onlyLabelCheckbox={item.onlyLabelCheckbox}
                onCheckedChange={item.onCheckedChange}
                isSwitch
              />
            );
          })}
        </div>
      );
    }
    return <span />;
  };

  const renderSettings = (settingDeviceId: string, drivenIndex: number): React.ReactElement => {
    if (!devices[settingDeviceId]) {
      return <span />;
    }
    let params = null;
    const drivenParams = curves.params && curves.params[settingDeviceId] ? curves.params[settingDeviceId] : null;
    params = drivenParams ? drivenParams[drivenIndex] : null;
    const docxdata = devices[settingDeviceId];
    return (
      <>
        {params ? (
          <Functions
            id={settingDeviceId}
            params={params as Record<string, IGenericCurveFunction>}
            docxdata={docxdata}
            curvesDirty={curves.curvesRequestRunning}
            updateElementParams={updateElementParams}
            getSelectedDeviceCurves={getDeviceCurves}
            setCurrentCurvesDirty={() => {
              dispatch(curvesActions.setCurrentCurvesDirty({ curvesDirty: true }));
            }}
          />
        ) : null}
      </>
    );
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn col={8}>
          <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            {propertiesTab(diagram)}
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default CurvesToolbar;
