import React from 'react';
import { Dialog, TabControl, TabItem, Button } from '@abb/abb-common-ux-react';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { IModalWindow } from 'types/modal/modalWindow';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { showModal, changeTab, closeHandler } from './actions';

// const tabId = (key: string | number): string => `modal-tab-id-${key}`;

const ModalWindow: React.FunctionComponent<IModalWindow> = ({
  blueButtonAction,
  blueButtonText,
  containerClassName,
  disableBlueButton,
  disableGreyButton,
  greyButtonAction,
  greyButtonText,
  isLoading,
  showCloseButton,
  title,
  // tutorialCheckbox,
  tabControlContentClassName,
  tabs,
  // tabsIsArray,
  footer,
  footerClassName,
}): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);

  // TODO: uncomment this when actual modal will be done
  let activeTab = modal.tabName ? Object.keys(tabs).indexOf(modal.tabName) : 0;
  if (activeTab === -1) {
    activeTab = 0;
  }

  const renderTabs = (): React.ReactElement => {
    if (Array.isArray(tabs)) {
      return (
        <TabControl activeTab={activeTab} onTabChange={onTabAction} contentClassName={tabControlContentClassName}>
          {tabs.map((item, key) => {
            console.log(item);
            return (
              <TabItem key={key} title={t(item.name)}>
                {item.modal}
              </TabItem>
            );
          })}
        </TabControl>
      );
    }
    if (Object.keys(tabs).length === 1 && Object.keys(tabs)[0] === 'without') {
      return tabs.without;
    }
    return (
      <TabControl activeTab={activeTab} onTabChange={onTabAction}>
        {Object.keys(tabs).map((name) => (
          <TabItem key={name} title={t(name)}>
            {tabs[name]}
          </TabItem>
        ))}
      </TabControl>
    );
  };

  const onTabAction = (oldIndex: number, newIndex: number) => {
    const tabName = Object.keys(tabs)[newIndex];
    // TODO: Pass proper params for fetch device filters
    dispatch(changeTab(tabName, false));
  };

  const onClose = () => {
    dispatch(closeHandler(modal.name));
    dispatch(showModal(''));
    dispatch(changeTab('', false));
  };

  return (
    <Dialog
      showCloseButton={showCloseButton === undefined ? true : showCloseButton}
      title={title ? t(title) : undefined}
      isOpen
      dimBackground
      onClose={onClose}
      closeOnEscape={false}
      closeOnLostFocus={false}
      containerClassName={containerClassName}
    >
      {renderTabs()}
      {footer === 'without' ? null : (
        <Grid>
          <GridRow className={footerClassName || 'DOCweb_CommonUX_TabControl__footer'}>
            {footer}
            <GridColumn className="modal-buttons" horizontalAlignment="end">
              {greyButtonText ? (
                <Button
                  id="ButtonPrev"
                  isLoading={isLoading}
                  type="discreet-blue"
                  text={t(greyButtonText)}
                  onClick={greyButtonAction}
                  disabled={disableGreyButton}
                />
              ) : null}
              {blueButtonText ? (
                <Button
                  id="ButtonNext"
                  type="primary-blue"
                  isLoading={isLoading}
                  text={t(blueButtonText)}
                  onClick={blueButtonAction}
                  disabled={disableBlueButton}
                />
              ) : null}
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </Dialog>
  );
};

export default ModalWindow;
