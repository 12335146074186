/* eslint-disable no-restricted-properties */
import CurveType from 'curves/curveType';

const milli = 10 ** -3;
const kilo = 10 ** 3;
const mega = 10 ** 6;
const giga = 10 ** 6;

export default {
  [CurveType.TFFF]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 's';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'ms';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.TFF]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 's';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'ms';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.TFN]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 's';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'ms';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.TFPE]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 's';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'ms';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.EFFF]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A\u00B2s';
      if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA\u00B2s';
      } else if (value >= mega) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA\u00B2s';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.EFN]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A\u00B2s';
      if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA\u00B2s';
      } else if (value >= mega) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA\u00B2s';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.EFPE]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A\u00B2s';
      if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA\u00B2s';
      } else if (value >= mega) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA\u00B2s';
      }
      return valueStr + valueSuffix;
    },
  },
  [CurveType.CL]: {
    x: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
    y: (value: number): string => {
      let valueStr = `${value}`;
      let valueSuffix = 'A';
      if (value < 1 && value >= milli) {
        valueStr = `${value / milli}`;
        valueSuffix = 'mA';
      } else if (value >= kilo && value < mega) {
        valueStr = `${value / kilo}`;
        valueSuffix = 'kA';
      } else if (value >= mega && value < giga) {
        valueStr = `${value / mega}`;
        valueSuffix = 'MA';
      }
      return valueStr + valueSuffix;
    },
  },
};
