import { Device } from 'types/devices/device';
import ApiCalls from './ApiCalls';

// export default class LvCableAPI {
//   static getCableData(changed, languageId, neutralOptimization, request) {
//     return ApiCalls.post(`CableSizing/GetCableData/${changed}/${languageId}/${neutralOptimization}`, request, false);
//   }
// }

export default class LvCableAPI {
  static async getCableData(
    changed: string,
    languageId: number,
    neutralOptimisation: boolean,
    request: Partial<Device>
  ): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, Partial<Device>>(
      `CableSizing/GetCableData/${changed}/${languageId}/${neutralOptimisation.toString()}`,
      request
    );
    return data;
  }
}
