/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Currents2Show, FunctionOption, ICurveSettingPath } from 'types/curves';
import { Device } from 'types/devices/device';
import _ from 'lodash';

const isNumber = (string: string) => !Number.isNaN(parseFloat(string)) && Number.isFinite(string);

export const getOnParamValue = (docxdata: Partial<Device>, onName?: Array<number | string>): boolean => {
  if (onName && onName.length > 0 && docxdata) {
    if (_.has(docxdata, onName)) {
      return _.get(docxdata, onName) as boolean;
    }
    console.error(`onName ${onName} is missing from docxdata ${docxdata[onName[0]]}`);
  }
  return true;
};

export const getParamValue = (
  docxdata: Partial<Device>,
  paramName: Array<number | string>,
  optional = false,
  defaultValue?: number | string
): number | string => {
  if (_.has(docxdata, paramName)) {
    return _.get(docxdata, paramName);
  }
  if (!optional && docxdata && docxdata.TripUnit) {
    console.warn(`${paramName} is missing from docxdata ${docxdata[paramName[0]]}`);
    return -1;
  }
  return defaultValue ?? -1;
};

export const getParamValueForPrint = (
  docxdata: Partial<Device>,
  paramName: Array<string | number>,
  optional = false,
  defaultValue?: number | string,
  numberOfDecimals?: number
): number | string => {
  let value = getParamValue(docxdata, paramName, optional, defaultValue);
  if (value && isNumber(value as string)) {
    value = parseFloat(value as string).toFixed(numberOfDecimals || 1);
  }
  return value;
};

export const correctTheValue = (value: Array<number> | number, min: number, max: number): number => {
  let correctValue = Array.isArray(value) ? value[0] : value;
  if (Number.isNaN(correctValue)) return -1;
  if (correctValue < min) {
    correctValue = min;
  } else if (correctValue > max) {
    correctValue = max;
  }
  return correctValue;
};

export const getCurrentCheckValue = (curveDevice: Device, current: Currents2Show): boolean => {
  let dataIndex = -1;
  let checked = current.bShowByDefault;
  if (curveDevice && curveDevice[current.storeAt[0]]) {
    dataIndex = (
      (curveDevice[current.storeAt[0]] as Record<string, unknown>)[current.storeAt[1]] as Array<ICurveSettingPath>
    ).findIndex((sh) => sh['@'] && sh['@'].id === current.attribute);
  }
  if (dataIndex !== -1) {
    checked = ((curveDevice[current.storeAt[0]] as Record<string, unknown>)[current.storeAt[1]] as Array<ICurveSettingPath>)[
      dataIndex
    ]['#'];
  }
  return checked;
};

export const findNearestValue = (
  options: Array<FunctionOption>,
  value: number,
  suffix?: string,
  numberOfDecimals?: number
): string | undefined => {
  numberOfDecimals = numberOfDecimals || -Math.floor(Math.log10(Math.min(...options.map((op) => op.step)) + 1e-9));
  if (numberOfDecimals < 0) numberOfDecimals = 0;
  const newSuffix = suffix || '';
  const fixValue = (val: number): string => `${val.toFixed(numberOfDecimals)}${newSuffix}`;
  if (value < options[0].start) {
    return fixValue(options[0].start);
  }
  if (value > options[options.length - 1].start + options[options.length - 1].step * (options[options.length - 1].count - 1)) {
    return fixValue(
      options[options.length - 1].start + options[options.length - 1].step * (options[options.length - 1].count - 1)
    );
  }
  let lastValue = 0;
  for (let i = 0; i < options.length; i += 1) {
    const currentOption = options[i];
    for (let j = 0; j < currentOption.count; j += 1) {
      const currentValue = currentOption.start + currentOption.step * j;
      // If j + 1 === count than nextValue === currentValue or last from options...
      const nextValue = currentOption.count === j + 1 ? currentValue : currentOption.start + currentOption.step * (j + 1);
      if (currentValue === value) {
        return fixValue(currentValue);
      }
      if (value > lastValue && value < nextValue) {
        if (value - parseFloat(lastValue.toFixed(numberOfDecimals)) > parseFloat(nextValue.toFixed(numberOfDecimals)) - value) {
          return fixValue(nextValue);
        }
        if (currentValue - value < value - lastValue && currentValue - value < nextValue - value) {
          return fixValue(currentValue);
        }
        return fixValue(lastValue);
      }
      lastValue = currentValue;
    }
  }
  return undefined;
};
