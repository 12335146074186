import { Action, combineReducers } from 'redux';
import { TypedUseSelectorHook, createSelectorHook, useStore } from 'react-redux';
import { ApplicationState } from 'types/store.d';
import { reducer as devices } from 'store/devices/store';
import { reducer as project } from 'store/project/store';
import { reducer as wizard } from 'store/wizard/store';
import { reducer as variables } from 'store/variables/store';
import { reducer as modal } from 'store/modal/store';
import { reducer as compute } from 'store/compute/store';
import { reducer as connections } from 'store/connections/store';
import { reducer as curves } from 'store/curves/store';
import { reducer as labels } from 'store/labels/store';
import { reducer as logo } from 'store/logo/store';
import { reducer as pages } from 'store/pages/store';
import { reducer as standards } from 'store/standards/store';
import { reducer as undo } from 'store/undo/store';
import { reducer as user } from 'store/user/store';
import { reducer as userSettings } from 'store/userSettings/store';
import { reducer as deviceFilters } from 'store/deviceFilters/store';
import { reducer as docWebData } from 'store/docWebData/store';
import { ThunkDispatch } from 'redux-thunk';

export const reducers = combineReducers({
  devices,
  project,
  wizard,
  variables,
  modal,
  compute,
  connections,
  curves,
  labels,
  logo,
  pages,
  standards,
  undo,
  user,
  userSettings,
  deviceFilters,
  docWebData,
});

export const useSelector: TypedUseSelectorHook<ApplicationState> = createSelectorHook();
type AppDispatch = ThunkDispatch<ApplicationState, unknown, Action<string>>;
export const useDispatch = (): AppDispatch => useStore<ApplicationState>().dispatch;
