import React, { FunctionComponent } from 'react';
import { useSelector } from 'store';
import { MessageDialogWindow } from '../ModalDialog';

export const MessageDialog: FunctionComponent = (): React.ReactElement => {
  const modal = useSelector((state) => state.modal);
  return (
    <MessageDialogWindow
      buttons={modal.dialogParams?.buttons ?? 'ok'}
      message={modal.dialogParams?.message}
      title={modal.dialogParams?.title}
      onOk={modal.dialogParams?.onOk}
      monochromeButtons={false}
    />
  );
};

export default MessageDialog;
