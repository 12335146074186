/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import UnitsDataAdapter, { units } from 'calculations/UnitsDataAdapter';
import { CircuitBreaker } from 'types/devices/circuitBreaker';
import { Device } from 'types/devices/device';
import { EBreakerTypes, EDeviceVersion, ELvLineType } from 'types/devices/enums.d';
import { CableIbPairs } from 'types/devices/lvCable';
import { ApplicationState } from 'types/store';
import { breakerSymbols } from '../constants';

export const isPropagatedProperty = (changed: string): boolean => {
  let shouldPropagate = false;
  switch (changed) {
    case 'Uref':
    case 'NominalVoltage':
    case 'EarthingSystem':
    case 'LineType':
    case 'PrimaryWindingLineType':
    case 'SecondaryWindingLineType':
    case 'PrimaryWindingEarthingSystem':
    case 'SecondaryWindingEarthingSystem':
    case 'PrimaryWindingUn':
    case 'SecondaryWindingUn':
    case 'SwitchBoardName':
    case 'MvPanelBreakingTechnology':
    case 'MvPanelIcw':
    case 'MvPanelUr':
    case 'VectorGroup':
    case 'MvSwitchBoardName':
    case 'LinkName':
      shouldPropagate = true;
      break;
    default:
      return false;
  }
  return shouldPropagate;
};

export const isSilentParam = (name: string): boolean => {
  switch (name) {
    case 'Name':
    case 'Description':
    case 'ProductDescription':
    case 'Model':
    case 'UserDescription1':
      return true;
    default:
      return false;
  }
};
export const processDeviceValues = (device: Device): Record<string, number | string> => {
  const cutValues: Record<string, number | string> = {};
  Object.keys(device).forEach((deviceAttr) => {
    if (device[deviceAttr] && units[deviceAttr as keyof typeof units] && typeof device[deviceAttr] !== 'object') {
      cutValues[deviceAttr] = UnitsDataAdapter.cutUnits(deviceAttr as keyof typeof units, device[deviceAttr] as string);
    }
  });
  return cutValues;
};

export const getBaseDeviceFromBlock = (elementId: string, state: ApplicationState, blockDeviceType: string): string => {
  if (state.project.selectedDeviceId !== undefined) {
    const selectedDevice = state.devices[state.project.selectedDeviceId];
    if (selectedDevice && selectedDevice.deviceType === blockDeviceType && selectedDevice.base_device !== undefined) {
      // eslint-disable-next-line no-param-reassign
      elementId = selectedDevice.base_device;
    }
  }
  return elementId;
};

export const getIkMaxMinOfDevice = (selectedDevice: Device & CableIbPairs) => {
  let scMaxValue = 0;
  let scMaxValueName = 'ScIEC60909L3Ib';
  let scMin = 0;
  if (!selectedDevice) {
    return {
      scMaxValue,
      scMaxValueName,
      scMin,
    };
  }
  // ScIEC60909L3Ib, ScIEC60909L2Ib, ScIEC60909LNeutralIb, according the line type
  if ([ELvLineType.LLLN, ELvLineType.LLL].includes(selectedDevice.LineType) && selectedDevice.ScIEC60909L3Ib) {
    scMaxValue = selectedDevice.ScIEC60909L3Ib.pair[0].current;
    scMaxValueName = 'ScIEC60909L3Ib';
  } else if (
    [ELvLineType.L2L3, ELvLineType.L1L3, ELvLineType.L1L2].includes(selectedDevice.LineType) &&
    selectedDevice.ScIEC60909L2Ib
  ) {
    scMaxValue = selectedDevice.ScIEC60909L2Ib.pair[0].current;
    scMaxValueName = 'ScIEC60909L2Ib';
  } else {
    scMaxValueName = 'ScIEC60909LNeutralIb';
    if (selectedDevice.ScIEC60909LNeutralIb) {
      scMaxValue = selectedDevice.ScIEC60909LNeutralIb.pair[0].current;
    }
  }
  if (selectedDevice.ScIEC60909LPEIbMin) {
    scMin = selectedDevice.ScIEC60909LPEIbMin.pair[0].current;
  }
  return {
    scMaxValue,
    scMaxValueName,
    scMin,
  };
};

type ObjectTypeData = {
  objectType?: string;
  initialized?: boolean;
  TypeFilter?: string;
};

const valueIsBreakerTypes = (value: string | EBreakerTypes | EDeviceVersion): value is EBreakerTypes => {
  return Object.keys(EBreakerTypes).find((item) => item === value) !== undefined;
};

const valueIsDeviceVersion = (value: string | EBreakerTypes | EDeviceVersion): value is EDeviceVersion => {
  return Object.keys(EDeviceVersion).find((item) => item === value) !== undefined;
};

export const changeBreakerObjectType = (
  value: string | EBreakerTypes | EDeviceVersion,
  selectedDevice?: Device<CircuitBreaker> | null,
  typeFilter = false
): ObjectTypeData => {
  const objectTypeData: ObjectTypeData = {};
  if (!selectedDevice && valueIsBreakerTypes(value)) {
    objectTypeData.objectType = breakerSymbols[value]['All possibilities'];
    return objectTypeData;
  }
  if (!selectedDevice) {
    return objectTypeData;
  }
  if (valueIsBreakerTypes(value)) {
    if (typeFilter) {
      objectTypeData.objectType =
        breakerSymbols[value][selectedDevice.versionOutput ? selectedDevice.versionOutput : selectedDevice.VersionFilter];
    }
  } else if (valueIsDeviceVersion(value)) {
    objectTypeData.objectType = breakerSymbols[selectedDevice.TypeFilter as keyof typeof EBreakerTypes][value];
  }
  objectTypeData.initialized = false;
  return objectTypeData;
};
