// import React from 'react';
// import PropTypes from 'prop-types';
// import {connect} from 'react-redux';

import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import FeederPropertiesGrid from 'components/generic/FeederPropertiesGrid';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import React, { useEffect } from 'react';
import { useDispatch } from 'store';
import { UnitsType } from 'types/calculations';
import { Column } from 'types/components/grid';
import { IProtectedObject, IRelationItem } from 'types/devices/protectedObjects';
import { isEmpty } from 'lodash';
import {
  discriminationSwitchHandler,
  getComponentRelations,
  getDeviceIdBySign,
  inputUpdateHandler,
  selectFocusOutHandler,
  updateDiscriminationHandler,
} from './actions';

const DiscriminationTab: React.FunctionComponent<IProtectedObject> = ({
  selectedDevice,
  selectedDeviceId,
}): React.ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(selectedDevice.DiscriminationPropertiesList) && isEmpty(selectedDevice.BackupPropertiesList)) {
      dispatch(getComponentRelations(selectedDeviceId));
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(getComponentRelations(selectedDeviceId));
    };
  }, []);
  const protection = (): React.ReactElement[] => {
    let data: Record<string, unknown> = {};
    if (selectedDevice.DiscriminationPropertiesList && selectedDevice.BackupPropertiesList) {
      data = {
        ...(selectedDevice.BackupPropertiesList as Record<string, IRelationItem[]>),
        ...(selectedDevice.DiscriminationPropertiesList as Record<string, IRelationItem[]>),
      };
    }
    const renderedProps = {
      colsAlignment: [1, 0, 0, 0] as Column[],
      colsNames: [
        'ID',
        'DOCWEB_STRING_REQUIRED_SELECTIVITY_VALUE',
        'DOCWEB_STRING_MAX_SHORT_CIRCUT_CURRENT',
        'DOCWEB_STRING_GRANTED_SELECTIVITY_VALUE',
      ],
      displayKeys: ['userId', 'discrimLevel', 'iMax', 'discrimDBLevel'],
      displayType: ['Label', 'Input', 'Label', 'Label'],
    };

    if (
      selectedDevice.DiscriminationProperty === 'backUpUpStream' ||
      selectedDevice.DiscriminationProperty === 'backUpDownStream'
    ) {
      renderedProps.colsAlignment = [2, 6];
      renderedProps.colsNames = ['ID', ''];
      renderedProps.displayKeys = ['userId', ''];
      renderedProps.displayType = ['Label', 'Label'];
    }
    const discriminationTranslation: IProtectedObject['discriminationTranslation'] = {
      backUpUpStream: 'DOCWEB_STRING_BACK_UP_WITH_UPSTREAM_DEVICE',
      backUpDownStream: 'DOCWEB_STRING_BACK_UP_WITH_DOWNSTREAM_DEVICE',
      discriminationDownStream: 'DOCWEB_STRING_SELECTIVITY_WITH_DOWNSTREAM_DEVICE',
      discriminationUpStream: 'DOCWEB_STRING_SELECTIVITY_WITH_UPSTREAM_DEVICE',
    };
    const displayData: Array<string> = [];
    Object.keys(data).forEach((item) => displayData.push(discriminationTranslation[item]));
    return [
      <GridRow key={0} style={{ marginBottom: '20px' }}>
        <GridColumn col={2}>
          <Select
            cols={[8, 8]}
            className="info-project-input"
            name="DiscriminationProperty"
            label="STRING_REPCOORDINATION"
            onChange={(e) => dispatch(discriminationSwitchHandler(selectedDeviceId, 'DiscriminationProperty', e.value))}
            valueList={Object.keys(data)}
            selectList={displayData}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={8}>
          <FeederPropertiesGrid
            cols={[1, 7]}
            // label=""
            colsAlignment={renderedProps.colsAlignment}
            colsNames={renderedProps.colsNames}
            colsItems={
              data && data[selectedDevice.DiscriminationProperty as string]
                ? (data[selectedDevice.DiscriminationProperty as string] as IRelationItem[])
                : []
            }
            displayKeys={renderedProps.displayKeys}
            displayType={renderedProps.displayType}
            onCheckBoxChange={(deviceId, name, value, silent, item, link, checked) =>
              updateDiscriminationHandler(deviceId, name, value, item, link, checked, silent)
            }
            onSelectChange={(e, item, checked) => dispatch(inputUpdateHandler(e, item, checked))}
            onSelectFocusOut={(e, item, checked) => dispatch(selectFocusOutHandler(e, item, checked))}
            // devices={devices}
            ProtectionAutoAssignOff
            cut={(name: UnitsType, value: string) => UnitsDataAdapter.cutUnits(name, value)}
            icons={['voltage']}
            getDeviceIdBySign={getDeviceIdBySign}
          />
        </GridColumn>
      </GridRow>,
    ];
  };

  return <Grid>{protection()}</Grid>;
};

export default DiscriminationTab;
