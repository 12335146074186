import CheckBox from 'components/generic/CheckBox';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { diagramComboNames } from 'curves/CurvesTranslations';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { onChangeReports, setSvgContent } from './actions';

const ProjectDocumentationManagerComponent = (): React.ReactElement => {
  const devices = useSelector((state) => state.devices);
  const project = useSelector((state) => state.project);
  const curves = useSelector((state) => state.curves);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const selectedDevice = devices[project.selectedDeviceId ?? ''];
    if (selectedDevice && selectedDevice.diagram) {
      dispatch(setSvgContent(selectedDevice.diagram));
    }
  }, []);

  const printManagerBody = () => {
    const curvesDiagram = curves.curve2Show ? curves.curve2Show : [];
    return curvesDiagram.map((diagram, index) => (
      <GridRow
        key={`PrintManagerComponentBody${index}`}
        className="project-properties-print-accordion print-manager-checkbox-background-main"
      >
        <GridColumn>
          <CheckBox
            label={
              diagramComboNames[diagram.name as keyof typeof diagramComboNames]
                ? t(diagramComboNames[diagram.name as keyof typeof diagramComboNames], { 'value[0]': [diagram.sign] })
                : diagram.name + (diagram.sign != null ? ` ${diagram.sign}` : '')
            }
            name={diagram.id}
            value={project.diagramsForPrint[diagram.id] ? project.diagramsForPrint[diagram.id].checked : false}
            onChange={(e) => dispatch(onChangeReports(e))}
          />
        </GridColumn>
      </GridRow>
    ));
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn id={'ProjectDocumentationColumn'} col={8} style={{ paddingRight: '10px' }}>
          <label className="print-manager-header print-manager-header-print">{t('DOCCURVES_STRING_PRINTMANAGER')}</label>
          <GridRow>
            <GridColumn>
              {printManagerBody()}
              {/* <ReportComponent state={{project: this.props.project, curves: this.props.curves, devices: this.props.devices}} t = {this.props.t}/> */}
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default ProjectDocumentationManagerComponent;
