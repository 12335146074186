import React from 'react';
// import Input from 'components/generic/inputContainer';
import { Input as AbbInput } from '@abb/abb-common-ux-react';

import { useTranslation } from 'react-i18next';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';

import { useDispatch, useSelector } from 'store';
import { leftInfoFields, rightInfoFields } from '../../../../constants';
import { onChange, onChangeDate } from './actions';

const InfoContainer = (): React.ReactElement => {
  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inputField = (name: string, type: string, title: string): React.ReactElement => {
    const value = project[name] as string;
    const onChangeHandler = type === 'date' ? onChangeDate : onChange;
    return (
      <GridRow key={name}>
        <GridColumn col={3}>
          <label>{t(title)}</label>
        </GridColumn>
        <GridColumn col={3}>
          <AbbInput
            dataType={type === 'date' ? 'date' : 'text'}
            type="normal"
            onValueChange={(val) => dispatch(onChangeHandler({ name, value: val }))}
            value={value}
          />
        </GridColumn>
      </GridRow>
    );
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn col={4}>{leftInfoFields.map((field) => inputField(field.name, field.type, field.title))}</GridColumn>
        <GridColumn col={4}>{rightInfoFields.map((field) => inputField(field.name, field.type, field.title))}</GridColumn>
      </GridRow>
    </Grid>
  );
};

export default InfoContainer;
