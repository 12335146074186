/* eslint-disable no-param-reassign */
import attributesToSave from 'types/devices/attributesToSave';
import _ from 'lodash';
import { Device, EdgeDevice, IDevicePort } from 'types/devices/device';
import { updateElementParams } from 'devices/actions';
import { ECellType, EDeviceType, ESymbolStatus } from 'types/devices/enums.d';
import {
  elementDefaultState,
  elementObjectIdCounter,
  elementObjectIdPrefix,
  elementObjectSignPrefix,
} from 'types/devices/constants';
import { IAddElement, IAddLink } from 'types/devices/actions';
import { isSwitchboard } from 'types/devices/type-guards';
import { ThunkAction } from 'types/known-actions';
import { Symbols } from 'project/utils/symbols';

export const deNullDeviceData = (deviceData: Device): void => {
  Object.keys(deviceData).forEach((property) => {
    if (deviceData[property] !== undefined) {
      if (deviceData[property] === null && attributesToSave.includes(property)) {
        delete deviceData[property];
      }
    }
  });
};

export const getObjectSign = (device: Device): string => {
  if (!device) {
    // if (console.warn) {
    //   console.warn(`Try to get sign of undefined object`);
    // }
    return '';
  }

  if (device.deviceType === EDeviceType.UTILITY) {
    return '-U1';
  }

  if (device.deviceType === EDeviceType.LINK) {
    return 'connection';
  }

  if (device) {
    return (device.TypicalUnitSign as string) || (device.ObjectSign as string);
  }

  // Departure and Arrival doesn't have symbol sign
  return '';
};

const isElementTypeSupported = (type: EDeviceType) => {
  return type in elementDefaultState && type in elementObjectIdCounter;
};

export const getElementDefaultState = (type: EDeviceType): Partial<Device> | undefined => {
  if (isElementTypeSupported(type)) {
    return elementDefaultState[type];
  }
  return undefined;
};

export const updateElementPosition = (actionElement: IAddElement, element: Partial<Device>): Device => {
  if (actionElement.elementType === EDeviceType.BUSBAR || actionElement.elementType === EDeviceType.LINE) {
    element.x2 = actionElement.x + actionElement.w;
    element.y2 = actionElement.y;
  } else if (actionElement.elementType === EDeviceType.CONNECTOON) {
    element.x2 = actionElement.w > 0.3 ? actionElement.x + actionElement.w : actionElement.x;
    element.y2 = actionElement.h > 0.3 ? actionElement.y + actionElement.h : actionElement.y;
  } else if (actionElement.elementType === EDeviceType.CURVE_DIAGRAM) {
    element.x2 = actionElement.x + actionElement.w;
    element.y2 = actionElement.y + actionElement.h;
  }
  element.x = actionElement.x;
  element.y = actionElement.y;
  if (typeof actionElement.json !== 'string') element.json = actionElement.json; // TODO refactor (maybe write only on save?)

  if (actionElement.ObjectStatus) {
    element.ObjectStatus = actionElement.ObjectStatus;
  } else if (actionElement.elementType !== EDeviceType.CURVE_DIAGRAM && actionElement.elementType !== EDeviceType.TEXTAREA) {
    element.ObjectStatus = ESymbolStatus.NotChecked;
  }
  // if (element.deviceType) {
  //   updateColumnObjectSign(element, element.deviceType, element.page ?? 1);
  // }
  // Hide block devices ports
  // if (element.page === 0) {
  //   window.app.graph.getCells().forEach((cell) => {
  //     const docElement = cell as DocElement;
  //     if (docElement.attributes.type !== EDeviceType.LINK && docElement.attributes.type !== EDeviceType.TEXTLINK) {
  //       docElement.attributes.ports.items.forEach((port) => {
  //         docElement.setPortVisibility(port.id ?? '', false);
  //       });
  //     }
  //   });
  // }

  return element as Device;
};

export const updateLink = (actionElement: IAddLink, page: number, link: Partial<Device<EdgeDevice>>): Device<EdgeDevice> => {
  const textLink = actionElement.json?.type === EDeviceType.TEXTLINK;
  const deviceType = textLink ? EDeviceType.TEXTLINK : EDeviceType.LINK;
  return {
    ...getElementDefaultState(EDeviceType.LINK),
    sourceId: actionElement.sourceId,
    targetId: actionElement.targetId,
    userVertices: actionElement.userVertices.map((vertex) => ({ x: vertex.x, y: vertex.y })),
    vertices: actionElement.vertices.map((vertex) => ({ x: vertex.x, y: vertex.y })),
    json: actionElement.json,
    page,
    deviceType,
    ObjectId: link && link.ObjectId ? link.ObjectId : getObjectIdSign(EDeviceType.LINK).ObjectId,
    ObjectStatus: link ? link.ObjectStatus : ESymbolStatus.NotChecked,
    type: page === 0 || textLink ? ECellType.BlockConnection : ECellType.Connection,
  } as Device<EdgeDevice>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateObjectIdSign = (element: Partial<Device>, type: EDeviceType, page?: number): void => {
  const ObjectIdSign = getObjectIdSign(type);
  element.ObjectId = ObjectIdSign.ObjectId;
  element.ObjectSign = ObjectIdSign.ObjectSign;
};

export const getObjectIdSign = (type: EDeviceType): { ObjectId: string; ObjectSign: string } => {
  let noSign = false;
  const counter = elementObjectIdCounter[type];
  let objectId;
  let objectSign;
  const isBlockDeviceChild =
    [EDeviceType.BLOCK_TRAFO, EDeviceType.BLOCK_UTILITY, EDeviceType.BLOCK_GENERATOR].indexOf(type) !== -1;
  const samePrefix = Object.keys(elementObjectIdPrefix).filter((t) => {
    const objectIdPrefix = elementObjectIdPrefix[t as EDeviceType];
    const objectSignPrefix = elementObjectSignPrefix[t as EDeviceType];
    return (
      (objectIdPrefix && objectIdPrefix === elementObjectIdPrefix[type]) ||
      (objectSignPrefix && objectSignPrefix === elementObjectSignPrefix[type])
    );
  });
  noSign = samePrefix.length === 0;
  if (noSign) {
    elementObjectIdCounter[type] += 1;
  } else {
    samePrefix.forEach((t) => {
      elementObjectIdCounter[t as EDeviceType] += 1;
    });
  }
  if (isBlockDeviceChild && counter > 1) {
    objectId = `${elementObjectIdPrefix[type]}${counter}`;
    // Object sign of block devices should be the same as parent
    objectSign = noSign ? `${elementObjectSignPrefix[type]}` : `${elementObjectSignPrefix[type]}${counter - 1}`;
  } else {
    objectId = `${elementObjectIdPrefix[type]}${counter}`;
    objectSign = noSign ? `${elementObjectSignPrefix[type]}` : `${elementObjectSignPrefix[type]}${counter}`;
  }
  return {
    ObjectId: objectId,
    ObjectSign: objectSign,
  };
};

export const createElement = (
  actionElement: IAddElement,
  page: number,
  cloneParams: Partial<Device> | null = null
): Device | undefined => {
  const type = actionElement.elementType;
  let element = getElementDefaultState(type);
  if (element) {
    if (cloneParams) {
      element = {
        ...element,
        ...cloneParams,
      };
    }

    updateObjectIdSign(element, type, page);
    element = _.cloneDeep(element);

    // if (type === EDeviceType.WIRELV) {
    //   const bkp = element;
    //   if (!cloneParams || !cloneParams.CableLength) {
    //     element = _.cloneDeep(lvCable);
    //   }
    //   element.ObjectId = bkp.ObjectId;
    //   element.ObjectSign = `${bkp.ObjectSign ?? ''}`;
    // }

    if (type === EDeviceType.DEPARTURE || type === EDeviceType.TYPICAL_FEEDER) {
      element.LinkName = `${element.ObjectId ?? ''}`;
    }

    if (!isSwitchboard(element)) {
      if (actionElement.SwitchBoardName) {
        element.SwitchBoardName = actionElement.SwitchBoardName;
      } else if (actionElement.MvSwitchBoardName) {
        element.MvSwitchBoardName = actionElement.MvSwitchBoardName;
      }
    }

    element.deviceType = type;
    element.page = page;

    // After deviceType and page are set
    element = updateElementPosition(actionElement, element);
    element.json = {
      id: actionElement.elementId,
      type,
      ports: {
        items: Symbols[element.symbol as keyof typeof Symbols] as unknown as IDevicePort[],
      },
    };
    return element as Device;
  }
  return undefined;
};

export const updateElement = (element: Device, action: { objectParams: Partial<Device>; silent?: boolean }): Device => {
  return {
    ...element,
    isFreshlyInserted: false,
    ...action.objectParams,
    ObjectStatus: action.silent ? (element.ObjectStatus as ESymbolStatus) : ESymbolStatus.NotChecked,
  };
};

const fixDeviceData =
  (deviceId: string, deviceAttr: string, callBack: () => ThunkAction<void>): ThunkAction<void> =>
  (dispatch, getState) => {
    const { devices } = getState();
    const device = devices[deviceId];
    const deviceDefaultState = elementDefaultState[device.deviceType];
    // if (!elementDefaultState[device.device][deviceAttr]) {
    //   throw new Error(`Wrong data in ${deviceAttr}: ${devices[deviceId][deviceAttr]} of device with id=${deviceId} and there is no such attr in default state of a device`);
    // }
    console.warn(
      `ERROR: Backend refused to accept ${deviceAttr}: ${devices[deviceId][deviceAttr] as string} of device with id=${deviceId}`
    );
    dispatch(
      updateElementParams(deviceId, {
        [deviceAttr]: deviceDefaultState[deviceAttr],
      })
    );
    dispatch(callBack());
  };

// TODO: Check what this function gets
export const checkDeviceData =
  (response: Partial<Device>, callBack: () => ThunkAction<void>): ThunkAction<boolean> =>
  (dispatch) => {
    if (!response) {
      console.warn('API Got broken', callBack);
      return false;
    }
    if (response.deviceId && response.deviceAttr) {
      dispatch(fixDeviceData(response.deviceId as string, response.deviceAttr as string, callBack));
      return false;
    }
    return true;
  };
