import { ECellType, EDeviceObjectType, EDeviceType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  type: ECellType.Line,
  deviceType: EDeviceType.LINE,
  symbol: '',
  objectType: EDeviceObjectType.Line,
  Color: '#000000',
  Width: 1,
};
