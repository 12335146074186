/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { FILTERS } from 'types/constants.d';
import { FiltersKnownActions } from 'types/known-actions.d';
import { State } from 'types/deviceFilters';

export const initialState: State = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: FiltersKnownActions): State =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILTERS.SET_FILTER:
        draft[action.payload.deviceType] = action.payload.filters;
        break;
      default:
        break;
    }
  });
