import { ECellType, EDeviceObjectType, EEarthingSystem, ELvLineType, ESymbolStatus } from './enums.d';
import { DefaultElement } from './device';

export const defaultElement: DefaultElement = {
  type: ECellType.Device,

  page: 1,
  objectType: EDeviceObjectType.Unknown,
  ObjectStatus: ESymbolStatus.NotChecked,
  symbol: 'UNKNOWN_SYMBOL',
  ObjectId: '?0',
  ObjectSign: '1',
  x: 0,
  y: 0,
  labelsPosition: 1,
  LineType: ELvLineType.LLLN,
  // distributionSystemList: [EEarthingSystem.TNS],
  EarthingSystem: EEarthingSystem.TNS,
  // lineTypeList: [ELvLineType.LLLN],
  distributionSystemList: [EEarthingSystem.TT, EEarthingSystem.TNC, EEarthingSystem.TNS, EEarthingSystem.IT],
  lineTypeList: [
    ELvLineType.LLLN,
    ELvLineType.LLL,
    ELvLineType.L2L3,
    ELvLineType.L1L3,
    ELvLineType.L1L2,
    ELvLineType.L1N,
    ELvLineType.L2N,
    ELvLineType.L3N,
  ],
  errors: {},
  isFreshlyInserted: false,

  ScIEC60909L3Ib: {
    pair: [{ time: 0.01, current: 10000 }],
  },
  ScIEC60909L2Ib: {
    pair: [{ time: 0.01, current: 10000 }],
  },
  ScIEC60909LNeutralIb: {
    pair: [{ time: 0.01, current: 10000 }],
  },
  ScIEC60909LPEIbMin: {
    pair: [{ time: 0.01, current: 1000 }],
  },
};
