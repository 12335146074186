/* eslint-disable no-nested-ternary */
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { diagramComboNames } from 'curves/CurvesTranslations';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { IDeviceCurveSetting } from 'types/curves';
import { EDeviceType } from 'types/devices/enums.d';
import { ApplicationState } from 'types/store';
import { logo } from './abb-logo';
import { getSettings, getTextValue } from './actions';

const ReportComponent = () => {
  const logoBlob = new Blob([logo], { type: 'image/svg+xml' });
  const logoUrl = URL.createObjectURL(logoBlob);
  const { t } = useTranslation();
  const state = useStore().getState() as ApplicationState;

  const reportBody = (
    diagramName: string,
    settings: Record<string, Array<IDeviceCurveSetting>>,
    svgDiagramUrl: string,
    selectedDeviceObjectSign: string
  ) => {
    const GENERAL_INFORMATION_VALUE_NAMES = ['Plant', 'Customer', 'Switchboard', 'Designer', 'Project'];
    const selectedDevice = state.devices[state.project.selectedDeviceId ?? ''];
    const isCable = selectedDevice.deviceType === EDeviceType.WIRELV || selectedDevice.deviceType === EDeviceType.WIREMV;
    const selectivityDiagram = Object.keys(settings).filter((setName) => setName.includes('-QF')).length > 1;
    // let deviceName;
    if (!isCable) {
      // const typePrefix = selectedDevice.deviceType === EDeviceType.FUSEBASE ? 'Fuse' : 'Cb';
      // deviceName = `${typePrefix} - ${selectedDevice.ProductDescription as string}`;
    } else {
      // const { CrossSectionDescription, Conductor, Insulator } = selectedDevice;
      // deviceName = `Cable - ${Conductor || ''} ${Insulator || ''} ${CrossSectionDescription || ''}`;
      GENERAL_INFORMATION_VALUE_NAMES.splice(
        GENERAL_INFORMATION_VALUE_NAMES.findIndex((n) => n === 'Switchboard'),
        1
      );
    }

    const selectedDeviceDiagramSettings = Object.keys(settings).find(
      (setName) => setName.includes(selectedDeviceObjectSign) && !setName.includes('&&secondPage')
    );
    const secondPage = Object.keys(settings).find(
      (setName) => setName.includes(selectedDeviceObjectSign) && setName.includes('&&secondPage')
    );
    const sortedSettings = new Array<string>();
    if (selectedDeviceDiagramSettings) {
      sortedSettings.push(selectedDeviceDiagramSettings);
    }
    if (secondPage) {
      sortedSettings.push(secondPage);
    }
    if (!selectedDeviceDiagramSettings && !secondPage && Object.keys(settings).length === 0) {
      sortedSettings.push('noSet');
    } else {
      Object.keys(settings).forEach((setName) => {
        if (!sortedSettings.includes(setName)) {
          sortedSettings.push(setName);
        }
      });
    }
    if (selectivityDiagram) {
      return (
        <>
          <Grid className="page-number-rectangle">
            <GridRow className="page-number" horizontalAlignment="center">
              <GridColumn>{`Page ${1}`}</GridColumn>
            </GridRow>
          </Grid>
          <Grid className="rectangle">
            <GridRow className="report-logo">
              <img src={logoUrl} width={100} height={50}></img>
            </GridRow>
            <GridRow horizontalAlignment={'center'}>
              <GridRow className="device-product-name">
                <div className={'device-stripe'}></div>
                {selectivityDiagram ? t('CURVESWEB_PRINT_HEADER') : diagramName}
                {/* {index === 0 ? this.renderGeneralInfo(GENERAL_INFORMATION_VALUE_NAMES) : null} */}
              </GridRow>
              <GridRow horizontalAlignment="center">
                <img src={svgDiagramUrl} width="650" height="455"></img>
                <GridColumn>
                  {sortedSettings.map((setName) => {
                    return <>{settings && !_.isEmpty(settings) ? renderSettings({ [setName]: settings[setName] }) : null}</>;
                  })}
                </GridColumn>
              </GridRow>
            </GridRow>
          </Grid>
          <Grid className={'copyright-page-space-rectangle'}>
            <GridRow className="abb-all-rights-reserved">
              <text>{'© Copyright [2020] ABB. All rights reserved.'}</text>
              <span className="copyright-page-space">{`PAGE ${1}`}</span>
            </GridRow>
          </Grid>
        </>
      );
    }

    return sortedSettings.map((setName, index) => {
      if (setName) {
        const secondPage2 = setName.indexOf('&&secondPage') !== -1 ? setName.split('&&secondPage')[0] : null;
        return (
          <>
            <Grid className="page-number-rectangle">
              <GridRow className="page-number" horizontalAlignment="center">
                <GridColumn>{`Page ${index + 1}`}</GridColumn>
              </GridRow>
            </Grid>
            <Grid className="rectangle">
              <GridRow className="report-logo">
                <img src={logoUrl} width={100} height={50}></img>
              </GridRow>
              <GridRow horizontalAlignment={'center'}>
                <GridRow className="device-product-name">
                  <div className={'device-stripe'}></div>
                  {selectivityDiagram ? t('CURVESWEB_PRINT_HEADER') : diagramName}
                  {index === 0 ? renderGeneralInfo(GENERAL_INFORMATION_VALUE_NAMES) : null}
                </GridRow>
                <GridRow horizontalAlignment="center" className="diagramName">
                  {index === 0 && selectivityDiagram ? diagramName : null}
                </GridRow>
                <GridRow horizontalAlignment="center">
                  {index === 0 || !selectivityDiagram ? <img src={svgDiagramUrl} width="650" height="455"></img> : null}
                  <GridColumn>
                    {/* <div style={{height: '40px'}}></div> */}
                    {!secondPage2 && !_.isEmpty(settings)
                      ? renderSettings({ [setName]: settings[setName] })
                      : settings && !_.isEmpty(settings)
                      ? renderSettings({ [secondPage2 as string]: settings[setName] })
                      : null}
                  </GridColumn>
                </GridRow>
              </GridRow>
            </Grid>
            <Grid className={'copyright-page-space-rectangle'}>
              <GridRow className="abb-all-rights-reserved">
                <text>{'© Copyright [2020] ABB. All rights reserved.'}</text>
                <span className="copyright-page-space">{`PAGE ${index + 1}`}</span>
              </GridRow>
            </Grid>
          </>
        );
      }
      return <></>;
    });
  };

  const renderGeneralInfo = (valueNames: Array<string>) => {
    return valueNames.map((name, index) => (
      <GridRow key={`ReportsPageGridROW${name}${index}`} className={'curves-data-rows'}>
        <GridColumn className="curves-data-keys" key={`ReportsPageTextContentName${name}${index}`} col={4}>
          {`${name}:`}
        </GridColumn>
        <GridColumn className="curves-data-keys" key={`ReportsPageTextContentValue${name}${index}`} col={4}>
          {getTextValue(name, state)}
        </GridColumn>
      </GridRow>
    ));
  };

  const renderSettings = (settings: Record<string, Array<IDeviceCurveSetting>>) => {
    return Object.keys(settings).map((deviceName, index) => (
      <>
        <GridRow key={`ReportsPageGridROW${deviceName}${index}`} className={'curves-data-rows'}>
          <GridColumn
            className="curves-data-keys device-setting-name"
            key={`ReportsPageTextContentName${deviceName}${index}`}
            col={8}
          >
            {`${deviceName}`}
          </GridColumn>
        </GridRow>
        {renderTwoColumnSettings(settings[deviceName], 15)}
      </>
    ));
  };

  const renderTwoColumnSettings = (settings: Array<IDeviceCurveSetting>, paddingLeft: number, nested = false) => {
    return settings.map((setting, index) => (
      <GridRow key={`ReportsPageGridROWSETTINGS${setting.name}${index}`} className={nested ? '' : 'curves-data-rows'}>
        <GridColumn
          className="curves-data-keys"
          style={{ paddingLeft }}
          key={`ReportsPageTextContentNameSETTINGS${setting.name}${index}`}
          col={4}
        >
          {`${setting.name}:`}
        </GridColumn>
        <GridColumn className="curves-data-keys" key={`ReportsPageTextContentValueSETTINGS${setting.name}${index}`} col={4}>
          {t(setting.value)}
        </GridColumn>
        {setting.nested ? renderTwoColumnSettings(setting.nested, paddingLeft + 15, true) : null}
      </GridRow>
    ));
  };

  const renderReports = () => {
    const { diagramsForPrint } = state.project;
    const { curve2Show } = state.curves;
    if (!curve2Show || !Object.keys(diagramsForPrint).some((name) => diagramsForPrint[name].checked)) {
      // dispatch(showModalDialog({
      //   title: 'No diagram\'s selected.',
      //   message: 'You need to select diagram for print.',
      // }));
      return <></>;
    }
    return Object.keys(diagramsForPrint).map((diagram) => {
      const currentDiagram = diagramsForPrint[diagram];
      if (currentDiagram.checked && currentDiagram.svgContent) {
        const curveDiagram = curve2Show.find((c) => c.id === diagram);
        const diagramName = diagramComboNames[curveDiagram?.name as keyof typeof diagramComboNames]
          ? t(diagramComboNames[curveDiagram?.name as keyof typeof diagramComboNames], { 'value[0]': [curveDiagram?.sign] })
          : (curveDiagram?.name as string) + (curveDiagram?.sign !== null ? ` ${curveDiagram?.sign as string}` : '');
        // const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="640" height="480" version="1.1">` + currentDiagram.svgContent + '</svg>';
        const blob = new Blob([currentDiagram.svgContent], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);
        const settings = getSettings(state, diagram, t);
        const selectedDeviceObjectSign = state.devices[state.project.selectedDeviceId ?? ''].ObjectSign as string;
        return reportBody(diagramName, settings, url, selectedDeviceObjectSign);
      }
      return <></>;
    });
  };

  return <Grid>{renderReports()}</Grid>;
};

// eslint-disable-next-line react/display-name
const ForwardedReport = React.forwardRef((props, ref) => {
  return (
    <div ref={ref as React.LegacyRef<HTMLDivElement>}>
      <ReportComponent />
    </div>
  );
});
export default ForwardedReport;
