import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'IMPEDANCE',
  objectType: EDeviceObjectType.Impedance,
  deviceType: EDeviceType.IMPEDANCE,
  ports: [],
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNC,
  Uref: 400.0,
  frequency: 50,
  LiveResistance: 0.1,
  NeutralResistance: 0.1,
  PEResistance: 0.1,
  LiveReactance: 0.1,
  NeutralReactance: 0.1,
  PEReactance: 0.1,
  impedancePhase: 0.14142135623730953,
  impedanceNeutral: 0.14142135623730953,
  impedancePE: 0.14142135623730953,
  LoadCurrent: 16.037507485085534,
};
