import { ApplicationState } from 'types/store';

export const gzipWorker = (data: string): Promise<string> => {
  return new Promise((resolve) => {
    const worker = new Worker(new URL('./gzipCompression.ts', import.meta.url));
    worker.addEventListener('message', (event) => {
      worker.terminate();
      resolve(event.data as string);
    });
    worker.postMessage(data);
  });
};

export const xml2jsonWorker = (graphML: string, state: ApplicationState): Promise<string> => {
  return new Promise((resolve) => {
    const worker = new Worker(new URL('./xml2jsonParser.ts', import.meta.url));
    worker.addEventListener('message', (event) => {
      worker.terminate();
      resolve(event.data as string);
    });
    const parserParams = {
      state,
      graphML,
    };
    worker.postMessage(JSON.stringify(parserParams));
  });
};

// Blob worker example
// export const blobWorker = (data) => {
//   // create blob from JavaScript code (ES6 template literal)
//   const blob = new Blob([`
//   import pako from 'pako';
// self.onmessage = function(e) {
//     const xx = pako.gzip(e.data);
//     postMessage(xx);
// }
// `]);
//   // create blob url from blob
//   const blobURL = window.URL.createObjectURL(blob);
//   // create web worker from blob url
//   const worker = new Worker(blobURL);
//   // send event to web worker
//   worker.postMessage(data);
//   // listen to message event from web worker
//   worker.onmessage = function(event) {
//     console.log(event.data);
//   };
// };
