import React, { FunctionComponent } from 'react';
import { IGridRow } from 'types/components/grid';
import './style.scss';

const GridRow: FunctionComponent<IGridRow> = ({
  children,
  verticalAlignment,
  horizontalAlignment,
  className,
  hidden,
  style,
  onBlur,
  onFocus,
  onClick,
  id,
}): React.ReactElement => {
  return (
    <div
      id={id}
      onBlur={onBlur}
      style={style}
      onFocus={onFocus}
      onClick={onClick}
      hidden={hidden}
      className={`DOCweb__CommonUX_GridRow ${verticalAlignment ? `DOCweb__CommonUX_GridRow_${verticalAlignment}` : ''} ${
        className || ''
      } ${horizontalAlignment ? `DOCweb__CommonUX_GridRow_${horizontalAlignment}` : ''}`}
      role="presentation"
    >
      {children}
    </div>
  );
};

export default GridRow;
