/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// @ts-nocheck
/* eslint-disable react/display-name */
import React, { FunctionComponent } from 'react';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { useSelector, useDispatch } from 'store';
import Select from 'components/generic/selectContainer';
import { inputUpdateHandler, getDeviceWishes } from 'devices/fuse/actions';
import { useTranslation } from 'react-i18next';
import { Table, TableHeader } from 'components/generic/table';
import { IFuse } from 'types/devices/fuse';
import { Popup, WithPopup } from '@abb/abb-common-ux-react';
import { PowerDevice } from 'types/devices/device';

const SelectionTab: FunctionComponent<IFuse> = ({ selectedDevice, selectedDeviceId }): React.ReactElement => {
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.variables);
  const compute = useSelector((state) => state.compute);
  const { t } = useTranslation();

  const filters = (): React.ReactElement[] => {
    const ieList = [...selectedDevice.ieList];
    ieList[0] = 'All possibilities';
    const incList = [...selectedDevice.incList];
    incList[0] = 'All possibilities';
    const inList = [...selectedDevice.inList];
    inList[0] = 'All possibilities';
    const obsoleteFamilies = variables.ObsoleteFamilies.name;
    const disabledFamilies = variables.DisabledProductFamilies.name;
    const { familyList } = selectedDevice;
    const familyColor: IFuse['familyColor'] = {};
    if (variables.ObsoleteFamilies && variables.DisabledProductFamilies) {
      disabledFamilies.forEach((family) => {
        familyColor[family] = 'grey';
      });
      obsoleteFamilies.forEach((family) => {
        familyColor[family] = '#CB6745';
      });
    }
    const sortedfamilyFilterList: Array<string> = [];
    const ObsoleteFamilies: Array<string> = [];
    const DisabledProductFamilies: Array<string> = [];
    const familyFilterList: Array<string> = [];
    obsoleteFamilies.forEach((value) => (familyList.includes(value) ? ObsoleteFamilies.push(value) : null));
    disabledFamilies.forEach((value) =>
      familyList.includes(value) && !sortedfamilyFilterList.includes(value) && !obsoleteFamilies.includes(value)
        ? DisabledProductFamilies.push(value)
        : null
    );
    familyList.forEach((value) =>
      !ObsoleteFamilies.includes(value) && !DisabledProductFamilies.includes(value) ? familyFilterList.push(value) : null
    );
    sortedfamilyFilterList.push(...familyFilterList, ...DisabledProductFamilies, ...ObsoleteFamilies);
    return [
      <GridRow key={1}>
        <GridColumn col={5}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="familyOutput"
            label="STRINGS_SUMTAB_FAMILY"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={sortedfamilyFilterList}
            selectList={sortedfamilyFilterList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
            style={familyColor}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={2}>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="ieOutput"
            label="STRINGS_SWITCH_IE_AC21_HEAD"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.ieList}
            selectList={ieList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="sizeOutput"
            label="STRING_SIZE"
            type="string"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.sizeList}
            selectList={selectedDevice.sizeList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="incOutput"
            label="STRINGS_SWITCH_INC_HEAD"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.incList}
            selectList={incList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="linksOutput"
            label="DOCWEB_FUSE_MODAL_LINK"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.linksList}
            selectList={selectedDevice.linksList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="inOutput"
            label="STRING_IN"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.inList}
            selectList={inList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={3}>
        <GridColumn col={2} offset={6} horizontalAlignment="center">
          <label>{`${selectedDevice.ProductDescription as string} ${selectedDevice.FuseHolderModelName}`}</label>
        </GridColumn>
      </GridRow>,
      <GridRow key={4}>
        <GridColumn col={5}>
          <img className="circuit-breaker-img" src={`images/Foto/${selectedDevice.Picture}.bmp`} alt="" />
        </GridColumn>
      </GridRow>,
    ];
  };
  const fuseTable = (): React.ReactElement[] => {
    return [
      <GridRow key={0}>
        <GridColumn col={8}>
          <span>{t('DOCWEB_FUSE_MODAL_AVAIBLEFUSEGEARS')}</span>
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={8}>
          <Table
            data={selectedDevice.DevicesList}
            selectedValue={selectedDevice.DeviceId}
            valueAttr="gearsId"
            customName="DeviceId"
            disabled={selectedDevice.requestRunning}
            onRowClick={(e) => dispatch(inputUpdateHandler(e))}
            maxHeight={150}
            specificColumnWidth={['auto', '250px', '100px', '100px']}
          >
            <TableHeader attr="description" key={0}>
              {t('STRING_DESCRIPTION')}
            </TableHeader>
            <TableHeader attr="fuseSize" key={1}>
              {t('DOCWEB_FUSE_MODAL_FUSESIZETABLEHEADER')}
            </TableHeader>
            <TableHeader attr="ie" key={2}>
              {t('STRINGS_SWITCH_IE_AC21_HEAD')}
            </TableHeader>
            <TableHeader attr="inc" key={3}>
              {t('STRINGS_SWITCH_INC_HEAD')}
            </TableHeader>
          </Table>
          <div style={{ marginTop: '20px' }} />
          <span>{t('DOCWEB_FUSE_MODAL_AVAIBLEFUSELINKS')}</span>
          <Table
            data={selectedDevice.fuseLinksList}
            selectedValue={selectedDevice.fuseLinksOutput}
            valueAttr="linksId"
            customName="fuseLinksOutput"
            disabled={selectedDevice.requestRunning}
            onRowClick={(e) => dispatch(inputUpdateHandler(e))}
            maxHeight={150}
            specificColumnWidth={['auto', '300px', '150px']}
          >
            <TableHeader attr={'description'} key={'FuseSelectionTabTableHeader2description'}>
              {t('STRING_DESCRIPTION')}
            </TableHeader>
            <TableHeader attr={'fuseLink'} key={'FuseSelectionTabTableHeader2fuseLink'}>
              {t('STR_IDC_FUSELINK_LABEL')}
            </TableHeader>
            <TableHeader attr={'iNom'} key={'FuseSelectionTabTableHeader2iNom'}>
              {t('STRING_IN')}
            </TableHeader>
          </Table>
        </GridColumn>
      </GridRow>,
    ];
  };
  return (
    <Grid>
      {filters()}
      <hr />
      {fuseTable()}
    </Grid>
  );
};

export default SelectionTab;
