import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export const mvUtilityDefaultState: Record<string, unknown> = {
  ...defaultElement,
  symbol: 'UTILITY_MV_SHORT',
  objectType: EDeviceObjectType.PowerSupply,
  deviceType: EDeviceType.UTILITY_MV_SHORT,

  ISecondK: 10000,
  sk: 6928000,
  ISecondKMin: 10000,
  SkMin: 6928000,
  ShortCircuitCurrentToGroundMin: 50,
  Sr: 25,
  ukr: 4,
  PowerSourceTransformer: 0,
  Uref: 20000.0,
  PlantLvUn: 1000,
  NominalVoltage: 20000.0,
  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  frequency: 50,
  CosPhiSc: 0.10000000000000001,
  ISecondKLN: 6000,
  CosPhiScLN: 0.10000000000000001,
  ISecondKLE: 6000,
  CosPhiScLE: 0.10000000000000001,
  ComputedP: 0,
  ComputedQ: 0,
  LoadCurrent: 0,
  ComputedCosPhi: 0,
  Sk: 259807.621135332,
  neutral: 'Neutral balanced',
  computeShortCircuitCurrent: false,
};

export const defaultMvUtilitySmallState: Record<string, unknown> = {
  ...mvUtilityDefaultState,
  deviceType: EDeviceType.UTILITY_MV_SMALL,
  objectType: EDeviceObjectType.UtilityMVSmall,
};
