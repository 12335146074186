import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  objectType: EDeviceObjectType.MvEarthDisconnector,
  deviceType: EDeviceType.MVEDISC,
  Locked: false,

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
};
