import { addElements, removeCells } from 'devices/actions';
import { genericStates } from 'project/projectTemplate';
import { uuid } from 'actions/utils';
import { setSelectedDevice } from 'project/actions';
import { changeTab } from 'ui/modals/actions';
import { EDeviceType } from 'types/devices/enums.d';
import { ThunkAction } from 'types/known-actions';
import { DocCell } from 'types/devices/cell';
import { updateWizardParams } from 'ui/startupWizard/actions';

export const UPDATE_POWER_SUPPLY_ERRORS = 'UPDATE_POWER_SUPPLY_ERRORS';

export const addDevice =
  (categoryName: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.wizard.wizardDeviceId) {
      dispatch(removeCells([state.wizard.wizardDeviceId]));
    }
    dispatch(
      dispatch({
        type: 'SET_SELECTED_DEVICE',
        id: '',
      })
    );
    let deviceType = EDeviceType.CB_TM;
    let changeToTab = 'STRINGS_IDD_CONFIGS';
    switch (categoryName) {
      case 'Miniature Circuit':
      case 'mouldedCaseBreaker':
      case 'airBreaker':
      case 'miniatureBreaker':
        deviceType = EDeviceType.CB_TM;
        break;
      case 'Manual Motor Starter':
      case 'manualMotorStarter':
        deviceType = EDeviceType.MMS;
        break;
      case 'residualCurrentBreaker':
      case 'RCCB Device':
        deviceType = EDeviceType.RCCB;
        break;
      case 'Fuse Device':
      case 'fuse':
        deviceType = EDeviceType.FUSEBASE;
        break;
      case 'cable':
        deviceType = EDeviceType.WIRELV;
        changeToTab = 'Properties';
        break;
      case 'MvCircuitBreaker':
        deviceType = EDeviceType.CIRCUITBREAKERMV;
        break;
      case 'MvSwitch':
        deviceType = EDeviceType.MVSWITCH;
        break;
      case 'MvCable':
        deviceType = EDeviceType.WIREMV;
        break;
      case 'MvFuse':
        deviceType = EDeviceType.FUSEMV;
        break;
      default:
        deviceType = EDeviceType.CB_TM;
        break;
    }

    const initialState = genericStates[deviceType].defaultState;
    if (initialState) {
      const devices = Object.keys(state.devices);
      let maxX = 0;
      let maxY = 0;
      if (devices.length > 0) {
        devices.forEach((device) => {
          if (state.devices[device].x > maxX) {
            maxX = state.devices[device].x;
          }
          if (state.devices[device].y > maxY) {
            maxY = state.devices[device].y;
          }
        });
      }

      const cell = {
        clonesParams: initialState,
        cell: {
          attributes: {
            position: { x: maxX + 10, y: maxY + 10 },
            size: { width: 1, height: 1 },
            id: uuid(),
            type: deviceType,
          },
        },
      };
      if (deviceType === EDeviceType.CIRCUITBREAKERMV || deviceType === EDeviceType.FUSEMV) {
        const feederId = uuid();
        cell.clonesParams = {
          ...cell.clonesParams,
          feederId,
          parentId: feederId,
          MvSwitchBoardName: '8432279c-3762-4bfd-8fb8-ea6d75e58173',
        };
      }
      dispatch(addElements([cell as unknown as DocCell]));
      dispatch(updateWizardParams({ wizardDeviceId: cell.cell.attributes.id }));
      dispatch(setSelectedDevice(cell.cell.attributes.id));
      dispatch(changeTab(changeToTab, true));
    }
  };
