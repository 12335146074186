import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';
import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'ATS',
  objectType: EDeviceObjectType.Ats,
  deviceType: EDeviceType.ATS,
  Locked: false,

  // General info
  Name: '',
  Description: '',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400.0,
  // Filters //////////////
  PreFiltersChanged: true,
  // Family
  FamilyFilter: 'All possibilities',
  familyFilterList: [],

  AtsNeutral: 'All possibilities',
  neutralList: ['All possibilities', 'Overlapped', 'Switched'],

  AtsTransition: 'All possibilities',
  transitionList: ['All possibilities', 'Open', 'Delayed'],

  AtsController: 'All possibilities',
  controllerList: ['All possibilities', 'Level 2 - Dip', 'Level 3 - LCD', 'Level 4 - Touch'],

  AtsStyle: 'All possibilities',
  styleList: ['All possibilities', 'Open', 'Enclosed IP54'],

  AtsCabling: 'All possibilities',
  cablingList: ['All possibilities', 'Bottom entry', 'Top entry'],

  familyOutput: 'All possibilities',
  familyList: [],

  // Type
  TypeFilter: 'All possibilities',
  typeFilterList: [],

  // PolesNumber
  PolesFilter: '4P',
  polesFilterList: ['2P', '3P', '4P'],

  polesOutput: '',
  polesOutputList: [],

  sizeOutput: -1,
  sizeOutputList: [],

  icwOutput: -1,
  icwOutputList: [],

  icmOutput: -1,
  icmOutputList: [],

  terminalsOutput: '',
  terminalsOutputList: [],

  VersionFilter: 'All possibilities',
  versionFilterList: [],

  versionOutput: 'All possibilities',
  versionList: [],

  DevicesList: [],
  disconnectorsOutput: -1,
  ProductDescription: '',
  ProductIdList: null,
  DeviceId: 0,
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,
  deviceDescriptionDisplay: [],
  deviceIcmDisplay: [],
  deviceIcwDisplay: [],
  deviceSizeDisplay: [],
  deviceStartIndex: 0,
  deviceEndIndex: 0,
  useIcw: false,
  useIcm: false,
  Icw: 0,
  Icm: 0,
  initialized: false,
  requestRunning: false,
  IsOffInScenario: {
    name: [],
  },
  IsOffInScenario2: {
    name: [],
  },
};
