/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  LabelsKnownActions,
  // ModalKnownActions
} from 'types/known-actions';
import * as LabelsNamespace from 'types/labels';
import { LABELS, PROJECT } from 'types/constants.d';
import _ from 'lodash';

export const initialState: LabelsNamespace.State = {
  'Power suppliers': {
    'Power supply': {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      Uref: {
        name: 'STRING_REPUREF',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      NominalVoltage: {
        name: 'Nominal voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      PhasesEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      LineTypeEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ISecondK: {
        name: 'Short circuit current LLL',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ISecondKLN: {
        name: 'Short circuit current LN',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ISecondKLE: {
        name: 'Short circuit current L -PE',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedP: {
        name: 'Requested active power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedQ: {
        name: 'Requested reactive power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      Order: [
        'ObjectSign',
        'Uref',
        'NominalVoltage',
        'PhasesEarthingSystem',
        'ISecondK',
        'ISecondKLN',
        'ISecondKLE',
        'ComputedP',
        'ComputedQ',
      ],
    },
    // GENERATOR
    Generator: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      PhasesEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },

      NominalVoltage: {
        name: 'Reference voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      NominalPower: {
        name: 'NominalPower',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedP: {
        name: 'Active power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedQ: {
        name: 'Reactive power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      Order: ['ObjectSign', 'PhasesEarthingSystem', 'NominalVoltage', 'NominalPower', 'ComputedP', 'ComputedQ'],
    },
    Transformer: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      PrimaryWindingUn: {
        name: 'Rated voltage on 1st winding',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      SecondaryWindingUn: {
        name: 'Rated voltage on 2nd winding',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      Sr: {
        name: 'STRING_REPTRAFO2SN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      PrimaryWindingLineTypePrimaryWindingEarthingSystem: {
        name: 'Circuit type at 1st winding, Distribution system',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      SecondaryWindingLineTypeSecondaryWindingEarthingSystem: {
        name: 'Circuit type at 2nd winding, Distribution system',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      uRr: {
        name: 'Short-circuit power losses 1-2',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ukr: {
        name: 'Vsc 1-2',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      GroundingResistance: {
        name: 'Grounding resistance',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Po: {
        name: 'Po',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      dU: {
        name: 'dU',
        display: true,
        inSpreadsheet: false,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      Order: [
        'ObjectSign',
        'ProductDescription',
        'UserDescription1',
        'PrimaryWindingUn',
        'SecondaryWindingUn',
        'Sr',
        'PrimaryWindingLineTypePrimaryWindingEarthingSystem',
        'SecondaryWindingLineTypeSecondaryWindingEarthingSystem',
        'uRr',
        'Po',
        'ukr',
        'dU',
        'GroundingResistance',
      ],
    },
    Ups: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      Uref: {
        name: 'Reference voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      LineTypeEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      LoadCurrent: {
        name: 'Load current IB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      CosPhiNominal: {
        name: 'Power factor -cos(phi)',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      IScInRatio: {
        name: 'Isc/In',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ComputedP: {
        name: 'Requested active power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedQ: {
        name: 'Requested reactive power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      NominalPower: {
        name: 'Nominal power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      Order: [
        'ObjectSign',
        'ProductDescription',
        'UserDescription1',
        'Uref',
        'LineTypeEarthingSystem',
        'NominalPower',
        'LoadCurrent',
        'CosPhiNominal',
        'IScInRatio',
        'ComputedP',
        'ComputedQ',
      ],
    },
    Renewables: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      NominalVoltage: {
        name: 'Nominal voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      NominalPower: {
        name: 'Nominal Power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      LineTypeEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedP: {
        name: 'Requested active power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedQ: {
        name: 'Requested reactive power',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: true,
      },
      Order: ['ObjectSign', 'NominalVoltage', 'NominalPower', 'LineTypeEarthingSystem', 'ComputedP', 'ComputedQ'],
    },
  },
  'MV Devices': {
    'MV Circuit-breaker': {
      ObjectStatusIsOff: {
        name: 'OFF',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      CurrentTransformerModelName: {
        name: 'Current Transformer Model Name',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      HomopolarTransformerModelName: {
        name: 'Homopolar Transformer Model Name',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      TripUnitModelName: {
        name: 'Trip Unit Model Name',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Locked: {
        name: 'Locked',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      MvSwitchBoardName: {
        name: 'STRINGS_SUMTAB_SWITCHNAME',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      RcbModelName: {
        name: 'Residual current Circuit-breaker description',
        display: false,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      ScLiveMax: {
        name: 'SC Live Max',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      '3I0To': {
        name: '3I0To',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      SelectivityLevel: {
        name: 'Selectivity level',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      Order: [
        'ObjectStatusIsOff',
        'ObjectSign',
        // 'CurrentTransformerModelName',
        // 'HomopolarTransformerModelName',
        // 'TripUnitModelName',
        'Locked',
        // 'MvSwitchBoardName',
        'ProductDescription',
        'UserDescription1',
        // 'RcbModelName',
        'LoadCurrent',
        // 'ComputedCosPhi',
        'ScLiveMax',
        '3I0To',
        'SelectivityLevel',
      ],
    },
    // MVFUSE
    'MV Fuse': {
      '3I0To': {
        name: '3I0To',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      ComputedCosPhi: {
        name: 'ComputedCosPhi',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      FuseLink: {
        name: 'FuseLink',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      FuseSize: {
        name: 'FuseSize',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      In: {
        name: 'In',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'LoadCurrent',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Locked: {
        name: 'Locked',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ObjectSign: {
        name: 'ObjectSign',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ObjectStatusIsOff: {
        name: 'ObjectStatusIsOff',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ProductDescription: {
        name: 'ProductDescription',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ScLiveMax: {
        name: 'ScLiveMax',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      MvSwitchBoardName: {
        name: 'SwitchBoardName',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: [
        'ObjectStatusIsOff',
        'Locked',
        'MvSwitchBoardName',
        'ObjectSign',
        'ProductDescription',
        'UserDescription1',
        'FuseLink',
        'In',
        'FuseSize',
        'LoadCurrent',
        'ComputedCosPhi',
        'ScLiveMax',
        '3I0To',
      ],
    },
    'MV Switch': {
      ComputedCosPhi: {
        name: 'ComputedCosPhi',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'LoadCurrent',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Locked: {
        name: 'Locked',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ObjectStatusIsOff: {
        name: 'ObjectStatusIsOff',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ProductDescription: {
        name: 'ProductDescription',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      RcbModelName: {
        name: 'RcbModelName',
        display: false,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScLiveMax: {
        name: 'ScLiveMax',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      MvSwitchBoardName: {
        name: 'SwitchBoardName',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: [
        'ObjectStatusIsOff',
        'Locked',
        'MvSwitchBoardName',
        'ObjectSign',
        'ProductDescription',
        // 'RcbModelName',
        'UserDescription1',
        'LoadCurrent',
        'ComputedCosPhi',
        'ScLiveMax',
      ],
    },
    // 'MV Disconnector': {
    //   ComputedCosPhi: {
    //     name: 'ComputedCosPhi',
    //     display: false,
    //     inSpreadsheet: true,
    //     inDeviceData: false,
    //     inFaultCurrents: false,
    //     inPowerFlow: true,
    //     inColumns: false,
    //   },
    //   LoadCurrent: {
    //     name: 'LoadCurrent',
    //     display: true,
    //     inSpreadsheet: true,
    //     inDeviceData: false,
    //     inFaultCurrents: false,
    //     inPowerFlow: true,
    //     inColumns: false,
    //   },
    //   Locked: {
    //     name: 'Locked',
    //     display: true,
    //     inSpreadsheet: true,
    //     inDeviceData: true,
    //     inFaultCurrents: true,
    //     inPowerFlow: true,
    //     inColumns: true,
    //   },
    //   ObjectSign: {
    //     name: 'ObjectSign',
    //     display: true,
    //     inSpreadsheet: true,
    //     inDeviceData: true,
    //     inFaultCurrents: true,
    //     inPowerFlow: true,
    //     inColumns: true,
    //   },
    //   ObjectStatusIsOff: {
    //     name: 'ObjectStatusIsOff',
    //     display: true,
    //     inSpreadsheet: false,
    //     inDeviceData: false,
    //     inFaultCurrents: false,
    //     inPowerFlow: false,
    //     inColumns: true,
    //   },
    //   ProductDescription: {
    //     name: 'ProductDescription',
    //     display: true,
    //     inSpreadsheet: true,
    //     inDeviceData: true,
    //     inFaultCurrents: false,
    //     inPowerFlow: true,
    //     inColumns: false,
    //   },
    //   RcbModelName: {
    //     name: 'RcbModelName',
    //     display: false,
    //     inSpreadsheet: true,
    //     inDeviceData: true,
    //     inFaultCurrents: false,
    //     inPowerFlow: false,
    //     inColumns: false,
    //   },
    //   ScLiveMax: {
    //     name: 'ScLiveMax',
    //     display: false,
    //     inSpreadsheet: true,
    //     inDeviceData: false,
    //     inFaultCurrents: true,
    //     inPowerFlow: false,
    //     inColumns: false,
    //   },
    //   MvSwitchBoardName: {
    //     name: 'SwitchBoardName',
    //     display: true,
    //     inSpreadsheet: false,
    //     inDeviceData: false,
    //     inFaultCurrents: false,
    //     inPowerFlow: false,
    //     inColumns: false,
    //   },
    //   UserDescription1: {
    //     name: 'STRING_REPUSAGE',
    //     display: true,
    //     inSpreadsheet: true,
    //     inDeviceData: true,
    //     inFaultCurrents: true,
    //     inPowerFlow: true,
    //     inColumns: false,
    //   },
    //   Order: [
    //     'ObjectStatusIsOff',
    //     'Locked',
    //     'MvSwitchBoardName',
    //     'ObjectSign',
    //     'ProductDescription',
    //     'RcbModelName',
    //     'UserDescription1',
    //     'LoadCurrent',
    //     'ComputedCosPhi',
    //     'ScLiveMax',
    //   ],
    // },
  },
  'Lv Devices': {
    'Lv Circuit-breaker': {
      ObjectStatusIsOff: {
        name: 'OFF',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      RcbModelName: {
        name: 'Residual current Circuit-breaker description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      In: {
        name: 'STRINGS_IDC_IN_TXT2',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      PolesFilter: {
        name: 'STRING_POLES',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      SelectivityLevel: {
        name: 'Selectivity level',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      Icu: {
        name: 'Icu',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectStatusIsOff',
        'ObjectSign',
        'ProductDescription',
        'UserDescription1',
        'RcbModelName',
        'LoadCurrent',
        'In',
        'PolesFilter',
        // 'ComputedCosPhi',
        'SelectivityLevel',
        'Icu',
      ],
    },
    'Residual current Circuit-breaker': {
      ObjectStatusIsOff: {
        name: 'OFF',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      Order: ['ObjectStatusIsOff', 'ObjectSign', 'ProductDescription', 'UserDescription1', 'LoadCurrent'],
    },
    'Lv Fuse': {
      ObjectStatusIsOff: {
        name: 'OFF',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      FuseHolderModelName: {
        name: 'Gear Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      FuseLink: {
        name: 'Links',
        display: false,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      In: {
        name: 'Inom',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      FuseSize: {
        name: 'Size',
        display: false,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      SelectivityLevel: {
        name: 'Selectivity level',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      Icu: {
        name: 'Icu',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectStatusIsOff',
        'ObjectSign',
        'ProductDescription',
        'FuseHolderModelName',
        'UserDescription1',
        'FuseLink',
        'In',
        'FuseSize',
        'LoadCurrent',
        // 'ComputedCosPhi',
        'SelectivityLevel',
        'Icu',
      ],
    },
    OverloadRelay: {
      ObjectStatusIsOff: {
        name: 'OFF',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      In: {
        name: 'STRINGS_IDC_IN_TXT2',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['ObjectStatusIsOff', 'ObjectSign', 'ProductDescription', 'In'],
    },
    // MANUAL MOTOR STARTER
    'Manual motor starter': {
      ObjectStatusIsOff: {
        name: 'ObjectStatusIsOff',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      Order: ['ObjectStatusIsOff', 'ObjectSign', 'ProductDescription', 'LoadCurrent'],
    },
    Softstarter: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['ObjectSign', 'ProductDescription'],
    },
    Contactor: {
      ObjectStatusIsOff: {
        name: 'ObjectStatusIsOff',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'UserDescription1',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['ObjectStatusIsOff', 'ObjectSign', 'ProductDescription', 'UserDescription1'],
    },
    Disconnector: {
      ObjectStatusIsOff: {
        name: 'OFF',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['ObjectStatusIsOff', 'ObjectSign', 'ProductDescription', 'UserDescription1'],
    },
    Ats: {
      ObjectStatusIsOff: {
        name: 'ObjectStatusIsOff',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: true,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['ObjectStatusIsOff', 'ObjectSign', 'ProductDescription', 'UserDescription1'],
    },
  },
  Wirings: {
    'LV Cable': {
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      InstallationItem: {
        name: 'DOCWEB_STRING_INSTALLATION',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      CrossSectionDescription: {
        name: 'STRINGS_IDD_SOMSEC',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CarryingCapacityIz: {
        name: 'Carrying capacity Iz',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      VoltageDrop: {
        name: 'STRINGS_IDC_VOLTDROP_TXT',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CableLength: {
        name: 'Length',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LiveResistance: {
        name: 'Resistance',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LiveReactance: {
        name: 'Reactance',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      CableLengthIFE: {
        name: 'IFE Length',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      IFETemperature: {
        name: 'IFE temperature',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      IFEClass: {
        name: 'FE class',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'UserDescription1',
        'InstallationItem',
        'CrossSectionDescription',
        // 'ComputedCosPhi',
        'LoadCurrent',
        'CarryingCapacityIz',
        'VoltageDrop',
        'CableLength',
        'CableLengthIFE',
        'LiveResistance',
        'LiveReactance',
        'IFETemperature',
        'IFEClass',
      ],
    },
    'MV Cable': {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Conductor: {
        name: 'Conductor',
        display: false,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      CrossSectionDescription: {
        name: 'STRINGS_IDD_SOMSEC',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Insulator: {
        name: 'Insulator',
        display: false,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      // ComputedCosPhi: {
      //   name: 'Power factor -cos(phi)',
      //   display: false,
      //   inSpreadsheet: true,
      //   inDeviceData: false,
      //   inFaultCurrents: false,
      //   inPowerFlow: true,
      //   inColumns: false,
      // },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CarryingCapacityIz: {
        name: 'Carrying capacity Iz',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      VoltageDrop: {
        name: 'STRINGS_IDC_VOLTDROP_TXT',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CableLength: {
        name: 'Length',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LiveResistance: {
        name: 'Resistance',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LiveReactance: {
        name: 'Reactance',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Ce: {
        name: 'Ce',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'UserDescription1',
        'Conductor',
        'CrossSectionDescription',
        'Insulator',
        // 'ComputedCosPhi',
        'LoadCurrent',
        'CarryingCapacityIz',
        'VoltageDrop',
        'CableLength',
        'LiveResistance',
        'LiveReactance',
        'Ce',
      ],
    },
    'Generic impedance': {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LiveResistance: {
        name: 'Resistance',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      LiveReactance: {
        name: 'Reactance',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      NeutralResistance: {
        name: 'Resistance of Neutral',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      NeutralReactance: {
        name: 'DOCWEB_STRING_REACTANCE_OF_NEUTRAL',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      PEResistance: {
        name: 'DOCWEB_STRING_RESISTANCE_OF_PE',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      PEReactance: {
        name: 'DOCWEB_STRING_REACTANCE_OF_PE',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'LoadCurrent',
        'LiveResistance',
        'LiveReactance',
        'NeutralResistance',
        'NeutralReactance',
        'PEResistance',
        'PEReactance',
      ],
    },
    Busbar: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ComputedVoltage: {
        name: 'Computed voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedCosPhi: {
        name: 'Power factor -cos(phi)',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      IkMax: {
        name: 'DOCWEB_STRING_MAX_SHORTCURCUIT_CURRENT',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: false,
        inColumns: false,
      },
      DemandFactor: {
        name: 'DOCWEB_STRING_BUSBARDEMANDFACTOR',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ScIEC60909L3ISecondK: {
        name: 'DOCWEB_STRING_IEC60909_I_SECOND_k_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3Ik: {
        name: 'DOCWEB_STRING_IEC60909_Ik_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3Ip: {
        name: 'DOCWEB_STRING_IEC60909_Ip_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2ISecondK: {
        name: 'DOCWEB_SRRING_IEC60909_I_SECOND_k_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2Ik: {
        name: 'DOCWEB_STRING_IEC60909_Ik_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2Ip: {
        name: 'DOCWEB_STRING_IEC60909_Ip_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralISecondK: {
        name: 'DOCWEB_STRING_IEC60909_I_SECOND_k_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIk: {
        name: 'DOCWEB_STRING_IEC60909_Ik_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIp: {
        name: 'DOCWEB_STRING_IEC60909_Ip_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEISecondK: {
        name: 'DOCWEB_STRING_IEC60909_I_SECOND_k_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIk: {
        name: 'DOCWEB_STRING_IEC60909_Ik_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIp: {
        name: 'DOCWEB_STRING_IEC60909_Ip_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363IsecondK: {
        name: 'DOCWEB_STRING_IEC60363_I_SECOND_k',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363Ik: {
        name: 'DOCWEB_STRING_IEC60363_Ik',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363Ip: {
        name: 'DOCWEB_STRING_IEC60363_Ip',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'ComputedVoltage',
        'LoadCurrent',
        'ComputedCosPhi',
        'IkMax',
        'DemandFactor',
        'ScIEC60909L3ISecondK',
        'ScIEC60909L3Ik',
        'ScIEC60909L3Ip',
        'ScIEC60909L2ISecondK',
        'ScIEC60909L2Ik',
        'ScIEC60909L2Ip',
        'ScIEC60909LNeutralISecondK',
        'ScIEC60909LNeutralIk',
        'ScIEC60909LNeutralIp',
        'ScIEC60909LPEISecondK',
        'ScIEC60909LPEIk',
        'ScIEC60909LPEIp',
        'ScIEC61363IsecondK',
        'ScIEC61363Ik',
        'ScIEC61363Ip',
      ],
    },
    Busway: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Locked: {
        name: 'Locked',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      ProductDescription: {
        name: 'ProductDescription',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CableLength: {
        name: 'Length',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      VoltageDrop: {
        name: 'STRINGS_IDC_VOLTDROP_TXT',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CarryingCapacityIz: {
        name: 'Carrying capacity Iz',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LiveResistance: {
        name: 'Resistance',
        display: true,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'UserDescription1',
        'Locked',
        'ProductDescription',
        'CableLength',
        'VoltageDrop',
        'LoadCurrent',
        'CarryingCapacityIz',
        'LiveResistance',
      ],
    },
  },
  'Cross reference': {
    Arrival: {
      LinkName: {
        name: 'Label',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['LinkName', 'UserDescription1'],
    },
    Departure: {
      LinkName: {
        name: 'Label',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['LinkName', 'UserDescription1'],
    },
    TypicalFeeder: {
      LinkName: {
        name: 'Label',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      Order: ['LinkName', 'UserDescription1'],
    },
  },
  Loads: {
    Load: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'Description',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      NominalPower: {
        name: 'DOCWEB_STRING_REFERENCTPOWER_AN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CosPhiNominal: {
        name: 'Power factor -cos(phi)',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Uf: {
        name: 'STRING_REPUSAGEFACTOR',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedVoltage: {
        name: 'Computed voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      VoltageDrop: {
        name: 'STRINGS_IDC_VOLTDROP_TXT',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedP: {
        name: 'Requested active power',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedQ: {
        name: 'Requested reactive power',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedPhaseShift: {
        name: 'DOCWEB_STRING_COMPUTED_PHASE_SHIFT',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      PhasesEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ProductDescription: {
        name: 'ProductDescription',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      AuxData1: {
        name: 'AuxData1',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      AuxData2: {
        name: 'AuxData2',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      AuxData3: {
        name: 'AuxData3',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      Order: [
        'ObjectSign',
        'UserDescription1',
        'NominalPower',
        'CosPhiNominal',
        'LoadCurrent',
        'Uf',
        'PhasesEarthingSystem',
        'ComputedVoltage',
        'VoltageDrop',
        'ComputedP',
        'ComputedQ',
        'ComputedPhaseShift',
        'ProductDescription',
        'AuxData1',
        'AuxData2',
        'AuxData3',
      ],
    },
    Capacitor: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      NominalPower: {
        name: 'DOCWEB_STRING_REFERENCTPOWER_AN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CosPhiNominal: {
        name: 'Power factor -cos(phi)',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'DOCWEB_STRING_LOAD_CURRENT_LABEL',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Uf: {
        name: 'STRING_REPUSAGEFACTOR',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      VoltageDrop: {
        name: 'Computed voltage',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedP: {
        name: 'Requested active power',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedQ: {
        name: 'Requested reactive power',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      // 'ComputedPhaseShift': {
      //   name: 'DOCWEB_STRING_COMPUTED_PHASE_SHIFT',
      //   display: false,
      // },
      // 'PhasesEarthingSystem': {
      //   name: 'Circuit type, Distribution system',
      //   display: false,
      // },
      Order: ['ObjectSign', 'NominalPower', 'CosPhiNominal', 'LoadCurrent', 'Uf', 'VoltageDrop', 'ComputedP', 'ComputedQ'],
    },
    Motor: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      NominalVoltage: {
        name: 'Reference voltage NominalVoltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Pn: {
        name: 'DOCWEB_STRING_RATED_POWER_PN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CosPhiNominal: {
        name: 'Nominal power factor',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      CosPhiRequested: {
        name: 'DOCWEB_STRING_COMPENSATED_POWER_FACTOR',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      Uf: {
        name: 'STRING_REPUSAGEFACTOR',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      VoltageDrop: {
        name: 'Computed voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedP: {
        name: 'Requested active power',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedQ: {
        name: 'Requested reactive power',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedVoltage: {
        name: 'STRINGS_IDC_VOLTDROP_TXT',
        display: false,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedPhaseShift: {
        name: 'DOCWEB_STRING_COMPUTED_PHASE_SHIFT',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      PhasesEarthingSystem: {
        name: 'Circuit type, Distribution system',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      GroundingResistance: {
        name: 'Grounding resistance',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'UserDescription1',
        'NominalVoltage',
        'Pn',
        'CosPhiNominal',
        'CosPhiRequested',
        'Uf',
        'PhasesEarthingSystem',
        'VoltageDrop',
        'ComputedP',
        'ComputedQ',
        'ComputedVoltage',
        'ComputedPhaseShift',
        'GroundingResistance',
      ],
    },
    Probe: {
      ObjectSign: {
        name: 'STRING_REPSIGN',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: true,
      },
      UserDescription1: {
        name: 'STRING_REPUSAGE',
        display: true,
        inSpreadsheet: true,
        inDeviceData: true,
        inFaultCurrents: true,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedPhaseShift: {
        name: 'DOCWEB_STRING_COMPUTED_PHASE_SHIFT',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ComputedVoltage: {
        name: 'Computed voltage',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      LoadCurrent: {
        name: 'STRING_REPLOADCURRENTIB',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ComputedCosPhi: {
        name: 'Power factor -cos(phi)',
        display: true,
        inSpreadsheet: true,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: true,
        inColumns: false,
      },
      ScIEC60909L3ISecondK: {
        name: 'DOCWEB_STRING_IEC60909_I_SECOND_k_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3Ik: {
        name: 'DOCWEB_STRING_IEC60909_Ik_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3Ip: {
        name: 'DOCWEB_STRING_IEC60909_Ip_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3Ib: {
        name: 'DOCWEB_STRING_IEC60909_Ib_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3Idc: {
        name: 'DOCWEB_STRING_IEC60909_Idc_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L3IbAsym: {
        name: 'DOCWEB_STRING_IEC60909_IbAsym_LLL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2ISecondK: {
        name: 'DOCWEB_SRRING_IEC60909_I_SECOND_k_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2Ik: {
        name: 'DOCWEB_STRING_IEC60909_Ik_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2Ip: {
        name: 'DOCWEB_STRING_IEC60909_Ip_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2Ib: {
        name: 'DOCWEB_STRING_IEC60909_Ib_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2Idc: {
        name: 'DOCWEB_STRING_IEC60909_Idc_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909L2IbAsym: {
        name: 'DOCWEB_STRING_IEC60909_IbAsym_LL',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralISecondK: {
        name: 'DOCWEB_STRING_IEC60909_I_SECOND_k_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIk: {
        name: 'DOCWEB_STRING_IEC60909_Ik_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIp: {
        name: 'DOCWEB_STRING_IEC60909_Ip_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIb: {
        name: 'ScIEC60909LNeutralIb',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIdc: {
        name: 'ScIEC60909LNeutralIdc',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LNeutralIbAsym: {
        name: 'DOCWEB_STRING_IEC60909_IbAsym_LN',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEISecondK: {
        name: 'DOCWEB_STRING_IEC60909_I_SECOND_k_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIk: {
        name: 'DOCWEB_STRING_IEC60909_Ik_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIp: {
        name: 'DOCWEB_STRING_IEC60909_Ip_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIb: {
        name: 'DOCWEB_STRING_IEC60909_IbAsym_LPE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIdc: {
        name: 'DOCWEB_STRING_IEC60909_Idc_L_PE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC60909LPEIbAsym: {
        name: 'DOCWEB_STRING_IEC60909_IbAsym_LPE',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninL3I: {
        name: 'ScTheveninL3I',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninL3Ip: {
        name: 'ScTheveninL3Ip',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninL2I: {
        name: 'ScTheveninL2I',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninL2Ip: {
        name: 'ScTheveninL2Ip',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninLNeutralI: {
        name: 'ScTheveninLNeutralI',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninLNeutralIp: {
        name: 'ScTheveninLNeutralIp',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninLPEI: {
        name: 'ScTheveninLPEI',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScTheveninLPEIp: {
        name: 'ScTheveninLPEIp',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363IsecondK: {
        name: 'DOCWEB_STRING_IEC60363_I_SECOND_k',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363Ik: {
        name: 'DOCWEB_STRING_IEC60363_Ikt',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363Ip: {
        name: 'DOCWEB_STRING_IEC60363_Ip',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363IAc: {
        name: 'DOCWEB_STRING_IEC60363_Iac',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      ScIEC61363Ikt: {
        name: 'DOCWEB_STRING_IEC60363_Ikt',
        display: false,
        inSpreadsheet: false,
        inDeviceData: false,
        inFaultCurrents: false,
        inPowerFlow: false,
        inColumns: false,
      },
      Order: [
        'ObjectSign',
        'UserDescription1',
        'ComputedPhaseShift',
        'ComputedVoltage',
        'LoadCurrent',
        'ComputedCosPhi',
        'ScIEC60909L3ISecondK',
        'ScIEC60909L3Ik',
        'ScIEC60909L3Ip',
        'ScIEC60909L3Ib',
        'ScIEC60909L3Idc',
        'ScIEC60909L3IbAsym',
        'ScIEC60909L2ISecondK',
        'ScIEC60909L2Ik',
        'ScIEC60909L2Ip',
        'ScIEC60909L2Ib',
        'ScIEC60909L2Idc',
        'ScIEC60909L2IbAsym',
        'ScIEC60909LNeutralISecondK',
        'ScIEC60909LNeutralIk',
        'ScIEC60909LNeutralIp',
        'ScIEC60909LNeutralIb',
        'ScIEC60909LNeutralIdc',
        'ScIEC60909LNeutralIbAsym',
        'ScIEC60909LPEISecondK',
        'ScIEC60909LPEIk',
        'ScIEC60909LPEIp',
        'ScIEC60909LPEIb',
        'ScIEC60909LPEIdc',
        'ScIEC60909LPEIbAsym',
        'ScTheveninL3I',
        'ScTheveninL3Ip',
        'ScTheveninL2I',
        'ScTheveninL2Ip',
        'ScTheveninLNeutralI',
        'ScTheveninLNeutralIp',
        'ScTheveninLPEI',
        'ScTheveninLPEIp',
        'ScIEC61363IsecondK',
        'ScIEC61363Ik',
        'ScIEC61363Ip',
        'ScIEC61363IAc',
        'ScIEC61363Ikt',
      ],
    },
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: LabelsKnownActions): LabelsNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      // TODO: if param reassigment for function parameter is not ok here => redo correctly;
      case LABELS.UPDATE_LABELS_ORDER:
        draft[action.payload.categoryName][action.payload.subCategoryName].Order = action.payload.order;
        break;
      case LABELS.UPDATE_LABELS_STATUS:
        draft[action.payload.categoryName][action.payload.subCategoryName] = action.payload.status;
        break;
      case LABELS.UPDATE_LABELS_LIST:
        draft[action.payload.categoryName][action.payload.subCategoryName][action.payload.targetName] = action.payload.label;
        break;
      case LABELS.SET_LABELS:
        return action.payload;
      case PROJECT.LOAD_PROJECT:
        return _.merge({}, initialState, action.payload.projectState.labels);
      default:
        break;
    }
  });
