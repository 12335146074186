import React, { FunctionComponent, useState } from 'react';
import Select from 'components/generic/selectContainer';
import DOCwebEditableSelect from 'components/generic/editableSelect';
import { ICurveCombo } from 'types/curves/components';
import { ISelectOnChange } from 'types/components/selectContainer';

const Combo: FunctionComponent<ICurveCombo> = ({
  width,
  on,
  title,
  options,
  valueList,
  value,
  curvesRequestIsRunning,
  onChange,
  onFocus,
  isString,
  editable,
}): React.ReactElement => {
  const [manualChange, setManualChange] = useState<boolean>(false);

  const comboOnChange = (val: string | number, fromInput = false) => {
    const floatValue = typeof val === 'string' ? val.indexOf('.') !== -1 : false;
    let realValue = isString || floatValue ? val : parseFloat(val.toString());
    if (Number.isNaN(realValue)) {
      realValue = 0;
    }
    onChange(realValue, fromInput, false);
    setManualChange(fromInput);
  };

  const comboOnFocusOut = (val: string | number, fromInput = false) => {
    const realValue = isString ? val : parseFloat(val.toString());
    onChange(realValue, fromInput, true);
    setManualChange(false);
  };

  const comboOnFocus = (event: ISelectOnChange) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  let finalValue = value;
  if (typeof value === 'number' && !manualChange) {
    if (!Array.isArray(options)) {
      finalValue = value.toFixed(options.numberOfDecimals);
    } else {
      finalValue = value.toFixed(2);
    }
  }

  const finalOptions =
    !Array.isArray(options) && (options.numberOfDecimals || options.numberOfDecimals === 0) ? options.options : options;

  return editable ? (
    <DOCwebEditableSelect
      type="text"
      cols={[width, 0, 0]}
      selectList={Array.isArray(finalOptions) ? finalOptions : []}
      valueList={(valueList as Array<string>) ?? []}
      value={finalValue.toString()}
      disabled={!on || curvesRequestIsRunning}
      onChange={(event) => comboOnChange(event.value, !!event.eventType)}
      onFocusOut={(event) => comboOnFocusOut(event.value, !!event.eventType)}
      onFocus={(event) => comboOnFocus(event)}
      name={`Curves${title}`}
      key={`Curves${title}ComboFunction`}
      dontTranslate={!isString && Array.isArray(finalOptions)}
    />
  ) : (
    <Select
      key={`Curves${title}${finalValue.toString()}`}
      cols={[width, 0, 0]}
      selectList={Array.isArray(finalOptions) ? finalOptions : []}
      valueList={valueList as Array<string>}
      value={finalValue.toString()}
      disabled={!on || curvesRequestIsRunning}
      onChange={(event) => onChange(event.value, false, false)}
      name={`Curves${title}`}
      onFocus={(event) => comboOnFocus(event)}
      dontTranslate={!isString && Array.isArray(finalOptions)}
    />
  );
};

export default Combo;
