import React, { FunctionComponent } from 'react';
import CurvesContainer from 'curves/CurvesContainer';
import { useDispatch, useSelector } from 'store';
import { IGraphTab } from 'types/curves/components';
import { setSvgContent } from 'ui/modals/printManager/actions';

const GraphTab: FunctionComponent<IGraphTab> = ({ selectedDeviceId, initialDiagram, userDevice }): React.ReactElement => {
  // useEffect(() => {}, [expanded]);
  const dispatch = useDispatch();
  const curves = useSelector((state) => state.curves);
  const devices = useSelector((state) => state.devices);

  return (
    <CurvesContainer
      curves={curves}
      selectedDeviceId={selectedDeviceId}
      devices={devices}
      userDevice={userDevice}
      initialDiagram={initialDiagram}
      setSvgContent={(diagram: string) => dispatch(setSvgContent(diagram))}
    />
  );
};

export default GraphTab;
