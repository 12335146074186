import { IBackendResponse, IScheme } from 'types/compute';
import { Device } from 'types/devices/device';
import ApiCalls from './ApiCalls';

// export default class ResidualCurrentCircuitBreakerAPI {
//   static getResidualCurrentFilters(requestBody) {
//     return ApiCalls.post(`ResidualCurrentFilter`, requestBody);
//   }

//   static store(requestBody) {
//     return ApiCalls.post(`ResidualCurrentStore`, requestBody);
//   }
// }

export default class ResidualCurrentCircuitBreakerAPI {
  static async getResidualCurrentFilters(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Promise<Partial<Device>>, IScheme>(`ResidualCurrentFilter`, requestBody);
    return data;
  }

  static async store(requestBody: IScheme): Promise<IBackendResponse> {
    const { data } = await ApiCalls.post<Promise<IBackendResponse>, IScheme>(`ResidualCurrentStore`, requestBody);
    return data;
  }

  static async getResidualCurrentFilterGlobals(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Promise<Partial<Device>>, IScheme>(`ResidualCurrentFilterGlobals`, requestBody);
    return data;
  }
}
