import { ECellType, EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import defaultCell from '../defaultCell';

export default {
  ...defaultCell,
  type: ECellType.Connection,
  objectType: EDeviceObjectType.Connection,
  deviceType: EDeviceType.LINK,
  ports: [],
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.IT,
};
