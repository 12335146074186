// @ts-nocheck
/* eslint-disable no-param-reassign */
import { IDOCwebInputProps } from 'types/components/inputContainer';
import { ThunkAction } from 'types/known-actions';

import { isBoolean, isNumber } from 'types/type-guards';

import { Variables } from 'types/variables/store';
import { VariablesActions } from 'store/variables/actions';
import { projectActions, setProjectDirty } from 'project/actions';
import _ from 'lodash';
import FieldsValidation from 'fieldsValidation';

const TAB_NAME = 'Short Circuit';

export const inputUpdateHandler =
  (event: IDOCwebInputProps): ThunkAction<void> =>
  (dispatch) => {
    const { name, value } = event;

    let val: string | boolean | number = value;
    if (!isNumber(value) && event.type === 'number') {
      val = parseFloat(value);
    }

    dispatch(VariablesActions.setVariables({ [name]: val }));
  };

export const timeChange =
  (name: string, value: string | boolean, id: number): ThunkAction<void> =>
  (dispatch, getState) => {
    const { variables } = getState();
    const ShortCircuitTimesObject = _.cloneDeep(variables.ShortCircuitTimesObject);
    const times = [...variables.ShortCircuitTimes.time];

    if (isBoolean(value) && name === 'editable') {
      const timesLength = times.length;
      dispatch(processTimesCheckbox(id, timesLength, ShortCircuitTimesObject, times));
    } else if (isNumber(value) && !isBoolean(value)) {
      ShortCircuitTimesObject[`time${id}` as keyof typeof ShortCircuitTimesObject].value = parseFloat(value);
      times[id] = parseFloat(value);
    }

    dispatch(setProjectDirty(true, ''));

    dispatch(
      VariablesActions.setVariables({
        ShortCircuitTimesObject,
        ShortCircuitTimes: {
          ...variables.ShortCircuitTimes,
          time: times,
        },
      })
    );
  };

const processTimesCheckbox =
  (
    id: number,
    timesLength: number,
    ShortCircuitTimesObject: Variables.State['ShortCircuitTimesObject'],
    times: number[]
  ): ThunkAction<void> =>
  (dispatch) => {
    Object.keys(ShortCircuitTimesObject).forEach((item, key) => {
      const time = `time${key}` as keyof typeof ShortCircuitTimesObject;
      if (key <= id && timesLength <= id) {
        times[key] = ShortCircuitTimesObject[time].value;
        ShortCircuitTimesObject[time].editable = true;
      } else if (timesLength > id && key >= id) {
        ShortCircuitTimesObject[time].editable = false;
        const index = times.indexOf(ShortCircuitTimesObject[time].value);
        if (index > -1) {
          times.splice(index, 1);
        }
      }
      if (key > 0) {
        dispatch(
          projectActions.setProjectError({
            name: `time${key}`,
            tabName: TAB_NAME,
            error: ShortCircuitTimesObject[`time${key}`].editable
              ? FieldsValidation.validateLowerValue(
                  ShortCircuitTimesObject[`time${key - 1}`].value,
                  ShortCircuitTimesObject[`time${key}`].value,
                  `time ${key}`
                )
              : undefined,
          })
        );
      }
    });
  };
