export default {
  SCTriMax: 0,
  SCTriPeak: 0,
  SCBiMax: 0,
  SCBiPeak: 0,
  SCLNMax: 0,
  SCLNPeak: 0,
  SCPEMax: 0,
  SCPEPeak: 0,
  SCBiTriMin: 0,
  SCLNMin: 0,
  SCPEMin: 0,
};
