import { saveNewUserProject } from 'actions/user';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import ModalWindow from 'ui/modals/ModalWindow';
import { updateWizardParams } from './actions';
import { getValidProjectName } from './asyncActions';
import ProjectInfo from './ProjectInfo';

const WizardDialog: FunctionComponent = (): React.ReactElement => {
  const project = useSelector((state) => state.project);
  const wizard = useSelector((state) => state.wizard);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!project.singleLineDiagramId) {
      dispatch(getValidProjectName(t));
    } else {
      dispatch(
        updateWizardParams({
          projectName: `${project.partnerHub.projectName} - ${project.name}`,
          projectCustomer: project.Customer,
          sldProject: !!project.singleLineDiagramId,
          // projectDescription: '//',
        })
      );
    }
    dispatch(
      updateWizardParams({
        requestRunning: false,
        creatingProject: false,
      })
    );
    // this.props.getDefaultProjectProperties();
  }, []);

  const tabs = {
    DOCWEB_STRING_PROJECT_INFO: <ProjectInfo />,
  };

  const onNextPage = () => {
    // Changing default utility state based on settings selected in wizard
    // TODO: Revise this functionality
    dispatch(
      updateWizardParams({
        creatingProject: true,
      })
    );
    dispatch(saveNewUserProject()).catch((err) => console.error(err));
    // dispatch(
    //   updateWizardParams({
    //     creatingProject: false,
    //   })
    // );
  };

  return (
    <ModalWindow
      tabs={tabs}
      disableBlueButton={wizard.requestRunning || wizard.creatingProject}
      blueButtonAction={onNextPage}
      blueButtonText={t('DOCWEB_FOOTER_BUTTON_SAVE_PROJECT')}
      title={wizard.projectName ? wizard.projectName : t('DOCWEB_NAME_PROJECT')}
      isLoading={wizard.requestRunning || wizard.creatingProject}
      showCloseButton={false}
    />
  );
};

export default WizardDialog;
