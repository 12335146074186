import React from 'react';
import ModalWindow from 'ui/modals/ModalWindow';
import ProjectDocumentationManager from './component';
import Footer from './footer';

const printManager = () => (
  <ModalWindow
    tabs={{
      STRINGS_PRINT_MANAGER: <ProjectDocumentationManager />,
    }}
    footer={<Footer />}
    tabControlContentClassName={'print-manager-container-dialog'}
  />
);

export default printManager;
