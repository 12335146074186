import { BrowserCacheLocation, Configuration, RedirectRequest } from '@azure/msal-browser';

const environment: Record<string, string> = {
  'https://tripcurves.econfigure.abb.com': 'd31b6279-65fe-4de6-9e5b-773b9e24766c',
  'https://abb-curves-prod.azurewebsites.net': 'd31b6279-65fe-4de6-9e5b-773b9e24766c',
  'https://localhost:5001': 'c577c902-a1a3-4864-a3f9-42d51434343c',
  'https://dev.tripcurves.econfigure.abb.com': 'd09ccff3-9ade-4885-9230-201423c58eff',
  'https://abb-curves-dev.azurewebsites.net': 'd09ccff3-9ade-4885-9230-201423c58eff',
  'https://test.tripcurves.econfigure.abb.com': '9acd3f3a-2e49-4799-84ea-82af6a9b75f2',
  'https://abb-curves-test.azurewebsites.net': '9acd3f3a-2e49-4799-84ea-82af6a9b75f2',
};

const environmentAud: Record<string, string> = {
  'https://tripcurves.econfigure.abb.com': '1deb6ec3-20e5-44b0-914a-1654854cb8b6',
  'http://localhost:3000': '08d2578c-6ab6-4da1-a1c8-3f9e12e6a582',
  'https://dev.tripcurves.econfigure.abb.com': '08d2578c-6ab6-4da1-a1c8-3f9e12e6a582',
  'https://test.tripcurves.econfigure.abb.com': '080f18a9-373a-43a9-9db4-26764c05d863',
};

const environmentAudPH: Record<string, string> = {
  dev: '08d2578c-6ab6-4da1-a1c8-3f9e12e6a582',
  test: '5e6c20ad-591e-4eed-81c9-3d393117435c',
  stage: '7e07715c-f67f-4ffb-ad11-ba27e700adde',
  prod: '1deb6ec3-20e5-44b0-914a-1654854cb8b6',
};

const getAudienceId = (): string => {
  const toolUrl = new URLSearchParams(window.location.search).get('toolUrl');
  if (toolUrl) {
    const phAudId = Object.keys(environmentAudPH).find((phEnv) => toolUrl.indexOf(phEnv) !== -1);
    if (phAudId) return environmentAudPH[phAudId];
  }
  return environmentAud[window.location.origin];
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: environment[window.location.origin] || 'd09ccff3-9ade-4885-9230-201423c58eff',
    authority: 'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd/',
    knownAuthorities: ['login.microsoftonline.com'],
    redirectUri: window.location.origin,
    // postLogoutRedirectUri: "/"
  },
  // system: {
  //   loggerOptions: {
  //     logLevel: LogLevel.Verbose,
  //     loggerCallback: (level, message) => console.log(`MSAL ${message}`),
  //   },
  // },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    secureCookies: true,
  },
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [`https://ABB.onmicrosoft.com/${getAudienceId()}/user_impersonation`],
};
