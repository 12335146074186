/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, KeyboardEvent } from 'react';
import { customCurvesDiagrams } from 'curves/Constants';
import { Expander, ExpanderGroup, Icon } from '@abb/abb-common-ux-react';
import Input from 'components/generic/inputContainer/Input';
import { GridColumn, GridRow } from 'components/generic/grid';
import _ from 'lodash';
import { TDeviceEvent } from 'types/devices/device';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { diagramComboNames } from 'curves/CurvesTranslations';
import { ICustomCurve } from 'types/curves';
import { getParamName } from './utils';

interface ICCDT {
  device: ICustomCurve;
  setDeviceState: React.Dispatch<React.SetStateAction<ICustomCurve>>;
}

const CustomCurvesDeviceExpanderTab: React.FunctionComponent<ICCDT> = ({ device, setDeviceState }) => {
  const { t } = useTranslation();
  const [copyStack, setCopyStack] = useState(undefined as unknown as { x: number; y: number } | undefined);
  const [cutDiagramName, setCutDiagramName] = useState('');
  const [cancelAction, setCancelAction] = useState('');
  const [cutIndex, setCutIndex] = useState(-1);
  // const [focusedInput, setFocusedInput] = useState(inputReference);

  const updatePointHandler = (e: TDeviceEvent, diagramName: string, pointName: 'x' | 'y', pointIndex: number) => {
    const state = _.cloneDeep(device);
    if (state) {
      state.curves[diagramName].points[pointIndex][pointName] = e.value as unknown as number;
      setDeviceState(state);
    }
  };

  const onKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      if ($('[name=customCurvePointY]:last').is(':focus')) {
        e.preventDefault();
        addPointHandler(device.selectedDiagram);
      } else if ($('[name=customCurvePointX]').is(':focus')) {
        e.preventDefault();
        const inputIndex = $(e.target).index('[name=customCurvePointX]');
        setTimeout(() => $($('[name=customCurvePointY]')[inputIndex]).trigger('focus'));
      }
    }
  };

  const returnDraggableContent = (
    point: { x: number; y: number },
    pointIndex: number,
    diagramName: string,
    snapshot: { isDragging: boolean }
  ) => {
    return (
      <>
        <GridRow
          style={snapshot.isDragging ? { opacity: 0.7, border: '1px solid lightGray' } : undefined}
          className="curvePoint-row"
          key={`${diagramName}point${pointIndex}`}
        >
          <GridColumn col={5} style={{ paddingLeft: '2px', backgroundColor: 'white', marginBottom: '2px' }}>
            <GridRow>
              <GridColumn className="curvePointRow-iconColumn" col={3} horizontalAlignment="start">
                <Icon className="curvePoint-icon" name="abb/menu" />
              </GridColumn>
              <GridColumn col={3} horizontalAlignment="center">
                <Input
                  cols={[1]}
                  className="custom-curves-device-parameters-input"
                  name="customCurvePointX"
                  value={point.x as unknown as string}
                  type="number"
                  onChange={(e) => {
                    updatePointHandler(e, diagramName, 'x', pointIndex);
                  }}
                  onKeyDown={(e) => onKeyPressed(e)}
                />
              </GridColumn>
              <GridColumn col={2} horizontalAlignment="center">
                <Input
                  cols={[1]}
                  className="custom-curves-device-parameters-input"
                  name="customCurvePointY"
                  value={point.y as unknown as string}
                  type="number"
                  onChange={(e) => {
                    updatePointHandler(e, diagramName, 'y', pointIndex);
                  }}
                  onKeyDown={(e) => onKeyPressed(e)}
                />
              </GridColumn>
            </GridRow>
          </GridColumn>
          <GridColumn className="curvePointRow-iconColumn">
            <Icon className="curvePoint-icon" name="abb/copy" onClick={() => copyPointHandler(diagramName, point)} />
            <Icon className="curvePoint-icon" name="abb/cut" onClick={() => cutPointHandler(diagramName, point, pointIndex)} />
            <Icon className="curvePoint-icon" name="abb/trash" onClick={() => removePointHandlers(diagramName, pointIndex)} />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const copyPointHandler = (diagramName: string, point: { x: number; y: number }) => {
    setCopyStack(point);
    setCancelAction('copy');
    setCutDiagramName(diagramName);
  };

  const cutPointHandler = (diagramName: string, point: { x: number; y: number }, index: number) => {
    const state = _.cloneDeep(device);
    if (!state) return;
    setCancelAction('cut');
    state.curves[diagramName].points.splice(index, 1);
    setCutDiagramName(diagramName);
    setCutIndex(index);
    setCopyStack(point);
    setDeviceState(state);
  };

  const removePointHandlers = (diagramName: string, index: number) => {
    const state = _.cloneDeep(device);
    if (!state) return;
    state.curves[diagramName].points.splice(index, 1);
    setDeviceState(state);
  };

  const addPointHandler = (diagramName: string) => {
    const state = _.cloneDeep(device);
    if (state && !state.curves[diagramName]) {
      state.curves[diagramName] = { points: [] };
    }
    if (state && !Array.isArray(state.curves[diagramName].points)) {
      state.curves[diagramName].points = [{ x: '' as unknown as number, y: '' as unknown as number }];
    } else if (state) {
      state.curves[diagramName].points.push({ x: '' as unknown as number, y: '' as unknown as number });
    }
    if (state) {
      setDeviceState(state);
    }
    setTimeout(() => $('[name=customCurvePointX]:last').trigger('focus'));
  };

  const onDragHandler = (e: DropResult, diagramName: string) => {
    const destinationIndex = e.destination?.index;
    const sourceIndex = e.source.index;
    if ((!destinationIndex && destinationIndex !== 0) || destinationIndex === sourceIndex) return;
    const state = _.cloneDeep(device);
    if (!state) return;
    const removedPoint = state.curves[diagramName].points.splice(sourceIndex, 1);
    state.curves[diagramName].points.splice(destinationIndex, 0, removedPoint[0]);
    setDeviceState(state);
  };

  const pastePointHandler = (diagramName: string) => {
    const state = _.cloneDeep(device);
    if (!state || !copyStack) return;
    state.curves[diagramName].points.push(copyStack);
    setCutDiagramName('');
    setCopyStack(undefined);
    setCutIndex(-1);
    setDeviceState(state);
  };

  const cancelRemoveHandler = () => {
    const state = _.cloneDeep(device);
    if (!state || !copyStack || !cutDiagramName) return;
    if (cancelAction === 'cut') {
      state.curves[cutDiagramName].points.splice(cutIndex, 0, copyStack);
      setCutIndex(-1);
      setCutDiagramName('');
    }
    setCopyStack(undefined);
    setDeviceState(state);
  };

  const selectCurrentDiagram = (diagramName: string) => {
    const state = _.cloneDeep(device);
    state.selectedDiagram = diagramName;
    setDeviceState(state);
  };

  return (
    <ExpanderGroup singleMode={false} className="device-parameters-expander" style={{ width: '100%' }}>
      {customCurvesDiagrams.map((diagramName, index) => {
        const params = getParamName(diagramName);
        return (
          <Expander
            // eslint-disable-next-line react/no-array-index-key
            key={`${diagramName}${index}`}
            className="custom-curves-device-parameters"
            title={t(diagramComboNames[diagramName as keyof typeof diagramComboNames])}
            open={device.selectedDiagram === diagramName}
            onClick={() => selectCurrentDiagram(diagramName)}
          >
            <GridRow>
              <GridColumn col={5} style={{ paddingLeft: '2px', backgroundColor: 'white', marginBottom: '2px' }}>
                <GridRow>
                  <GridColumn col={3} horizontalAlignment="start">
                    {t('DOCWEB_CUSTOMCURVESDEVICE_PARAMETER')}
                  </GridColumn>
                  <GridColumn col={3} horizontalAlignment="center">
                    <span style={{ fontWeight: 600 }}>{params.x}</span>
                  </GridColumn>
                  <GridColumn col={2} horizontalAlignment="center">
                    <span style={{ fontWeight: 600 }}>{params.y}</span>
                  </GridColumn>
                </GridRow>
              </GridColumn>
            </GridRow>
            <DragDropContext key={1} onDragEnd={(e) => onDragHandler(e, diagramName)}>
              <div>
                <Droppable droppableId="diagramNameHere">
                  {(provided) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {device && device.curves && device.curves[diagramName] ? (
                          device.curves[diagramName].points.map((point, pointIndex) => (
                            <Draggable key={`key${pointIndex}`} draggableId={`id${pointIndex}`} index={pointIndex}>
                              {(p, s) => (
                                <div ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps}>
                                  {returnDraggableContent(point, pointIndex, diagramName, s)}
                                </div>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </DragDropContext>
            <GridRow>
              {copyStack ? (
                <>
                  <GridColumn col={2} offset={2}>
                    <p className="clickable-text" onClick={() => cancelRemoveHandler()}>
                      {t('STRINGS_IDCANCEL')}
                    </p>
                  </GridColumn>
                  <GridColumn col={2}>
                    <p className="clickable-text" onClick={() => pastePointHandler(diagramName)}>
                      {t('DOCWEB_NAVBAR_PASTE')}
                    </p>
                  </GridColumn>
                </>
              ) : (
                <GridColumn col={2} offset={4}>
                  <p className="clickable-text" onClick={() => addPointHandler(diagramName)}>
                    {t('DOCWEB_DEVICEPARAMETERS_ADDROW')}
                  </p>
                </GridColumn>
              )}
            </GridRow>
          </Expander>
        );
      })}
    </ExpanderGroup>
  );
};

export default CustomCurvesDeviceExpanderTab;
