/* eslint-disable no-param-reassign */

import RelationsAPI from 'api/relations';
import { dependentAPIRequestIsRunning, runRequestFromQueue, setRequestRunning } from 'compute/actions';
import { setDeviceRequestRunning, updateAllElementsParams, updateElementParams } from 'devices/actions';
import DeviceProcessor from 'devices/device';
import _ from 'lodash';
import { showTimesFromResponse } from 'project/actions/projectMessages';
import buildNetwork from 'project/buildNetwork';
import { batch } from 'react-redux';
import { VariablesActions } from 'store/variables/actions';
import { IGetRelationsDataResponse } from 'types/api/relations';
import { REQUESTS_API_DEPENDENCY } from 'types/compute/requestQueue';
import { CircuitBreaker } from 'types/devices/circuitBreaker';
import { Device, TDeviceEvent } from 'types/devices/device';
import { DataSource, EDeviceType } from 'types/devices/enums.d';
import { IRelationItem, IRelationLink, IRelationPropagate } from 'types/devices/protectedObjects';
import { isPropagateRelation } from 'types/devices/protectedObjects/type-guards';
import { ThunkAction } from 'types/known-actions';
import { ApplicationState } from 'types/store';

export const getRelations =
  (elementId: string, deleteList = false): ThunkAction<void> =>
  (dispatch, getState) => {
    const request = {
      name: 'relations' as keyof typeof REQUESTS_API_DEPENDENCY,
      func: _.partial(getRelations, elementId, deleteList),
    };
    if (dispatch(dependentAPIRequestIsRunning(request))) {
      return;
    }
    const state = getState();
    const requestBody = _.cloneDeep(buildNetwork(state, true));
    if (requestBody === undefined) {
      return;
    }
    requestBody.variables.WEBDOC_CURRENT_DEVICE = elementId;
    // Delete old relation before showing new ones
    // Is needed because will crash if some devices no more present
    // In relations
    batch(() => {
      dispatch(updateElementParams(elementId, { Relations: {}, Protection: '' }, true));
      dispatch(setDeviceRequestRunning(elementId));
      dispatch(setRequestRunning('relationsRunning', true));
    });
    RelationsAPI.getRelationsData(requestBody)
      .then((response) => {
        batch(() => {
          // TODO
          dispatch(getRelationsData(response, state, elementId, deleteList));
          dispatch(setDeviceRequestRunning(elementId));
          dispatch(setRequestRunning('relationsRunning', false));
          dispatch(runRequestFromQueue());
        });
      })
      .catch((error) => console.log(error));
  };

export const getRelationsData =
  (response: IGetRelationsDataResponse, state: ApplicationState, elementId: string, deleteList = false): ThunkAction<void> =>
  (dispatch) => {
    response.jsonOutput = dispatch(checkIdsInResponse(response.jsonOutput));
    const ProtectedObjects = ['cables', 'disconnectors', 'contactors', 'contactors', 'capacitorBanks', 'residualCurrentRelays'];
    const DiscriminationObjects = ['discriminationUpStream', 'discriminationDownStream'];
    const BackUpObjects = ['backUpUpStream', 'backUpDownStream'];
    const ProtectedObjectsList = processRelationsObject(ProtectedObjects, response.jsonOutput);
    const DiscriminationObjectsList = processRelationsObject(DiscriminationObjects, response.jsonOutput);
    const BackUpObjectsList = processRelationsObject(BackUpObjects, response.jsonOutput);
    const deviceDataProtectedObjects = processRelationsResponse(
      ProtectedObjectsList as IGetRelationsDataResponse['jsonOutput'],
      state.devices,
      elementId
    );
    const deviceDataDiscrimination = processRelationsResponse(
      DiscriminationObjectsList as IGetRelationsDataResponse['jsonOutput'],
      state.devices,
      elementId,
      true
    );
    const deviceDataBackUp = processRelationsResponse(
      BackUpObjectsList as IGetRelationsDataResponse['jsonOutput'],
      state.devices,
      elementId,
      true
    );
    let discriminationProp = state.devices[elementId].DiscriminationProperty;
    if (
      !discriminationProp ||
      (Object.keys(DiscriminationObjectsList).indexOf(discriminationProp) === -1 &&
        Object.keys(BackUpObjectsList).indexOf(discriminationProp) === -1)
    ) {
      // eslint-disable-next-line prefer-destructuring
      discriminationProp = Object.keys(DiscriminationObjectsList)[0];
    }
    if (!deleteList) {
      deviceDataProtectedObjects[elementId] = {
        ...deviceDataProtectedObjects[elementId],
        Relations: ProtectedObjectsList,
        Protection: Object.keys(ProtectedObjectsList)[0],
        PreFiltersChanged: true,
        relationsWasCalled: true,
      };
      deviceDataDiscrimination[elementId] = {
        ...deviceDataDiscrimination[elementId],
        DiscriminationProperty: discriminationProp,
        DiscriminationPropertiesList: DiscriminationObjectsList,
      };
      deviceDataBackUp[elementId] = {
        ...deviceDataBackUp[elementId],
        BackupPropertiesList: BackUpObjectsList,
      };
      batch(() => {
        dispatch(updateAllElementsParams(deviceDataDiscrimination));
        dispatch(updateAllElementsParams(deviceDataBackUp));
        dispatch(updateAllElementsParams(deviceDataProtectedObjects));
      });
    }

    if (response.jsonUpdate != null && !Array.isArray(response.jsonUpdate.docxdata)) {
      dispatch(updateAllElementsParams(response.jsonUpdate.docxdata));
    }
    // Coordination Filter
    let currentSelectedId = state.project.selectedDeviceId;
    const CoordinationFilterList = ['Not requested'];
    let CoordinationFilter = 'Not requested';
    if (response.jsonOutput.backUpUpStream && response.jsonOutput.backUpUpStream.length > 0) {
      CoordinationFilterList.push('Back-up');
      if (response.jsonOutput.backUpUpStream.some((bck) => bck.links.some((link) => link.check === true))) {
        CoordinationFilter = 'Back-up';
      }
    }
    if (response.jsonOutput.discriminationUpStream && response.jsonOutput.discriminationUpStream.length > 0) {
      CoordinationFilterList.push('Selectivity');
      if (response.jsonOutput.discriminationUpStream.some((bck) => bck.links.some((link) => link.check === true))) {
        CoordinationFilter = 'Selectivity';
      }
    }
    if (
      currentSelectedId &&
      (state.devices[currentSelectedId].deviceType === EDeviceType.CB_FEEDER ||
        state.devices[currentSelectedId].deviceType === EDeviceType.MOTOR_FEEDER)
    ) {
      currentSelectedId = state.devices[currentSelectedId].json?.embeds?.find(
        (id) => state.devices[id].deviceType === EDeviceType.CB_TM || state.devices[id].deviceType === EDeviceType.FUSEBASE
      );
    }
    dispatch(updateElementParams(currentSelectedId as string, { CoordinationFilterList, CoordinationFilter }, true));

    dispatch(VariablesActions.setVariables({ WEBDOC_BCKDISC_STATE: '' }));
    if (response.jsonUpdate) {
      showTimesFromResponse(response.jsonUpdate.console);
    }
  };

const processRelationsObject = (typesArray: Array<string>, relations: IGetRelationsDataResponse['jsonOutput']) => {
  const newObj: Record<string, IRelationItem[]> = {};
  Object.keys(relations)
    .filter((protection) => typesArray.indexOf(protection) > -1)
    // eslint-disable-next-line no-return-assign
    .forEach((item) => (newObj[item] = relations[item as keyof IGetRelationsDataResponse['jsonOutput']]));
  return newObj;
};
const checkIdsInResponse =
  (response: IGetRelationsDataResponse['jsonOutput']): ThunkAction<IGetRelationsDataResponse['jsonOutput']> =>
  (dispatch, getState) => {
    const { devices } = getState();
    const newResponse = { ...response };
    Object.keys(newResponse).forEach((category) => {
      newResponse[category as keyof IGetRelationsDataResponse['jsonOutput']].forEach((item) => {
        if (item.discrimLevel === -1) {
          item.discrimLevel = 'Full';
        }
        let { userId } = item;
        if (item.userId.split(',').length > 1) {
          // eslint-disable-next-line prefer-destructuring
          userId = item.userId.split(',')[0];
        }
        if (!userId) {
          console.warn('NO Id for device discrimination', response);
        } else if (devices[item.id] && (devices[item.id].ObjectSign as string) !== userId) {
          item.id = dispatch(getDeviceIdBySign(userId));
        }
      });
    });
    return newResponse;
  };

const processRelationsResponse = (
  relations: IGetRelationsDataResponse['jsonOutput'],
  devices: Record<string, Device>,
  elementId: string,
  isDiscrimination = false
) => {
  const outputData: Record<string, Record<string, unknown>> = {};
  const defaultLink = { allowed: false, linkType: 'NONE', property: 'NotUsed', check: false, oldValue: '', value: 'NONE' };
  let oderOfProtection = new Array<string>();
  if (isDiscrimination) {
    oderOfProtection = ['DISC', 'BCK'];
  } else {
    oderOfProtection = ['OL', 'SC', 'NDT'];
  }
  Object.keys(relations).forEach((category) => {
    relations[category as keyof IGetRelationsDataResponse['jsonOutput']].forEach((item) => {
      if (!outputData[item.id]) {
        outputData[item.id] = {};
      }
      const outLinks = [];
      if (item.links.length < 3 && !isDiscrimination) {
        outLinks.push(defaultLink, defaultLink, defaultLink);
        item.links.forEach((link) => {
          outLinks[oderOfProtection.indexOf(link.linkType)] = link;
        });
        item.links = outLinks;
      }
      item.links.forEach((link) => {
        if (!link.property) {
          link.property = category;
        }
        outputData[item.id][`${link.property}Old`] = link.unCheckedValue;
        outputData[item.id][`${link.property}New`] = link.checkedValue;
        if (!devices[elementId].ProtectionAutoAssignOff || isDiscrimination) {
          if (link.check) {
            outputData[item.id][link.property] = link.checkedValue;
          } else {
            outputData[item.id][link.property] = link.unCheckedValue;
          }
        } else if (item.id) {
          if (devices[item.id][link.property] === link.unCheckedValue) {
            outputData[item.id][link.property] = link.unCheckedValue;
          } else {
            outputData[item.id][link.property] = link.checkedValue;
          }
        }
      });
      const newObj = { ...item, links: undefined, type: undefined, userId: undefined, id: undefined };
      const { id } = item;
      delete newObj.links;
      delete newObj.type;
      delete newObj.userId;
      delete newObj.id;
      outputData[id] = { ...outputData[id], ...newObj };
    });
  });
  return outputData;
};

export const updateProtectionHandler = (
  deviceId: string,
  name: string,
  value: Record<string, never> | boolean | string,
  silent = false
): ThunkAction<void> => {
  return (dispatch) => {
    dispatch(updateElementParams(deviceId, { [name]: value }, silent));
  };
};

export const discriminationSwitchHandler =
  (deviceId: string, name: string, value: string, silent = false): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const currentSelection = state.devices[deviceId].DiscriminationProperty;
    dispatch(updateElementParams(deviceId, { [name]: value }, silent));
    if (state.variables.WEBDOC_BCKDISC_STATE !== '') {
      switch (currentSelection) {
        case 'backUpDownStream':
        case 'backUpUpStream':
          if (value === 'discriminationUpStream' || value === 'discriminationDownStream') {
            dispatch(getRelations(deviceId, false));
          }
          break;
        case 'discriminationUpStream':
        case 'discriminationDownStream':
          if (value === 'backUpDownStream' || value === 'backUpUpStream') {
            dispatch(getRelations(deviceId, false));
          }
          break;
        default:
          return null;
      }
    }
    return null;
  };

export const inputUpdateHandler =
  (event: TDeviceEvent, item: IRelationItem, checked: boolean): ThunkAction<void> =>
  (dispatch, getState) => {
    const { devices } = getState();
    let deviceId = item.id;
    if (devices[item.id].ObjectSign !== item.userId) {
      deviceId = dispatch(getDeviceIdBySign(item.userId));
    }
    dispatch(DeviceProcessor.inputUpdateHandler(event, EDeviceType.CB_TM, deviceId, undefined, undefined));
    dispatch(discrimHandler(item.links[0], checked, item));
  };

export const updateDiscriminationHandler =
  (
    deviceId: string,
    name: string,
    value: string,
    item: IRelationItem,
    link: IRelationLink,
    checked = false,
    silent = false
  ): ThunkAction<void> =>
  (dispatch) => {
    dispatch(updateElementParams(deviceId, { [name]: value }, silent));
    dispatch(discrimHandler(link, checked, item));
  };

export const discrimHandler =
  (link: IRelationLink, checked: boolean, item: IRelationItem | IRelationPropagate): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { devices } = state;
    let deviceId;
    if (!isPropagateRelation(item)) {
      deviceId = item.id;
      if (devices[deviceId].discrimLevel !== 'Full' && Number.isNaN(parseFloat(devices[deviceId].discrimLevel as string))) {
        return;
      }
    } else {
      deviceId = Object.keys(devices).find((id) => devices[id].ObjectId === item.upStreamId) || '';
    }

    if (link.linkType === 'DISC' || link.linkType === 'BCK' || (isPropagateRelation(item) && deviceId)) {
      let type;
      if (checked === false) {
        type = `;NO${link.linkType}`;
      } else {
        type = `;${link.linkType}`;
      }
      if (link.property.startsWith('backUpDownStream') || link.property.startsWith('discriminationDownStream')) {
        type += '-';
      }
      // eslint-disable-next-line no-nested-ternary
      const descrimLevelValue = isPropagateRelation(item)
        ? -1
        : devices[deviceId].discrimLevel === 'Full'
        ? -1
        : devices[deviceId].discrimLevel;
      const descrimLevelString = link.linkType === 'BCK' ? '' : `;${descrimLevelValue as string}`;
      const backup = `${state.variables.WEBDOC_BCKDISC_STATE}${link.checkedValue as string}${type}${descrimLevelString}\n`;
      dispatch(
        VariablesActions.setVariables({
          WEBDOC_BCKDISC_STATE: backup,
        })
      );
    }
  };

export const selectFocusOutHandler =
  (event: TDeviceEvent, item: IRelationItem, checked: boolean): ThunkAction<void> =>
  (dispatch) => {
    if (item.linkType !== 'BCK') {
      const link = item.links[0];
      dispatch(DeviceProcessor.inputUpdateHandler(event, EDeviceType.CB_TM, item.id, undefined, undefined));
      dispatch(discrimHandler(link, checked, item));
    }
  };

export const getDeviceIdBySign =
  (deviceSign: string): ThunkAction<string> =>
  (dispatch, getState) => {
    const { devices } = getState();
    const deviceId = Object.keys(devices).find((docDeviceId) => devices[docDeviceId].ObjectSign === deviceSign) || '';
    return deviceId;
  };

export const manageCoordinationFilter =
  (deviceId: string, value: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDevice = state.devices[deviceId] as Device<CircuitBreaker>;
    const discriminationNames: Array<string> = [];
    let discriminationName = '';
    const dataSource = value === 'Back-up' ? DataSource.BackupPropertiesList : DataSource.DiscriminationPropertiesList;
    let checked = true;
    if (value === 'Back-up') {
      discriminationName = 'backUpUpStream';
      dispatch(setUpStream('discriminationUpStream', selectedDevice, DataSource.DiscriminationPropertiesList, false));
    } else if (value === 'Selectivity') {
      discriminationName = 'discriminationUpStream';
      dispatch(setUpStream('backUpUpStream', selectedDevice, DataSource.BackupPropertiesList, false));
    } else {
      checked = false;
    }
    if (discriminationName) {
      discriminationNames.push(discriminationName);
    } else {
      discriminationNames.push('backUpUpStream', 'discriminationUpStream');
    }
    const relationDeviceData = selectedDevice[dataSource as string] as IGetRelationsDataResponse['jsonOutput'];
    if (
      discriminationName === 'backUpUpStream' &&
      relationDeviceData &&
      relationDeviceData[discriminationName] &&
      relationDeviceData[discriminationName].length > 1
    ) {
      const minimumLevel = Math.max(...relationDeviceData[discriminationName].map((disc) => disc.level));
      const backupUp = relationDeviceData[discriminationName].find((bckUp) => bckUp.level === minimumLevel);
      backupUp?.links.forEach((link) => {
        dispatch(
          updateDiscriminationHandler(
            backupUp.id,
            discriminationName,
            link.checkedValue as string,
            backupUp,
            link,
            checked,
            false
          )
        );
      });
    } else {
      discriminationNames.forEach((discName) => {
        if (discName === 'backUpUpStream') {
          if (selectedDevice.BackupPropertiesList[discName]) {
            dispatch(setUpStream(discName, selectedDevice, 'BackupPropertiesList', checked));
          }
        } else if (selectedDevice.DiscriminationPropertiesList[discName]) {
          dispatch(setUpStream(discName, selectedDevice, 'DiscriminationPropertiesList', checked));
        }
      });
    }
    dispatch(getRelations(state.project.selectedDeviceId as string));
  };

const setUpStream =
  (discName: string, selectedDevice: Device<CircuitBreaker>, dataSource: string, checked: boolean): ThunkAction<void> =>
  (dispatch) => {
    const relationDeviceData = (selectedDevice[dataSource] as IGetRelationsDataResponse['jsonOutput'])[
      discName as keyof IGetRelationsDataResponse['jsonOutput']
    ];
    if (relationDeviceData) {
      relationDeviceData.forEach((disc) => {
        disc.links.forEach((link) => {
          dispatch(updateDiscriminationHandler(disc.id, discName, link.checkedValue as string, disc, link, checked, false));
        });
      });
    }
  };

export const manageCoordinationCombo =
  (selectedDevice: Device<CircuitBreaker>, value: string, deviceId: string): ThunkAction<void> =>
  (dispatch) => {
    const notRequested = value === 'Not requested';
    let backObject = selectedDevice.AvailableDiscrimination || selectedDevice.AvailableBackup;
    if (!notRequested) {
      backObject = value === 'Selectivity' ? selectedDevice.AvailableDiscrimination : selectedDevice.AvailableBackup;
    }

    backObject.Relation.forEach((relItem) => {
      const link = {
        allowed: true,
        check: !!notRequested,
        checkedValue: typeof relItem.upStreamId === 'string' ? relItem.upStreamId : relItem.upStreamId['#'],
        linkType: value === 'Selectivity' ? 'DISC' : 'BCK',
        property: 'Idk',
        unCheckedValue: '',
      };
      dispatch(discrimHandler(link, !notRequested, relItem));
      dispatch(getRelations(deviceId));
    });
  };

export const getComponentRelations =
  (selectedDeviceId: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(getRelations(selectedDeviceId, false));
    dispatch(updateProtectionHandler(selectedDeviceId, 'DiscriminationPropertiesList', {}, true));
    dispatch(updateProtectionHandler(selectedDeviceId, 'BackupPropertiesList', {}, true));
  };
