import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'CAPACITORBANKS',
  objectType: EDeviceObjectType.Capacitor,
  deviceType: EDeviceType.CAPACITORBANKS,
  Locked: false,
  DesPowerFactor: 0,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400,
  frequency: 50,
  Uf: 1,
  VoltageDropMaX: 4,
  VoltageDrop: 0,
  LoadCurrent: 5.77350269189626,
  CosPhiNominal: 0,
  Pn: 4000,
  NominalPower: 4000,
};
