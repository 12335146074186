/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
import { batch } from 'react-redux';
import { processClientMessages, removeOldMessages } from 'project/actions/projectMessages';

import { deviceHasCurves } from 'curves/Constants';

import { ThunkAction } from 'types/known-actions';
import { DocCell } from 'types/devices/cell';
import { IAddElement, IDevicesThunkActions } from 'types/devices/actions';
import { DeviceActions } from 'store/devices/actions';
import { setProjectDirty } from 'project/actions';
import { updateComputeParams } from 'compute/actions';
import { ECellType, EDeviceType } from 'types/devices/enums.d';
import { Device, IJsonPort } from 'types/devices/device';
import _ from 'lodash';
import { undoActions } from 'undo/actions';
import { ApplicationState } from 'types/store';

import { ICustomCurve } from 'types/curves';
import { IRelationPropagate } from 'types/devices/protectedObjects';
import { supportSwitchboard, VERSION_FILTER_PRIORITY } from '../constants';

export const ADD_ELEMENTS = 'ADD_ELEMENTS';
export const UPDATE_ELEMENTS_POSITION = 'UPDATE_ELEMENTS_POSITION';
export const UPDATE_ELEMENT_PARAMS = 'UPDATE_ELEMENT_PARAMS';
export const UPDATE_LINKS = 'UPDATE_LINKS';
export const REMOVE_CELLS = 'REMOVE_CELLS';
export const DELETE_ELEMENT_PARAMS = 'DELETE_ELEMENT_PARAMS';
export const UPDATE_ELEMENT_ERRORS = 'UPDATE_ELEMENT_ERRORS';
export const ADD_RAW_ELEMENT = 'ADD_RAW_ELEMENT';
export const UPDATE_ALL_ELEMENTS_PARAMS = 'UPDATE_ALL_ELEMENTS_PARAMS';
export const ADD_PREVIOUS_STATE = 'ADD_PREVIOUS_STATE';
export const ADD_NEXT_STATE = 'ADD_NEXT_STATE';
export const REMOVE_UNDO = 'REMOVE_UNDO';

/**
 * Adds new or copy/paste elements to the state
 * @param {*} cells New cells
 * @param {*} clonesParams In case of pasted cells this is a dict of id=>objectParams
 */
export const addElements =
  (cells: DocCell[]): ThunkAction<void> =>
  (dispatch, getState) => {
    const elements = cells.map((cell) => {
      const { position } = cell.cell.attributes;
      const { size } = cell.cell.attributes;
      const { id } = cell.cell.attributes;
      const elementType = cell.cell.attributes.type;
      let clonesParams = cell.clonesParams ? cell.clonesParams : undefined;
      // Set standards for cables
      if (elementType === EDeviceType.WIRELV) {
        clonesParams = {
          ...(clonesParams as Device<Record<string, unknown>>),
          ScStandard: getState().standards.ScStandard,
          ScStandardList: getState().standards.ScStandardList,
          isFreshlyInserted: true,
        };
      }
      let SwitchBoardName;
      let switchboardPropertyName;
      const switchboardTypes =
        elementType === EDeviceType.BUSBAR ? [EDeviceType.LV_SWITCHBOARD] : supportSwitchboard(elementType);
      const { devices } = getState();
      const deviceKeys = Object.keys(devices);
      if (switchboardTypes && deviceKeys.length > 0) {
        SwitchBoardName = deviceKeys.find((id) => switchboardTypes.includes(devices[id].deviceType) && devices[id].first);
        if (!SwitchBoardName) {
          // Can be LV and not first when project is MV first is MVswitchboard
          SwitchBoardName = deviceKeys.find((id) => switchboardTypes.includes(devices[id].deviceType));
        }
        switchboardPropertyName =
          devices[SwitchBoardName as string].deviceType === EDeviceType.MV_SWITCHBOARD ? 'MvSwitchBoardName' : 'SwitchBoardName';
        // switchboardPropertyName = 'SwitchBoardName';
      }

      return {
        elementId: id,
        elementType,
        x: position.x,
        y: position.y,
        w: size.width,
        h: size.height,
        clonesParams,
        [switchboardPropertyName as string]: SwitchBoardName,
        json: '',
      } as IAddElement;
    });
    dispatch(
      DeviceActions.addDevice({
        elements,
        page: 1,
      })
    );
    // dispatch({
    //   type: ADD_ELEMENTS,
    //   elements,
    //   // lvCable,
    //   page,
    // });
    elements.forEach((element) => {
      switch (element.elementType) {
        case EDeviceType.LV_SWITCHBOARD:
        case EDeviceType.MV_SWITCHBOARD: {
          // dispatch(addSwitchboard(element.elementId, element.json.first, element.elementType));
          break;
        }
        // case 'app.TRAFO2': {
        //   const utility = devices[getUtilityId(devices)];
        //   if (utility && utility.NominalVoltage) {
        //     dispatch(updateElementParams(element.elementId, {
        //       PrimaryWindingUn: utility.NominalVoltage,
        //     }));
        //   }
        //   break;
        // }
        default:
          break;
      }
    });

    const { devices } = getState();
    const feederElement = elements.find(
      (element) =>
        element.feederId !== undefined &&
        devices[element.feederId] &&
        devices[element.feederId].deviceType !== EDeviceType.MOTOR_FEEDER
      // && typicalUnitsNames.indexOf(devices[element.feederId].deviceType) === -1
    );
    if (feederElement) {
      return;
      // setTimeout(() => dispatch(propagate(feederElement.elementId)));
    }

    const elementsToUpdate: Record<string, Partial<Device<Record<string, unknown>>>> = {};
    elements.forEach((element) => {
      const mvProjectType =
        element.elementType === EDeviceType.UTILITY_MV_SHORT || element.elementType === EDeviceType.UTILITY_MV;
      // if (!isFeeder(element.elementType)) {
      if (!element.clonesParams || (!element.clonesParams.LineType && !element.clonesParams.EarthingSystem)) {
        const { variables } = getState();
        const { mvUtilitySettings } = getState().wizard;
        elementsToUpdate[element.elementId] = {
          Frequency: variables.PlantFrequency,
          Uref: mvProjectType ? variables.PlantMvUn : variables.PlantLvUn,
          LineType: mvProjectType ? mvUtilitySettings.LineType : variables.PlantLineType,
          EarthingSystem: mvProjectType ? mvUtilitySettings.EarthingSystem : variables.PlantEarthingSystem,
          isFreshlyInserted: true,
        };
      }
      // }
    });
    batch(() => {
      if (Object.keys(elementsToUpdate).length > 0) {
        dispatch(updateAllElementsParams(elementsToUpdate, false, true));
      }
      // dispatch(setProjectDirty(true));
      // dispatch(processClientMessages());
    });
  };

/**
 * @param {*} id
 * @param {*} objectParams a hash of <objectparam, value>
 * @param {boolean} [silent=false] if true will not trigger computation
 */

export const updateElementParams: IDevicesThunkActions['updateElementParams'] =
  (id, objectParams, silent = false, undoable = false) =>
  (dispatch, getState) => {
    const { devices } = getState();
    if (!devices[id]) {
      return;
    }
    batch(() => {
      dispatch(
        DeviceActions.updateElementParams({
          id,
          objectParams,
          silent,
          undoable,
        })
      );
    });
  };

export const deleteElementParams: IDevicesThunkActions['deleteElementParams'] = (id, objectParams) => (dispatch) => {
  batch(() => {
    dispatch(
      DeviceActions.deleteElementParams({
        id,
        objectParams,
      })
    );
  });
};

export const removeCells: IDevicesThunkActions['removeCells'] =
  (cellIds, removeLinkedDevices = true) =>
  (dispatch, getState) => {
    /**
     * When removing departures reset label for all the arrivals connected to it
     */
    const { devices } = getState();
    let notRequiredComputeElements = false;
    if (
      cellIds.every(
        (elId) =>
          devices[elId] &&
          ([EDeviceType.CURVE_DIAGRAM, EDeviceType.TEXTAREA, EDeviceType.LINE].includes(devices[elId].deviceType) === true ||
            devices[elId].type === ECellType.BlockConnection)
      )
    ) {
      notRequiredComputeElements = true;
    }

    cellIds.forEach((cellId) => {
      if (!devices[cellId]) return;
      const { deviceType } = devices[cellId];
      if (deviceType === EDeviceType.DEPARTURE) {
        Object.keys(devices).forEach((id) => {
          if (devices[id].deviceType === EDeviceType.ARRIVAL && devices[id].LinkName === devices[cellId].LinkName) {
            dispatch(updateElementParams(id, { LinkName: 'Not connected' }));
          }
        });
      } else if (deviceType === EDeviceType.LV_SWITCHBOARD || deviceType === EDeviceType.MV_SWITCHBOARD) {
        const switchboardDevices: Array<string> = [];
        Object.keys(devices).forEach((id) => {
          if (devices[id].SwitchBoardName === cellId || devices[id].MvSwitchBoardName === cellId) {
            const { feederId } = devices[id];
            if (feederId && !switchboardDevices.includes(feederId)) {
              switchboardDevices.push(feederId);
              switchboardDevices.push(...(devices[feederId].json.embeds ?? []));
            } else {
              switchboardDevices.push(id);
            }
          }
        });
        if (switchboardDevices.length > 0) {
          Object.keys(devices).forEach((id) => {
            const device = devices[id];
            if (
              device.deviceType === EDeviceType.LINK &&
              device.json &&
              (switchboardDevices.includes((device.json.source as IJsonPort)?.id ?? '') ||
                switchboardDevices.includes((device.json.target as IJsonPort)?.id ?? ''))
            ) {
              switchboardDevices.push(id);
            }
          });
          batch(() => {
            dispatch(removeCells(switchboardDevices));
            // dispatch(updateConnections(true));
          });
        }
      } else if (removeLinkedDevices === true) {
        if (
          deviceType === EDeviceType.BLOCK_UTILITY ||
          deviceType === EDeviceType.BLOCK_GENERATOR ||
          deviceType === EDeviceType.BLOCK_TRAFO
        ) {
          // IF tu Remove all devices for TRAFOENCLOS TU
          const devicesToRemove = [];
          const baseDeviceId = Object.keys(devices).find((id) => devices[id].block_device === cellId);
          if (baseDeviceId) {
            if (devices[baseDeviceId].TypicalUnit === 'TRAFOENCLOS') {
              devicesToRemove.push(
                ...Object.keys(devices).filter(
                  (devId) => devices[devId].feederId && devices[devId].feederId === devices[baseDeviceId].feederId
                ),
                devices[baseDeviceId].feederId as string
              );
            }
            devicesToRemove.push(baseDeviceId);
            dispatch(removeCells(devicesToRemove, false));
          }
        } else if (
          deviceType === EDeviceType.UTILITY ||
          deviceType === EDeviceType.UTILITY_MV_SHORT ||
          deviceType === EDeviceType.UTILITY_MV ||
          deviceType === EDeviceType.UTILITY_SMALL ||
          deviceType === EDeviceType.UTILITY_MV_SMALL ||
          deviceType === EDeviceType.TRAFO2 ||
          deviceType === EDeviceType.GENERATOR ||
          deviceType === EDeviceType.GENERATOR_SMALL ||
          deviceType === EDeviceType.TRAFOENCLOS ||
          deviceType === EDeviceType.GENERATOR_BIO ||
          deviceType === EDeviceType.GENERATOR_PHOTO ||
          deviceType === EDeviceType.GENERATOR_WIND
        ) {
          const blockDeviceId = Object.keys(devices).find((id) => devices[id].base_device === cellId);
          if (blockDeviceId) {
            const blockConnections = Object.keys(devices).filter(
              (devId) =>
                devices[devId].type === ECellType.BlockConnection &&
                (devices[devId].sourceId === blockDeviceId || devices[devId].targetId === blockDeviceId)
            );
            dispatch(removeCells([blockDeviceId, ...blockConnections], false));
            // Remove block connections in connections state
            // const { connections } = getState();
            // const newBlockConnections = new Array<INotDirectEdge>();
            // if (connections.block && (connections.block as Array<INotDirectEdge>).length > 0) {
            //   (connections.block as Array<INotDirectEdge>).forEach((conn) => {
            //     if (blockConnections.indexOf(conn.id) === -1) {
            //       newBlockConnections.push(conn);
            //     }
            //   });
            //   dispatch(
            //     DeviceActions.updateConnections({
            //       block: newBlockConnections,
            //       shouldCompute: false,
            //     })
            //   );
            // }
          }
        }
      }
    });
    cellIds.forEach((cId) => {
      const objectId = devices[cId].ObjectId;
      if (objectId) {
        Object.keys(devices).forEach((dId) => {
          const availableBackup = devices[dId].AvailableBackup as { Relation: IRelationPropagate[] };
          const availableDiscrimination = devices[dId].AvailableDiscrimination as { Relation: IRelationPropagate[] };
          const discrimination = devices[dId].Discrimination as { Relation: IRelationPropagate[] };
          if (availableBackup && availableBackup.Relation && availableBackup.Relation.length > 0) {
            const newRelations = availableBackup.Relation.filter((rel) => rel.upStreamId !== objectId);
            if (availableBackup.Relation.length !== newRelations.length) {
              dispatch(updateElementParams(dId, { AvailableBackup: { Relation: newRelations } }));
            }
          }
          if (availableDiscrimination && availableDiscrimination.Relation && availableDiscrimination.Relation.length > 0) {
            const newRelations = availableDiscrimination.Relation.filter((rel) => rel.upStreamId !== objectId);
            if (availableDiscrimination.Relation.length !== newRelations.length) {
              dispatch(updateElementParams(dId, { AvailableDiscrimination: { Relation: newRelations } }));
            }
          }
          if (discrimination && discrimination.Relation && discrimination.Relation.length > 0) {
            const newRelations = discrimination.Relation.filter((rel) => rel.upStreamId !== objectId);
            if (discrimination.Relation.length !== newRelations.length) {
              dispatch(updateElementParams(dId, { Discrimination: { Relation: newRelations } }));
            }
          }
        });
      }
    });
    batch(() => {
      if (!notRequiredComputeElements) {
        dispatch(removeOldMessages(cellIds));
      }
      dispatch(
        DeviceActions.removeCells({
          cellIds,
        })
      );
    });
  };

// const removeOldCurveMessages = (): ThunkAction<void> => (dispatch, getState) => {
//   dispatch(setProjectMessages('curvesErrors', []));
//   dispatch(setProjectMessages('curvesWarnings', []));
// };

export const addRawElement: IDevicesThunkActions['addRawElement'] =
  (id, rawElement, page = null, updateIdSign = false) =>
  (dispatch, getState) => {
    if (!rawElement) {
      return;
    }
    const { pages, project, variables } = getState();
    if (page == null) {
      // eslint-disable-next-line no-param-reassign
      page = pages[project.selectedPage].number;
    }
    switch (rawElement.deviceType) {
      case EDeviceType.GENERATOR:
      case EDeviceType.UTILITY:
        rawElement.NominalVoltage = variables.PlantLvUn;
        rawElement.Uref = variables.PlantLvUn;
        break;
      case EDeviceType.UTILITY_MV_SHORT:
      case EDeviceType.UTILITY_MV:
        rawElement.NominalVoltage = variables.PlantMvUn;
        rawElement.Uref = variables.PlantMvUn;
        break;
      default:
        break;
    }

    batch(() => {
      dispatch(
        DeviceActions.addRawElement({
          id,
          rawElement,
          page: page ?? 1,
          updateIdSign,
        })
      );
      dispatch(setProjectDirty(true, id));
      dispatch(processClientMessages());
    });
  };

export const updateAllElementsParams: IDevicesThunkActions['updateAllElementsParams'] =
  (elements, silent = true, isFreshlyInserted = false) =>
  (dispatch, getState) => {
    const { devices, project } = getState();
    const checkedElements: Record<string, Partial<Device>> = {};
    Object.keys(elements).forEach((deviceId) => {
      if (devices[deviceId]) {
        checkedElements[deviceId] = elements[deviceId];
      }
    });
    if (!_.isEmpty(checkedElements)) {
      batch(() => {
        dispatch(
          DeviceActions.updateAllElementsParams({
            elements: checkedElements,
            silent,
            isFreshlyInserted,
          })
        );
        if (silent === false) {
          dispatch(setProjectDirty(true));
        }
        if (project.dirty) {
          dispatch(updateComputeParams({ saveProject: true }));
        }
      });

      // redrawLabels(getState(), Object.keys(elements));
    }
  };

export const setDeviceRequestRunning: IDevicesThunkActions['setDeviceRequestRunning'] =
  (selectedDeviceId) => (dispatch, getState) => {
    if (Object.keys(getState().devices).indexOf(selectedDeviceId) !== -1) {
      const running = !getState().devices[selectedDeviceId].requestRunning;
      dispatch(updateElementParams(selectedDeviceId, { requestRunning: running }, true));
    }
  };

export const addPreviousUndoState: IDevicesThunkActions['addPreviousUndoState'] = (deviceId) => (dispatch, getState) => {
  const state = getState();
  const elementId = deviceId && typeof deviceId === 'string' ? deviceId : state.project.selectedDeviceId ?? '';
  dispatch(DeviceActions.addPreviousState({ devices: { [elementId]: state.devices[elementId] }, variables: state.variables }));
};

export const onFocusOutHandler: IDevicesThunkActions['onFocusOutHandler'] = () => (dispatch, getState) => {
  const state = getState();
  const elementId = state.project.selectedDeviceId;
  if (elementId) {
    dispatch(undoActions.addNextState({ nextState: { [elementId]: state.devices[elementId] } }));
    // redrawLabels(getState(), [elementId]);
  }
};

export const collectLeftPanelData = (
  devices: ApplicationState['devices'],
  customDevices: Record<string, ICustomCurve>,
  mainStandard?: string
) => {
  const tableData: Record<string, unknown>[] = [];
  Object.keys(devices).forEach((deviceId) => {
    const device = devices[deviceId];
    if (device.TypicalUnitSign && tableData.some((dev) => dev.ObjectSign === device.TypicalUnitSign)) {
      return;
    }
    if (device && device.page !== 0 && deviceHasCurves(device.deviceType, mainStandard === 'UL')) {
      tableData.push({
        ...device,
        deviceId,
        ...(device.SwitchBoardName ? { SwitchBoardName: devices[device.SwitchBoardName as string].SwitchBoardName } : {}),
        ...(device.TypicalUnitSign ? { ObjectSign: device.TypicalUnitSign } : {}),
      });
    }
  });
  // Custom devices
  Object.keys(customDevices).forEach((customId) => {
    const customDevice = customDevices[customId];
    if (customDevice.checked) {
      tableData.push({
        ObjectSign: '',
        ProductDescription: customDevice.name,
        Uref: customDevice.voltage,
        id: customId,
        custom: true,
      });
    }
  });
  return tableData;
};

export const disableOnEditForMVStuff = (item: Record<string, unknown>) => {
  if (item && item.TypicalUnitName && item.TypicalUnitName !== undefined && item.TypicalUnitName !== null) {
    return true;
  }
  return false;
};

export const sortVersionFilter = (array: Array<string>): Array<string> => {
  return array.slice().sort((a, b) => VERSION_FILTER_PRIORITY.indexOf(a) - VERSION_FILTER_PRIORITY.indexOf(b));
};
