import Konva from 'konva';
import { DEFAULT_CURRENTS_LINE_COLOR, DEFAULT_CURVE_STROKE_WIDTH } from 'curves/Constants';
import GridSizeConfig from 'curves/GridSizeConfig';
import { Device } from 'types/devices/device';

export const drawLegend = (
  legendGroup: Konva.Group,
  label: string,
  sizeConfig: GridSizeConfig,
  device: Device<Record<string, unknown>>,
  currents: boolean | number[],
  curveColor: string | undefined,
  point = -1
): void => {
  // console.log(height, width);
  const rectWidth = sizeConfig.width > 600 ? sizeConfig.width / 3.5 : sizeConfig.width / 2;
  const rectHeight = 15;
  // const rectX = sizeConfig.width - (rectWidth + 10 * window.miniCurves.scale);
  const rectX = window.miniCurves.legendCoordinates ? window.miniCurves.legendCoordinates.x : 5;
  const rectY = window.miniCurves.legendCoordinates ? window.miniCurves.legendCoordinates.y : 5;
  const text = point !== -1 ? `${label}=${sizeConfig.getLabelForX(point)}` : label;
  // console.log(rectWidth, rectHeight);
  // console.log(rectX);
  if (legendGroup.children) {
    let legendRect = legendGroup.children.find((child) => child.id() === 'LegendRect');
    if (!legendRect) {
      legendRect = new Konva.Rect({
        x: rectX,
        y: rectY,
        width: rectWidth,
        height: rectHeight,
        fill: 'white',
        id: 'LegendRect',
        draggable: true,
      });
      legendRect.on('dragstart', () => {
        // stop rect dragging
        legendRect?.stopDrag();
        legendGroup.startDrag();
      });
      legendGroup.add(legendRect);
    }

    const labelCounter =
      legendGroup.children.filter((child) => child.name() === 'LegendLabel').length * 12 * window.miniCurves.scale;
    const labelX = rectX + 22 * window.miniCurves.scale;
    const labelY = rectY + labelCounter;

    drawLine(legendGroup, rectX, labelY + 8 * window.miniCurves.scale, currents, curveColor);
    drawLabel(legendGroup, labelX, labelY, text);

    if (legendRect) {
      legendRect.height(legendRect.height() + 12 * window.miniCurves.scale);
    }
  }
};

const drawLabel = (legendGroup: Konva.Group, x: number, y: number, text: string) => {
  const label = new Konva.Label({
    x,
    y,
    name: 'LegendLabel',
  });
  const labelText = new Konva.Text({
    text,
    fontSize: 12 * window.miniCurves.scale,
    fontFamily: 'Calibri',
    padding: 3,
    fill: 'black',
    draggable: true,
  });
  label.add(labelText);
  labelText.on('dragstart', () => {
    // stop rect dragging
    labelText?.stopDrag();
    legendGroup.startDrag();
  });

  legendGroup.add(label);
};

const drawLine = (legendGroup: Konva.Group, x: number, y: number, currents: boolean | number[], lineStroke?: string) => {
  const dash = Array.isArray(currents) ? currents : undefined;
  if (currents) {
    // eslint-disable-next-line no-param-reassign
    lineStroke = DEFAULT_CURRENTS_LINE_COLOR;
  }

  const legendLine = new Konva.Line({
    points: [x, y, x + 20 * window.miniCurves.scale, y],
    width: 10 * window.miniCurves.scale,
    // strokeWidth: 2 * window.miniCurves.scale,
    strokeWidth: DEFAULT_CURVE_STROKE_WIDTH * window.miniCurves.scale,
    fill: 'black',
    stroke: lineStroke,
    name: 'LegendLine',
    dash,
  });
  legendLine.on('dragstart', () => {
    // stop rect dragging
    legendLine?.stopDrag();
    legendGroup.startDrag();
  });
  legendGroup.add(legendLine);
};
