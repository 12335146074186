// @ts-nocheck
import React, { FunctionComponent } from 'react';
import Combo from 'components/curves/Combo';
import Switch from 'components/generic/Switch';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { ITwoDoubleCombo } from 'types/curves/twoDoubleCombo';

const TwoDoubleCombo: FunctionComponent<ITwoDoubleCombo> = ({
  title,
  bEnabled,
  on,
  options1,
  options2,
  value1,
  value2,
  onValue1Change,
  onValue2Change,
  onOnChange,
  curvesRequestIsRunning,
  onFocus,
}): React.ReactElement => {
  return (
    <Grid className="curve-function">
      <GridRow className="curve-function-row">
        <GridColumn col={3}>
          <Switch
            disabled={!bEnabled || curvesRequestIsRunning}
            value={on}
            textAlign="right"
            onChange={(event) => onOnChange(event.value)}
            label={title}
            name={title}
          />
        </GridColumn>
      </GridRow>
      <GridRow className="curve-function-row">
        <GridColumn col={4}>
          <Combo
            width={8}
            on={on}
            options={options1}
            value={value1}
            onChange={onValue1Change}
            onFocus={onFocus}
            isString={false}
            curvesRequestIsRunning={curvesRequestIsRunning}
            title={title}
            editable
          />
        </GridColumn>
        <GridColumn col={4}>
          <Combo
            width={8}
            on={on}
            options={options2}
            value={value2}
            onChange={onValue2Change}
            onFocus={onFocus}
            isString={false}
            curvesRequestIsRunning={curvesRequestIsRunning}
            title={title}
            editable
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default TwoDoubleCombo;
