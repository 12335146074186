import _ from 'lodash';
import { generateSymbols } from 'project/utils/symbols';
import { IScheme } from 'types/compute';
import { Device, DeviceInitialState } from 'types/devices/device';
import { ECellType, EDeviceType } from 'types/devices/enums.d';
import { isLink, isTypicalUnit } from 'types/devices/type-guards';
import { ApplicationState } from 'types/store';
import DEFAULT_LINK_STATE from 'types/devices/link/initial-state';
import { NOT_COMPUTED_ELEMENTS } from '../constants';

// export const propagateFilter = (element) => NOT_COMPUTED_ELEMENTS.indexOf(element.deviceType) === -1 && element.type != 'BLOCK_DEVICE' ||
// element.type === 'BLOCK_DEVICE' && element.deviceType === 'app.MV_SWITCHBOARD';

export const propagateFilter = (element: Device): boolean =>
  (NOT_COMPUTED_ELEMENTS.indexOf(element.deviceType) === -1 && element.type !== ECellType.BlockDevice) ||
  (element.type === ECellType.BlockDevice && element.deviceType === EDeviceType.MV_SWITCHBOARD);
/*
 * Checks if there are no validation errors in the schema
 * Returns false as soon as it finds a device with at least one validation error
 * @param {Object} elements
 */

export const isNetworkValid = (elements: Record<string, Device>): boolean =>
  !Object.keys(elements).some((key) => elements[key].errors && Object.keys(elements[key].errors ?? {}).length > 0);

export default (state: ApplicationState, allowInvalid = false): IScheme | undefined => {
  const { devices } = state;
  if (allowInvalid || isNetworkValid(devices)) {
    const scheme: Record<string, DeviceInitialState> = {};
    Object.keys(devices).forEach((id) => {
      const element = devices[id];
      if (element) {
        if (isLink(element)) {
          const linkDefaultState = _.cloneDeep(DEFAULT_LINK_STATE);
          if (element.type === ECellType.BlockConnection) {
            linkDefaultState.type = ECellType.BlockConnection;
          }
          const { vertices } = element;
          let index = 0;
          for (let i = 1; i < vertices.length; i += 1) {
            scheme[`${id}#${(index += 1)}`] = {
              ...linkDefaultState,
              ...element,
              page: element.page,
              x1: vertices[i - 1].x,
              y1: vertices[i - 1].y,
              x2: vertices[i].x,
              y2: vertices[i].y,
            };
          }
        } else if (
          NOT_COMPUTED_ELEMENTS.indexOf(element.deviceType) === -1 &&
          // !Object.values(ETypicalUnitNames).includes(element.deviceType)
          !isTypicalUnit(element.deviceType)
          // ETypicalUnitNames.indexOf(element.deviceType) === -1
        ) {
          /**
           * Filter out the feeders
           * TODO they have no meening for the compute api?
           */
          scheme[id] = { ...devices[id] };
          delete scheme[id].json; // TODO take care of this shit
          delete scheme[id].errors;
        }
      }
    });
    const symbols = generateSymbols(scheme as Record<string, Device>);
    return {
      variables: state.variables,
      scheme: scheme as Record<string, Device>,
      symbols,
    };
  }
  return undefined;
};
