/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { Table, TableHeader } from 'components/generic/table';
import { useTranslation } from 'react-i18next';

import EditableSelect from 'components/generic/editableSelect';

import { LoadingIndicator } from '@abb/abb-common-ux-react';

import { useDispatch, useSelector } from 'store';
import { ICoordsPage } from 'types/modal/coordinationModal/types';
import { selectionList } from './constants';
import { inputUpdateHandler, onFocusOutHandler, onModalClose } from './actions';

const CoordinationXAxisComponent: React.FunctionComponent<ICoordsPage> = ({
  selectedDevice,
  selectedDeviceId,
  diagramType,
}): React.ReactElement => {
  const dispatch = useDispatch();

  const curves = useSelector((state) => state.curves);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      onFocusOutHandler({
        name: 'coordinationXValue',
        type: 'number',
        value: '1',
      })
    );
    return () => dispatch(onModalClose(selectedDeviceId, 'coordsX', 'coordinationXvalue'));
  }, []);

  let unit = '';
  if (diagramType === 'Time/Current') {
    unit = '[s]';
  }
  if (diagramType === 'ShortCircuit/Energy') {
    unit = '[MA<sup>2</sup>s]';
  }
  if (diagramType === 'ShortCircuit/Peak') {
    unit = '[kA]';
  }

  const customInputs = [
    {
      index: 2,
      input: (item: Record<string, unknown>) => (
        <div>
          <span>
            {item.min === ''
              ? item.min
              : selectedDevice && diagramType === 'ShortCircuit/Energy'
              ? (parseFloat(item.min as string) / 1000000).toFixed(3)
              : parseFloat(item.min as string).toFixed(3)}
          </span>
        </div>
      ),
    },
    {
      index: 3,
      input: (item: Record<string, unknown>) => (
        <div>
          <span>
            {item.max === ''
              ? item.max
              : selectedDevice && diagramType === 'ShortCircuit/Energy'
              ? (parseFloat(item.max as string) / 1000000).toFixed(3)
              : parseFloat(item.max as string).toFixed(3)}
          </span>
        </div>
      ),
    },
  ];

  return (
    <Grid>
      <GridRow>
        <GridColumn col={1}>
          <EditableSelect
            cols={[7, 8, 0, 0]}
            type={'number'}
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
            className={'info-project-input'}
            name={'coordinationXValue'}
            label={'* Current [kA]'}
            selectList={selectionList}
            valueList={selectionList}
            step={0.1}
            // disabled={curves.coordinationRequestRunning}
            data={selectedDevice}
          />
        </GridColumn>
        {curves.coordinationRequestRunning ? (
          <GridColumn col={1}>
            <LoadingIndicator className={'previewText'} sizeClass={'small'} type={'radial'} determinate={false} color={'blue'} />
          </GridColumn>
        ) : null}
      </GridRow>
      <hr></hr>
      {selectedDevice && selectedDevice.coordsX ? (
        <GridRow>
          <GridColumn>
            <Table
              data={selectedDevice.coordsX as Record<string, unknown>[]}
              valueAttr={'DeviceId'}
              customInputs={customInputs}
              maxHeight={150}
              specificColumnWidth={['200px', '200px', '100px', '100px']}
              selectedValue=""
            >
              <TableHeader attr={'deviceSign'} key={'coordsXdeviceSign'}>
                {t('DOCWEB_MODAL_DEVICE')}
              </TableHeader>
              <TableHeader attr={'switchboardName'} key={'coordsXswitchboardSign'}>
                {t('STRING_LOCATION')}
              </TableHeader>
              <TableHeader attr={'min'} key={'coordsXminValue'}>
                {<span dangerouslySetInnerHTML={{ __html: t(`Min ${unit}`) }} />}
              </TableHeader>
              <TableHeader attr={'max'} key={'coordsXmaxValue'}>
                {<span dangerouslySetInnerHTML={{ __html: t(`Max ${unit}`) }} />}
              </TableHeader>
            </Table>
          </GridColumn>
        </GridRow>
      ) : null}
    </Grid>
  );
};

export default CoordinationXAxisComponent;
