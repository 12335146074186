import { ICurvesResponse } from 'types/api/curves';
import { IScheme } from 'types/compute';
import ApiCalls from './ApiCalls';

export default class CurvesAPI {
  static async curves(requestBody: IScheme): Promise<ICurvesResponse> {
    const { data } = await ApiCalls.post<ICurvesResponse, IScheme>('Curves/', requestBody);
    return data;
  }

  static async curvesParams(requestBody: IScheme): Promise<ICurvesResponse> {
    const { data } = await ApiCalls.post<ICurvesResponse, IScheme>('CurvesParams/', requestBody);
    return data;
  }
}
