import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  objectType: EDeviceObjectType.MvFuseGear,
  deviceType: EDeviceType.FUSEMV,
  Locked: false,
  symbol: 'FUSEMV',

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  requestRunning: false,
  PreFiltersChanged: true,
  Frequency: '50',
  IsMVAccessoriesPlaceHolder: true,
  MvPanelBreakingTechnology: 'Vacuum',
  MvPanelIcw: 16,
  MvPanelUr: 17500,
  NominalVoltage: 15000,
  Uref: 17500,
  PanelNumber: '1',
  TypicalUnit: 'SFC',
  TypicalUnitId: 'SFC',
  TypicalUnitName: 'SFC',
  page: 1,
};
