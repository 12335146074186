/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { DEVICES, PROJECT, USER } from 'types/constants.d';
import { DevicesState } from 'types/devices';
import { Device } from 'types/devices/device';
import { ECellType, EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType, ESymbolStatus } from 'types/devices/enums.d';
import { DevicesKnownActions } from 'types/known-actions';
import { createElement, deNullDeviceData, updateElement, updateElementPosition, updateLink, updateObjectIdSign } from './utils';

export const initialState: DevicesState = {
  'a72fe36c-4d64-400b-990e-94b7d9a9b3c9': {
    type: ECellType.BlockDevice,
    page: 0,
    objectType: EDeviceObjectType.MvSwitchBoard,
    ObjectStatus: ESymbolStatus.NotChecked,
    symbol: '',
    ObjectId: 'e1',
    ObjectSign: '+Q1',
    x: 130,
    y: 40,
    labelsPosition: 1,
    LineType: ELvLineType.LLLN,
    distributionSystemList: [EEarthingSystem.TNS],
    EarthingSystem: EEarthingSystem.TNS,
    lineTypeList: [ELvLineType.LLLN],
    errors: {},
    isFreshlyInserted: true,
    deviceType: EDeviceType.MV_SWITCHBOARD,
    SwitchBoardName: 'MvSwitchboard1',
    FamilyList: ['UniSec', 'UniGear'],
    SwitchBoardFamily: 'UniSec',
    MvPanelBreakingTechnology: 'Vacuum',
    MvPanelUr: 17500,
    MvPanelIr: 630,
    MvPanelIcw: 16,
    MvTypeReadOnly: true,
    json: {
      ports: {
        groups: {
          default: {
            position: {
              name: 'absolute',
            },
            attrs: {
              '.joint-port-body': {
                fill: 'transparent',
                stroke: 'red',
                strokeWidth: 'inherit',
                r: 3,
                magnet: 'passive',
                fillOpacity: 0.1,
                strokeOpacity: 1,
              },
            },
          },
        },
        items: [
          {
            group: 'default',
            args: {
              x: 5,
              y: 0,
            },
            id: '990361db-f6dd-4fed-8b0e-b0532e2cf12c',
          },
          {
            group: 'default',
            args: {
              x: 10,
              y: 0,
            },
            id: '918e52c5-0ddd-41da-9561-5059c6c4ea00',
          },
          {
            group: 'default',
            args: {
              x: 15,
              y: 0,
            },
            id: 'a82ce22e-a5fd-4bed-95ba-1a26a8b14e7d',
          },
          {
            group: 'default',
            args: {
              x: 20,
              y: 0,
            },
            id: 'ff6c8f91-1919-4419-8bf7-456b980fa013',
          },
          {
            group: 'default',
            args: {
              x: 25,
              y: 0,
            },
            id: 'e0fc0c3e-c3ac-4584-bdd6-30bbb82e1b12',
          },
          {
            group: 'default',
            args: {
              x: 5,
              y: 20,
            },
            id: 'a34b22d9-9ba0-444a-8996-0a0f18b78866',
          },
          {
            group: 'default',
            args: {
              x: 10,
              y: 20,
            },
            id: 'acefdff3-7c1b-4690-9196-f4f65c14225f',
          },
          {
            group: 'default',
            args: {
              x: 15,
              y: 20,
            },
            id: '58e1fbff-ae20-4ce3-b965-f146920f0bdb',
          },
          {
            group: 'default',
            args: {
              x: 20,
              y: 20,
            },
            id: '883e939e-eba4-46df-a538-36f4ab30ff69',
          },
          {
            group: 'default',
            args: {
              x: 25,
              y: 20,
            },
            id: '9acadfa3-1205-46d9-8d22-a8c09583c7dd',
          },
        ],
      },
      angle: 0,
      position: {
        x: 130,
        y: 40,
      },
      size: {
        width: 1,
        height: 1,
      },
      type: EDeviceType.MV_SWITCHBOARD,
      id: 'a72fe36c-4d64-400b-990e-94b7d9a9b3c9',
      first: true,
      z: 2,
    },
    first: true,
    Frequency: '50',
    NominalVoltage: 400,
  },
  '547dc1fe-3c77-4bfa-8512-c46e7d2c948b': {
    type: ECellType.BlockDevice,
    page: 0,
    objectType: EDeviceObjectType.SwitchBoard,
    ObjectStatus: ESymbolStatus.NotChecked,
    symbol: '',
    ObjectId: 'e2',
    ObjectSign: '+Q2',
    x: 130,
    y: 140,
    labelsPosition: 1,
    LineType: ELvLineType.LLLN,
    distributionSystemList: [EEarthingSystem.TNS],
    EarthingSystem: EEarthingSystem.TNS,
    lineTypeList: [ELvLineType.LLLN],
    errors: {},
    isFreshlyInserted: true,
    deviceType: EDeviceType.LV_SWITCHBOARD,
    SwitchBoardName: 'Switchboard1',
    SwitchBoardTemperature: '40',
    McbStandard: 'IEC90947-2',
    UsageClass: 'CATIII',
    SegregationForm: '1',
    TerminalType: 'All',
    json: {
      ports: {
        groups: {
          default: {
            position: {
              name: 'absolute',
            },
            attrs: {
              '.joint-port-body': {
                fill: 'transparent',
                stroke: 'red',
                strokeWidth: 'inherit',
                r: 3,
                magnet: 'passive',
                fillOpacity: 0.1,
                strokeOpacity: 1,
              },
            },
          },
        },
        items: [
          {
            group: 'default',
            args: {
              x: 5,
              y: 0,
            },
            id: '5ee2a8d1-df39-4e0a-8e9f-2c67b8b2a6b3',
          },
          {
            group: 'default',
            args: {
              x: 10,
              y: 0,
            },
            id: '9dc505c0-3115-4116-9488-64c6338f1a31',
          },
          {
            group: 'default',
            args: {
              x: 15,
              y: 0,
            },
            id: '9903f332-9995-46b2-a664-73880eb6307b',
          },
          {
            group: 'default',
            args: {
              x: 20,
              y: 0,
            },
            id: 'b206f2e6-fff9-49f7-b97a-7570a68fdd82',
          },
          {
            group: 'default',
            args: {
              x: 25,
              y: 0,
            },
            id: '57c76f55-1b8f-489c-a571-de8c0b2351b5',
          },
          {
            group: 'default',
            args: {
              x: 5,
              y: 20,
            },
            id: '1267668a-34ed-47a2-b02e-4624d957aa22',
          },
          {
            group: 'default',
            args: {
              x: 10,
              y: 20,
            },
            id: '3b8a4e4b-6585-4bbf-a43b-d33d315dd637',
          },
          {
            group: 'default',
            args: {
              x: 15,
              y: 20,
            },
            id: 'd1c795b8-1998-4055-b4ab-e2f46f7d1ab4',
          },
          {
            group: 'default',
            args: {
              x: 20,
              y: 20,
            },
            id: '35c75400-6a66-4ba4-95f0-914f99e835e5',
          },
          {
            group: 'default',
            args: {
              x: 25,
              y: 20,
            },
            id: 'f4829a41-5b1b-40ea-84f6-0cbbf6714090',
          },
        ],
      },
      angle: 0,
      position: {
        x: 130,
        y: 140,
      },
      size: {
        width: 1,
        height: 1,
      },
      type: EDeviceType.LV_SWITCHBOARD,
      id: '547dc1fe-3c77-4bfa-8512-c46e7d2c948b',
      first: true,
      z: 3,
    },
    first: true,
    Frequency: '50',
    NominalVoltage: 400,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: DevicesKnownActions): DevicesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEVICES.UPDATE_ELEMENT_ERRORS: {
        const { id, error, name } = action.payload;
        const device = state[id];
        if (device) {
          const el = { ...device };
          if (typeof device.errors !== 'string' && typeof el.errors !== 'string') {
            el.errors = { ...device.errors };
            if (!error) {
              delete el.errors[name];
            } else {
              el.errors[name] = error;
            }
          }
          return {
            ...state,
            [action.payload.id]: el,
          };
        }
        break;
      }
      case DEVICES.ADD_ELEMENTS:
        return action.payload.elements.reduce<DevicesState>(
          (newState, el) => {
            const newElement = createElement(el, action.payload.page, el.clonesParams);
            if (newElement) {
              newState[el.elementId] = newElement;
            }
            return newState;
          },
          { ...state }
        );
      case DEVICES.UPDATE_ELEMENTS_POSITIONS:
        return action.payload.elements.reduce<DevicesState>(
          (newState, el) => {
            if (state[el.elementId]) {
              newState[el.elementId] = updateElementPosition(el, { ...state[el.elementId] });
            }
            return newState;
          },
          { ...state }
        );
      case DEVICES.UPDATE_LINKS:
        return action.payload.links.reduce<DevicesState>(
          (newState, el) => {
            newState[el.elementId] = updateLink(el, action.payload.page, state[el.elementId]);
            return newState;
          },
          { ...state }
        );
      case DEVICES.REMOVE_CELLS:
        return action.payload.cellIds.reduce<DevicesState>(
          (newState, id) => {
            delete newState[id];
            return newState;
          },
          { ...state }
        );
      case DEVICES.UPDATE_ELEMENT_PARAMS:
        if (state[action.payload.id]) {
          return {
            ...state,
            [action.payload.id]: updateElement(state[action.payload.id], action.payload),
          };
        }
        break;
      case DEVICES.DELETE_ELEMENT_PARAMS:
        if (draft[action.payload.id]) {
          draft[action.payload.id] = action.payload.objectParams.reduce<Device>(
            (element, key) => {
              delete element[key];
              return element;
            },
            { ...draft[action.payload.id] }
          );
        }
        return draft;
      case DEVICES.ADD_RAW_ELEMENT: {
        const element = action.payload.rawElement;
        if (action.payload.updateIdSign) {
          // TODO: In theory we have to pass here page.
          updateObjectIdSign(element, element.deviceType);
        }
        return {
          ...state,
          [action.payload.id]: { ...element, page: action.payload.page },
        };
      }
      case DEVICES.UPDATE_ALL_ELEMENTS_PARAMS:
        return Object.keys(action.payload.elements).reduce<DevicesState>(
          (devices, id) => {
            if (devices[id]) {
              const device = {
                ...devices[id],
              };
              device.isFreshlyInserted = action.payload.isFreshlyInserted;
              devices[id] = {
                ...device,
                ...action.payload.elements[id],
              };
              deNullDeviceData(devices[id]);
            }
            return devices;
          },
          { ...state }
        );
      case PROJECT.LOAD_PROJECT:
        return action.payload.projectState.devices;
      case USER.PASTE_MACRO:
        return {
          ...state,
          ...action.payload.devices,
        };
      // TODO: case LOAD_PROJECT_INTRO_JS
      // TODO: UNDO_DELETE_PAGE
      default:
        break;
    }
    return state;
  });
