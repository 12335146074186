/* eslint-disable no-restricted-properties */
/* eslint-disable class-methods-use-this */
import Konva from 'konva';
import CurveTypeAxisFormatter from 'curves/curveType/CurveTypeAxisFormatter';
import { GridLineTypeToColor, GridLineType } from 'curves/Constants';
import GridSizeConfig from './GridSizeConfig';

export default class Grid extends Konva.Group {
  curveShapeName: string;

  config: GridSizeConfig;

  xGridValues: Array<{ type: number; value: number; text?: string }>;

  yGridValues: Array<{ type: number; value: number; text?: string }>;

  constructor(sizeConfig: GridSizeConfig) {
    super(sizeConfig);
    this.curveShapeName = 'Grid';
    this.config = sizeConfig;
    this.xGridValues = this.computeGrid(this.config.nx, this.config.width, this.xAxisFormat.bind(this));
    this.yGridValues = this.computeGrid(this.config.ny, this.config.height, this.yAxisFormat.bind(this));
    // draw horizontal gridlines
    this.yGridValues.forEach((item) => {
      const drawY = Math.floor(this.config.height - Math.log10(item.value) * this.config.dy) + 0.5;
      const line = new Konva.Line({
        points: [0, drawY, this.config.width, drawY],
        stroke: GridLineTypeToColor[item.type],
        strokeWidth: 1,
        shadowForStrokeEnabled: false,
      });
      this.add(line);
    });

    // draw vertical gridlines
    this.xGridValues.forEach((item) => {
      const drawX = Math.floor(Math.log10(item.value) * this.config.dx) + 0.5;
      const line = new Konva.Line({
        points: [drawX, 0, drawX, this.config.height],
        stroke: GridLineTypeToColor[item.type],
        strokeWidth: 1,
        shadowForStrokeEnabled: false,
      });
      this.add(line);
    });
  }

  xAxisFormat(value: number): string {
    return CurveTypeAxisFormatter[this.config.curveType as keyof typeof CurveTypeAxisFormatter].x(value * this.config.minX);
  }

  yAxisFormat(value: number): string {
    return CurveTypeAxisFormatter[this.config.curveType as keyof typeof CurveTypeAxisFormatter].y(value * this.config.minY);
  }

  computeGrid(
    nParts: number,
    width: number,
    format: (value: number) => string
  ): Array<{ type: number; value: number; text?: string }> {
    const totalLines = nParts * 10 - nParts - 1;
    const drawMinorLines = width / totalLines >= 0; // 5.5;
    const drawMiddleLines = width / totalLines >= 0; // 5.5;
    const drawMajorLines = true;
    const gridValues = [];
    let value = 1;
    for (let i = 0; i < nParts; i += 1) {
      for (let j = 1; j < 10; j += 1) {
        if (value > 1) {
          if (value === 10 ** i) {
            if (drawMajorLines) {
              gridValues.push({
                value,
                type: GridLineType.Major,
                text: format(value),
              });
            }
          } else if (value === 5 * 10 ** i) {
            if (drawMiddleLines) {
              gridValues.push({
                value,
                type: GridLineType.Middle,
              });
            }
          } else if (drawMinorLines) {
            gridValues.push({
              value,
              type: GridLineType.Minor,
            });
          }
        }
        value += 10 ** i;
      }
    }
    return gridValues;
  }
}
