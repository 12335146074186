import { getCableDataProjectProperties } from 'devices/lvCable/actions';
import { updateUserDefaultProjectProperties } from 'actions/user';
// import {
//   setVariables,
// } from 'actions/variables';
import { setProjectDirty } from 'project/actions';
import { TDeviceEvent } from 'types/devices/device';
import { ThunkAction } from 'types/known-actions';
import { VariablesActions } from 'store/variables/actions';

// const calculateAbbFlag = () => (dispatch, getState) => {
//  const variables = getState().variables;
//  let name = '\nABBLOADFLOWFLAGS';
//  let value = variables.SIZE_OUTPUT_BRANCHES * 0x20 | variables.AUTOMATIC_PHASE_BALANCING * 0x10 | 0x9 | variables.LOADS * 0x2 | variables.SCENARIOS * 0x4;
//  dispatch(setVariables({[name]: value}));
// };
export const TAB_NAME = 'DOCWEB_STRING_OPTIONS';

export const onChange =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    let { value } = event;
    const { name } = event;
    let cableStandardChanged = false;
    switch (name) {
      case 'NeutralOptimization':
        value = !state.variables.NeutralOptimization;
        break;
      case 'PreserveConstantCurrrentLoads':
      case 'ComputeAllScenarios':
        value = value === 'true';
        break;
      default:
        break;
    }
    dispatch(VariablesActions.setVariables({ [name]: value }));

    switch (name) {
      case 'ShortCircuitStandard':
      case 'CableStandard':
        dispatch(getCableDataProjectProperties(name));
        cableStandardChanged = true;
        break;
      default:
        break;
    }
    if (!cableStandardChanged) {
      if (state.compute.saveProjectProperties) {
        dispatch(updateUserDefaultProjectProperties(null)).catch((err) => console.error(err));
      }
      dispatch(setProjectDirty(true, ''));
    }
  };
