import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'MULTIMETER',
  objectType: EDeviceObjectType.Probe,
  deviceType: EDeviceType.MULTIMETER,
  // General info
  Name: '',
  Description: '',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.IT,
  ObjectSign: '',
  UserDescription1: '',
};
