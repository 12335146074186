import React, { useEffect, useState } from 'react';
import { GridColumn, GridRow } from 'components/generic/grid';
import { Button, Input } from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import 'react-dropzone/examples/theme.css';
import CheckBox from 'components/generic/CheckBox';
import { showModal, showModalDialog } from 'ui/modals/actions';
import { pushNotification } from 'compute/actions';
import { useDispatch, useSelector } from 'store';
import { sendSupportMail } from '../../../actions/user';

const MailSupport: React.FunctionComponent<{ title?: string }> = ({ title }): React.ReactElement => {
  const [subject, setSubject] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [attachProject, setAttachProject] = useState<boolean>(true);
  const [attachedFiles, setAttachedFiles] = useState<Array<File>>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const modalStateParams = useSelector((state) => state.modal.params);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removeFile = (file: File) => () => {
    acceptedFiles.splice(
      acceptedFiles.findIndex((f) => f.name === file.name),
      1
    );
    setAttachedFiles([...attachedFiles, ...acceptedFiles]);
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes{' '}
      <i
        className="ABB_CommonUX_Icon__root ABB_CommonUX_style__icon_abb_16 ABB_CommonUX_style__icon-abb_close_16"
        onClick={removeFile(file)}
        aria-hidden="true"
        style={{ cursor: 'pointer' }}
      />
    </li>
  ));

  useEffect(() => {
    if (title) {
      setSubject(t(title));
    }
    if (modalStateParams && modalStateParams.subject) {
      setSubject(modalStateParams.subject as string);
    }
  }, []);

  const onSendRequest = () => {
    dispatch(showModal(''));
    const mailBody = `${description}\nFrom: ${window.document.location.hostname}`;
    const sendSupportPromise = dispatch(
      sendSupportMail(subject, mailBody, attachProject, acceptedFiles.length > 0 ? acceptedFiles : undefined)
    );
    sendSupportPromise
      ?.then((response) => {
        if (response && response.statusCode >= 200 && response.statusCode <= 204) {
          dispatch(pushNotification('success', t('DOCWEB_SUBMIT_SUPPORT_PUSH_NOTIFICATION')));
        } else {
          dispatch(
            showModalDialog({
              title: 'DOCWEB_MESSAGEDIALOG_ERROR_TITLE',
              message: `${t('DOCWEB_SUBMIT_SUPPORT_BAD_PUSH_NOTIFICATION')} ${response.supportMail}`,
            })
          );
        }
        if (window.sentryError) {
          setTimeout(() => window.location.reload(), 1500);
        }
      })
      .catch(() => window.location.reload());
  };

  return (
    <div className="container-fluid">
      {title ? <span>{t('DOCWEB_ERROR_SUPPORT_TITLE')}</span> : null}
      <GridRow>
        <GridColumn col={5}>
          <Input
            label={t('DOCWEB_SUPPORT_LABEL_SUBJECT')}
            type="normal"
            dataType="text"
            value={subject}
            className="DOCWeb_no-margin-bottom"
            onValueChange={(value) => setSubject(value)}
          />
        </GridColumn>
      </GridRow>
      <GridRow />
      <GridRow>
        <GridColumn col={5}>
          <Input
            label={t('DOCWEB_SUPPORT_LABEL_DESCRIPTION')}
            type="normal"
            value={description}
            className="DOCWeb_no-margin-bottom"
            onValueChange={(value) => setDescription(value)}
            dataType="textarea"
            inputAttributes={{ rows: '6' }}
          />
        </GridColumn>
      </GridRow>
      <GridRow style={{ height: '21px' }} />
      <section className="container" style={{ width: '721px' }}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{t('DOCWEB_SUPPORT_MODAL_ATTACH_FILES')}</p>
        </div>
        <aside style={{ marginLeft: '15px' }}>
          <ul>{files}</ul>
        </aside>
      </section>
      <GridRow style={{ paddingTop: '10px' }}>
        <GridColumn col={1}>
          <CheckBox
            name="Support mail checkbox"
            label="Attach project"
            value={attachProject}
            onChange={() => {
              setAttachProject(!attachProject);
            }}
          />
        </GridColumn>
        <GridColumn col={2} />
        <GridColumn col={1} style={{ paddingLeft: '49px' }} />
        <GridColumn col={1} style={{ paddingLeft: '52px' }}>
          <Button
            id="SendSupportEmail"
            text={t('DOCWEB_FOOTER_BUTTON_SUBMIT_SUPPORT')}
            type="primary-blue"
            onClick={() => onSendRequest()}
          />
        </GridColumn>
      </GridRow>
    </div>
  );
};

export default MailSupport;
