import { ECellType, EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  type: ECellType.CONNECTOON,
  symbol: 'CONNECTOON',
  objectType: EDeviceObjectType.Connection,
  deviceType: EDeviceType.CONNECTOON,

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  Uref: 400.0,
  DemandFactor: 1,
};
