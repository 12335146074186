import { PlainPoint } from 'types/devices/device';
import { EDeviceType } from 'types/devices/enums.d';

export const GRID_SIZE = 5;
export const SNAP_DISTANCE = 3 * GRID_SIZE;
export const REAL_TIME_TIMEOUT = 3.5 * 1000;
export const BBOX_INFLATE = 0.1;
export const BUSBAR_MIN_WIDTH = 50;
export const GRAPH_EVENTS_TIMEOUT = 100;
export const BUSBAR_PORT_STEP = 1;

export const MAIN_MENU_HEIGHT = 50;
export const PROJECT_TABS_HEIGHT = 42;
export const TOOLBAR_HEIGHT = 32;
export const PAPER_V_OFFSET = MAIN_MENU_HEIGHT + PROJECT_TABS_HEIGHT + TOOLBAR_HEIGHT;
export const ABB_UNLOCK_ICON = 'ABB_CommonUX_style__icon-abb_lock-open_24';
export const ABB_LOCK_ICON = 'ABB_CommonUX_style__icon-abb_lock-closed_24';
export const ABB_POWER_ON_OFF_ICON = 'ABB_CommonUX_style__icon-abb_power-on-off_24';
export const ABB_ROTATE_ICON = 'ABB_CommonUX_style__icon-abb_refresh_24';
export const LV_BLOCK_DEVICE_COLOR = '#B5EBE2';
export const MV_BLOCK_DEVICE_COLOR = '#F2AC66';
export const BLOCK_SYMBOL_BORDER_COLOR = '#525252';
export const BLOCK_SYMBOL_TEXT_COLOR = '#525252';

export enum ESymbolStatus {
  NotChecked = 'UnChecked',
  Error = 'Error',
  OK = 'OK',
  Warning1 = 'Warning',
  Warning2 = 'CurvesWarning',
  Warning3 = 'PermanentWarning',
  Offline = 'Offline',
}

export const LOCKABLE_DEVICES = [
  EDeviceType.CB_TM,
  EDeviceType.WIRELV,
  EDeviceType.FUSEBASE,
  EDeviceType.CB_FEEDER,
  EDeviceType.MOTOR_FEEDER,
  EDeviceType.HEATER_FEEDER,
  EDeviceType.VEHICLE_CHARGER_FEEDER,
  EDeviceType.CONTACTOR,
  EDeviceType.OLR,
  EDeviceType.TRAFO2,
  EDeviceType.DISCONNECTORLV,
  EDeviceType.SOFTSTARTER,
  EDeviceType.RCCB,
  EDeviceType.LOAD,
  EDeviceType.LV_SWITCHBOARD,
  EDeviceType.MV_SWITCHBOARD,
  EDeviceType.MMS,
  EDeviceType.WIREMV,
  EDeviceType.BUSDUCT,
  EDeviceType.UPS,
  EDeviceType.UPS_2_INPUT,
  EDeviceType.UPS_BYPASS,
  EDeviceType.UPS_NOBYPASS,
];

export const TYPICAL_UNITS_PROTECTION = [
  EDeviceType.MVEDISC,
  EDeviceType.CIRCUITBREAKERMV,
  EDeviceType.CIRCUITBREAKERMVW,
  EDeviceType.MVDISC,
  EDeviceType.MVDISC_DOWN,
  EDeviceType.MVSWITCH,
  EDeviceType.FUSEMV,
  EDeviceType.CONNECTOON,
];

export const CONNECTION_ERROR_KEY = '#connected#';

export const escapeXMLCharacters = (string: string): string => {
  return string
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};

export const convertBackEscapedXMLCharacters = (string: string): string => {
  return string
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

export const SCENARIO_DEVICE_TYPES = [
  EDeviceType.ATS,
  EDeviceType.CB_TM,
  EDeviceType.FUSEBASE,
  EDeviceType.DISCONNECTORLV,
  EDeviceType.RCCB,
  EDeviceType.CONTACTOR,
  EDeviceType.MMS,
  EDeviceType.OLR,
  EDeviceType.MVEDISC,
  EDeviceType.CIRCUITBREAKERMV,
  EDeviceType.CIRCUITBREAKERMVW,
  EDeviceType.MVDISC,
  EDeviceType.MVDISC_DOWN,
  EDeviceType.MVSWITCH,
];

export const isShortLink = (vertices: PlainPoint[]): boolean => {
  const lastX = vertices[vertices.length - 1].x;
  const lastY = vertices[vertices.length - 1].y;
  return (
    (vertices[0].x !== lastX && Math.abs(vertices[0].x - lastX) <= 10) ||
    (vertices[0].y !== lastY && Math.abs(vertices[0].y - lastY) <= 10)
  );
};
