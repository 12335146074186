import { updateProjectParam, projectActions } from 'project/actions';
import { updateElementParams } from 'devices/actions';
import { batch } from 'react-redux';
import { titleFromName, paramValuePresent } from 'curves/actions/Functions';
import { getCurrentUnitValue } from 'curves/actions/utils';
import { getParamValueForPrint, getOnParamValue } from 'curves/actions/functionValues';
import generateSvg from 'actions/konvaToSvg';
import translations from 'translations/DOC-en';
import { ICheckBoxOnChange } from 'types/components/checkbox';
import { ThunkAction } from 'types/known-actions';
import { ISelectOnChange } from 'types/components/selectContainer';
import { ApplicationState } from 'types/store';
import { TFunction } from 'i18next';
import { MIN_CURRENT_X } from 'curves/Constants';
import { IGenericCurveFunction } from 'types/curves/functions';
import _ from 'lodash';
import { IDeviceCurveSetting } from 'types/curves';

export const onChangeReports =
  (event: ICheckBoxOnChange): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { name } = event;
    const value = !(state.project.diagramsForPrint[name] && state.project.diagramsForPrint[name]?.checked === true);
    batch(() => {
      dispatch(updateElementParams(state.project.selectedDeviceId ?? '', { diagram: name }));
      dispatch(projectActions.updateDiagramForPrinting({ name, checked: value, svgContent: undefined }));
    });
    if (value) {
      window.miniCurves.diagramPrint = name;
    } else {
      window.miniCurves.diagramPrint = false;
    }
  };

export const onChangePrintFormat =
  (event: ISelectOnChange): ThunkAction<void> =>
  (dispatch) => {
    const { value } = event;
    const { name } = event;
    dispatch(updateProjectParam(name, value));
  };

export const selectCurrentDiagramForPrint =
  (unmount = false): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedDeviceId } = state.project;
    if (unmount) {
      dispatch(projectActions.setProjectInfo({ info: { diagramsForPrint: {} } }));
    } else if (selectedDeviceId && state.devices[selectedDeviceId] && state.devices[selectedDeviceId].diagram) {
      const selectedDiagram = state.devices[selectedDeviceId].diagram;
      if (selectedDiagram) {
        dispatch(projectActions.updateDiagramForPrinting({ name: selectedDiagram, checked: true, svgContent: undefined }));
      }
    }
  };

export const setSvgContent =
  (diagram: string): ThunkAction<void> =>
  (dispatch) => {
    if (window.miniCurves) {
      dispatch(projectActions.updateDiagramForPrinting({ name: diagram, checked: true, svgContent: generateSvg(650, 455) }));
    }
  };

const THRES_DELAY_TRANSLATION = {
  threshold: 'Threshold',
  delay: 'Delay',
};

export const getSettings = (state: ApplicationState, diagram: string, translate: TFunction) => {
  const collectedSettings: Record<string, Array<IDeviceCurveSetting>> = {};
  const currentCurve = state.curves.curve2Show?.find((curve) => curve.id === diagram);
  let selectedDeviceObjectSign = '';
  if (state.project.selectedDeviceId) {
    selectedDeviceObjectSign = state.devices[state.project.selectedDeviceId].ObjectSign || '';
  }
  currentCurve?.driven.forEach((drivenId, index) => {
    const drivenIndex = currentCurve.drivenIndex[index];
    const drivenParams = state.curves.params && state.curves.params[drivenId] ? state.curves.params[drivenId] : null;
    const params = drivenParams ? drivenParams[drivenIndex] : null;
    const device = state.devices[drivenId];
    if (device) {
      const deviceName = `${device.ObjectSign as string} ${device.ProductDescription as string}`;
      if (params) {
        const deviceSettings = [];
        if (device.NominalVoltage) {
          deviceSettings.push({ name: 'Voltage', value: `${device.NominalVoltage as number}[V]` });
        }
        if (device.CableLength) {
          deviceSettings.push({ name: 'Length', value: `${device.CableLength as number}` });
        }
        currentCurve.currents2Show.forEach((current) => {
          if (!current.currents && currentCurve.selectivityLevel) {
            if (currentCurve.selectivityLevel > MIN_CURRENT_X) {
              const { value, unit } = getCurrentUnitValue(currentCurve.selectivityLevel);
              const title = translations[current.title as keyof typeof translations]
                ? translations[current.title as keyof typeof translations]([])
                : translations[current.title as keyof typeof translations];
              deviceSettings.push({ name: `${title as string}[${unit}]`, value: `${value}` });
            }
          }
        });
        const translatedSettingOff = translate('CURVESWEB_PRINT_SETTING_OFF');
        Object.keys(params).forEach((paramName) => {
          const param = params[paramName] as IGenericCurveFunction;
          if (param) {
            const on = getOnParamValue(device, param.onName);
            if (paramValuePresent(param, paramName, 'settingName', true)) {
              const setting = getParamValueForPrint(device, param.settingName, param.min === param.max, param.min);
              if (setting) {
                deviceSettings.push({ name: translate(titleFromName(paramName)), setting });
              }
            } else if (
              paramValuePresent(param, paramName, 'secondParamName', true) &&
              paramValuePresent(param, paramName, 'secondParamName2', true) &&
              paramValuePresent(param, paramName, 'firstParams', true)
            ) {
              const value1 = getParamValueForPrint(device, param.secondParamName, true, param.secondDefault, param.secondPrec);
              const value2 = getParamValueForPrint(device, param.secondParamName2, true, param.secondDefault2, param.secondPrec2);
              const comboValue = getParamValueForPrint(device, param.firstParamName, true, param.firstParams[0]);
              let nestedSetting;
              if (on) {
                nestedSetting = collectNestedSettings(value1, value2);
              }
              const value = on === false ? translatedSettingOff : comboValue;
              deviceSettings.push({ name: translate(titleFromName(paramName)), value, nested: nestedSetting });
            } else if (
              paramValuePresent(param, paramName, 'secondParamName', true) &&
              paramValuePresent(param, paramName, 'secondParamName2', true)
            ) {
              if (paramValuePresent(param, paramName, 'secondParams') && paramValuePresent(param, paramName, 'secondParams2')) {
                const value1 = getParamValueForPrint(device, param.secondParamName, true, param.secondDefault, param.secondPrec);
                const value2 = getParamValueForPrint(
                  device,
                  param.secondParamName2,
                  true,
                  param.secondDefault2,
                  param.secondPrec2
                );
                const nestedSetting = collectNestedSettings(value1, value2);
                deviceSettings.push({ name: translate(titleFromName(paramName)), value: '', nested: nestedSetting });
              }
            } else if (
              paramValuePresent(param, paramName, 'firstParamName', true) &&
              paramValuePresent(param, paramName, 'secondParamName', true) &&
              (paramValuePresent(param, paramName, 'thirdParamName', true) ||
                paramValuePresent(param, paramName, 'fourthParamName', true))
            ) {
              let value = getParamValueForPrint(device, param.firstParamName, true, param.firstParams[0]);
              const value1 = getParamValueForPrint(device, param.secondParamName, true, param.secondDefault, param.secondPrec);
              let value2;
              if (paramValuePresent(param, paramName, 'thirdParamName', true)) {
                value2 = getParamValueForPrint(device, param.thirdParamName, true, param.thirdDefault);
              } else {
                value2 = getParamValueForPrint(device, param.fourthParamName, true, param.fourthDefault, param.fourthPrec);
              }
              let nestedSetting;
              if (on) {
                nestedSetting = collectNestedSettings(value1, value2);
              }
              value = on === false ? translatedSettingOff : (value as string);
              deviceSettings.push({ name: translate(titleFromName(paramName)), value, nested: nestedSetting });
            } else if (
              paramValuePresent(param, paramName, 'thirdParamName', true) ||
              paramValuePresent(param, paramName, 'fourthParamName', true)
            ) {
              const value1 = getParamValueForPrint(device, param.secondParamName, true, param.secondDefault, param.secondPrec);
              let value2;
              if (paramValuePresent(param, paramName, 'thirdParamName', true)) {
                value2 = getParamValueForPrint(device, param.thirdParamName, true, param.thirdDefault);
              } else {
                value2 = getParamValueForPrint(device, param.fourthParamName, true, param.fourthDefault, param.fourthPrec);
              }
              const nestedSetting = collectNestedSettings(value1, value2);
              deviceSettings.push({ name: translate(titleFromName(paramName)), value: '', nested: nestedSetting });
            } else if (paramValuePresent(param, paramName, 'secondParamName', true)) {
              if (paramValuePresent(param, paramName, 'secondParams')) {
                let value = getParamValueForPrint(device, param.secondParamName, true, param.secondDefault, param.secondPrec);
                value = on === false ? translatedSettingOff : value;
                deviceSettings.push({ name: translate(titleFromName(paramName)), value: `${value}` });
              }
            } else if (paramValuePresent(param, paramName, 'firstParamName', true)) {
              if (paramValuePresent(param, paramName, 'firstParams')) {
                let value = translateFirstParamNameValuePrint(
                  param,
                  getParamValueForPrint(device, param.firstParamName, true, param.firstParams[0]) as string
                );
                value = on === false ? translatedSettingOff : value;
                deviceSettings.push({ name: translate(titleFromName(paramName)), value: `${value}` });
              }
            }
          }
        });
        collectedSettings[deviceName] = deviceSettings;
      }
    }
  });

  const clonedSettings = _.cloneDeep(collectedSettings);

  Object.keys(clonedSettings).forEach((deviceName) => {
    let settingsLength = clonedSettings[deviceName].length;
    clonedSettings[deviceName].forEach((setting) => {
      if (setting.nested) {
        settingsLength += setting.nested.length;
      }
    });

    if (settingsLength > 13 && deviceName.includes(selectedDeviceObjectSign)) {
      collectedSettings[`${deviceName}&&secondPage`] = [];
      let setLength = 0;
      collectedSettings[deviceName].forEach((set, index) => {
        setLength += 1;
        if (set.nested) {
          setLength += set.nested.length;
        }
        if (setLength > 13) {
          delete collectedSettings[deviceName][index];
          collectedSettings[`${deviceName}&&secondPage`].push(clonedSettings[deviceName][index]);
        }
      });
    }
  });

  return collectedSettings;
};

const translateFirstParamNameValuePrint = (param: IGenericCurveFunction, value: string): string => {
  if (param.firstParamTranslation) {
    const transValue = Object.keys(param.firstParamTranslation).find((key) => param.firstParamTranslation[key] === value);
    return transValue || value;
  }
  return value;
};

const collectNestedSettings = (value1: string | number, value2: string | number) => {
  const nestedSetting = [];
  let name1;
  let name2;
  if (`${value1}`.indexOf('s') !== -1) {
    name1 = THRES_DELAY_TRANSLATION.delay;
    name2 = THRES_DELAY_TRANSLATION.threshold;
  } else {
    name1 = THRES_DELAY_TRANSLATION.threshold;
    name2 = THRES_DELAY_TRANSLATION.delay;
  }
  if (value1) {
    nestedSetting.push({
      name: name1,
      value: value1,
    });
  }
  if (value2) {
    nestedSetting.push({
      name: name2,
      value: value2,
    });
  }
  return nestedSetting;
};

export const getTextValue = (valueName: string, state: ApplicationState): string => {
  let textValue = '';
  const selectedDevice = state.devices[state.project.selectedDeviceId ?? ''];
  if (selectedDevice) {
    switch (valueName) {
      case 'Plant':
        textValue = ''; // TODO PH
        break;
      case 'Customer':
        textValue = state.project.Customer;
        break;
      case 'Switchboard':
        textValue = state.devices[selectedDevice.SwitchBoardName as string]
          ? (state.devices[selectedDevice.SwitchBoardName as string].SwitchBoardName as string)
          : '';
        break;
      case 'Designer':
        textValue = state.project.Designer || '';
        break;
      case 'Project':
        textValue = state.project.name;
        break;
      default:
        textValue = '';
    }
  }
  return _.isEmpty(textValue) ? '' : textValue;
};
