import {
  ECellType,
  EDeviceObjectType,
  EDeviceType,
  ELvLineType,
  ESwitchboardDevice,
  ETypicalUnitNames,
} from 'types/devices/enums.d';
import { IDocValidLinks } from 'types/drawingArea/appLink';
import { IAts } from './ats';
import { ICapacitorBank } from './capacitorBank';
import { ICircuitBreaker } from './circuitBreaker';
import { CrossReference } from './crossReference';
import { Device, PowerDevice } from './device';
import { Switchboard } from './switchboard';
import { IUps } from './ups';

export const isISwitchboardDevice = (deviceType: ESwitchboardDevice | EDeviceType): deviceType is ESwitchboardDevice => {
  const switchboardDevice = Object.keys(ESwitchboardDevice).map(
    (val) => ESwitchboardDevice[val as keyof typeof ESwitchboardDevice]
  );
  return switchboardDevice.some((d) => d === deviceType);
};

export const isDevice = (obj: Record<string, unknown> | Device | undefined): obj is Device => {
  return obj?.deviceType !== undefined;
};

export const isRappidDevice = (obj: Record<string, unknown> | Device): obj is Device => {
  return isDevice(obj) && obj.json !== undefined;
};

export const isStringError = (error: Device['errors'] | unknown): error is string => {
  return typeof error === 'string';
};

export const isCapacitorBank = (device: Device | ICapacitorBank): device is ICapacitorBank => {
  return device.deviceType === EDeviceType.CAPACITORBANKS;
};

export const isPowerDevice = (device: Device | Device<PowerDevice>): device is Device<PowerDevice> => {
  return device.SwitchBoardName !== undefined;
};

export const isTypicalUnit = (deviceType: EDeviceType | ETypicalUnitNames): deviceType is ETypicalUnitNames => {
  return Object.values(ETypicalUnitNames).find((d) => d === deviceType) !== undefined;
};

export const isSwitchboard = (device: Partial<Device> | Device | undefined): device is Device<Switchboard> => {
  if (!device) {
    return false;
  }
  return device.deviceType === EDeviceType.LV_SWITCHBOARD || device.deviceType === EDeviceType.MV_SWITCHBOARD;
};

export const isAts = (device: Partial<Device> | Device | undefined): device is Device<IAts> => {
  if (!device) {
    return false;
  }
  return device.deviceType === EDeviceType.ATS;
};

export const isUpsTwoInput = (device: Partial<Device> | Device | undefined): device is Device<IUps> => {
  if (!device) {
    return false;
  }
  return device.objectType === EDeviceObjectType.Ups2Input;
};

export const isTypicalFeeder = (device: Partial<Device> | Device | undefined): device is Device<CrossReference> => {
  if (!device) {
    return false;
  }
  return device.deviceType === EDeviceType.TYPICAL_FEEDER;
};

export const isLink = (device: Device): device is Device<IDocValidLinks> => {
  return device.type === ECellType.Connection || device.type === ECellType.BlockConnection;
};

export const isBomDevice = (device: Device<PowerDevice> | Device): device is Device<PowerDevice> => {
  return (
    device.ProductIdList !== undefined &&
    Array.isArray((device as Device<PowerDevice>).ProductIdList.product) &&
    (device as Device<PowerDevice>).ProductIdList.product.length > 0
  );
};

export const isELvLineType = (value: unknown | string): value is ELvLineType => {
  return Object.keys(ELvLineType).some((attr) => {
    const key = attr as keyof typeof ELvLineType;
    return value === ELvLineType[key];
  });
};

export const isCircuitBreakerDevice = (device: Device<PowerDevice> | Device): device is ICircuitBreaker['selectedDevice'] => {
  return device.deviceType === EDeviceType.CB_TM;
};
