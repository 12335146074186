import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'store';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import CheckBox from 'components/generic/CheckBox';
import Input from 'components/generic/inputContainer';
import Select from 'components/generic/selectContainer';
import EditableSelect from 'components/generic/editableSelect';
import { projectCommonData } from '../../../constants';
import Results from './Results';
import { inputUpdateHandler, onFocusOutHandler } from '../actions';

interface ILvCableConfigurationTab {
  selectedDevice: Record<string, unknown>;
  selectedDeviceId: string;
}
const ConfigurationTab: React.FunctionComponent<ILvCableConfigurationTab> = ({ selectedDevice, selectedDeviceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const descriptionSectionBody = (): React.ReactElement => {
    return (
      <>
        <GridRow key={'CURCUITPARAMS'}>
          <GridColumn col={8} key={'duCheck'} horizontalAlignment={'start'}>
            <CheckBox
              // style={{ marginLeft: '0px' }}
              name={'dUCheckOn'}
              value={
                typeof selectedDevice.dUCheckOn === 'string'
                  ? selectedDevice.DUCheckOn === 'true'
                  : (selectedDevice.dUCheckOn as boolean)
              }
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              label={'DU_ALLOWED_MSG'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={2} key={'TA'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'AmbientTemperature'}
              label={'TA [C]'}
              data={selectedDevice}
            />
          </GridColumn>
          <GridColumn col={isMobileOnly ? 4 : 2} key={'MINIZIB'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'IzIbRatio'}
              label={'MIN_IZ_IB'}
              data={selectedDevice}
              // unescapeLabel={true}
            />
          </GridColumn>
          <GridColumn col={2}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'dUMax'}
              label={'DU[%]'}
              disabled={!selectedDevice.dUCheckOn || (selectedDevice.requestRunning as boolean)}
              data={selectedDevice}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const generalProperties = (): React.ReactElement => {
    return (
      <>
        <GridRow key={'CablePropertiesContainer'}>
          <GridColumn col={isMobileOnly ? 3 : 2} key={'LOADCURRENT'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'LoadCurrent'}
              label={'LoadCurrent [A]'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={2} key={'COSF'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'ComputedCosPhi'}
              label={'COSF'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={isMobileOnly ? 3 : 2} key={'LENGTH'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'CableLength'}
              label={'LENGTH [m]'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridRow>
            <GridColumn col={isMobileOnly ? 3 : 2} key={'CABLETYPE'}>
              <Select
                cols={[7, 8, 0]}
                className={'info-project-input'}
                name={'CableType'}
                label={'CABLE_TYPE'}
                onChange={(e) => dispatch(onFocusOutHandler(e))}
                // onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
                data={selectedDevice}
                selectList={selectedDevice.CableTypeList as Array<string>}
                plainObjectModel={true}
                displayedAttribute={'TranslateString'}
                valueAttribute={'TypeIdString'}
                disabled={selectedDevice.requestRunning as boolean}
              />
            </GridColumn>
            <GridColumn col={2} key={'CONDUCTOR'}>
              <Select
                cols={[7, 8, 0]}
                className={'info-project-input'}
                name={'Conductor'}
                label={'CONDUCTOR'}
                onChange={(e) => dispatch(onFocusOutHandler(e))}
                // onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
                data={selectedDevice}
                selectList={selectedDevice.CableConductorList as Array<string>}
                plainObjectModel={true}
                displayedAttribute={'TranslateString'}
                valueAttribute={'ConductorIdString'}
                disabled={selectedDevice.requestRunning as boolean}
              />
            </GridColumn>
            <GridColumn col={isMobileOnly ? 3 : 2} key={'INSULATOR'}>
              <Select
                cols={[7, 8, 0]}
                className={'info-project-input'}
                name={'Insulator'}
                label={'INSULATOR'}
                onChange={(e) => dispatch(onFocusOutHandler(e))}
                // onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
                data={selectedDevice}
                selectList={selectedDevice.CableInsulatorList as Array<string>}
                plainObjectModel={true}
                displayedAttribute={'TranslateString'}
                valueAttribute={'InsulatorIdString'}
                disabled={selectedDevice.requestRunning as boolean}
              />
            </GridColumn>
          </GridRow>

          <GridColumn col={2} key={'MaximumShortCircuitCurrent'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'ScL3IbForCurves'}
              label={'Maximum SC current [KA]'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={2} key={'MinimumShortCircuitCurrent'}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'number'}
              onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
              onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
              className={'info-project-input'}
              name={'ScLPEIbForCurves'}
              label={'DOCCURVES_CABLE_MINSCCURRENT'}
              data={selectedDevice}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const peSection = (): React.ReactElement => {
    const disablePeSection = !!(!selectedDevice.PEPresent || (selectedDevice.PEPresent && selectedDevice.PELikeLives));
    const peLengthValue = disablePeSection ? '' : undefined;
    return (
      <>
        <GridRow key={'cableModalPropertiesGridRow'}>
          <GridColumn col={4}>
            <GridRow>
              <GridColumn col={8} key={'PEPRESENT'} horizontalAlignment={'start'}>
                <CheckBox
                  // style={{ marginLeft: '0px' }}
                  name={'PEPresent'}
                  value={selectedDevice.PEPresent as boolean}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  label={'PE'}
                  disabled={selectedDevice.requestRunning as boolean}
                />
              </GridColumn>
              <GridColumn col={8} key={'PEWITHLIVES'}>
                <CheckBox
                  // style={{ marginLeft: '0px' }}
                  name={'PEWithLives'}
                  value={selectedDevice.PEWithLives as boolean}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  label={'STRINGS_IDC_PECONDUCT_CHK'}
                  disabled={selectedDevice.requestRunning as boolean}
                />
              </GridColumn>
              <GridColumn col={8} key={'SYMMETRYRESPECTED'}>
                <CheckBox
                  // style={{ marginLeft: '0px' }}
                  name={'SymmetryRespected'}
                  value={selectedDevice.SymmetryRespected as boolean}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  label={'STRINGS_IDC_POSESYMETRY_CHK'}
                  disabled={selectedDevice.requestRunning as boolean}
                />
              </GridColumn>
            </GridRow>
          </GridColumn>
          <GridColumn col={4}>
            <GridRow>
              <GridColumn col={8} key={'PELIKELIVES'}>
                <CheckBox
                  // style={{ marginLeft: '0px' }}
                  name={'PELikeLives'}
                  value={selectedDevice.PELikeLives as boolean}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  label={'STRINGS_IDC_PEISPHASE_CHK'}
                  disabled={selectedDevice.requestRunning as boolean}
                />
              </GridColumn>
              <GridColumn col={2} key={'PELENGTH'}>
                <Input
                  cols={[7, 0, 0, 0]}
                  type={'number'}
                  onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
                  onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
                  className={'info-project-input'}
                  name={'PELength'}
                  value={peLengthValue}
                  label={'DOCCURVES_CABLE_LENGTHM'}
                  data={disablePeSection ? {} : selectedDevice}
                  // checked={selectedDevice.PELikeLives as boolean}
                  disabled={disablePeSection}
                />
              </GridColumn>
              <GridColumn col={2} key={'PECONDUCTOR'}>
                <Select
                  cols={[7, 8, 0]}
                  className={'info-project-input'}
                  name={'PEConductor'}
                  label={'CONDUCTOR'}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  data={selectedDevice}
                  selectList={disablePeSection ? [] : (selectedDevice.CableConductorList as Array<string>)}
                  plainObjectModel={true}
                  displayedAttribute={'TranslateString'}
                  valueAttribute={'ConductorIdString'}
                  disabled={disablePeSection}
                />
              </GridColumn>
              <GridColumn col={2} key={'PEINSULATOR'}>
                <Select
                  cols={[7, 8, 0]}
                  className={'info-project-input'}
                  name={'PEInsulator'}
                  label={'INSULATOR'}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  data={selectedDevice}
                  selectList={disablePeSection ? [] : (selectedDevice.CableInsulatorList as Array<string>)}
                  plainObjectModel={true}
                  displayedAttribute={'TranslateString'}
                  valueAttribute={'InsulatorIdString'}
                  disabled={disablePeSection}
                />
              </GridColumn>
              <GridColumn col={2} key={'PETYPE'}>
                <Select
                  cols={[7, 8, 0]}
                  className={'info-project-input'}
                  name={'PEType'}
                  label={'CABLE_TYPE'}
                  onChange={(e) => dispatch(onFocusOutHandler(e))}
                  data={selectedDevice}
                  selectList={disablePeSection ? [] : (selectedDevice.CableTypeList as Array<string>)}
                  plainObjectModel={true}
                  displayedAttribute={'TranslateString'}
                  valueAttribute={'TypeIdString'}
                  disabled={disablePeSection}
                />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const installationMethodBody = () => {
    return (
      <>
        <GridRow key={'INSTALLATIONS'}>
          <GridColumn col={4} key={'INSTALLATION ITEMS'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationItem'}
              label={'DOCWEB_STRING_INSTALLATION'}
              type={'string'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationNumberList as Array<string>}
              valueList={selectedDevice.InstallationNumberList as Array<string>}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={4} key={'INSTALLATIONMETHOD'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationMethod'}
              label={'STRING_REPMETHOD'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationMethodList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'MethodIdString'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={4} key={'INSTALLATIONPLACE'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationPlace'}
              label={'DOCCURVES_CABLE_INSTALLATIONPLACE'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationPlaceList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'PlaceIdString'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={4} key={'INSTALLATIONDETAILS'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationDetails'}
              label={'DOCCURVES_CABLE_INSTALLATIONDETAILS'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationDetailsList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'DetailsIdString'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const installationDetailsBody = (): React.ReactElement => {
    return (
      <>
        <GridRow key={'INSTALLATION D'}>
          <GridColumn col={4} key={'INSTALLATIONSCHEME'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationScheme'}
              type={'string'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationSchemeList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'SchemeIdString'}
              disabled={selectedDevice.requestRunning as boolean}
              label={'DOCCURVES_CABLE_INSTALLATIONSCHEME'}
            />
          </GridColumn>
          <GridColumn col={4} key={'INSTALLATIONLAYOUT'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationLayout'}
              type={'string'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationLayoutList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'LayoutIdString'}
              disabled={selectedDevice.requestRunning as boolean}
              label={'DOCCURVES_CABLE_INSTALLATIONLAYOUT'}
            />
          </GridColumn>
          <GridColumn col={4} key={'INSTALLATIONENV'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'InstallationEnvironment'}
              label={'ENV'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationEnvironmentList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'EnvironmentIdString'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={4} key={'CONDUITROW'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'ConduitRow'}
              label={'DOCCURVES_CABLE_CONDUITROW'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.InstallationConduitList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'RowIdString'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={4} key={'INSTALLATIONCOLUMN'}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'ConduitColumn'}
              label={'DOCCURVES_CABLE_CONDUITCOLUMN'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              data={selectedDevice}
              selectList={selectedDevice.ConduitFactorList as Array<string>}
              plainObjectModel={true}
              displayedAttribute={'TranslateString'}
              valueAttribute={'columnIdString'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const circuitProperties = (): React.ReactElement => {
    const voltageSelectList =
      (selectedDevice.Uref as number) > Math.max(...projectCommonData.voltageLow)
        ? projectCommonData.voltageMedium
        : projectCommonData.voltageLow;
    return (
      <GridRow key={'CURCUITPARAMS'}>
        <GridColumn col={2} key={'CableUref'}>
          <EditableSelect
            name="Uref"
            cols={[7, 8, 0]}
            type="number"
            onChange={(e) => dispatch(inputUpdateHandler(e, selectedDeviceId))}
            onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
            data={selectedDevice}
            selectList={voltageSelectList}
            label={'STRINGS_BROWSEGEN_LISTHEAD_2'}
            // dimension={'DOCWEB_DIMENSIONS_V'}
          />
        </GridColumn>
        <GridColumn col={2} key={'CableLineType'}>
          <Select
            name="LineType"
            cols={[7, 8, 0]}
            onChange={(e) => dispatch(onFocusOutHandler(e))}
            // onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
            data={selectedDevice}
            selectList={selectedDevice.lineTypeList as Array<string>}
            label={'DOCWEB_GENERIC_LINETYPE'}
          />
        </GridColumn>
        <GridColumn col={isMobileOnly ? 3 : 2} key={'CableEarthingSystem'}>
          <Select
            name="EarthingSystem"
            cols={[7, 8, 0]}
            onChange={(e) => dispatch(onFocusOutHandler(e))}
            // onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
            data={selectedDevice}
            selectList={selectedDevice.distributionSystemList as Array<string>}
            label={'DOCWEB_GENERIC_EARTHINGSYSTEM'}
          />
        </GridColumn>
      </GridRow>
    );
  };
  return (
    <Grid>
      <GridRow>
        <GridColumn col={4}>
          <p className={'title'}>{t('STRINGS_EDIT_USUAL_DEFAULTS')}</p>
          {circuitProperties()}
          {descriptionSectionBody()}
        </GridColumn>
        <GridColumn col={4}>
          <p className={'title'}>{t('STRINGS_IDC_PROPERTIES_TXT')}</p>
          {generalProperties()}
        </GridColumn>
      </GridRow>
      <GridRow></GridRow>

      <GridRow>
        <GridColumn col={4}>
          <p className={'title'}>{t('DOCCURVES_CABLE_HEADER_INSTALLATIONMETHOD')}</p>
          {installationMethodBody()}
        </GridColumn>
        <GridColumn col={4}>
          <p className={'title'}>{t('DOCCURVES_CABLE_HEADER_INSTALLATIONDETAILS')}</p>
          {installationDetailsBody()}
        </GridColumn>
      </GridRow>
      <Results
        selectedDevice={
          selectedDevice as {
            CalculationResultsList: Record<string, number[]>;
            CableInstallationReferenceList: Record<string, unknown>[];
          }
        }
        selectedDeviceId={selectedDeviceId}
      />
      <GridRow>
        <GridColumn col={8}>
          <p className={'title'}>{t('DOCCURVES_CABLE_HEADER_PESECTION')}</p>
          {peSection()}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default ConfigurationTab;
