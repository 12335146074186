import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'LOAD',
  objectType: EDeviceObjectType.Load,
  deviceType: EDeviceType.LOAD,
  Locked: false,
  ports: [],
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNC,
  Uref: 400,
  frequency: 50,
  Uf: 1,
  VoltageDrop: 0,
  VoltageDropMaX: 5,
  LoadCurrent: 40.094,
  CosPhiNominal: 0.9,
  Pn: 25000.0,
  NominalPower: 27777.777777777777,
};
