import React, { FunctionComponent } from 'react';
import { ISelect, ISelectGrid } from 'types/components/selectContainer';
import Grid from './Grid';
import Select from './Select';

const DOCwebSelect: FunctionComponent<ISelect & ISelectGrid> = (props): React.ReactElement => {
  return (
    <Grid {...props}>
      <Select {...props} />
    </Grid>
  );
};
export default DOCwebSelect;
