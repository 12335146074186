/* eslint-disable @typescript-eslint/unbound-method */
import GetCircuitBreaker from 'api/circuitBreaker';
import ContactorAPI from 'api/contactor';
import DisconnectorAPI from 'api/disconnector';
import FuseAPI from 'api/fuse';
import ResidualCurrentCircuitBreakerAPI from 'api/residualCurrentCircuitBreaker';
import { EDeviceType } from 'types/devices/enums.d';

const CB_FILTER_KEYS = [
  'RcdTypeFilter',
  'RcdVersionFilter',
  'IdnFilter',
  'VersionFilter',
  'PolesFilter',
  'FamilyFilter',
  'ReleaseFilter',
];
const CB_FILTER_LISTS_KEYS = [
  'rcClassFilterList',
  'rcVersionFilterList',
  'idnFilterList',
  'versionFilterList',
  'polesFilterList',
  'familyFilterList',
  'releaseFilterList',
];

const MMS_FILTER_KEYS = ['PolesFilter', 'FamilyFilter'];
const MMS_FILTER_LISTS_KEYS = ['polesFilterList', 'familyFilterList'];

const OLR_FILTER_KEYS = ['PolesFilter', 'FamilyFilter', 'ReleaseFilter'];
const OLR_FILTER_LISTS_KEYS = ['polesFilterList', 'familyFilterList', 'releaseFilterList'];

const RCCB_FILTER_KEYS = ['PolesFilter', 'FamilyFilter', 'RcdTypeFilter', 'RcdVersionFilter', 'IdnFilter'];
const RCCB_FILTER_LISTS_KEYS = [
  'polesFilterList',
  'familyFilterList',
  'rcClassFilterList',
  'rcVersionFilterList',
  'idnFilterList',
];

const CONTACTOR_FILTER_KEYS = ['FamilyFilter', 'PolesFilter', 'ServiceFilter'];
const CONTACTOR_FILTER_LISTS_KEYS = ['familyFilterList', 'polesFilterList', 'ServiceFilterList'];

const DISCONNECTOR_FILTER_KEYS = ['TypeFilter', 'VersionFilter', 'PolesFilter', 'FamilyFilter'];
const DISCONNECTOR_FILTER_LISTS_KEYS = ['typeFilterList', 'versionFilterList', 'polesFilterList', 'familyFilterList'];

const FUSE_FILTER_KEYS = ['TypeFilter', 'FamilyFilter', 'FuseLinkFilter', 'FuseSizeFilter', 'PolesFilter'];
const FUSE_FILTER_LISTS_KEYS = ['typeFilterList', 'familyFilterList', 'linksFilterList', 'sizeFilterList', 'polesFilterList'];

export const getFilterKeys = (deviceType: EDeviceType): { filterKeys: Array<string>; filterListKeys: Array<string> } => {
  switch (deviceType) {
    case EDeviceType.MMS:
      return { filterKeys: MMS_FILTER_KEYS, filterListKeys: MMS_FILTER_LISTS_KEYS };
    case EDeviceType.OLR:
      return { filterKeys: OLR_FILTER_KEYS, filterListKeys: OLR_FILTER_LISTS_KEYS };
    case EDeviceType.RCCB:
      return { filterKeys: RCCB_FILTER_KEYS, filterListKeys: RCCB_FILTER_LISTS_KEYS };
    case EDeviceType.CONTACTOR:
      return { filterKeys: CONTACTOR_FILTER_KEYS, filterListKeys: CONTACTOR_FILTER_LISTS_KEYS };
    case EDeviceType.DISCONNECTORLV:
      return { filterKeys: DISCONNECTOR_FILTER_KEYS, filterListKeys: DISCONNECTOR_FILTER_LISTS_KEYS };
    case EDeviceType.FUSEBASE:
      return { filterKeys: FUSE_FILTER_KEYS, filterListKeys: FUSE_FILTER_LISTS_KEYS };
    default:
      return { filterKeys: CB_FILTER_KEYS, filterListKeys: CB_FILTER_LISTS_KEYS };
  }
};

export const DEVICE_TYPE_LIST_PRE_FILTERS = {
  [EDeviceType.CB_TM]: GetCircuitBreaker.getCircuitBreakerFilterGlobals,
  [EDeviceType.FUSEBASE]: FuseAPI.getFuseFilterGlobals,
  [EDeviceType.MMS]: GetCircuitBreaker.getMMSFilterGlobals,
  [EDeviceType.CONTACTOR]: ContactorAPI.getContactorFiltersGlobal,
  [EDeviceType.DISCONNECTORLV]: DisconnectorAPI.getDisconnectorFilterGlobals,
  [EDeviceType.OLR]: GetCircuitBreaker.getOLRFilterGlobals,
  [EDeviceType.RCCB]: ResidualCurrentCircuitBreakerAPI.getResidualCurrentFilterGlobals,
};
