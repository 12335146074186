import { ThunkAction } from 'types/known-actions';
import _ from 'lodash';
import { ICheckBoxOnChange } from 'types/components/checkbox';
import { curvesActions, getDeviceCurves } from 'curves/actions';
import { setSelectedDevice } from 'project/actions';

export const checkCurves =
  (e: ICheckBoxOnChange, objectId: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const curves = _.cloneDeep(state.curves);
    const objectIdIndex = curves.checkedCurves.findIndex((checkedObjectId) => {
      return checkedObjectId === objectId;
    });
    if (objectIdIndex === -1) {
      curves.checkedCurves.push(objectId);
    } else {
      curves.checkedCurves.splice(objectIdIndex, 1);
    }
    dispatch(curvesActions.setCheckedCurves({ checkedCurves: curves.checkedCurves }));
    if (!state.project.selectedDeviceId && !curves.customCurves.devices[objectId]) {
      const deviceId = Object.keys(state.devices).find((devId) => state.devices[devId].ObjectId === objectId);
      if (deviceId) {
        dispatch(setSelectedDevice(deviceId));
      }
    }
    if (!curves.customCurves.devices[objectId]) {
      dispatch(getDeviceCurves());
    }
  };

export const removeCheckedCurve =
  (objectId: string, redrawCurves?: boolean): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const curves = _.cloneDeep(state.curves);
    const indexToRemove = curves.checkedCurves.findIndex((checkedObjectId) => {
      return checkedObjectId === objectId;
    });
    if (indexToRemove !== -1 || curves.customCurves.devices[objectId]) {
      curves.checkedCurves.splice(indexToRemove, 1);
      dispatch(curvesActions.setCheckedCurves({ checkedCurves: curves.checkedCurves }));
      if (curves.customCurves.devices[objectId]) {
        curves.customCurves.devices[objectId].checked = false;
        dispatch(curvesActions.setCustomCurves({ customCurves: curves.customCurves }));
      } else if (redrawCurves && state.project.selectedDeviceId) {
        dispatch(getDeviceCurves());
      }
    }
  };
