/* eslint-disable no-param-reassign */
import { IBreakerFilters } from 'types/deviceFilters';
import { Device } from 'types/devices/device';
import { ELvLineType } from 'types/devices/enums.d';
import { ApplicationState } from 'types/store';
import _ from 'lodash';

export const getIdnList = (filter: IBreakerFilters): Array<number> => {
  const idnList = new Array<number>();
  const fk = Object.keys(filter);
  for (let i = 0; i < fk.length; i += 1) {
    fk[i].split(';').forEach((val) => {
      const numVal = parseFloat(val);
      if (idnList.indexOf(numVal) === -1) {
        idnList.push(numVal);
      }
    });
  }
  return idnList;
};

export const mergeFilter = (filter: IBreakerFilters): IBreakerFilters => {
  return _.merge({}, ...Object.keys(filter).map((k) => filter[k])) as IBreakerFilters;
};

export const idnNotPresentInList = (array: unknown, value: unknown): boolean =>
  (array as Array<number>).indexOf(parseFloat(value as string)) === -1;

export const managePreferredPoles = (
  device: Device<Record<string, unknown>>,
  state: ApplicationState,
  newDeviceFilters: Record<string, Array<string> | string | number>
): void => {
  const preferredPoles =
    // eslint-disable-next-line no-nested-ternary
    device.LineType === ELvLineType.LLLN
      ? state.variables.CbDefaultPolesForLLLN
      : device.LineType === ELvLineType.L1N || device.LineType === ELvLineType.LN
      ? state.variables.CbDefaultPolesForLN
      : undefined;
  if (preferredPoles && (newDeviceFilters.polesFilterList as Array<string>).some((p) => p === preferredPoles)) {
    newDeviceFilters.PolesFilter = preferredPoles;
  }
};

export const manageAllPossibilities = (
  newDeviceFilters: Record<string, Array<string> | string | number>,
  filterKeys: Array<string>,
  filterListKeys: Array<string>,
  index: number
): void => {
  let allPossibilities: string | number = 'All possibilities';
  if (filterKeys[index] === 'IdnFilter') {
    if (newDeviceFilters[filterKeys[index]] === 'All possibilities') {
      newDeviceFilters[filterKeys[index]] = -1;
    }
    allPossibilities = -1;
  }
  newDeviceFilters[filterListKeys[index]] = [
    allPossibilities as string,
    ...(newDeviceFilters[filterListKeys[index]] as Array<string>),
  ];
};
