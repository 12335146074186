import { ICoordinationResponse, IScheme } from 'types/compute';
import ApiCalls from './ApiCalls';

export default class CoordinationAPI {
  static async curvesCoords(requestBody: IScheme) {
    // TODO: check for proper type here
    const { data } = await ApiCalls.post<ICoordinationResponse, IScheme>(`CurvesCoords/`, requestBody);
    return data;
  }
}
