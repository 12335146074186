import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'TYPICAL_BREAKER_LOAD',
  objectType: EDeviceObjectType.TypicalBreakerLoad,
  deviceType: EDeviceType.TYPICAL_FEEDER,
  ports: [],
  LinkName: '0',
  UserDescription1: '',

  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
};
