import { Button } from '@abb/abb-common-ux-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'store';
import ReportComponent from './reportComponent';

const Footer = (): React.ReactElement => {
  const project = useSelector((state) => state.project);
  const { t } = useTranslation();

  const [print, setPrint] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    if (print && $('#PrintButton')[0]) {
      $('#PrintButton').on('click', (e) => {
        e.preventDefault();
      });
      $('#PrintButton')[0].click();
    }
  }, [print]);

  return (
    <div>
      <Button
        id={'DownloadDocumentationButton'}
        text={t('DOCWEB_FOOTER_BUTTON_DOWNLOAD_DOCUMENTATION')}
        type={'primary-blue'}
        onClick={() => setPrint(true)}
        isLoading={project.printing}
        disabled={print}
      ></Button>
      {print ? (
        <>
          <ReactToPrint
            trigger={() => (
              <div style={{ display: 'none' }}>
                <a id="PrintButton" href="#">
                  Print this out!
                </a>
              </div>
            )}
            content={() => componentRef.current}
            copyStyles={true}
            pageStyle={'@media print {html, body {border: 1px solid white;height: 99%;}}'}
            // onBeforeGetContent={() => $('#PrintButton')[0].click()}
            removeAfterPrint={true}
            onAfterPrint={() => setPrint(false)}
          />
          <div style={{ display: 'none' }}>
            <ReportComponent ref={componentRef} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Footer;
