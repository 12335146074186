import { createAction } from 'redux-actions';
import { DEVICES, UNDO } from 'types/constants.d';
import { IDevicesActions } from 'types/devices';

export const DeviceActions = {
  addDevice: createAction<IDevicesActions['addDevice']['payload']>(DEVICES.ADD_ELEMENTS),
  updateElementErrors: createAction<IDevicesActions['updateElementErrors']['payload']>(DEVICES.UPDATE_ELEMENT_ERRORS),
  addPreviousState: createAction<IDevicesActions['addPreviousState']['payload']>(DEVICES.ADD_PREVIOUS_STATE),
  removeUndo: createAction<IDevicesActions['removeUndo']['payload']>(UNDO.REMOVE_UNDO),
  updateElementsPosition: createAction<IDevicesActions['updateElementsPosition']['payload']>(DEVICES.UPDATE_ELEMENTS_POSITIONS),
  updateLinks: createAction<IDevicesActions['updateLinks']['payload']>(DEVICES.UPDATE_LINKS),
  removeCells: createAction<IDevicesActions['removeCells']['payload']>(DEVICES.REMOVE_CELLS),
  updateElementParams: createAction<IDevicesActions['updateElementParams']['payload']>(DEVICES.UPDATE_ELEMENT_PARAMS),

  deleteElementParams: createAction<IDevicesActions['deleteElementParams']['payload']>(DEVICES.DELETE_ELEMENT_PARAMS),
  addRawElement: createAction<IDevicesActions['addRawElement']['payload']>(DEVICES.ADD_RAW_ELEMENT),
  updateAllElementsParams: createAction<IDevicesActions['updateAllElementsParams']['payload']>(
    DEVICES.UPDATE_ALL_ELEMENTS_PARAMS
  ),
};
