import { IBackendResponse, IScheme } from 'types/compute';
import { Device } from 'types/devices/device';
import ApiCalls from './ApiCalls';

export default class FuseAPI {
  static async filterFuse(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`FuseFilter`, requestBody);
    return data;
  }

  static async store(requestBody: IScheme): Promise<IBackendResponse> {
    const { data } = await ApiCalls.post<IBackendResponse, IScheme>(`FuseStore`, requestBody);
    return data;
  }

  static async wishes(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`FuseMiss`, requestBody);
    return data;
  }

  static async getFuseFilterGlobals(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`FuseFilterGlobals`, requestBody);
    return data;
  }
}
