import React, { FunctionComponent } from 'react';
import { IGrid } from 'types/components/grid';

const Grid: FunctionComponent<IGrid> = ({ children, className, style, onBlur, onFocus, onClick, id }): React.ReactElement => {
  return (
    <div
      id={id}
      onBlur={onBlur}
      style={style}
      onFocus={onFocus}
      onClick={onClick}
      className={`DOCweb__CommonUX_Grid ${className || ''}`}
      role="presentation"
    >
      {children}
    </div>
  );
};

export default Grid;
