import CurveTypes from 'curves/curveType';

/**
 * Values for the origin of the diagram
 * e.g. 0 for current (X axis) is 1 kA (1000)
 * e.g. 0 for time (Y axis) is 10 s (10)
 */
export default {
  [CurveTypes.TFFF]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 0.001,
    maxY: 10000.0,
    originY: 1,
  },
  [CurveTypes.TFF]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 0.001,
    maxY: 10000.0,
    originY: 1,
  },
  [CurveTypes.TFN]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 0.001,
    maxY: 10000.0,
    originY: 1,
  },
  [CurveTypes.TFPE]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 0.001,
    maxY: 10000.0,
    originY: 1,
  },
  [CurveTypes.EFFF]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 10,
    maxY: 10000000000.0,
    originY: 1000000,
  },
  [CurveTypes.EFN]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 10,
    maxY: 10000000000.0,
    originY: 1000000,
  },
  [CurveTypes.EFPE]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 10,
    maxY: 10000000000.0,
    originY: 1000000,
  },
  [CurveTypes.CL]: {
    minX: 0.01,
    maxX: 1000000.0,
    originX: 1000,
    minY: 0.01,
    maxY: 1000000.0,
    originY: 1000,
  },
};

/**
 * just to be tuned the "max" coordinates to be shown by diagram are (left/right/bottom/top):
 * -5/3/-3/4 for TFFF, TFF, TFN, TFPE and
 * -5/3/-5/4 for the rest
 */
