import React from 'react';
import { ICurvesContainer } from 'types/curves';
import Curves from './Curves';

const getContainerWidth = (container: HTMLDivElement) => {
  let width = container.clientWidth;
  // There is no point to show curves smaller than 100px width/height
  if (width > 100) {
    width = Math.trunc(width);
    // Odd size can cause blurry picture due to subpixel rendering
    if (width % 2 !== 0) width -= 1;
    return width;
  }
  return false;
};

export default class CurvesContainer extends React.Component<ICurvesContainer> {
  container: HTMLDivElement;

  width: number;

  expanded: boolean;

  height: number;

  curves: Curves;

  componentDidMount() {
    window.onresize = () => this.forceUpdate();
  }

  shouldComponentUpdate(nextProps: ICurvesContainer): boolean {
    this.tryDrawKonva(nextProps);
    return !this.container;
  }

  /**
   * If the container is visible tries to init it
   * If the size changed then tries to resize the canvas
   * If the points or currents changed then re-renders everything
   * @param {*} props The props of the component to be used
   */
  tryDrawKonva(props: ICurvesContainer): void {
    if (this.container) {
      const width = getContainerWidth(this.container);
      if (width !== false) {
        let height = $('.ABB_CommonUX_AppLeftPane__leftPaneContent').height() ?? 125;
        height = height > width * 0.7 ? width * 0.7 : height;
        let resized = false;
        if (width !== this.width) {
          // this.width = width * 0.98;
          // this.height = width * 0.7;
          this.width = width;
          this.height = height;
          if (!this.curves) {
            this.curves = new Curves(this.container, this.width, this.height);
          } else {
            this.curves.onResize(this.width, this.height);
            resized = true;
          }
        }
        // re-draw the points if required
        if (
          resized ||
          this.curves.curves !== props.curves ||
          this.curves.selectedDeviceId !== props.selectedDeviceId ||
          this.curves.devices !== props.devices ||
          this.curves.userCurves !== props.userDevice
        ) {
          this.curves.curves = props.curves;
          this.curves.selectedDeviceId = props.selectedDeviceId;
          this.curves.devices = props.devices;
          this.curves.userCurves = props.userDevice;
          this.curves.drawCurves();
        }

        if ((window.miniCurves && window.miniCurves.diagramPrint) || this.props.initialDiagram) {
          const diagram = window.miniCurves.diagramPrint || this.props.initialDiagram;
          this.props.setSvgContent(diagram as string);
          window.miniCurves.diagramPrint = false;
        }
      }
    }
  }

  refCurves(container: HTMLDivElement | null): void {
    if (!container) {
      return;
    }
    this.container = container;
  }

  refResizeHelper(container: HTMLDivElement | null): void {
    // console.log('refResizeHelper:', container);
    if (!container) {
      return;
    }
    /**
     * Re-render when the container resized
     * This is possible thanks to https://github.com/legomushroom/resize
     */
    container.addEventListener('onresize', () => {
      this.tryDrawKonva(this.props);
    });
    setTimeout(() => this.tryDrawKonva(this.props));
  }

  render(): React.ReactElement {
    return (
      <div>
        {/**
         * This div should not be a child of curves div
         * because the lib that detects when an element is resized
         * ads some child elements to the parent div, but konva
         * overwrites them when it is initialized
         */}
        <div ref={(ref) => this.refResizeHelper(ref)} />
        <div ref={(ref) => this.refCurves(ref)} className="curves" />
      </div>
    );
  }
}
