import CoordinationAPI from 'api/coordination';
import buildNetwork from 'project/buildNetwork';
import { updateElementParams } from 'devices/actions';
import { ThunkAction } from 'types/known-actions';
import { TDeviceEvent } from 'types/devices/device';
import { DeviceActions } from 'store/devices/actions';
import { computeActions } from 'compute/actions';
import { ICoordinationResponse } from 'types/compute';
import _ from 'lodash';

// const notKiloCurvesGraphs = ['PEAK', 'TFFF', 'TFN', 'TFPE']; // for y coordinate do not multipli the result by 1000 in webdoc_float_value
export const getCoordination =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDeviceId = state.project.selectedDeviceId ?? '';

    if (!selectedDeviceId || !state.devices[selectedDeviceId]) {
      return;
    }
    const selectedDevice = state.devices[selectedDeviceId];

    dispatch(computeActions.setCoordinationRequestRunning({ isRunning: true }));
    const requestBody = _.cloneDeep(buildNetwork(state));

    if (!requestBody) {
      console.warn('No request body from build network (in ui/modal/coordination/actions)');
      return;
    }
    requestBody.variables.WEBDOC_CURRENT_DEVICE = selectedDeviceId;
    requestBody.variables.WEBDOC_STRING_VALUE = selectedDevice.diagram; // sfasfa
    const currentCurve = state.curves.curve2Show?.find((curve) => curve.id === selectedDevice.diagram);

    if (event.name === 'coordinationYValue' && currentCurve) {
      // notKiloCurvesGraphs
      if (currentCurve.curveType > 3 && currentCurve.curveType < 8) {
        // TFFF contains times
        requestBody.variables.WEBDOC_FLOAT_VALUE = Number(event.value) * -1000;
      } else {
        requestBody.variables.WEBDOC_FLOAT_VALUE = Number(event.value) * -1;
      }
    } else if (event.name === 'coordinationXValue') {
      requestBody.variables.WEBDOC_FLOAT_VALUE = Number(event.value) * 1000;
    }

    CoordinationAPI.curvesCoords(requestBody)
      .then((response) => {
        if (response.stderr) {
          dispatch(computeActions.setCoordinationRequestRunning({ isRunning: false }));

          // window.docCaptureBackendError('CoordinationAPI.CurvesCoords', response.stderr);
          console.warn('Coordination API returned error, check response');
          return;
        }
        dispatch(computeActions.setCoordinationRequestRunning({ isRunning: false }));
        dispatch(processCoordsResponse(response, selectedDeviceId, event));
      })
      .catch((err) => {
        console.log(err);
        computeActions.setCoordinationRequestRunning({ isRunning: false });
      });
  };

export const onFocusOutHandler =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch) => {
    dispatch(inputUpdateHandler(event));
    // dispatch(getCoordination(event));
  };

export const inputUpdateHandler =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedDeviceId } = state.project;
    if (!selectedDeviceId) {
      return;
    }
    dispatch(updateElementParams(selectedDeviceId, { [event.name]: event.value }, true));
    dispatch(getCoordination(event));
  };

interface IProcessedCoordResponse {
  deviceSign: string;
  switchboardName: string;
  min: string | number;
  max: string | number;
}

const processCoordsResponse =
  (response: ICoordinationResponse, selectedDeviceId: string, event: TDeviceEvent): ThunkAction<void> =>
  (dispatch, getState) => {
    const { devices } = getState();
    const processedResponse: Array<IProcessedCoordResponse> = [];
    Object.keys(response.jsonCoords).forEach((deviceId) => {
      let deviceSign = '';
      let switchboardName = '';
      const device = devices[deviceId];
      if (device) {
        if (device.ObjectSign) {
          deviceSign = device.ObjectSign;
        }
        if (device.SwitchBoardName) {
          const switchboardId = device.SwitchBoardName as string;
          const switchboard = devices[switchboardId];
          if (switchboard && switchboard.ObjectSign) {
            switchboardName = switchboard.SwitchBoardName as string;
          }
        }
      }
      processedResponse.push({
        deviceSign,
        switchboardName,
        min: response.jsonCoords[deviceId].item1 >= 0 ? response.jsonCoords[deviceId].item1.toString() : '',
        max: response.jsonCoords[deviceId].item2 >= 0 ? response.jsonCoords[deviceId].item2.toString() : '',
      });
    });
    if (event.name === 'coordinationXValue') {
      // dispatch(DeviceActions.updateElementParams(selectedDeviceId, {['coordsX']: processedResponse}));
      dispatch(
        DeviceActions.updateElementParams({
          id: selectedDeviceId,
          objectParams: {
            coordsX: processedResponse,
          },
        })
      );
    }

    if (event.name === 'coordinationYValue') {
      dispatch(
        DeviceActions.updateElementParams({
          id: selectedDeviceId,
          objectParams: {
            coordsY: processedResponse,
          },
        })
      );
    }
  };

export const onModalClose =
  (selectedDeviceId: string, coordStore: string, coordSelectionValue: string): ThunkAction<void> =>
  (dispatch) => {
    // dispatch(DeviceActions.deleteElementParams(selectedDeviceId, [coordStore, coordSelectionValue]));
    dispatch(
      DeviceActions.deleteElementParams({
        id: selectedDeviceId,
        objectParams: [coordStore, coordSelectionValue],
      })
    );
  };
