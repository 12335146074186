import { EDeviceObjectType, EDeviceType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'FEEDER',
  // type: EDeviceType.CB_FEEDER,
  objectType: EDeviceObjectType.FEEDER,
  Locked: false,
  deviceType: EDeviceType.CB_FEEDER,
  ports: [],
  protection: 'TMP',
  cable: 'CABLE_LLLNPE',
  load: 'LOAD',
  initialized: false,
};
