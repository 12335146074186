import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'RCCB',
  objectType: EDeviceObjectType.RcCb,
  deviceType: EDeviceType.RCCB,
  // General info
  Name: '',
  Description: '',
  Standard: 'IEC 60947-2',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400.0,
  Locked: false,
  PreFiltersChanged: true,
  // Filters //////////////

  // Family
  FamilyFilter: 'All possibilities',
  familyFilterList: [],

  familyList: [],
  familyOutput: 'All possibilities',

  // Class
  RcdTypeFilter: 'All possibilities',
  rcClassFilterList: [],

  rcClassOutput: 'All possibilities',
  rcClassOutputList: [],

  // Version
  RcdVersionFilter: 'All possibilities',
  rcVersionFilterList: [],

  rcVersionOutput: 'All possibilities',
  rcVersionOutputList: [],

  // Idn
  IdnFilter: -1,
  idnFilterList: [],

  idnOutput: -1,
  idnOutputList: [],

  // PolesNumber
  PolesFilter: '4P',
  polesFilterList: [],

  polesOutput: 'All possibilities',
  polesOutputList: [],

  // Ir
  irOutput: -1,
  irOutputList: [],

  rcdOutput: -1,
  DevicesList: [],
  DeviceId: 0,

  ProductDescription: '',
  ProductIdList: null,
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,
  deviceDescriptionDisplay: [],
  deviceIcuDisplay: [],
  deviceIcsDisplay: [],
  deviceIcnDisplay: [],
  deviceRatedCurrentDisplay: [],
  deviceIcw1s: [],
  deviceStartIndex: 0,
  deviceEndIndex: 0,

  initialized: false,
  requestRunning: false,
  IsOffInScenario: {
    name: [],
  },
  Relations: [],
};
