/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { PAGES, PROJECT } from 'types/constants.d';
import { EDeviceType } from 'types/devices/enums.d';
import { elementObjectIdCounter, elementObjectIdPrefix, elementObjectSignPrefix } from 'types/devices/constants';
import { ProjectKnownActions } from 'types/known-actions.d';
import _ from 'lodash';
import { State, EPaperAlign, EPaperSize, EPaperList, MacroConversionData } from 'types/project/store.d';
import { EProjectType } from 'types/project/enums.d';
import { IMessage } from 'types/project/messages.d';
import reports from '../reports';

export const initialState: State = {
  id: null,
  name: 'New curves Project',
  Customer: '',
  description: '',
  CurrentStamp: EPaperList.A3,
  appVersion: '-',
  autoCompute: false,
  selectedDeviceId: '',
  dirty: false,
  ProjectIsComputed: false,
  propagateDirty: false,
  propagateId: '',
  sessionId: '',
  userCountry: '',
  type: EProjectType.LV,
  Designer: '',

  // messages
  computeErrors: {},
  propagateErrors: {},
  computeWarnings: {},
  propagateWarnings: {},
  clientErrors: [],
  clientWarnings: [],
  curvesErrors: [],
  curvesWarnings: [],
  curvesNotifications: [],
  projectErrors: {},
  diagramsForPrint: {},

  // Pages
  pageOrder: ['block', 'e6965058-1be8-4db0-aee3-bbbbc8aef654'],
  openedPages: ['e6965058-1be8-4db0-aee3-bbbbc8aef654'],
  selectedPage: 'e6965058-1be8-4db0-aee3-bbbbc8aef654',
  pageCounter: 1,

  // Reports
  reports,
  printReportFormat: 'pdf',
  printing: false,
  paperAlign: EPaperAlign.Horizontal,
  paperSize: EPaperSize.A3,
  paperAlignList: [EPaperAlign.Horizontal, EPaperAlign.Vertical],
  paperSizeList: [EPaperSize.A3, EPaperSize.A4],
  paperSettingSizeList: [EPaperSize.A0, EPaperSize.A1, EPaperSize.A2, EPaperSize.A3, EPaperSize.A4],

  multipleSelection: '',
  partnerHub: {
    toolUrl: '',
    bomId: null,
    hookUrlInsertDevices: '',
    hookUrlUploadDocuments: '',
    projectId: '',
    singleLineDiagramId: '',
    user: '',
    userName: '',
    projectName: '',
    customerName: '',
    singleLineDiagramName: '',
    hookUrlUploadSettings: '',
    projectSettings: '',
  },
  energyEfficiencyData: [],
  macroConversionData: {} as MacroConversionData,
  SwitchBoardList: [],
  SwitchBoard: 'All possibilities',
  // TODO: check what's inside reports
  // reports,
  neoGearWizard: {
    lists: {},
    values: {
      IncomerMin: '160',
      IncomerMax: '1000',
      OutcomerMin: '160',
      OutcomerMax: '1000',
      MotorMin: '22',
      MotorMax: '110',
      SelectedProduct: '',
    },
    changed: [],
    notAvailableProducts: [],
  },
  PlantDate: '',
  PlantDrafter: '',
  PlantNote1: '',
  PlantNote2: '',
  PlantNote3: '',
  PlantFile: '',
  PlantDesigner: '',
  PlantBoard: '',
  PlantProject: '',
  PlantRev1: '',
  PlantRev2: '',
  PlantRev3: '',
  PlantSerial: '',
  PlantApproval: '',
  PlantDrawingNumber: '',
  PlantMainCode: '',
  busbarDemandFactor: 1, // Import feeders...
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: ProjectKnownActions): State =>
  produce(state, (draft) => {
    switch (action.type) {
      case PROJECT.SET_AUTO_COMPUTE:
        draft.autoCompute = action.payload.autoCompute;
        break;
      case PROJECT.SET_SELECTED_DEVICE:
        draft.selectedDeviceId = action.payload.id;
        break;
      case PROJECT.SET_PROJECT_DATA:
        draft.id = action.payload.id;
        draft.name = action.payload.name;
        draft.sessionId = action.payload.sessionId;
        break;
      case PROJECT.SET_PROJECT_NAME:
        draft.name = action.payload.name;
        break;
      case PROJECT.SET_MACROCONVERSIONDATA:
        draft.macroConversionData = action.payload.macroConversionData;
        break;
      case PROJECT.SET_NEOGEARWIZARD:
        draft.neoGearWizard = draft.neoGearWizard ? { ...draft.neoGearWizard, ...action.payload } : action.payload;
        break;
      case PROJECT.SET_PROJECT_ERROR:
        draft.projectErrors[action.payload.tabName] = {
          [action.payload.name]: action.payload.error,
        };
        break;
      case PROJECT.SET_APP_VERSION:
        draft.appVersion = action.payload.version;
        break;
      case PROJECT.SET_PROJECT_DIRTY:
        draft.dirty = action.payload.dirty;
        draft.propagateDirty = action.payload.dirty;
        draft.ProjectIsComputed = action.payload.ProjectIsComputed ?? false;
        draft.propagateId = action.payload.propagateId ?? '';
        break;
      case PROJECT.SET_PROJECT_INFO:
        return { ...state, ...action.payload.info };

      case PROJECT.SET_PROJECT_PROPAGATE_DIRTY:
        draft.propagateDirty = action.payload.propagateDirty;
        draft.propagateId = action.payload.propagateId;
        break;

      case PROJECT.SET_PROJECT_MESSAGES:
        return {
          ...state,
          [action.payload.category]: action.payload.message,
        };
      case PROJECT.LOAD_PROJECT: {
        // recompute the id counters
        // TODO: Check this code. Seems to be meaningless
        Object.keys(elementObjectIdCounter).forEach((key) => {
          elementObjectIdCounter[key as EDeviceType] = 1;
        });
        Object.keys(action.payload.projectState.devices).forEach((id) => {
          const device = action.payload.projectState.devices[id];
          const deviceType = device.deviceType === EDeviceType.TEXTLINK ? EDeviceType.LINK : device.deviceType;
          const currentValue = parseInt(elementObjectIdCounter[deviceType].toString(), 10);
          if (currentValue) {
            let deviceValue: number;
            if (device.ObjectId) {
              deviceValue = parseInt(device.ObjectId.replace(/[^0-9]/g, ''), 10) + 1;
            } else {
              deviceValue = currentValue + 1;
            }
            const samePrefix = Object.keys(elementObjectSignPrefix).filter((t) => {
              const signPrefix = elementObjectSignPrefix[t as EDeviceType];
              const objectIdPrefix = elementObjectIdPrefix[t as EDeviceType];
              return (
                (signPrefix && signPrefix === elementObjectSignPrefix[device.deviceType]) ||
                (objectIdPrefix && objectIdPrefix === elementObjectIdPrefix[device.deviceType])
              );
            });
            if (deviceValue > currentValue) {
              if (samePrefix.length > 0) {
                samePrefix.forEach((type) => {
                  elementObjectIdCounter[type as EDeviceType] = deviceValue;
                });
              } else {
                elementObjectIdCounter[deviceType] = deviceValue;
              }
            }
          }
        });
        // Remove curves messages for not existing devices
        const existingCurvesWarnings: IMessage[] = [];
        action.payload.projectState.project.curvesWarnings.forEach((curveWarning) => {
          if (curveWarning.messageId) {
            const objectIds = curveWarning.messageId.split('.');
            if (objectIds.length === 4 && objectIds[0] === 'CurveCheck') {
              const messageDevices = Object.keys(action.payload.projectState.devices).filter((id) =>
                [objectIds[1], objectIds[2]].includes(action.payload.projectState.devices[id].ObjectId || '')
              );
              if (messageDevices.length === 2) {
                existingCurvesWarnings.push(curveWarning);
              }
            }
          }
        });
        action.payload.projectState.project.curvesWarnings = existingCurvesWarnings;

        const newState = _.merge({}, initialState, action.payload.projectState.project, {});

        if (newState.pageOrder.indexOf('block') === -1) {
          newState.pageOrder = ['block', ...newState.pageOrder];
        }
        return newState;
      }
      case PROJECT.SELECT_PAGE:
        draft.selectedPage = action.payload.id;
        break;
      case PROJECT.OPEN_PAGE:
      case PROJECT.CLOSE_PAGE:
        draft.openedPages = action.payload.openedPages;
        break;
      case PROJECT.UPDATE_PAGE_COUNTER:
        draft.pageCounter = action.payload.pageCounter;
        break;
      case PAGES.ADD_PAGE:
        draft.pageOrder = [...state.pageOrder, action.payload.id];
        break;
      case PROJECT.UPDATE_PAGES_ORDER:
        draft.pageOrder = action.payload.order;
        break;
      case PROJECT.SET_REPORT_FORCED:
        draft.reports[action.payload.name].forced = action.payload.value;
        break;
      case PROJECT.SET_PARTNER_HUB_DATA:
        draft.partnerHub = { ...state.partnerHub, ...action.payload.partnerHubData };
        break;
      case PROJECT.UPDATE_PROJECT_PARAM:
        return { ...state, ...action.payload };
      case PROJECT.TRIGGER_MULTIPLE_SELECTION:
        draft.multipleSelection = action.payload.multipleSelection;
        break;
      case PROJECT.REMOVE_PROJECT_MESSAGES:
        console.log(action.payload);
        break;
      case PROJECT.UPDATE_DIAGRAM_FOR_PRINTING:
        return {
          ...state,
          diagramsForPrint: {
            ...state.diagramsForPrint,
            [action.payload.name]: {
              checked: action.payload.checked,
              svgContent: action.payload.svgContent,
            },
          },
        };
      default:
        break;
    }
  });
