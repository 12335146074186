/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import MvMacroAPI from 'api/mvMacro';
import { updateAllElementsParams, updateElementParams, setDeviceRequestRunning } from 'devices/actions';

import { getDeviceCurves, processCurvesResponse } from 'curves/actions';
import { setRequestRunning, runRequestFromQueue, dependentAPIRequestIsRunning } from 'compute/actions';
import _ from 'lodash';
import { ThunkAction } from 'types/known-actions';
import { IGetMvMacroResponse, IRequestQueue } from 'types/compute';
import { TypicalUnit } from 'types/devices/typicalUnits';
import { DevicesState } from 'types/devices';
import { EDeviceType } from 'types/devices/enums.d';
import { generateSymbols } from 'project/utils/symbols';
import { ICurvesResponse } from 'types/api/curves';

const setMacroRequestsRunning =
  (selectedDeviceId: string, status: boolean): ThunkAction<void> =>
  (dispatch) => {
    dispatch(setRequestRunning('getMvMacroRequestRunning', status));
    dispatch(setDeviceRequestRunning(selectedDeviceId));
  };

export const getMvMacro =
  (
    selectedDeviceId: string,
    customSelection?: TypicalUnit['selection'],
    changedByUser = false
  ): ThunkAction<Promise<void> | undefined> =>
  (dispatch, getState) => {
    const state = getState();
    const request: IRequestQueue = { name: 'typicalUnits', func: _.partial(getMvMacro, selectedDeviceId, customSelection) };
    if (dispatch(dependentAPIRequestIsRunning(request))) {
      return;
    }
    dispatch(setMacroRequestsRunning(selectedDeviceId, true));

    // const accessoryDeviceId = getTypicalUnitPlaceholderId(selectedDeviceId, state.devices);
    // Custom requestBody
    // const typicalUnit = state.devices[selectedDeviceId];
    const scheme: DevicesState<Record<string, unknown>> = {};
    // typicalUnit.json.embeds?.forEach((embId) => {
    //   if (state.devices[embId]) {
    //     scheme[embId] = state.devices[embId];
    //   }
    // });
    scheme[selectedDeviceId] = state.devices[selectedDeviceId];
    scheme[state.devices[selectedDeviceId].MvSwitchBoardName as string] =
      state.devices[state.devices[selectedDeviceId].MvSwitchBoardName as string];
    const symbols = generateSymbols(scheme);
    const requestBody = {
      variables: {
        ..._.cloneDeep(state.variables),
        WEBDOC_CURRENT_DEVICE: selectedDeviceId,
      },
      symbols,
      scheme,
      changed: 'Initial',
      languageId: 2,
      selection: customSelection || state.devices[selectedDeviceId].selection,
    };

    const filterCombos = (combo: Array<{ bCurrent: boolean; value: string }> | null): string | undefined => {
      if (combo && combo.length > 0) {
        const currentCombo = combo.find((item) => item.bCurrent === true);
        return currentCombo?.value || undefined;
      }
      return undefined;
    };

    // eslint-disable-next-line consistent-return
    return MvMacroAPI.getMacroFilters(requestBody)
      .then((response) => {
        const data = response as unknown as IGetMvMacroResponse;
        dispatch(
          updateAllElementsParams(
            {
              ...data.jsonOutput.docxdata,
              [selectedDeviceId]: {
                ...data.jsonOutput.docxdata[selectedDeviceId],
                ...data.jsonProps,
                mvBreakerValue: filterCombos(data.jsonProps.mvBreaker),
                mvDisconnectorValue: filterCombos(data.jsonProps.mvDisconnector),
                mvFuseValue: filterCombos(data.jsonProps.mvFuse),
                currentTransformerValue: filterCombos(data.jsonProps.currentTransformer),
                homopolarTransformerValue: filterCombos(data.jsonProps.homopolarTransformer),
                tripUnitValue: filterCombos(data.jsonProps.tripUnit),
                voltageTransformerValue: filterCombos(data.jsonProps.voltageTransformer),
                trafoEncloseValue: filterCombos(data.jsonProps.trafoEnclose),
              },
            },
            true
          )
        );
        // typicalUnitCell.setupAccessories(window.app.paper, window.app.graph, getState().devices[accessoryDeviceId]);
        if (
          getState().project.selectedDeviceId === selectedDeviceId &&
          data.curve2Show &&
          data.curves &&
          Array.isArray(data.curves) &&
          data.curves[0] != null &&
          data.jsonCurvesParams
        ) {
          dispatch(processCurvesResponse(data as unknown as ICurvesResponse));
        }
        if (changedByUser) {
          dispatch(updateElementParams(selectedDeviceId, { Locked: true }));
        }
        dispatch(getDeviceCurves());
        dispatch(setMacroRequestsRunning(selectedDeviceId, false));
        dispatch(runRequestFromQueue());
        // if (window && window.app && window.app.halo) {
        //   window.app.halo.updateLockIcon();
        // }
      })
      .catch((error) => {
        console.warn(error);
        dispatch(setMacroRequestsRunning(selectedDeviceId, false));
      });
  };

// export const inputUpdateHandler =
//   (event: TDeviceEvent, deviceType?: EDeviceType, selectedDeviceId?: string): ThunkAction<void> =>
//   (dispatch) => {
//     dispatch(Device.inputUpdateHandler(event, deviceType, selectedDeviceId));
//   };

export const inputUpdateHandler =
  (data: { name: string; value: string }): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const { selectedDeviceId } = state.project;
    if (selectedDeviceId) {
      const selection: TypicalUnit['selection'] =
        state.devices[selectedDeviceId].selection !== undefined ? [...(state.devices[selectedDeviceId].selection as [])] : [];
      selection[0] = {
        deviceAccesory: data.name.split('Value')[0],
        current: data.value,
      };
      dispatch(updateElementParams(selectedDeviceId, { [data.name]: data.value, selection }));
      dispatch(getMvMacro(selectedDeviceId, selection))
        ?.then(() => {})
        .catch((err) => console.error(err));
    }
  };

export const getTypicalUnitPlaceholderId = (selectedDeviceId: string, devices: DevicesState): string => {
  let accessoryDeviceId = '';
  if (Object.values(EDeviceType).indexOf(devices[selectedDeviceId].deviceType) !== -1) {
    accessoryDeviceId = Object.keys(devices).find(
      (deviceId) => devices[deviceId].feederId === selectedDeviceId && devices[deviceId].IsMVAccessoriesPlaceHolder === true
    ) as string;
  }
  return accessoryDeviceId;
};
