import CheckBox from 'components/generic/CheckBox';
import CheckBoxList from 'components/generic/CheckBoxList';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import Select from 'components/generic/selectContainer';
import _ from 'lodash';
import { setProjectDirty } from 'project/actions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { FamilyChangedState, IFamilyColor } from 'types/modal/projectProperties/familiesModals';
import { DisabledFamilies, Variables } from 'types/variables/store';
import { optionsBreakersICUList, polesOptionsTableLLLN, polesOptionsTableLN } from '../../../../constants';

// import { polesOptionsTableLLLN, polesOptionsTableLN, optionsBreakersICUList } from '../../../constants';
import { checkboxUpdateHandler, checkHandler, inputUpdateHandler } from './actions';

const CircuitBreakerFamiliesComponent: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const variables = _.cloneDeep(useSelector((state) => state.variables));
  const project = _.cloneDeep(useSelector((state) => state.project));
  const projectErrors = project.projectErrors['Circuit Breaker'] ? project.projectErrors['Circuit Breaker'] : {};

  const [userChangedFamily, userChangedFamilyHandler] = useState({} as unknown as FamilyChangedState);

  const setUserChangedFamilies = (family: string): void => {
    if (userChangedFamily[family] !== undefined) {
      userChangedFamilyHandler({ [family]: !userChangedFamily });
    } else {
      userChangedFamilyHandler({ [family]: true });
    }
  };

  const checkFamiliesChanged = (): void => {
    // hope families and variants don't cross with names
    const isFamilyChanged = Object.keys(userChangedFamily).some((familyName) => userChangedFamily[familyName] === true);
    if (isFamilyChanged) {
      dispatch(setProjectDirty(true));
    }
  };

  // if user checks / unchecks checkbox => setProjectDirty, but only when something is changed
  useEffect(() => {
    return checkFamiliesChanged();
  });

  const displayPolesSelection = (): React.ReactElement => {
    return (
      <Grid>
        <GridRow>
          <GridColumn col={3} verticalAlignment="middle">
            {t('DOCWEB_MODAL_BREAKERFAMILIES_LLLNCIRCUITS')}
          </GridColumn>
          <GridColumn col={2} offset={3}>
            <Select
              cols={[7, 8, 0]}
              className="select-for-device-families"
              name="CbDefaultPolesForLLLN"
              value={variables.AllProductsFamilies.CbDefaultPolesForLLLN as unknown as string}
              valueList={polesOptionsTableLLLN as unknown as string[]}
              selectList={polesOptionsTableLLLN as unknown as string[]}
              data={variables}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              type="string"
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn col={3} verticalAlignment="middle">
            {t('DOCWEB_MODAL_BREAKERFAMILIES_LNCIRCUITS')}
          </GridColumn>
          <GridColumn col={2} offset={3}>
            <Select
              cols={[7, 8, 0]}
              className="select-for-device-families"
              name="CbDefaultPolesForLN"
              value={variables.AllProductsFamilies.CbDefaultPolesForLN as unknown as string}
              valueList={polesOptionsTableLN as unknown as string[]}
              selectList={polesOptionsTableLN as unknown as string[]}
              data={variables}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              type="string"
            />
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  const displayCbIb = (): React.ReactElement => {
    return (
      <Grid>
        <GridRow className="space-after-input-for-device-families">
          <GridColumn col={3} verticalAlignment="middle">
            <CheckBox
              label="STRINGS_IDC_THRESHMODULAR_TXT"
              className=""
              name="BreakerFamilyAccordingToIB"
              value={variables.BreakerFamilyAccordingToIB}
              onChange={(e) => dispatch(checkHandler(e))}
            />
          </GridColumn>
          <GridColumn col={2} offset={3}>
            <Input
              row
              type="number"
              className="info-project-input"
              name="ChooseMcbThreshold"
              disabled={!variables.BreakerFamilyAccordingToIB}
              data={variables}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              error={projectErrors.ChooseMcbThreshold}
              displayError
              showIconInvalid
              // dimension="STRINGS_IDC_AMPER_UNIT"
            />
          </GridColumn>
        </GridRow>
        <GridRow className="space-after-input-for-device-families">
          <GridColumn col={3} verticalAlignment="middle">
            <label className="DOCWeb_aligment-for-device-families">{t('STRINGS_IDC_THRESHBOXED_TXT')}</label>
          </GridColumn>
          <GridColumn col={2} offset={3}>
            <Input
              row
              type="number"
              className="info-project-input"
              name="ChooseAcbThreshold"
              disabled={!variables.BreakerFamilyAccordingToIB}
              data={variables}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              error={projectErrors.ChooseAcbThreshold}
              displayError
              showIconInvalid
              // dimension="STRINGS_IDC_AMPER_UNIT"
            />
          </GridColumn>
        </GridRow>
        <GridRow className="space-after-input-for-device-families">
          <GridColumn col={3} verticalAlignment="middle">
            <CheckBox
              label="STRINGS_IDC_THRESHELECTRONIC_CHK"
              className=""
              name="ChooseElectronic"
              value={variables.ChooseElectronic}
              onChange={(e) => dispatch(checkHandler(e))}
            />
          </GridColumn>
          <GridColumn col={2} offset={3}>
            <Input
              row
              type="number"
              className="info-project-input"
              name="ChooseElectronicThreshold"
              disabled={!variables.ChooseElectronic}
              data={variables}
              value={variables.ChooseElectronicThreshold as unknown as string}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              error={projectErrors.ChooseElectronicThreshold}
              displayError
              showIconInvalid
              // dimension="STRINGS_IDC_AMPER_UNIT"
            />
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  const renderICU = (text: string, variable: keyof Variables.State): React.ReactElement => {
    return (
      <Grid>
        <GridRow>
          <GridColumn col={1} offset={5} verticalAlignment="middle" style={{ marginBottom: '10px' }}>
            {t(text)}
          </GridColumn>
          <GridColumn col={2} style={{ marginBottom: '10px' }}>
            <Select
              cols={[8]}
              className="info-project-input"
              name={variable as string}
              value={variables[variable] as string}
              valueList={optionsBreakersICUList}
              selectList={optionsBreakersICUList}
              data={variables}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              type="number"
            />
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  const listRender = (
    label: string,
    list: string,
    disabledList: DisabledFamilies,
    cols: number[],
    showTooltip: boolean
  ): React.ReactElement => {
    const toolTip = showTooltip ? t('DOCWEB_OBSOLETE_HELP') : '';
    const familyColor: IFamilyColor = {};
    if (variables.ObsoleteFamilies && variables.DisabledProductFamilies) {
      variables.DisabledProductFamilies.name.forEach((family) => {
        familyColor[family] = 'checkbox-list-disabled-family-color';
      });
      variables.ObsoleteFamilies.name.forEach((family) => {
        familyColor[family] = 'checkbox-list-obsolete-family-color';
      });
    }
    return (
      <CheckBoxList
        label={label}
        tableList={variables.AllProductsFamilies[list]}
        checkBoxHandler={(event) => {
          setUserChangedFamilies(event.name ?? '');
          dispatch(checkboxUpdateHandler(event, disabledList));
        }}
        valuesStorage={variables[disabledList].name}
        obsoleteList={variables.AllProductsFamilies.obsolette}
        style={familyColor}
        toolTip={toolTip}
      />
    );
  };

  return (
    <Grid>
      {displayPolesSelection()}
      {displayCbIb()}
      <GridRow>
        <GridColumn col={6} style={{ paddingRight: '5px' }}>
          {listRender('STRINGS_IDC_MODULAR_BREAKERS_TXT', 'miniatureBreaker', 'DisabledProductFamilies', [1, 11], true)}
        </GridColumn>
        <GridColumn col={2}>
          {listRender('DOCWEB_MODAL_BREAKER_VARIANTS', 'variants', 'DisabledProductVariants', [2, 10], false)}
        </GridColumn>
      </GridRow>
      {renderICU('STRINGS_IDC_ICU_ICN_LBL', 'MinBreakingCapacityForMCB')}
      {listRender('STRINGS_IDC_BOXED_BREAKERS_TXT', 'mouldedCaseBreaker', 'DisabledProductFamilies', [1, 11], true)}
      {renderICU('STRINGS_IDC_ICU_LBL', 'MinBreakingCapacityForMCCB')}
      {listRender('STRINGS_IDC_AIR_BREAKERS_TXT', 'airBreaker', 'DisabledProductFamilies', [1, 11], true)}
    </Grid>
  );
};

export default CircuitBreakerFamiliesComponent;
