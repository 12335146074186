import React from 'react';

import ModalWindow from '../ModalWindow';
import MailSupportModal from './component';

const modal: React.FunctionComponent<{ title?: string }> = ({ title }): React.ReactElement => (
  <ModalWindow
    tabs={{
      without: <MailSupportModal title={title} />,
    }}
    footer="without"
    containerClassName="support-mail-request-container"
  />
);

export default modal;
