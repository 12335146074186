import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  objectType: EDeviceObjectType.MvCircuitBreaker,
  deviceType: EDeviceType.CIRCUITBREAKERMV,
  symbol: 'CIRCUITBREAKERMV',
  Locked: false,
  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  requestRunning: false,
  PreFiltersChanged: true,
  feederId: '7edb2ef3-13e5-4399-8fb0-330c41273341',

  Frequency: '50',
  IsMVAccessoriesPlaceHolder: true,
  MvPanelBreakingTechnology: 'Vacuum',
  MvPanelIcw: 16,
  MvPanelUr: 17500,
  NominalVoltage: 15000,
  Uref: 17500,
  ObjectId: 'L1',
  PanelNumber: '1',
  TypicalUnit: 'SBC',
  TypicalUnitId: 'SBC_IN',
  TypicalUnitName: 'SBC',
  page: 1,
};
