import { EDeviceType } from 'types/devices/enums.d';
import { IPortsDefault } from 'types/drawingArea/model';
import SymbolPortDefaults from './symbolPortsDefaults';

export const getBlockDevicePorts = (deviceType: EDeviceType): IPortsDefault | undefined => {
  if (deviceType === EDeviceType.LV_SWITCHBOARD || deviceType === EDeviceType.MV_SWITCHBOARD) {
    return SWITCHBOARD as unknown as IPortsDefault;
  }
  if (deviceType === EDeviceType.BLOCK_UTILITY) {
    return UTILITY as unknown as IPortsDefault;
  }
  // TODO: Check line
  // if (deviceType === EDeviceType.LINE) {
  //   return { item: [] };
  // }
  if (deviceType === EDeviceType.BLOCK_GENERATOR) {
    return GENERATOR as unknown as IPortsDefault;
  }
  if (deviceType === EDeviceType.BLOCK_TRAFO) {
    return TRAFO as unknown as IPortsDefault;
  }
  return undefined;
};

const SWITCHBOARD = {
  ...SymbolPortDefaults,
  items: [
    {
      group: 'default',
      args: {
        x: 5,
        y: 0,
      },
      id: '0a8d2304-c59e-41d7-a3e7-63db7a0f5a8f',
    },
    {
      group: 'default',
      args: {
        x: 10,
        y: 0,
      },
      id: '2c095e7c-6450-478e-9299-2fe2965521a9',
    },
    {
      group: 'default',
      args: {
        x: 15,
        y: 0,
      },
      id: '43773d8c-6be6-4d06-a6f5-af8acf9e7484',
    },
    {
      group: 'default',
      args: {
        x: 20,
        y: 0,
      },
      id: '3aa6d19d-e252-487f-8d44-3f6e44ab2f13',
    },
    {
      group: 'default',
      args: {
        x: 25,
        y: 0,
      },
      id: '48769933-3c2f-4983-b3c5-910aec1456fd',
    },
    {
      group: 'default',
      args: {
        x: 5,
        y: 20,
      },
      id: '4ae16625-b05d-4d5a-9a7f-2cfda32ab6dd',
    },
    {
      group: 'default',
      args: {
        x: 10,
        y: 20,
      },
      id: '9da7d61c-0a5f-4d08-9d64-a845a728835c',
    },
    {
      group: 'default',
      args: {
        x: 15,
        y: 20,
      },
      id: '42ea0089-d885-447b-84a2-65d5bc9787b0',
    },
    {
      group: 'default',
      args: {
        x: 20,
        y: 20,
      },
      id: '723cad31-13b5-48d6-b9fa-77f3cf8380c3',
    },
    {
      group: 'default',
      args: {
        x: 25,
        y: 20,
      },
      id: '2fbb6e57-c587-4b02-9914-78dd283d5aac',
    },
  ],
};

const UTILITY = {
  ...SymbolPortDefaults,
  items: [
    {
      group: 'default',
      args: { x: 17.5, y: 0 },
      id: 'c4238f51-413a-4480-aa2a-93225053d018',
    },
    {
      group: 'default',
      args: { x: 35, y: 12.5 },
      id: 'c4238f51-433a-4480-aa2a-93225053d018',
    },
    {
      group: 'default',
      args: { x: 17.5, y: 25 },
      id: 'c4238f51-422a-4480-aa2a-93225053d018',
    },
    {
      group: 'default',
      args: { x: 0, y: 12.5 },
      id: 'c4238f51-411a-4480-aa2a-93225053d018',
    },
  ],
};

const GENERATOR = {
  ...SymbolPortDefaults,
  items: [
    {
      group: 'default',
      args: { x: 10, y: 0 },
      id: '5b0d8fa4-xx42-4d5b-ad82-943d55c79464',
    },
    {
      group: 'default',
      args: { x: 20, y: 12.5 },
      id: '5b0d8fa4-x32x-4d5b-ad82-943d55c79464',
    },
    {
      group: 'default',
      args: { x: 10, y: 25 },
      id: '5b0d8fa4-xx34-4d5b-ad82-943d55c79464',
    },
    {
      group: 'default',
      args: { x: 0, y: 12.5 },
      id: '5b0d8fa4-xtr4-4d5b-ad82-943d55c79464',
    },
  ],
};

const TRAFO = {
  ...SymbolPortDefaults,
  items: [
    {
      group: 'default',
      args: {
        x: 10,
        y: 0,
      },
      id: '5b0d8fa4-655a-4d5b-ad82-943d55c79464',
    },
    {
      group: 'default',
      args: {
        x: 10,
        y: 25,
      },
      id: '86e4865f-12ff-425f-8e3c-cc69ababd901',
    },
  ],
};
