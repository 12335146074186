// objectType value is used to store device subtype, which is mostly used to derive the symbol,
// but for motor feeder the proper subtypes are essencial, what follows are three conversion tables:
// Type2Symbol defines the symbol for each subtype,
// Symbol2Type defines the subtype for each symbol,
// and SubType2Type defines the "generic" type for each sybtype (which is one of subtypes in fact)
//
// so Type2Symbol should be used any time the backend returns device data, the proper symbolt to use is:
// Type2Symbol[deviceProps.objectType]
//
// the opposite conversion - Symbol2Type - is to be used when motor feeder is to be changed
// (the backend returns the set of symbols to be used for each combination of motor coordination values)
// so proper is deviceProps.objectType = Symbol2Type[symbol]
//
// the SubType2Type table is to be used when testing the "generic" type of device, so for instance:
// if (SubType2Type[deviceProps.objectType] == 'LvCircuitBreaker') {do something for breaker}
// if (SubType2Type[deviceProps.objectType] == 'LvCable') {do something for cable}

export default class TypesSymbols {
  static Type2Symbol = {
    Ats: 'ATS',
    PowerSupply: 'UTILITY',
    Genset: 'GENERATOR',
    GeneratorSmall: 'GENERATOR_SMALL',
    UtilityMVSmall: 'UTILITY_MV_SMALL',
    UtilitySmall: 'UTILITY_SMALL',
    Load: 'LOAD',
    Motor: 'MOTOR',
    Capacitor: 'CAPACITORBANKS',
    MvCable: 'WIREMV',
    LvCable: 'WIRELV',
    Transformer: 'TRAFO2',
    LvCircuitBreaker: 'CB_TM',
    MvCircuitBreaker: 'CIRCUITBREAKERMV',
    LvDisconnector: 'DISCONNECTORLV',
    MvDisconnector: 'MVDISC',
    LvContactor: 'CONTACTOR',
    LvOverLoadRelay: 'OLR',
    LvFuseGear: 'FUSELV',
    MvFuseGear: 'FUSEMV',
    Impedance: 'IMPEDANCE',
    Busbar: 'BUSBAR',
    LinkIn: 'ARRIVAL',
    LinkOut: 'DEPARTURE',
    LightsLoad: 'LIGHTS',
    RcCb: 'RCCB',
    LvCbLsi: 'CB_TM',
    LvCbLsiRc: 'CB_TMRC',
    LvCbI: 'CB_MO',
    LvCbIRc: 'CB_MORC',
    LvCbLsiW: 'CB_TM_W',
    LvCbLsiRcW: 'CB_TMRC_W',
    LvCbIW: 'CB_MO_W',
    LvCbIRcW: 'CB_MORC_W',
    LvCableLLLNPE: 'CABLE_LLLNPE',
    LvCableLLLN: 'CABLE_LLLN',
    LvCableLLLPEN: 'CABLE_LLLPEN',
    LvCableLLLPE: 'CABLE_LLLPE',
    LvCableLLL: 'CABLE_LLL',
    LvCableLLPE: 'CABLE_LLPE',
    LvCableLL: 'CABLE_LL',
    LvCableLNPE: 'CABLE_LNPE',
    LvCableLN: 'CABLE_LN',
    LvCableLPEN: 'CABLE_LPEN',
    TransformerStarStar: 'TRAFO2_11',
    TransformerStarStarg: 'TRAFO2_12',
    TransformerStarDelta: 'TRAFO2_13',
    TransformerStargStar: 'TRAFO2_21',
    TransformerStargStarg: 'TRAFO2_22',
    TransformerStargDelta: 'TRAFO2_23',
    TransformerDeltaStar: 'TRAFO2_31',
    TransformerDeltaStarg: 'TRAFO2_32',
    TransformerDeltaDelta: 'TRAFO2_33',
    TransformerInsulatedInsulated: 'TRAFO2_44',
    TransformerInsulatedGrounded: 'TRAFO2_45',
    TransformerGroundedInsulated: 'TRAFO2_54',
    TransformerGroundedGrounded: 'TRAFO2_55',
    Transformer3: 'TRAFO3',
    BusWay: 'BUSDUCT',
    Probe: 'MULTIMETER',
    Ups: 'UPS',
    SwitchBoard: 'BOARD',
    Mms: 'MMS',
    MotorDol: 'MOTORDOL',
    MotorYd: 'MOTORYD',
    MmsMo: 'MMSMO',
    MmsTm: 'MMSTM',
    LoadArrow: 'LOAD-ARROW',
    LoadTrafo: 'LOAD_TRAFO',
    MvCircuitBreakerW: 'CIRCUITBREAKERMVW',
    MvDownDisconnector: 'MVDISC-DOWN',
    MvEarthDisconnector: 'MVEDISC',
    MvSwitchDisconnector: 'MVSWITCH',
    MvFuseDisconnector: 'MVDISCFUSE',
    MvFuseSwitchDisconnector: 'MVSWITCHFUSE',
    MvVoltageTransformer: 'TRAFO_V',
    LvSwitchFuse: 'SWITCH_FUSE',
    LvDisconnectorFuse: 'DISCONNECTOR_FUSE',
    LvSwitchDisconnectorFuse: 'SWITCH_DISCONNECTOR_FUSE',
    LvFuseSwitch: 'FUSE_SWITCH',
    LvFuseDisconnector: 'FUSE_DISCONNECTOR',
    LvFuseSwitchDisconnector: 'FUSE_SWITCH_DISCONNECTOR',
    LvFuseBase: 'FUSEBASE',
    LvSwitch: 'SWITCH',
    LvSwitchDisconnector: 'SWITCH_DISCONNECTOR',
    SoftStarterGeneric: 'SOFTSTARTER',
    SoftStarter: 'SOFTSTARTER_PURE',
    SoftStarterOLR: 'SOFTSTARTER_OLR',
    InsulationTransformer: 'TRAFO2_44_INSUL',
    InsulationTransformerStarStar: 'TRAFO2_11_INSUL',
    InsulationTransformerStarDelta: 'TRAFO2_13_INSUL',
    GensetBio: 'GENERATOR_BIO',
    GensetPhoto: 'GENERATOR_PHOTO',
    GensetWind: 'GENERATOR_WIND',
    TypicalBreakerMotor: 'TYPICAL_BREAKER_MOTOR',
    TypicalFuseMotor: 'TYPICAL_FUSE_MOTOR',
    TypicalBreakerLoad: 'TYPICAL_BREAKER_LOAD',
    TypicalFuseLoad: 'TYPICAL_FUSE_LOAD',
    TypicalBreakerHeater: 'TYPICAL_BREAKER_HEATER',
    TypicalFuseHeater: 'TYPICAL_FUSE_HEATER',
    HeaterLoad: 'HEATER_LOAD',
    ChargerLoad: 'CHARGER_LOAD',
    ContactorStarDelta: 'CONTACTOR_STAR_DELTA',
    LvOverLoadRelayStarDelta: 'OLR_STAR_DELTA',
    CableStarDelta: 'WIREFFF_STAR_DELTA',
    CableStarDeltaPE: 'WIREFFFPE_STAR_DELTA',
    MotorStarDelta: 'MOTOR_STAR_DELTA',
    ContactorRev: 'CONTACTOR_REV',
    Mcu: 'MCU',
    ContactorStarDeltaInv: 'CONTACTOR_STAR_DELTA_INV',
    LvOverLoadRelayStarDeltaInv: 'OLR_STAR_DELTA_INV',
    SoftStarterStarDelta: 'SOFTSTARTER_PURE_STAR_DELTA',
    SoftStarterOLRStarDelta: 'SOFTSTARTER_OLR_STAR_DELTA',
    Vsd: 'VSD_PURE',
    VsdOLR: 'VSD_OLR',
    Ups2Input: 'UPS_2_INPUT',
    UpsBypass: 'UPS_BYPASS',
    UpsNoBypass: 'UPS_NOBYPASS',
    TextArea: 'TEXTAREA',
    Line: 'LINE',
    Connection: 'CONNECTOON',
    //
  };

  static Symbol2Type = {
    ATS: 'Ats',
    UTILITY: 'PowerSupply',
    GENERATOR: 'Genset',
    GENERATOR_SMALL: 'GeneratorSmall',
    UTILITY_MV_SMALL: 'UtilityMVSmall',
    UTILITY_SMALL: 'UtilitySmall',
    LOAD: 'Load',
    LOAD_PHANTOM: 'Load',
    MOTOR: 'Motor',
    CAPACITORBANKS: 'Capacitor',
    WIREMV: 'MvCable',
    WIRELV: 'LvCable',
    TRAFO2: 'Transformer',
    CIRCUITBREAKERLV: 'LvCircuitBreaker',
    CIRCUITBREAKERMV: 'MvCircuitBreaker',
    DISCONNECTORLV: 'LvDisconnector',
    MVDISC: 'MvDisconnector',
    CONTACTOR: 'LvContactor',
    OLR: 'LvOverLoadRelay',
    FUSELV: 'LvFuseGear',
    FUSEMV: 'MvFuseGear',
    IMPEDANCE: 'Impedance',
    BUSBAR: 'Busbar',
    ARRIVAL: 'LinkIn',
    DEPARTURE: 'LinkOut',
    LIGHTS: 'LightsLoad',
    RCCB: 'RcCb',
    CB_TM: 'LvCbLsi',
    CB_TMRC: 'LvCbLsiRc',
    CB_MO: 'LvCbI',
    CB_MORC: 'LvCbIRc',
    CB_TM_W: 'LvCbLsiW',
    CB_TMRC_W: 'LvCbLsiRcW',
    CB_MO_W: 'LvCbIW',
    CB_MORC_W: 'LvCbIRcW',
    CABLE_LLLNPE: 'LvCableLLLNPE',
    CABLE_LLLN: 'LvCableLLLN',
    CABLE_LLLPEN: 'LvCableLLLPEN',
    CABLE_LLLPE: 'LvCableLLLPE',
    CABLE_LLL: 'LvCableLLL',
    CABLE_LLPE: 'LvCableLLPE',
    CABLE_LL: 'LvCableLL',
    CABLE_LNPE: 'LvCableLNPE',
    CABLE_LN: 'LvCableLN',
    CABLE_LPEN: 'LvCableLPEN',
    TRAFO2_11: 'TransformerStarStar',
    TRAFO2_12: 'TransformerStarStarg',
    TRAFO2_13: 'TransformerStarDelta',
    TRAFO2_21: 'TransformerStargStar',
    TRAFO2_22: 'TransformerStargStarg',
    TRAFO2_23: 'TransformerStargDelta',
    TRAFO2_31: 'TransformerDeltaStar',
    TRAFO2_32: 'TransformerDeltaStarg',
    TRAFO2_33: 'TransformerDeltaDelta',
    TRAFO2_44: 'TransformerInsulatedInsulated',
    TRAFO2_45: 'TransformerInsulatedGrounded',
    TRAFO2_54: 'TransformerGroundedInsulated',
    TRAFO2_55: 'TransformerGroundedGrounded',
    TRAFO3: 'Transformer3',
    BUSDUCT: 'BusWay',
    MULTIMETER: 'Probe',
    UPS: 'Ups',
    BOARD: 'SwitchBoard',
    MMS: 'Mms',
    MOTORDOL: 'MotorDol',
    MOTORYD: 'MotorYd',
    MMSMO: 'MmsMo',
    MMSTM: 'MmsTm',
    'LOAD-ARROW': 'LoadArrow',
    LOAD_TRAFO: 'LoadTrafo',
    CIRCUITBREAKERMVW: 'MvCircuitBreakerW',
    'MVDISC-DOWN': 'MvDownDisconnector',
    MVEDISC: 'MvEarthDisconnector',
    MVSWITCH: 'MvSwitchDisconnector',
    MVDISCFUSE: 'MvFuseDisconnector',
    MVSWITCHFUSE: 'MvFuseSwitchDisconnector',
    TRAFO_V: 'MvVoltageTransformer',
    SWITCH_FUSE: 'LvSwitchFuse',
    DISCONNECTOR_FUSE: 'LvDisconnectorFuse',
    SWITCH_DISCONNECTOR_FUSE: 'LvSwitchDisconnectorFuse',
    FUSE_SWITCH: 'LvFuseSwitch',
    FUSE_DISCONNECTOR: 'LvFuseDisconnector',
    FUSE_SWITCH_DISCONNECTOR: 'LvFuseSwitchDisconnector',
    FUSEBASE: 'LvFuseBase',
    SWITCH: 'LvSwitch',
    SWITCH_DISCONNECTOR: 'LvSwitchDisconnector',
    SOFTSTARTER: 'SoftStarterGeneric',
    SOFTSTARTER_PURE: 'SoftStarter',
    SOFTSTARTER_OLR: 'SoftStarterOLR',
    TRAFO2_44_INSUL: 'InsulationTransformer',
    TRAFO2_11_INSUL: 'InsulationTransformerStarStar',
    TRAFO2_13_INSUL: 'InsulationTransformerStarDelta',
    GENERATOR_BIO: 'GensetBio',
    GENERATOR_PHOTO: 'GensetPhoto',
    GENERATOR_WIND: 'GensetWind',
    TEXTAREA: 'TextArea',
    LINE: 'Line',
    CONNECTOON: 'Connection',
    CIRCUITBREAKERMVW_SMALL: 'MvCircuitBreakerW',
    CIRCUITBREAKERMVW_PSMALL: 'MvCircuitBreakerW',
    CIRCUITBREAKERMV_SMALL: 'MvCircuitBreaker',
    CIRCUITBREAKERMV_DISC: 'MvCircuitBreaker',
    CIRCUITBREAKERMV_GEN: 'MvCircuitBreaker',
    MVDISC_GEN: 'MvDisconnector',
    TYPICAL_BREAKER_MOTOR: 'TypicalBreakerMotor',
    TYPICAL_FUSE_MOTOR: 'TypicalFuseMotor',
    TYPICAL_BREAKER_LOAD: 'TypicalBreakerLoad',
    TYPICAL_FUSE_LOAD: 'TypicalFuseLoad',
    TYPICAL_BREAKER_HEATER: 'TypicalBreakerHeater',
    TYPICAL_FUSE_HEATER: 'TypicalFuseHeater',
    HEATER_LOAD: 'HeaterLoad',
    CHARGER_LOAD: 'ChargerLoad',
    CONTACTOR_STAR_DELTA: 'ContactorStarDelta',
    OLR_STAR_DELTA: 'LvOverLoadRelayStarDelta',
    WIREFFF_STAR_DELTA: 'CableStarDelta',
    WIREFFFPE_STAR_DELTA: 'CableStarDeltaPE',
    MOTOR_STAR_DELTA: 'MotorStarDelta',
    CONTACTOR_REV: 'ContactorRev',
    MCU: 'Mcu',
    CONTACTOR_STAR_DELTA_INV: 'ContactorStarDeltaInv',
    OLR_STAR_DELTA_INV: 'LvOverLoadRelayStarDeltaInv',
    SOFTSTARTER_PURE_STAR_DELTA: 'SoftStarterStarDelta',
    SOFTSTARTER_OLR_STAR_DELTA: 'SoftStarterOLRStarDelta',
    VSD_PURE: 'Vsd',
    VSD_OLR: 'VsdOLR',
    UPS_2_INPUT: 'Ups2Input',
    UPS_BYPASS: 'UpsBypass',
    UPS_NOBYPASS: 'UpsNoBypass',

    //
  };

  static SubType2Type = {
    Ats: 'app.ATS',
    PowerSupply: 'app.UTILITY',
    Genset: 'app.GENERATOR',
    GeneratorSmall: 'app.GENERATOR',
    UtilityMVSmall: 'app.UTILITY_MV_SMALL',
    UtilitySmall: 'app.UTILITY_SMALL',
    Load: 'app.LOAD',
    Motor: 'app.MOTOR',
    Capacitor: 'app.CAPACITORBANKS',
    MvCable: 'app.WIREMV',
    LvCable: 'app.WIRELV',
    Transformer: 'app.TRAFO2',
    LvCircuitBreaker: 'app.CB_TM',
    MvCircuitBreaker: 'app.CIRCUITBREAKERMV',
    LvDisconnector: 'app.DISCONNECTORLV',
    MvDisconnector: 'app.MVDISC',
    LvContactor: 'app.CONTACTOR',
    LvOverLoadRelay: 'app.OLR',
    LvFuseGear: 'app.FUSEBASE',
    MvFuseGear: 'app.FUSEMV',
    Impedance: 'app.IMPEDANCE',
    Busbar: 'app.BUSBAR',
    LinkIn: 'app.ARRIVAL',
    LinkOut: 'app.DEPARTURE',
    LightsLoad: 'app.LIGHTS',
    RcCb: 'app.RCCB',
    LvCbLsi: 'app.CB_TM',
    LvCbLsiRc: 'app.CB_TM',
    LvCbI: 'app.CB_TM',
    LvCbIRc: 'app.CB_TM',
    LvCbLsiW: 'app.CB_TM',
    LvCbLsiRcW: 'app.CB_TM',
    LvCbIW: 'app.CB_TM',
    LvCbIRcW: 'app.CB_TM',
    LvCableLLLNPE: 'app.WIRELV',
    LvCableLLLN: 'app.WIRELV',
    LvCableLLLPEN: 'app.WIRELV',
    LvCableLLLPE: 'app.WIRELV',
    LvCableLLL: 'app.WIRELV',
    LvCableLLPE: 'app.WIRELV',
    LvCableLL: 'app.WIRELV',
    LvCableLNPE: 'app.WIRELV',
    LvCableLN: 'app.WIRELV',
    LvCableLPEN: 'app.WIRELV',
    TransformerStarStar: 'app.TRAFO2',
    TransformerStarStarg: 'app.TRAFO2',
    TransformerStarDelta: 'app.TRAFO2',
    TransformerStargStar: 'app.TRAFO2',
    TransformerStargStarg: 'app.TRAFO2',
    TransformerStargDelta: 'app.TRAFO2',
    TransformerDeltaStar: 'app.TRAFO2',
    TransformerDeltaStarg: 'app.TRAFO2',
    TransformerDeltaDelta: 'app.TRAFO2',
    TransformerInsulatedInsulated: 'app.TRAFO2',
    TransformerInsulatedGrounded: 'app.TRAFO2',
    TransformerGroundedInsulated: 'app.TRAFO2',
    TransformerGroundedGrounded: 'app.TRAFO2',
    Transformer3: 'app.TRAFO3',
    BusWay: 'app.BUSDUCT',
    Probe: 'app.MULTIMETER',
    Ups: 'app.UPS',
    // UpsBypass: 'UPS-BYPASS',
    // UpsNoBypass: 'UPS-NOBYPASS',
    Ups2Input: 'app.UPS_2_INPUT',
    UpsBypass: 'app.UPS_BYPASS',
    UpsNoBypass: 'app.UPS_NOBYPASS',
    SwitchBoard: 'app.BOARD',
    Mms: 'app.MMS',
    MotorDol: 'app.MOTOR',
    MotorYd: 'app.MOTOR',
    MmsMo: 'app.MMS',
    MmsTm: 'app.MMS',
    LoadArrow: 'app.LOAD',
    LoadTrafo: 'app.LOAD',
    MvCircuitBreakerW: 'app.CIRCUITBREAKERMVW',
    MvDownDisconnector: 'app.MVDISC',
    MvEarthDisconnector: 'app.MVDISC',
    MvSwitchDisconnector: 'app.MVSWITCH',
    MvFuseDisconnector: 'app.FUSEMV',
    MvFuseSwitchDisconnector: 'app.FUSEMV',
    MvVoltageTransformer: 'app.MULTIMETER',
    LvSwitchFuse: 'app.FUSEBASE',
    LvDisconnectorFuse: 'app.FUSEBASE',
    LvSwitchDisconnectorFuse: 'app.FUSEBASE',
    LvFuseSwitch: 'app.FUSEBASE',
    LvFuseDisconnector: 'app.FUSEBASE',
    LvFuseSwitchDisconnector: 'app.FUSEBASE',
    LvFuseBase: 'app.FUSEBASE',
    LvSwitch: 'app.DISCONNECTORLV',
    LvSwitchDisconnector: 'app.DISCONNECTORLV',
    SoftStarterGeneric: 'app.SOFTSTARTER',
    SoftStarter: 'app.SOFTSTARTER',
    SoftStarterOLR: 'app.SOFTSTARTER',
    InsulationTransformer: 'app.TRAFO2',
    InsulationTransformerStarStar: 'app.TRAFO2',
    InsulationTransformerStarDelta: 'app.TRAFO2',
    GensetBio: 'app.GENERATOR_BIO',
    GensetPhoto: 'app.GENERATOR_PHOTO',
    GensetWind: 'app.GENERATOR_WIND',
    Connection: 'app.CONNECTOON',
    TypicalBreakerMotor: 'app.TYPICAL_FEEDER',
    TypicalFuseMotor: 'app.TYPICAL_FEEDER',
    TypicalBreakerLoad: 'app.TYPICAL_FEEDER',
    TypicalFuseLoad: 'app.TYPICAL_FEEDER',
    TypicalBreakerHeater: 'app.TYPICAL_FEEDER',
    HeaterLoad: 'app.LOAD',
    ChargerLoad: 'app.LOAD',
    ContactorStarDelta: 'app.CONTACTOR',
    LvOverLoadRelayStarDelta: 'app.OLR',
    CableStarDelta: 'app.WIRELV',
    CableStarDeltaPE: 'app.WIRELV',
    MotorStarDelta: 'app.MOTOR',
    ContactorRev: 'app.CONTACTOR',
    Mcu: 'app.OLR',
    ContactorStarDeltaInv: 'app.CONTACTOR',
    LvOverLoadRelayStarDeltaInv: 'app.OLR',
    SoftStarterStarDelta: 'app.SOFTSTARTER',
    SoftStarterOLRStarDelta: 'app.SOFTSTARTER',
    Vsd: 'app.SOFTSTARTER',
    VsdOLR: 'app.SOFTSTARTER',
    //
  };
}
