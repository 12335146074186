import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';
import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'CB_TM',
  objectType: EDeviceObjectType.LvCbLsi,
  deviceType: EDeviceType.CB_TM,
  // General info
  Name: '',
  Description: '',
  Standard: 'IEC 60947-2',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400.0,
  Locked: false,
  PreFiltersChanged: true,
  // Filters //////////////

  // Family
  FamilyFilter: '',
  familyFilterList: [],

  familyOutput: '',
  familyOutputList: [],

  // PolesNumber
  PolesFilter: '',
  polesFilterList: ['4P', '3P', '3P+N'],
  polesOutput: '',
  polesOutputList: [],

  // Release
  ReleaseFilter: 'All possibilities',
  releaseFilterList: [],
  releaseOutput: '',
  releaseOutputList: [],

  // Relations
  Relations: [],
  protectionTypeList: [],
  defaultProtectionTypeList: ['Cables-Busways', 'Capacitor Banks', 'Pure residual current relays', 'Disconnector'],
  displayRelations: [],

  // Type
  Type: '',
  TypeFilter: 'TMP',
  TypeList: ['TMP', 'MO', 'TMRCR', 'MORCR'],
  TypeListTranslate: [
    'Overload and Short Circuit protection',
    'Short Circuit protection only',
    'Overload and Short Circuit protection + RCD',
    'Short Circuit protection + RCD',
  ],

  // Version
  VersionFilter: 'All possibilities',
  versionFilterList: [],
  versionOutput: '',
  versionOutputList: [],

  // RC PreFilters
  // Class
  RcdTypeFilter: 'All possibilities',
  rcClassFilterList: [],

  rcClassOutput: 'All possibilities',
  rcClassOutputList: [],

  // Version
  RcdVersionFilter: 'All possibilities',
  rcVersionFilterList: [],

  rcVersionOutput: 'All possibilities',
  rcVersionOutputList: [],

  CoordinationFilter: 'Not requested',
  CoordinationFilterList: ['Not requested'], // Selectivity, Back-up

  // Idn
  IdnFilter: -1,
  idnFilterList: [],

  IdnOutput: -1,
  IdnOutputList: [],

  // FamilyList: {},
  sizeOutput: -1,
  sizeOutputList: [],

  icuOutput: -1,
  icuOutputList: [],

  inOutput: -1,
  inOutputList: [],
  ProtectionAutoAssignOff: false,
  terminalsOutput: '',
  FrameFilter: '',
  frameFilterList: [],
  frameOutput: '',
  frameOutputList: [],
  tripUnitTypeOutput: '',
  tripUnitTypeOutputList: [],
  terminalsOutputList: [],
  DevicesList: [],
  BreakersRcbList: [],
  DevicesBatchList: [],
  DeviceId: 0,
  RcbId: 0,
  ProductDescription: '',
  RcbModelName: '',
  DeviceDescriptionRcb: '',
  ProductIdList: null,
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,
  deviceIcw1s: [],
  deviceStartIndex: 0,
  deviceEndIndex: 0,
  initialized: false,
  requestRunning: false,
  IsOffInScenario: {
    name: [],
  },
  DiscriminationProperty: '',
  DiscriminationPropertiesList: {},
  TripUnitFunctions: [],
  tripUnitFunctionsAdvanced: false,
};
