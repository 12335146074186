export default {
  type: 'app.LINK',
  router: {
    name: 'manhattan2',
    args: {
      step: 5,
      perpendicular: false,
    },
  },
  connector: {
    name: 'normal',
  },
  vertexMarkup:
    '<g class="marker-vertex-group" transform="translate(<%= x %>, <%= y %>) scale(0.5)"><circle class="marker-vertex" idx="<%= idx %>" r="10" /><path class="marker-vertex-remove-area" idx="<%= idx %>" d="M16,5.333c-7.732,0-14,4.701-14,10.5c0,1.982,0.741,3.833,2.016,5.414L2,25.667l5.613-1.441c2.339,1.317,5.237,2.107,8.387,2.107c7.732,0,14-4.701,14-10.5C30,10.034,23.732,5.333,16,5.333z" transform="translate(5, -33)"/><path class="marker-vertex-remove" idx="<%= idx %>" transform="scale(.8) translate(9.5, -37)" d="M24.778,21.419 19.276,15.917 24.777,10.415 21.949,7.585 16.447,13.087 10.945,7.585 8.117,10.415 13.618,15.917 8.116,21.419 10.946,24.248 16.447,18.746 21.948,24.248z"><title>Remove vertex.</title></path></g>',
  toolMarkup:
    '<g class="link-tool"><g class="tool-remove" event="remove"><circle r="11" /><path transform="scale(.8) translate(-16, -16)" d="M24.778,21.419 19.276,15.917 24.777,10.415 21.949,7.585 16.447,13.087 10.945,7.585 8.117,10.415 13.618,15.917 8.116,21.419 10.946,24.248 16.447,18.746 21.948,24.248z" /><title>Remove link.</title></g></g>',
  source: {
    id: '14e3fe95-002c-4d6e-851d-ca5efbef67f2',
    selector: 'g:nth-child(2) > circle:nth-child(1)',
    port: '5930370d-6f0f-4801-81dc-28359c77892b',
  },
  target: {
    id: 'e91312a9-7e42-49eb-b2bd-a8a70b433196',
    selector: 'g:nth-child(3) > circle:nth-child(1)',
    port: 'cd828f91-a3c8-43df-b936-0e2dbe95e8c6',
  },
  id: '7b576aba-38fd-4647-8265-2cb213703807',
  z: 6,
  attrs: {},
};
