import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export const defaultBioGeneratorState = {
  ...defaultElement,
  deviceType: EDeviceType.GENERATOR_BIO,
  symbol: 'GENERATOR_BIO',
  objectType: EDeviceObjectType.GensetBio,

  Description: '',
  LineType: ELvLineType.LLLN,
  PrimaryWindingLineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  PrimaryWindingEarthingSystem: EEarthingSystem.TNS,
  NominalVoltage: 400.0,
  Uref: 400.0,
  Frequency: 50,
  NominalPower: 10000,
  CosPhiNominal: 1,
  GensetType: 'LVT',
  Ra: 0.000463,
  TA: 55,
  XdSecond: 12.5,
  XqSecond: 12.5,
  XdFirst: 30,
  Xd: 225,
  XSecond: 12.5,
  X0: 8,
  TdSecond: 0.02,
  TdFirst: 0.65,
  StarCenterAvailable: false,
  GroundingResistance: 10,
  IsPq: false,
  MaxContactVoltage: 50,
  UpsNoByPass: true,
  ComputedP: 0,
  ComputedQ: 0,
  ComputedCosPhi: 0,
  UpsIkMax: 0,
  LoadCurrent: 0,
  IScInRatio: 1.2,
  IScInRatioList: [1, 1.2, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
  BatteryChargingCurrent: 15,
  BatteryChargingCurrentList: [1, 2, 5, 10, 15, 20, 25],
  DISTRIBUTION_SYSTEM_0_LIST: ['TN-S', 'TN-C', 'TT', 'IT'],
};

export const defaultWindGeneratorState = {
  ...defaultBioGeneratorState,
  deviceType: EDeviceType.GENERATOR_WIND,
  symbol: 'GENERATOR_WIND',
  objectType: EDeviceObjectType.GensetWind,
};

export const defaultPHOTOGeneratorState = {
  ...defaultBioGeneratorState,
  deviceType: EDeviceType.GENERATOR_PHOTO,
  symbol: 'GENERATOR_PHOTO',
  objectType: EDeviceObjectType.GensetPhoto,
};
