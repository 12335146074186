/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import { Button } from '@abb/abb-common-ux-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import _, { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import DeviceRow from './deviceRow';
import { editDevice, getUserCurves } from './actions';
import { GridColumn, GridRow } from 'components/generic/grid';
import 'styles/customCurvesDevices.scss';
import { curvesActions } from 'curves/actions';

const CustomCurvesDeviceManager: React.FunctionComponent = (): React.ReactElement => {
  const customCurves = useSelector((state) => state.curves.customCurves, _.isEqual);
  const checkedCurves = useSelector((state) => state.curves.checkedCurves, _.isEqual);
  const devices = customCurves.devices;
  const dispatch = useDispatch();
  const devicesArray = Object.keys(customCurves.devices ?? []);
  const [checkedDevices, setCheckedDevices] = useState<Array<string>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserCurves());
  }, []);

  useEffect(() => {
    setCheckedDevices(Object.keys(devices).filter((devId) => devices[devId].checked));
  }, [devices]);

  const checkDevice = (deviceId: string, value: boolean) => {
    if (value) {
      if (!checkedDevices.includes(deviceId)) {
        setCheckedDevices([...checkedDevices, deviceId]);
      }
      if (!checkedCurves.includes(deviceId)) {
        dispatch(curvesActions.setCheckedCurves({ checkedCurves: [...checkedCurves, deviceId] }));
      }
    } else if (!value) {
      const newCheckedDevices = checkedDevices.filter((devId) => devId !== deviceId);
      setCheckedDevices(newCheckedDevices);
    }
    const newCustomCurves = cloneDeep(customCurves);
    const checkId = Object.keys(newCustomCurves.devices).find((devId) => devId === deviceId);
    if (checkId) {
      newCustomCurves.devices[checkId].checked = value;
      newCustomCurves.devices[checkId].selectedDiagram = Object.keys(newCustomCurves.devices[checkId].curves).find((diagramName) =>
        newCustomCurves.devices[checkId].curves[diagramName].points.length > 0) as string;
      dispatch(curvesActions.setCustomCurves({ customCurves: newCustomCurves }));
    }
  }

  // const addCustomDevices = (customDevices: Array<string>) => {
  //   dispatch(curvesActions.setCurvesControls({selectedFilter: '', curvesList}));
  // }

  return (
    <>
      <GridRow style={{ width: '92%', margin: '0 auto 10px', marginBottom: '8px' }}>
        <GridColumn col={2} offset={6} horizontalAlignment="end">
          <Button type="primary-blue" onClick={() => dispatch(editDevice('new'))} text={t('DOCWEB_BUTTON_CREATECUSTOMDEVICE')} />
        </GridColumn>
      </GridRow>
      {devicesArray ? (
        devicesArray.map((deviceId, index) => {
          return (
            <DeviceRow
              key={`${deviceId}${index}`}
              deviceName={devices ? devices[deviceId].name : ''}
              deviceVoltage={devices ? devices[deviceId].voltage : 0}
              index={index}
              deviceId={deviceId}
              onCheckBoxChange={checkDevice}
              checkedDevices={checkedDevices}
            />
          );
        })
      ) : (
        <></>
      )}
      <GridRow style={{ paddingTop: "15px" }}>
        <GridColumn col={7}></GridColumn>
        <GridColumn col={1}>
          {/* 
                <Button
            type="primary-blue"
            text={t('ADD')}
            onClick={() => console.log('xxx')}
          /> */}
        </GridColumn>

      </GridRow>
    </>
  );
};

export default CustomCurvesDeviceManager;
