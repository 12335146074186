/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Range from 'components/curves/Range';
import TwoDoubleCombo from 'components/curves/TwoDoubleCombo';
import OneDoubleCombo from 'components/curves/OneDoubleCombo';
import OneCombo from 'components/curves/OneCombo';
import DoubleStringCombo from 'components/curves/DoubleStringCombo';
import ComboDoubleStringCombo from 'components/curves/ComboDoubleStringCombo';
import ComboTwoDoubleCombo from 'components/curves/ComboTwoDoubleCombo';

// WHAT TO DO? translations is file we add to locize
// here we get name from translations and pass it to locize, and then we will process the key in the final component
import { curvesUINames } from 'curves/CurvesTranslations';

import { Icon } from '@abb/abb-common-ux-react';

import { resetCurvesSettings } from 'curves/actions';
import { CurveFunction, ICurvesFunctions, IGenericCurveFunction } from 'types/curves/functions';
import { FunctionOption } from 'types/curves';
import { isFunctionOptions } from 'types/curves/type-guards';
import { useDispatch } from 'store';
import _ from 'lodash';
import { Device } from 'types/devices/device';
import styles from 'styles/Functions.css';
import { ICurveOptions } from 'types/curves/components';
import { ISelectOnChange } from 'types/components/selectContainer';
import { GridRow } from 'components/generic/grid';
import { getParamValue, getOnParamValue, findNearestValue } from './functionValues';

export const paramValuePresent = (
  param: IGenericCurveFunction,
  settingName: string,
  attribute: keyof IGenericCurveFunction,
  optional = false
): boolean => {
  const present = param[attribute] !== undefined && param[attribute] !== null;
  if (present) {
    // Empty string means that there is no value
    // Numbers are ok
    // Booleans are ok
    // Array are ok
    // Everything else is not supported
    if (typeof param[attribute] === 'string' && param[attribute] !== '') {
      return true;
    }
    if (['number', 'boolean', 'object'].indexOf(typeof param[attribute]) >= 0) {
      return true;
    }
  }
  if (!optional) {
    console.error(`Curve '${settingName}' attribute '${attribute}' is missing`);
  }
  return false;
};

export const titleFromName = (name: string): string => {
  // since we move translation to locize - we just convert name to key here and pass it as a title
  return curvesUINames[name.toUpperCase() as keyof typeof curvesUINames]
    ? curvesUINames[name.toUpperCase() as keyof typeof curvesUINames]
    : name.toUpperCase();
};

// const translateValue = (value: string): string => {
//   const valueUpperCase = value.toUpperCase() as keyof typeof curvesUINames;
//   // same here
//   return curvesUINames[valueUpperCase] ? curvesUINames[valueUpperCase] : value;
// };

const getPrecision = (title: string): number => {
  if (title === 'Overload (L - ANSI 49)') {
    return 3;
  }
  return 2;
};

const structurizeOptions = (
  options: Array<FunctionOption> | Array<string | number>,
  isString: boolean,
  suffix?: string,
  numberOfDecimals?: number
): { options: Array<string>; numberOfDecimals: number } | Array<string> | null => {
  if (!options) {
    return null;
  }
  const structurizedOptions = new Array<string>();
  let delimiter: number;
  const innerSuffix = suffix || '';
  if (isFunctionOptions(options)) {
    const totalCount = options.reduce((a, op) => a + op.count, 0);
    if (totalCount > 65) {
      delimiter = 0.01;
    }
    numberOfDecimals = numberOfDecimals || -Math.floor(Math.log10(Math.min(...options.map((op) => op.step)) + 1e-9));
    if (numberOfDecimals < 0) numberOfDecimals = 0;
    let currentIndex = 0;
    options.forEach((op) => {
      for (let i = 0; i < op.count; i += 1) {
        if (delimiter) {
          if (
            Number.isInteger(parseFloat((op.start + op.step * i).toFixed(numberOfDecimals)) / delimiter) ||
            currentIndex === 0 ||
            currentIndex === totalCount - 1
          ) {
            structurizedOptions.push(`${(op.start + op.step * i).toFixed(numberOfDecimals)}${innerSuffix}`);
          }
          currentIndex += 1;
        } else {
          structurizedOptions.push(`${(op.start + op.step * i).toFixed(numberOfDecimals)}${innerSuffix}`);
        }
      }
    });
    return { options: structurizedOptions, numberOfDecimals };
  }
  if (!isString && !isFunctionOptions(options)) {
    options = (options as Array<number>).map((value) => value.toFixed(getPrecision('')));
  }
  return options as Array<string>;
};

const Functions: FunctionComponent<ICurvesFunctions> = ({
  id,
  params,
  docxdata,
  curvesDirty,
  updateElementParams,
  getSelectedDeviceCurves,
  setCurrentCurvesDirty,
}): React.ReactElement => {
  const [lastValue, setLastValue] = useState<number | string>('');
  const dispatch = useDispatch();
  const getDeviceCurvesTimeout: { current: NodeJS.Timeout | undefined } = useRef(undefined);

  useEffect(() => {
    return () => {
      if (getDeviceCurvesTimeout.current) {
        clearTimeout(getDeviceCurvesTimeout.current);
        getDeviceCurvesTimeout.current = undefined;
      }
    };
  }, []);

  const getDeviceCurves = () => {
    if (getDeviceCurvesTimeout.current) {
      clearTimeout(getDeviceCurvesTimeout.current);
      getDeviceCurvesTimeout.current = undefined;
    }
    if (!curvesDirty) {
      setCurrentCurvesDirty();
    }
    getDeviceCurvesTimeout.current = setTimeout(() => {
      getDeviceCurvesTimeout.current = undefined;
      dispatch(getSelectedDeviceCurves());
    }, 300);
  };

  const onOnChange = (
    value: string | boolean | number,
    currentParam: { onName: Array<string | number>; interlocked: number },
    name: string
  ) => {
    const data = updateTripUnitParamsByPath(currentParam.onName ?? [], value);
    if (value && currentParam.interlocked && currentParam.interlocked > 0) {
      if (params) {
        Object.keys(params).forEach((key) => {
          const param = params[key];
          if (param && name !== key && param.interlocked === currentParam.interlocked && param.onName) {
            // _.merge(data, updateTripUnitParamsByPath(param.onName, false));
            _.set(data, param.onName, false);
          }
        });
      }
    }
    onParamsValueChange(data);
  };

  const renderRange = (data: Partial<Device>, param: IGenericCurveFunction, name: string, on: boolean, bEnabled?: boolean) => {
    const { settingName, min, max } = param;
    const setting = getParamValue(data, settingName, min === max, min);
    // TODO: Check this strange stuff
    const val: string =
      typeof setting === 'object' && Object.keys(setting).length > 0
        ? (setting[Object.keys(setting)[0]] as string)
        : (setting as string);
    return (
      <Range
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled ?? false}
        on={on}
        min={min}
        max={max}
        value={Array.isArray(val) ? val[0] : val}
        onValueChange={(value) => updateParamsChange(value, settingName)}
        onFocusOut={(value) => onParamValueChange(value, settingName)}
        onFocus={(event) => onFocus(event)}
        onSliderChange={(value) =>
          onParamValueChange(Array.isArray(value) ? value[0] : value, settingName, undefined, false, 'sliderChange')
        }
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const renderComboTwoDoubleCombo = (
    data: Partial<Device>,
    param: IGenericCurveFunction,
    name: string,
    on: boolean,
    bEnabled = false
  ): JSX.Element | undefined => {
    const value1 = getParamValue(data, param.secondParamName, true, param.secondDefault);
    const value2 = getParamValue(data, param.secondParamName2, true, param.secondDefault2);
    const comboValue = translateFirstParamNameValue(
      param,
      getParamValue(data, param.firstParamName, true, param.firstParams[0]) as string
    );
    const options1 = structurizeOptions(param.secondParams, false, undefined, param.secondPrec) as ICurveOptions;
    const options2 = structurizeOptions(param.secondParams2, false, undefined, param.secondPrec) as ICurveOptions;
    return (
      <ComboTwoDoubleCombo
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled}
        on={on}
        options1={options1 ?? []}
        value1={value1}
        options2={options2 ?? []}
        value2={value2}
        comboValue={comboValue}
        comboOptions={param.firstTrans}
        comboValues={param.firstParams}
        // numberOfDecimals={options2.numberOfDecimals}
        onOnChange={(value) => onOnChange(value, param, name)}
        onFocus={(event) => onFocus(event)}
        onStringComboChange={(value) => onParamValueChange(translateFirstParamNameValue(param, value), param.firstParamName)}
        onValue1Change={(value, fromInput, focus) =>
          onParamValueChange(
            value,
            param.secondParamName,
            fromInput ? { options: param.secondParams, numberOfDecimals: param.secondPrec, focus } : undefined
          )
        }
        onValue2Change={(value, fromInput, focus) =>
          onParamValueChange(
            value,
            param.secondParamName2,
            fromInput ? { options: param.secondParams2, numberOfDecimals: param.secondPrec2, focus } : undefined
          )
        }
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const renderTwoDoubleCombo = (
    data: Partial<Device>,
    param: IGenericCurveFunction,
    name: string,
    on: boolean,
    bEnabled = false
  ) => {
    const value1 = getParamValue(data, param.secondParamName, true, param.secondDefault);
    const value2 = getParamValue(data, param.secondParamName2, true, param.secondDefault2);
    const options1 = structurizeOptions(param.secondParams, false, undefined, param.secondPrec) as ICurveOptions;
    const options2 = structurizeOptions(param.secondParams2, false, undefined, param.secondPrec2) as ICurveOptions;
    return (
      <TwoDoubleCombo
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled}
        on={on}
        options1={options1 ?? []}
        value1={value1}
        options2={options2 ?? []}
        value2={value2}
        onOnChange={(value) => onOnChange(value, param, name)}
        onFocus={(event) => onFocus(event)}
        onValue1Change={(value, fromInput, focus) =>
          onParamValueChange(
            value,
            param.secondParamName,
            fromInput ? { options: param.secondParams, numberOfDecimals: param.secondPrec, focus } : undefined
          )
        }
        onValue2Change={(value, fromInput, focus) =>
          onParamValueChange(
            value,
            param.secondParamName2,
            fromInput ? { options: param.secondParams2, numberOfDecimals: param.secondPrec2, focus } : undefined
          )
        }
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const renderComboDoubleStringCombo = (
    data: Partial<Device>,
    param: IGenericCurveFunction,
    name: string,
    on: boolean,
    bEnabled = false
  ) => {
    const value = translateFirstParamNameValue(
      param,
      getParamValue(data, param.firstParamName, true, param.firstParams[0]) as string
    );
    const value1 = translateFirstParamNameValue(
      param,
      getParamValue(data, param.secondParamName, true, param.secondDefault) as string
    );
    const options = structurizeOptions(param.firstParams, true);
    const options1 = structurizeOptions(param.secondParams, false, undefined, param.secondPrec);
    let value2;
    let options2;
    let value2Name: Array<string>;
    let options2Editable = false;
    if (paramValuePresent(param, name, 'thirdParamName', true)) {
      value2 = translateFirstParamNameValue(param, getParamValue(data, param.thirdParamName, true, param.thirdDefault) as string);
      options2 = structurizeOptions(param.thirdParams, true);
      value2Name = param.thirdParamName;
    } else {
      options2Editable = true;
      value2 = translateFirstParamNameValue(
        param,
        getParamValue(data, param.fourthParamName, true, param.fourthDefault) as string
      );
      options2 = structurizeOptions(param.fourthParams, true, param.fourthSuffix, param.fourthPrec);
      value2Name = param.fourthParamName;
    }
    return (
      <ComboDoubleStringCombo
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled}
        on={on}
        options={options ?? []}
        value={value}
        options1={options1 ?? []}
        value1={value1}
        options2={options2 ?? []}
        editable={options2Editable}
        onFocus={(event) => onFocus(event)}
        // translateFunc={translateValue}
        value2={value2}
        onOnChange={(val) => onOnChange(translateFirstParamNameValue(param, val), param, name)}
        onValueChange={(val) => onParamValueChange(translateFirstParamNameValue(param, val), param.firstParamName)}
        onValue1Change={(val, fromInput, focus) =>
          onParamValueChange(
            translateFirstParamNameValue(param, val),
            param.secondParamName,
            fromInput ? { options: param.secondParams, numberOfDecimals: param.secondPrec, focus: focus ?? false } : undefined
          )
        }
        onValue2Change={(val, fromInput, focus) =>
          onParamValueChange(
            translateFirstParamNameValue(param, val),
            value2Name,
            options2Editable && fromInput
              ? {
                  options: param.fourthParams,
                  numberOfDecimals: param.fourthPrec,
                  suffix: param.fourthSuffix,
                  focus: focus ?? false,
                }
              : undefined
          )
        }
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const renderOneCombo = (data: Partial<Device>, param: IGenericCurveFunction, name: string) => {
    const value2 = getParamValue(data, param.thirdParamName, true, param.thirdDefault);
    const options2 = structurizeOptions(param.thirdParams, true) as ICurveOptions;
    const value2Name = param.thirdParamName;
    if (!paramValuePresent(param, name, 'firstParamName', true)) {
      return (
        <OneCombo
          key={name}
          title={titleFromName(name)}
          bEnabled={false}
          on
          options={options2 ?? []}
          onFocus={(event) => onFocus(event)}
          // translateFunc={translateValue}
          value={value2}
          onOnChange={(value) => onOnChange(translateFirstParamNameValue(param, value), param, name)}
          onValueChange={(value, fromInput, focus) =>
            onParamValueChange(value, value2Name, fromInput ? { options: options2 ?? [], focus: focus ?? false } : undefined)
          }
          curvesRequestIsRunning={curvesDirty}
        />
      );
    }
    return undefined;
  };

  const renderDoubleStringCombo = (
    data: Partial<Device>,
    param: IGenericCurveFunction,
    name: string,
    on: boolean,
    bEnabled = false
  ) => {
    const value2 = getParamValue(data, param.fourthParamName, true, param.fourthDefault);
    const options2 = structurizeOptions(param.fourthParams, true, param.fourthSuffix, param.fourthPrec);
    const value2Name = param.fourthParamName;
    const options2Editable = true;
    const value1 = getParamValue(data, param.secondParamName, true, param.secondDefault);
    const options1 = structurizeOptions(param.secondParams, false, undefined, param.secondPrec);
    return (
      <DoubleStringCombo
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled}
        on={on}
        options1={options1 ?? []}
        value1={value1 as string}
        options2={options2 ?? []}
        value2={value2 as string}
        onFocus={(event) => onFocus(event)}
        editable={options2Editable}
        onOnChange={(value) => onOnChange(value, param, name)}
        onValue1Change={(value, fromInput, focus) =>
          onParamValueChange(
            value,
            param.secondParamName,
            fromInput ? { options: param.secondParams, numberOfDecimals: param.secondPrec, focus } : undefined
          )
        }
        onValue2Change={(value, fromInput, focus) =>
          onParamValueChange(
            value,
            value2Name,
            options2Editable && fromInput
              ? { options: param.fourthParams, suffix: param.fourthSuffix, numberOfDecimals: param.fourthPrec, focus }
              : undefined
          )
        }
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const renderOneDoubleCombo = (
    data: Partial<Device>,
    param: IGenericCurveFunction,
    name: string,
    on: boolean,
    bEnabled = false
  ) => {
    const value = getParamValue(data, param.secondParamName, true, param.secondDefault);
    const options = structurizeOptions(param.secondParams, false, undefined, param.secondPrec) as ICurveOptions;
    return (
      <OneDoubleCombo
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled}
        on={on}
        options={options ?? []}
        value={value}
        onFocus={(event) => onFocus(event)}
        onOnChange={(val) => onOnChange(val, param, name)}
        onValueChange={(val, fromInput, focus) =>
          onParamValueChange(
            val,
            param.secondParamName,
            fromInput ? { options: param.secondParams, numberOfDecimals: param.secondPrec, focus } : undefined
          )
        }
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const renderOneCombo2 = (
    data: Partial<Device>,
    param: IGenericCurveFunction,
    name: string,
    on: boolean,
    bEnabled?: boolean
  ) => {
    const firstParams = [...param.firstParams];
    let value = getParamValue(data, param.firstParamName, true, firstParams[0]) as string;
    if (param.firstParamTranslation && firstParams.indexOf(value) === -1) {
      const valueIndex = Object.keys(param.firstParamTranslation).findIndex(
        (trans) => param.firstParamTranslation[trans] === value
      );
      if (valueIndex !== -1) {
        value = firstParams[valueIndex];
      }
    } else if (firstParams.indexOf(value) === -1) {
      firstParams.push(value);
    }
    return (
      <OneCombo
        key={name}
        title={titleFromName(name)}
        bEnabled={bEnabled}
        on={on}
        options={param.firstParams}
        // translateFunc={translateValue}
        value={value}
        onOnChange={(val) => onOnChange(translateFirstParamNameValue(param, val), param, name)}
        onFocus={(event) => onFocus(event)}
        onValueChange={(val) => onParamValueChange(translateFirstParamNameValue(param, val), param.firstParamName)}
        curvesRequestIsRunning={curvesDirty}
      />
    );
  };

  const curveParamToComponent = (data: Partial<Device>, param: IGenericCurveFunction, name: keyof CurveFunction) => {
    let bEnabled;
    if (param.bEnabled !== undefined) {
      bEnabled = !!param.bEnabled;
    }
    const on = getOnParamValue(data, param.onName);
    if (paramValuePresent(param, name, 'settingName', true)) {
      // Param of type setting
      // I need also the min and max to be present
      if (paramValuePresent(param, name, 'min') && paramValuePresent(param, name, 'max')) {
        // settingName is optional only when min == max
        return renderRange(data, param, name, on, bEnabled);
      }
    } else if (
      paramValuePresent(param, name, 'secondParamName', true) &&
      paramValuePresent(param, name, 'secondParamName2', true) &&
      paramValuePresent(param, name, 'firstParams', true)
    ) {
      return renderComboTwoDoubleCombo(data, param, name, on, bEnabled);
    } else if (
      paramValuePresent(param, name, 'secondParamName', true) &&
      paramValuePresent(param, name, 'secondParamName2', true)
    ) {
      if (paramValuePresent(param, name, 'secondParams') && paramValuePresent(param, name, 'secondParams2')) {
        return renderTwoDoubleCombo(data, param, name, on, bEnabled);
      }
    } else if (
      paramValuePresent(param, name, 'firstParamName', true) &&
      paramValuePresent(param, name, 'secondParamName', true) &&
      (paramValuePresent(param, name, 'thirdParamName', true) || paramValuePresent(param, name, 'fourthParamName', true))
    ) {
      return renderComboDoubleStringCombo(data, param, name, on, bEnabled);
    } else if (
      paramValuePresent(param, name, 'thirdParamName', true) ||
      paramValuePresent(param, name, 'fourthParamName', true)
    ) {
      if (paramValuePresent(param, name, 'thirdParamName', true)) {
        return renderOneCombo(data, param, name);
      }
      return renderDoubleStringCombo(data, param, name, on, bEnabled);
    } else if (paramValuePresent(param, name, 'secondParamName', true)) {
      if (paramValuePresent(param, name, 'secondParams')) {
        return renderOneDoubleCombo(data, param, name, on, bEnabled);
      }
    } else if (paramValuePresent(param, name, 'firstParamName', true)) {
      if (paramValuePresent(param, name, 'firstParams')) {
        return renderOneCombo2(data, param, name, on, bEnabled);
      }
    } else {
      console.error(`Unknown curve attribute ${name}:`, param);
    }
    return undefined;
  };

  const onParamValueChange = (
    value: number | string | boolean,
    valueName: Array<string | number>,
    options?: {
      options: Array<FunctionOption | string | number> | ICurveOptions;
      focus: boolean;
      numberOfDecimals?: number;
      suffix?: string;
    },
    isFocused = false,
    name?: string
  ) => {
    if (name === 'sliderChange') {
      setLastValue(parseFloat(value.toString()));
    }
    dispatch(updateElementParams(id, updateTripUnitParamsByPath(valueName, value), true));
    if (options && Array.isArray(options.options) && options.focus && typeof value === 'number') {
      value = findNearestValue(options.options as Array<FunctionOption>, value, options.suffix) as string | number;
      dispatch(updateElementParams(id, updateTripUnitParamsByPath(valueName, value), true));
      if (value !== lastValue) {
        getDeviceCurves();
      }
    } else if (!options) {
      // if (!isFocused && (lastValue !== '' || name === 'sliderChange') && value !== lastValue) {
      if (!isFocused && value !== lastValue) {
        getDeviceCurves();
      }
    }
  };

  const updateParamsChange = (value: string | number, valueName: Array<string | number>) => {
    dispatch(updateElementParams(id, updateTripUnitParamsByPath(valueName, value), true));
  };

  const onParamsValueChange = (data: Partial<Device>): void => {
    dispatch(updateElementParams(id, data, true));
    getDeviceCurves();
  };

  const updateTripUnitParamsByPath = (path: Array<string | number>, value: unknown) => {
    const device = _.cloneDeep(docxdata);
    _.set(device, path, value);
    const rootNode = path[0];
    const updatedTripUnit = { [rootNode]: device[rootNode] };
    return updatedTripUnit as Device;
  };

  const translateFirstParamNameValue = (
    param: IGenericCurveFunction,
    value: string | number | boolean
  ): string | number | boolean => {
    return param.firstParamTranslation && typeof value !== 'boolean' ? param.firstParamTranslation[value] : value;
  };

  const onFocus = (event: ISelectOnChange) => {
    setLastValue(parseFloat(event.value));
  };

  const paramsComponents: Array<JSX.Element | undefined> = [];
  if (params) {
    Object.keys(params).forEach((key) => {
      const param = params[key];
      if (param) {
        paramsComponents.push(curveParamToComponent(docxdata, param, key as keyof CurveFunction));
      }
    });
  }
  return (
    <div className={styles.root}>
      <GridRow horizontalAlignment="end" className={'curves-refresh'}>
        <Icon
          key={'paramsComponents'}
          name={'abb/refresh'}
          className={'icon-toolbar'}
          sizeClass={'small'}
          onClick={() => dispatch(resetCurvesSettings(id))}
        />
      </GridRow>
      {paramsComponents}
    </div>
  );
};

export default Functions;
