/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { StrokeData, Svg, SVG } from '@svgdotjs/svg.js';
import { GRID_OFFSET_X, GRID_OFFSET_X_PRINT, GRID_OFFSET_Y, LABEL_FONT_SIZE_FOR_PRINT } from 'curves/Constants';
import CurrentLabel from 'curves/shapes/CurrentLabel';
import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { Shape } from 'konva/lib/Shape';
import { Rect } from 'konva/lib/shapes/Rect';

interface IAttrsShape {
  points: Array<number>;
  stroke: string;
  strokeWidth: number;
  width: number;
  height: number;
  fill: string;
  x: number;
  y: number;
  text: string;
  cornerRadius: number;
  fontSize: number;
}

let gridOffsetX = GRID_OFFSET_X;
export default (width: number, height: number): string => {
  const curvesKonva = window.miniCurves;
  const printing = width !== undefined && height !== undefined;
  const rootElementWidth = printing ? width : curvesKonva.width;
  const rootElementHeight = printing ? height : curvesKonva.height;
  if (printing) {
    gridOffsetX = GRID_OFFSET_X_PRINT;
  }
  // eslint-disable-next-line new-cap
  const draw = SVG().addTo('#root').size(rootElementWidth, rootElementHeight);
  // For resize
  draw.viewbox(0, 0, curvesKonva.width, curvesKonva.height);
  // For opacity
  draw
    .rect(curvesKonva.width - gridOffsetX, height - GRID_OFFSET_Y)
    .fill('#FFFFFF')
    .move(gridOffsetX, 0);

  const minX = -curvesKonva.curvesLayer.x();
  const maxX = curvesKonva.sizeConfig.initialWidth - curvesKonva.curvesLayer.x();
  const minY = -curvesKonva.curvesLayer.y();
  const maxY = curvesKonva.sizeConfig.initialHeight - curvesKonva.curvesLayer.y();
  const { scale } = curvesKonva;
  const layerChild1 = curvesKonva.curvesLayer.children ? curvesKonva.curvesLayer.children[0] : undefined;

  if (layerChild1) {
    const layerChildren = (layerChild1 as Group).getChildren() as unknown as Array<
      Layer & {
        curveShapeName: string;
        children: Array<Konva.Line>;
      }
    >;

    layerChildren.forEach((layerChild) => {
      switch (layerChild.curveShapeName) {
        case 'Grid':
          layerChild.children.forEach((gridLine1) => {
            const gridLine = gridLine1 as { attrs: IAttrsShape };
            const x1 = gridLine.attrs.points[0] + gridOffsetX;
            const x2 = gridLine.attrs.points[2] + gridOffsetX;
            const y1 = gridLine.attrs.points[1];
            const y2 = gridLine.attrs.points[3];
            // horizontal lines
            if (y1 >= minY && y2 <= maxY) {
              if (scale !== 1) {
                draw
                  .line(x1, y1 - minY, curvesKonva.width, y2 - minY)
                  .stroke({ color: gridLine.attrs.stroke, width: gridLine.attrs.strokeWidth });
              } else {
                draw.line(x1, y1, x2, y2).stroke({ color: gridLine.attrs.stroke, width: gridLine.attrs.strokeWidth });
              }
              // vertical lines
            } else if (x1 >= minX + gridOffsetX && x2 <= maxX + gridOffsetX) {
              if (scale !== 1) {
                draw
                  .line(x1 - minX, 0, x2 - minX, curvesKonva.height - 30)
                  .stroke({ color: gridLine.attrs.stroke, width: gridLine.attrs.strokeWidth });
              } else {
                draw.line(x1, y1, x2, y2).stroke({ color: gridLine.attrs.stroke, width: gridLine.attrs.strokeWidth });
              }
            }
          });
          break;
        case 'PeakPerspectiveLine': {
          const peakPerspectiveLine = layerChild.children[0] as { attrs: IAttrsShape };
          draw
            .polyline(addXOffset(peakPerspectiveLine.attrs.points, gridOffsetX, minX, minY))
            .stroke({
              color: layerChild.children[0].attrs.stroke,
              width: layerChild.children[0].attrs.strokeWidth,
              linecap: layerChild.children[0].attrs.lineCap,
              linejoin: layerChild.children[0].attrs.lineJoin,
              stroke: layerChild.children[0].attrs.stroke,
            } as StrokeData)
            .fill('none');
          break;
        }
        case 'Line':
          draw
            .polyline(addXOffset((layerChild as unknown as { attrs: IAttrsShape }).attrs.points, gridOffsetX, minX, minY))
            .stroke({
              color: layerChild.attrs.stroke,
              width: layerChild.attrs.strokeWidth,
              linecap: layerChild.attrs.lineCap,
              linejoin: layerChild.attrs.lineJoin,
            })
            .fill('none');
          break;
        case 'CurrentLine':
          draw
            .line(
              layerChild.attrs.points[0] + gridOffsetX - minX,
              0,
              layerChild.attrs.points[2] + gridOffsetX - minX,
              curvesKonva.height - GRID_OFFSET_Y
            )
            .stroke({
              color: layerChild.attrs.stroke,
              width: layerChild.attrs.strokeWidth,
              linecap: layerChild.attrs.lineCap,
              linejoin: layerChild.attrs.lineJoin,
            });
          break;
        case 'Hatch':
          layerChild.children.forEach((line, index) => {
            const points = addXOffset((line as unknown as { attrs: IAttrsShape }).attrs.points, gridOffsetX, minX, minY);
            let path = 'M';
            points.forEach((p, pIndex) => {
              if (pIndex % 2 === 0) {
                path += `${p}`;
              } else {
                path += ` ${p} `;
              }
            });
            if (index === 0) {
              draw
                .path(path)
                .stroke({ color: line.attrs.fill, linecap: line.attrs.lineCap, linejoin: line.attrs.lineJoin })
                .fill({ color: line.attrs.fill, opacity: line.attrs.opacity });
            } else {
              draw
                .path(path)
                .stroke({
                  width: line.attrs.strokeWidth,
                  color: line.attrs.fill,
                  linecap: line.attrs.lineCap,
                  linejoin: line.attrs.lineJoin,
                })
                .fill('none');
            }
          });
          break;
        case 'CurveLabel':
          drawLabel(draw, layerChild, minX, minY, false, printing);
          break;
        default:
          break;
      }
    });
  }

  // Rect & Lines around graph
  (curvesKonva.labelsLayer.children as unknown as Array<Konva.Line & { attrs: IAttrsShape }>).forEach((child) => {
    if (child.attrs && child.attrs.width && child.attrs.height && child.attrs.fill) {
      draw
        .rect(child.attrs.width as number, child.attrs.height as number)
        .fill(child.attrs.fill as string)
        .move(child.attrs.x as number, child.attrs.y as number);
    } else if (child.attrs && child.attrs.points && child.attrs.stroke && child.attrs.strokeWidth) {
      draw
        .line(
          (child.attrs.points[0] + (child.attrs.x as number)) as number,
          child.attrs.points[1] as number,
          (child.attrs.points[2] + child.attrs.x) as number,
          child.attrs.points[3] as number
        )
        .stroke({ color: child.attrs.stroke, width: child.attrs.strokeWidth });
    }
  });

  // Grid labels
  (curvesKonva.labelsLayerX.children as unknown as Array<Konva.Text>).forEach((label) => {
    if (label.attrs.x >= minX && label.attrs.x <= maxX + gridOffsetX) {
      draw
        .text((label as { attrs: IAttrsShape }).attrs.text)
        .move(label.attrs.x - minX, label.attrs.y as number)
        .font({ fill: label.attrs.fill, family: label.attrs.fontFamily, size: label.attrs.fontSize });
    }
  });
  (curvesKonva.labelsLayerY.children as unknown as Array<Konva.Text>).forEach((label) => {
    if (label.attrs.y >= minY && label.attrs.y <= maxY) {
      draw
        .text(label.attrs.text as string)
        .move(label.attrs.x as number, label.attrs.y - minY)
        .font({ fill: label.attrs.fill, family: label.attrs.fontFamily, size: label.attrs.fontSize });
    }
  });
  // Current labels
  (curvesKonva.currentLabelsLayerX.children as unknown as Array<CurrentLabel>).forEach((currentLabel) => {
    drawLabel(draw, currentLabel as unknown as Layer, minX, minY, true, printing);
  });

  // Curves legend
  const arrayOfChildren = curvesKonva.curvesLayer.children
    ? ((curvesKonva.curvesLayer.children[1] as Group).children as unknown as Array<Shape>)
    : new Array<Shape>();
  const legendRect = arrayOfChildren.find((child) => child.id() === 'LegendRect') as unknown as Rect;
  if (legendRect) {
    draw
      .rect(legendRect.width(), legendRect.height())
      .fill('#FFFFFF')
      .move(GRID_OFFSET_X + 5, 5);

    arrayOfChildren.forEach((layerChild) => {
      if (layerChild.name() === 'LegendLine') {
        const legendLine = layerChild as unknown as Konva.Line;
        draw
          .polyline(addXOffset(legendLine.attrs.points as number[], GRID_OFFSET_X, minX, minY))
          .stroke({
            color: legendLine.attrs.stroke,
            width: legendLine.attrs.strokeWidth,
            linecap: legendLine.attrs.lineCap,
            linejoin: legendLine.attrs.lineJoin,
            dasharray: legendLine.attrs.dash,
          })
          .fill('none');
      } else if (layerChild.name() === 'LegendLabel') {
        const legendLabel = layerChild as unknown as Konva.Label;
        const legendText = legendLabel.children ? legendLabel.children[0] : new Shape();
        draw
          .text(legendText.attrs.text as string)
          .move(legendLabel.attrs.x - minX + GRID_OFFSET_X, legendLabel.attrs.y - 5)
          .font({ fill: legendText.attrs.fill, family: legendText.attrs.fontFamily, size: legendText.attrs.fontSize });
      }
    });
  }

  const generatedSvg = draw.svg();
  draw.remove();
  return generatedSvg;
};

const drawLabel = (draw: Svg, layer: Layer, minX: number, minY: number, isCurrentLabel?: boolean, isPrint?: boolean) => {
  const gridXStart = isCurrentLabel ? 0 : gridOffsetX;

  (layer.children as unknown as Array<Layer>).forEach((label) => {
    const currentY = isCurrentLabel ? 0 : layer.attrs.y - minY;
    const fontSize = isPrint ? LABEL_FONT_SIZE_FOR_PRINT : label.attrs.fontSize;
    if (label.attrs.cornerRadius && label.attrs.width && label.attrs.height && layer.children) {
      const printFontOffset = isPrint
        ? LABEL_FONT_SIZE_FOR_PRINT / (layer.children[1] as { attrs: IAttrsShape }).attrs.fontSize
        : 1;
      draw
        .rect(label.attrs.width * printFontOffset, label.attrs.height * printFontOffset)
        .radius((label.attrs as IAttrsShape).cornerRadius)
        .fill({ color: label.attrs.fill, opacity: layer.attrs.opacity })
        .move(layer.attrs.x + gridXStart - minX, currentY);
    } else {
      draw
        .text(label.attrs.text as string)
        .font({
          fill: label.attrs.fill,
          fontFamily: label.attrs.fontFamily,
          size: fontSize,
          opacity: layer.attrs.opacity,
        })
        .move(layer.attrs.x + gridXStart + label.attrs.padding - minX, currentY);
    }
  });
};

const addXOffset = (points: Array<number>, xOffset: number, minX: number, minY: number): Array<number> => {
  const calculatedPoints = points.map((p, index) => {
    if (index === 0 || index % 2 === 0) {
      return p + xOffset - minX;
    }
    return p - minY;
  });
  return calculatedPoints;
};
