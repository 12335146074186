import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'BUSDUCT',
  objectType: EDeviceObjectType.Busway,
  deviceType: EDeviceType.BUSDUCT,
  Ib: 10,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Voltage: 400,
  Length: 3,
  dUCheckOn: false,
  LoadCurrent: 10,
  ComputedCosPhi: 0.9,
  Frequency: 50,
  Uref: 400,
  CableLength: 3,
  dUMax: 0,
  CableType: '',
  BuswaysInsulator: 5,
  Conductor: 'Al',
  AmbientTemperature: 30,
  LiveCrossSection: 1.5,
  NeutralCrossSection: 1.5,
  PECrossSection: 1.5,
  InstallationFactor: 1,
  temperatureFactor: 1,
  environmentFactor: 1,
  layoutFactor: 1,
  conduitFactor: 1,
  totalReductionFactor: 1,
  Iz: 18,
  LanguageId: 0,
  ConduitRow: '',
  ConduitColumn: '',
  BuswaysCarryingCapacityUserFactor: 1,
  CableCarryingCapacityUserFactor: 1,
  TemperatureFactor: 1,
  EnvironmentFactor: 1,
  LayoutFactor: 1,
  ConduitFactor: 1,
  TotalReductionFactor: 1,
  SuggestedList: [],
  BuswaysTypeList: [],
  BuswaysInsulatorList: [],
  BuswaysConductorList: [],
  BuswaysInstallationReferenceList: [],
  CalculationResultsList: {
    Iz: [],
    K2S2: [],
    Kt: 1,
    R20C: [],
    RSC: [],
    RWork: [],
    X: [],
    dU: [],
    dissipatedPower: [],
    phaseReactance: [],
    workingTemperature: [],
  },
  FunctionalEndurance: 'notManaged',
  IFEClass: 'E30',
  IFETemperature: 822,
  initialized: false,

  CableLengthIFE: 0,
  PELengthIFE: 0,
  DoubleInsulation: false,
};
