import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'TRAFO2',
  objectType: EDeviceObjectType.TransformerDeltaStarg,
  Locked: false,
  deviceType: EDeviceType.TRAFO2,

  Model: '',
  ProductDescription: '',
  Desrciption: '',
  trafoList: [],
  userList: [],
  importErrorList: [],
  importError: '',
  distributionSystemList: [],
  lineTypeList: [],
  DISTRIBUTION_SYSTEM_1_LIST: ['TN-S', 'TN-C', 'TT', 'IT'],
  COUPLING_0_LIST: ['Delta', 'Star'],
  COUPLING_1_LIST: ['Star-grounded'],
  PHASES_1_LIST: ['LLLN', 'LLL'],
  Sr: 100000,
  uRr: 2.05,
  ukr: 6,
  PrimaryWindingUn: 400,
  SecondaryWindingUn: 400,
  PrimaryWindingLineType: ELvLineType.LLL,
  SecondaryWindingLineType: ELvLineType.LLLN,
  PrimaryWindingEarthingSystem: EEarthingSystem.IT,
  SecondaryWindingEarthingSystem: EEarthingSystem.TNS,
  PrimaryWindingCouplingType: 'Delta',
  SecondaryWindingCouplingType: 'Star-grounded',
  GroundingResistance: 8,
  VoltageRegulatorPlug: 0,
  MaxContactVoltage: 50,
  TransformerSecondaryWindingNoLoadU: 400,

  lossesABS: 12000,
  plugUpperRange: 10,
  plugLowerRange: -10,
  plugStep: 1,
  TrafoPowerIncrease: 0,
  VectorGroup: 'Dyn 5',
  Po: 0.28,
};
