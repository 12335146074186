import { IGetRelationsDataResponse } from 'types/api/relations';
import { IScheme } from 'types/compute';
import ApiCalls from './ApiCalls';

export default class RelationsAPI {
  static async getRelationsData(requestBody: IScheme): Promise<IGetRelationsDataResponse> {
    const { data } = await ApiCalls.post<IGetRelationsDataResponse, IScheme>(`Relations`, requestBody);
    return data;
  }
}
