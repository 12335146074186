import { ELvLineType } from 'types/devices/enums.d';

export const getPh = (lineType: ELvLineType): number => {
  if (lineType === ELvLineType.LLLN || lineType === ELvLineType.LLL) {
    return 3;
  }
  if (
    lineType === ELvLineType.LLN ||
    lineType === ELvLineType.L1L2 ||
    lineType === ELvLineType.L2L3 ||
    lineType === ELvLineType.L1L3
  ) {
    return Math.sqrt(3);
  }
  return 1;
};

export const getVh = (lineType: ELvLineType): number => {
  if (lineType === ELvLineType.L3N || lineType === ELvLineType.L2N || lineType === ELvLineType.L1N) {
    return 1;
  }
  return Math.sqrt(3);
};

export const calculateUkr = (srValue: number): number => {
  let ukr = 4;
  if (srValue >= 500) {
    ukr = 6;
  }
  return ukr;
};
