import React, { FunctionComponent } from 'react';
import { ToggleSwitch } from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { ISwitch } from 'types/components/switch';

/**
 * Use this class to create switch element
 * @class Switch
 * @param {string} className - class name for select field
 * @param {label} label - text that is shown left side from select field
 * @param {string} name - name attribute of select
 * @param {func} onClick - Function that called when input is clicked
 * @param {bool} disabled - enables or disables input field
 * @param {array} value - array of values that is applied to value attribute in options field
 */
const Switch: FunctionComponent<ISwitch> = ({
  className,
  label,
  name,
  onChange,
  disabled,
  value,
  id,
  namespace,
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <ToggleSwitch
      id={id}
      value={value}
      label={label ? t(namespace ? `${namespace}::${label}` : label) : ''}
      showIcons={false}
      onChange={(newState) =>
        onChange
          ? onChange({
              id,
              value: newState,
              name,
              type: 'switch',
            })
          : null
      }
      className={className}
      disabled={disabled}
    />
  );
};

export default Switch;
