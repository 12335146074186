/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import * as ComputeNamespace from 'types/compute';
import { COMPUTE, PROJECT } from 'types/constants.d';
import { ComputeKnownAction } from 'types/known-actions';

export const initialState: ComputeNamespace.State = {
  saveProjectProperties: false,
  errors: [],
  errorsFiltered: {},
  data: [],
  warnings: [],
  notifications: [],
  docxdata: {},
  calculating: false,
  saveProject: false,

  saveRunning: false,
  computeRunning: false,
  computeCounter: 0,
  propagateRunning: false,
  propagateCounter: 0,
  onOffRunning: false,
  relationsRunning: false,
  requestQueue: [],
  deviceMessages: {},
  projectIsLoading: false,
  macroRequestIsRunning: false,
  coordinationRequestRunning: false,
  loadingMessage: '',
  loadingPercentage: 0,
  getMvMacroRequestRunning: false,
  cableRequestRunning: false,
  bomRequestIsRunning: false,
};

// TODO: assign proper action
// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: ComputeKnownAction): ComputeNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMPUTE.PUSH_NOTIFICATION:
        draft.notifications = [...draft.notifications, action.payload];
        break;
      case COMPUTE.SET_NOTIFICATIONS:
        draft.notifications = action.payload;
        break;
      case COMPUTE.SET_COMPUTE_RUNNING:
        draft.computeRunning = action.payload.running;
        draft.computeCounter = action.payload.running ? action.payload.counter ?? 0 : state.computeCounter;
        break;
      case COMPUTE.UPDATE_COMPUTE_PARAMS:
        return { ...state, ...action.payload };
      case COMPUTE.SET_PROJECT_LOADING:
        draft.projectIsLoading = action.payload.isLoading;
        break;
      case COMPUTE.UPDATE_LOAD_STATUS:
        draft.loadingMessage = action.payload.loadingMessage;
        draft.loadingPercentage = action.payload.loadingPercentage;
        break;
      case PROJECT.LOAD_PROJECT:
        return initialState;
      case COMPUTE.SET_PROPAGATE_RUNNING:
        draft.propagateRunning = action.payload.running;
        break;
      case COMPUTE.DEVICE_MESSAGES:
        draft.deviceMessages = action.payload.deviceMessages;
        break;
      case COMPUTE.SET_COORDINATIONREQUEST_RUNNING:
        draft.coordinationRequestRunning = action.payload.isRunning;
        break;
      default:
        return state;
    }
  });
