/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import { ICheckboxList } from 'types/components/checkbox-list';
import { useTranslation } from 'react-i18next';
import CheckBox from './CheckBox';
import { Grid, GridRow, GridColumn } from './grid';

const CheckBoxList: FunctionComponent<ICheckboxList> = ({
  label,
  tableList,
  checkBoxHandler,
  valuesStorage,
  obsoleteList,
  style,
  toolTip,
}): React.ReactElement => {
  const { t } = useTranslation();
  const tableLabel = () => {
    return (
      <Grid>
        <GridRow>
          <GridColumn className="object-properties-left-border label-checkbox-header">{t(label)}</GridColumn>
        </GridRow>
      </Grid>
    );
  };
  const color = (attr: string) => (style ? style[attr] : 'black');

  const tableBody = () => {
    return tableList.map((value, key) => (
      <GridRow key={key} className="labels-list-container">
        <GridColumn col={8}>
          <CheckBox
            className={`${color(value)}`}
            name={value}
            value={!valuesStorage.includes(value)}
            onChange={(e) => checkBoxHandler(e)}
            disabled={obsoleteList.includes(value)}
            label={t(value)}
            toolTip={obsoleteList.includes(value) ? toolTip : ''}
            sizeClass="small"
            monochrome={false}
          />
        </GridColumn>
      </GridRow>
    ));
  };

  return (
    <Grid>
      {tableLabel()}
      <div className="checkboxTableAccordion">{tableBody()}</div>
    </Grid>
  );
};

export default CheckBoxList;
