/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { USER } from 'types/constants.d';
import { UserKnownActions } from 'types/known-actions';
import * as UserNamespace from 'types/user';
import { countryCode, countryName, defaultLanguageCode, defaultLanguageName } from 'userCountries';

export const initialState: UserNamespace.State = {
  name: 'Fake',
  email: 'Fake@Email.com',
  isAuthenticated: false,
  userCountry: 'MOLDOVA',
  countryCode,
  countryName,
  defaultLanguageCode,
  defaultLanguageName,
  userInternalId: 1347,
  registrationDate: '',
  lastVisitDate: '',
  languageId: 2,
  language: 'en',
  View: {},
  macros: null,
  showDemo: false,
  macroIsSaving: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: UserKnownActions): UserNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER.AAD_LOGIN_SUCCESS:
        draft.name = action.payload.account.name;
        draft.email = action.payload.account.userName;
        draft.isAuthenticated = true;
        break;
      case USER.SAVE_USER_DATA:
        // TODO: Raven
        // if (Raven) {
        //   Raven.setUserContext({
        //     name: action.name,
        //     email: action.email,
        //   });
        // }
        draft.name = action.payload.name;
        draft.email = action.payload.email;
        draft.isAuthenticated = action.payload.isAuthenticated;
        break;
      case USER.SAVE_ABB_COUNTRIES:
        draft.countryCode = action.payload.countryCode;
        draft.countryName = action.payload.countryName;
        draft.defaultLanguageCode = action.payload.defaultLanguageCode;
        draft.defaultLanguageName = action.payload.defaultLanguageName;
        draft.userCountry = action.payload.userCountry;
        break;
      case USER.SAVE_CURRENT_USER_VIEW:
        draft.View = action.payload.View;
        break;
      case USER.UPDATE_USER_DATA:
        return { ...state, ...action.payload.data };
      case USER.SELECT_COUNTRY:
        draft.userCountry = action.payload.userCountry;
        break;
      case USER.SAVE_USER_INTERNAL_DATA:
        draft.userInternalId = action.payload.userInternalId;
        draft.registrationDate = action.payload.registrationDate;
        draft.lastVisitDate = action.payload.lastVisitDate;
        draft.name = action.payload.name;
        draft.showDemo = action.payload.showDemo;
        break;
      case USER.UPDATE_SHOW_DEMO_STATUS:
        draft.showDemo = action.payload.showDemo;
        break;
      case USER.UPDATE_USER_MACROS:
        draft.macros = action.payload.macros;
        break;
      default:
        break;
    }
  });
