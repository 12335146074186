/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { MODALS, WIZARD } from 'types/constants.d';
import { WizardKnownActions } from 'types/known-actions';
import { EPaperLayout } from 'types/project/store.d';
import { EPowerSupply } from 'types/standards/index.d';
import { Wizard } from 'types/wizard/store.d';
import { generatorDefaultState as generator } from 'types/devices/generator/initial-state';
import { defaultLvUtilityState as lvUtility } from 'types/devices/lvUtility/initial-state';
import { mvUtilityDefaultState as mvUtility } from 'types/devices/mvUtility/initial-state';
import { initialState as variables } from 'store/variables/store';
import _ from 'lodash';

const initialState: Wizard.State = {
  currentPage: 0,
  // Project info page
  projectName: 'New project',
  projectDescription: '',
  projectCustomer: '',
  pageLayoutSelection: EPaperLayout.Free,
  wizardDeviceId: '',
  // Standards page
  // TODO get the default from the server or user settings
  standards: {
    ScStandard: -1,
    CableStandard: -1,
    powerSupply: EPowerSupply.LVU,
    ScStandardList: [],
    CableStandardList: [],
  },

  // TODO: Add below
  labels: {},
  variables: {
    ...variables,
  },
  lvUtilitySettings: {
    ...lvUtility,
  } as Wizard.State['lvUtilitySettings'],
  mvUtilitySettings: {
    ...mvUtility,
  } as Wizard.State['mvUtilitySettings'],
  generatorSettings: {
    ...generator,
  } as Wizard.State['generatorSettings'],
  // lvCable: {
  //   ...lvCable,
  // },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: WizardKnownActions): Wizard.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case WIZARD.UPDATE_WIZARD_PARAMS:
        if (action.payload.category) {
          return {
            ...state,
            [action.payload.category]: {
              ...(state[action.payload.category] as Record<string, unknown>),
              ...action.payload.wizardObject,
            } as Record<string, unknown>,
          };
        }
        return { ...draft, ...action.payload.wizardObject };
      case MODALS.SHOW_MODAL:
        if (action.payload.name === 'Wizard') {
          return _.cloneDeep(initialState);
        }
        return state;
      // case WIZARD.UPDATE_ELEMENT_ERRORS: {
      //   if (state[action.id]) {
      //     const el = { ...state[action.id] };
      //     el.errors = { ...state[action.id].errors };
      //     if (!action.error) {
      //       delete el.errors[action.name];
      //     } else {
      //       el.errors[action.name] = action.error;
      //     }
      //     return {
      //       ...state,
      //       [action.id]: el,
      //     };
      //   }
      //   return state;
      // }
      case WIZARD.UPDATE_POWER_SUPPLY_ERRORS: {
        const errors = {
          ...(state[action.payload.category] as Record<string, Record<string, unknown>>).errors,
          [action.payload.name]: action.payload.value,
        } as Record<string, unknown>;
        if (!action.payload.value) {
          delete errors[action.payload.name];
        }
        return {
          ...state,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [action.payload.category]: {
            ...(state[action.payload.category] as Record<string, unknown>),
            errors: {
              ...errors,
            },
          },
        };
      }
      default:
        return state;
    }
  });
