import Konva from 'konva';
import { GRID_OFFSET_X, GRID_OFFSET_Y, DEFAULT_GRID_LABEL_CONFIG } from 'curves/Constants';
import Grid from './Grid';
import GridSizeConfig from './GridSizeConfig';

export default class GridLabels extends Konva.Group {
  constructor(
    config: { x?: number; y?: number },
    grid: Grid,
    sizeConfig: GridSizeConfig,
    width: number,
    height: number,
    horizontal = true
  ) {
    super(config);
    if (horizontal) {
      const d = sizeConfig.dx;
      grid.xGridValues.forEach((item) => {
        if (item.text) {
          const drawX = Math.floor(Math.log10(item.value) * d);
          const text = this.createText(drawX + GRID_OFFSET_X, height - GRID_OFFSET_Y + 5, item.text);
          text.x(text.x() - text.getTextWidth() / 2);
        }
      });
    } else {
      const d = sizeConfig.dy;
      grid.yGridValues.forEach((item) => {
        if (item.text) {
          const drawY = Math.floor(sizeConfig.height - Math.log10(item.value) * d);
          const text = this.createText(GRID_OFFSET_X - 5, drawY, item.text);
          text.x(text.x() - text.getTextWidth());
          text.y(text.y() - text.height() / 2);
        }
      });
    }
  }

  createText(x: number, y: number, textStr: string): Konva.Text {
    const text = new Konva.Text({
      ...DEFAULT_GRID_LABEL_CONFIG,
      x,
      y,
      text: textStr,
    });
    this.add(text);
    return text;
  }
}
