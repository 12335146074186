// @ts-nocheck
import React, { FunctionComponent } from 'react';
import Label from 'components/label';
import Combo from 'components/curves/Combo';
import Switch from 'components/generic/Switch';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { IOneDoubleCombo } from 'types/curves/oneDoubleCombo';

const OneDoubleCombo: FunctionComponent<IOneDoubleCombo> = ({
  title,
  bEnabled,
  on,
  options,
  value,
  onValueChange,
  onOnChange,
  curvesRequestIsRunning,
  onFocus,
}): React.ReactElement => {
  return (
    <Grid className="curve-function">
      <GridRow className="curve-function-row">
        {bEnabled === undefined ? (
          <GridColumn col={5}>
            <Label text={title} />
          </GridColumn>
        ) : null}
        {bEnabled !== undefined ? (
          <GridColumn col={3}>
            <Switch
              disabled={!bEnabled || curvesRequestIsRunning}
              value={on}
              textAlign="right"
              onChange={(event) => onOnChange(event.value)}
              label={title}
              name={title}
            />
          </GridColumn>
        ) : null}
      </GridRow>
      <GridRow className="curve-function-row">
        <Combo
          width={8}
          on={on}
          options={options}
          value={value}
          onChange={onValueChange}
          onFocus={onFocus}
          isString={false}
          curvesRequestIsRunning={curvesRequestIsRunning}
          title={title}
          editable
        />
      </GridRow>
    </Grid>
  );
};

export default OneDoubleCombo;
