import React, { FunctionComponent } from 'react';
import { IRadio } from 'types/components/radio';
// this is wrapping of standart radio button to use ABB style and give event consisting only from name and value
// maybe this now work better in new ABB/CommonUX????
const Radio: FunctionComponent<IRadio> = ({ value, name, id, checked, onChange, onFocus }): React.ReactElement => {
  return (
    <div className="ABB_CommonUX_RadioGroup__root ABB_CommonUX_RadioGroup__light ABB_CommonUX_RadioGroup__smallSize">
      <label className="device-table-radio-label">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={(event) =>
            onChange ? onChange({ name: event.target.name, value: event.target.value, type: 'radio' }) : null
          }
          onFocus={(event) => (onFocus ? onFocus({ name: event.target.name, value: event.target.value, type: 'radio' }) : null)}
        />
        <span />
      </label>
    </div>
  );
};
export default Radio;
