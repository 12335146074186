import { ECellType, EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  type: ECellType.BlockDevice,
  deviceType: EDeviceType.MV_SWITCHBOARD,
  symbol: '',
  SwitchBoardName: 'MvSwitchboard0',
  objectType: EDeviceObjectType.MvSwitchBoard,
  ObjectId: 'SW',
  FamilyList: ['UniSec', 'UniGear'],
  SwitchBoardFamily: '',
  MvPanelBreakingTechnology: 'Vacuum',
  MvPanelUr: 0,
  MvPanelIr: 0,
  MvPanelIcw: 0,
  MvTypeReadOnly: true,

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,

  errors: {},
};
