import CheckBox from 'components/generic/CheckBox';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import Select from 'components/generic/selectContainer';
import React from 'react'; // import React, {useState} from 'react'
import { useDispatch, useSelector } from 'store';
import { projectCommonData } from '../../../../constants';
import { onChange, TAB_NAME } from './actions';

const OptionsContainer: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const standards = useSelector((state) => state.standards);
  const project = useSelector((state) => state.project);
  const variables = useSelector((state) => state.variables);
  const compute = useSelector((state) => state.compute);
  const projectErrors = project.projectErrors[TAB_NAME] ? project.projectErrors[TAB_NAME] : {};
  const standardsField = (): React.ReactElement => {
    return (
      <GridRow>
        <GridColumn col={2}>
          <Select
            cols={[8, 7, 4]}
            className="info-project-input"
            name="ShortCircuitStandard"
            label="DOCWEB_MODAL_PROJECTPROPS_CALCULATIONSACCORDING"
            onChange={(e) => dispatch(onChange(e))}
            selectList={standards.ScStandardList}
            data={variables}
            plainObjectModel
            displayedAttribute="StandardIdString"
            valueAttribute="StandardIdString"
            disabled={compute.cableRequestRunning}
          />
        </GridColumn>
        <GridColumn col={2} offset={1}>
          <Select
            cols={[8, 7, 4]}
            className="info-project-input"
            name="CableStandard"
            label="DOCWEB_MODAL_PROJECTPROPS_CABLEDIMENTIONINGACCORDING"
            onChange={(e) => dispatch(onChange(e))}
            type="string"
            selectList={standards.CableStandardList}
            data={variables}
            plainObjectModel
            displayedAttribute="TranslateString"
            valueAttribute="StandardIdString"
            disabled={compute.cableRequestRunning}
          />
        </GridColumn>
      </GridRow>
    );
  };

  const optionsField = (): React.ReactElement => {
    return (
      <GridRow>
        <GridColumn col={8}>
          <CheckBox
            label="DOCWEB_PROJECTPROPS_VOLTAGEDROPBYUPSTREAM"
            name="VoltageDropByUpstream"
            value={variables.VoltageDropByUpstream}
            onChange={(e) => dispatch(onChange(e))}
          />
        </GridColumn>
        <GridColumn col={8}>
          <CheckBox
            label="STRINGS_IDC_CONSIDER_DERATING_CHK"
            name="UseDerating"
            value={variables.UseDerating}
            onChange={(e) => dispatch(onChange(e))}
          />
        </GridColumn>
        <GridColumn col={8}>
          <CheckBox
            name="ConsiderFuseDerating"
            label="STRINGS_IDC_CONSIDER_FUSE_DERATING_CHK"
            value={variables.ConsiderFuseDerating}
            onChange={(e) => dispatch(onChange(e))}
          />
        </GridColumn>
        <GridColumn col={8}>
          <CheckBox
            label="STRINGS_IDC_PATTERNTRAFO_CHECK"
            name="UseRatedCurrentToSizeBranches"
            value={variables.UseRatedCurrentToSizeBranches}
            onChange={(e) => dispatch(onChange(e))}
          />
        </GridColumn>
        <GridColumn col={8}>
          <CheckBox
            label="STRINGS_IDC_PHASEBALANCE_CHECK"
            name="LiveAutoBalance"
            value={variables.LiveAutoBalance}
            onChange={(e) => dispatch(onChange(e))}
          />
        </GridColumn>
        <GridColumn col={8}>
          <CheckBox
            label="STRINGS_IDC_DIMNEUTRAL_CHK"
            name="NeutralOptimization"
            value={variables.NeutralOptimization}
            onChange={(e) => dispatch(onChange(e))}
          />
        </GridColumn>
      </GridRow>
    );
  };

  const additionalOptionsField = (): React.ReactElement => {
    return (
      <GridRow>
        <GridColumn col={2}>
          <Select
            cols={[8, 4, 4]}
            className="info-project-input"
            name="PreserveConstantCurrrentLoads"
            label="STRINGS_NAME24"
            onChange={(e) => dispatch(onChange(e))}
            type="string"
            selectList={projectCommonData.loads}
            valueList={['true', 'false']}
            data={variables}
            disabled={false}
          />
        </GridColumn>
        <GridColumn col={2} offset={1}>
          <Select
            cols={[8, 4, 4]}
            className="info-project-input"
            name="ComputeAllScenarios"
            label="STRINGS_IDC_CONFIGURATION_STATIC"
            onChange={(e) => dispatch(onChange(e))}
            type="string"
            selectList={projectCommonData.scenarios}
            valueList={['true', 'false']}
            data={variables}
            disabled={false}
          />
        </GridColumn>
      </GridRow>
    );
  };

  const temperatureField = (): React.ReactElement => {
    return (
      <GridRow>
        <GridColumn col={2}>
          <Input
            type="number"
            // divStyle="options-select"
            onChange={(e) => dispatch(onChange(e))}
            onFocusOut={(e) => dispatch(onChange(e))}
            className="info-project-input"
            name="EnvironmentTemperature"
            label="STRINGS_IDC_ENVTEMP_TXT"
            data={variables}
            error={projectErrors.EnvironmentTemperature}
            displayError
            showIconInvalid
          />
        </GridColumn>
      </GridRow>
    );
  };

  return (
    <Grid>
      {standardsField()}
      <hr />
      {optionsField()}
      <hr />
      {additionalOptionsField()}
      <hr />
      {temperatureField()}
    </Grid>
  );
};

export default OptionsContainer;
