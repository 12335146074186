import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'components/label/Label.css';

const Label: FunctionComponent<{
  text: string | number;
  textAlign?: 'left' | 'center' | 'right';
}> = ({ text, textAlign = 'left' }): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign }}>
      <label className={styles.root}>{t(text.toString())}</label>
    </div>
  );
};

export default Label;
