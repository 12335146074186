import React, { FunctionComponent, useEffect } from 'react';
import { updateUserProject } from 'actions/user';
import { useDispatch, useSelector } from 'store';
import _ from 'lodash';
import { shallowEqual } from 'react-redux';
import { updateComputeParams } from 'compute/actions';

let queueTimeout: number;
const AutoSave: FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const modalName = useSelector((state) => state.modal.name);
  // const devices = JSON.stringify(useSelector((state) => state.devices, shallowEqual));
  const variables = useSelector((state) => state.variables, shallowEqual);
  const { propagateRunning, motorCoordinationIsRunning } = useSelector((state) => state.compute);
  const { projectErrors, id, dirty } = useSelector((state) => state.project);
  // const device = useSelector((state) => state.devices[selectedDeviceId ?? ''], shallowEqual);
  const devices = useSelector((state) => state.devices, shallowEqual);
  const { saveProject, saveRunning, getMvMacroRequestRunning, computeRunning } = useSelector((state) => state.compute);
  // useSelector((state) => state.project);
  const userId = useSelector((state) => state.user.userInternalId);

  const queueSave = () => {
    if (queueTimeout) {
      clearTimeout(queueTimeout);
      queueTimeout = 0;
    }

    // Forbid automatic save
    if (modalName) {
      return;
    }
    if (!id) {
      return;
    }
    if (userId === 0) {
      return;
    }

    if (projectErrors && projectErrors.nameError && _.isEmpty(projectErrors.nameError)) {
      return;
    }

    // TIMEOUT 30000
    queueTimeout = window.setTimeout(() => {
      // console.log('auto save project');
      if (saveProject) {
        dispatch(updateUserProject())
          .then(() => dispatch(updateComputeParams({ saveRunning: false })))
          .catch((error) => {
            console.error(error);
            dispatch(updateComputeParams({ saveRunning: false }));
          });
        dispatch(updateComputeParams({ saveProject: false, saveRunning: true }));
      }
      queueTimeout = 0;
    }, 3000);
  };

  useEffect(() => {
    if (
      saveProject &&
      !saveRunning &&
      !propagateRunning &&
      !motorCoordinationIsRunning &&
      !getMvMacroRequestRunning &&
      !computeRunning
    ) {
      queueSave();
    }
    if ((saveRunning || computeRunning) && queueTimeout !== 0) {
      clearTimeout(queueTimeout);
      queueTimeout = 0;
    }
  }, [devices, saveProject, saveRunning, dirty, propagateRunning, computeRunning, variables]);

  return <></>;
};

export default AutoSave;
