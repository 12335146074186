import React from 'react';
import ModalWindow from 'ui/modals/ModalWindow';
import DeviceFamilies from 'ui/modals/projectProperties/deviceFamilies/circuitBreakerFamiliesComponent';
import OtherDeviceFamilies from 'ui/modals/projectProperties/deviceFamilies/otherDevicesFamiliesComponent';
import Options from './options/container';
import Info from './info/container';
import ShortCircuit from './shortCircuit/component';
import ProtectionAgainstInderectContacts from './protectionAgainstInderectContacts/component';
import Footer from './footer';

const modal = (): React.ReactElement => (
  <ModalWindow
    tabs={{
      DOCWEB_STRING_OPTIONS: <Options />,
      DOCWEB_STRING_INFO: <Info />,
      STRING_CRITERIA11: <ShortCircuit />,
      DOCWEB_STRING_PROTECTION_AGAINST_CONTACT_IN_CASE_OF_FAULT: <ProtectionAgainstInderectContacts />,
      STRING_LVCB: <DeviceFamilies />,
      STRINGS_OTHERDEVICES: <OtherDeviceFamilies />,
    }}
    title={'PROJECT PROPERTIES'}
    footer={<Footer />}
  />
);

export default modal;
