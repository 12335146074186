/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

// import { introProjectInfoWizardPage } from 'tutorialIntro';

import { useDispatch, useSelector } from 'store';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { isMobileOnly } from 'react-device-detect';
import Input from 'components/generic/inputContainer';
import { updateWizardParams } from './actions';
import { checkProjectName } from './asyncActions';
import Settings from './Settings';
// import Standards from './Standards';

const ProjectInfo: FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const wizard = useSelector((state) => state.wizard);
  const user = useSelector((state) => state.user);
  // const variables = useSelector((state) => state.variables);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.showDemo) {
      // TODO: introProjectInfoWizardPage
      // dispatch(introProjectInfoWizardPage());
    }
  }, []);

  return (
    <Grid>
      <GridRow>
        <GridColumn col={isMobileOnly ? 8 : 3} id={'ProjectPage'}>
          <Input
            cols={[8, 0, 0, 0]}
            type={'text'}
            onChange={(e) => dispatch(updateWizardParams(e))}
            onFocusOut={(e) => dispatch(checkProjectName(e))}
            name={'projectName'}
            label={'DOCWEB_COMMONPROPS_NAME'}
            data={wizard}
            // errorDivStyle={'object-properties-error-message'}
            // errorInputStyle={'object-properties-input-field-error-field'}
            displayError={true}
            error={wizard.errors}
            disabled={wizard.sldProject}
          />
          <GridColumn col={3} style={{ height: '33px' }}>
            <label className="info-project-input-label padding-left-0">{t('STRING_DESCRIPTION')}</label>
          </GridColumn>
          <GridColumn col={8}>
            <textarea
              // type="text"
              style={{ width: '100%' }}
              name="projectDescription"
              onChange={(e) => dispatch(updateWizardParams(e))}
              className="info-project-input info-project-description-field"
            />
          </GridColumn>
          <GridColumn col={8}></GridColumn>
        </GridColumn>
        <GridColumn col={isMobileOnly ? 8 : 4} id={'Common Properties'} offset={isMobileOnly ? undefined : 1}>
          {/* {variables.MainStandard !== 'UL' ? <Standards /> : null} */}
          <Settings />
        </GridColumn>
      </GridRow>
      <GridColumn col={8}>
        <hr />
      </GridColumn>
      <GridColumn col={8}>
        <h4>{t('DOCWEB_STRING_MODAL_CUSTOMER')}</h4>
        <GridRow>
          <GridColumn col={3}>
            <Input
              cols={[3, 0, 0, 0]}
              type={'text'}
              onChange={(e) => dispatch(updateWizardParams(e))}
              name={'projectCustomer'}
              label={'STRINGS_CONFIGS_ID'}
              data={wizard}
              disabled={wizard.sldProject}
            />
          </GridColumn>
        </GridRow>
      </GridColumn>
    </Grid>
  );
};

export default ProjectInfo;
