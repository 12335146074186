import Konva from 'konva';
import { ECustomCurveOption } from 'types/curves/enums.d';
import PeakPerspectiveLine from 'curves/shapes/PeakPerspectiveLine';
import { ICustomCurve } from 'types/curves';
import { DEFAULT_CURVE_HATCH_CONFIG } from './Constants';
import Curves from './Curves';
import Line from './shapes/Line';
import { getCurveColor } from './actions/utils';

export const drawCustomCurve = (
  customCurveDevice: ICustomCurve,
  curvesGroup: Konva.Group,
  curvesColor: Record<string, string>,
  curvesInstance: Curves,
  curveId: string,
  curveDiagram?: string
): void => {
  let selectedDiagram = curveDiagram || customCurveDevice.selectedDiagram;
  if (selectedDiagram === 'CL') selectedDiagram = 'PEAK';

  if (selectedDiagram === 'PEAK') {
    if (window.miniCurves.curvesLayer.children &&
      !(window.miniCurves.curvesLayer.children[0] as Konva.Group).children?.some((child) => (child as PeakPerspectiveLine).curveShapeName === 'PeakLine')) {
      const peak = new PeakPerspectiveLine(curvesInstance.sizeConfig);
      curvesGroup.add(peak);
    }
  }

  if (!customCurveDevice.curves[selectedDiagram] || customCurveDevice.curves[selectedDiagram].points.length <= 1) {
    return;
  }

  const { points } = customCurveDevice.curves[selectedDiagram];
  const toAmpers = selectedDiagram === 'TFFF' || selectedDiagram === 'TFN';
  const logPoints = points.map((point) => ({ x: Math.log10(toAmpers ? point.x / 1000 : point.x), y: Math.log10(point.y) }));
  const curveColor = getCurveColor(curvesColor, curveId, true);

  if (customCurveDevice.option === ECustomCurveOption.Curve) {
    const line = new Line(
      logPoints,
      curvesInstance.sizeConfig,
      true,
      curveColor,
      curvesInstance.curvesLayer.x(),
      curvesInstance.curvesLayer.y()
    );
    curvesGroup.add(line);
  } else {
    // Full Line
    const lineYPoints = [
      // { x: logPoints[0].x, y: logPoints[0].y },
      { x: logPoints[0].x, y: curvesInstance.height },
    ];

    const lineXPoints = [
      // { x: logPoints.at(-1)?.x as number, y: logPoints.at(-1)?.y as number },
      { x: curvesInstance.width, y: logPoints.at(-1)?.y as number },
    ];

    const fullLine = [...lineYPoints, ...logPoints, ...lineXPoints];
    const line2 = new Line(
      fullLine,
      curvesInstance.sizeConfig,
      true,
      curveColor,
      curvesInstance.curvesLayer.x(),
      curvesInstance.curvesLayer.y()
    );
    curvesGroup.add(line2);

    let areaPoints: Array<{ x: number; y: number }> = [];
    if (customCurveDevice.option === ECustomCurveOption.AreaUp) {
      areaPoints = [
        { x: fullLine.at(-1)?.x as number, y: fullLine.at(-1)?.y as number },
        { x: fullLine.at(-1)?.x as number, y: curvesInstance.sizeConfig.height },
        { x: fullLine[0].x, y: curvesInstance.sizeConfig.height },
      ];
    } else {
      areaPoints = [
        {
          x: fullLine.at(-1)?.x as number,
          y: curvesInstance.sizeConfig.revertAdjustedPoint({
            x: fullLine.at(-1)?.x as number,
            y: curvesInstance.sizeConfig.height,
          }).y,
        },
        curvesInstance.sizeConfig.revertAdjustedPoint({ x: curvesInstance.curvesLayer.x(), y: curvesInstance.sizeConfig.height }),
        curvesInstance.sizeConfig.revertAdjustedPoint({ x: curvesInstance.curvesLayer.x(), y: 0 }),
        { x: fullLine[0].x, y: fullLine[0].y },
      ];
    }

    const hatch = curvesInstance.sizeConfig.adjustPoints(
      fullLine.concat(areaPoints),
      curvesInstance.curvesLayer.x(),
      curvesInstance.curvesLayer.y(),
      true
    );

    curvesGroup.add(
      new Konva.Line({
        ...DEFAULT_CURVE_HATCH_CONFIG,
        fill: curveColor,
        points: hatch,
      })
    );
  }
};
