export const countryCode = [
  'AF',
  'AL',
  'DZ',
  'AD',
  'AO',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BT',
  'BO',
  'BA',
  'BW',
  'BR',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CF',
  'TD',
  'CL',
  'CN',
  'CO',
  'CG',
  'CD',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FO',
  'FI',
  'FR',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GR',
  'GT',
  'GN',
  'GW',
  'GY',
  'HT',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MR',
  'MU',
  'MX',
  'MD',
  'MC',
  'MN',
  'ME',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NP',
  'NL',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NO',
  'OM',
  'PK',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RU',
  'RW',
  'MF',
  'SM',
  'SA',
  'SN',
  'RS',
  'SL',
  'SG',
  'SK',
  'SI',
  'SO',
  'ZA',
  'SS',
  'ES',
  'LK',
  'SD',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TT',
  'TN',
  'TR',
  'TM',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UY',
  'UZ',
  'VE',
  'VN',
  'YE',
  'ZM',
  'ZW',
];

export const countryName = [
  'AFGHANISTAN',
  'ALBANIA',
  'ALGERIA',
  'ANDORRA',
  'ANGOLA',
  'ARGENTINA',
  'ARMENIA',
  'ARUBA',
  'AUSTRALIA',
  'AUSTRIA',
  'AZERBAIJAN',
  'BAHAMAS',
  'BAHRAIN',
  'BANGLADESH',
  'BARBADOS',
  'BELARUS',
  'BELGIUM',
  'BELIZE',
  'BENIN',
  'BHUTAN',
  'BOLIVIA, PLURINATIONAL STATE OF',
  'BOSNIA AND HERZEGOVINA',
  'BOTSWANA',
  'BRAZIL',
  'BRUNEI DARUSSALAM',
  'BULGARIA',
  'BURKINA FASO',
  'BURUNDI',
  'CAMBODIA',
  'CAMEROON',
  'CANADA',
  'CENTRAL AFRICAN REPUBLIC',
  'CHAD',
  'CHILE',
  'CHINA',
  'COLOMBIA',
  'CONGO',
  'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  'COSTA RICA',
  "CÔTE D'IVOIRE",
  'CROATIA',
  'CUBA',
  'CURAÇAO',
  'CYPRUS',
  'CZECH REPUBLIC',
  'DENMARK',
  'DJIBOUTI',
  'DOMINICA',
  'DOMINICAN REPUBLIC',
  'ECUADOR',
  'EGYPT',
  'EL SALVADOR',
  'EQUATORIAL GUINEA',
  'ERITREA',
  'ESTONIA',
  'ETHIOPIA',
  'FAROE ISLANDS',
  'FINLAND',
  'FRANCE',
  'GABON',
  'GAMBIA',
  'GEORGIA',
  'GERMANY',
  'GHANA',
  'GREECE',
  'GUATEMALA',
  'GUINEA',
  'GUINEA-BISSAU',
  'GUYANA',
  'HAITI',
  'HOLY SEE (VATICAN CITY STATE)',
  'HONDURAS',
  'HONG KONG',
  'HUNGARY',
  'ICELAND',
  'INDIA',
  'INDONESIA',
  'IRAN, ISLAMIC REPUBLIC OF',
  'IRAQ',
  'IRELAND',
  'ISRAEL',
  'ITALY',
  'JAMAICA',
  'JAPAN',
  'JORDAN',
  'KAZAKHSTAN',
  'KENYA',
  "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  'KOREA, REPUBLIC OF',
  'KUWAIT',
  'KYRGYZSTAN',
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  'LATVIA',
  'LEBANON',
  'LESOTHO',
  'LIBERIA',
  'LIBYA',
  'LIECHTENSTEIN',
  'LITHUANIA',
  'LUXEMBOURG',
  'MACAO',
  'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  'MADAGASCAR',
  'MALAWI',
  'MALAYSIA',
  'MALDIVES',
  'MALI',
  'MALTA',
  'MAURITANIA',
  'MAURITIUS',
  'MEXICO',
  'MOLDOVA, REPUBLIC OF',
  'MONACO',
  'MONGOLIA',
  'MONTENEGRO',
  'MOROCCO',
  'MOZAMBIQUE',
  'MYANMAR',
  'NAMIBIA',
  'NEPAL',
  'NETHERLANDS',
  'NEW ZEALAND',
  'NICARAGUA',
  'NIGER',
  'NIGERIA',
  'NORWAY',
  'OMAN',
  'PAKISTAN',
  'PALESTINE, STATE OF',
  'PANAMA',
  'PAPUA NEW GUINEA',
  'PARAGUAY',
  'PERU',
  'PHILIPPINES',
  'POLAND',
  'PORTUGAL',
  'PUERTO RICO',
  'QATAR',
  'ROMANIA',
  'RUSSIAN FEDERATION',
  'RWANDA',
  'SAINT MARTIN (FRENCH PART)',
  'SAN MARINO',
  'SAUDI ARABIA',
  'SENEGAL',
  'SERBIA',
  'SIERRA LEONE',
  'SINGAPORE',
  'SLOVAKIA',
  'SLOVENIA',
  'SOMALIA',
  'SOUTH AFRICA',
  'SOUTH SUDAN',
  'SPAIN',
  'SRI LANKA',
  'SUDAN',
  'SWAZILAND',
  'SWEDEN',
  'SWITZERLAND',
  'SYRIAN ARAB REPUBLIC',
  'TAIWAN, PROVINCE OF CHINA',
  'TAJIKISTAN',
  'TANZANIA, UNITED REPUBLIC OF',
  'THAILAND',
  'TIMOR-LESTE',
  'TOGO',
  'TRINIDAD AND TOBAGO',
  'TUNISIA',
  'TURKEY',
  'TURKMENISTAN',
  'UGANDA',
  'UKRAINE',
  'UNITED ARAB EMIRATES',
  'UNITED KINGDOM',
  'UNITED STATES',
  'URUGUAY',
  'UZBEKISTAN',
  'VENEZUELA, BOLIVARIAN REPUBLIC OF',
  'VIET NAM',
  'YEMEN',
  'ZAMBIA',
  'ZIMBABWE',
];

export const defaultLanguageCode = [
  'af',
  'en',
  'dz',
  'en',
  'en',
  'ar',
  'am',
  'en',
  'en',
  'en',
  'az',
  'en',
  'bh',
  'en',
  'en',
  'en',
  'be',
  'en',
  'en',
  'en',
  'bo',
  'ba',
  'en',
  'br',
  'bn',
  'bg',
  'en',
  'bi',
  'en',
  'en',
  'ca',
  'en',
  'en',
  'en',
  'en',
  'co',
  'en',
  'en',
  'en',
  'en',
  'hr',
  'en',
  'en',
  'cy',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'sv',
  'en',
  'en',
  'en',
  'et',
  'fo',
  'fi',
  'fr',
  'ga',
  'en',
  'en',
  'de',
  'en',
  'en',
  'en',
  'gn',
  'en',
  'en',
  'ht',
  'en',
  'en',
  'en',
  'hu',
  'is',
  'en',
  'en',
  'en',
  'en',
  'ie',
  'en',
  'it',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'en',
  'la',
  'lv',
  'en',
  'en',
  'en',
  'en',
  'li',
  'lt',
  'en',
  'mo',
  'mk',
  'mg',
  'en',
  'my',
  'en',
  'ml',
  'mt',
  'mr',
  'en',
  'en',
  'en',
  'en',
  'mn',
  'en',
  'en',
  'en',
  'en',
  'na',
  'en',
  'nl',
  'en',
  'en',
  'ne',
  'en',
  'no',
  'om',
  'en',
  'ps',
  'pa',
  'en',
  'en',
  'en',
  'en',
  'pl',
  'pt',
  'en',
  'en',
  'ro',
  'ru',
  'rw',
  'en',
  'sm',
  'sa',
  'sn',
  'en',
  'sl',
  'sg',
  'sk',
  'si',
  'so',
  'en',
  'ss',
  'es',
  'en',
  'sd',
  'en',
  'en',
  'en',
  'en',
  'tw',
  'en',
  'en',
  'th',
  'tl',
  'tg',
  'tt',
  'tn',
  'tr',
  'en',
  'ug',
  'en',
  'en',
  'en',
  'en',
  'en',
  'uz',
  'en',
  'en',
  'en',
  'en',
  'en',
];

export const defaultLanguageName = [
  'Afrikaans',
  'English',
  'Bhutani',
  'English',
  'English',
  'Arabic',
  'Amharic',
  'English',
  'English',
  'English',
  'Azerbaijani',
  'English',
  'Bihari',
  'English',
  'English',
  'English',
  'Byelorussian (Belarusian)',
  'English',
  'English',
  'English',
  'Tibetan',
  'Bashkir',
  'English',
  'Breton',
  'Bengali (Bangla)',
  'Bulgarian',
  'English',
  'Bislama',
  'English',
  'English',
  'Catalan',
  'English',
  'English',
  'English',
  'English',
  'Corsican',
  'English',
  'English',
  'English',
  'English',
  'Croatian',
  'English',
  'English',
  'Welsh',
  'English',
  'English',
  'English',
  'English',
  'English',
  'English',
  'English',
  'Swedish',
  'English',
  'English',
  'English',
  'Estonian',
  'Faeroese',
  'Finnish',
  'French',
  'Irish',
  'English',
  'English',
  'German',
  'English',
  'English',
  'English',
  'Guarani',
  'English',
  'English',
  'Haitian Creole',
  'English',
  'English',
  'English',
  'Hungarian',
  'Icelandic',
  'English',
  'English',
  'English',
  'English',
  'Interlingue',
  'English',
  'Italian',
  'English',
  'English',
  'English',
  'English',
  'English',
  'English',
  'English',
  'English',
  'English',
  'Latin',
  'Latvian (Lettish)',
  'English',
  'English',
  'English',
  'English',
  'Limburgish ( Limburger)',
  'Lithuanian',
  'English',
  'Moldavian',
  'Macedonian',
  'Malagasy',
  'English',
  'Burmese',
  'English',
  'Malayalam',
  'Maltese',
  'Marathi',
  'English',
  'English',
  'English',
  'English',
  'Mongolian',
  'English',
  'English',
  'English',
  'English',
  'Nauru',
  'English',
  'Dutch',
  'English',
  'English',
  'Nepali',
  'English',
  'Norwegian',
  'Oromo (Afaan Oromo)',
  'English',
  'Pashto (Pushto)',
  'Punjabi',
  'English',
  'English',
  'English',
  'English',
  'Polish',
  'Portuguese',
  'English',
  'English',
  'Romanian',
  'Russian',
  'Kinyarwanda (Ruanda)',
  'English',
  'Samoan',
  'Sanskrit',
  'Shona',
  'English',
  'Slovenian',
  'Sangro',
  'Slovak',
  'Sinhalese',
  'Somali',
  'English',
  'Siswati',
  'Spanish',
  'English',
  'Sindhi',
  'English',
  'English',
  'English',
  'English',
  'Twi',
  'English',
  'English',
  'Thai',
  'Tagalog',
  'Tajik',
  'Tatar',
  'Setswana',
  'Turkish',
  'English',
  'Uighur',
  'English',
  'English',
  'English',
  'English',
  'English',
  'Uzbek',
  'English',
  'English',
  'English',
  'English',
  'English',
];
