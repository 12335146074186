/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { UNDO } from 'types/constants.d';
import { UndoKnownActions } from 'types/known-actions';
import * as UndoNamespace from 'types/undo';

export const initialState: UndoNamespace.State = {
  nextState: {},
  previousState: {},
};

// TODO: change action type to proper One
// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: UndoKnownActions): UndoNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case UNDO.ADD_NEXT_STATE:
        draft.nextState = action.payload.nextState;
        break;
      case UNDO.ADD_PREVIOUS_STATE:
        draft.previousState = action.payload.previousState;
        break;
      case UNDO.REMOVE_UNDO:
        draft.previousState = action.payload.previousState;
        draft.nextState = action.payload.nextState;
        break;
      default:
        break;
    }
  });
