import React from 'react';
// import PropTypes from 'prop-types';
// import {withTranslation} from 'react-i18next';

const Login = (): React.ReactElement => {
  return (
    <div className="background-image">
      <div className="modal-content login-page">
        <div className="modal-body">
          <hr />
          <h1>CURVESweb</h1>
          {/* <p>{this.props.t('drawing and calculating electrical plants')}</p> */}
          <div className="loader">{'Loading...'}</div>
        </div>
      </div>
    </div>
  );
};
// class Login extends React.Component {
//   static propTypes = {
//     // t: PropTypes.func.isRequired,
//   }
//   render() {
//     return (
//       <div className="background-image">
//         <div className="modal-content login-page">
//           <div className="modal-body">
//             <hr />
//             <h1>CURVESweb</h1>
//             {/* <p>{this.props.t('drawing and calculating electrical plants')}</p> */}
//             <div className="loader">{'Loading...'}</div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export default Login;
// export default withTranslation()(Login);
