import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'DEPARTURE',
  objectType: EDeviceObjectType.LinkOut,
  deviceType: EDeviceType.DEPARTURE,
  ports: [],
  LinkName: '0',
  UserDescription1: '',

  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
};
