// BEWARE - this is generated js source code, any manual changes will be lost when file gets regenerated
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
export default {
  STR_OL_WARNING_0: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_OL_WARNING_1: (pars) => {
    return (pars !== null && pars.length === 6 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number' && typeof pars[5] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2), pars[4].toFixed(2), pars[5].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%5.2f', '%5.2f', '%5.2f'];
  },
  STR_OL_WARNING_2: (pars) => {
    return (pars !== null && pars.length === 6 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number' && typeof pars[5] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2), pars[4].toFixed(2), pars[5].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%5.2f', '%5.2f', '%5.2f'];
  },
  STR_OL_WARNING_3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_OL_WARNING_4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_OL_WARNING_5: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_0: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_SC_WARNING_1: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'number')
      ? [pars[0], pars[1].toFixed(2)]
      : ['%s', '%5.2f'];
  },
  STR_SC_WARNING_2: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_SC_WARNING_4: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_5: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'number')
      ? [pars[0], pars[1].toFixed(2)]
      : ['%s', '%5.2f'];
  },
  STR_SC_WARNING_6: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_7: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_8: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_9: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_10: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_11: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_12: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_SC_WARNING_13: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'number')
      ? [pars[0], pars[1].toFixed(2)]
      : ['%s', '%5.2f'];
  },
  STR_SC_WARNING_14: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'number')
      ? [pars[0], pars[1].toFixed(2)]
      : ['%s', '%5.2f'];
  },
  STR_SC_WARNING_15: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(1), pars[2].toFixed(1)]
      : ['%s', '%5.1f', '%5.1f'];
  },
  STR_SC_WARNING_16: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_SC_WARNING_17: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_SC_WARNING_18: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_SC_WARNING_19: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(1), pars[2].toFixed(1)]
      : ['%s', '%5.1f', '%5.1f'];
  },
  STR_SC_WARNING_20: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_NDT_WARNING_0: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_NDT_WARNING_1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_NDT_WARNING_2: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(1)]
      : ['%s', '%5.2f', '%5.2f', '%3.1f'];
  },
  STR_NDT_WARNING_3: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_NDT_WARNING_4: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%2.2f', '%2.2f'];
  },
  STR_NDT_WARNING_5: (pars) => {
    return (pars !== null && pars.length === 5 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2), pars[4].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%2.2f', '%2.2f'];
  },
  STR_NDT_WARNING_6: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(1)]
      : ['%s', '%5.2f', '%5.2f', '%3.1f'];
  },
  STR_NDT_WARNING_7: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_NDT_WARNING_8: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%2.2f', '%2.2f'];
  },
  STR_NDT_WARNING_9: (pars) => {
    return (pars !== null && pars.length === 5 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2), pars[4].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%2.2f', '%2.2f'];
  },
  STR_NDT_WARNING_10: (pars) => {
    return (pars !== null && pars.length === 7 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number' && typeof pars[5] === 'number' && typeof pars[6] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(1), pars[3].toFixed(1), pars[4].toFixed(2), pars[5].toFixed(2), pars[6].toFixed(2)]
      : ['%s', '%5.2f', '%3.1f', '%3.1f', '%5.2f', '%5.2f', '%2.2f'];
  },
  STR_NDT_WARNING_11: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2), pars[3].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f', '%2.2f'];
  },
  STR_NDT_WARNING_12: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'number')
      ? [pars[0], pars[1].toFixed(2)]
      : ['%s', '%2.2f'];
  },
  STR_NDT_WARNING_13: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_NDT_WARNING_14: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_DISC_WARNING_0: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_DISC_WARNING_1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_DISC_WARNING_2: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_DISC_WARNING_3: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_DISC_WARNING_4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_DISC_WARNING_5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_BCK_WARNING_0: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_BCK_WARNING_1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_BCK_WARNING_2: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_BCK_WARNING_3: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_BCK_WARNING_4: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(2), pars[2].toFixed(2)]
      : ['%s', '%5.2f', '%5.2f'];
  },
  STR_DISC: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_DISCR: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_BCK: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STR_BCKR: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_PROGRESS_IN_LOADFLOW: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toString()]
      : ['%d'];
  },
  STRINGS_BREAKERDIM_MSG4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSEDIM_MSG1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_CONTDIM_MSG1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_CONTDIM_MSG2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_LF_WIRE_ITERATION_N: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'number')
      ? [pars[0], pars[1].toString()]
      : ['%s', '%d'];
  },
  STRINGS_PROPERTIES_CHECK_STRING4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_PROBLEM_WITH_TRAFO_WINDING_PART: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_PROBLEM_WITH_TRAFO_WINDING_WINDING: (pars) => {
    return (pars !== null && pars.length === 5 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4]]
      : ['%s', '%s', '%s', '%s', '%s'];
  },
  STRINGS_PROBLEM_WITH_MAXIMUM_POWER_PART: (pars) => {
    return (pars !== null && pars.length === 5 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number')
      ? [pars[0], (pars[1] < 1e-4 || pars[1] > 1e3 - 1e-9 ? pars[1].toExponential(3) : parseFloat(pars[1].toFixed(3 - (Math.abs(pars[1]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[1])) / Math.LN10 + 1e-9)))).toString()), (pars[2] < 1e-4 || pars[2] > 1e3 - 1e-9 ? pars[2].toExponential(3) : parseFloat(pars[2].toFixed(3 - (Math.abs(pars[2]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[2])) / Math.LN10 + 1e-9)))).toString()), (pars[3] < 1e-4 || pars[3] > 1e3 - 1e-9 ? pars[3].toExponential(3) : parseFloat(pars[3].toFixed(3 - (Math.abs(pars[3]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[3])) / Math.LN10 + 1e-9)))).toString()), (pars[4] < 1e-4 || pars[4] > 1e3 - 1e-9 ? pars[4].toExponential(3) : parseFloat(pars[4].toFixed(3 - (Math.abs(pars[4]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[4])) / Math.LN10 + 1e-9)))).toString())]
      : ['%s', '%.3g', '%.3g', '%.3g', '%.3g'];
  },
  STRINGS_PROBLEM_WITH_TRAFO_PART: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_INSULATOR_TRAFO_OFF_LIMITS: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(2), pars[1].toFixed(2)]
      : ['%.2lf', '%.2lf'];
  },
  STRINGS_PROBLEM_WITH_TRAFO3_WINDING: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'number' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0].toString(), (pars[1] < 1e-4 || pars[1] > 1e6 - 1e-9 ? pars[1].toExponential(6) : parseFloat(pars[1].toFixed(6 - (Math.abs(pars[1]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[1])) / Math.LN10 + 1e-9)))).toString()), (pars[2] < 1e-4 || pars[2] > 1e6 - 1e-9 ? pars[2].toExponential(6) : parseFloat(pars[2].toFixed(6 - (Math.abs(pars[2]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[2])) / Math.LN10 + 1e-9)))).toString())]
      : ['%d', '%g', '%g'];
  },
  STRINGS_PROBLEM_WITH_TRAFO2_WINDING: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [(pars[0] < 1e-4 || pars[0] > 1e6 - 1e-9 ? pars[0].toExponential(6) : parseFloat(pars[0].toFixed(6 - (Math.abs(pars[0]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[0])) / Math.LN10 + 1e-9)))).toString()), (pars[1] < 1e-4 || pars[1] > 1e6 - 1e-9 ? pars[1].toExponential(6) : parseFloat(pars[1].toFixed(6 - (Math.abs(pars[1]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[1])) / Math.LN10 + 1e-9)))).toString())]
      : ['%g', '%g'];
  },
  STRINGS_NOMCURR_PROBLEM_WITH_TRAFO3_WINDING: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'number' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0].toString(), (pars[1] < 1e-4 || pars[1] > 1e6 - 1e-9 ? pars[1].toExponential(6) : parseFloat(pars[1].toFixed(6 - (Math.abs(pars[1]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[1])) / Math.LN10 + 1e-9)))).toString()), (pars[2] < 1e-4 || pars[2] > 1e6 - 1e-9 ? pars[2].toExponential(6) : parseFloat(pars[2].toFixed(6 - (Math.abs(pars[2]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[2])) / Math.LN10 + 1e-9)))).toString())]
      : ['%d', '%g', '%g'];
  },
  STRINGS_NOMCURR_PROBLEM_WITH_TRAFO2_WINDING: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [(pars[0] < 1e-4 || pars[0] > 1e6 - 1e-9 ? pars[0].toExponential(6) : parseFloat(pars[0].toFixed(6 - (Math.abs(pars[0]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[0])) / Math.LN10 + 1e-9)))).toString()), (pars[1] < 1e-4 || pars[1] > 1e6 - 1e-9 ? pars[1].toExponential(6) : parseFloat(pars[1].toFixed(6 - (Math.abs(pars[1]) < 1e-9 ? 0 : Math.ceil(Math.log(Math.abs(pars[1])) / Math.LN10 + 1e-9)))).toString())]
      : ['%g', '%g'];
  },
  STRINGS_PROBLEM_WITH_NEGATIVE_POWER_PART: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_ERROR_MOTOR_PERIOD_NEGATIVE: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_ERROR_GENERATOR_DIVZERO: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_ERROR_GENERATOR_PERIOD_NEGATIVE: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER6: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER9: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER14: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0].toFixed(1), pars[1], pars[2]]
      : ['%.1lf', '%s', '%s'];
  },
  STRINGS_BREAKER_FILTER15: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0].toFixed(0), pars[1], pars[2]]
      : ['%.0lf', '%s', '%s'];
  },
  STRINGS_BREAKER_FILTER16: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER17: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER18: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(0)]
      : ['%.1lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER19: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER20: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER21: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER22: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER23: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0].toFixed(1), pars[1], pars[2]]
      : ['%.1lf', '%s', '%s'];
  },
  STRINGS_BREAKER_FILTER24: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(1)]
      : ['%.0lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER25: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER26: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER31: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER32: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER33: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER34: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_BREAKER_FILTER38: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER39: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER40: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER41: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER44: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER45: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER46: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER47: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER48: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER49: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER50: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER51: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_BREAKER_FILTER52: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'number')
      ? [pars[0].toFixed(1), pars[1], pars[2], pars[3].toFixed(1)]
      : ['%.1lf', '%s', '%s', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER53: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER54: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(2), pars[1].toFixed(2)]
      : ['%.2lf', '%.2lf'];
  },
  STRINGS_BREAKER_FILTER55: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER56: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_BREAKER_FILTER57: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER58: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_BREAKER_FILTER59: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER60: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER61: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER63: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER64: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_BREAKER_FILTER65: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_BREAKER_FILTER71: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER72: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER73: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER74: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_BREAKER_FILTER75: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER76: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER77: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER78: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_BREAKER_FILTER88: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER89: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_BREAKER_FILTER90: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER91: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_BREAKER_FILTER92: (pars) => {
    return (pars !== null && pars.length === 11 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'string' && typeof pars[5] === 'number' && typeof pars[6] === 'number' && typeof pars[7] === 'string' && typeof pars[8] === 'number' && typeof pars[9] === 'string' && typeof pars[10] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4], pars[5].toFixed(0), pars[6].toFixed(1), pars[7], pars[8].toFixed(1), pars[9], pars[10]]
      : ['%s', '%s', '%s', '%s', '%s', '%.0lf', '%.1lf', '%s', '%.1lf', '%s', '%s'];
  },
  STRINGS_BUSBAR_FILTER1: (pars) => {
    return (pars !== null && pars.length === 6 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'string' && typeof pars[5] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4], pars[5].toString()]
      : ['%s', '%s', '%s', '%s', '%s', '%d'];
  },
  STRINGS_BUSBAR_FILTER2: (pars) => {
    return (pars !== null && pars.length === 6 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'string' && typeof pars[5] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4], pars[5].toString()]
      : ['%s', '%s', '%s', '%s', '%s', '%d'];
  },
  STRINGS_BUSBAR_FILTER3: (pars) => {
    return (pars !== null && pars.length === 9 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'string' && typeof pars[5] === 'number' && typeof pars[6] === 'number' && typeof pars[7] === 'number' && typeof pars[8] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4], pars[5].toString(), pars[6].toFixed(0), pars[7].toFixed(0), pars[8].toFixed(0)]
      : ['%s', '%s', '%s', '%s', '%s', '%d', '%.0lf', '%.0lf', '%.0lf'];
  },
  STRINGS_BUSBAR_FILTER4: (pars) => {
    return (pars !== null && pars.length === 9 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'string' && typeof pars[5] === 'number' && typeof pars[6] === 'number' && typeof pars[7] === 'number' && typeof pars[8] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4], pars[5].toString(), pars[6].toFixed(0), pars[7].toFixed(0), pars[8].toFixed(0)]
      : ['%s', '%s', '%s', '%s', '%s', '%d', '%.0lf', '%.0lf', '%.0lf'];
  },
  STRINGS_FUSE_FILTER2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_FUSE_FILTER6: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_FUSE_FILTER9: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER10: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(1)]
      : ['%.0lf', '%.1lf'];
  },
  STRINGS_FUSE_FILTER11: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_FUSE_FILTER12: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_FUSE_FILTER13: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER14: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER15: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_FUSE_FILTER16: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_FUSE_FILTER17: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_FUSE_FILTER18: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_FUSE_FILTER19: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_FUSE_FILTER21: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(0)]
      : ['%.1lf', '%.0lf'];
  },
  STRINGS_FUSE_FILTER24: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER25: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER26: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER27: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER29: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER31: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_FUSE_FILTER32: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_FUSE_FILTER33: (pars) => {
    return (pars !== null && pars.length === 10 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string' && typeof pars[4] === 'number' && typeof pars[5] === 'number' && typeof pars[6] === 'string' && typeof pars[7] === 'number' && typeof pars[8] === 'string' && typeof pars[9] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3], pars[4].toFixed(0), pars[5].toFixed(1), pars[6], pars[7].toFixed(1), pars[8], pars[9]]
      : ['%s', '%s', '%s', '%s', '%.0lf', '%.1lf', '%s', '%.1lf', '%s', '%s'];
  },
  STRINGS_FUSE_FILTER34: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_CONTACTOR_FILTER1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_CONTACTOR_FILTER2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_CONTACTOR_FILTER3: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'number' && typeof pars[2] === 'number')
      ? [pars[0], pars[1].toFixed(0), pars[2].toFixed(0)]
      : ['%s', '%.0lf', '%.0lf'];
  },
  STRINGS_CONTACTOR_FILTER4: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_CONTACTOR_FILTER5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_CONTACTOR_FILTER6: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toString()]
      : ['%d'];
  },
  STRINGS_MOTOR_COORDINATION_FILTER8: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_MOTOR_COORDINATION_FILTER9: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MOTOR_COORDINATION_FILTER10: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MOTOR_COORDINATION_FILTER11: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_PRCR_FILTER2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_PRCR_FILTER3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_PRCR_FILTER4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_PRCR_FILTER5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(2)]
      : ['%.2lf'];
  },
  STRINGS_PRCR_FILTER6: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_PRCR_FILTER7: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_PRCR_FILTER8: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_DISCON_FILTER2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_DISCON_FILTER3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_DISCON_FILTER4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_DISCON_FILTER5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_DISCON_FILTER6: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_DISCON_FILTER7: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_TIME: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'string')
      ? [pars[0].toString(), pars[1]]
      : ['%lf', '%s'];
  },
  STRINGS_MVMACRODIM_MSG1_2: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_MVMACRODIM_MSG2_2: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_MVMACRO_FILTER1: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_MVMACRO_FILTER1_2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_MVMACRO_FILTER2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MVMACRO_FILTER2_2: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MVMACRO_FILTER3: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MVMACRO_FILTER4: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MVMACRO_FILTER5: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'string')
      ? [pars[0]]
      : ['%s'];
  },
  STRINGS_MVMACRO_FILTER6: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(1)]
      : ['%.1lf'];
  },
  STRINGS_MVMACRO_FILTER7: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toFixed(0)]
      : ['%.0lf'];
  },
  STRINGS_MVMACRO_FILTER8: (pars) => {
    return (pars !== null && pars.length === 5 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'number' && typeof pars[4] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3].toFixed(1), pars[4].toFixed(1)]
      : ['%s', '%s', '%s', '%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER9: (pars) => {
    return (pars !== null && pars.length === 6 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'number' && typeof pars[4] === 'number' && typeof pars[5] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3].toFixed(0), pars[4].toFixed(1), pars[5].toFixed(1)]
      : ['%s', '%s', '%s', '%.0lf', '%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER10: (pars) => {
    return (pars !== null && pars.length === 6 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'number' && typeof pars[4] === 'number' && typeof pars[5] === 'number')
      ? [pars[0], pars[1], pars[2], pars[3].toFixed(0), pars[4].toFixed(1), pars[5].toFixed(0)]
      : ['%s', '%s', '%s', '%.0lf', '%.1lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER11: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER12: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER13: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER14: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_MVMACRO_FILTER15: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER16: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER17: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER18: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER19: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER20: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER21: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER22: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER23: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER24: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER25: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER26: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER27: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER28: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER29: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER30: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER31: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER32: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER33: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER34: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER35: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER36: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER37: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER38: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER39: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER40: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'number')
      ? [pars[0].toFixed(1), pars[1], pars[2].toFixed(1)]
      : ['%.1lf', '%s', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER41: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'number' && typeof pars[3] === 'number')
      ? [pars[0].toFixed(0), pars[1], pars[2].toFixed(0), pars[3].toFixed(1)]
      : ['%.0lf', '%s', '%.0lf', '%.1lf'];
  },
  STRINGS_MVMACRO_FILTER42: (pars) => {
    return (pars !== null && pars.length === 5 && typeof pars[0] === 'number' && typeof pars[1] === 'string' && typeof pars[2] === 'number' && typeof pars[3] === 'number' && typeof pars[4] === 'number')
      ? [pars[0].toFixed(0), pars[1], pars[2].toFixed(0), pars[3].toFixed(1), pars[4].toFixed(0)]
      : ['%.0lf', '%s', '%.0lf', '%.1lf', '%.0lf'];
  },
  STRINGS_MVMACRO_FILTER43: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_MVMACRO_FILTER44: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_MVMACRO_FILTER45: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_MVMACRODIM_MSG3: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_MVMACRODIM_MSG4: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_TU_INCOMPATIBLE_SW: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3]]
      : ['%s', '%s', '%s', '%s'];
  },
  STRINGS_TU_FIRST_ONLY_ERROR: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_TU_LAST_ONLY_ERROR: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string')
      ? [pars[0], pars[1], pars[2]]
      : ['%s', '%s', '%s'];
  },
  STRINGS_TU_NO_CONNECTION_ERROR: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3]]
      : ['%s', '%s', '%s', '%s'];
  },
  STRINGS_TU_LEFT_OF_ERROR: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3]]
      : ['%s', '%s', '%s', '%s'];
  },
  STRINGS_TU_RIGHT_OF_ERROR: (pars) => {
    return (pars !== null && pars.length === 4 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'string' && typeof pars[3] === 'string')
      ? [pars[0], pars[1], pars[2], pars[3]]
      : ['%s', '%s', '%s', '%s'];
  },
  STRINGS_TU_WRONG_TECHNOLOGY: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRINGS_TU_WRONG_UR: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(1), pars[1].toFixed(1)]
      : ['%.1lf', '%.1lf'];
  },
  STRINGS_TU_WRONG_ICW: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'number' && typeof pars[1] === 'number')
      ? [pars[0].toFixed(0), pars[1].toFixed(0)]
      : ['%.0lf', '%.0lf'];
  },
  STRINGS_TU_INSUFFICIENT_UR: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'number')
      ? [pars[0], pars[1], pars[2].toFixed(1)]
      : ['%s', '%s', '%.1lf'];
  },
  STRINGS_TU_INSUFFICIENT_ICW: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'number')
      ? [pars[0], pars[1], pars[2].toFixed(0)]
      : ['%s', '%s', '%.0lf'];
  },
  STRINGS_TU_INSUFFICIENT_IR: (pars) => {
    return (pars !== null && pars.length === 3 && typeof pars[0] === 'string' && typeof pars[1] === 'string' && typeof pars[2] === 'number')
      ? [pars[0], pars[1], pars[2].toFixed(0)]
      : ['%s', '%s', '%.0lf'];
  },
  STRINGS_TU_INCOMPATIBLE: (pars) => {
    return (pars !== null && pars.length === 2 && typeof pars[0] === 'string' && typeof pars[1] === 'string')
      ? [pars[0], pars[1]]
      : ['%s', '%s'];
  },
  STRING_SHORTCIRCUIT16: (pars) => {
    return (pars !== null && pars.length === 1 && typeof pars[0] === 'number')
      ? [pars[0].toString()]
      : ['% o'];
  },
} as Record<string, (pars: Array<string | number | boolean>) => Array<string> | undefined>;
