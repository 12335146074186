import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'CONTACTOR',
  objectType: EDeviceObjectType.LvContactor,
  deviceType: EDeviceType.CONTACTOR,
  // General info
  Name: '',
  Description: '',
  Standard: 'IEC 60947-2',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400.0,
  loading: true,
  Locked: false,
  PreFiltersChanged: true,
  // Pre filters
  FamilyFilter: 'All possibilities',
  FamilyFilterList: [],

  PolesFilter: '3P',
  polesFilterList: ['3P'],

  ServiceFilter: 'AC-3',
  ServiceFilterList: [],

  // Manual filters
  familyOutput: 'All possibilities',
  familyOutputList: [],
  polesOutput: 'All possibilities',
  polesOutputList: [],
  ac1IeOutput: -1,
  ac1IeOutputList: [],
  ac3IeOutput: -1,
  ac3IeOutputList: [],
  unCoilOutput: 'All possibilities',
  unCoilOutputList: [],
  ContactorService: 'AC-3',

  DevicesList: [],
  DeviceId: -1,

  ProductDescription: '',
  ProductIdList: null,
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,
  requestRunning: false,
  IsOffInScenario: {
    name: [],
  },
  initialized: false,
};
