import TypesSymbols from 'components/shapes/TypesSymbols';
import DeviceProcessor from 'devices/device';
import { manageCoordinationCombo } from 'devices/protectedObjects/actions';
import { manageFilters } from 'filters/manager';
import { ICircuitBreaker } from 'types/devices/circuitBreaker';
import { AfterUpdateCallBackFunc, BeforeUpdateCallBackFunc, Device, PowerDevice, TDeviceEvent } from 'types/devices/device';
import { EDeviceType } from 'types/devices/enums.d';
import { IFuse } from 'types/devices/fuse';
import { ThunkAction } from 'types/known-actions';

export const onFocusOutHandler =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch) => {
    dispatch(DeviceProcessor.onFocusOutHandler(event, inputUpdateHandler, EDeviceType.FUSEBASE));
  };

export const inputUpdateHandler =
  (event: TDeviceEvent, selectedDeviceId?: string): ThunkAction<void> =>
  (dispatch) => {
    const manageFuseAdditionalData: BeforeUpdateCallBackFunc =
      (name, objValue, selectedDevice, additionalData, elementId, data) => () => {
        switch (name) {
          case 'TypeFilter': {
            if (data.value !== 'All possibilities') {
              Object.assign(additionalData, {
                objectType:
                  TypesSymbols.Symbol2Type[(data.value as string).toUpperCase() as keyof typeof TypesSymbols.Symbol2Type],
                symbol: (data.value as string).toUpperCase(),
              });
            }
            break;
          }
          default:
            break;
        }
      };
    const manageFuseRequest: AfterUpdateCallBackFunc = (name, value, selectedDevice, additionalData, deviceId) => () => {
      switch (name) {
        case 'fuseLinksOutput':
          dispatch(storeFuse(deviceId, name));
          break;
        case 'CoordinationFilter':
          // dispatch(manageCoordinationFilter(deviceId, value.toString()));
          dispatch(manageCoordinationCombo(selectedDevice as ICircuitBreaker['selectedDevice'], value.toString(), deviceId));
          break;
        case 'Name':
        case 'UserDescription1':
        case 'SwitchBoardName':
        case 'LineType':
        case 'EarthingSystem':
          break;
        case 'familyOutput':
        case 'ieOutput':
        case 'sizeOutput':
        case 'incOutput':
        case 'linksOutput':
        case 'inOutput':
        case 'DeviceId':
          dispatch(getFuseData(deviceId, name));
          break;
        default:
          dispatch(manageFilters(selectedDevice));
      }
    };
    dispatch(
      DeviceProcessor.inputUpdateHandler(
        event,
        EDeviceType.FUSEBASE,
        selectedDeviceId,
        manageFuseAdditionalData,
        manageFuseRequest
      )
    );
  };

export const getFuseData =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(DeviceProcessor.getDeviceFilters(deviceId, changed, EDeviceType.FUSEBASE, storeFuse));
  };

const storeFuse =
  (deviceId: string, changed: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const selectedDevice = getState().devices[deviceId] as IFuse['selectedDevice'];
    dispatch(DeviceProcessor.storeDevice(deviceId, changed, EDeviceType.FUSEBASE, selectedDevice.fuseLinksOutput.toString()));
  };

export const getDeviceWishes =
  (deviceData: Device<PowerDevice>, deviceId: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(DeviceProcessor.getDeviceWishes(deviceData, EDeviceType.FUSEBASE, deviceId));
  };
