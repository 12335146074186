import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageDialog, MessageDialogProps } from '@abb/abb-common-ux-react';
import { useDispatch } from 'store';
import { showModalDialog } from './actions';

export const MessageDialogWindow: React.FunctionComponent<MessageDialogProps> = ({
  buttons,
  message,
  title,
  isOpen,
  onOk,
  onConfirm,
  onYes,
  onNo,
  onCancel,
  onClose,
}): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <MessageDialog
      className="ABB_Message_Dialog_Center DOCWEB_Message_Dialog_Pre_Format_Text"
      buttons={buttons}
      message={message ? t(message) : ''}
      title={title ? t(title) : ''}
      onExit={() => dispatch(showModalDialog(undefined))}
      isOpen={isOpen}
      onOk={onOk}
      onConfirm={onConfirm}
      onYes={onYes}
      onNo={onNo}
      onCancel={onCancel}
      onClose={onClose}
      // dimBackground
    />
  );
};

export default MessageDialogWindow;
