import React from 'react';
import { Icon } from '@abb/abb-common-ux-react';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { generateDataSource, inputFocusOutHandler, inputUpdateHandler, restoreToDefaults, TAB_NAME } from './actions';

const ProtectionAgainstIndirectContacts: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const variables = useSelector((state) => state.variables);
  const data = generateDataSource(variables);
  const projectErrors = project.projectErrors[TAB_NAME] ? project.projectErrors[TAB_NAME] : {};

  const iconForDefaults = (): React.ReactElement => {
    return (
      <Grid>
        <GridRow verticalAlignment="bottom">
          <GridColumn horizontalAlignment="end">
            <Icon
              name="abb/refresh"
              className="icon-protection-indirect-col-center"
              sizeClass="large"
              onClick={() => dispatch(restoreToDefaults())}
            />
            <label style={{ marginLeft: '8px' }}>{t('STRINGS_IDC_MTT_DEFAULTS_TXT')}</label>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  const contactVoltageAndRa = (): React.ReactElement => {
    return (
      <GridRow style={{ marginTop: '5px' }}>
        <GridColumn col={4}>
          <Input
            cols={[5, 0]}
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
            row={false}
            type="number"
            // divStyle="options-select"
            className="info-project-input"
            name="MaxContactVoltage"
            label="DOCWEB_MODAL_PROTECTION_CONTACT_VOLTAGE_MAX"
            data={variables}
            disabled={variables.ShortCircuitStandard === 'VDE 0102'}
            // disabled={variables.ScStandard == 2} // VDE 0102
            // TODO: InputErrorValidation check this
            // TODO: InputErrorValidation check this
            error={projectErrors.MaxContactVoltage}
            displayError
            // errorDivStyle="object-properties-error-message"
            // errorInputStyle="object-properties-input-field-error-field"
            showIconInvalid
          />
        </GridColumn>
        <GridColumn col={4}>
          <Input
            cols={[5, 0]}
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
            row={false}
            type="number"
            // divStyle="options-select"
            className="info-project-input"
            name="PlantGroundResistance"
            label="DOCWEB_MODAL_RA"
            data={variables}
            disabled={variables.ShortCircuitStandard === 'VDE 0102'}
            // disabled={variables.ScStandard == 2} VDE 0102
            // TODO: InputErrorValidation check this
            // TODO: InputErrorValidation check this
            error={projectErrors.PlantGroundResistance}
            displayError
            // errorDivStyle="object-properties-error-message"
            // errorInputStyle="object-properties-input-field-error-field"
            showIconInvalid
          />
        </GridColumn>
      </GridRow>
    );
  };

  const systemTN = (): React.ReactElement => {
    return (
      <GridRow style={{ marginTop: '5px' }}>
        <GridColumn col={2} verticalAlignment="middle">
          <label>{t('STRINGS_IDC_SYSTEM_TN_TXT')}</label>
        </GridColumn>
        <GridColumn>
          <GridRow>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTNUpTo120"
                label="STRINGS_IDC_MTT_HEAD1_TXT"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.1}
                showIconInvalid
                // TODO: InputErrorValidation check this
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTNUpTo120}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
              />
            </GridColumn>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                showIconInvalid
                type="number"
                className="info-project-input"
                name="FinalTNUpTo240"
                label="STRINGS_IDC_MTT_HEAD2_TXT"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.1}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTNUpTo240}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
              />
            </GridColumn>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTNUpTo400"
                label="STRINGS_IDC_MTT_HEAD3_TXT"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.01}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTNUpTo400}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTNOver400"
                label="STRINGS_IDC_MTT_HEAD4_TXT"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.001}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTNOver400}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridRow>
    );
  };

  const systemTT = (): React.ReactElement => {
    return (
      <GridRow style={{ marginTop: '5px' }}>
        <GridColumn col={2} verticalAlignment="middle">
          <label>{t('STRINGS_IDC_SYSTEM_TT_TXT')}</label>
        </GridColumn>
        <GridColumn>
          <GridRow>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTTUpTo120"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.1}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTTUpTo120}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTTUpTo240"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.1}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTTUpTo240}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTTUpTo400"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.001}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTTUpTo400}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="FinalTTOver400"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                step={0.001}
                // TODO: InputErrorValidation check this
                error={projectErrors.FinalTTOver400}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridRow>
    );
  };

  const otherCircuitsTN = (): React.ReactElement => {
    return (
      <GridRow style={{ marginTop: '5px' }}>
        <GridColumn col={2} verticalAlignment="middle">
          <label>{t('STRINGS_IDC_SYSTEM_TN_TXT')}</label>
        </GridColumn>
        <GridColumn>
          <GridRow>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                type="number"
                className="info-project-input"
                name="NotFinalTN"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                // TODO: InputErrorValidation check this
                error={projectErrors.NotFinalTN}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridRow>
    );
  };

  const otherCircuitsTT = (): React.ReactElement => {
    return (
      <GridRow style={{ marginTop: '5px' }}>
        <GridColumn col={2} verticalAlignment="middle">
          <label>{t('STRINGS_IDC_SYSTEM_TT_TXT')}</label>
        </GridColumn>
        <GridColumn>
          <GridRow>
            <GridColumn col={2} className="padding-left-5">
              <Input
                onChange={(e) => dispatch(inputUpdateHandler(e))}
                onFocusOut={(e) => dispatch(inputFocusOutHandler(e))}
                step={0.01}
                type="number"
                className="info-project-input"
                name="NotFinalTT"
                data={data}
                disabled={variables.ShortCircuitStandard === 'VDE 0102'}
                // TODO: InputErrorValidation check this
                // TODO: InputErrorValidation check this
                error={projectErrors.NotFinalTT}
                displayError
                // errorDivStyle="object-properties-error-message"
                // errorInputStyle="object-properties-input-field-error-field"
                showIconInvalid
              />
            </GridColumn>
            <GridColumn col={2} offset={3}>
              {iconForDefaults()}
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridRow>
    );
  };

  // TODO: Error Validation check this
  return (
    <Grid>
      {contactVoltageAndRa()}
      <div style={{ paddingTop: '40px' }}>
        <label>{t('DOCWEB_MODAL_PROTECTION_DISCONNECION_TIME_MAX')}</label>
        <hr className="DOCweb_hr_protectionAgainstIndirect" />
      </div>
      <div style={{ paddingTop: '15px' }}>
        <label>{t('STRINGS_IDC_FINAL_32_TXT')}</label>
      </div>
      {systemTN()}
      {systemTT()}
      {otherCircuitsTN()}
      {otherCircuitsTT()}
    </Grid>
  );
};

export default ProtectionAgainstIndirectContacts;
