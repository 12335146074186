import { ECellType, EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  type: ECellType.BusBar,
  symbol: 'BUSBAR',
  objectType: EDeviceObjectType.Busbar,
  deviceType: EDeviceType.BUSBAR,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.IT,
  ports: [],
  Uref: 400.0,
  DemandFactor: 1,
};
