import { Device, PlainPoint } from 'types/devices/device';

export const generateSymbols = (scheme: Record<string, Device>): Record<string, Array<{ x: number; y: number }>> => {
  const symbols: Record<string, Array<{ x: number; y: number }>> = {};
  Object.keys(scheme).forEach((key) => {
    const symbolName = scheme[key].symbol as keyof typeof Symbols;
    if (symbolName && Symbols[symbolName]) {
      if (!(symbolName in symbols)) {
        symbols[symbolName] = [];
        const terms = Symbols[symbolName];
        terms.forEach((term) => {
          symbols[symbolName].push(term);
        });
      }
    }
  });
  return symbols;
};

export const Symbols = {
  ATS: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 10,
      y: 0,
    },
    {
      x: 5,
      y: 40,
    },
  ],
  ARRIVAL: [
    {
      x: 0,
      y: 0,
    },
  ],
  BDUCTLOAD: [
    {
      x: 0,
      y: 0,
    },
  ],
  BOARD: [],
  BUSBAR: [],
  BUSDUCT: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CAPACITORBANKS: [
    {
      x: 0,
      y: 0,
    },
  ],
  CHARGER_LOAD: [
    {
      x: 0,
      y: 0,
    },
  ],
  CIRCUITBREAKERLV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CIRCUITBREAKERMV_DISC: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -5,
      y: 45,
    },
  ],
  CIRCUITBREAKERMV_GEN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CIRCUITBREAKERMV_SMALL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CIRCUITBREAKERMV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CIRCUITBREAKERMVW_PSMALL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CIRCUITBREAKERMVW_SMALL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CIRCUITBREAKERMVW: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CM: [
    {
      x: 0,
      y: 0,
    },
  ],
  COMBISENSOR: [
    {
      x: 0,
      y: 0,
    },
  ],
  CONTACTOR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CONTACTOR_REV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CONTACTOR_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CONTACTOR_STAR_DELTA_INV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  DEPARTURE: [
    {
      x: 0,
      y: 0,
    },
  ],
  DISCONNECTORLV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  DISCONNECTORLVW: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  DISCONNECTORMV_A: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  DISCONNECTORMV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -3.75,
      y: 30,
    },
  ],
  DISCONNECTOR_FUSE__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  DISCONNECTOR_FUSE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  DRIVEANDMOTOR: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_AMPPERTA_1F: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_AMPPERTA_3F: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_AMP_1F: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_AMP_3F: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_CHANGEOVER: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_COMM_A: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  DT_COMM_V: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  DT_COSF: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_DISC: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  DT_ENERMETER_1F: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_ENERMETER_3F: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_ENERMETER_3F_PERTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 12.5,
      y: 15,
    },
  ],
  DT_ENERMETER_UTF: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -17.5,
      y: -15,
    },
  ],
  DT_FREQ: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_FUSE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 15,
    },
  ],
  DT_INT_ORA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 25,
    },
  ],
  DT_LAMP_SMALL: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_LAMP: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_LIGHTSWITCH: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 25,
    },
  ],
  DT_MULT: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 12.5,
      y: 15,
    },
  ],
  DT_OVR: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_PUSH: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_RELPASPAS: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 25,
    },
  ],
  DT_TA_1F: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -5,
      y: 0,
    },
  ],
  DT_TA_3F: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -5,
      y: 0,
    },
  ],
  DT_TRAFO_AUX: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_VOLT: [
    {
      x: 0,
      y: 0,
    },
  ],
  DT_VOLT_1F: [
    {
      x: 0,
      y: 0,
    },
  ],
  FUSEBASE__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  FUSEBASE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  FUSELV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  FUSEMV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  FUSE_DISCONNECTOR__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  FUSE_DISCONNECTOR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  FUSE_SWITCH__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  FUSE_SWITCH: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  FUSE_SWITCH_DISCONNECTOR__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  FUSE_SWITCH_DISCONNECTOR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  GENERATOR: [
    {
      x: 85,
      y: 40,
    },
  ],
  GENERATOR_BIO: [
    {
      x: 0,
      y: 0,
    },
  ],
  GENERATOR_PHOTO: [
    {
      x: 0,
      y: 0,
    },
  ],
  GENERATOR_SMALL: [
    {
      x: 0,
      y: 0,
    },
  ],
  GENERATOR_WIND: [
    {
      x: 0,
      y: 0,
    },
  ],
  GENERICWRELAY: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  HEATER_LOAD: [
    {
      x: 0,
      y: 0,
    },
  ],
  IMPEDANCE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  LIGHTS: [
    {
      x: 0,
      y: 0,
    },
  ],
  LOAD_ARROW: [
    {
      x: 0,
      y: 0,
    },
  ],
  LOAD_PHANTOM: [
    {
      x: 0,
      y: 0,
    },
  ],
  LOAD_TRAFO: [
    {
      x: 0,
      y: 0,
    },
  ],
  LOAD: [
    {
      x: 0,
      y: 0,
    },
  ],
  CB_MO: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_MO_W: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_MORCTO: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_MORC: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_MORCTO_W: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_MORC_W: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  MCU: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  MMS: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  MMSMO: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  MMSTM: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  MOTOR: [
    {
      x: 0,
      y: 0,
    },
  ],
  MOTORDOL: [
    {
      x: 0,
      y: 0,
    },
  ],
  MOTORYD: [
    {
      x: 0,
      y: 0,
    },
  ],
  MOTOR_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
  ],
  MULTIMETER: [
    {
      x: 0,
      y: 0,
    },
  ],
  MVDISC_DOWN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  MVDISC_GEN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  MVDISC_SAFERING: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  MVDISC: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -5,
      y: 30,
    },
  ],
  MVDISCFUSE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 60,
    },
  ],
  MVEDISC: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  MVSWITCH_ROTATED: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 5,
      y: 30,
    },
  ],
  MVSWITCH: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -5,
      y: 30,
    },
  ],
  MVSWITCHFUSE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 60,
    },
  ],
  MVVT: [
    {
      x: 0,
      y: 0,
    },
  ],
  MWIRE: [
    {
      x: 0,
      y: 0,
    },
  ],
  NC1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  OLR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  OLR_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  OLR_STAR_DELTA_INV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 10,
    },
  ],
  PARTITOREV: [
    {
      x: 0,
      y: 0,
    },
  ],
  PUREREZCURRRELAY__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  RCCB: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  RCDISCONNECTOR: [],
  ROGOWSKI: [
    {
      x: 0,
      y: 0,
    },
  ],
  SOFTSTARTER: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SOFTSTARTERANDMOTOR: [
    {
      x: 0,
      y: 0,
    },
  ],
  SOFTSTARTER_OLR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SOFTSTARTER_OLR_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SOFTSTARTER_OLR_STAR_DELTA2: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SOFTSTARTER_PURE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SOFTSTARTER_PURE_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SOFTSTARTER_PURE_STAR_DELTA2: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  SWITCH__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  SWITCH: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  SWITCH_DISCONNECTOR__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  SWITCH_DISCONNECTOR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  SWITCH_DISCONNECTOR_FUSE__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  SWITCH_DISCONNECTOR_FUSE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  SWITCH_FUSE__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  SWITCH_FUSE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  TA: [
    {
      x: 0,
      y: 0,
    },
  ],
  THERMOMAGNETICLSIRELAY__S1: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 30,
    },
  ],
  CB_TM: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_TM_W: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_TMRCTO: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_TMRC: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_TMRCTO_W: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  CB_TMRC_W: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  TO: [
    {
      x: 0,
      y: 0,
    },
  ],
  TRAFO2: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_11: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_11_INSUL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_12: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_13: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_13_INSUL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_21: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_22: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_23: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_31: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_32: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_33: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_44: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_44_INSUL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_45: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_54: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO2_55: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  TRAFO3: [
    {
      x: 0,
      y: 0,
    },
    {
      x: -5,
      y: 20,
    },
    {
      x: 5,
      y: 20,
    },
  ],
  TV: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_BREAKER_MOTOR: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_FUSE_MOTOR: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_BREAKER_LOAD: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_FUSE_LOAD: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_BREAKER_HEATER: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_FUSE_HEATER: [
    {
      x: 0,
      y: 0,
    },
  ],
  TYPICAL_FEEDER: [
    {
      x: 0,
      y: 0,
    },
  ],
  UPS: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  UPS_2_INPUT: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 20,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  UPS_BYPASS: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  UPS_NOBYPASS: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  UTILITY_MV_SHORT: [
    {
      x: 25,
      y: 35,
    },
  ],
  UTILITY: [
    {
      x: 85,
      y: 0,
    },
  ],
  UTILITY_1TRAFO: [
    {
      x: 85,
      y: 0,
    },
  ],
  UTILITY_2TRAFO: [
    {
      x: 85,
      y: 0,
    },
  ],
  UTILITY_3TRAFO: [
    {
      x: 85,
      y: 0,
    },
  ],
  UTILITY_MV: [
    {
      x: 75,
      y: 140,
    },
  ],
  UTILITY_MV_SMALL: [
    {
      x: 30,
      y: 30,
    },
  ],
  UTILITY_SMALL: [
    {
      x: 30,
      y: 30,
    },
  ],
  VSD_PURE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  VSD_OLR: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LLL: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LLLN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LLLNPE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LLLPE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LLLPEN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  WIREFFF_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  WIREFFFPE_STAR_DELTA: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LLPE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LNPE: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  CABLE_LPEN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  WIRELV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  WIREMV_IN: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  WIREMV_OUT: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
  WIREMV_PLUG: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 40,
    },
  ],
  WIREMV: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 0,
      y: 20,
    },
  ],
} as {
  [key: string]: PlainPoint[];
};
