/* eslint-disable no-param-reassign */
import { batch } from 'react-redux';
import translations from 'translations/DOC-en';
import i18next from 'i18next';
import { updateElementParams } from 'devices/actions';
import { uuid } from 'actions/utils';
import { IJsonOutputMessage, IMessage, IParsedMessages } from 'types/project/messages';
import { Device } from 'types/devices/device';
import { IJsonOutput } from 'types/compute';
import { ThunkAction } from 'types/known-actions';
import _ from 'lodash';
import { getObjectSign } from 'store/devices/utils';
// eslint-disable-next-line import/no-cycle
import { projectActions } from '.';

export const SET_PROJECT_MESSAGES = 'SET_PROJECT_MESSAGES';

const setProjectMessages = (category: string, message: Record<string, IMessage> | Array<IMessage>) =>
  projectActions.setProjectMessages({
    category,
    message,
  });

// Convert messages to make them expandable

const convertMessages = (messages: Array<IMessage>, object: Record<string, IMessage>, type = '') => {
  messages.forEach((message) => {
    if (
      (!message.child && object[message.id] === undefined) ||
      (message.child &&
        object[message.id] === undefined &&
        !Array.isArray(message.child) &&
        message.child?.pars.length > 0 &&
        message.child.pars[0] === 'ShowIfNotPresent')
    ) {
      object[message.id] = { ...message };
      object[message.id].child = [];
    } else if (!message.child && object[message.id] !== undefined) {
      // Create header for group of messages if they don't have it
      const { child } = object[message.id];
      if (
        object[message.id].message !== i18next.t(`DOCWEB_MESSAGE_DEVICE_HAS_ERRORS`) &&
        object[message.id].message !== i18next.t(`DOCWEB_MESSAGE_DEVICE_HAS_WARNINGS`)
      ) {
        const messageCopy = { ...object[message.id] };
        messageCopy.child = null;
        object[message.id].message = `Object has some ${type}`;
        // TODO define type
        if (Array.isArray(child)) {
          child.push(messageCopy);
        }
      }
      if (Array.isArray(child)) {
        child.push(message);
      }
    }
  });
  messages.forEach((message) => {
    if (message.child != null && (Array.isArray(message.child) || message.child.name !== 'Parent')) {
      if (object[message.id]) {
        // TODO define type
        const { child } = object[message.id];
        if (Array.isArray(child)) {
          child.push(message);
        }
      }
    }
  });
};

const parseMessages = (
  messages: Array<IJsonOutputMessage>,
  devices: Record<string, Device>,
  stateMessages: Record<string, IMessage>
): IParsedMessages => {
  const parsedMessages: IParsedMessages = {
    compute: [],
    curves: [],
  };
  messages.forEach((message) => {
    let category: keyof IParsedMessages = 'compute';
    const messagePars: Record<string, Partial<IJsonOutputMessage>> = {};
    if (!Array.isArray(message) && message?.pars) {
      message.pars.forEach((parameter, key) => {
        messagePars[`value[${key}]`] = { param: message.pars ?? [], key };
      });
    }
    if (message.handle && message.handle !== 'null' && devices[message.handle]) {
      const handle = message.handle.split('#')[0];
      if (message.id && message.id?.indexOf('CurveCheck') !== -1) category = 'curves';
      if (category === 'curves' || isNotDuplicate(message, stateMessages)) {
        const messageId = message.id && category !== 'curves' ? message.id.split('#')[0] : message.id;
        parsedMessages[category].push({
          id: handle,
          title: getObjectSign(devices[handle]),
          message: i18next.t(message.message?.substr(1) ?? '', messagePars),
          child: message?.data,
          messageId: message.id,
          ...(message.data && message.data.pars && message.data.pars[0] === 'ShowIfNotPresent' ? { ShowIfNotPresent: true } : {}),
          ...(category === 'curves' ? { messageId, diagram: message.data?.pars[1], settingId: message.data?.pars[0] } : {}),
        });
      }
    } else if (message.message) {
      parsedMessages[category].push({
        id: uuid(),
        message: i18next.t(message.message.substr(1), messagePars),
      });
    }
  });
  return parsedMessages;
};

const isNotDuplicate = (message: IMessage, stateMessages: Record<string, IMessage>) => {
  if (message.data && message.data.name && message.data.pars[0] === 'ShowIfNotPresent') {
    return !Object.keys(stateMessages).some((id) => {
      const stateMessage = stateMessages[id];
      if (stateMessage.messageId && message.id && stateMessage.messageId === message.id && id === message.handle) {
        return true;
      }
      if (
        Array.isArray(stateMessage.child) &&
        stateMessage.child.some((c) => c.messageId === message.id && id === message.handle)
      ) {
        return true;
      }
      return false;
    });
  }
  const messageToRemove = Object.keys(stateMessages).find((id) => {
    const stateMessage = stateMessages[id];
    if (
      stateMessage.messageId &&
      message.id &&
      stateMessage.showIfNotPresent &&
      stateMessage.messageId === message.id &&
      id === message.handle
    ) {
      return true;
    }
    if (
      Array.isArray(stateMessage.child) &&
      stateMessage.child.find((c) => c.showIfNotPresent && c.messageId === message.id && id === message.handle)
    ) {
      return true;
    }
    return false;
  });
  if (messageToRemove) {
    delete stateMessages[messageToRemove];
  }
  return true;
};

export const processComputeMessages =
  (jsonOutput: IJsonOutput, fromPropagate = false): ThunkAction<void> =>
  (dispatch, getState) => {
    const errors = {};
    const warnings = {};
    const curvesErrors: Array<IMessage> = [];
    const curvesWarnings: Array<IMessage> = [];
    const { devices, project } = getState();

    showTimesFromResponse(jsonOutput.console);
    if (!jsonOutput) {
      console.error('No jsonOutput');
    }

    if (jsonOutput.notifications && jsonOutput.notifications.length > 0) {
      jsonOutput.notifications.forEach((notification) => {
        ['computeErrors', 'propagateErrors', 'computeWarnings', 'propagateWarnings'].forEach((category) => {
          const currentMessages = _.cloneDeep(project[category] as Record<string, IMessage>);
          const messagesToRemove = Object.keys(currentMessages).filter((id) => {
            const message = currentMessages[id];
            if (message.messageId && notification.id && message.messageId === notification.id) {
              return true;
            }
            if (
              Array.isArray(message.child) &&
              message.child.find((c) => c.messageId === notification.id && id === notification.handle)
            ) {
              return true;
            }
            return false;
          });
          if (messagesToRemove.length > 0) {
            messagesToRemove.forEach((messageId) => {
              delete currentMessages[messageId];
            });
            dispatch(setProjectMessages(category, currentMessages));
          }
        });
      });
    }

    if (jsonOutput.errors && jsonOutput.errors.length > 0) {
      const stateMessages = fromPropagate ? project.computeErrors : project.propagateErrors;
      const messages = parseMessages(jsonOutput.errors, devices, stateMessages);
      convertMessages(messages.compute, errors, 'errors');
      curvesErrors.push(...messages.curves);
    }

    if (jsonOutput.warnings && jsonOutput.warnings.length > 0) {
      const stateMessages = fromPropagate ? project.computeWarnings : project.propagateWarnings;
      const messages = parseMessages(jsonOutput.warnings, devices, stateMessages);
      convertMessages(messages.compute, warnings, 'warnings');
      curvesWarnings.push(...messages.curves);
    }
    batch(() => {
      dispatch(copyToCurvesMessages(jsonOutput));
      if (fromPropagate) {
        dispatch(setPropagateErrors(errors));
        dispatch(setPropagateWarnings(warnings));
      } else {
        dispatch(setComputeErrors(errors));
        dispatch(setComputeWarnings(warnings));
      }
      dispatch(setCurvesErrors(curvesErrors));
      dispatch(setCurvesWarnings(curvesWarnings));
    });
  };

// export const processComputeMessages = (jsonOutput, fromPropagate = false) => (dispatch, getState) => {
//   const errors = {};
//   const warnings = {};
//   const curvesErrors = [];
//   const curvesWarnings = [];
//   const {devices} = getState();
//   showTimesFromResponse(jsonOutput.console);
//   if (jsonOutput && jsonOutput.errors) {
//     const messages = parseMessages(jsonOutput.errors, devices);
//     convertMessages(messages.compute, errors, 'errors');
//     curvesErrors.push(...messages.curves);
//   }

//   if (jsonOutput && jsonOutput.warnings) {
//     const messages = parseMessages(jsonOutput.warnings, devices);
//     convertMessages(messages.compute, warnings, 'warnings');
//     curvesWarnings.push(...messages.curves);
//   }
//   batch(() => {
//     dispatch(copyToCurvesMessages(jsonOutput));
//     if (fromPropagate) {
//       dispatch(setPropagateErrors(errors));
//       dispatch(setPropagateWarnings(warnings));
//     } else {
//       dispatch(setComputeErrors(errors));
//       dispatch(setComputeWarnings(warnings));
//     }
//     dispatch(setCurvesErrors(curvesErrors));
//     dispatch(setCurvesWarnings(curvesWarnings));
//   });
// };

const setPropagateErrors = (messages: Record<string, IMessage>) => setProjectMessages('propagateErrors', messages);
const setPropagateWarnings = (messages: Record<string, IMessage>) => setProjectMessages('propagateWarnings', messages);
const setComputeErrors = (messages: Record<string, IMessage>) => setProjectMessages('computeErrors', messages);
const setComputeWarnings = (messages: Record<string, IMessage>) => setProjectMessages('computeWarnings', messages);
const setClientErrors = (messages: Array<IMessage>) => setProjectMessages('clientErrors', messages);
export const setClientWarnings = (messages: Record<string, IMessage>) => setProjectMessages('clientWarnings', messages);
export const setCurvesErrors = (messages: Array<IMessage>) => setProjectMessages('curvesErrors', messages);
export const setCurvesWarnings = (messages: Array<IMessage>) => setProjectMessages('curvesWarnings', messages);
export const setCurvesNotifications = (messages: Array<IMessage>) => setProjectMessages('curvesNotifications', messages);

export const processClientMessages = (): ThunkAction<void> => (dispatch, getState) => {};

export const showTimesFromResponse = (consoleTimes: IMessage['child']): void => {
  if (Array.isArray(consoleTimes) && window.location.href.indexOf('show-times') !== -1) {
    consoleTimes.forEach((timeMessage) => {
      if (timeMessage.message) {
        console.log(translations[timeMessage.message as keyof typeof translations](timeMessage.pars ?? []));
      }
    });
  }
};

export const removeOldMessages =
  (removedCellsId: Array<string>): ThunkAction<void> =>
  (dispatch, getState) => {
    const { project, devices } = getState();
    const clonedProject = _.cloneDeep(project);
    const messageCategories = ['curvesErrors', 'curvesWarnings'];
    messageCategories.forEach((category) => {
      removedCellsId.forEach((deviceId) => {
        let i = 0;
        const deviceObjectId = devices[deviceId] ? devices[deviceId].ObjectId : undefined;
        while (i < (clonedProject[category] as Array<IMessage>).length) {
          const message = (clonedProject[category] as Array<IMessage>)[i];
          if (
            deviceId === message.id ||
            (deviceObjectId &&
              (message.id1 === deviceObjectId ||
                message.id2 === deviceObjectId ||
                (message.messageId && message.messageId.includes(`.${deviceObjectId}.`))))
          ) {
            (clonedProject[category] as Array<IMessage>).splice(i, 1);
          } else {
            i += 1;
          }
        }
      });
    });
    messageCategories.forEach((category) => {
      dispatch(setProjectMessages(category, clonedProject[category] as Record<string, IMessage>));
    });
  };

export const copyToCurvesMessages =
  (messages: IJsonOutput): ThunkAction<void> =>
  (dispatch) => {
    const messageCategories: Array<'notifications' | 'warnings' | 'errors'> = ['notifications', 'warnings', 'errors'];
    messageCategories.forEach((category) => {
      messages[category].forEach((message) => {
        if (message.data && message.data.name === 'CurveCheck') {
          const copyToIndex = message.data.pars.findIndex((val) => val.startsWith('CopyTo:'));
          if (copyToIndex !== -1) {
            const propertyName = message.data.pars[copyToIndex].split('CopyTo:')[1];
            const messagePars: Record<string, { param: typeof message.pars; key: number }> = {};
            if (message.pars) {
              message.pars.forEach((parameter, key) => {
                messagePars[`value[${key}]`] = { param: message.pars, key };
              });
            }
            dispatch(
              updateElementParams(
                message.handle,
                {
                  [propertyName]: i18next.t(message.message.substr(1), messagePars),
                },
                true
              )
            );
          }
        }
      });
    });
  };
