/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { batch } from 'react-redux';
// import { getDeviceCurves } from 'actions/curves';
// import { initDeviceFilters } from 'store/devices/utils';
import { ThunkAction } from 'types/known-actions';
import _ from 'lodash';
import { ESymbolStatus } from 'types/devices/enums.d';
import { REQUESTS_API_DEPENDENCY } from 'types/compute/requestQueue';
import * as ComputeNS from 'types/compute';
import { COMPUTE } from 'types/constants.d';
import { createAction } from 'redux-actions';
import { uuid } from 'actions/utils';

export const SET_COMPUTE_RUNNING = 'SET_COMPUTE_RUNNING';

export const computeActions = {
  updateDeviceMessages: createAction<ComputeNS.Actions['updateDeviceMessages']['payload']>(COMPUTE.DEVICE_MESSAGES),
  setComputeRunning: createAction<ComputeNS.Actions['setComputeRunnings']['payload']>(COMPUTE.SET_COMPUTE_RUNNING),
  setPropagateRunning: createAction<ComputeNS.Actions['setPropagateRunning']['payload']>(COMPUTE.SET_PROPAGATE_RUNNING),
  setCoordinationRequestRunning: createAction<ComputeNS.Actions['setCoordinationRequestRunning']['payload']>(
    COMPUTE.SET_COORDINATIONREQUEST_RUNNING
  ),
};

export const setNotifications = (notifications: ComputeNS.IPushNotification[]): ComputeNS.Actions['setNotifications'] => ({
  type: COMPUTE.SET_NOTIFICATIONS,
  payload: notifications,
});

export const removeNotification =
  (id: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const notifications = _.cloneDeep(getState().compute.notifications);
    const index = notifications.findIndex((notification) => notification.id === id);
    notifications.splice(index, 1);
    dispatch(setNotifications(notifications));
  };

export const pushDeviceMoveNotification =
  (message: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const { notifications } = getState().compute;
    if (!notifications.find((notification) => notification.text === message)) {
      dispatch(pushNotification('alarm', message));
    }
  };

export const pushNotification = (
  type: ComputeNS.IPushNotification['severity'],
  message: string
): ComputeNS.Actions['pushNotification'] => ({
  type: COMPUTE.PUSH_NOTIFICATION,
  payload: {
    id: uuid(),
    type: 'banner',
    // Change the Notification type and severity by randomly:
    discreet: true,
    //  ['success', 'info', 'warn', 'alarm'][Math.floor(Math.random() * Math.floor(4))]
    severity: type,
    timeout: 8000,
    text: message,
  },
});

export const setComputeRunning =
  (running = false, counter?: number): ThunkAction<void> =>
  (dispatch, getState) => {
    const computeCounter = counter || getState().compute.computeCounter;
    batch(() => {
      dispatch(
        computeActions.setComputeRunning({
          running,
          counter: computeCounter,
        })
      );
      dispatch(runRequestFromQueue());
    });
  };

export const setProjectLoading = (isLoading = false): ComputeNS.Actions['setProjectIsLoading'] => ({
  type: COMPUTE.SET_PROJECT_LOADING,
  payload: { isLoading },
});

export const updateLoadStatus = (message: string, percentage: number): ComputeNS.Actions['updateLoadStatus'] => ({
  type: COMPUTE.UPDATE_LOAD_STATUS,
  payload: { loadingMessage: message, loadingPercentage: percentage },
});

export const runRequestFromQueue = (): ThunkAction<void> => (dispatch, getState) => {
  const requestState = getState().compute.requestQueue;
  if (requestState && requestState.length > 0) {
    const request = dispatch(getRequestFromQueue());
    if (request) {
      dispatch(request.func());
    }
  }
};

export const dependentAPIRequestIsRunning =
  (request: ComputeNS.IRequestQueue): ThunkAction<boolean> =>
  (dispatch, getState) => {
    const state = getState().compute;
    const requestNames = REQUESTS_API_DEPENDENCY[request.name];
    if (requestNames.some((requestName: string) => state[requestName as keyof typeof state] === true)) {
      dispatch(putRequestInQueue(request));
      return true;
    }
    return false;
  };

export const isProjectDirty = (): ThunkAction<void> => (dispatch, getState) => {
  const { devices } = getState();
  const devicesDirty = Object.keys(devices).some((id) => devices[id].ObjectStatus === ESymbolStatus.NotChecked);
  return devicesDirty;
};

export const updateComputeParams =
  (params: Record<string, unknown>): ThunkAction<void> =>
  (dispatch) => {
    return dispatch({
      type: COMPUTE.UPDATE_COMPUTE_PARAMS,
      payload: params,
    });
  };

export const setRequestRunning =
  (name: string, running = false): ThunkAction<void> =>
  (dispatch) => {
    dispatch(updateComputeParams({ [name]: running }));
  };

const getRequestFromQueue = (): ThunkAction<ComputeNS.IRequestQueue | undefined> => (dispatch, getState) => {
  const requestState = _.cloneDeep(getState().compute.requestQueue);
  const request = requestState.shift();
  dispatch(updateComputeParams({ requestQueue: requestState }));
  return request;
};

const putRequestInQueue =
  (request: ComputeNS.IRequestQueue): ThunkAction<void> =>
  (dispatch, getState) => {
    const requestState = [...getState().compute.requestQueue];
    if (!requestState.some((req) => req.name === request.name)) {
      requestState.push(request);
      dispatch(updateComputeParams({ requestQueue: requestState }));
    }
  };
