import { IBackendResponse, IScheme } from 'types/compute';
import { Device } from 'types/devices/device';
import ApiCalls from './ApiCalls';

export default class DisconnectorAPI {
  static async getDisconnectorFilters(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`DisconnectorFilter`, requestBody);
    return data;
  }

  static async store(requestBody: IScheme): Promise<IBackendResponse> {
    const { data } = await ApiCalls.post<IBackendResponse, IScheme>(`DisconnectorStore`, requestBody);
    return data;
  }

  static async getDisconnectorFilterGlobals(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`DisconnectorFilterGlobals`, requestBody);
    return data;
  }
}
