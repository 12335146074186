import {
  AbbBar,
  AppHeader,
  AppTopNavi,
  AppTopNaviDivider,
  AppTopNaviItem,
  Icon,
  Popup,
  Tooltip,
  WithPopup,
  WithTooltip,
} from '@abb/abb-common-ux-react';
import { selectUserCountry, userActions } from 'actions/user';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import { copySvgCurveDiagramToClipboard, zoomCurvesOnClick } from 'curves/actions';
import CurvesToolbar from 'curves/toolbar';
import i18n from 'i18n';
import { confirmUploading, downloadProject, isProjectFromDocWeb, saveToBom } from 'project/actions';
import React, { FunctionComponent, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import FileReaderInput from 'react-file-reader-input';
import { ISelectOnChange } from 'types/components/selectContainer';
import { showModal, showModalDialog, showWizard } from './modals/actions';
import { selectCurrentDiagramForPrint } from './modals/printManager/actions';
import LeftPanelTablet from './tablet/leftPanel';

const Navbar: FunctionComponent = (): React.ReactElement => {
  const variables = useSelector((state) => state.variables);
  const project = useSelector((state) => state.project);
  const user = useSelector((state) => state.user);

  const [languages] = useState<Record<string, { name: string; nativeName: string }>>({});
  const [orientation] = useState();
  // const [currentMenu, setCurrentMenu] = useState('');
  const [fileReaderState, setFileReaderState] = useState({
    acceptFileFormat: '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fileReaderAction: (event: React.ChangeEvent<HTMLInputElement>, results: FileReaderInput.Result[]) => {},
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const locize = new Backend(locizeOptions);
  //   locize.getLanguages((err, data: Record<string, { name: string; nativeName: string }>) => {
  //     if (err) return;
  //     const languageObject: Record<string, { name: string; nativeName: string }> = {};
  //     Object.keys(data).forEach((lang) => {
  //       if (availableLanguages.includes(lang)) {
  //         languageObject[lang] = data[lang];
  //       }
  //     });
  //     setLanguages(languageObject);
  //   });
  // }, []);

  const onFileSave = (fileType: string) => {
    dispatch(downloadProject(fileType));
  };

  const onFileNew = () => {
    dispatch(showWizard());
  };

  const selectUserCountryZ = (e: ISelectOnChange) => {
    selectUserCountry(e.value);
    // this.props.updateUserCountry(e.value);
  };

  const onUploadProject = () => {
    setFileReaderState({
      acceptFileFormat: '.docweb',
      fileReaderAction: (e, f) => dispatch(confirmUploading(e, f)),
    });
    setTimeout(() => $('._react-file-reader-input').trigger('click'));
  };

  const openModal = (modalName: string) => {
    dispatch(showModal(modalName));
  };

  const reportAnIssue = () => {
    dispatch(showModal('MailSupport'));
  };

  const onSelect = (menuItem: { id: string }) => {
    switch (menuItem.id) {
      case 'onFileNew':
        onFileNew();
        break;
      case 'onFileSave':
        onFileSave('docweb');
        break;
      case 'onSaveToBom':
        dispatch(saveToBom(t));
        break;
      case 'FromFile':
        onUploadProject();
        break;
      case 'PDFUploader':
      case 'StartPage':
      case 'ProjectProperties':
      case 'Scenarios':
      case 'DeviceProperties':
      case 'ProjectList':
        openModal('');
        break;
      case 'PrintManager':
        if (!project.selectedDeviceId || project.selectedDeviceId === 'initial-some-123') {
          dispatch(
            showModalDialog({
              title: 'No selected device',
              message: 'You should select device first.',
            })
          );
        } else {
          dispatch(selectCurrentDiagramForPrint());
          openModal('PrintManager');
        }
        break;
      case 'Support':
        reportAnIssue();
        break;
      case 'CopySvgDiagram':
        copySvgCurveDiagramToClipboard()
          .then(() => {})
          .catch((err) => console.error(err));
        break;
      default:
        break;
    }
    // setCurrentMenu('');
  };

  const renderProjectPropertiesIcon = () => {
    const isPortrait = window.orientation === 0 ? 'portrait' : null;
    if (variables.MainStandard === 'UL') {
      return null;
    }
    return (
      <WithTooltip>
        <AppTopNaviItem
          className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
          icon={'abb/advanced-settings'}
          onClick={() => dispatch(showModal('ProjectProperties'))}
        />
        <Tooltip>{t('DOCWEB_NAVBAR_PROJECTPROPERTIES')}</Tooltip>
      </WithTooltip>
    );
  };

  const tabletNavbar = () => {
    const isPortrait = window.orientation === 0 ? 'portrait' : null;
    const orientationType = window.orientation;
    if ((orientationType === 0 || orientation === 0) && isMobile) {
      return [
        <WithPopup key={0}>
          <div>
            <AppTopNaviItem
              className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
              icon={'abb/menu'}
              // onClick={() => setCurrentMenu('PropsDevices')}
              // onLostFocus={() => setCurrentMenu('')}
            />
          </div>
          <Popup
            className={isMobileOnly ? 'mobile' : 'tablet'}
            position="bottom left"
            showCloseButton={false}
            closeOnEscape={false}
            closeOnLostFocus={true}
          >
            <LeftPanelTablet />
          </Popup>
        </WithPopup>,
        <WithPopup key={1}>
          <div>
            <AppTopNaviItem
              className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
              icon={'abb/advanced-settings'}
              // onClick={() => setCurrentMenu('CurvesToolbar')}
              // onLostFocus={() => setCurrentMenu('')}
            />
          </div>
          <Popup
            className={isMobileOnly ? 'mobile1' : 'tablet'}
            position="bottom left"
            showCloseButton={false}
            closeOnEscape={false}
            closeOnLostFocus={true}
          >
            <CurvesToolbar />
          </Popup>
        </WithPopup>,
      ];
    }
    return <></>;
  };

  const commonNavbar = () => {
    const isPortrait = window.orientation === 0 ? 'portrait' : null;
    // const hideAddProjectIcon =
    //   window.location.origin === 'https://tripcurves.econfigure.abb.com' ||
    //   window.location.origin === 'https://abb-curves-prod.azurewebsites.net';
    return (
      <AppTopNavi>
        {tabletNavbar()}
        {/* {!hideAddProjectIcon ? (
          <WithTooltip>
            <AppTopNaviItem
              className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
              icon={'abb/new-document'}
              onClick={() => dispatch(showModal('StartUpWizard'))}
            />
            <Tooltip>{t('CURVESWEB_NAVBAR_NEW_PROJECT')}</Tooltip>
          </WithTooltip>
        ) : null} */}
        {isMobileOnly && !isPortrait ? (
          <WithPopup key={0}>
            <div>
              <AppTopNaviItem
                className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
                icon={'abb/menu'}
                // onClick={() => setCurrentMenu('PropsDevices')}
                // onLostFocus={() => setCurrentMenu('')}
              />
            </div>
            <Popup
              className={isMobileOnly ? 'mobile' : 'tablet'}
              position="bottom left"
              showCloseButton={false}
              closeOnEscape={false}
              closeOnLostFocus={true}
            >
              <LeftPanelTablet />
            </Popup>
          </WithPopup>
        ) : null}
        <WithTooltip>
          <AppTopNaviItem
            className={`${isPortrait && isMobileOnly ? 'phonesNav' : ''} ${dispatch(isProjectFromDocWeb()) ? 'disabled' : ''}`}
            icon={'abb/plus'}
            disabled={dispatch(isProjectFromDocWeb())}
            onClick={() => dispatch(showWizard())}
          />
          <Tooltip>{t('CURVESWEB_NAVBAR_ADD_DEVICE')}</Tooltip>
        </WithTooltip>
        <WithTooltip>
          <AppTopNaviItem
            className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
            icon={'abb/print'}
            onClick={() => onSelect({ id: 'PrintManager' })}
          />
          <Tooltip>{t('STRINGS_PRINT_MANAGER')}</Tooltip>
        </WithTooltip>
        <AppTopNaviDivider />
        <WithTooltip>
          <AppTopNaviItem
            className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
            icon={'abb/copy'}
            disabled={!window.miniCurves}
            onClick={() => onSelect({ id: 'CopySvgDiagram' })}
          />
          <Tooltip>{t('CURVESWEB_NAVBAR_COPY_DIAGRAM_CLIPBOARD')}</Tooltip>
        </WithTooltip>
        <AppTopNaviDivider />
        <WithTooltip>
          <AppTopNaviItem
            className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
            icon={'abb/import'}
            onClick={() => onSelect({ id: 'FromFile' })}
          />
          <Tooltip>{t('DOCWEB_NAVBAR_IMPORTFROMFILE')}</Tooltip>
        </WithTooltip>
        <WithTooltip>
          <AppTopNaviItem
            className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
            icon={'abb/export'}
            onClick={() => onSelect({ id: 'onFileSave' })}
          />
          <Tooltip>{t('Export project')}</Tooltip>
        </WithTooltip>
        <WithTooltip>
          <AppTopNaviItem
            className={isPortrait && isMobileOnly ? 'phonesNav' : undefined}
            icon={'abb/object'}
            onClick={() => onSelect({ id: 'onSaveToBom' })}
          />
          <Tooltip>{t('DOCWEB_NAVBAR_EXPORTBOM')}</Tooltip>
        </WithTooltip>
        <AppTopNaviDivider />
        <WithTooltip>
          <AppTopNaviItem
            className={`${isPortrait && isMobileOnly ? 'phonesNav' : ''} ${!project.selectedDeviceId ? 'disabled' : ''}`}
            icon={'abb/calibration'}
            disabled={!project.selectedDeviceId}
            onClick={() => dispatch(showModal('Coordination'))}
          />
          <Tooltip>{t('DOC_COORDINATES')}</Tooltip>
        </WithTooltip>
        {renderProjectPropertiesIcon()}
        <AppTopNaviDivider />
        {isMobileOnly ? null : (
          <>
            <WithTooltip>
              <AppTopNaviItem
                icon={'abb/zoom-in'}
                onClick={(e) => {
                  e.preventDefault();
                  zoomCurvesOnClick();
                }}
              />
              <Tooltip>{t('DOCCURVES_NAVBAR_ZOOM_IN')}</Tooltip>
            </WithTooltip>
            <WithTooltip>
              <AppTopNaviItem
                icon={'abb/zoom-out'}
                onClick={(e) => {
                  e.preventDefault();
                  zoomCurvesOnClick(true);
                }}
              />
              <Tooltip>{t('DOCCURVES_NAVBAR_ZOOM_OUT')}</Tooltip>
            </WithTooltip>
            <AppTopNaviDivider />
            <WithTooltip>
              <AppTopNaviItem
                icon={'abb/envelope'}
                onClick={(e) => {
                  e.preventDefault();
                  reportAnIssue();
                }}
              />
              <Tooltip>{t('DOCWEB_NAVBAR_HELP')}</Tooltip>
            </WithTooltip>
          </>
        )}
      </AppTopNavi>
    );
  };

  return (
    <AppHeader className="DOC_WEB_app_header">
      <AbbBar productName={variables.MainStandard === 'UL' ? t('STR_TITLE_DOC_UL') : t('STR_TITLE_DOC')} />
      {commonNavbar()}
      {project.id ? (
        <div className="nav navbar-right user-profile-nav">
          {/* <AppTopNaviDivider /> */}
          {/* if you change width of country selection dropdown, you also tune up this class in css
            to get rid of too much white space in the right part of popup */}
          <div className="user-circle">{user.email ? user.email[0] + user.email[user.email.indexOf(' ') + 1] : ''}</div>
          <div className="user-country-popup-tune-up">
            <WithPopup>
              {/* <div onClick={() => setCurrentMenu('UserInfo')}> */}
              <div>
                <Icon name="abb/caret-down" sizeClass="small" className="icon-main-toolbar" />
              </div>
              {/* <AppTopNaviItem
                text={this.props.user.name}
                hasMenu={true}
                onClick={() => this.setCurrentMenu('UserInfo')}
                onLostFocus={() => this.setCurrentMenu('')}
              >
              </AppTopNaviItem> */}
              <Popup showCloseButton={false} closeOnEscape={false} closeOnLostFocus={true}>
                <Grid>
                  <GridRow>
                    <GridColumn col={2} verticalAlignment="middle">
                      <div className="user-circle">{user.email[0] + user.email[user.email.indexOf(' ') + 1]}</div>
                    </GridColumn>
                    <GridColumn col={6}>
                      <GridRow>
                        <GridColumn verticalAlignment="middle">{user.name}</GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn verticalAlignment="middle">
                          <p className="user-email">{user.email}</p>
                        </GridColumn>
                      </GridRow>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn>
                      <Select
                        cols={[7, 8, 0]}
                        // labelDivStyle={'info-project-input-label'}
                        className={'info-project-input width-country-select'}
                        name={'userCountry'}
                        label={'DOCWEB_NAVBAR_COUTNRY'}
                        onChange={(e) => selectUserCountryZ(e)}
                        type={'string'}
                        // value={this.props.user.userCountry}
                        data={user}
                        selectList={user.countryName}
                        valueList={user.countryCode}
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn>
                      <Select
                        cols={[7, 8, 0]}
                        // labelDivStyle={'info-project-input-label'}
                        className={'info-project-input width-country-select'}
                        name={'language'}
                        label={t('STRINGS_IDD_ADVSET_LANG_PAGE')}
                        onChange={(e) => {
                          dispatch(userActions.updateUserData({ data: { language: e.value } }));
                          i18n.changeLanguage(e.value).catch((err) => console.error(err));
                        }}
                        type={'string'}
                        data={user}
                        selectList={Object.keys(languages).map((lang) => languages[lang].name)}
                        valueList={Object.keys(languages)}
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Popup>
            </WithPopup>
          </div>
          <AppTopNaviDivider />
          {/* <AppTopNaviItem icon={'abb/log-out'} onClick={() => window.location.href = $('.SignOut').attr('href')}>
              <a style={{display: 'none'}} className={'SignOut'} href="api/account/SignOut">Sign Out</a>
            </AppTopNaviItem> */}
        </div>
      ) : null}
      <FileReaderInput as="binary" onChange={fileReaderState.fileReaderAction} accept={fileReaderState.acceptFileFormat}>
        <span style={{ display: 'none' }}></span>
      </FileReaderInput>
      {/* {this.props.projectLoaded ? <MainToolbar activeMenu={activeMenu}/> : <span></span>} */}
    </AppHeader>
  );
};

export default Navbar;
