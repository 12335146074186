import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType, ENeutral, ETolerance } from '../enums.d';
import { defaultElement } from '../default-element';

export const defaultLvUtilityState: Record<string, unknown> = {
  ...defaultElement,
  symbol: 'UTILITY',
  objectType: EDeviceObjectType.PowerSupply,
  deviceType: EDeviceType.UTILITY,
  ports: [],
  ISecondK: 10000,
  ISecondKMin: 10000,
  SkMin: 6928000,
  sk: 6928000,
  Sr: 25,
  ukr: 4,
  PowerSourceTransformer: 0,
  NominalVoltage: 400.0,
  Uref: 400.0,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  frequency: 50,
  tolerance: ETolerance.tolerance10,
  CosPhiSc: 0.10000000000000001,
  ISecondKLN: 6000,
  CosPhiScLN: 0.10000000000000001,
  ISecondKLE: 6000,
  CosPhiScLE: 0.10000000000000001,
  ComputedP: 0,
  ComputedQ: 0,
  LoadCurrent: 0,
  ComputedCosPhi: 0,
  computeShortCircuitCurrent: false,
  neutral: ENeutral.NeutralBalanced,
  DiscriminationProperty: '',
};

export const defaultLvUtilitySmallState = {
  ...defaultLvUtilityState,
  deviceType: EDeviceType.UTILITY_SMALL,
  objectType: EDeviceObjectType.UtilitySmall,
};
