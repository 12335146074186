import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import { isMobileOnly } from 'react-device-detect';
import { Button } from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { addDevice } from './actions';

const DevicesTab: FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.variables);
  const { t } = useTranslation();

  useEffect(() => {
    [
      {
        id: 'CircuitBreaker',
        symbol: 'Interruttore_Magnetotermico_TM_48px',
      },
      {
        id: 'ManualMotorStarter',
        symbol: 'Interruttore_Magnetotermico_TM_48px',
      },
      {
        id: 'residualCurrentBreaker',
        symbol: 'Interruttore_Magnetotermico_SM_48px',
      },
      {
        id: 'fuse',
        symbol: 'Fusibile_48px',
      },
      // {
      //   id: 'cable',
      //   symbol: 'Cavo_48px',
      // },
      {
        id: 'MvCircuitBreaker',
        symbol: 'Interruttore_Magnetotermico_TM_48px',
      },
      {
        id: 'MvFuse',
        symbol: 'Fusibile_48px',
      },
    ].forEach((item) => {
      $(`#${item.id}`).prepend(`<img class='device-svg' src='assets/symbols/${item.symbol}.svg'/>`);
    });
  }, []);

  const main = () => {
    return (
      <Grid>
        <GridRow key={'uiWizardFirstTabGridRow'} style={{ paddingTop: '10px' }}>
          <GridColumn col={3}>
            <h4 style={{ fontFamily: 'ABBvoice-medium' }}>Low voltage</h4>
          </GridColumn>
        </GridRow>
        <hr />
        <GridRow key={'uiWizardFirstTabGridRow1'}>
          <GridColumn col={isMobileOnly ? 8 : 2} key={'uiWizardFir2stTabGridRow2'}>
            <Button
              id="CircuitBreaker"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('STRING_LVCB')}
              onClick={() => dispatch(addDevice('Miniature Circuit'))}
            />
          </GridColumn>
          <GridColumn col={2} key={'uiWizardFirstTabGridRow4'}>
            <Button
              id="fuse"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('STRINGS_FUSEDLGTITLE')}
              onClick={() => dispatch(addDevice('fuse'))}
            />
          </GridColumn>
        </GridRow>
        <GridRow key={'uiWizardFirstTabGridRow3'}>
          <GridColumn col={isMobileOnly ? 8 : 2} key={'uiWizardFirstTabManualMotorStarter'}>
            <Button
              id="ManualMotorStarter"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('STRINGS_NAME101')}
              onClick={() => dispatch(addDevice('Manual Motor Starter'))}
            />
          </GridColumn>
          {/* <GridColumn col={2} key={'uiWizardFirstTabGridRow44'}>
            <Button
              id="cable"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('CABLE')}
              onClick={() => dispatch(addDevice('cable'))}
            />
          </GridColumn> */}
          <GridColumn col={2} key={'uiWizardFirstTabresidualCurrentBreaker'}>
            <Button
              id="residualCurrentBreaker"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('DOCCURVES_MODAL_DEVICESELECTION_RCD')}
              onClick={() => dispatch(addDevice('residualCurrentBreaker'))}
            />
          </GridColumn>
        </GridRow>
        {/* <GridRow key={'uiWizardFirstTabGridRow54'}>

        </GridRow> */}
      </Grid>
    );
  };

  const mainMv = () => {
    return (
      <>
        <GridRow key={'uiWizardFirstTabGridRow'} style={{ paddingTop: '10px' }}>
          <GridColumn col={3}>
            <h4 style={{ fontFamily: 'ABBvoice-medium' }}>Medium voltage</h4>
          </GridColumn>
        </GridRow>
        <hr />
        <GridRow key={'uiWizardFirstTabGridRow1'}>
          <GridColumn col={isMobileOnly ? 8 : 2} key={'uiWiza1rdFirstTabGridRow2'}>
            <Button
              id="MvCircuitBreaker"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('STRINGS_IDC_RELAY_TXT')}
              onClick={() => dispatch(addDevice('MvCircuitBreaker'))}
            />
          </GridColumn>
          <GridColumn col={isMobileOnly ? 8 : 2} key={'uiWizard3FirstTabGridRow2'}>
            <Button
              id="MvFuse"
              type="discreet-blue"
              sizeClass="large"
              className={'custom-svg-button'}
              text={t('STRING_FUSE')}
              onClick={() => dispatch(addDevice('MvFuse'))}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const mainUl = () => {
    return (
      <GridRow key={'uiWizardFirstTabGridRowUL'}>
        <GridColumn col={2} key={'uiWizardFirstTabGridRowUL12'}>
          <Button
            id="CircuitBreaker"
            type="discreet-blue"
            sizeClass="large"
            text={t('STRING_LVCB')}
            onClick={() => dispatch(addDevice('Miniature Circuit'))}
          />
        </GridColumn>
      </GridRow>
    );
  };

  return (
    <>
      {variables.MainStandard === 'UL' ? (
        mainUl()
      ) : (
        <>
          {main()}
          {mainMv()}
        </>
      )}
    </>
  );
};

export default DevicesTab;
