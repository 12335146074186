/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon } from '@abb/abb-common-ux-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'store';
import { GridColumn, GridRow } from 'components/generic/grid';
import CheckBox from 'components/generic/CheckBox';
import { removeDevice, editDevice, duplicateDevice } from './actions';

interface ICustomCurvesDeviceRow {
  deviceName: string;
  index: number;
  deviceId: string;
  deviceVoltage: number;
  checkedDevices: Array<string>;
  onCheckBoxChange: (deviceId: string, value: boolean) => void;
}

const DeviceRow: React.FunctionComponent<ICustomCurvesDeviceRow> = ({
  deviceName,
  index,
  deviceId,
  deviceVoltage,
  checkedDevices,
  onCheckBoxChange,
}): React.ReactElement => {
  const [overlayOn, setOverlayOn] = useState(false);
  const [popupCoords, setPopupCoords] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const overlayHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore
    setPopupCoords({ x: e.target.getClientRects()[0].x - 10, y: e.target.getClientRects()[0].y });
    setOverlayOn(!overlayOn);
  };

  const removeDeviceHandler = (id: string) => {
    setOverlayOn(false);
    dispatch(removeDevice(id));
  };

  const editDeviceHandler = (id: string) => {
    setOverlayOn(false);
    dispatch(editDevice(id));
  };

  const duplicateHandler = (id: string) => {
    setOverlayOn(false);
    dispatch(duplicateDevice(id));
  };

  return (
    <GridRow className="custom-curves-library-row" key={`${deviceName}${index}`}>
      <GridColumn
        col={7}
        style={{ paddingLeft: '16px', fontWeight: '600' }}
        className="custom-curves-library-text"
        verticalAlignment="middle"
        horizontalAlignment="start"
      >
        <CheckBox
          name="deviceName"
          className="custom-devices-checkbox-style"
          onChange={(e) => onCheckBoxChange(deviceId, e.value)}
          value={checkedDevices.includes(deviceId)}
        />
        {`${deviceName} ${deviceVoltage} [V]`}
      </GridColumn>
      <GridColumn col={1} horizontalAlignment="end" verticalAlignment="middle">
        <GridRow horizontalAlignment="center" style={{ maxHeight: '40px' }}>
          {overlayOn ? <div className="deviceManagerOverlay" onClick={(e) => overlayHandler(e)} /> : null}
          <Icon name="abb/more" onClick={(e) => overlayHandler(e)} />
          <div
            style={{ position: 'fixed', left: popupCoords.x - 197, top: popupCoords.y }}
            className={`deviceManagerOptions ${!overlayOn ? 'hidden' : ''}`}
          >
            <GridRow className="deviceMenuRow">
              <span className="deviceManagerClickableText" onClick={(e) => duplicateHandler(deviceId)}>
                {t('DOCWEB_BUTTON_DUPLICATE')}
              </span>
            </GridRow>
            <GridRow className="deviceMenuRow">
              <span className="deviceManagerClickableText" onClick={(e) => editDeviceHandler(deviceId)}>
                {t('DOCWEB_BUTTON_EDIT')}
              </span>
            </GridRow>
            <GridRow className="deviceMenuRow">
              <span className="deviceManagerClickableText" onClick={(e) => removeDeviceHandler(deviceId)}>
                {t('DOCWEB_BUTTON_REMOVE')}
              </span>
            </GridRow>
          </div>
        </GridRow>
      </GridColumn>
    </GridRow>
  );
};

export default DeviceRow;
