export const diagramComboNames = {
  TFFF: 'STR_TFFF',
  TFN: 'STR_TFN',
  TFPE: 'STR_TFPE',
  EFFF: 'STR_EFFF',
  EFN: 'STR_EFN',
  EFPE: 'STR_EFPE',
  PEAK: 'STR_PEAK',
  OL: 'STR_OL',
  SC1: 'STR_SC1',
  SC2: 'STR_SC2',
  DT1: 'STR_NDT1',
  DT2: 'STR_NDT2',
  DISC: 'STR_DISC',
  DISCR: 'STR_DISCR',
  BCK: 'STR_BCK',
  BCKR: 'STR_BCKR',
};

export const curvesUINames = {
  INN: 'STR_InN',
  MANUALELECTRONIC: 'STR_ManualElectronic',
  TML: 'STR_TML',
  TMI: 'STR_TMI',
  RC: 'STR_RC',
  ELL: 'STR_ELL',
  ELS: 'STR_ELS',
  ELS2: 'STR_ELS2',
  ELI: 'STR_ELI',
  ELI2: 'STR_ELI2',
  ELG: 'STR_ELG',
  ELR: 'STR_ELR',
  ELU: 'STR_ELU',
  ZONE: 'STR_Zone',
  OFF: 'STR_OFF',
  MANUAL: 'STR_Manual',
  ELECTRONIC: 'STR_Electronic',
  HOT: 'STR_Hot',
  COLD: 'STR_Cold',
  'IEC-60947-2': 'STR_IEC609472',
  'IEC60255-3A': 'STR_IEC602553A',
  'IEC60255-3B': 'STR_IEC602553B',
  'IEC60255-3C': 'STR_IEC602553C',
  'IEC-60947-4-1': 'STR_IEC6094741',
  'I2T=K': 'STR_I2tk',
  'T=K': 'STR_tk',
  SSL: 'STR_ELL',
  SECONDSET: 'STR_SecondSet',
  DISABLED: 'STR_DISABLED',
  ENABLED: 'STR_ENABLED',
  i1: 'I>',
  i2: 'I>>',
  i3: 'I>>>',
  io1: 'Io>',
  io2: 'Io>>',
  IECVDE: 'STR_IECVDE',
  TOLERANCES: 'STR_TOLERANCES',
};
