/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-computed-key */
import LvCableAPI from 'api/lvCable';
import { updateAllElementsParams, updateElementParams, setDeviceRequestRunning } from 'devices/actions';
import Device from 'devices/device';
import { BeforeUpdateCallBackFunc, TDeviceEvent, Device as TDevice } from 'types/devices/device';
import { ThunkAction } from 'types/known-actions';
import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from 'types/devices/enums.d';
import { IMvCable } from 'types/devices/mvCable';
import { IGetCableDataResponse, ILvCable, IProcessLvCable } from 'types/devices/lvCable';
import { updateWizardParams } from 'ui/startupWizard/actions';
import { TUpdateWizardParamsEvent } from 'types/wizard';
// import { checkDeviceData } from 'store/devices/utils';
import { updateUserDefaultProjectProperties } from 'actions/user';
import { setRequestRunning } from 'compute/actions';
import { setProjectDirty } from 'project/actions';
import { standardsActions } from 'standards/actions';
import { VariablesActions } from 'store/variables/actions';
import lvCableInitialState from 'types/devices/lvCable/initial-state';
import { getSelectedId } from 'actions/utils';
import { getDeviceCurves } from 'curves/actions';
import { cablePESymbols, cableSymbols } from '../../constants';

export const GET_INITIAL_DATA_LV_CABLE = 'GET_INITIAL_DATA_LV_CABLE';
export const SET_CABLE = 'SET_CABLE';

export const onFocusOutHandler =
  (event: TDeviceEvent, elementId?: string, silent?: boolean): ThunkAction<void> =>
  (dispatch) => {
    dispatch(Device.onFocusOutHandler(event, inputUpdateHandler, EDeviceType.WIRELV, elementId, getCableData, false, silent));
  };

export const getCableData =
  (id: string | null = null, changed = 'Initial'): ThunkAction<Promise<unknown> | undefined> =>
  (dispatch, getState) => {
    const state = getState();
    const elementId = id || dispatch(getSelectedId(EDeviceType.WIRELV));
    if (!elementId) {
      return;
    }
    if (changed === 'Initial' && state.devices[elementId] && state.devices[elementId].initialized) {
      return;
    }
    const requestBody = {
      ...state.devices[elementId],
      ScStandardString: state.variables.ShortCircuitStandard,
      CableStandardString: state.variables.CableStandard,
    };

    const neutralOptimization = state.variables.NeutralOptimization;
    dispatch(setDeviceRequestRunning(elementId));
    return LvCableAPI.getCableData(changed, 2, neutralOptimization, requestBody)
      .then((response) => {
        // if (!dispatch(checkDeviceData(response, () => getCableData(id, 'Initial')))) {
        //   dispatch(setDeviceRequestRunning(elementId));
        //   return;
        // }
        response.LoadCurrentForCurves = response.LoadCurrent;
        dispatch(
          updateElementParams(
            elementId,
            {
              ...response,
              initialized: true,
            },
            true
          )
        );
        dispatch(setDeviceRequestRunning(elementId));
        dispatch(getDeviceCurves(true));
        return response;
      })
      .catch((e) => {
        dispatch(setDeviceRequestRunning(elementId));
        console.error(e);
      });
  };

export const inputUpdateHandler =
  (
    event: TDeviceEvent,
    selectedDeviceId?: string,
    customDeviceId?: string,
    deviceType?: EDeviceType,
    inWizard?: boolean,
    silent?: boolean
  ): ThunkAction<void> =>
  (dispatch) => {
    const processCableData: BeforeUpdateCallBackFunc = (name, objValue, selectedDevice, additionalData) => () => {
      const device = selectedDevice as ILvCable['selectedDevice'];
      // disp(automaticLock(event.name));
      switch (event.name) {
        case 'dUCheckOn':
        case 'KsquaredSsquaredAtWorkingTemp':
        case 'Locked':
        case 'PELikeLives':
        case 'PEWithLives':
        case 'SymmetryRespected':
          objValue.value = event.value;
          break;
        case 'PEPresent':
          objValue.value = event.value;
          Object.assign(additionalData, changeCableObjectType(objValue.value as boolean, event.name, device));
          break;
        case 'LineType':
          objValue.value = event.value;
          Object.assign(additionalData, changeCableObjectType(objValue.value as ELvLineType, event.name, device));
          break;
        case 'FunctionalEndurance':
          if (event.value === 'flameproofEnclosure') {
            Object.assign(additionalData, { IFETemperature: 150 });
          }
          break;
        default:
          break;
      }
    };
    dispatch(
      Device.inputUpdateHandler(event, EDeviceType.WIRELV, selectedDeviceId, processCableData, undefined, customDeviceId, silent)
    );
  };

export const changeCableObjectType = (
  value: ELvLineType | EEarthingSystem | boolean,
  changeType: string,
  selectedDevice: IMvCable['selectedDevice'] | ILvCable['selectedDevice']
): Record<string, unknown> => {
  const objectTypeData: { objectType: EDeviceObjectType } = { objectType: selectedDevice.objectType };
  const PEPresent = changeType === 'PEPresent' ? value : selectedDevice.PEPresent;
  const lvCableSymbols = PEPresent ? cablePESymbols : cableSymbols;
  switch (changeType) {
    case 'LineType':
      objectTypeData.objectType = lvCableSymbols[value as keyof typeof lvCableSymbols][
        selectedDevice.EarthingSystem
      ] as EDeviceObjectType;
      break;
    case 'EarthingSystem':
      objectTypeData.objectType = lvCableSymbols[selectedDevice.LineType as keyof typeof lvCableSymbols][
        value as EEarthingSystem
      ] as EDeviceObjectType;
      break;
    case 'PEPresent':
      if ([EDeviceObjectType.CableStarDelta, EDeviceObjectType.CableStarDeltaPE].includes(objectTypeData.objectType)) {
        if (value) {
          objectTypeData.objectType = EDeviceObjectType.CableStarDeltaPE;
        } else if (!value) {
          objectTypeData.objectType = EDeviceObjectType.CableStarDelta;
        }
      } else {
        objectTypeData.objectType = lvCableSymbols[selectedDevice.LineType as keyof typeof lvCableSymbols][
          selectedDevice.EarthingSystem
        ] as EDeviceObjectType;
      }
      break;
    default:
      break;
  }
  return objectTypeData;
};

export const getCableDataWizard =
  (changed: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const { wizard, variables } = getState();
    const neutralOptimization = variables.NeutralOptimization;
    // dispatch(updateWizardParams({ requestRunning: true }, false));
    dispatch(updateWizardParams({ requestRunning: true }));
    return LvCableAPI.getCableData(changed, 2, neutralOptimization, {
      ...wizard.lvCable,
      CableStandardString: wizard.variables?.CableStandard,
      ScStandardString: wizard.variables?.ShortCircuitStandard,
    }).then((response) => {
      response.LoadCurrentForCurves = response.LoadCurrent;
      let processedResponse: IProcessLvCable = {} as unknown as IProcessLvCable;
      const dataIsCorrupted = response.deviceAttr && response.deviceId === 'Variables';
      if (dataIsCorrupted) {
        processedResponse = processLvCableResponse(response, true);
      } else {
        processedResponse = processLvCableResponse(response);
      }
      dispatch(updateWizardParams(processedResponse.standards as TUpdateWizardParamsEvent, 'standards'));
      dispatch(updateWizardParams(processedResponse.variables as TUpdateWizardParamsEvent, 'variables'));
      dispatch(updateWizardParams({ requestRunning: false }));
      if (dataIsCorrupted) {
        dispatch(getCableDataWizard('Initial'));
      }
    });
  };

export const getCableDataProjectProperties =
  (changed: string, loadedProject = false): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const requestBody = {
      ...lvCableInitialState,
      ...state.standards,
      CableStandardString: state.variables.CableStandard,
      ScStandardString: state.variables.ShortCircuitStandard,
    };
    const neutralOptimization = state.variables.NeutralOptimization;
    dispatch(setRequestRunning('cableRequestRunning', true));
    return LvCableAPI.getCableData(changed, 2, neutralOptimization, requestBody).then((response) => {
      let processedResponse: IProcessLvCable;
      delete response.LineType;
      delete response.Uref;
      delete response.EarthingSystem;
      delete response.CableLength;
      const dataIsCorrupted = response.deviceAttr && response.deviceId === 'Variables';
      response.LoadCurrentForCurves = response.LoadCurrent;
      if (dataIsCorrupted) {
        processedResponse = processLvCableResponse(response, true);
      } else {
        processedResponse = processLvCableResponse(response);
        // TODO
        // dispatch(
        //   getInitialDataHandler({
        //     ...lvCableInitialState,
        //     ...response,
        //   })
        // );
      }

      dispatch(standardsActions.setStandard({ ...processedResponse.standards }));
      dispatch(VariablesActions.setVariables({ ...processedResponse.variables }));
      if (!dataIsCorrupted) {
        if (!loadedProject) {
          dispatch(setProjectDirty(true));
          const devicesToUpdate: Record<string, Device> = {};
          Object.keys(state.devices).forEach((cableId) => {
            if (state.devices[cableId].deviceType === EDeviceType.WIRELV) {
              devicesToUpdate[cableId] = response;
            }
          });
          dispatch(updateAllElementsParams(devicesToUpdate, false));
        }
        if (state.compute.saveProjectProperties) {
          dispatch(updateUserDefaultProjectProperties(null)).catch(console.warn);
        }
      } else {
        dispatch(getCableDataProjectProperties('Initial', loadedProject));
      }
      // dispatch(setFilter(EDeviceType.WIRELV, response));
      dispatch(setRequestRunning('cableRequestRunning', false));
    });
  };

const processLvCableResponse = (response: Partial<TDevice & IGetCableDataResponse>, returnEmpty = false): IProcessLvCable => {
  if (returnEmpty) {
    return {
      standards: {
        ScStandardList: [],
        CableStandardList: [],
        CableStandard: -1,
        ScStandard: -1,
      },
      variables: {
        CableStandard: '',
        ShortCircuitStandard: '',
      },
    };
  }
  return {
    standards: {
      ScStandardList: response.ScStandardList ?? [],
      CableStandardList: response.CableStandardList ?? [],
      CableStandard: response.CableStandard ?? -1,
      ScStandard: response.ScStandard ?? -1,
    },
    variables: {
      CableStandard: response.CableStandardString ?? '',
      ShortCircuitStandard: response.ScStandardString ?? '',
    },
  };
};

// export const updateCableObjectType = (elementId) => (dispatch, getState)=> {
//   const state = getState();
//   const selectedDevice = state.devices[elementId];
//   if (selectedDevice.deviceType === 'app.WIRELV') {
//     const objectTypeData = changeCableObjectType(selectedDevice.LineType, elementId, 'LineType', selectedDevice);
//     dispatch(updateElementParams(elementId, {
//       ...objectTypeData,
//     }));
//   }
// };
