import {
  IGetProjectInformation,
  IGetProjectInformationResponse,
  ISaveProjectData,
  ISaveToBom,
  ISaveToBomResponse,
  ISaveUserSettings,
} from 'types/api/partnerHubApi';
import ApiCalls from './ApiCalls';

export default class PartnerHubAPI {
  static async getProjectInfo(requestData: IGetProjectInformation): Promise<IGetProjectInformationResponse> {
    const { data } = await ApiCalls.post<IGetProjectInformationResponse, IGetProjectInformation>(
      `Users/GetProjectInformation/`,
      requestData
    );
    return data;
  }

  static async saveProjectData(type: string, requestData: ISaveProjectData): Promise<string> {
    const { data } = await ApiCalls.put<string, ISaveProjectData>(`Users/SaveProjectData/${type}`, requestData);
    return data;
  }

  static async saveToBom(requestData: ISaveToBom): Promise<ISaveToBomResponse> {
    const { data } = await ApiCalls.put<ISaveToBomResponse, ISaveToBom>(`Users/SaveToBom/`, requestData);
    return data;
  }

  static async saveUserSettings(requestData: ISaveUserSettings): Promise<string> {
    const { data } = await ApiCalls.post<Promise<string>, ISaveUserSettings>(`Users/SaveUserSettings/`, requestData);
    return data;
  }
}
