import { USER_SETTINGS } from 'types/constants.d';
import { UserSettingsKnownActions } from 'types/known-actions';
import { State } from 'types/userSettings';

export const initialState: State = {
  pdf: '',
  notation: '',
  pages: '',
  xsdValidationErrors: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: UserSettingsKnownActions): State => {
  switch (action.type) {
    case USER_SETTINGS.LOAD_PDF:
      return {
        ...state,
        // pdf: action.pdf,
        pdf: action.payload.pdfLoadId,
      };
    case USER_SETTINGS.SET_USER_SETTING:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case USER_SETTINGS.SET_XSD_VALIDATION_ERRORS:
      return {
        ...state,
        xsdValidationErrors: action.payload.xsdValidationErrors,
      };
    default:
      return state;
  }
};
