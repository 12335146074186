import { Tooltip, WithTooltip } from '@abb/abb-common-ux-react';
import { Table, TableHeader } from 'components/generic/table';
import { collectLeftPanelData, disableOnEditForMVStuff, removeCells } from 'devices/actions';
import { isProjectFromDocWeb, setSelectedDevice } from 'project/actions';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { EDeviceType } from 'types/devices/enums.d';
import { showWizard } from 'ui/modals/actions';

const LeftPanelTablet: FunctionComponent = (): React.ReactElement => {
  const devices = useSelector((state) => state.devices);
  const mainStandard = useSelector((state) => state.variables.MainStandard);
  const selectedDeviceId = useSelector((state) => state.project.selectedDeviceId);
  const customDevices = useSelector((state) => state.curves.customCurves.devices);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const customInputs = [
    {
      index: 1,
      input: (item: Record<string, unknown>) => {
        if (item.TripUnitModelName && item.deviceType !== EDeviceType.WIREMV) {
          return (
            <WithTooltip>
              <span>{item.TripUnitModelName as string}</span>
              <Tooltip>{item.TripUnitModelName as string}</Tooltip>
            </WithTooltip>
          );
        }
        if (item.ProductDescription) {
          return (
            <WithTooltip>
              <span>{item.ProductDescription as string}</span>
              <Tooltip>{item.ProductDescription as string}</Tooltip>
            </WithTooltip>
          );
        }
        return (
          <WithTooltip>
            <span>{`${(item.Conductor as string) ?? ''} ${(item.Insulator as string) ?? ''} ${
              (item.CrossSectionDescription as string) ?? ''
            }`}</span>
            <Tooltip>{`${(item.Conductor as string) ?? ''} ${(item.Insulator as string) ?? ''} ${
              (item.CrossSectionDescription as string) ?? ''
            }`}</Tooltip>
          </WithTooltip>
        );
      },
    },
  ];
  return (
    <Table
      data={collectLeftPanelData(devices, customDevices, mainStandard)}
      selectedValue={selectedDeviceId}
      valueAttr={'deviceId'}
      onRowClick={(device) => dispatch(setSelectedDevice(device.deviceId ?? ''))}
      specificColumnWidth={['70px', 'auto', '70px']}
      searchable={true}
      onDelete={(device) => {
        dispatch(setSelectedDevice(''));
        dispatch(removeCells([device.deviceId as string]));
      }}
      onDeleteDisabled={isProjectFromDocWeb}
      onEdit={(device) => {
        dispatch(setSelectedDevice((device as { deviceId: string }).deviceId));
        dispatch(showWizard(device));
      }}
      onEditDisabled={(e) => disableOnEditForMVStuff(e)}
      customInputs={customInputs}
    >
      <TableHeader attr={'ObjectSign'} key={'deviceListTableHeaderSign'}>
        {t('STRING_REPSIGN')}
      </TableHeader>
      <TableHeader
        attr={['ProductDescription', 'Conductor', 'Insulator', 'CrossSectionDescription', 'UserDescription1']}
        key={'deviceListTableHeaderName'}
        // showTooltip={true}
      >
        {t('DOCWEB_STRING_PRODUCT_DESCRIPTION')}
      </TableHeader>
      <TableHeader attr={'Uref'} key={'deviceListTableHeaderVoltage'}>
        {t('DOCWEB_MOTOR_UN')}
      </TableHeader>
    </Table>
  );
};

export default LeftPanelTablet;
