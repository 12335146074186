import CurveType from 'curves/curveType';

/**
 * This is a mapping of curve type TO index in the points array
 */
export default {
  [CurveType.TFFF]: 0,
  [CurveType.TFF]: 1,
  [CurveType.TFN]: 2,
  [CurveType.TFPE]: 3,
  [CurveType.EFFF]: 4,
  [CurveType.EFF]: 5,
  [CurveType.EFN]: 6,
  [CurveType.EFPE]: 7,
  [CurveType.CL]: 8,
};
