import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'WIREMV',
  objectType: EDeviceObjectType.MvCable,
  deviceType: EDeviceType.WIREMV,
  ports: [],
  initialized: false,
  LoadCurrent: 10,
  ComputedCosPhi: 0.9,
  Uref: 1000,
  dUCheckOn: false,
  dUMax: 3,
  CableLength: 3,
  IzIbRatio: 1.3,
  AmbientTemperature: 30,
  LiveCordsNumber: 1,
  Frequency: 50,
  CableCarryingCapacityUserFactor: 1,
  CarryingCapacityIzCorrectionFactor: 1,
  CableInstallationReferenceList: [],
  SpaceRatingList: [],
  SpaceRating: '',
  SpaceRatingId: -1,
  ScreenConductor: 'Cu',
  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  CableProtectionCombo: 'DOC_NORMAL_OL_SC_NDT_PROTECTION',
  CalculationResultsList: {
    Iz: [],
    K2S2: [],
    Kt: 1,
    R20C: [],
    RSC: [],
    RWork: [],
    X: [],
    dU: [],
    dissipatedPower: [],
    phaseReactance: [],
    workingTemperature: [],
  },
};
