/* eslint-disable @typescript-eslint/unbound-method */

import CircuitBreakerAPI from 'api/circuitBreaker';
import DisconnectorAPI from 'api/disconnector';
import ResidualCurrentCircuitBreakerAPI from 'api/residualCurrentCircuitBreaker';
import ContactorAPI from 'api/contactor';
import SoftStarterAPI from 'api/softstarter';
import FuseAPI from 'api/fuse';
import { ThunkAction } from 'types/known-actions';
import {
  AfterUpdateCallBackFunc,
  BeforeUpdateCallBackFunc,
  DefaultElement,
  Device,
  PowerDevice,
  TDeviceEvent,
  TypeToDeviceAPI,
} from 'types/devices/device';
import { EDeviceType } from 'types/devices/enums.d';
import _ from 'lodash';
import { computeActions, dependentAPIRequestIsRunning, pushNotification, runRequestFromQueue } from 'compute/actions';
import { IRequestQueue } from 'types/compute';
import buildNetwork from 'project/buildNetwork';
import { getDeviceCurves } from 'curves/actions';
import { modalIsOpened, updateModalParams } from 'ui/modals/actions';
import { updateWizardParams } from 'ui/startupWizard/actions';
import { checkDeviceData } from 'store/devices/utils';
import { showTimesFromResponse } from 'project/actions/projectMessages';
import { getSelectedId } from 'actions/utils';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { setDeviceRequestRunning, updateAllElementsParams, updateElementParams } from './actions';
import { isSilentParam, processDeviceValues } from './utils';

const typeToAPI = {
  [EDeviceType.CB_TM]: {
    get: CircuitBreakerAPI.getCircuitBreakerFilters,
    store: CircuitBreakerAPI.store,
    wishes: CircuitBreakerAPI.wishes,
  },
  [EDeviceType.MMS]: {
    get: CircuitBreakerAPI.getCircuitBreakerFilters,
    store: CircuitBreakerAPI.store,
  },
  [EDeviceType.OLR]: {
    get: CircuitBreakerAPI.getCircuitBreakerFilters,
    store: CircuitBreakerAPI.store,
  },
  [EDeviceType.DISCONNECTORLV]: {
    get: DisconnectorAPI.getDisconnectorFilters,
    store: DisconnectorAPI.store,
  },
  [EDeviceType.RCCB]: {
    get: ResidualCurrentCircuitBreakerAPI.getResidualCurrentFilters,
    store: ResidualCurrentCircuitBreakerAPI.store,
  },
  [EDeviceType.CONTACTOR]: {
    get: ContactorAPI.getContactorFilters,
    store: ContactorAPI.store,
  },
  [EDeviceType.SOFTSTARTER]: {
    get: SoftStarterAPI.preFilters,
    store: SoftStarterAPI.store,
  },
  [EDeviceType.FUSEBASE]: {
    get: FuseAPI.filterFuse,
    store: FuseAPI.store,
    wishes: FuseAPI.wishes,
  },
} as TypeToDeviceAPI;

export default class DeviceProcessor {
  // TODO: Check if possible describe class in standalone place
  static onFocusOutHandler =
    (
      event: TDeviceEvent,
      inputUpdateHandler: (
        event: TDeviceEvent,
        selectedDeviceId?: string,
        customDeviceId?: string,
        deviceType?: EDeviceType,
        inWizard?: boolean,
        silent?: boolean
      ) => ThunkAction<void>,
      deviceType?: EDeviceType,
      customDeviceId?: string,
      focusOutCallBackFn?: (elementId: string, name: string) => ThunkAction<void>,
      inWizard = false,
      silent?: boolean
    ): ThunkAction<void> =>
    (dispatch, getState) => {
      // TODO: implement this
      let elementId = customDeviceId;
      if (!elementId) {
        elementId = dispatch(getSelectedId(deviceType));
        if (!elementId) return;
      }
      const { devices, wizard } = getState();
      const selectedDevice = inWizard ? wizard[elementId] : devices[elementId];
      if (!selectedDevice) {
        return;
      }
      dispatch(inputUpdateHandler(event, '', customDeviceId, deviceType, inWizard, silent));
      if (!isSilentParam(event.name)) {
        const newSelectedDevice = inWizard ? (getState().wizard[customDeviceId ?? ''] as Device) : getState().devices[elementId];
        const cutValues = processDeviceValues(newSelectedDevice);
        dispatch(updateElementParams(elementId, cutValues, true));
        if (focusOutCallBackFn) {
          dispatch(focusOutCallBackFn(elementId, event.name));
        }
      }
    };

  static inputUpdateHandler =
    (
      event: TDeviceEvent,
      deviceType?: EDeviceType,
      selectedDeviceId?: string,
      beforeUpdateCallBackFn?: BeforeUpdateCallBackFunc,
      afterUpdateCallBackFn?: AfterUpdateCallBackFunc,
      customDeviceId?: string,
      silent?: boolean,
      inWizard = false
    ): ThunkAction<void> =>
    (dispatch, getState) => {
      let elementId = customDeviceId || selectedDeviceId;
      if (!elementId) {
        elementId = dispatch(getSelectedId(deviceType));
        if (!elementId) return;
      }
      const state = getState();
      const { name } = event;
      const selectedDevice: Device = inWizard ? (state.wizard[elementId] as Device) : state.devices[elementId];
      if (!selectedDevice) {
        return;
      }
      let value = event.type === 'number' && !Number.isNaN(parseFloat(event.value)) ? parseFloat(event.value) : event.value;
      if (typeof value === 'number' && typeof event.value === 'string' && event.value.toString().split('.').length > 1) {
        value = value.toFixed(event.value.toString().split('.')[1].length);
      }
      if (value === 'true' || value === 'false') {
        value = value === 'true';
      }
      const additionalData = {};
      if (beforeUpdateCallBackFn) {
        const objValue = { value };
        if (event.defaultValue) {
          dispatch(removeErrorForDefaultValue(name, elementId));
        }
        dispatch(beforeUpdateCallBackFn(name, objValue, selectedDevice, additionalData, elementId, event));
        value = objValue.value;
      }
      let isSilent = false;
      if (silent !== undefined) {
        isSilent = silent;
      } else if (!silent) {
        if (!modalIsOpened(state.modal)) {
          isSilent = isSilentParam(name ?? '');
        } else {
          isSilent = modalIsOpened(state.modal);
        }
      }
      if (!inWizard) {
        if (['ScIEC60909L3Ib', 'ScIEC60909L2Ib', 'ScIEC60909LNeutralIb', 'ScIEC60909LPEIbMin'].includes(name)) {
          const scValue = _.cloneDeep(selectedDevice[name] as DefaultElement['ScIEC60909L3Ib']);
          if (scValue) {
            scValue.pair[0].current = parseFloat(UnitsDataAdapter.convertKiloToBase(value as unknown as string) as string);
            dispatch(
              updateElementParams(
                elementId,
                {
                  [name]: scValue,
                  ...additionalData,
                },
                isSilent
              )
            );
          }
        } else {
          dispatch(
            updateElementParams(
              elementId,
              {
                [name]: value,
                ...additionalData,
              },
              isSilent
            )
          );
        }
      } else {
        dispatch(
          updateWizardParams({
            [name]: value,
            ...additionalData,
          })
        );
      }
      if (afterUpdateCallBackFn && name !== undefined) {
        dispatch(afterUpdateCallBackFn(name, value, getState().devices[elementId], {}, elementId, event));
      }
    };

  static getDeviceFilters =
    (
      deviceId: string,
      changed: string,
      deviceType: EDeviceType,
      storeCallBackFn?: typeof DeviceProcessor.storeDevice
    ): ThunkAction<void> =>
    (dispatch, getState) => {
      if (!deviceType && !typeToAPI[deviceType]) {
        return;
      }
      const state = getState();
      const request: IRequestQueue = {
        name: 'deviceFilters',
        func: _.partial(DeviceProcessor.getDeviceFilters, deviceId, changed, deviceType, storeCallBackFn),
      };
      if (dispatch(dependentAPIRequestIsRunning(request))) {
        return;
      }
      const elementId = (deviceId || dispatch(getSelectedId(deviceType))) ?? '';
      const requestBody = _.cloneDeep(buildNetwork(state, true));
      if (requestBody) {
        requestBody.languageId = state.user.languageId;
        requestBody.variables.WEBDOC_CURRENT_DEVICE = elementId;
        requestBody.changed = changed || 'Initial';
        requestBody.languageId = 2;
      }

      const isDeviceInitinitialized = state.devices[elementId]?.initialized;

      const initial = changed === 'Initial';
      const additionalParams: Partial<Device> = {};
      if (initial || (!isDeviceInitinitialized && (changed === 'TypeFilter' || changed === 'VersionFilter'))) {
        additionalParams.initialized = true;
      }
      dispatch(setDeviceRequestRunning(elementId ?? ''));
      if (requestBody) {
        typeToAPI[deviceType]
          .get(requestBody)
          .then((response) => {
            if (!response && !window.app) {
              throw Error(
                `Crash in filters API deviceType = ${state.devices[elementId].deviceType} ObjectId = ${
                  state.devices[elementId].ObjectId ?? ''
                }`
              );
            }
            if (
              !dispatch(
                checkDeviceData(response, () =>
                  DeviceProcessor.getDeviceFilters(deviceId, 'Initial', deviceType, storeCallBackFn)
                )
              )
            ) {
              dispatch(setDeviceRequestRunning(elementId));
              return;
            }
            if (getState().devices[elementId]) {
              if (response.ProductDescription === '') {
                additionalParams.ProductIdList = {};
              }
              dispatch(updateElementParams(elementId, { ...response, ...additionalParams }, true, true));
              dispatch(setDeviceRequestRunning(elementId));
            }
          })
          .then(() => {
            if (getState().devices[elementId]) {
              window.storeDeviceFunc = () => dispatch(processDeviceStore(changed, elementId, deviceType, storeCallBackFn));
              if (
                getState().modal.params &&
                getState().modal.params?.selectedDevice &&
                (getState().modal.params?.selectedDevice as { terminalsOutput: string }).terminalsOutput === ''
              ) {
                dispatch(updateModalParams({ selectedDevice: _.cloneDeep(getState().devices[elementId]) }));
              }
            }
            dispatch(runRequestFromQueue());
            // if (getState().devices[elementId] && storeCallBackFn) {
            //   dispatch(processDeviceStore(changed, elementId, deviceType, storeCallBackFn));
            // }
            // dispatch(runRequestFromQueue());
          })
          .catch((error) => {
            console.error(error);
            dispatch(setDeviceRequestRunning(elementId));
            dispatch(pushNotification('alarm', 'DOCWEB_ERROR_DEVICEDATA'));
          });
      }
    };

  static onModalClose =
    (deviceType: EDeviceType, customDeviceId: string): ThunkAction<void> =>
    (dispatch) => {
      const elementId = customDeviceId || dispatch(getSelectedId(deviceType));
      dispatch(
        updateElementParams(
          elementId ?? '',
          {
            PreFiltersChanged: true,
          },
          true
        )
      );
    };

  static storeDevice =
    (deviceId: string, changed: string, deviceType: EDeviceType, deviceKey2?: string, deviceKey1?: string): ThunkAction<void> =>
    (dispatch, getState) => {
      const state = getState();
      const request: IRequestQueue = {
        name: 'storeDevice',
        func: _.partial(DeviceProcessor.storeDevice, deviceId, changed, deviceType, deviceKey2),
      };
      if (dispatch(dependentAPIRequestIsRunning(request))) {
        return;
      }
      const requestBody = _.cloneDeep(buildNetwork(state, true));
      if (requestBody) {
        requestBody.variables.WEBDOC_CURRENT_DEVICE = deviceId;
        requestBody.variables.WEBDOC_DEVICE_KEY_1 = (deviceKey1 || (state.devices[deviceId].DeviceId as string)) ?? '';
        if (deviceKey2) {
          requestBody.variables.WEBDOC_DEVICE_KEY_2 = deviceKey2;
        }
      }

      dispatch(setDeviceRequestRunning(deviceId));
      if (requestBody) {
        typeToAPI[deviceType]
          .store(requestBody)
          .then((response) => {
            if (
              !dispatch(checkDeviceData(response, () => DeviceProcessor.storeDevice(deviceId, 'Initial', deviceType, deviceKey2)))
            ) {
              dispatch(setDeviceRequestRunning(deviceId));
              return;
            }
            if (!getState().devices[deviceId]) {
              return;
            }
            let silent = true;

            if (state.devices[deviceId] && response.jsonOutput.docxdata[deviceId]) {
              if (
                state.devices[deviceId].ProductIdList &&
                response.jsonOutput.docxdata[deviceId].ProductIdList &&
                JSON.stringify(state.devices[deviceId].ProductIdList) !==
                  JSON.stringify(response.jsonOutput.docxdata[deviceId].ProductIdList)
              ) {
                silent = false;
              }

              if (
                silent &&
                state.devices[deviceId].ProductDescription &&
                response.jsonOutput.docxdata[deviceId].ProductDescription &&
                state.devices[deviceId].ProductDescription !== response.jsonOutput.docxdata[deviceId].ProductDescription
              ) {
                silent = false;
              }
            }

            dispatch(updateAllElementsParams(response.jsonOutput.docxdata, silent));
            const manuallySelection = ['DeviceId', 'RcbId', 'fuseLinksOutput'].indexOf(changed) !== -1;
            dispatch(
              updateElementParams(
                deviceId,
                {
                  ...(manuallySelection ? { Locked: true } : {}),
                },
                true
              )
            );
            dispatch(setDeviceRequestRunning(deviceId));
            dispatch(runRequestFromQueue());
            dispatch(getDeviceCurves());
            showTimesFromResponse(response.jsonOutput.console);
            // TODO: Check if we need return here response
            // return response;
          })
          .catch(console.warn);
      }
    };

  static getDeviceWishes =
    (deviceData: Device<PowerDevice>, deviceType: EDeviceType, deviceId: string): ThunkAction<void> =>
    (dispatch, getState) => {
      const api = typeToAPI[deviceType];
      if (api.wishes) {
        const state = getState();
        const requestBody = _.cloneDeep(buildNetwork(state, true));
        if (requestBody) {
          requestBody.variables.WEBDOC_CURRENT_DEVICE = deviceId;
          requestBody.variables.WEBDOC_DEVICE_KEY_1 = deviceData.Wishes as string;
          api
            .wishes(requestBody)
            .then((response) => dispatch(computeActions.updateDeviceMessages({ deviceMessages: response })))
            .catch(console.warn);
        }
      }
    };
}

const processDeviceStore =
  (
    changed: string,
    deviceId: string,
    deviceType: EDeviceType,
    storeCallBackFn?: typeof DeviceProcessor.storeDevice
  ): ThunkAction<void> =>
  (dispatch) => {
    if (storeCallBackFn) {
      dispatch(storeCallBackFn(deviceId, changed, deviceType));
    } else {
      dispatch(DeviceProcessor.storeDevice(deviceId, changed, deviceType));
    }
  };

const removeErrorForDefaultValue =
  (propertyName: string, deviceId: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const { errors } = getState().devices[deviceId];
    if (errors && typeof errors !== 'string' && errors[propertyName]) {
      delete errors[propertyName];
      dispatch(updateElementParams(deviceId, { errors }));
    }
  };
