import React from 'react';
import CheckBox from 'components/generic/CheckBox';
import { updateComputeParams } from 'compute/actions';
import { updateUserDefaultProjectProperties } from 'actions/user';
import { useDispatch, useSelector } from 'store';
import { TDeviceEvent } from 'types/devices/device';

const Footer = (): React.ReactElement => {
  const compute = useSelector((state) => state.compute);

  const dispatch = useDispatch();

  const onChange = (e: TDeviceEvent) => {
    dispatch(updateComputeParams({ saveProjectProperties: e.value }));
    dispatch(updateUserDefaultProjectProperties(null)).catch((error) => console.error(error));
  };

  return (
    <div className="row text-left">
      <CheckBox
        label="DOCWEB_SAVE_PROPERTIES_AS_DEFAULT"
        name="SaveUserProperties"
        value={compute.saveProjectProperties}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export default Footer;
