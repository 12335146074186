import React, { FunctionComponent, useEffect } from 'react';
import { CollapsibleContainer, Collapsible } from '@abb/abb-common-ux-react';
import { IMessage } from 'types/project/messages';
import { useSelector } from 'store';

const Compute: FunctionComponent = (): React.ReactElement => {
  const devices = useSelector((state) => state.devices);
  const project = useSelector((state) => state.project);

  useEffect(() => {
    setTimeout(() => computeHeightOfErrors());
    window.addEventListener('resize', computeHeightOfErrors);
  }, []);

  const computeHeightOfErrors = () => {
    setTimeout(() => {
      const heightOfHeader = 136;
      const windowHeight = $(window).height();
      const curvesAreaHeight = $('canvas').height();
      if (windowHeight && curvesAreaHeight) {
        $('.calculations').height(windowHeight - curvesAreaHeight - heightOfHeader);
      }
    });
  };

  const messageItem = (item: IMessage, index: number, messageClass: string, abbIcon: string) => {
    const title = item.title ? `${item.title} : ${item.message}` : `${item.message}`;
    return (
      <Collapsible
        icon={`abb/${abbIcon}`}
        title={title}
        className={`DOCWEB_device-message-${messageClass}`}
        key={`${title}${index}`}
        itemId={`${item.title ?? ''}${item.id}${item.message}${item.messageId ?? ''}`}
      />
    );
  };

  const renderCurvesOrClientMessage = (item: IMessage, index: number, messageClass: string, abbIcon: string) => {
    return messageItem(item, index, messageClass, abbIcon);
  };

  const displayArrayErrors = (messagesArray: Array<IMessage>, type: string, icon: string) => {
    return messagesArray.map((item, index) => renderCurvesOrClientMessage(item, index, type, icon));
  };

  const checkAndPushMessage = (messageArray: Array<IMessage>, message: IMessage) => {
    if (messageArray.indexOf(message) === -1) {
      messageArray.push(message);
    }
  };

  const sortMessages = (notifications: IMessage[]) => {
    if (!project.selectedDeviceId || !notifications) {
      return [];
    }
    const { selectedDeviceId } = project;
    const filteredNotifications: IMessage[] = [];
    const firstPriorityMessages = notifications.filter(
      (item) => item.settingId === project.selectedDeviceId && item.diagram === devices[selectedDeviceId].diagram
    );
    filteredNotifications.push(...firstPriorityMessages);
    const secondPriorityMessages = notifications.filter((item) => item.id1 === devices[selectedDeviceId].ObjectId);
    secondPriorityMessages.forEach((message) => checkAndPushMessage(filteredNotifications, message));
    const thirdPriorityMessages = notifications.filter((item) => item.id2 === devices[selectedDeviceId].ObjectId);
    thirdPriorityMessages.forEach((message) => checkAndPushMessage(filteredNotifications, message));
    notifications.forEach((message) => checkAndPushMessage(filteredNotifications, message));
    return filteredNotifications;
  };

  const renderCurvesNotifications = () => {
    return (
      <CollapsibleContainer expanderOnRight>
        {[
          ...displayArrayErrors(project.curvesErrors, 'error', 'error-circle-1'),
          ...displayArrayErrors(sortMessages(project.curvesWarnings), 'warning', 'warning-triangle'),
          ...displayArrayErrors(sortMessages(project.curvesNotifications), 'curve-notification', 'check-mark-circle-1'),
        ]}
      </CollapsibleContainer>
    );
  };

  return <div className="calculations">{renderCurvesNotifications()}</div>;
};

export default Compute;
