import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export const upsDefaultState = {
  ...defaultElement,
  symbol: 'UPS',
  objectType: EDeviceObjectType.Ups,
  deviceType: EDeviceType.UPS,
  Desrciption: '',
  ProductId: '',
  ProductDescription: '',
  LineType: ELvLineType.LLLN,
  PrimaryWindingLineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  PrimaryWindingEarthingSystem: EEarthingSystem.TNS,
  NominalVoltage: 400.0,
  Uref: 400.0,
  Frequency: 50,
  NominalPower: 5000,
  CosPhiNominal: 0.9,
  UpsNoByPass: false,
  ComputedP: 0,
  ComputedQ: 0,
  ComputedCosPhi: 0,
  UpsIkMax: 0,
  LoadCurrent: 0,
  IScInRatio: 2,
  IScInRatioList: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
  BatteryChargingCurrent: 15,
  BatteryChargingCurrentList: [1, 2, 5, 10, 15, 20, 25],
  DISTRIBUTION_SYSTEM_0_LIST: ['TN-S', 'TN-C', 'TT', 'IT'],
  initialized: false,
  // Input
  TypeFilter: 'Single',
  // Series
  FamilyFilter: 'All Possibilities',
  Model: 'All Possibilities',

  UpsSeriesList: [],
  UpsModelsList: [],
};

export const upsBypassDefaultState = {
  ...upsDefaultState,
  symbol: 'UPS_BYPASS',
  objectType: EDeviceObjectType.UpsBypass,
  deviceType: EDeviceType.UPS,
};

export const upsNoByPassDefaultState = {
  ...upsDefaultState,
  symbol: 'UPS_NOBYPASS',
  deviceType: EDeviceType.UPS_NOBYPASS,
  objectType: EDeviceObjectType.UpsNoBypass,
};

export const ups2inputDefaultState = {
  ...upsDefaultState,
  symbol: 'UPS_2_INPUT',
  deviceType: EDeviceType.UPS_2_INPUT,
  objectType: EDeviceObjectType.Ups2Input,
};
