/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { PAGES, PROJECT } from 'types/constants.d';
import { PagesKnownActions } from 'types/known-actions';
import * as PagesNamespace from 'types/pages';
// import * as ActionConstants from 'types/constants.d';
import { EPaperAlign, EPaperLayout, EPaperList } from 'types/project/store.d';
import _ from 'lodash';

export const initialState: PagesNamespace.State = {
  block: {
    name: 'Block Diagram',
    number: 0,
    editable: false,
    errors: [],
    frame: EPaperList.A3,
    pageAlign: EPaperAlign.Horizontal,
    layout: EPaperLayout.Free,
  },
  'e6965058-1be8-4db0-aee3-bbbbc8aef654': {
    name: `New page 1`,
    editable: true,
    number: 1,
    errors: [],
    pageAlign: EPaperAlign.Horizontal,
    frame: EPaperList.A3,
    layout: EPaperLayout.Free,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: PagesKnownActions): PagesNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case PAGES.ADD_PAGE:
        draft[action.payload.id] = action.payload.page;
        break;
      case PAGES.UPDATE_PAGE:
        draft[action.payload.id] = { ...state[action.payload.id], ...action.payload.changes };
        break;
      case PAGES.DELETE_PAGE:
        delete draft[action.payload.id];
        break;
      case PROJECT.LOAD_PROJECT:
        return _.merge({}, initialState, action.payload.projectState.pages);
      default:
        break;
    }
  });
