import CheckBox from 'components/generic/CheckBox';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { inputUpdateHandler, timeChange } from './actions';

const ShortCircuitOptionsContainer: React.FunctionComponent = (): React.ReactElement => {
  // TODO: useTranslation - assign proper namespace
  const variables = useSelector((state) => state.variables);
  const dispatch = useDispatch();

  const times = (): React.ReactElement[] => {
    return [
      <GridRow key="0">
        <GridColumn col={1} className="project-properties-short-circuit-checkbox-width">
          <CheckBox name="" disabled value onChange={() => console.log('disabled')} />
        </GridColumn>
        <GridColumn col={2}>
          <Input
            cols={[8]}
            type="number"
            // onFocusOut={inputFocusOutHandler}
            className="info-project-input"
            name="value"
            disabled
            // TODO: Input, can't accept data
            data={variables.ShortCircuitTimesObject.time0}
          />
        </GridColumn>
      </GridRow>,
      <GridRow style={{ marginTop: '5px' }} key="1">
        <GridColumn col={1} className="project-properties-short-circuit-checkbox-width">
          <CheckBox
            name="editable"
            value={variables.ShortCircuitTimesObject.time1.editable}
            onChange={(data) => dispatch(timeChange('editable', data.value, 1))}
          />
        </GridColumn>
        <GridColumn col={2}>
          <Input
            cols={[8]}
            type="number"
            onChange={(e) => dispatch(timeChange('value', e.value, 1))}
            className="info-project-input"
            name="value"
            disabled={!variables.ShortCircuitTimesObject.time1.editable}
            // TODO: Input, can't accept data
            data={variables.ShortCircuitTimesObject.time1}
            // showIconInvalid
            displayError
            additionalValidationData={{ id: 1 }}
          />
        </GridColumn>
      </GridRow>,
      <GridRow style={{ marginTop: '5px' }} key="2">
        <GridColumn col={1} className="project-properties-short-circuit-checkbox-width">
          <CheckBox
            name="editable"
            value={variables.ShortCircuitTimesObject.time2.editable}
            onChange={(data) => dispatch(timeChange('editable', data.value, 2))}
          />
        </GridColumn>
        <GridColumn col={2}>
          <Input
            cols={[8]}
            type="number"
            onChange={(e) => dispatch(timeChange('value', e.value, 2))}
            className="info-project-input"
            name="value"
            disabled={!variables.ShortCircuitTimesObject.time2.editable}
            // TODO: Input, can't accept data
            data={variables.ShortCircuitTimesObject.time2}
            displayError
            // showIconInvalid
            additionalValidationData={{ id: 2 }}
          />
        </GridColumn>
      </GridRow>,
      <GridRow style={{ marginTop: '5px' }} key="3">
        <GridColumn col={1} className="project-properties-short-circuit-checkbox-width">
          <CheckBox
            name="UseRatedCurrentToSizeBranches"
            value={variables.ShortCircuitTimesObject.time3.editable}
            onChange={(data) => dispatch(timeChange('editable', data.value, 3))}
          />
        </GridColumn>
        <GridColumn col={2}>
          <Input
            cols={[8]}
            type="number"
            onChange={(e) => dispatch(timeChange('value', e.value, 3))}
            className="info-project-input"
            name="value"
            disabled={!variables.ShortCircuitTimesObject.time3.editable}
            data={variables.ShortCircuitTimesObject.time3}
            displayError
            // showIconInvalid
            additionalValidationData={{ id: 3 }}
          />
        </GridColumn>
      </GridRow>,
    ];
  };

  const cosPhi = (): React.ReactElement => {
    return (
      <GridRow>
        <GridColumn col={2}>
          <Input
            type="number"
            // divStyle="options-select"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            // onFocusOut={inputFocusOutHandler}
            className="info-project-input"
            name="CosPhiIk3"
            label="DOCWEB_LABEL_SHORT_CIRCUIT_POWERFACTOR"
            data={variables}
            displayError
            step={0.1}
          />
        </GridColumn>
      </GridRow>
    );
  };

  const ik = (): React.ReactElement => {
    return (
      <GridColumn col={8}>
        <GridRow>
          <GridColumn col={4}>
            <Input
              cols={[7, 0]}
              type="number"
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              className="info-project-input"
              name="Ik1LNOverIk3Ratio"
              label="DOCWEB_LABEL_SHORT_CIRCUIT_IK_LN"
              data={variables}
              displayError
            />
          </GridColumn>
          <GridColumn col={4}>
            <Input
              cols={[7, 0]}
              type="number"
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              className="info-project-input"
              name="Ik1LPEOverIk3Ratio"
              label="DOCWEB_LABEL_SHORT_CIRCUIT_IK_LPE"
              data={variables}
              displayError
            />
          </GridColumn>
        </GridRow>
      </GridColumn>
    );
  };

  const { t } = useTranslation();
  return (
    <Grid>
      <GridRow>
        <GridColumn col={5}>
          <h4 style={{ marginBottom: '5px' }}>{t('DOCWEB_SHORT_CORCUIT_CALCULATIONAT')} </h4>
        </GridColumn>
      </GridRow>
      {times()}
      <GridRow>
        <GridColumn col={6}>
          {t('STRINGS_IDC_ICC3_TXT')}
          <hr />
        </GridColumn>
      </GridRow>
      {cosPhi()}
      <GridRow>
        <GridColumn col={8}>
          {t('DOCWEB_SHORT_CIRCUIT_PHASE')}
          <hr />
        </GridColumn>
      </GridRow>
      {ik()}
    </Grid>
  );
};

export default ShortCircuitOptionsContainer;
