/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import { Icon } from '@abb/abb-common-ux-react';
import { GridRow, GridColumn } from 'components/generic/grid';

import { IInputGrid } from 'types/components/inputContainer/grid';
import { Column } from 'types/components/grid';
import { useTranslation } from 'react-i18next';
// TODO: Check what properties are mandatory (not optional)
const InputGrid: FunctionComponent<IInputGrid> = ({ cols, label, labelLeft, icons, dimension, children }): React.ReactElement => {
  const { t } = useTranslation();
  const displayLabel = () => {
    return <label>{t(label ?? '')}</label>;
  };
  const renderIcons = () => {
    if (icons) {
      return icons.map((ic, key) => (
        <Icon key={key} name={`abb/${ic.name}`} style={ic.style} onClick={(e) => ic.onClick(e)} sizeClass="large" />
      ));
    }
    return undefined;
  };

  const standardInput = (colForInputDimensionStringId: Column | null) => {
    return (
      <GridRow>
        {labelLeft ? <GridColumn col={cols ? cols[1] : undefined}>{displayLabel()}</GridColumn> : undefined}
        <GridColumn col={cols ? cols[0] : undefined}>
          {children}
          {icons && icons.length > 0 ? renderIcons() : undefined}
        </GridColumn>
        {colForInputDimensionStringId ? (
          <GridColumn
            col={colForInputDimensionStringId}
            className="DOCweb_CommonUX_Label_Centered_for_ABB_Inputs"
            style={{ marginTop: '5px' }}
          >
            {/* not sure we need to translate displayed dimentions, but anyway will do it now, it's not a problem to delete {this.props.t()} from this 1 line */}
            <label>{t(dimension ?? '')}</label>
          </GridColumn>
        ) : (
          <></>
        )}
      </GridRow>
    );
  };

  // TODO - maybe add proper names to SCCurrents which we get from Petr and add separate component for multiple inputs?
  // instead of assigning styles here?

  // this is just to apply proper styles to multiple unputs in a row for cable

  let colForInputDimensionStringId: Column | null = null;
  if (dimension) {
    colForInputDimensionStringId = 1;
  }
  return standardInput(colForInputDimensionStringId);
};

export default InputGrid;
