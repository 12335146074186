import { ICurvesResponse } from 'types/api/curves';
import { IGetMvMacroResponse } from 'types/compute';
import { FunctionOption } from '.';

export const isFunctionOptions = (options: Array<FunctionOption> | Array<number | string>): options is Array<FunctionOption> =>
  typeof options[0] === 'object' && options[0].start !== undefined;

export const isGetMvMacroResponse = (response: IGetMvMacroResponse | ICurvesResponse): response is IGetMvMacroResponse => {
  return (response as IGetMvMacroResponse).jsonProps !== undefined;
};
