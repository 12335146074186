import CheckBoxList from 'components/generic/CheckBoxList';
import { Grid } from 'components/generic/grid';
import _ from 'lodash';
import { setProjectDirty } from 'project/actions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { IFamilyColor } from 'types/modal/projectProperties/familiesModals';
import { DisabledFamilies } from 'types/variables/store';
import { checkboxUpdateHandler } from './actions';

const OtherDevicesFamiliesComponent: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const variables = _.cloneDeep(useSelector((state) => state.variables));

  interface FamilyChangedState {
    [key: string]: boolean;
  }
  const [userChangedFamily, userChangedFamilyHandler] = useState({} as unknown as FamilyChangedState);

  const setUserChangedFamilies = (family: string): void => {
    if (userChangedFamily[family] !== undefined) {
      userChangedFamilyHandler({ [family]: !userChangedFamily });
    } else {
      userChangedFamilyHandler({ [family]: true });
    }
  };

  // eslint-disable-next-line consistent-return
  const checkFamiliesChanged = () => {
    // hope families and variants don't cross with names
    const isFamilyChanged = Object.keys(userChangedFamily).some((familyName) => userChangedFamily[familyName] === true);
    if (isFamilyChanged) {
      return dispatch(setProjectDirty(true));
    }
  };

  useEffect(() => {
    return checkFamiliesChanged();
  });

  const listRender = (
    label: string,
    list: string,
    disabledList: DisabledFamilies,
    cols: number[],
    showTooltip: boolean
  ): React.ReactElement => {
    const toolTip = showTooltip ? t('Family disabled; go to "Project settings/Circuit-breakers" to enable it.') : '';
    const familyColor: IFamilyColor = {};
    if (variables.ObsoleteFamilies && variables.DisabledProductFamilies) {
      variables.DisabledProductFamilies.name.forEach((family) => {
        familyColor[family] = 'grey';
      });
      variables.ObsoleteFamilies.name.forEach((family) => {
        familyColor[family] = '#CB6745';
      });
    }
    return (
      <CheckBoxList
        label={label}
        tableList={variables.AllProductsFamilies[list]}
        checkBoxHandler={(event) => {
          setUserChangedFamilies(event.name ?? '');
          dispatch(checkboxUpdateHandler(event, disabledList));
        }}
        valuesStorage={variables[disabledList].name}
        obsoleteList={variables.AllProductsFamilies.obsolette}
        style={familyColor}
        toolTip={toolTip}
      />
    );
  };

  return (
    <Grid>
      {listRender('DOCWEB_MODAL_DEVICE_FAMILIES_OTHER_RCR', 'residualCurrentBreaker', 'DisabledProductFamilies', [1, 11], true)}
      {listRender('STRINGS_NAME101', 'manualMotorStarter', 'DisabledProductFamilies', [1, 11], false)}
      {listRender('DOCWEB_MODAL_DEVICE_FAMILIES_OTHER_OLR', 'overloadRelay', 'DisabledProductFamilies', [1, 11], false)}
      {listRender('STRINGS_IDC_DISCONS_TXT', 'disconnector', 'DisabledProductFamilies', [1, 11], false)}
      {listRender('STRINGS_IDC_FUSEGEARS_TXT', 'fuse', 'DisabledProductFamilies', [1, 11], false)}
      {listRender('STRINGS_IDC_CONTS_TXT', 'contactor', 'DisabledProductFamilies', [1, 11], false)}
    </Grid>
  );
};

export default OtherDevicesFamiliesComponent;
