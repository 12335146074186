/* eslint-disable no-param-reassign */
import { batch } from 'react-redux';
import fileDownload from 'js-file-download';
import UserAPI from 'api/user';
import { Symbols as symbols2s } from 'project/utils/symbols';
import { getObsoleteFamilies } from 'actions/variables';
import { getUserProjectData, createUserProject, updateUserProject } from 'actions/user';
import { setComputeRunning, setProjectLoading, updateLoadStatus, pushNotification } from 'compute/actions';
import { showModal, showModalDialog } from 'ui/modals/actions';
import { deviceHasCurves } from 'curves/Constants';
import { getBom } from 'actions/billOfMaterials';
import attributesToSave from 'attributesToSave';
import { parseJSONProject, uuid } from 'actions/utils';
import { getCableData, getCableDataProjectProperties } from 'devices/lvCable/actions';
import FileReaderInput from 'react-file-reader-input';
import { xml2jsonWorker } from 'workers';
import PartnerHubAPI from 'api/partnerHubApi';
import { ThunkAction } from 'types/known-actions';
import { IProjectThunkActions } from 'types/project';
import { createAction } from 'redux-actions';
import { PROJECT } from 'types/constants.d';
import { Actions } from 'types/project/store.d';
import { ECellType, EDeviceObjectType, EDeviceType } from 'types/devices/enums.d';
import { ApplicationState } from 'types/store';
import { EProjectType } from 'types/project/enums.d';
import _ from 'lodash';
import i18n from 'i18n';
import { isIGetProjectInformationResponse } from 'types/user/type-guards';
import { FileReaderEventTarget } from 'types/logo';
import { ISaveUserProjectResponse } from 'types/api/user';
import { TFunction } from 'i18next';
import { curvesActions, getDeviceCurves } from 'curves/actions';
import { EPowerSupply } from 'types/standards/index.d';
import { IGetProjectInformationResponse } from 'types/api/partnerHubApi';
import { setPHProjectData, xmlValidation } from './utils';
import GraphMlKeys from '../graphMLKeys';
// eslint-disable-next-line import/no-cycle
import { processClientMessages } from './projectMessages';
import { frames } from '../../constants';
import {
  loadElements,
  //   checkManagedDeviceTypes,
} from '../projectTemplate';

export const SET_AUTO_COMPUTE = 'SET_AUTO_COMPUTE';
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA';
export const SET_PROJECT_DIRTY = 'SET_PROJECT_DIRTY';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_REPORT_FORCED = 'SET_REPORT_FORCED';
export const SET_PROJECT_INFO = 'SET_PROJECT_INFO';
export const SET_PROJECT_ERROR = 'SET_PROJECT_ERROR';
export const UPDATE_PROJECT_PARAM = 'UPDATE_PROJECT_PARAM';
export const UPDATE_DIAGRAM_FOR_PRINTING = 'UPDATE_DIAGRAM_FOR_PRINTING';
export const TRIGGER_MULTIPLE_SELECTION = 'TRIGGER_MULTIPLE_SELECTION';
export const SET_XSD_VALIDATION_ERRORS = 'SET_XSD_VALIDATION_ERRORS';
export const SET_PARTNER_HUB_DATA = 'SET_PARTNER_HUB_DATA';
export const UPDATE_ADDED_DEVICES = 'UPDATE_ADDED_DEVICES';
// const ENCRYPTION_KEY = 'MIIBOgIBAAJAchq4slLlqM6UQbaPOHC+T6Pb9f+oEjlkn8g6x08/TCUBizRGFyGjz/vL09a3dSZvx8Rnmwsjn8fhNyOCozFXYwIDAQABAkAqgMGjyzU4+BpJkBMxVu3NBKB+tciJYU+WJSOf8mkFl5K8pEh9kG5/zKnJfA1cJMKJLW24qOJvV1EOc5geRRxBAiEAxDT2TJzvs+yJgFcN+MiB8Rc+Pz0KN3s5b4psg3uKOZkCIQCU4IzdOle68w8zTb4k+uSE61Vipqtx3bty1KQiv3yOWwIhAIH8fHhBOGTA/k9yXSVG4R9yQKDwtYYQMvr8f+uTuv1xAiEAjF2Csnp1iL/tzvM3mWor1t6C7LYz4vGp8ERoVJKx/w0CIG59QqFzmAsHt3k5T3WWPCGSJ9U4xdRaZc8/yCfBeC+S';

export const projectActions = {
  setAutoCompute: createAction<Actions['setAutoCompute']['payload']>(PROJECT.SET_AUTO_COMPUTE),
  setSelectedDevice: createAction<Actions['setSelectedDevice']['payload']>(PROJECT.SET_SELECTED_DEVICE),
  setProjectData: createAction<Actions['setProjectData']['payload']>(PROJECT.SET_PROJECT_DATA),
  setProjectName: createAction<Actions['setProjectName']['payload']>(PROJECT.SET_PROJECT_NAME),
  setMacroConversionData: createAction<Actions['setMacroConversionData']['payload']>(PROJECT.SET_MACROCONVERSIONDATA),
  setNeoGearWizardData: createAction<Actions['setNeoGearWizard']['payload']>(PROJECT.SET_NEOGEARWIZARD),
  setProjectError: createAction<Actions['setProjectError']['payload']>(PROJECT.SET_PROJECT_ERROR),
  setAppVersion: createAction<Actions['setAppVersion']['payload']>(PROJECT.SET_APP_VERSION),
  setProjectDirty: createAction<Actions['setProjectDirty']['payload']>(PROJECT.SET_PROJECT_DIRTY),
  setProjectInfo: createAction<Actions['setProjectInfo']['payload']>(PROJECT.SET_PROJECT_INFO),
  setProjectPropagateDirty: createAction<Actions['setProjectPropagateDirty']['payload']>(PROJECT.SET_PROJECT_PROPAGATE_DIRTY),
  setProjectMessages: createAction<Actions['setProjectMessages']['payload']>(PROJECT.SET_PROJECT_MESSAGES),
  removeProjectMessages: createAction<Actions['removeProjectMessages']['payload']>(PROJECT.REMOVE_PROJECT_MESSAGES),
  loadProject: createAction<Actions['loadProject']['payload']>(PROJECT.LOAD_PROJECT),
  selectPage: createAction<Actions['selectPage']['payload']>(PROJECT.SELECT_PAGE),
  openPage: createAction<Actions['openPage']['payload']>(PROJECT.OPEN_PAGE),
  closePage: createAction<Actions['closePage']['payload']>(PROJECT.CLOSE_PAGE),
  updatePageCounter: createAction<Actions['updatePageCounter']['payload']>(PROJECT.UPDATE_PAGE_COUNTER),
  updatePagesOrder: createAction<Actions['updatePagesOrder']['payload']>(PROJECT.UPDATE_PAGES_ORDER),
  setReportForced: createAction<Actions['setReportForced']['payload']>(PROJECT.SET_REPORT_FORCED),
  setPartnerHubData: createAction<Actions['setPartnerHubData']['payload']>(PROJECT.SET_PARTNER_HUB_DATA),
  updateProjectParam: createAction<Actions['updateProjectParam']['payload']>(PROJECT.UPDATE_PROJECT_PARAM),
  triggerMultipleSelection: createAction<Actions['triggerMultipleSelection']['payload']>(PROJECT.TRIGGER_MULTIPLE_SELECTION),
  updateDiagramForPrinting: createAction<Actions['updateDiagramForPrinting']['payload']>(PROJECT.UPDATE_DIAGRAM_FOR_PRINTING),
};

const uploadErrorMessage = {
  title: 'DOCWEB_MESSAGEDIALOG_ERROR_TITLE',
  message: 'DOCWEB_MESSAGEDIALOG_WRONGPDFTXT',
};

const partnerHubLoadProjectError = {
  title: 'DOCWEB_MESSAGEDIALOG_ERROR_TITLE',
  message: 'DOCWEB_MESSAGEDIALOG_LOAD_ERROR_PH',
  onOk: () => {},
};

// TODO should also start compute if true
export const setAutoCompute = (autoCompute = false) => ({
  type: SET_AUTO_COMPUTE,
  autoCompute,
});

export const setSelectedDevice: IProjectThunkActions['setSelectedDevice'] =
  (id, undo = false): ThunkAction<void> =>
  (dispatch, getState) => {
    // const oldSelectedDeviceId = getState().project.selectedDeviceId;
    // set selected device to '', to trigger all
    // what should be triggered for previously selected device
    // because if new selected device is a same type - component just switch data source
    // and if there is an value error which should be reset to default - it doesn't because new pointed device has valid value
    const oldSelectedDeviceId = getState().project.selectedDeviceId;
    dispatch(projectActions.setSelectedDevice({ id: '' }));
    dispatch(projectActions.setSelectedDevice({ id }));
    // and right after set correctDevice
    if (id) {
      const state = getState();
      const selectedDevice = state.devices[id];
      if (
        !selectedDevice ||
        selectedDevice.deviceType === EDeviceType.TEXTAREA ||
        selectedDevice.deviceType === EDeviceType.LINE
      ) {
        return;
      }

      // if (!selectedDevice.initialized) {
      //   // initDeviceFilters(id, selectedDevice.deviceType, dispatch);
      // }

      if (
        !selectedDevice.isFreshlyInserted &&
        oldSelectedDeviceId !== id &&
        deviceHasCurves(selectedDevice.deviceType) &&
        !undo
      ) {
        if (
          !selectedDevice.initialized &&
          (selectedDevice.deviceType === EDeviceType.WIRELV || selectedDevice.deviceType === EDeviceType.WIREMV)
        ) {
          dispatch(curvesActions.setCurvesRequestRunning({ running: false }));
          const cablePromise = dispatch(getCableData(id));
          Promise.resolve(cablePromise)
            .then(() => {
              dispatch(getCurves());
            })
            .catch((error) => console.error(error));
        } else if (
          selectedDevice.objectType !== EDeviceObjectType.MvCircuitBreaker ||
          (selectedDevice.objectType === EDeviceObjectType.MvCircuitBreaker && selectedDevice.TripUnitModelName)
        ) {
          dispatch(getCurves());
        }
      }
    }
  };

const getCurves = (): ThunkAction<void> => (dispatch) => {
  batch(() => {
    dispatch(curvesActions.setCurrentCurves({ curve2Show: null, curves: null, params: null, curvesDrag: null }));
    dispatch(curvesActions.setCurvesRequestRunning({ running: false }));
    dispatch(getDeviceCurves());
  });
};

export const loadProject =
  (projectState: ApplicationState): ThunkAction<void> =>
  (dispatch) => {
    // const state = getState();
    projectState.devices = loadElements(projectState.devices);
    let projectType: EProjectType = EProjectType.LV;
    Object.keys(projectState.devices).forEach((deviceId) => {
      const device = projectState.devices[deviceId];
      if (
        !projectType &&
        device.type === ECellType.Device &&
        (device.objectType === EDeviceObjectType.PowerSupply || device.objectType === EDeviceObjectType.Genset)
      ) {
        if (device.objectType === EDeviceObjectType.Genset) {
          projectType = (device.NominalVoltage as number) >= 1000 ? EProjectType.MV : EProjectType.LV;
        } else {
          projectType =
            device.deviceType === EDeviceType.UTILITY_MV_SHORT || device.deviceType === EDeviceType.UTILITY_MV
              ? EProjectType.MV
              : EProjectType.LV;
        }
      }
    });
    // Setting type of new project LV or MV
    if (!projectType && _.isEmpty(projectState.devices)) {
      if (projectState.wizard.standards.powerSupply === EPowerSupply.LVU) {
        projectType = EProjectType.LV;
      } else if (projectState.wizard.standards.powerSupply === EPowerSupply.MVU) {
        projectType = EProjectType.MV;
      } else {
        // It's Generator
        projectType = projectState.wizard.generatorSettings.NominalVoltage > 1000 ? EProjectType.MV : EProjectType.LV;
      }
    }
    projectState.project.type = projectType;
    /**
     * Fetch default lvCable state
     */
    // dispatch(fetchDefaultCable(projectState.variables));

    // TODO: no longer used in DOC, check if used here
    // if (state.wizard.lvCable && state.wizard.lvCable.ScStandardList.length > 0) {
    //   projectState.lvCable = {
    //     ...projectState.lvCable,
    //     ...state.wizard.lvCable,
    //   };
    // }

    // // Expands the appropriate devices palette
    // let powerSupply = 'LVU';
    // // Find utility to know what type of powerSupply to use than we load project
    // const utilityId = Object.keys(projectState.devices).find((deviceId) => projectState.devices[deviceId].symbol == 'UTILITY' || projectState.devices[deviceId].symbol == 'UTILITY_MV_SHORT' || projectState.devices[deviceId].symbol == 'GENERATOR');
    // if (utilityId) {
    //   switch (projectState.devices[utilityId].symbol) {
    //   case 'UTILITY':
    //     powerSupply = 'LVU';
    //     break;
    //   case 'UTILITY_MV_SHORT':
    //     powerSupply = 'MVU';
    //     break;
    //   case 'GENERATOR':
    //     powerSupply = 'LVG';
    //     break;
    //   }
    // }

    /**
     * Now restore the state
     */
    batch(() => {
      dispatch(
        projectActions.loadProject({
          projectState,
        })
      );
      dispatch(setProjectLoading(false));
    });
  };

const sessionIntervalId: number | NodeJS.Timer = 0;

export const loadUserProject =
  (id: string, forceUnlock = false, initial = false): ThunkAction<void> =>
  (dispatch, getState) => {
    const sessionId = uuid();
    const sessionLastModified = new Date();
    if (sessionIntervalId !== 0) {
      clearInterval(sessionIntervalId);
    }
    if (!getState().compute.projectIsLoading) {
      dispatch(setProjectLoading(true));
    }
    dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_GETUSERPROJECT'), 0));
    dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_GETDATAFROMPH'), 10));
    return dispatch(getUserProjectData(id, sessionId, sessionLastModified, forceUnlock)).then((project) => {
      if (!project) {
        dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_NOINFO'), 20));
        console.warn('No information from Partner Hub!');
        setTimeout(() => {
          dispatch(setProjectLoading(false));
          const phError = _.cloneDeep(partnerHubLoadProjectError);
          phError.onOk = () => dispatch(loadUserProject(id, forceUnlock, initial));
          dispatch(showModalDialog(phError));
        }, 3000);
        if (window.localStorage.getItem('phLink')) {
          window.localStorage.removeItem('phLink');
        }
        return;
      }
      if (isIGetProjectInformationResponse(project) && project.id && !project.projectData) {
        batch(() => {
          dispatch(projectActions.setPartnerHubData({ partnerHubData: project }));
          dispatch(
            projectActions.setProjectInfo({
              info: {
                name: project.name,
                Customer: project.customerName,
              },
            })
          );
          dispatch(showModal('StartUpWizard'));
          dispatch(setProjectLoading(false));
        });
        if (window.localStorage.getItem('phLink')) {
          window.localStorage.removeItem('phLink');
        }
      } else {
        dispatch(showModal());
        dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_CHECKINGPROJECT'), 25));
        if (project.sessionIsValid === false && !forceUnlock) {
          dispatch(setProjectLoading(false));
          return;
        }
        if (forceUnlock && getState().modal.name === 'ProjectLock') {
          dispatch(showModal());
        }
        if (!project.projectData) {
          dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_ERRORNODATAFROMPH'), 40));
          console.warn('No project Data from Partner HUB');
          const phError = _.cloneDeep(partnerHubLoadProjectError);
          phError.onOk = () => dispatch(loadUserProject(id, forceUnlock, initial));
          dispatch(showModalDialog(phError));
          return;
        }
        if (!project.projectData.startsWith('<?xml')) {
          // eslint-disable-next-line no-param-reassign
          project.projectData = atob(project.projectData);
        }
        dispatch(xmlValidation(project))
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() =>
              xml2jsonWorker(project.projectData ?? '', getState()).then((projectJSON) => {
                dispatch(updateLoadStatus(i18n.t('DOCWEB_LOADING_FINISHING'), 75));
                let parsedProject = parseJSONProject(projectJSON) as ApplicationState;
                if (!parsedProject) {
                  dispatch(showModalDialog(uploadErrorMessage));
                  return;
                }
                if (isIGetProjectInformationResponse(project)) {
                  parsedProject = dispatch(setPHProjectData(parsedProject, project));
                }
                batch(() => {
                  dispatch(loadProject(parsedProject));
                  dispatch(getCableDataProjectProperties('Initial', true));
                });
                if (initial) {
                  dispatch(showModal());
                }
                batch(() => {
                  dispatch(getObsoleteFamilies(initial));
                  const projectName = (project as IGetProjectInformationResponse).id
                    ? `${project.projectName} - ${
                        (project.singleLineDiagramName as string) || (project as IGetProjectInformationResponse).configuratorName
                      }`
                    : project.projectName;
                  dispatch(projectActions.setProjectData({ id, name: projectName, sessionId: project.sessionId ?? '' }));
                });
                // if (window.location.href.indexOf('no-session') === -1 && !project.singleLineDiagramId) {
                //   sessionIntervalId = setInterval(() => {
                //     const projectState = getState().project;
                //     const sessionlastModified = new Date();
                //     if (projectState.id) {
                //       // dispatch(updateUserProjectSession(projectState.id, projectState.sessionId, sessionlastModified))
                //       dispatch(
                //         UserAPI.getProjectData(projectState.id, projectState.sessionId, sessionlastModified, 'false')
                //       ).then((sessionIsValid) => {
                //         if (!sessionIsValid) {
                //           let sessionExpired;
                //           if (sessionIsValid === undefined) {
                //             sessionExpired = true;
                //           }
                //           clearInterval(sessionIntervalId as number);
                //           // console.log('Session is not valid');
                //           dispatch(
                //             showModal('ProjectLock', {
                //               projectId: id,
                //               projectName: project.projectName,
                //               partnerHub: !!getState().project.singleLineDiagramId,
                //               sessionExpired,
                //             })
                //           );
                //           dispatch(setProjectLoading(false));
                //         }
                //       });
                //     }
                //   }, 5000);
                // }
              })
            );
          })
          .catch((error) => {
            if (window.localStorage.getItem('phLink')) {
              window.localStorage.removeItem('phLink');
            }
            console.error(error);
          });
      }
    });
  };

export const dumpProject =
  (
    newProject: Partial<ApplicationState> | null = null,
    stringify = true,
    isMacro = false,
    macroState?: Partial<ApplicationState>
  ): ThunkAction<string | (Partial<ApplicationState> & Record<string, unknown>)> =>
  (dispatch, getState) => {
    let state = newProject || getState();
    if (isMacro) state = { ...state, ...macroState };
    const dumpedState = {
      ...state,
      symbols: symbols2s,
      attrsToSave: attributesToSave,
      frames,
      graphKeys: GraphMlKeys,
    };
    if (stringify) {
      return JSON.stringify(dumpedState);
    }
    return dumpedState;
  };

export const downloadProject =
  (exportType: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const fileContent = dispatch(dumpProject(null, false)) as ApplicationState & Record<string, unknown>;
    const fileName = `${getState().project.name}.docweb`;

    if (!Object.keys(getState().devices).find((id) => getState().devices[id].page !== 0)) {
      dispatch(
        showModalDialog({
          title: 'Please add device',
          message: 'You cannot save project without devices.',
        })
      );
      return;
    }

    UserAPI.convertJson(fileContent)
      .then((data) => {
        switch (exportType) {
          case 'docweb':
            fileDownload(data, fileName);
            break;
          default:
            break;
        }
      })
      .catch((error) => console.error(error));
  };

export const confirmUploading =
  (e: React.ChangeEvent<HTMLInputElement>, results: FileReaderInput.Result[]): ThunkAction<void> =>
  (dispatch, getState) => {
    const event = results[0][0] as ProgressEvent<FileReaderEventTarget>;
    const state = getState();
    const projectGraphML = event.target?.result;
    if (!projectGraphML) {
      // TODO: add some kind of notification of invalid project i suppose
      return;
    }
    const curvesInULMode = state.variables.MainStandard === 'UL';
    const projectIsUL = projectGraphML.includes('<data key="MainStandard">UL</data>');

    if (results[0][1].name.split('.')[results[0][1].name.split('.').length - 1] === 'docweb') {
      if (curvesInULMode !== projectIsUL) {
        dispatch(
          showModalDialog({
            // buttons: 'okcancel',
            message: `You are trying to open ${projectIsUL ? 'UL' : 'IEC'} project in ${
              curvesInULMode ? 'UL' : 'IEC'
            } mode so we will switch it automatically.`,
            title: 'WARNING!',
            onOk: () => dispatch(uploadProject(projectGraphML, state)),
          })
        );
      } else {
        dispatch(uploadProject(projectGraphML, state));
      }
    } else {
      dispatch(showModalDialog(uploadErrorMessage));
    }
  };

export const uploadProject =
  (projectGraphML: string, state: ApplicationState): ThunkAction<void> =>
  (dispatch) => {
    dispatch(setProjectLoading(true));
    // const cryptr = new Cryptr(ENCRYPTION_KEY);
    // const decryptedString = cryptr.decrypt(projectGraphML);
    const partnerHubData = state.project.partnerHub;
    dispatch(xmlValidation({ projectData: projectGraphML }))
      .then(() => {
        xml2jsonWorker(projectGraphML, state)
          .then((projectJSON) => {
            let projectState = parseJSONProject(projectJSON) as ApplicationState;
            if (!projectState) {
              dispatch(showModalDialog(uploadErrorMessage));
              dispatch(setProjectLoading(false));
              return;
            }
            if (partnerHubData.toolUrl && partnerHubData.singleLineDiagramId && partnerHubData.projectId) {
              projectState = dispatch(
                setPHProjectData(projectState, {
                  id: partnerHubData.singleLineDiagramId,
                  configuratorName: partnerHubData.singleLineDiagramName,
                  ...partnerHubData,
                })
              );
              batch(() => {
                dispatch(loadProject(projectState));
                dispatch(getObsoleteFamilies(false));
              });
              updateUserProject();
            } else {
              // eslint-disable-next-line consistent-return
              return (
                dispatch(createUserProject(projectState.project.name, projectGraphML))
                  .then((project) => {
                    dispatch(loadUserProject((project as ISaveUserProjectResponse).projectId.toString(), true));
                  })
                  // this will save also the project.id to the database
                  .then(() => updateUserProject())
              );
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
    // TODO catch parse error
    // const projectState = JSON.parse(finalJson);
  };

// export const importEDesign = (e: Event, results: FileReaderInput.Result[]): ThunkAction<void> => (dispatch, getState) => {
//   dispatch(setProjectLoading(true));
//   dispatch(updateLoadStatus('Sending edesign project...', 25));
//   const event = results[0][0] as ProgressEvent<FileReaderEventTarget>;
//   const res = event.target?.result;
//   if (!res) {
//     // TODO: add some notification, if event used
//     return;
//   }
//   const encodedEdesignFile = window.btoa(event.target?.result);
//   dispatch(updateLoadStatus('Converting e-Design project...', 30));
//   return Doc3API.convertEdesign(encodedEdesignFile)
//     .then((convertResult) => {
//       if (!convertResult) {
//         dispatch(showModalDialog({
//           title: 'Error',
//           message: `Failed to convert e-Design project`,
//         }));
//         dispatch(setProjectLoading(false));
//         return;
//       }
//       const notManagedDeviceTypes = checkManagedDeviceTypes(convertResult.scheme, convertResult.symbols);
//       if (notManagedDeviceTypes.length > 0) {
//         dispatch(showModalDialog({
//           title: 'Error',
//           message: `Failed to convert e-Design project. DocWeb not supporting these devices: ${notManagedDeviceTypes.join(', ')}`,
//         }));
//         dispatch(setProjectLoading(false));
//         return;
//       }
//       dispatch(updateLoadStatus('Conversion of project finished ', 80));
//       const projectState = projectTemplate(convertResult);
//       const convertWarnings = _.cloneDeep(projectState.convertWarnings);
//       delete projectState.convertWarnings;
//       const project = dispatch(dumpProject(projectState, false));
//       const state = getState();
//       if (state.project.singleLineDiagramId) {
//         dispatch(loadProjectFromFiles(project, state));
//         return;
//       }
//       UserAPI.convertJson(project)
//         .then((graphML) => {
//           dispatch(updateLoadStatus('Drawing project...', 90));
//           dispatch(createUserProject(projectState.project.name, graphML))
//             .then((project) => {
//               dispatch(loadUserProject(project.projectId, true));
//               if (convertWarnings.Motors.length > 0) {
//                 dispatch(showModalDialog({
//                   title: 'Warning',
//                   message: `These motors was converted to LOAD: ${convertWarnings.Motors.join(', ')}`,
//                 }));
//                 dispatch(setProjectLoading(false));
//               }
//             })
//           // this will save also the project.id to the database
//             .then(() => updateUserProject());
//         });
//     });
// };

export const setProjectName =
  (name: string): ThunkAction<void> =>
  (dispatch, getState) => {
    return UserAPI.checkProjectName({
      UserId: getState().user.userInternalId,
      ProjectName: name,
    }).then((valid) => {
      if (window.app) {
        if (valid) {
          dispatch(setProjectError('nameError', 'nameError', undefined));
        } else {
          dispatch(setProjectError('nameError', 'nameError', `Project "${name}" already exists`));
          dispatch(
            showModalDialog({
              title: 'Error',
              message: `Project "${name}" already exists. Please enter valid project name`,
            })
          );
        }
      }
      dispatch(
        projectActions.setProjectName({
          name,
        })
      );
    });
  };

export const setProjectDirty =
  (dirty = false, propagateId = ''): ThunkAction<void> =>
  (dispatch) => {
    batch(() => {
      dispatch(projectActions.setProjectDirty({ dirty, ProjectIsComputed: !dirty, propagateId }));
      if (dirty) {
        dispatch(setComputeRunning(false));
        // dispatch(setPropagateRunning(false));
      }
    });
  };

export const setProjectError =
  (name: string, tabName: string, error?: string): ThunkAction<void> =>
  (dispatch) => {
    batch(() => {
      dispatch(projectActions.setProjectError({ error: error ?? '', name, tabName }));
      dispatch(processClientMessages());
    });
  };

export const triggerMultipleSelection: IProjectThunkActions['triggerMultipleSelection'] =
  (multipleSelection) => (dispatch, getState) => {
    if (getState().project.multipleSelection === multipleSelection) {
      return;
    }
    dispatch(projectActions.triggerMultipleSelection({ multipleSelection }));
  };

// disabled
// export const saveProjectToDXF = () => (dispatch, getState) => {
//   const state = getState();
//   if (state.project.id) {
//     const preparedDXF = saveToDXF(state);
//     const request = {'preparedDxf': preparedDXF};
//     return ExportProjectAPI.convertProjectToDXF(request)
//       .then((projectInDXF) => {
//         const fileContent = projectInDXF;
//         const fileName = getState().project.name + '.dxf';
//         fileDownload(fileContent, fileName);
//       });
//   }
// };

// part of disabled import EDesign
// const loadProjectFromFiles = (projectState: ApplicationState, state: ApplicationState): ThunkAction<void> => (dispatch, getState) => {
//   projectState.project.name = state.project.name;
//   projectState.project.id = state.project.id;
//   projectState.project.singleLineDiagramId = state.project.singleLineDiagramId;
//   batch(() => {
//     dispatch(loadProject(projectState));
//     dispatch(getObsoleteFamilies(false));
//   });
//   updateUserProject();
// };

export const saveToBom =
  (t: TFunction): ThunkAction<void> =>
  (dispatch, getState) => {
    const partnerHubState = getState().project.partnerHub;
    if (partnerHubState.hookUrlInsertDevices && !getState().compute.bomRequestIsRunning) {
      const hookUrl =
        partnerHubState.hookUrlInsertDevices.indexOf('https://') === -1
          ? partnerHubState.hookUrlInsertDevices.replace('http://', 'https://')
          : partnerHubState.hookUrlInsertDevices;
      const billOfMaterials = dispatch(getBom());
      if (billOfMaterials.length > 0) {
        const bomStructure = {
          ProjectId: partnerHubState.projectId,
          Id: partnerHubState.singleLineDiagramId,
          BomId: partnerHubState.bomId,
          User: partnerHubState.user,
          Product: billOfMaterials,
        };
        const requestBody = {
          hookUrlInsertDevices: hookUrl,
          bomData: bomStructure,
        };
        PartnerHubAPI.saveToBom(requestBody)
          .then((bomResponse) => {
            if (!partnerHubState.bomId) {
              dispatch(projectActions.setPartnerHubData({ partnerHubData: { bomId: bomResponse.id } }));
            }
            dispatch(pushNotification('success', t('DOCWEB_SAVETOBOM_SUCCESS')));
          })
          .catch(() => {
            dispatch(pushNotification('alarm', t('DOCWEB_SAVETOBOM_FAIL')));
          });
      } else {
        dispatch(
          showModalDialog({
            title: 'DOCWEB_MESSAGE_WARNING_NO_BOM_DEVICES',
            message: `DOCWEB_MESSAGE_WARNING_ADD_BOM_DEVICES`,
          })
        );
      }
    } else {
      console.warn('No hook url for inserting devices!');
    }
  };

export const isProjectFromDocWeb = (): ThunkAction<boolean> => (dispatch, getState) => {
  const { devices, docWebData, user } = getState();
  const utilityPresent = Object.keys(devices).find(
    (deviceId) => devices[deviceId].objectType === EDeviceObjectType.PowerSupply
    // devices[deviceId].deviceType === 'app.UTILITY' || devices[deviceId].deviceType === 'app.UTILITY_MV_SHORT' || devices[deviceId].deviceType === 'app.GENERATOR' || devices[deviceId].deviceType === 'app.UTILITY_MV
  );
  const connectionsPresent = Object.keys(docWebData.connections).length > 0;
  const isUserViewPresent = user.View && Object.keys(user.View).length > 0;
  return !!(utilityPresent || connectionsPresent || isUserViewPresent);
};

export const updateProjectParam = (name: string, value: unknown) => ({
  type: UPDATE_PROJECT_PARAM,
  name,
  value,
});
