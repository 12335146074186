import { ECustomCurveOption } from 'types/curves/enums.d';
import { ICustomCurve } from 'types/curves';

export const newDeviceState = {
  name: '',
  option: ECustomCurveOption.Curve,
  selectedDiagram: '',
  curves: {},
  voltage: 400,
} as ICustomCurve;
