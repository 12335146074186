enum CurveTypes {
  TFFF = 'I-t LLL-LL',
  TFF = 'TFF', // not used now
  TFN = 'I-t LN',
  TFPE = 'I-t LPE',
  EFFF = 'I-IІt LLL-LL',
  EFF = 'EFF', // not used now
  EFN = 'I-IІt LN',
  EFPE = 'I-IІt LPE',
  CL = 'Peak',
}

export default CurveTypes;
