import { ECellType, EDeviceObjectType, EDeviceType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  type: ECellType.BlockDevice,
  symbol: '',
  deviceType: EDeviceType.BLOCK_UTILITY,
  objectType: EDeviceObjectType.PowerSupply,
  ObjectId: 'BU1',
};
