import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';
import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'MMSMO',
  objectType: EDeviceObjectType.MmsMo,
  deviceType: EDeviceType.MMS,

  // General info
  Name: '',
  Description: '',
  Standard: 'IEC 60947-2',
  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
  Uref: 400.0,
  Locked: false,
  PreFiltersChanged: true,
  // Filters //////////////

  // Family
  FamilyFilter: '',
  familyFilterList: [],

  familyOutput: '',
  familyOutputList: [],

  // PolesNumber
  PolesFilter: '',
  polesFilterList: ['4P'],
  polesOutput: '',
  polesOutputList: [],

  // Release
  ReleaseFilter: 'All possibilities',
  releaseFilterList: [],
  releaseOutput: '',
  releaseOutputList: [],

  // Type
  Type: '',
  TypeFilter: 'MMSMO',
  TypeList: ['MMSMO', 'MMSTM'],
  TypeListTranslate: ['Short Circuit protection only', 'Overload and Short Circuit protection'],

  // Version
  VersionFilter: 'F',
  versionFilterList: [],
  versionOutput: '',
  versionOutputList: [],

  CoordinationFilter: 'Not requested',
  CoordinationFilterList: ['Not requested'], // Selectivity, Back-up

  // FamilyList: {},
  sizeOutput: -1,
  sizeOutputList: [],

  icuOutput: -1,
  icuOutputList: [],

  inOutput: -1,
  inOutputList: [],
  ProtectionAutoAssignOff: false,
  terminalsOutput: '',
  terminalsOutputList: [],
  DevicesList: [],
  BreakersRcbList: [],
  DevicesBatchList: [],
  DeviceId: 0,
  ProductDescription: '',
  ProductIdList: null,
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,
  deviceIcw1s: [],
  deviceStartIndex: 0,
  deviceEndIndex: 0,
  initialized: false,
  requestRunning: false,
  IsOffInScenario: {
    name: [],
  },
  Relations: [],
  FrameFilter: '',
  frameFilterList: [],
  frameOutput: '',
  frameOutputList: [],
  tripUnitTypeOutput: '',
  tripUnitTypeOutputList: [],
};
