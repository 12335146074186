export default [
  'type',
  'symbol',
  'page',
  'x',
  'y',
  // Busbar
  'x2',
  'y2',
  'Coords',
  'rotated',
  'ObjectId',
  'ObjectSign',
  'ObjectStatus',
  'Locked',
  'IsOffInScenario',
  'IsOff',
  'UserDescription1',
  'UserDescription2',
  'Uref',
  'LineType',
  'EarthingSystem',
  'SwitchBoardName',
  'NominalPower',
  'NominalVoltage',
  'CosPhiNominal',
  'CosPhiRequested',
  'LoadCurrent',
  'LoadCurrentForCurves',
  'ComputedVoltage',
  'ComputedPhaseShift',
  'ComputedCosPhi',
  'ComputedP',
  'ComputedQ',
  'UoNominal',
  'UoComputed',
  'LoadCurrentL1',
  'LoadCurrentL2',
  'LoadCurrentL3',
  'CosPhiL1',
  'CosPhiL2',
  'CosPhiL3',
  'IbNeutral',
  'CosPhiNeutral',
  'GroundingResistance',
  'MaxContactVoltage',
  'ProductDescription',
  'ProductIdList',
  'OverLoadProtectionObjectId',
  'ShortCircuitTerminal1ProtectionObjectId',
  'ShortCircuitTerminal2ProtectionObjectId',
  'IndirectTouchTerminal1ProtectionObjectId',
  'IndirectTouchTerminal2ProtectionObjectId',
  'BackupTerminal1ObjectId',
  'BackupTerminal2ObjectId',
  'BackupTerminal1GrantedCurrent',
  'BackupTerminal2GrantedCurrent',
  'Discrimination',
  'ProtectionAutoAssignOff',
  'BackupDeviceNumber',
  'DiscriminationDeviceNumber',
  'MotorFeederContactorId',
  'ParallelFeederSourceId',
  'ParallelFeederCableId',
  'ScTheveninL3I',
  'ScTheveninL3Ip',
  'ScTheveninL2I',
  'ScTheveninL2Ip',
  'ScTheveninLNeutralI',
  'ScTheveninLNeutralIp',
  'ScTheveninLPEI',
  'ScTheveninLPEIp',
  'ScTheveninL3IMin',
  'ScTheveninL2IMin',
  'ScTheveninLNeutralIMin',
  'ScTheveninLPEIMin',
  'Terminal0ScTheveninL3I',
  'Terminal1ScTheveninL3I',
  'Terminal0ScTheveninL2I',
  'Terminal1ScTheveninL2I',
  'Terminal0ScTheveninLPEI',
  'Terminal1ScTheveninLPEI',
  'Terminal0ScTheveninLNeutralI',
  'Terminal1ScTheveninLNeutralI',
  'Terminal0ScTheveninL3IMin',
  'Terminal1ScTheveninL3IMin',
  'Terminal0ScTheveninL2Imin',
  'Terminal1ScTheveninL2IMin',
  'Terminal0ScTheveninLNeutralIMin',
  'Terminal1ScTheveninLNeutralIMin',
  'Terminal0ScTheveninLPEIMin',
  'Terminal1ScTheveninLPEIMin',
  'ScIEC61363IsecondK',
  'ScIEC61363Ik',
  'ScIEC61363Ip',
  'ScIEC61363IAc',
  'ScIEC61363IDc',
  'ScIEC61363Ikt',
  'Terminal0ScIEC61363IAc',
  'Terminal1ScIEC61363IAc',
  'Terminal0ScIEC61363IAcMin',
  'Terminal1ScIEC61363IAcMin',
  'ScIEC61363IAcMin',
  'ScIEC60909L3ISecondK',
  'ScIEC60909L3Ik',
  'ScIEC60909L3Ip',
  'ScIEC60909L3Ib',
  'ScIEC60909L3Idc',
  'ScIEC60909L3IbAsym',
  'ScIEC60909L2ISecondK',
  'ScIEC60909L2Ik',
  'ScIEC60909L2Ip',
  'ScIEC60909L2Ib',
  'ScL3IbForCurves',
  'ScLPEIbForCurves',
  'ScIEC60909L2Idc',
  'ScIEC60909L2IbAsym',
  'ScIEC60909LPEISecondK',
  'ScIEC60909LPEIk',
  'ScIEC60909LPEIp',
  'ScIEC60909LPEIb',
  'ScIEC60909LPEIdc',
  'ScIEC60909LPEIbAsym',
  'ScIEC60909LNeutralISecondK',
  'ScIEC60909LNeutralIk',
  'ScIEC60909LNeutralIp',
  'ScIEC60909LNeutralIb',
  'ScIEC60909LNeutralIdc',
  'ScIEC60909LNeutralIbAsym',
  'ScIEC60909L3IbMin',
  'ScIEC60909L2IbMin',
  'ScIEC60909LNeutralIbMin',
  'ScIEC60909LPEIbMin',
  'Terminal0ScIEC60909L3Ib',
  'Terminal1ScIEC60909L3Ib',
  'Terminal0ScIEC60909L2Ib',
  'Terminal1ScIEC60909L2Ib',
  'Terminal0ScIEC60909LNIb',
  'Terminal1ScIEC60909LNIb',
  'Terminal0ScIEC60909LPEIb',
  'Terminal1ScIEC60909LPEIb',
  'Terminal0ScIEC60909L3IbMin',
  'Terminal1ScIEC60909L3IbMin',
  'Terminal0ScIEC60909L2IbMin',
  'Terminal1ScIEC60909L2IbMin',
  'Terminal0ScIEC60909LNIbMin',
  'Terminal1ScIEC60909LNIbMin',
  'Terminal0ScIEC60909LPEIbMin',
  'Terminal1ScIEC60909LPEIbMin',
  'ScLiveMax',
  'ScLivePeak',
  'ScLiveMin',
  'ScNeutralMax',
  'ScNeutralPeak',
  'ScNeutralMin',
  'ScPEMax',
  'ScPEPeak',
  'ScPEMin',
  'Termina1LteAtMaximumScCurrent',
  'Termina2LteAtMaximumScCurrent',
  'TripUnit',
  'BestTripUnitTolerances',
  'UserCoordination',
  'CurveID',
  'ShowScMax',
  'ShowScMin',
  // now breaks saving if present (curves show control tab checks)
  'ShowImax',
  'ShowIB',
  'ShowIz',
  'OverLoadCheckDone',
  'ShortCircuitCheckDone',
  'IndirectTouchCheckDone',
  'DiscriminationCheckDone',
  'Terminal1ShortCircuitCheckDone',
  'Terminal1IndirectTouchCheckDone',
  'Terminal2ShortCircuitCheckDone',
  'Terminal2IndirectTouchCheckDone',
  'IBUsed',
  'CosPhiUsed',
  'CarryingCapacityIz',
  'NeutralCarryingCapacityIz',
  'PECarryingCapacityIz',
  'CableLength',
  'PELength',
  'CableLengthIFE',
  'PeLengthIFE',
  'Conductor',
  'Insulator',
  'PEConductor',
  'PEInsulator',
  'ScreenConductor',
  'CableType',
  'PEType',
  'ScreenType',
  'InstallationMethod',
  'InstallationPlace',
  'InstallationDetails',
  'InstallationItem',
  'InstallationScheme',
  'InstallationLayout',
  'ConduitRow',
  'ConduitColumn',
  'InstallationEnvironment',
  'ThD',
  'IFEClass',
  'PEPresent',
  'PEWithLives',
  'PELikeLives',
  'DoubleInsulation',
  'SymmetryRespected',
  'dUCheckOn',
  'dUMax',
  'dUMaxIFE',
  'VoltageDrop',
  'IzIbRatio',
  'CarryingCapacityIzCorrectionFactor',
  'CableCarryingCapacityUserFactor',
  'InstallationFactor',
  'TemperatureFactor',
  'EnvironmentFactor',
  'CircuitsFactor',
  'ConduitsFactor',
  'ThdFactor',
  'AsymmetricLayOutFactor',
  'AmbientTemperature',
  'WorkingTemperature',
  'MaxWorkingTemperature',
  'FaultTemperatureUp300',
  'FaultTemperatureOver300',
  'FaultMaxTemperature',
  'IFETemperature',
  'NotFinal',
  'LiveCordsNumber',
  'NeutralCordsNumber',
  'PECordsNumber',
  'LiveCrossSection',
  'NeutralCrossSection',
  'PECrossSection',
  'ScreenCrossSection',
  'LiveResistance',
  'LiveReactance',
  'LiveResistance20',
  'LiveResistanceFaultTemperature',
  'NeutralResistance',
  'NeutralReactance',
  'NeutralResistance20',
  'NeutralResistanceFaultTemperature',
  'PEResistance',
  'PEReactance',
  'PEResistance20',
  'PEResistanceFaultTemperature',
  'LiveK',
  'LiveKFaultTemperature',
  'PEK',
  'NeutralK',
  'NeutralKFaultTemperature',
  'KsquaredSsquaredAtWorkingTemp',
  'InsideDeltaFactor',
  'LiveKsquaredSsquared',
  'NeutralKsquaredSsquared',
  'PEKsquaredSsquared',
  'CrossSectionDescription',
  'BuswayLiveR',
  'BuswayLiveRzero',
  'BuswayNeutralRzero',
  'BuswayNeutralX',
  'BuswayNeutralR',
  'BuswayPERzero',
  'BuswayPER',
  'BuswayPEX',
  'PowerLoss',
  'BuswayLivePeakCurrent',
  'BuswayNeutralPeakCurrent',
  'IkMax',
  'IkMin',
  '3I0Min',
  '3I0Max',
  '3I0To',
  'Ce',
  'TypeFilter',
  'FamilyFilter',
  'PolesFilter',
  'VersionFilter',
  'ReleaseFilter',
  'RcdTypeFilter',
  'RcdVersionFilter',
  'IdnFilter',
  'UimpRequested',
  'InOverIBMinfactor',
  'ServiceOrUltimateBreakingCapacity',
  'IsParallel',
  'FuseLinkFilter',
  'FuseSizeFilter',
  'ContactorService',
  'OperationsLifeSpan',
  'OperationFrequency',
  'IcwFilter',
  'StepFilter',
  'MvDisconnectorSubType',
  'MvSwitchBoardName',
  'In',
  'Icu',
  'Ics',
  'Icm',
  'Icw',
  'Installation',
  'TripUnitType',
  'Terminals',
  'Uimp',
  'RcbModelName',
  'TripTimeFinalCircuits',
  'TripTimeOtherCircuits',
  'Id',
  'Td',
  'Ith',
  'Im',
  'IdMin',
  'TdMin',
  'Iu',
  'FuseLink',
  'FuseSize',
  'FuseHolderModelName',
  'ContactorIe',
  'PowerSourceTransformer',
  'CurrentTransfomerPrimaryIn',
  'HomopolarTransformerPrimaryIn',
  'CurrentTransfomerSecondaryIn',
  'HomopolarTransformerSecondaryIn',
  'CurrentTransformerModelName',
  'HomopolarTransformerModelName',
  'TripUnitModelName',
  'VoltageTransformerModelName',
  'TrafoEncloseModelName',
  'MVAccessoriesPresent',
  'IsMVAccessoriesPlaceHolder',
  'TypicalUnitId',
  'TypicalUnit',
  'TypicalUnitName',
  'TypicalUnitSign',
  'PanelNumber',
  'MvMacroPartMark',
  'DuplicateOf',
  'FuseModelName',
  'SwitchBoardFamily',
  'Pn',
  'Uf',
  'VoltageDropMaX',
  'VoltageDrop',
  'MotorFeederStarterTypeFilter',
  'MotorFeederCoordinationTypeFilter',
  'MotorFeederProtectionFilter',
  'MotorFeederOlrFilter',
  'MotorFeederContactorFilter',
  'StarterType',
  'StarterVariant',
  'CoordinationType',
  'MotorFeederType',
  'Eta',
  'IlrInRatio',
  'MotorPhases',
  'PolesNumber',
  'CosPhiStart',
  'IStart',
  'tStart',
  'CmCnRatio',
  'CstartCnRatio',
  'Rr',
  'Xr',
  'Rs',
  'Xs',
  'I0InRatio',
  'ISecondK',
  'CosPhiSc',
  'ISecondKLN',
  'CosPhiScLN',
  'ISecondKLE',
  'CosPhiScLE',
  'Sk',
  'SkPE',
  'SkN',
  'GensetType',
  'Ra',
  'XdSecond',
  'XqSecond',
  'XdFirst',
  'Xd',
  'XSecond',
  'X0',
  'TdSecond',
  'TdFirst',
  'StarCenterAvailable',
  'IsPq',
  'IScInRatio',
  'UpsNoByPass',
  'BatteryChargingCurrent',
  'NormalizedR',
  'NormalizedX',
  'NormalizedZ',
  'Sr',
  'uRr',
  'ukr',
  'PrimaryWindingUn',
  'SecondaryWindingUn',
  'PrimaryWindingLineType',
  'SecondaryWindingLineType',
  'PrimaryWindingEarthingSystem',
  'SecondaryWindingEarthingSystem',
  'PrimaryWindingCouplingType',
  'SecondaryWindingCouplingType',
  'VoltageRegulatorPlug',
  'TransformerSecondaryWindingNoLoadU',
  'NormalizedZkt',
  'kt',
  'xt',
  'TrafoR',
  'TrafoX',
  'TrafoZ',
  'TrafoZkt',
  'DemandFactor',
  'LinkName',
  'SwitchBoardTemperature',
  'McbStandard',
  'SegregationForm',
  'TerminalType',
  'UsageClass',
  'UimpL3',
  'UimpLNeutral',
  'EnvironmentTemperature',
  'SwitchBoardInsideTemperature',
  'PlantAddress',
  'PlantCompany',
  'PlantCustomer',
  'PlantDate',
  'PlantDrafter',
  'PlantNote1',
  'PlantNote2',
  'PlantName',
  'PlantRevisionNumber',
  'MaxContactVoltage',
  'CurrentStamp',
  'ProjectIsComputed',
  'PlantEarthingSystem',
  'PlantFrequency',
  'PlantLineType',
  'PlantGroundResistance',
  'PlantLvUn',
  'PlantMvUn',
  'LiveAutoBalance',
  'ComputeAllScenarios',
  'PreserveConstantCurrrentLoads',
  'UseRatedCurrentToSizeBranches',
  'NeutralOptimization',
  'CableStandard',
  'UseOnlySomeSections',
  'ShortCircuitStandard',
  'ShortCircuitTimes',
  'PowerSupplyVoltageTolerance',
  'OverExciting',
  'BreakerFamilyAccordingToIB',
  'ChooseAcbThreshold',
  'ChooseMcbThreshold',
  'UseDerating',
  'ChooseElectronic',
  'ChooseElectronicThreshold',
  'CbDefaultPolesForLLLN',
  'CbDefaultPolesForLN',
  'DisabledProductFamilies',
  'DisabledProductVariants',
  'MinBreakingCapacityForMCCB',
  'MinBreakingCapacityForMCB',
  'IndirectTouchTripTimes',
  'CosPhiIk3',
  'Ik1LNOverIk3Ratio',
  'Ik1LPEOverIk3Ratio',
  'TransformersNumber',
  'TransformersSr',
  'TransformersUkr',
  'CapacityToGround',
  'ShortCircuitCurrentToGround',
  'PetersensCoilReactance',
  'PetersensCoilsPresent',
  'PetersensCoilResistance',
  'PowerSourceType',
  'MvAvailable',
  'BackUpRequired',
  'DiscriminationRequired',
  'AvailableDiscrimination',
  'DiscriminationLevel',
  'BackupLevel',
  'AvailableBackup',
  'LabelsVisibility',
  'Pages',
  'Scenarios',
  'Symbols',
  'PageFrames',
  'svgContent',
  'macroId',
  'macroDescription',

  // Added by Front-End (Out of document)
  'name',
  'Customer',
  'PlantFile',
  'PlantDesigner',
  'PlantBoard',
  'PlantProject',
  'PlantRev1',
  'PlantRev2',
  'PlantRev3',
  'PlantSerial',
  'PlantApproval',
  'PlantDrawingNumber',
  'PlantMainCode',
  'PlantNote3',
  'description',
  'appVersion',
  'feederId',
  'projectLogo',

  // textArea
  'Note',
  'AllowFreeTransform',
  'TextColor',
  'BorderColor',
  'FillColor',
  'Height',

  // Lines
  'Color',
  'Width',

  // curves
  // 'showImax',
  'MainStandard',

  // MVSWitchboards
  'MvPanelBreakingTechnology',
  'MvPanelUr',
  'MvPanelIr',
  'MvPanelIcw',
  'TransformerStandard',
  'MvBoardMain',
  'MvBoardLocation',
  'MvBoardFlexibility',
  'MvBoardEasyAccess',
  'MvBoardInTank',
  'MvBoardWithdrawable',
  'MvBoardCables',
  'MvPanelSelectionBy',
  'MvPanelApplication',
  'MvPanelShowAdvanced',
  'MvPanelProtectionFunctions',
  'MvPanelCommunicationProtocol',
  'MvPanelIsCEI',
  'MvPanelMainOnly',
  'MvPanelSensorsType',
  'FunctionalEndurance',
  'ISecondKMin',
  'SkMin',
  'ShortCircuitCurrentToGroundMin',
  'NonStandardSCNDTProtection',
  'SCProtectionRequested',
  'NDTProtectionRequested',
  'InsulationTransformerZone',
  'TrafoPowerIncrease',
  'groupId',

  // LV switchboards
  'LvSwitchboardData',
];
