import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';
import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'SOFTSTARTER',
  objectType: EDeviceObjectType.SoftStarterGeneric,
  deviceType: EDeviceType.SOFTSTARTER,

  // General info
  Name: '',
  Description: '',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400.0,
  Locked: false,
  PreFiltersChanged: true,
  ProductDescription: '',
  ProductIdList: null,
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,

  FamilyFilter: '',
  familyFilterList: [],

  familyOutput: '',
  familyList: [],

  RatedControlSupplyVoltage: '',
  RatedControlSupplyVoltageList: [],

  DevicesList: [],
  DeviceId: -1,
  requestRunning: false,
  initialized: false,
};
