import Konva from 'konva';
import { DEFAULT_CURVE_HATCH_CONFIG, DEFAULT_CURVE_LINE_CONFIG } from 'curves/Constants';
import GridSizeConfig from 'curves/GridSizeConfig';
import { CurveCoord } from 'types/curves';

export default class Hatch extends Konva.Group {
  curveShapeName: string;

  deviceId?: string;

  constructor(
    points1: CurveCoord[],
    points2: CurveCoord[],
    sizeConfig: GridSizeConfig,
    hatchColor: string,
    offX: number,
    offY: number,
    deviceId?: string
  ) {
    super();
    this.curveShapeName = 'Hatch';
    const line1Points = sizeConfig.adjustPoints(points1, offX, offY);
    const line2Points = sizeConfig.adjustPoints(points2.concat().reverse(), offX, offY);
    const hatch = sizeConfig.adjustPoints(points1.concat(points2.concat().reverse()), offX, offY, true);
    this.add(
      new Konva.Line({
        ...DEFAULT_CURVE_HATCH_CONFIG,
        fill: hatchColor,
        points: hatch,
        deviceId,
      })
    );
    this.add(
      new Konva.Line({
        ...DEFAULT_CURVE_LINE_CONFIG,
        stroke: hatchColor,
        points: line1Points,
        deviceId,
      })
    );
    this.add(
      new Konva.Line({
        ...DEFAULT_CURVE_LINE_CONFIG,
        stroke: hatchColor,
        points: line2Points,
        deviceId,
      })
    );
  }
}
