import { IGetProjectInformation, IGetProjectInformationResponse } from 'types/api/partnerHubApi';
import ApiCalls from './ApiCalls';

export default class AppAPI {
  static async getAppVersion(): Promise<{ version: string }> {
    const { data } = await ApiCalls.get<{ version: string }>(`version`);
    return data;
  }

  static async getProjectInfo(requestBody: IGetProjectInformation): Promise<IGetProjectInformationResponse> {
    // return ApiCalls.post(`Users/GetProjectInformation`, requestBody);
    const { data } = await ApiCalls.post<IGetProjectInformationResponse, IGetProjectInformation>(
      `Users/GetProjectInformation/`,
      requestBody
    );
    return data;
  }
}
