import { getDefaultPropertyValue } from 'actions/variables';
import { projectActions } from 'project/actions';
import FieldsValidation from 'fieldsValidation';
import { ThunkAction } from 'types/known-actions';
import _ from 'lodash';
import { IProtectionModalDataSource } from 'types/modal/projectProperties/protectionAgainstIndirectContacts';
import { Variables } from 'types/variables/store';
import { IDOCwebInputProps } from 'types/components/inputContainer';
import { VariablesActions } from 'store/variables/actions';

export const TAB_NAME = 'Protection against contact in case of fault';
const DEFAULT_INDIRECT_TOUCH_TRIP_TIME_VALUES = [0.8, 0.4, 0.2, 0.1, 0.3, 0.2, 0.07, 0.03, 5, 1];
const errorNames = {
  MaxContactVoltage: 'Maximum contact voltage Uo',
  PlantGroundResistance: 'Ra',
};

export const inputFocusOutHandler =
  (event: IDOCwebInputProps): ThunkAction<void> =>
  (dispatch, getState) => {
    const { project } = getState();
    if (project.projectErrors[TAB_NAME][event.name] && !_.isEmpty(project.projectErrors[TAB_NAME])) {
      // eslint-disable-next-line no-param-reassign
      event.value = getDefaultPropertyValue(event.name) as string;
    }
    dispatch(inputUpdateHandler(event));
  };

export const inputUpdateHandler =
  (event: IDOCwebInputProps): ThunkAction<void> =>
  (dispatch, getState) => {
    const { variables } = getState();
    const { IndirectTouchTripTimes } = _.cloneDeep(variables);
    const { value } = event;
    const { name } = event;
    if (name === 'MaxContactVoltage' || name === 'PlantGroundResistance') {
      dispatch(VariablesActions.setVariables({ [name]: value }));
      dispatch(
        projectActions.setProjectError({
          name,
          tabName: TAB_NAME,
          error: FieldsValidation.validateLowerValue(0, value, errorNames[name]) ?? '',
        })
      );
    } else {
      const tripTimeIndex = IndirectTouchTripTimes.time.findIndex((tt) => tt['@'].id === name);

      dispatch(
        projectActions.setProjectError({
          name,
          tabName: TAB_NAME,
          error: FieldsValidation.validateRange(0.015, getDefaultPropertyValue(name) as number, value, 'Value') ?? '',
        })
      );
      _.set(IndirectTouchTripTimes, ['time', tripTimeIndex, '#'], value);
      dispatch(VariablesActions.setVariables({ IndirectTouchTripTimes }));
    }
  };

export const generateDataSource = (variables: Variables.State): IProtectionModalDataSource => {
  const IndirectTouchTripTimes = variables.IndirectTouchTripTimes.time;
  const dataSource: IProtectionModalDataSource = {};
  IndirectTouchTripTimes.forEach((tripTime) => {
    dataSource[tripTime['@'].id] = tripTime['#'];
  });
  return dataSource;
};

export const restoreToDefaults = (): ThunkAction<void> => (dispatch, getState) => {
  const { project, variables } = getState();
  const { IndirectTouchTripTimes } = _.cloneDeep(variables);
  Object.keys(errorNames).forEach((name) => {
    dispatch(VariablesActions.setVariables({ [name]: getDefaultPropertyValue(name) }));
  });
  IndirectTouchTripTimes.time.forEach((tripTime, index) => {
    _.set(IndirectTouchTripTimes, ['time', index, '#'], DEFAULT_INDIRECT_TOUCH_TRIP_TIME_VALUES[index]);
  });
  dispatch(VariablesActions.setVariables({ IndirectTouchTripTimes }));

  // Clean errors if we have
  const protectionAgainstIndirectContactsErrors = project.projectErrors[TAB_NAME] ? project.projectErrors[TAB_NAME] : null;
  if (protectionAgainstIndirectContactsErrors) {
    Object.keys(protectionAgainstIndirectContactsErrors).forEach((error) => {
      if (IndirectTouchTripTimes.time.some((time) => time['@'].id === error)) {
        dispatch(projectActions.setProjectError({ error, tabName: TAB_NAME, name: '' }));
      }
    });
  }
};
