import React, { useEffect, useState } from 'react';
import { Table, TableHeader } from 'components/generic/table';
import CheckBox from 'components/generic/CheckBox';
import { isProjectFromDocWeb, setSelectedDevice } from 'project/actions';
import { collectLeftPanelData, disableOnEditForMVStuff, removeCells } from 'devices/actions';
import { useDispatch, useSelector } from 'store';
import { showModal, showWizard } from 'ui/modals/actions';
import { useTranslation } from 'react-i18next';
import { WithTooltip, Tooltip } from '@abb/abb-common-ux-react';
import { EDeviceType } from 'types/devices/enums.d';
import { cloneDeep } from 'lodash';
import { curvesActions } from 'curves/actions';
import { checkCurves, removeCheckedCurve } from './actions';

export interface IDeviceTable {
  selectedDeviceId?: string;
}

const LeftPanelDeviceTable: React.FunctionComponent<IDeviceTable> = ({ selectedDeviceId }): React.ReactElement => {
  const devices = useSelector((state) => state.devices);
  // const project = useSelector((state) => state.project);
  const variables = useSelector((state) => state.variables);
  const checkedCurves = useSelector((state) => state.curves.checkedCurves);
  const customCurves = useSelector((state) => state.curves.customCurves);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedDevices = useState([]) as unknown as Array<string>;
  useEffect(() => {
    if (selectedDeviceId && !selectedDevices.includes(selectedDeviceId)) {
      selectedDevices.push(selectedDeviceId);
    }
  }, [selectedDeviceId]);

  const customInputs = [
    {
      index: 0,
      input: (item: Record<string, unknown>) => {
        const deviceId = item.ObjectId ? (item.ObjectId as string) : (item.id as string);
        return (
          <CheckBox
            value={checkedCurves.includes(deviceId) || item.deviceId === selectedDeviceId}
            name="checked"
            onChange={(e) => dispatch(checkCurves(e, deviceId))}
            disabled={item.deviceId === selectedDeviceId}
          />
        );
      },
    },
    {
      index: 2,
      input: (item: Record<string, unknown>) => {
        if (item.TripUnitModelName && item.deviceType !== EDeviceType.WIREMV) {
          return (
            <WithTooltip>
              <span>{item.TripUnitModelName as string}</span>
              <Tooltip>{item.TripUnitModelName as string}</Tooltip>
            </WithTooltip>
          );
        }
        if (item.ProductDescription) {
          return (
            <WithTooltip>
              <span>{item.ProductDescription as string}</span>
              <Tooltip>{item.ProductDescription as string}</Tooltip>
            </WithTooltip>
          );
        }
        if (item.Conductor && item.Insulator && item.CrossSectionDescription) {
          return (
            <WithTooltip>
              <span>{`${item.Conductor as string} ${item.Insulator as string} ${item.CrossSectionDescription as string}`}</span>
              <Tooltip>{`${item.Conductor as string} ${item.Insulator as string} ${
                item.CrossSectionDescription as string
              }`}</Tooltip>
            </WithTooltip>
          );
        }
        return <span></span>;
      },
    },
  ];
  return (
    <Table
      data={collectLeftPanelData(devices, customCurves.devices, variables.MainStandard)}
      selectedValue={selectedDeviceId}
      valueAttr={'deviceId'}
      onRowClick={(device) => {
        if (!device.data.custom) {
          dispatch(setSelectedDevice(device.value));
        }
      }}
      specificColumnWidth={['70px', '70px', 'auto', '70px']}
      searchable={true}
      onDelete={(device) => {
        if (selectedDeviceId === device.deviceId) dispatch(setSelectedDevice(''));
        const deviceId = device.ObjectId ? (device.ObjectId as string) : (device.id as string);
        dispatch(removeCells([device.deviceId as string]));
        dispatch(removeCheckedCurve(deviceId, true));
      }}
      onDeleteDisabled={isProjectFromDocWeb}
      onEdit={(device) => {
        if (device.custom) {
          const copyCustomCurves = cloneDeep(customCurves);
          copyCustomCurves.selectedDeviceId = device.id as string;
          dispatch(curvesActions.setCustomCurves({ customCurves: copyCustomCurves }));
          dispatch(showModal('CustomCurvesDeviceModal'));
        } else {
          const copyCustomCurves = cloneDeep(customCurves);
          copyCustomCurves.selectedDeviceId = '';
          dispatch(curvesActions.setCustomCurves({ customCurves: copyCustomCurves }));
          dispatch(setSelectedDevice((device as { deviceId: string }).deviceId));
          dispatch(showWizard(device));
        }
      }}
      onEditDisabled={disableOnEditForMVStuff}
      customInputs={customInputs}
      noRadio
    >
      <TableHeader attr="" key="checkboxCell"></TableHeader>
      <TableHeader attr={'ObjectSign'} key={'deviceListTableHeaderSign'}>
        {t('STRING_REPSIGN')}
      </TableHeader>
      <TableHeader
        attr={[
          'ProductDescription',
          'Conductor',
          'Insulator',
          'CrossSectionDescription',
          'UserDescription1',
          'TripUnitModelName',
        ]}
        key={'deviceListTableHeaderName'}
      >
        {t('DOCWEB_STRING_PRODUCT_DESCRIPTION')}
      </TableHeader>
      <TableHeader attr={'Uref'} key={'deviceListTableHeaderVoltage'}>
        {t('DOCWEB_MOTOR_UN')}
      </TableHeader>
    </Table>
  );
};

export default LeftPanelDeviceTable;
