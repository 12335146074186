/* eslint-disable no-param-reassign */
import { CURVES_COLORS, DEFAULT_CURVE_STROKE_COLOR, UPSTREAM_CURVE_COLOR } from 'curves/Constants';
import { getObjectSign } from 'store/devices/utils';
import { Device } from 'types/devices/device';

/* eslint-disable no-restricted-properties */
export const getCurrentUnitValue = (point: number): { value: string; unit: string } => {
  // eslint-disable-next-line prefer-exponentiation-operator
  let value: number | string = Math.pow(10, point + Math.log10(window.miniCurves.sizeConfig.originX));
  let unit = 'A';
  if (value < 1 && value >= 0.001) {
    value = (value / 0.001).toFixed(0);
    unit = 'mA';
  } else if (value >= 1000 && value < 1000000) {
    value = (value / 1000).toFixed(2);
    unit = 'kA';
  } else if (value >= 1000000 && value < 1000000000) {
    value = (value / 1000000).toFixed(2);
    unit = 'MA';
  } else {
    value = value.toFixed(1);
  }
  return {
    value,
    unit,
  };
};

export const getCurveLabelText = (element: Device): { text: string; checked: boolean } => {
  if (element) {
    const curveLabelText = element[`curveLabelText${getObjectSign(element)}`] as string;
    let label = curveLabelText || getObjectSign(element);
    if (element.ProductDescription && !curveLabelText) {
      label = `${label}: ${element.ProductDescription as string}`;
    }
    let curveLabelTextChecked = element[`curveLabelTextChecked${getObjectSign(element)}`] as boolean;
    if (curveLabelTextChecked === undefined) {
      curveLabelTextChecked = true;
    }
    return {
      text: label,
      checked: curveLabelTextChecked,
    };
  }
  return {
    text: '',
    checked: false,
  };
};

export const getCurveColor = (curvesColor: Record<string, string>, deviceId: string, downstream = true): string => {
  const colorByStream = downstream ? DEFAULT_CURVE_STROKE_COLOR : UPSTREAM_CURVE_COLOR;
  if (curvesColor[deviceId]) {
    return curvesColor[deviceId];
  }

  const usedColors = Object.values(curvesColor);
  if (usedColors.indexOf(colorByStream) === -1) {
    // eslint-disable-next-line no-param-reassign
    curvesColor[deviceId] = colorByStream;
    return colorByStream;
  }
  const colorToUse =
    CURVES_COLORS.find((color) => color !== colorByStream && !usedColors.includes(color)) ||
    CURVES_COLORS[CURVES_COLORS.length - 1];
  // eslint-disable-next-line no-param-reassign
  curvesColor[deviceId] = colorToUse;
  return colorToUse;
};
