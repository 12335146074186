import UserAPI from 'api/user';
import FieldsValidation from 'fieldsValidation';

import { getCableDataWizard } from 'devices/lvCable/actions';
import { updateUserDefaultProjectProperties } from 'actions/user';
import { ThunkAction } from 'types/known-actions';
import { ApplicationState } from 'types/store';
import _ from 'lodash';
import { IDOCwebInputProps } from 'types/components/inputContainer';
import { TFunction } from 'i18next';
import { updateWizardParams } from './actions';

export const checkProjectName =
  (e: IDOCwebInputProps): ThunkAction<void> =>
  (dispatch, getState) => {
    const { name, value } = e;
    return UserAPI.checkProjectName({
      UserId: getState().user.userInternalId,
      ProjectName: value ?? '',
    }).then((status) => {
      dispatch(
        updateWizardParams(
          {
            [name]:
              value === ''
                ? FieldsValidation.validateEmptyField(value, 'Project name')
                : FieldsValidation.validateAlreadyExisting(status, value ?? ''),
          },
          'errors'
        )
      );
    });
  };

export const getValidProjectName =
  (t: TFunction<'translation'>): ThunkAction<void> =>
  (dispatch, getState) => {
    return UserAPI.getValidProjectName(getState().user.userInternalId.toString(), t('DOCWEB_WIZARD_NEWPROJECTSTRING')).then(
      (validName) => {
        dispatch(updateWizardParams({ projectName: validName }));
      }
    );
  };

export const getDefaultProjectProperties = (): ThunkAction<void> => (dispatch, getState) => {
  dispatch(updateWizardParams({ requestRunning: true }));
  const { email } = getState().user;
  return UserAPI.getInternalUserData({ email }).then((userData) => {
    const state = getState();
    const userDefaults = state.project.partnerHub.projectSettings || userData.defaultProjectProperties;
    if (userDefaults && userDefaults !== '') {
      try {
        const defaultProjectProperties = JSON.parse(userDefaults ?? '') as ApplicationState;
        if (defaultProjectProperties) {
          const labelsInitialState = _.cloneDeep(state.labels);
          const variablesInitialState = _.cloneDeep(state.variables);
          const userLabels = defaultProjectProperties.labels || labelsInitialState;
          if (!defaultProjectProperties.labels) {
            console.warn('No user labels:', defaultProjectProperties);
          }
          const userVariables = defaultProjectProperties.variables;
          let rewriteUserDefaults = false;
          // Remove not needed data in defaults;
          delete variablesInitialState.PAPERSIZE;
          // TOOD: Check if we really need to delete this
          // delete variablesInitialState.ShortCircuitTimesObject;
          delete variablesInitialState.WEBDOC_CURRENT_DEVICE;
          delete variablesInitialState.tooltip;
          delete variablesInitialState.MvRatedValues;
          delete variablesInitialState.MvTechnologies;
          Object.keys(labelsInitialState).forEach((group) => {
            Object.keys(labelsInitialState[group]).forEach((device) => {
              Object.keys(labelsInitialState[group][device]).forEach((labelName) => {
                if (userLabels[group] && userLabels[group][device] && userLabels[group][device][labelName]) {
                  const currentUserLabel = userLabels[group][device][labelName];
                  const labelObj = labelsInitialState[group][device][labelName];

                  if (labelName === 'Order') {
                    if (
                      Array.isArray(labelObj) &&
                      Array.isArray(currentUserLabel) &&
                      labelObj.every((name) => currentUserLabel.indexOf(name) !== -1)
                    ) {
                      labelsInitialState[group][device][labelName] = currentUserLabel;
                    } else {
                      rewriteUserDefaults = true;
                    }
                  } else if (
                    !Array.isArray(labelObj) &&
                    !Array.isArray(currentUserLabel) &&
                    labelObj.display !== currentUserLabel.display
                  ) {
                    labelObj.display = currentUserLabel.display;
                  }
                }
              });
            });
          });
          Object.keys(variablesInitialState).forEach((variable) => {
            if (userVariables[variable] !== undefined) {
              variablesInitialState[variable] = userVariables[variable];
            } else {
              rewriteUserDefaults = true;
            }
          });
          defaultProjectProperties.variables = variablesInitialState;
          defaultProjectProperties.labels = labelsInitialState;
          dispatch(updateWizardParams({ ...defaultProjectProperties, requestRunning: false }));
          dispatch(
            updateWizardParams({
              mvUtilitySettings: {
                ...getState().wizard.mvUtilitySettings,
                Uref: defaultProjectProperties.variables.PlantMvUn,
                NominalVoltage: defaultProjectProperties.variables.PlantMvUn,
              },
              requestRunning: false,
            })
          );
          if (rewriteUserDefaults) {
            dispatch(
              updateUserDefaultProjectProperties({
                labels: labelsInitialState,
                variables: variablesInitialState,
                user: state.user,
              })
            ).catch((e) => console.log(e));
          }
        }
      } catch (error) {
        dispatch(updateUserDefaultProjectProperties(null, true)).catch((e) => console.log(e));
        console.error(error);
      }
    }
    dispatch(getCableDataWizard('Initial'));
  });
};
