/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-return-assign */

import React from 'react';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import CheckBox from 'components/generic/CheckBox';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import Select from 'components/generic/selectContainer';
import { useTranslation } from 'react-i18next';
import { onFocusOutHandler } from '../actions';

interface ILvCableConfigurationTab {
  selectedDevice: Record<string, unknown> & {
    CalculationResultsList: Record<string, Array<number>>;
    CableInstallationReferenceList: Array<Record<string, unknown>>;
  };
  selectedDeviceId: string;
}

const ConfigurationTab: React.FunctionComponent<ILvCableConfigurationTab> = ({ selectedDevice }) => {
  const { t } = useTranslation();

  const isNotLLL = selectedDevice.LineType !== 'LLL';
  const isPEPresent = !!selectedDevice.PEPresent;
  let CrossSectionLiveIndex: number | undefined | null = -1;
  let CrossSectionNeutralIndex: number | undefined | null = -1;
  let CrossSectionPEIndex: number | undefined | null = -1;
  selectedDevice.CableInstallationReferenceList.some((value, key) =>
    value.PhaseSection === selectedDevice.LiveCrossSection ? (CrossSectionLiveIndex = key) : null
  );
  selectedDevice.CableInstallationReferenceList.some((value, key) =>
    value.PhaseSection === selectedDevice.NeutralCrossSection ? (CrossSectionNeutralIndex = key) : null
  );
  selectedDevice.CableInstallationReferenceList.some((value, key) =>
    value.PhaseSection === selectedDevice.PECrossSection ? (CrossSectionPEIndex = key) : null
  );
  if (CrossSectionLiveIndex === undefined || CrossSectionLiveIndex === null || CrossSectionLiveIndex === -1) {
    CrossSectionLiveIndex = 0;
  }
  if (CrossSectionNeutralIndex === undefined || CrossSectionNeutralIndex === null || CrossSectionNeutralIndex === -1) {
    CrossSectionNeutralIndex = 0;
  }
  if (CrossSectionPEIndex === undefined || CrossSectionPEIndex === null || CrossSectionPEIndex === -1) {
    CrossSectionPEIndex = 0;
  }

  const maxNw = 99;

  const resultsBody = (CrossSectionLiveIndex: number): React.ReactElement => {
    // const selectedDeviceType = selectedDevice.deviceType;
    return (
      <>
        <GridRow key={'CableModalResultsTabGridRow3'}>
          <GridColumn col={4}>
            <GridRow>
              <GridColumn col={2}>
                <label>
                  <span>{t('PHASE')}</span>
                </label>
              </GridColumn>
              <GridColumn col={2} key={'CableModalResultsTabLiveCordsNumber'}>
                <Select
                  cols={[8, 8, 0]}
                  className={'info-project-input'}
                  name={'LiveCordsNumber'}
                  type="number"
                  onChange={onFocusOutHandler}
                  data={selectedDevice}
                  selectList={Array.from(Array(maxNw).keys())}
                  // displaykey={'CableModalResultsTab'}
                  disabled={selectedDevice.Locked === true || (selectedDevice.requestRunning as boolean)}
                />
              </GridColumn>
              <GridColumn col={1} className={`object-properties-divider`}>
                <label className="aligned-form-label object-properties-dimensions-label">
                  <span>{'X'}</span>
                </label>
              </GridColumn>
              <GridColumn col={2} key={'CableModalResultsTabLiveCrossSection'}>
                <Select
                  cols={[8, 8, 0]}
                  className={'info-project-input'}
                  name={'LiveCrossSection'}
                  type="number"
                  onChange={onFocusOutHandler}
                  data={selectedDevice}
                  selectList={selectedDevice.CrossSectionLiveList as Array<string>}
                  // displaykey={'CableModalResultsTab'}
                  disabled={selectedDevice.Locked === true || (selectedDevice.requestRunning as boolean)}
                />
              </GridColumn>
              <GridColumn col={1} horizontalAlignment={'center'}>
                <label>
                  <span dangerouslySetInnerHTML={{ __html: t('MM2') }} />
                </label>
              </GridColumn>
            </GridRow>
            {isNotLLL ? (
              <GridRow>
                <GridColumn col={2}>
                  <label>
                    <span>{t('NEUTRAL')}</span>
                  </label>
                </GridColumn>
                <GridColumn col={2} key={'CableModalResultsTabNeutralCordsNumber'}>
                  <Select
                    cols={[8, 8, 0]}
                    className={'info-project-input'}
                    name={'NeutralCordsNumber'}
                    type="number"
                    onChange={onFocusOutHandler}
                    data={selectedDevice}
                    selectList={Array.from(Array(maxNw).keys())}
                    // displaykey={'CableModalResultsTab'}
                    disabled={selectedDevice.Locked === true || (selectedDevice.requestRunning as boolean)}
                  />
                </GridColumn>
                <GridColumn col={1} className={`object-properties-divider`}>
                  <label className="aligned-form-label object-properties-dimensions-label">
                    <span>{'X'}</span>
                  </label>
                </GridColumn>
                <GridColumn col={2} key={'CableModalResultsTabNeutralCrossSection'}>
                  <Select
                    cols={[8, 8, 0]}
                    className={'info-project-input'}
                    name={'NeutralCrossSection'}
                    type="number"
                    onChange={onFocusOutHandler}
                    data={selectedDevice}
                    selectList={selectedDevice.CrossSectionNeturalList as Array<string>}
                    // displaykey={'CableModalResultsTab'}
                    disabled={selectedDevice.Locked === true || (selectedDevice.requestRunning as boolean)}
                  />
                </GridColumn>
                <GridColumn col={1} horizontalAlignment={'center'}>
                  <label>
                    <span dangerouslySetInnerHTML={{ __html: t('MM2') }} />
                  </label>
                </GridColumn>
              </GridRow>
            ) : (
              <span></span>
            )}
            {isPEPresent ? (
              <GridRow>
                <GridColumn col={2}>
                  <label>
                    <span>{t('PE')}</span>
                  </label>
                </GridColumn>
                <GridColumn col={2} key={'CableModalResultsTabPECordsNumber'}>
                  <Select
                    cols={[8, 8, 0]}
                    className={'info-project-input'}
                    name={'PECordsNumber'}
                    type="number"
                    onChange={onFocusOutHandler}
                    data={selectedDevice}
                    selectList={Array.from(Array(maxNw).keys())}
                    // displaykey={'CableModalResultsTab'}
                    displayName={t('PE')}
                    disabled={selectedDevice.Locked === true || (selectedDevice.requestRunning as boolean)}
                  />
                </GridColumn>
                <GridColumn col={1} className={`object-properties-divider`}>
                  <label className="aligned-form-label object-properties-dimensions-label">
                    <span>{'X'}</span>
                  </label>
                </GridColumn>
                <GridColumn col={2} key={'CableModalResultsTabPECrossSection'}>
                  <Select
                    cols={[8, 8, 0]}
                    className={'info-project-input'}
                    name={'PECrossSection'}
                    type="number"
                    onChange={onFocusOutHandler}
                    data={selectedDevice}
                    selectList={selectedDevice.CrossSectionPEList as Array<string>}
                    // displaykey={'CableModalResultsTab'}
                    disabled={selectedDevice.Locked === true || (selectedDevice.requestRunning as boolean)}
                  />
                </GridColumn>
                <GridColumn col={1} horizontalAlignment={'center'}>
                  <label>
                    <span dangerouslySetInnerHTML={{ __html: t('MM2') }} />
                  </label>
                </GridColumn>
              </GridRow>
            ) : (
              <span></span>
            )}
          </GridColumn>
          <GridColumn col={8}>
            <GridRow>
              <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListIz'}>
                <Input
                  cols={[7, 0, 0, 0]}
                  type={'number'}
                  className={'info-project-input'}
                  label={'STRINGS_PROTALL_IZ'}
                  displayValue={[selectedDevice.CalculationResultsList.Iz[CrossSectionLiveIndex], 1]}
                  disabled={true}
                  name={''}
                />
              </GridColumn>
              <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListIz1'}>
                <Input
                  cols={[7, 0, 0, 0]}
                  type={'number'}
                  className={'info-project-input'}
                  label={'STRING_REPKT'}
                  displayValue={[selectedDevice.CalculationResultsList.Iz[CrossSectionLiveIndex], 1]}
                  disabled={true}
                  name={''}
                />
              </GridColumn>
              <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListdissipatedPower'}>
                <Input
                  cols={[7, 0, 0, 0]}
                  type={'number'}
                  className={'info-project-input'}
                  label={'DOCCURVES_CABLE_RESULTS_DISSPOWER'}
                  displayValue={[selectedDevice.CalculationResultsList.dissipatedPower[CrossSectionLiveIndex], 2]}
                  disabled={true}
                  name={''}
                />
              </GridColumn>
              <GridColumn col={4} key={'CableModalResultsTabVoltageDrop'}>
                <Input
                  cols={[7, 0, 0, 0]}
                  name={'VoltageDrop'}
                  data={selectedDevice}
                  type={'number'}
                  className={'info-project-input'}
                  label={'DOCCURVES_CABLE_RESULTS_DUPERCENT'}
                  cut={UnitsDataAdapter.cutUnits}
                  disabled={true}
                />
              </GridColumn>
              <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListworkingTemperature'}>
                <Input
                  cols={[7, 0, 0, 0]}
                  type={'number'}
                  className={'info-project-input'}
                  label={'WORK_TEM [%]'}
                  displayValue={[selectedDevice.CalculationResultsList.workingTemperature[CrossSectionLiveIndex], 2]}
                  disabled={true}
                  name={''}
                />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const k2s2Body = (
    CrossSectionLiveIndex: number,
    CrossSectionNeutralIndex: number,
    CrossSectionPEIndex: number
  ): React.ReactElement => {
    return (
      <>
        <GridRow key={'CableModalResultsTabk2s2BodyGridRow1'}>
          <GridColumn col={4} key={'CableModalResultsTabKsquaredSsquaredAtWorkingTemp'} verticalAlignment={'middle'}>
            <CheckBox
              // style={{marginLeft: '0px'}}
              name={'KsquaredSsquaredAtWorkingTemp'}
              value={selectedDevice.KsquaredSsquaredAtWorkingTemp as boolean}
              onChange={onFocusOutHandler}
              label={'WORK_TEM'}
              disabled={selectedDevice.requestRunning as boolean}
            />
          </GridColumn>
          <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListK2S2'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              label={'DOCCURVES_CABLE_PHASEA2S'}
              disabled={true}
              displayValue={[selectedDevice.CalculationResultsList.K2S2[CrossSectionLiveIndex], 3, true]}
              name={''}
              type={'string'}
            />
          </GridColumn>
          {isNotLLL ? (
            <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListK2S21'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'DOCCURVES_CABLE_NEUTRALA2S'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.K2S2[CrossSectionNeutralIndex], 3, true]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
          {isPEPresent ? (
            <GridColumn col={4} key={'CableModalResultsTabCalculationResultsListK2S23'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'DOCCURVES_CABLE_PEA2S'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.K2S2[CrossSectionPEIndex], 3, true]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
        </GridRow>
      </>
    );
  };
  const r20c = (
    CrossSectionLiveIndex: number,
    CrossSectionNeutralIndex: number,
    CrossSectionPEIndex: number
  ): React.ReactElement => {
    return (
      <>
        <GridRow>
          <GridColumn col={2} key={'CableModalResultsTabGridColumnPHASE'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.CalculationResultsList.R20C[CrossSectionLiveIndex], 3]}
              data={selectedDevice}
              label={'PHASE'}
              disabled={true}
              name={''}
              type={'string'}
            />
          </GridColumn>
          {isNotLLL ? (
            <GridColumn col={2} key={'CableModalResultsTabisNotLLLNEUTRAL'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'NEUTRAL'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.R20C[CrossSectionNeutralIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
          {isPEPresent ? (
            <GridColumn col={2} key={'CableModalResultsTabisPEPresentPE'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'PE'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.R20C[CrossSectionPEIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
        </GridRow>
      </>
    );
  };
  const rWorkBody = (
    CrossSectionLiveIndex: number,
    CrossSectionNeutralIndex: number,
    CrossSectionPEIndex: number
  ): React.ReactElement => {
    return (
      <>
        <GridRow key={'CableModalResultsTabrWorkBodyGridRow1'}>
          <GridColumn col={2} key={'CableModalResultsTabrWorkBodyGridRow2'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.CalculationResultsList.RWork[CrossSectionLiveIndex], 3]}
              data={selectedDevice}
              label={'PHASE'}
              disabled={true}
              name={''}
              type={'string'}
            />
          </GridColumn>
          {isNotLLL ? (
            <GridColumn col={2} key={'CableModalResultsTabrWorkBodyisNotLLL'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'NEUTRAL'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.RWork[CrossSectionNeutralIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
          {isPEPresent ? (
            <GridColumn col={2} key={'CableModalResultsTabisPEPresentGridColumn'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'PE'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.RWork[CrossSectionPEIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
        </GridRow>
      </>
    );
  };
  const reactionBody = (
    CrossSectionLiveIndex: number,
    CrossSectionNeutralIndex: number,
    CrossSectionPEIndex: number
  ): React.ReactElement => {
    return (
      <>
        <GridRow>
          <GridColumn col={2} key={'CableModalResultsTabrScBodyisPHASEPresent1'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.CalculationResultsList.X[CrossSectionLiveIndex], 3]}
              data={selectedDevice}
              label={'PHASE'}
              disabled={true}
              name={''}
              type={'string'}
            />
          </GridColumn>
          {isNotLLL ? (
            <GridColumn col={2} key={'CableModalResultsTabisNotLLLNEUTRALrScBody'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'NEUTRAL'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.X[CrossSectionNeutralIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
          {isPEPresent ? (
            <GridColumn col={2} key={'CableModalResultsTabisPEPresentirScBody'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'PE'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.X[CrossSectionPEIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
        </GridRow>
      </>
    );
  };
  const rScBody = (
    CrossSectionLiveIndex: number,
    CrossSectionNeutralIndex: number,
    CrossSectionPEIndex: number
  ): React.ReactElement => {
    return (
      <>
        <GridRow key={'CableModalResultsTabrScBodyGridRow1'}>
          <GridColumn col={2} key={'CableModalResultsTabrScBodyGridRow2'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.CalculationResultsList.RSC[CrossSectionLiveIndex], 3]}
              data={selectedDevice}
              label={'PHASE'}
              disabled={true}
              name={''}
              type={'string'}
            />
          </GridColumn>
          {isNotLLL ? (
            <GridColumn col={2} key={'CableModalResultsTabrScBodyisNotLLL'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'NEUTRAL'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.RSC[CrossSectionNeutralIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
          {isPEPresent ? (
            <GridColumn col={2} key={'CableModalResultsTabrScBodyisPEPresent'}>
              <Input
                cols={[7, 0, 0, 0]}
                className={'info-project-input'}
                label={'PE'}
                disabled={true}
                displayValue={[selectedDevice.CalculationResultsList.RSC[CrossSectionPEIndex], 3]}
                name={''}
                type={'string'}
              />
            </GridColumn>
          ) : (
            <span></span>
          )}
        </GridRow>
      </>
    );
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn col={4}>
          <p className={'title'}>{t('DOCWEB_WIRINGS_HEADER_RESULTS')}</p>
          {resultsBody(CrossSectionLiveIndex)}
        </GridColumn>
        <GridColumn col={4}>
          <p className={'title'} dangerouslySetInnerHTML={{ __html: t('DOCWEB_MODAL_PROTECTION_K2S2') }} />
          {k2s2Body(CrossSectionLiveIndex, CrossSectionNeutralIndex, CrossSectionPEIndex)}
          <p className={'title'} dangerouslySetInnerHTML={{ __html: t('R_WORK') }} />
          {rWorkBody(CrossSectionLiveIndex, CrossSectionNeutralIndex, CrossSectionPEIndex)}
          <p className={'title'} dangerouslySetInnerHTML={{ __html: t('R_20C') }} />
          {r20c(CrossSectionLiveIndex, CrossSectionNeutralIndex, CrossSectionPEIndex)}
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={4}>
          <p className={'title'} dangerouslySetInnerHTML={{ __html: t('R_SC') }} />
          {rScBody(CrossSectionLiveIndex, CrossSectionNeutralIndex, CrossSectionPEIndex)}
        </GridColumn>
        <GridColumn col={4}>
          <p className={'title'} dangerouslySetInnerHTML={{ __html: t('X') }} />
          {reactionBody(CrossSectionLiveIndex, CrossSectionNeutralIndex, CrossSectionPEIndex)}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default ConfigurationTab;
