import React, { FunctionComponent } from 'react';
import { Checkbox, WithTooltip, Tooltip } from '@abb/abb-common-ux-react';

import { useTranslation } from 'react-i18next';
import { ICheckBox } from 'types/components/checkbox';

const CheckBox: FunctionComponent<ICheckBox> = ({
  value,
  name,
  toolTip,
  label,
  onChange,
  disabled,
  inverted,
  className,
  allowIndeterminate,
  sizeClass,
}): React.ReactElement => {
  const { t } = useTranslation();
  return toolTip ? (
    <WithTooltip>
      <Checkbox
        className={className}
        label={label ? t(label) : ''}
        value={inverted ? !value : value}
        onChange={() =>
          onChange
            ? onChange({
                name,
                value: !value,
                type: 'checkbox',
              })
            : undefined
        }
        allowIndeterminate={allowIndeterminate}
        disabled={disabled}
        sizeClass={sizeClass ?? 'small'}
      />
      <Tooltip>
        <p>{t(toolTip)}</p>
      </Tooltip>
    </WithTooltip>
  ) : (
    <Checkbox
      className={className}
      label={label ? t(label) : ''}
      value={inverted ? !value : value}
      onChange={() =>
        onChange
          ? onChange({
              name,
              value: !value,
              type: 'checkbox',
            })
          : undefined
      }
      allowIndeterminate={allowIndeterminate}
      disabled={disabled}
      sizeClass={sizeClass ?? 'large'}
    />
  );
};

export default CheckBox;
