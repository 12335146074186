import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'CABLE_LLLNPE',
  objectType: EDeviceObjectType.LvCableLLLNPE,
  deviceType: EDeviceType.WIRELV,
  ports: [],
  IBUsed: 10,
  LoadCurrent: 10,
  ComputedCosPhi: 0.9,
  Frequency: 50,
  KsquaredSsquaredAtWorkingTemp: false,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNC,
  Uref: 400.0,
  CableLength: 3,
  dUCheckOn: false,
  dUMax: 3,
  CableTypeId: 6,
  CableInsulator: 5,
  CableConductor: 2,
  MaxWorkingTemperature: 70,
  InstallationMethodId: 2,
  InstallationPlaceId: 4,
  InstallationDetailsId: 10,
  InstallationItemId: 4,
  InstallationItem: '31',
  PEPresent: true,
  IzIbRatio: 1.15,
  AmbientTemperature: 30,
  PEType: 'CableSingleCoreInsul',
  PEConductor: 'Cu',
  PEInsulator: 'PVC',
  PELength: 3,
  LiveCordsNumber: 1,
  NeutralCordsNumber: 1,
  PECordsNumber: 1,
  DoubleInsulation: false,
  ThD: '0<=TH<10%',
  InstallationSchemeId: 1,
  InstallationLayoutId: 19,
  ConduitRowId: -1,
  ConduitColumnId: -1,
  InstallationEnvironmentId: -1,
  FaultTemperatureUp300: 160,
  FaultTemperatureOver300: 140,
  SymmetryRespected: true,
  PEWithLives: true,
  PELikeLives: true,
  CableLengthIFE: 0,
  PELengthIFE: 0,
  IFEClass: 'E30',
  IFETemperature: 822,
  dUMaxIFE: 2.5,
  CrossSectionDescription: '4x(1x1.5) + 1G1.5',
  LiveCrossSection: 1.5,
  NeutralCrossSection: 1.5,
  PECrossSection: 1.5,
  CarryingCapacityIzCorrectionFactor: 1,
  Locked: false,
  WConductors: 2,
  InstallationFactor: 1,
  temperatureFactor: 1,
  environmentFactor: 1,
  layoutFactor: 1,
  conduitFactor: 1,
  carryingCapacityUserFactor: 1,
  totalReductionFactor: 1,
  Iz: 18,
  DissipatedPower: 6.985178720840337,
  lvCableConductorFetched: false,
  LanguageId: 0,
  Kv: 0,
  Conductor: 'Cu',
  Insulator: 'PVC',
  CableType: 'CableMultiCore',
  InstallationMethod: 'Embedded in structure',
  InstallationPlace: 'In cable ducting',
  InstallationNumber: '11A',
  InstallationDetails: 'Flat, spaced',
  InstallationScheme: '0Circuit',
  InstallationLayout: 'nothing',
  InstallationEnvironment: '',
  ConduitRow: '',
  ConduitColumn: '',
  CableCarryingCapacityUserFactor: 1,
  TemperatureFactor: 1,
  EnvironmentFactor: 1,
  LayoutFactor: 1,
  ConduitFactor: 1,
  TotalReductionFactor: 1,
  CableStandard: -1,
  CableStandardString: '',
  ScStandard: -1,
  ScStandardString: '',
  InstallationId: 0,
  InstallationReferenceId: 341,
  ScStandardList: [],
  CableStandardList: [],
  CableTypeList: [],
  CableInsulatorList: [],
  CableConductorList: [],
  InstallationMethodList: [],
  InstallationNumberList: [],
  InstallationPlaceList: [],
  InstallationSchemeList: [],
  InstallationEnvironmentList: [],
  InstallationConduitList: [],
  ConduitFactorList: [],
  CableInstallationReferenceList: [],
  CalculationResultsList: {
    Iz: [],
    K2S2: [],
    Kt: 1,
    R20C: [],
    RSC: [],
    RWork: [],
    X: [],
    dU: [],
    dissipatedPower: [],
    phaseReactance: [],
    workingTemperature: [],
  },
  InstallationDetailsList: [],
  InstallationLayoutList: [],
  CrossSectionLiveList: [],
  CrossSectionNeturalList: [],
  CrossSectionPEList: [],
  initialized: false,
  EdgeSCCurrents: [],
  FunctionalEndurance: 'notManaged',
  requestRunning: false,
  PreFiltersChanged: true,
};
