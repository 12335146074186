import { applyMiddleware, compose, createStore, Store } from 'redux';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import { ApplicationState } from 'types/store';
import { reducers } from '.';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  export interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configureStore = (initialState?: ApplicationState): Store<ApplicationState> => {
  const middleware = [thunk];

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : (window as Window);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return createStore(reducers, initialState, compose(applyMiddleware(...middleware), ...enhancers, sentryReduxEnhancer));
  // return createStore(reducers, initialState, compose(applyMiddleware(...middleware), ...enhancers));
  // return createStore(reducers, initialState);
};

// const store = configureStore(initialState);
const store = configureStore();

export { store };
