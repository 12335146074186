import { defaultElement } from '../default-element';
import { EDeviceObjectType, EDeviceType } from '../enums.d';

export default {
  ...defaultElement,
  symbol: 'MEDICAL_ROOM',
  objectType: EDeviceObjectType.FEEDER,
  Locked: false,
  deviceType: EDeviceType.MEDICAL_ROOM_FEEDER,

  protection: 'FuseBase',
  trafo: 'TRAFO2_11_INSUL',
  disconnector: 'No',
  busbar: 'BUSBAR',
  initialized: false,
};
