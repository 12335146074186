import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { isEqual } from 'lodash';
import DeviceManager from './deviceManager';
import DeviceParameters from './deviceParameters';
import ModalWindow from '../ModalWindow';

const CustomCurvesDeviceModal: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const selectedDeviceId = useSelector((state) => state.curves.customCurves.selectedDeviceId, isEqual);

  const tabs = () => {
    if (!selectedDeviceId || selectedDeviceId === '') {
      return { without: <DeviceManager /> };
    }
    return { without: <DeviceParameters /> };
  };

  return (
    <ModalWindow
      tabs={tabs()}
      title={t(!selectedDeviceId || selectedDeviceId === '' ? 'DOCWEB_MODALS_CUSTOMCURVES' : 'DOCWEB_MODALS_DEVICEEDITOR')}
      footer="without"
    />
  );
};

export default CustomCurvesDeviceModal;
