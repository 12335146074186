import React from 'react';

import { GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';

import { useDispatch, useSelector } from 'store';
import { onFocusOutHandler } from 'devices/lvCable/actions';
import { isMobileOnly } from 'react-device-detect';
import Properties from './Properties';
import ReductionFactors from './ReductionFactors';

const CableModal = (): React.ReactElement => {
  const devices = useSelector((state) => state.devices);
  const project = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const propertiesCable = () => {
    const selectedDevice = devices[project.selectedDeviceId as string];
    const objectSign = selectedDevice.TypicalUnitSign || selectedDevice.ObjectSign;
    return (
      <>
        <GridRow>
          <GridColumn col={8}>
            <p className={'title'}>{`${(objectSign as string) || ''} Cable`}</p>
          </GridColumn>
          <GridColumn col={4}>
            <Input
              cols={[7, 0, 0, 0]}
              type={'text'}
              onChange={(e) => dispatch(onFocusOutHandler(e))}
              className={'description-modal-label'}
              name={'UserDescription1'}
              label={'Description'}
              data={selectedDevice}
            />
          </GridColumn>
        </GridRow>
        <Properties selectedDevice={selectedDevice} selectedDeviceId={project.selectedDeviceId as string} />
        <ReductionFactors selectedDevice={selectedDevice} selectedDeviceId={project.selectedDeviceId as string} />
      </>
    );
  };

  const orientationType = window.orientation;
  return orientationType === 0 && isMobileOnly ? (
    <h2 className="productDescription">Please, rotate the phone to activate the screen</h2>
  ) : (
    propertiesCable()
  );
};

export default CableModal;
