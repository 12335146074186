// BEWARE - this is generated js source code, any manual changes will be lost when file gets regenerated
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { IReport } from 'types/drawingArea/reports';

/* eslint prefer-const: 0 */
const reports = [
  {
    id: 'HEADER',
    strId: 'STRINGS_HEADING',
    strDef: 'Header',
    child: false,
    parent: false,
    forced: false,
  },
  {
    id: 'PRELIMINARIES',
    strId: 'STRINGS_CALCULATION_PRELIMINARIES',
    strDef: 'Calculation and dimensioning criteria',
    child: false,
    parent: false,
    forced: false,
  },
  {
    id: 'PRELIMINARIES_SC',
    strId: 'STRINGS_SC_PRELIMINARIES',
    strDef: 'Short-circuit calculation hypothesis',
    child: false,
    parent: false,
    forced: false,
  },
  {
    id: 'BLOCK_DIAGRAM',
    strId: 'STRINGS_BLOCK_DIAGRAM',
    strDef: 'Block diagram',
    child: false,
    parent: false,
    forced: false,
  },
  {
    id: 'SLD',
    strId: 'STRINGS_UNIWIRE_SCHEME',
    strDef: 'Single line diagram',
    child: false,
    parent: false,
    forced: true,
  },
  {
    id: 'REPORTS',
    strId: 'STRINGS_RELATIONS',
    strDef: 'Reports',
    child: false,
    parent: true,
    forced: false,
  },
  {
    id: '01 DISTRIBUTION',
    strId: 'STRING_REPUTILITY',
    strDef: 'Distribution',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '02 TRANSFORMERS',
    strId: 'STRING_REPTRAFO',
    strDef: 'Transformers',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '03 UPS',
    strId: 'STRING_REPUPS',
    strDef: 'Uninterrupted power supply',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '04 SC CURRENTS-60909',
    strId: 'STRING_REPSCCALCULATIONS',
    strDef: 'Short-circuit calculation',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '04 SC CURRENTS-61363',
    strId: 'STRING_REPSC363CALCULATIONS',
    strDef: 'Short-circuit currents (IEC61363)',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '05 CABLES PROTECTION',
    strId: 'STRING_REPCABLEPROTECTION',
    strDef: 'Cable protection',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '05 MV CABLES PROTECTION',
    strId: 'STRING_REPMVCABLEPROTECTION',
    strDef: 'MV Cable protection',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '06 BUSWAYS PROTECTION',
    strId: 'STRING_REPBUSWAYPROTECTION',
    strDef: 'Busway protection',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '07 LIST OF DEVICES MV',
    strId: 'STRING_REPDEVICELISTMV',
    strDef: 'List of devices MV',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '08 LIST OF SETTINGS MV',
    strId: 'STRING_REPTABLESETTINGSMV',
    strDef: 'Table of settings MV',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '09 LIST OF DEVICES',
    strId: 'STRING_REPDEVICELIST',
    strDef: 'List of devices',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '10 LIST OF SETTINGS',
    strId: 'STRING_REPSETTINGTABLE',
    strDef: 'Table of settings',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '11 LIST OF CABLES',
    strId: 'STRING_REPLVCABLESUMMARY',
    strDef: 'List of cables',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '11 LIST OF MV CABLES',
    strId: 'STRING_REPMVCABLESUMMARY',
    strDef: 'List of MV cables',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '12 LIST OF BUSWAYS',
    strId: 'STRING_REPBUSDUCTSUMMARY',
    strDef: 'List of busways',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '13 BACK-UP',
    strId: 'STRING_REPBACK-UP',
    strDef: 'Back-up',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '15 LIST OF LOADS',
    strId: 'STRING_REPLOAD',
    strDef: 'Loads',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '16 LIST OF DRIVES AND MOTORS',
    strId: 'STRING_REPDRIVEANDMOTOR',
    strDef: 'List of Drives and Motors',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '17 LIST OF BUSBARS',
    strId: 'STRING_REPBUSBARS',
    strDef: 'Busbars',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: '18 LIST OF MOTOR FEEDERS',
    strId: 'STRING_REPMOTORFEEDERS',
    strDef: 'Motor feeders',
    child: true,
    parent: false,
    forced: false,
  },
  {
    id: 'SELECTIVITY',
    strId: 'STRINGS_SELECTIVITY',
    strDef: 'Selectivity diagrams',
    child: true,
    parent: false,
    forced: false,
  },
];

const normalReportsStructure = reports.reduce((p: IReport, c) => {
  // eslint-disable-next-line no-param-reassign
  p[c.id] = c;
  return p;
}, {});

export default normalReportsStructure;
