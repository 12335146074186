/* eslint-disable no-restricted-properties */
import { UnitsType } from 'types/calculations';

/* eslint-disable no-param-reassign */
export const units = {
  // related to Utility
  ISecondK: 3,
  ISecondKMin: 3,
  sk: 3,
  SkMin: 3,
  ComputedP: 3,
  ComputedQ: 3,
  LoadCurrent: 3,
  IBUsed: 1,
  CosPhiNominal: 2,
  ComputedVoltage: 1,
  VoltageDrop: 2,
  // related to feeder common properties
  Ue: 2, // name in switchboard?
  Uref: 2,
  // related to cable
  dUMax: 2,
  CableLength: 1,
  CableCarryingCapacityUserFactor: 2,
  CarryingCapacityIz: 1,
  AmbientTemperature: 1,
  IzIbRatio: 2,
  ComputedCosPhi: 2,
  IkMax: 2,
  IkMin: 2,
  IpMax: 2,
  WorkingTemperature: 2,
  DissipatePower: 2,
  CarryingCapacityIzCorrectionFactor: 1,
  PowerLoss: 2,
  // related to load
  VoltageDropMaX: 2,
  Pn: 3,
  // related to Disconnector
  Icu: 1,
  Icm: 1,
  // related to SC Details
  SCTriPeak: 2,
  SCBiMax: 2,
  SCBiPeak: 2,
  SCLNPeak: 2,
  SCPEPeak: 2,
  SCBiTriMin: 2,
  SCLNMin: 2,
  SCPEMin: 2,
  ScLiveMax: 2,
  ScLivePeak: 2,

  Eta: 3,
  CapacityToGround: 3,
  PetersensCoilResistance: 2,
  PetersensCoilReactance: 2,
  ShortCircuitCurrentToGround: 2,
  ShortCircuitCurrentToGroundMin: 2,
  Relations: 2,
  Protection: 2,
  minSCSurrent: 3,
  i2t: 3,
  cbI2t: 3,
  iMax: 3,
  RatedPower: 2,
  Qn: 2,
  In: 2,
  Ra: 6,
  TA: 2,
  // For capacitor
  CalcPowerFactor: 2,
  ReqPowerFactor: 1,
  DesPowerFactor: 2,
  NominalPower: 1,
  // For sc currents
  scCurrentsValues: 2,

  // MVSwitchboard
  MvPanelUr: 2,
  MvPanelIr: 1,
  MvPanelIcw: 2,

  // input in slider
  CurveRange: 1,

  // Trafo
  PrimaryWindingUn: 2,
  SecondaryWindingUn: 2,
  NominalVoltage: 2,
  CosPhiRequested: 2,
  ___Q: 5,

  ikmax: 2,
  ipmax: 2,
  UpsIkMax: 2,
  // Bullshit
  ScIEC60909LPEIbMin: 0,
  ScIEC60909LNeutralIb: 0,
  ScIEC60909L2Ib: 0,
  ScIEC60909L3Ib: 0,
} as const;

/**
 * Use this class to convert some value to basic units
 */
export default class UnitsDataAdapter {
  static checkIfNaN(value: string): boolean {
    return Number.isNaN(parseFloat(value));
  }

  /**
   * Converts value from kilo to base units (value * 10^3)
   * @param {number} value - input value
   */

  static convertKiloToBase(value: string, name?: UnitsType): number | string {
    if (UnitsDataAdapter.checkIfNaN(value)) {
      return value;
    }
    const convertedValue = parseFloat(value) * 10 ** 3;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from Milli to base units (value / 10^3)
   * @param {number} value - input value
   */
  static convertMilliToBase(value: string, name?: UnitsType): number | string {
    if (UnitsDataAdapter.checkIfNaN(value)) {
      return value;
    }
    const convertedValue = parseFloat(value) / 10 ** 3;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from Mega to base units (value * 10^6)
   * @param {number} value - input value
   */
  static convertMegaToBase(value: string, name?: UnitsType): number | string {
    if (UnitsDataAdapter.checkIfNaN(value)) {
      return value;
    }
    const convertedValue = parseFloat(value) * 10 ** 6;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from Micro to base units (value / 10^6)
   * @param {number} value - input value
   */
  static convertMicroToBase(value: string, name?: UnitsType): number | string {
    if (UnitsDataAdapter.checkIfNaN(value)) {
      return value;
    }
    const convertedValue = parseFloat(value) / 10 ** 6;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from Giga to base units (value * 10^9)
   * @param {number} value - input value
   */
  static convertGigaToBase(value: string, name: UnitsType): number | string {
    const convertedValue = parseFloat(value) * 10 ** 9;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from Nano to base units (value / 10^9)
   * @param {number} value - input value
   */
  static convertNanoToBase(value: string, name: UnitsType): number | string {
    const convertedValue = parseFloat(value) / 10 ** 9;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from percent to base units (value / 100)
   * @param {number} value - input value
   */
  static convertPercentToBase(value: string, name?: UnitsType): number | string {
    const convertedValue = parseFloat(value) / 100;
    return processConvert(convertedValue, name);
  }

  /**
   * Converts value from percent to base units (value * 100)
   * @param {number} value - input value
   */
  static convertBaseToPercent(value: string, name: UnitsType): number | string {
    const convertedValue = parseFloat((parseFloat(value) * 100).toFixed(2));
    return processConvert(convertedValue, name);
  }

  /**
   * Cuts units based on its name
   * @param {string} name - name of a filed
   */
  static cutUnits(name: UnitsType, value: string): number | string {
    // TODO: Check how it is possible and why userId is being cut && name != 'userId'
    if (units[name] && !Number.isNaN(parseFloat(value)) && value) {
      return parseFloat(parseFloat(value).toFixed(units[name]));
    }
    if (typeof value !== 'object' && Number.isNaN(value)) {
      return '';
    }
    if (!Number.isNaN(parseFloat(value))) {
      return parseFloat(value);
    }
    return value;
  }
}

const processConvert = (convertedValue: number, name?: UnitsType): number => {
  let stringValue = `${convertedValue}`;
  if (name && units[name] && stringValue.split('.')[1] && stringValue.split('.')[1].length > units[name]) {
    const lastNumber = stringValue[stringValue.length - 1];
    stringValue = stringValue.slice(0, stringValue.length - 2) + lastNumber;
    convertedValue = parseFloat(UnitsDataAdapter.cutUnits(name, stringValue) as string);
  }
  return convertedValue;
};
