import Konva from 'konva';
import { DEFAULT_PEAK_PERSPECTIVE_LINE_CONFIG } from 'curves/Constants';
import GridSizeConfig from 'curves/GridSizeConfig';

const points = [
  { x: -4.0, y: -3.7695510786217259 },
  { x: 1.0, y: 1.2304489213782739 },
  { x: 1.0, y: 1.3010299956639813 },
  { x: 1.3010299956639813, y: 1.6020599913279623 },
  { x: 1.3010299956639813, y: 1.6232492903979006 },
  { x: 1.6989700043360187, y: 2.0211892990699383 },
  { x: 1.6989700043360187, y: 2.0413926851582249 },
  { x: 5.0, y: 5.3424226808222066 },
];

export default class PeakPerspectiveLine extends Konva.Group {
  curveShapeName: string;

  constructor(sizeConfig: GridSizeConfig) {
    super();
    this.curveShapeName = 'PeakLine';
    this.add(
      new Konva.Line({
        ...DEFAULT_PEAK_PERSPECTIVE_LINE_CONFIG,
        points: sizeConfig.adjustPoints(points, 0, 0),
      })
    );
  }
}
