import CurveType from 'components/curves/CurveType';
import { LineCap, LineJoin, ShapeConfig } from 'konva/lib/Shape';
import { EDeviceType } from 'types/devices/enums.d';

export const ABB_GREY_255 = '#FFFFFF';

const ABB_GREY_0 = '#000000';
const ABB_GREY_90 = '#5A5A5A';
const ABB_GREY_200 = '#C8C8C8';
const ABB_GREY_220 = '#DCDCDC';
const ABB_GREY_240 = '#F0F0F0';
const ABB_BLUE_4 = '#2E92FA';

// Grid
export const GridLineType = {
  Minor: 1,
  Middle: 2,
  Major: 3,
};

export const GridLineTypeToColor = {
  [GridLineType.Minor]: ABB_GREY_240,
  [GridLineType.Middle]: ABB_GREY_220,
  [GridLineType.Major]: ABB_GREY_200,
};

export const DEFAULT_GRID_BORDER_CONFIG = {
  stroke: ABB_GREY_0,
  strokeWidth: 1,
  shadowForStrokeEnabled: false,
};

export const GRID_OFFSET_X_PRINT = 70;
export const GRID_OFFSET_X = 50;
export const GRID_OFFSET_Y = 30;
export const LABEL_FONT_SIZE_FOR_PRINT = 20;
export const MIN_CURRENT_X = -10;

export const DEFAULT_GRID_LABEL_CONFIG = {
  fontSize: 10,
  fontFamily: 'Calibri',
  fill: ABB_GREY_90,
};

// Curves
export const zoomFactor = 1.5;
export const minZoom = 1.0;
export const maxZoom = 16.0;
export const visibleDiagrams = [
  CurveType.TFFF,
  CurveType.TFF,
  CurveType.TFN,
  CurveType.TFPE,
  CurveType.EFFF,
  CurveType.EFF,
  CurveType.EFN,
  CurveType.EFPE,
  CurveType.CL,
];

export const customCurvesDiagrams = ['TFFF', 'TFN', 'EFFF', 'EFN', 'PEAK'];
export const customCurvesCurveType = [0, 2, 4, 6, 8];

export const DEFAULT_CURVE_STROKE_COLOR = ABB_BLUE_4;
export const DEFAULT_PEAK_PERSPECTIVE_LINE_COLOR = ABB_GREY_0;
export const DEFAULT_CURVE_STROKE_WIDTH = 2.0;
export const CABLE_BREAKER_LINE_STROKE_WIDTH = 4;
export const UPSTREAM_CURVE_COLOR = '#FFD700';
export const DEFAULT_CURRENTS_LINE_COLOR = '#C8A7A6';
export const DRAG_CURVE_COLOR = '#4E4E4E';

export const DEFAULT_CURVE_LINE_CONFIG: ShapeConfig = {
  stroke: DEFAULT_CURVE_STROKE_COLOR,
  strokeWidth: DEFAULT_CURVE_STROKE_WIDTH,
  lineCap: 'round',
  lineJoin: 'round',
  shadowForStrokeEnabled: false,
};
export const DEFAULT_PEAK_PERSPECTIVE_LINE_CONFIG: ShapeConfig = {
  stroke: DEFAULT_PEAK_PERSPECTIVE_LINE_COLOR,
  strokeWidth: 1.0,
  lineCap: 'round',
  lineJoin: 'round',
  shadowForStrokeEnabled: false,
  opacity: 0.75,
};

export const CURRENT_CURVE_LINE_DASH = [
  [5, 5],
  [0, 0, 1, 5],
];

export const CURVES_COLORS = [
  DEFAULT_CURVE_STROKE_COLOR,
  UPSTREAM_CURVE_COLOR,
  '#42f5e6',
  '#701323',
  '#b51833',
  '#af72fc',
  '#852C19',
  '#18A43C',
];
// export const STRIPE1 = new Image();
// http://www.patternify.com/
// STRIPE1.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVQoU2NkIALoTfr1n5GQOpCiS3lsjHgVwhSBDMOpEFkRToXoirAqxKYIQyEuRSgK8SmCKySkCKyQGEUghQAKOSbElPHmAQAAAABJRU5ErkJggg==';
export const DEFAULT_CURVE_HATCH_CONFIG = {
  strokeEnabled: false,
  lineCap: 'round' as LineCap,
  lineJoin: 'round' as LineJoin,
  // fillPatternImage: STRIPE1,
  // fillPatternRepeat: 'repeat',
  fill: DEFAULT_CURVE_STROKE_COLOR,
  opacity: 0.65,
  closed: true,
};
export const DEFAULT_CURVES_SETTINGS_PARAMS = ['TripUnit'];

const CURVES_DEVICE_TYPES = [
  EDeviceType.CB_TM,
  EDeviceType.WIRELV,
  EDeviceType.BUSDUCT,
  EDeviceType.FUSEBASE,
  EDeviceType.SOFTSTARTER,
  EDeviceType.OLR,
  EDeviceType.RCCB,
  EDeviceType.MMS,
  EDeviceType.CB_FEEDER,
  EDeviceType.HEATER_FEEDER,
  EDeviceType.MOTOR_FEEDER,
  EDeviceType.VEHICLE_CHARGER_FEEDER,
  EDeviceType.WIREMV,
  EDeviceType.CIRCUITBREAKERMV,
  EDeviceType.CIRCUITBREAKERMVW,
  EDeviceType.MVSWITCH,
  EDeviceType.FUSEMV,
  EDeviceType.TRAFO2,
  EDeviceType.BME_IN,
  EDeviceType.BME_OUT,
  EDeviceType.DRC_IN,
  EDeviceType.DRC_OUT,
  EDeviceType.HBC_IN,
  EDeviceType.HBC_OUT,
  EDeviceType.RLC_IN,
  EDeviceType.RLC_OUT,
  EDeviceType.RRC_IN,
  EDeviceType.RRC_OUT,
  EDeviceType.SBC_IN,
  EDeviceType.SBC_OUT,
  EDeviceType.SBC_W_IN,
  EDeviceType.SBC_W_OUT,
  EDeviceType.SDC_IN,
  EDeviceType.SDC_OUT,
  EDeviceType.WBC_IN,
  EDeviceType.WBC_OUT,
  EDeviceType.IF_IN,
  EDeviceType.IF_OUT,
  EDeviceType.ZS2_IF_IN,
  EDeviceType.ZS2_IF_OUT,
  EDeviceType.SFC,
  EDeviceType.SBM,
];

// don't show cables for UL
export const deviceHasCurves = (deviceType: EDeviceType, isUl = false) => {
  return isUl
    ? CURVES_DEVICE_TYPES.indexOf(deviceType) !== -1 &&
        deviceType !== EDeviceType.WIRELV &&
        deviceType !== EDeviceType.WIREMV &&
        deviceType !== EDeviceType.WIREMV_IN
    : CURVES_DEVICE_TYPES.indexOf(deviceType) !== -1;
};
