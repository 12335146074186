import { IBackendResponse, IScheme } from 'types/compute';
import { Device } from 'types/devices/device';
import ApiCalls from './ApiCalls';

export default class ContactorAPI {
  static async getContactorFilters(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`ContactorFilter`, requestBody);
    return data;
  }

  static async store(requestBody: IScheme): Promise<IBackendResponse> {
    const { data } = await ApiCalls.post<IBackendResponse, IScheme>(`ContactorStore`, requestBody);
    return data;
  }

  static async getContactorFiltersGlobal(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`ContactorFilterGlobals`, requestBody);
    return data;
  }
}
