/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { VARIABLES, PROJECT } from 'types/constants.d';
import { EEarthingSystem, ELvLineType } from 'types/devices/enums.d';
import { VariablesKnowActions } from 'types/known-actions';
import { Variables } from 'types/variables/store';

export const initialState: Variables.State = {
  MvTypeReadOnly: false,
  PlantGroundResistance: 5,
  ABBWIREMAXITERATIONS: 25,
  ABBWIREITERATIONSDONE: 3,
  ABBLFVOLTAGEPRECISION: 0.00001,
  ABBLFPOWERPRECISION: 0.0001,
  ABBLFMAXITERATIONS: 25,
  ABBPROTECTIONRESISTANCE: 0.00001,
  ABBLFITERATIONSDONE: 4,
  ABBLFPOWERPRECISIONREACHED: 1.6528924007275236e-8,
  ABBLFVOLTAGEPRECISIONREACHED: 1.6528924007275236e-8,
  PowerSourceType: 'Utility',
  TransformersNumber: 1,
  TransformersSr: 25000,
  Scenarios: {
    name: [
      {
        '@': {
          isCurrent: true,
        },
        '#': 'default',
      },
    ],
  },
  '\nABBALLISGREEN': 1,
  UNKSIGNPREFIX: '-ND',
  ComputeAllScenarios: true,
  UseRatedCurrentToSizeBranches: true,
  PreserveConstantCurrrentLoads: true,
  LiveAutoBalance: true,
  CableStandard: 'IEC60364',
  ShortCircuitStandard: 'IEC 60909-1',
  ABBWIREDB: '',
  ABB_WIRESDATACUSTOM_DBPATH: '',
  ABBDBMVCABLES: '',
  ABB_X_DEPENDING_ON_SYMETRY: 1,
  UseOnlySomeSections: false,
  ABB_Custom_WiresData: 0,
  NeutralOptimization: true,
  PlantFrequency: 50,
  PAPERSIZE: {
    x: 420,
    y: 297,
  },
  PowerSupplyVoltageTolerance: '10',
  OverExciting: '1.6-2.0',
  MvAvailable: false,
  PlantLvUn: 400,
  PlantMvUn: 15000,
  ABBVOLTGENERATOR: 400,
  ABBICCBT: 6,
  ABBICCMT: 12.5,
  CosPhiIk3: 0.3,
  ABBICCCOSFIMT: 0.1,
  ABBICCROWBT: 0,
  ABBICCROWMT: 1,
  Ik1LNOverIk3Ratio: 60,
  Ik1LPEOverIk3Ratio: 60,
  ShortCircuitCurrentToGround: 50,
  ShortCircuitCurrentToGroundMin: 50,
  PlantEarthingSystem: EEarthingSystem.TNS,
  PlantLineType: ELvLineType.LLLN,
  ABBSTANDARD: 0,
  MaxContactVoltage: 50,
  BreakerFamilyAccordingToIB: false,
  ChooseMcbThreshold: 63,
  ChooseAcbThreshold: 800,
  EnvironmentTemperature: 30,
  SwitchBoardInsideTemperature: 40,
  CbDefaultPolesForLLLN: '4P',
  CbDefaultPolesForLN: '1P+N',
  CapacityToGround: 0.000005569,
  PetersensCoilsPresent: true,
  PetersensCoilResistance: 190.5,
  PetersensCoilReactance: 606.5,
  ShortCircuitTimes: {
    time: [10],
  },
  ShortCircuitTimesObject: {
    time0: {
      value: 10,
      editable: true,
      error: {},
    },
    time1: {
      value: 20,
      editable: false,
      error: {},
    },
    time2: {
      value: 70,
      editable: false,
      error: {},
    },
    time3: {
      value: 100,
      editable: false,
      error: {},
    },
  },
  ChooseElectronic: false,
  ChooseElectronicThreshold: 250,
  IndirectTouchTripTimes: {
    time: [
      {
        '@': {
          id: 'FinalTNUpTo120',
        },
        '#': 0.8,
      },
      {
        '@': {
          id: 'FinalTNUpTo240',
        },
        '#': 0.4,
      },
      {
        '@': {
          id: 'FinalTNUpTo400',
        },
        '#': 0.2,
      },
      {
        '@': {
          id: 'FinalTNOver400',
        },
        '#': 0.1,
      },
      {
        '@': {
          id: 'FinalTTUpTo120',
        },
        '#': 0.3,
      },
      {
        '@': {
          id: 'FinalTTUpTo240',
        },
        '#': 0.2,
      },
      {
        '@': {
          id: 'FinalTTUpTo400',
        },
        '#': 0.07,
      },
      {
        '@': {
          id: 'FinalTTOver400',
        },
        '#': 0.03,
      },
      {
        '@': {
          id: 'NotFinalTN',
        },
        '#': 5,
      },
      {
        '@': {
          id: 'NotFinalTT',
        },
        '#': 1,
      },
    ],
  },
  ABBTRIPTIMESRO: 0,
  '\nABBSTANDARDTRIPTIME': '0.8;0.4;0.2;0.1;0.3;0.2;0.07;0.04\n#VDE 0102',
  ABBBOARDSIGN: '',
  '\nABBCURRENTBOARD': 'Switchboard1',
  UseDerating: false,
  MinBreakingCapacityForMCCB: 4.5,
  MinBreakingCapacityForMCB: 4.5,
  DisabledProductFamilies: {
    name: [
      'Air CB Emax',
      'Air CB Emax 2 UL',
      'Limiting Air CB Emax',
      'Limiting moulded case CB Isomax',
      'Miniature CB System SMISSLINE',
      'Miniature CB System Smissline-S',
      'Miniature CB System pro M',
      'Moulded case CB Formula',
      'Moulded case CB Isomax',
      'Moulded case CB Tmax XT UL',
      'SACE Tmax XT UL',
    ],
  },
  ABBNONCBDISABLEDFAMILIES: '',
  ObsoleteFamilies: {
    name: [
      'Air CB Emax',
      'Air CB Emax 2 UL',
      'Limiting Air CB Emax',
      'Limiting moulded case CB Isomax',
      'Miniature CB System pro M',
      'Moulded case CB Isomax',
      'SACE Tmax XT UL',
    ],
  },
  DisabledProductVariants: {
    name: [],
  },
  AllProductsFamilies: {
    airBreaker: [
      'Air CB Emax',
      'Air CB Emax 2',
      'Air CB Emax 2 UL',
      'Air CB Emax new',
      'Limiting Air CB Emax',
      'Limiting Air CB Emax 2',
      'Limiting Air CB Emax new',
    ],
    obsolette: [
      'Air CB Emax',
      'Air CB Emax 2 UL',
      'Limiting Air CB Emax',
      'Limiting moulded case CB Isomax',
      'Miniature CB System pro M',
      'Moulded case CB Isomax',
      'SACE Tmax XT UL',
    ],
    contactor: [
      'Contactors - EN range',
      'Contactors - ESB range',
      'E 250 - Latching relays (electro-magnetic)',
      'E 259 - Installation relays',
      'E 260 - Latching relays (electronic)',
      'Industrial contactors A range',
      'Industrial contactors AF range',
      'Industrial contactors AS range',
      'Mini contactors',
      'Mini contactors relays',
    ],
    disconnector: [
      'Derived from ACB',
      'Derived from MCCB',
      'Disconnectors OT-OETL',
      'Miniature Switches E 200',
      'Motorized Switch-disconnectors OTM',
    ],
    overloadRelay: ['Electronic Overload Relays', 'Thermal Overload Relays'],
    miniatureBreaker: [
      'FS400',
      'Miniature CB System pro M',
      'Miniature CB System pro M Compact',
      'Miniature CB System pro M Compact High performance',
      'Miniature CB System SMISSLINE',
      'Miniature CB System Smissline-S',
      'S400',
      'S400NP',
    ],
    fuse: [
      'Full protection Fuse Bases IP 20',
      'Fuse Bases E 90',
      'Fuse Bases E 930',
      'Fuse Bases EasyLine',
      'Fuse Switch Disconnectors EasyLine',
      'Fuse Switch Disconnectors InLine II',
      'Open Fuse Bases with phase barriers IP 00',
      'Switch Disconnector Fuses OESA',
      'Switch Disconnector Fuses OS',
      'Switch Disconnector Fuses OSM',
      'Switch Disconnector Fuses SlimLine XR',
    ],
    mouldedCaseBreaker: [
      'Limiting moulded case CB Isomax',
      'Moulded case CB Formula',
      'Moulded case CB Isomax',
      'Moulded case CB Tmax',
      'SACE Tmax XT',
      'SACE Tmax XT UL',
    ],
    manualMotorStarter: ['Manual Motor Starter magnetic only MO', 'Manual Motor Starter thermomagnetic MS'],
    residualCurrentBreaker: ['Miniature CB System pro M', 'Miniature CB System pro M Compact'],
    variants: [
      'TMB',
      'TMBRc',
      'TMC',
      'TMCRc',
      'TMD',
      'TMFB',
      'TMFBRc',
      'TMFC',
      'TMFCRc',
      'TMFD',
      'TMFE',
      'TMFK',
      'TMFKRc',
      'TMFZ',
      'TMK',
    ],
  },
  ABBVREFFACTORFORICU: 1.01,
  '\nABBTEXTGRAPHSTRUCT': '',
  '\nABBTEXTBREAKERSTRUCT': '',
  ABBIBTRESHOLD: 63,
  ABBIMAXTRESHOLD: 25000,
  ABBCURVESVOLTAGE: -1,
  WEBDOC_CURRENT_DEVICE: 'xx0',
  WEBDOC_DEVICE_KEY_1: -1,
  WEBDOC_DEVICE_KEY_2: -1,
  WEBDOC_BCKDISC_STATE: '',
  USER_COUNTRY: 'IT',
  WEBDOC_STANDALONE_CURVES: 1,
  VoltageDropByUpstream: false,
  ABBSHOWCALCOPT: 0,
  MvTechnologies: [],
  MvRatedValues: {},
  ConsiderFuseDerating: false,
  MainStandard: 'IEC',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: VariablesKnowActions): Variables.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case VARIABLES.SET_VARIABLES:
        return { ...draft, ...action.payload };
      case PROJECT.LOAD_PROJECT:
        return { ...initialState, ...action.payload.projectState.variables };
      default:
        return state;
    }
  });
