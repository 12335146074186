/* eslint-disable @typescript-eslint/unbound-method */
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import Select from 'components/generic/selectContainer';
import Switch from 'components/generic/Switch';
import FeederPropertiesGrid from 'components/generic/FeederPropertiesGrid';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'store';
import { Column } from 'types/components/grid';
import { IProtectedObject } from 'types/devices/protectedObjects';
import { updateProtectionHandler } from './actions';

const ProtectedObjectTab: React.FunctionComponent<IProtectedObject> = ({
  selectedDevice,
  selectedDeviceId,
}): React.ReactElement => {
  const [orientation, setOrientation] = useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      () => {
        setOrientation(window.orientation);
      },
      false
    );
    return () => dispatch(updateProtectionHandler(selectedDeviceId, 'Relations', {}, true));
  }, []);

  const maxDisconnectionTime = (): React.ReactElement[] => [
    <GridRow key={0}>
      <GridColumn col={8}>
        <h4>{t('DOCWEB_PROTECTEDOBJECTS_MAXDISCONNECTIONTIME')}</h4>
      </GridColumn>
    </GridRow>,
    <GridRow key={1}>
      <GridColumn col={3}>
        <Input
          cols={[7, 0, 0, 0]}
          type="text"
          onChange={(e) => dispatch(updateProtectionHandler(selectedDeviceId, e.name, e.value))}
          className="info-project-input"
          name="TripTimeOtherCircuits"
          label="DOCWEB_PROTECTEDOBJECTS_OTHERCIRCUITS"
          data={selectedDevice}
          disabled
        />
      </GridColumn>
      <GridColumn col={3}>
        <Input
          cols={[7, 0, 0, 0]}
          type="text"
          onChange={(e) => dispatch(updateProtectionHandler(selectedDeviceId, e.name, e.value))}
          className="info-project-input"
          name="TripTimeFinalCircuits"
          label="DOCWEB_IDC_STAR_FINAL_32_TXT"
          data={selectedDevice}
          disabled
        />
      </GridColumn>
    </GridRow>,
  ];

  const protection = (): React.ReactElement[] => {
    const gridColsNames: Array<string> = [];
    const gridDisplayKeys: Array<string> = [];
    const gridDisplayType: Array<string> = [];
    const gridColsAlignment: Array<number> = [];
    if (selectedDevice.Protection === 'disconnectors') {
      gridColsNames.push('ID', 'DOCWEB_MOTOR_IN', 'DOCWEB_MODAL_PROTECTION_IKMAX');
      gridDisplayKeys.push('userId', 'current', 'iMax');
      gridColsAlignment.push(2, 1, 1);
    } else {
      gridColsNames.push(
        'ID',
        'DOCWEB_MODAL_PROTECTION_IZ',
        'DOCWEB_MODAL_PROTECTION_IKMIN',
        'DOCWEB_MODAL_PROTECTION_IKMAX',
        'DOCWEB_MODAL_PROTECTION_K2S2',
        'DOCWEB_MODAL_PROTECTION_I2T'
      );
      gridDisplayKeys.push('userId', 'current', 'minSCSurrent', 'iMax', 'i2t', 'cbI2t');
      gridColsAlignment.push(1, 1, 1, 1, 2, 2);
    }
    gridDisplayKeys.forEach(() => gridDisplayType.push('Label'));

    return [
      <GridRow key={0} style={{ marginBottom: '20px' }}>
        <GridColumn col={3}>
          <Select
            cols={[7, 0, 0]}
            className="info-project-input"
            name="Protection"
            label="STRINGS_IDD_PROTECTION_DIALOG"
            onChange={(e) => dispatch(updateProtectionHandler(selectedDeviceId, 'Protection', e.value))}
            selectList={Object.keys(selectedDevice.Relations)}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={3}>
          <Switch
            label="STRINGS_IDC_BP1_AUTOPROTECTION_CHECK"
            name="ProtectionAutoAssignOff"
            value={!selectedDevice.ProtectionAutoAssignOff}
            onChange={(e) => dispatch(updateProtectionHandler(selectedDeviceId, 'ProtectionAutoAssignOff', !e.value))}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={8}>
          <FeederPropertiesGrid
            cols={[2, 6]}
            colsAlignment={gridColsAlignment as Column[]}
            colsNames={gridColsNames}
            colsItems={
              selectedDevice.Relations && selectedDevice.Relations[selectedDevice.Protection as string]
                ? [selectedDevice.Relations[selectedDevice.Protection as string]]
                : []
            }
            displayKeys={gridDisplayKeys}
            displayType={gridDisplayType}
            onCheckBoxChange={updateProtectionHandler}
            ProtectionAutoAssignOff={selectedDevice.ProtectionAutoAssignOff as boolean}
            cut={UnitsDataAdapter.cutUnits}
            icons={['gauge', 'voltage', 'user']}
            onSelectChange={() => console.log('test')}
            onSelectFocusOut={() => console.log('test2')}
          />
        </GridColumn>
      </GridRow>,
    ];
  };

  const protectedObject = () => {
    return (
      <Grid>
        {maxDisconnectionTime()}
        <hr />
        {protection()}
      </Grid>
    );
  };

  const orientationType = window.orientation;

  return (
    <Grid>
      {orientationType === 0 && isMobileOnly ? (
        <h2 style={{ marginTop: '50px', marginBottom: '50px' }}>Please, rotate the phone to activate the screen</h2>
      ) : (
        protectedObject()
      )}
    </Grid>
  );
};

export default ProtectedObjectTab;
