export const getParamName = (diagramName: string): { x: string; y: string } => {
  switch (diagramName) {
    case 'TFFF':
    case 'TFN':
      return { x: '[A]', y: 's' };
    case 'EFFF':
    case 'EFN':
      return { x: '[kA]', y: '[MA2s]' };
    case 'PEAK':
      return { x: '[kA]', y: '[kA]' };
    default:
      return { x: '[A]', y: 's' };
  }
};
