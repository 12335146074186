export default `
<graphml xmlns='http://graphml.graphdrawing.org/xmlns'  
      xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance'
      xsi:schemaLocation='http://graphml.graphdrawing.org/xmlns ${window.location.origin}/xsd/1.0/DocWebXSD.xsd'>
<key id='type' for='all' attr.name='type' attr.type='string'/>
<key id='symbol' for='node' attr.name='symbol' attr.type='string'/>
<key id='page' for='all' attr.name='page' attr.type='string'/>
<key id='x' for='node' attr.name='x' attr.type='double'/>
<key id='y' for='node' attr.name='y' attr.type='double'/>
<key id='x2' for='node' attr.name='x2' attr.type='double'/>
<key id='y2' for='node' attr.name='y2' attr.type='double'/>
<key id='vertices' for='edge' attr.name='vertices' attr.type='double'/>
<key id='rotated' for='node' attr.name='rotated' attr.type='boolean'/>
<key id='ObjectSign' for='all' attr.name='ObjectSign' attr.type='string'/>
<key id='ObjectStatus' for='all' attr.name='ObjectStatus' attr.type='string'/>
<key id='Locked' for='node' attr.name='Locked' attr.type='boolean'/>
<key id='IsOffInScenario' for='node' attr.name='IsOffInScenario'/>
<key id='IsOff' for='all' attr.name='IsOff' attr.type='boolean'/>
<key id='UserDescription1' for='node' attr.name='UserDescription1' attr.type='string'/>
<key id='UserDescription2' for='node' attr.name='UserDescription2' attr.type='string'/>
<key id='Uref' for='all' attr.name='Uref' attr.type='double'/>
<key id='LineType' for='all' attr.name='LineType' attr.type='string'/>
<key id='EarthingSystem' for='all' attr.name='EarthingSystem' attr.type='string'/>
<key id='SwitchBoardName' for='all' attr.name='SwitchBoardName' attr.type='string'/>
<key id='feederId' for='all' attr.name='feederId' attr.type='string'/>
<key id='NominalVoltage' for='node' attr.name='NominalVoltage' attr.type='double'/>
<key id='CosPhiNominal' for='node' attr.name='CosPhiNominal' attr.type='double'/>
<key id='CosPhiRequested' for='node' attr.name='CosPhiRequested' attr.type='double'/>
<key id='LoadCurrent' for='all' attr.name='LoadCurrent' attr.type='double'/>
<key id='LoadCurrentForCurves' for='all' attr.name='LoadCurrentForCurves' attr.type='double'/>
<key id='ComputedVoltage' for='all' attr.name='ComputedVoltage' attr.type='double'/>
<key id='ComputedPhaseShift' for='all' attr.name='ComputedPhaseShift' attr.type='double'/>
<key id='ComputedCosPhi' for='all' attr.name='ComputedCosPhi' attr.type='double'/>
<key id='ComputedP' for='all' attr.name='ComputedP' attr.type='double'/>
<key id='ComputedQ' for='all' attr.name='ComputedQ' attr.type='double'/>
<key id='UoNominal' for='all' attr.name='UoNominal' attr.type='double'/>
<key id='UoComputed' for='all' attr.name='UoComputed' attr.type='double'/>
<key id='LoadCurrentL1' for='all' attr.name='LoadCurrentL1' attr.type='double'/>
<key id='LoadCurrentL2' for='all' attr.name='LoadCurrentL2' attr.type='double'/>
<key id='LoadCurrentL3' for='all' attr.name='LoadCurrentL3' attr.type='double'/>
<key id='CosPhiL1' for='all' attr.name='CosPhiL1' attr.type='double'/>
<key id='CosPhiL2' for='all' attr.name='CosPhiL2' attr.type='double'/>
<key id='CosPhiL3' for='all' attr.name='CosPhiL3' attr.type='double'/>
<key id='IbNeutral' for='all' attr.name='IbNeutral' attr.type='double'/>
<key id='CosPhiNeutral' for='all' attr.name='CosPhiNeutral' attr.type='double'/>
<key id='GroundingResistance' for='node' attr.name='GroundingResistance' attr.type='double'/>
<key id='MaxContactVoltage' for='all' attr.name='MaxContactVoltage' attr.type='double'/>
<key id='ProductDescription' for='node' attr.name='ProductDescription' attr.type='string'/>
<key id='ProductIdList' for='node' attr.name='ProductIdList'/>
<key id='OverLoadProtectionObjectId' for='node' attr.name='OverLoadProtectionObjectId' attr.type='string'/>
<key id='ShortCircuitTerminal1ProtectionObjectId' for='node' attr.name='ShortCircuitTerminal1ProtectionObjectId' attr.type='string'/>
<key id='ShortCircuitTerminal2ProtectionObjectId' for='node' attr.name='ShortCircuitTerminal2ProtectionObjectId' attr.type='string'/>
<key id='IndirectTouchTerminal1ProtectionObjectId' for='node' attr.name='IndirectTouchTerminal1ProtectionObjectId' attr.type='string'/>
<key id='IndirectTouchTerminal2ProtectionObjectId' for='node' attr.name='IndirectTouchTerminal2ProtectionObjectId' attr.type='string'/>
<key id='BackupTerminal1ObjectId' for='node' attr.name='BackupTerminal1ObjectId' attr.type='string'/>
<key id='BackupTerminal2ObjectId' for='node' attr.name='BackupTerminal2ObjectId' attr.type='string'/>
<key id='BackupTerminal1GrantedCurrent' for='node' attr.name='BackupTerminal1GrantedCurrent' attr.type='double'/>
<key id='BackupTerminal2GrantedCurrent' for='node' attr.name='BackupTerminal2GrantedCurrent' attr.type='double'/>
<key id='Discrimination' for='node' attr.name='Discrimination'/>
<key id='AvailableDiscrimination' for='node' attr.name='AvailableDiscrimination'/>
<key id='DiscriminationLevel' for='node' attr.name='DiscriminationLevel' attr.type='double'/>
<key id='BackupLevel' for='node' attr.name='BackupLevel' attr.type='double'/>
<key id='AvailableBackup' for='node' attr.name='AvailableBackup'/>
<key id='ProtectionAutoAssignOff' for='node' attr.name='ProtectionAutoAssignOff' attr.type='boolean'/>
<key id='BackupDeviceNumber' for='node' attr.name='BackupDeviceNumber' attr.type='int'/>
<key id='DiscriminationDeviceNumber' for='node' attr.name='DiscriminationDeviceNumber' attr.type='int'/>
<key id='MotorFeederContactorId' for='node' attr.name='MotorFeederContactorId' attr.type='string'/>
<key id='ParallelFeederSourceId' for='node' attr.name='ParallelFeederSourceId' attr.type='string'/>
<key id='ParallelFeederCableId' for='node' attr.name='ParallelFeederCableId' attr.type='string'/>
<key id='ScTheveninL3I' for='all' attr.name='ScTheveninL3I'/>
<key id='ScTheveninL3Ip' for='all' attr.name='ScTheveninL3Ip' attr.type='double'/>
<key id='ScTheveninL2I' for='all' attr.name='ScTheveninL2I'/>
<key id='ScTheveninL2Ip' for='all' attr.name='ScTheveninL2Ip' attr.type='double'/>
<key id='ScTheveninLNeutralI' for='all' attr.name='ScTheveninLNeutralI'/>
<key id='ScTheveninLNeutralIp' for='all' attr.name='ScTheveninLNeutralIp' attr.type='double'/>
<key id='ScTheveninLPEI' for='all' attr.name='ScTheveninLPEI'/>
<key id='ScTheveninLPEIp' for='all' attr.name='ScTheveninLPEIp' attr.type='double'/>
<key id='ScTheveninL3IMin' for='all' attr.name='ScTheveninL3IMin'/>
<key id='ScTheveninL2IMin' for='all' attr.name='ScTheveninL2IMin'/>
<key id='ScTheveninLNeutralIMin' for='all' attr.name='ScTheveninLNeutralIMin'/>
<key id='ScTheveninLPEIMin' for='all' attr.name='ScTheveninLPEIMin'/>
<key id='Terminal0ScTheveninL3I' for='all' attr.name='Terminal0ScTheveninL3I'/>
<key id='Terminal1ScTheveninL3I' for='all' attr.name='Terminal1ScTheveninL3I'/>
<key id='Terminal0ScTheveninL2I' for='all' attr.name='Terminal0ScTheveninL2I'/>
<key id='Terminal1ScTheveninL2I' for='all' attr.name='Terminal1ScTheveninL2I'/>
<key id='Terminal0ScTheveninLPEI' for='all' attr.name='Terminal0ScTheveninLPEI'/>
<key id='Terminal1ScTheveninLPEI' for='all' attr.name='Terminal1ScTheveninLPEI'/>
<key id='Terminal0ScTheveninLNeutralI' for='all' attr.name='Terminal0ScTheveninLNeutralI'/>
<key id='Terminal1ScTheveninLNeutralI' for='all' attr.name='Terminal1ScTheveninLNeutralI'/>
<key id='Terminal0ScTheveninL3IMin' for='all' attr.name='Terminal0ScTheveninL3IMin'/>
<key id='Terminal1ScTheveninL3IMin' for='all' attr.name='Terminal1ScTheveninL3IMin'/>
<key id='Terminal0ScTheveninL2Imin' for='all' attr.name='Terminal0ScTheveninL2Imin'/>
<key id='Terminal1ScTheveninL2IMin' for='all' attr.name='Terminal1ScTheveninL2IMin'/>
<key id='Terminal0ScTheveninLNeutralIMin' for='all' attr.name='Terminal0ScTheveninLNeutralIMin'/>
<key id='Terminal1ScTheveninLNeutralIMin' for='all' attr.name='Terminal1ScTheveninLNeutralIMin'/>
<key id='Terminal0ScTheveninLPEIMin' for='all' attr.name='Terminal0ScTheveninLPEIMin'/>
<key id='Terminal1ScTheveninLPEIMin' for='all' attr.name='Terminal1ScTheveninLPEIMin'/>
<key id='ScIEC61363IsecondK' for='all' attr.name='ScIEC61363IsecondK' attr.type='double'/>
<key id='ScIEC61363Ik' for='all' attr.name='ScIEC61363Ik' attr.type='double'/>
<key id='ScIEC61363Ip' for='all' attr.name='ScIEC61363Ip' attr.type='double'/>
<key id='ScIEC61363IAc' for='all' attr.name='ScIEC61363IAc'/>
<key id='ScIEC61363IDc' for='all' attr.name='ScIEC61363IDc'/>
<key id='ScIEC61363Ikt' for='all' attr.name='ScIEC61363Ikt'/>
<key id='Terminal0ScIEC61363IAc' for='all' attr.name='Terminal0ScIEC61363IAc'/>
<key id='Terminal1ScIEC61363IAc' for='all' attr.name='Terminal1ScIEC61363IAc'/>
<key id='Terminal0ScIEC61363IAcMin' for='all' attr.name='Terminal0ScIEC61363IAcMin'/>
<key id='Terminal1ScIEC61363IAcMin' for='all' attr.name='Terminal1ScIEC61363IAcMin'/>
<key id='ScIEC61363IAcMin' for='all' attr.name='ScIEC61363IAcMin'/>
<key id='ScIEC60909L3ISecondK' for='all' attr.name='ScIEC60909L3ISecondK' attr.type='double'/>
<key id='ScIEC60909L3Ik' for='all' attr.name='ScIEC60909L3Ik' attr.type='double'/>
<key id='ScIEC60909L3Ip' for='all' attr.name='ScIEC60909L3Ip' attr.type='double'/>
<key id='ScIEC60909L3Ib' for='all' attr.name='ScIEC60909L3Ib'/>
<key id='ScL3IbForCurves' for='all' attr.name='ScL3IbForCurves'/>
<key id='ScIEC60909L3Idc' for='all' attr.name='ScIEC60909L3Idc'/>
<key id='ScIEC60909L3IbAsym' for='all' attr.name='ScIEC60909L3IbAsym'/>
<key id='ScIEC60909L2ISecondK' for='all' attr.name='ScIEC60909L2ISecondK' attr.type='double'/>
<key id='ScIEC60909L2Ik' for='all' attr.name='ScIEC60909L2Ik' attr.type='double'/>
<key id='ScIEC60909L2Ip' for='all' attr.name='ScIEC60909L2Ip' attr.type='double'/>
<key id='ScIEC60909L2Ib' for='all' attr.name='ScIEC60909L2Ib'/>
<key id='ScIEC60909L2Idc' for='all' attr.name='ScIEC60909L2Idc'/>
<key id='ScIEC60909L2IbAsym' for='all' attr.name='ScIEC60909L2IbAsym'/>
<key id='ScIEC60909LPEISecondK' for='all' attr.name='ScIEC60909LPEISecondK' attr.type='double'/>
<key id='ScIEC60909LPEIk' for='all' attr.name='ScIEC60909LPEIk' attr.type='double'/>
<key id='ScIEC60909LPEIp' for='all' attr.name='ScIEC60909LPEIp' attr.type='double'/>
<key id='ScIEC60909LPEIb' for='all' attr.name='ScIEC60909LPEIb'/>
<key id='ScIEC60909LPEIdc' for='all' attr.name='ScIEC60909LPEIdc'/>
<key id='ScIEC60909LPEIbAsym' for='all' attr.name='ScIEC60909LPEIbAsym'/>
<key id='ScIEC60909LNeutralISecondK' for='all' attr.name='ScIEC60909LNeutralISecondK' attr.type='double'/>
<key id='ScIEC60909LNeutralIk' for='all' attr.name='ScIEC60909LNeutralIk' attr.type='double'/>
<key id='ScIEC60909LNeutralIp' for='all' attr.name='ScIEC60909LNeutralIp' attr.type='double'/>
<key id='ScIEC60909LNeutralIb' for='all' attr.name='ScIEC60909LNeutralIb'/>
<key id='ScIEC60909LNeutralIdc' for='all' attr.name='ScIEC60909LNeutralIdc'/>
<key id='ScIEC60909LNeutralIbAsym' for='all' attr.name='ScIEC60909LNeutralIbAsym'/>
<key id='ScIEC60909L3IbMin' for='all' attr.name='ScIEC60909L3IbMin'/>
<key id='ScIEC60909L2IbMin' for='all' attr.name='ScIEC60909L2IbMin'/>
<key id='ScIEC60909LNeutralIbMin' for='all' attr.name='ScIEC60909LNeutralIbMin'/>
<key id='ScIEC60909LPEIbMin' for='all' attr.name='ScIEC60909LPEIbMin'/>
<key id='ScLPEIbForCurves' for='all' attr.name='ScLPEIbForCurves'/>
<key id='Terminal0ScIEC60909L3Ib' for='all' attr.name='Terminal0ScIEC60909L3Ib'/>
<key id='Terminal1ScIEC60909L3Ib' for='all' attr.name='Terminal1ScIEC60909L3Ib'/>
<key id='Terminal0ScIEC60909L2Ib' for='all' attr.name='Terminal0ScIEC60909L2Ib'/>
<key id='Terminal1ScIEC60909L2Ib' for='all' attr.name='Terminal1ScIEC60909L2Ib'/>
<key id='Terminal0ScIEC60909LNIb' for='all' attr.name='Terminal0ScIEC60909LNIb'/>
<key id='Terminal1ScIEC60909LNIb' for='all' attr.name='Terminal1ScIEC60909LNIb'/>
<key id='Terminal0ScIEC60909LPEIb' for='all' attr.name='Terminal0ScIEC60909LPEIb'/>
<key id='Terminal1ScIEC60909LPEIb' for='all' attr.name='Terminal1ScIEC60909LPEIb'/>
<key id='Terminal0ScIEC60909L3IbMin' for='all' attr.name='Terminal0ScIEC60909L3IbMin'/>
<key id='Terminal1ScIEC60909L3IbMin' for='all' attr.name='Terminal1ScIEC60909L3IbMin'/>
<key id='Terminal0ScIEC60909L2IbMin' for='all' attr.name='Terminal0ScIEC60909L2IbMin'/>
<key id='Terminal1ScIEC60909L2IbMin' for='all' attr.name='Terminal1ScIEC60909L2IbMin'/>
<key id='Terminal0ScIEC60909LNIbMin' for='all' attr.name='Terminal0ScIEC60909LNIbMin'/>
<key id='Terminal1ScIEC60909LNIbMin' for='all' attr.name='Terminal1ScIEC60909LNIbMin'/>
<key id='Terminal0ScIEC60909LPEIbMin' for='all' attr.name='Terminal0ScIEC60909LPEIbMin'/>
<key id='Terminal1ScIEC60909LPEIbMin' for='all' attr.name='Terminal1ScIEC60909LPEIbMin'/>
<key id='ScLiveMax' for='all' attr.name='ScLiveMax'/>
<key id='ScLivePeak' for='all' attr.name='ScLivePeak' attr.type='double'/>
<key id='ScLiveMin' for='all' attr.name='ScLiveMin'/>
<key id='ScNeutralMax' for='all' attr.name='ScNeutralMax'/>
<key id='ScNeutralPeak' for='all' attr.name='ScNeutralPeak' attr.type='double'/>
<key id='ScNeutralMin' for='all' attr.name='ScNeutralMin'/>
<key id='ScPEMax' for='all' attr.name='ScPEMax'/>
<key id='ScPEPeak' for='all' attr.name='ScPEPeak' attr.type='double'/>
<key id='ScPEMin' for='all' attr.name='ScPEMin'/>
<key id='Termina1LteAtMaximumScCurrent' for='node' attr.name='Termina1LteAtMaximumScCurrent' attr.type='double'/>
<key id='Termina2LteAtMaximumScCurrent' for='node' attr.name='Termina2LteAtMaximumScCurrent' attr.type='double'/>
<key id='TripUnit' for='node' attr.name='TripUnit'/>
<key id='BestTripUnitTolerances' for='node' attr.name='BestTripUnitTolerances' attr.type='boolean'/>
<key id='UserCoordination' for='node' attr.name='UserCoordination'/>
<key id='CurveID' for='node' attr.name='CurveID'/>
<key id='ShowScMax' for='node' attr.name='ShowScMax'/>
<key id='ShowScMin' for='node' attr.name='ShowScMin'/>
<key id='ShowIB' for='node' attr.name='ShowIB'/>
<key id='ShowIz' for='all' attr.name='ShowIz'/>
<key id='OverLoadCheckDone' for='node' attr.name='OverLoadCheckDone' attr.type='string'/>
<key id='ShortCircuitCheckDone' for='node' attr.name='ShortCircuitCheckDone' attr.type='string'/>
<key id='IndirectTouchCheckDone' for='node' attr.name='IndirectTouchCheckDone' attr.type='string'/>
<key id='DiscriminationCheckDone' for='node' attr.name='DiscriminationCheckDone' attr.type='string'/>
<key id='Terminal1ShortCircuitCheckDone' for='node' attr.name='Terminal1ShortCircuitCheckDone' attr.type='string'/>
<key id='Terminal1IndirectTouchCheckDone' for='node' attr.name='Terminal1IndirectTouchCheckDone' attr.type='string'/>
<key id='Terminal2ShortCircuitCheckDone' for='node' attr.name='Terminal2ShortCircuitCheckDone' attr.type='string'/>
<key id='Terminal2IndirectTouchCheckDone' for='node' attr.name='Terminal2IndirectTouchCheckDone' attr.type='string'/>
<key id='IBUsed' for='node' attr.name='IBUsed' attr.type='double'/>
<key id='CosPhiUsed' for='node' attr.name='CosPhiUsed' attr.type='double'/>
<key id='CarryingCapacityIz' for='node' attr.name='CarryingCapacityIz' attr.type='double'/>
<key id='NeutralCarryingCapacityIz' for='node' attr.name='NeutralCarryingCapacityIz' attr.type='double'/>
<key id='PECarryingCapacityIz' for='node' attr.name='PeCarryingCapacityIz' attr.type='double'/>
<key id='CableLength' for='node' attr.name='CableLength' attr.type='double'/>
<key id='PELength' for='node' attr.name='PELength' attr.type='double'/>
<key id='CableLengthIFE' for='node' attr.name='CableLengthIFE' attr.type='double'/>
<key id='PeLengthIFE' for='node' attr.name='PeLengthIFE' attr.type='double'/>
<key id='Conductor' for='node' attr.name='Conductor' attr.type='string'/>
<key id='Insulator' for='node' attr.name='Insulator' attr.type='string'/>
<key id='PEConductor' for='node' attr.name='PEConductor' attr.type='string'/>
<key id='PEInsulator' for='node' attr.name='PEInsulator' attr.type='string'/>
<key id='ScreenConductor' for='node' attr.name='ScreenConductor' attr.type='string'/>
<key id='CableType' for='node' attr.name='CableType' attr.type='string'/>
<key id='PEType' for='node' attr.name='PEType' attr.type='string'/>
<key id='ScreenType' for='node' attr.name='ScreenType' attr.type='string'/>
<key id='InstallationMethod' for='node' attr.name='InstallationMethod' attr.type='string'/>
<key id='InstallationPlace' for='node' attr.name='InstallationPlace' attr.type='string'/>
<key id='InstallationDetails' for='node' attr.name='InstallationDetails' attr.type='string'/>
<key id='InstallationItem' for='node' attr.name='InstallationItem' attr.type='string'/>
<key id='InstallationScheme' for='node' attr.name='InstallationScheme' attr.type='string'/>
<key id='InstallationLayout' for='node' attr.name='InstallationLayout' attr.type='string'/>
<key id='ConduitRow' for='node' attr.name='ConduitRow' attr.type='string'/>
<key id='ConduitColumn' for='node' attr.name='ConduitColumn' attr.type='string'/>
<key id='InstallationEnvironment' for='node' attr.name='InstallationEnvironment' attr.type='string'/>
<key id='ThD' for='node' attr.name='ThD' attr.type='string'/>
<key id='IFEClass' for='node' attr.name='IFEClass' attr.type='string'/>
<key id='PEPresent' for='node' attr.name='PEPresent' attr.type='boolean'/>
<key id='PEWithLives' for='node' attr.name='PEWithLives' attr.type='boolean'/>
<key id='PELikeLives' for='node' attr.name='PELikeLives' attr.type='boolean'/>
<key id='DoubleInsulation' for='node' attr.name='DoubleInsulation' attr.type='boolean'/>
<key id='SymmetryRespected' for='node' attr.name='SymmetryRespected' attr.type='boolean'/>
<key id='dUCheckOn' for='node' attr.name='dUCheckOn' attr.type='boolean'/>
<key id='dUMax' for='node' attr.name='dUMax' attr.type='double'/>
<key id='dUMaxIFE' for='node' attr.name='dUMaxIFE' attr.type='double'/>
<key id='VoltageDrop' for='node' attr.name='VoltageDrop' attr.type='double'/>
<key id='IzIbRatio' for='node' attr.name='IzIbRatio' attr.type='double'/>
<key id='CarryingCapacityIzCorrectionFactor' for='node' attr.name='CarryingCapacityIzCorrectionFactor' attr.type='double'/>
<key id='CableCarryingCapacityUserFactor' for='node' attr.name='CableCarryingCapacityUserFactor' attr.type='double'/>
<key id='InstallationFactor' for='node' attr.name='InstallationFactor' attr.type='double'/>
<key id='TemperatureFactor' for='node' attr.name='TemperatureFactor' attr.type='double'/>
<key id='EnvironmentFactor' for='node' attr.name='EnvironmentFactor' attr.type='double'/>
<key id='CircuitsFactor' for='node' attr.name='CircuitsFactor' attr.type='double'/>
<key id='ConduitsFactor' for='node' attr.name='ConduitsFactor' attr.type='double'/>
<key id='ThdFactor' for='node' attr.name='ThdFactor' attr.type='double'/>
<key id='AsymmetricLayOutFactor' for='node' attr.name='AsymmetricLayOutFactor' attr.type='double'/>
<key id='AmbientTemperature' for='node' attr.name='AmbientTemperature' attr.type='double'/>
<key id='WorkingTemperature' for='node' attr.name='WorkingTemperature' attr.type='double'/>
<key id='MaxWorkingTemperature' for='node' attr.name='MaxWorkingTemperature' attr.type='double'/>
<key id='FaultTemperatureUp300' for='node' attr.name='FaultTemperatureUp300' attr.type='double'/>
<key id='FaultTemperatureOver300' for='node' attr.name='FaultTemperatureOver300' attr.type='double'/>
<key id='FaultMaxTemperature' for='node' attr.name='FaultMaxTemperature' attr.type='double'/>
<key id='IFETemperature' for='node' attr.name='IFETemperature' attr.type='double'/>
<key id='NotFinal' for='node' attr.name='NotFinal' attr.type='boolean'/>
<key id='LiveCordsNumber' for='node' attr.name='LiveCordsNumber' attr.type='int'/>
<key id='NeutralCordsNumber' for='node' attr.name='NeutralCordsNumber' attr.type='int'/>
<key id='PECordsNumber' for='node' attr.name='PECordsNumber' attr.type='int'/>
<key id='LiveCrossSection' for='node' attr.name='LiveCrossSection' attr.type='double'/>
<key id='NeutralCrossSection' for='node' attr.name='NeutralCrossSection' attr.type='double'/>
<key id='PECrossSection' for='node' attr.name='PECrossSection' attr.type='double'/>
<key id='ScreenCrossSection' for='node' attr.name='ScreenCrossSection' attr.type='double'/>
<key id='LiveResistance' for='node' attr.name='LiveResistance' attr.type='double'/>
<key id='LiveReactance' for='node' attr.name='LiveReactance' attr.type='double'/>
<key id='LiveResistance20' for='node' attr.name='LiveResistance20' attr.type='double'/>
<key id='LiveResistanceFaultTemperature' for='node' attr.name='LiveResistanceFaultTemperature' attr.type='double'/>
<key id='NeutralResistance' for='node' attr.name='NeutralResistance' attr.type='double'/>
<key id='NeutralReactance' for='node' attr.name='NeutralReactance' attr.type='double'/>
<key id='NeutralResistance20' for='node' attr.name='NeutralResistance20' attr.type='double'/>
<key id='NeutralResistanceFaultTemperature' for='node' attr.name='NeutralResistanceFaultTemperature' attr.type='double'/>
<key id='PEResistance' for='node' attr.name='PEResistance' attr.type='double'/>
<key id='PEReactance' for='node' attr.name='PEReactance' attr.type='double'/>
<key id='PEResistance20' for='node' attr.name='PEResistance20' attr.type='double'/>
<key id='PEResistanceFaultTemperature' for='node' attr.name='PEResistanceFaultTemperature' attr.type='double'/>
<key id='LiveK' for='node' attr.name='LiveK' attr.type='double'/>
<key id='LiveKFaultTemperature' for='node' attr.name='LiveKFaultTemperature' attr.type='double'/>
<key id='PEK' for='node' attr.name='PEK' attr.type='double'/>
<key id='NeutralK' for='node' attr.name='NeutralK' attr.type='double'/>
<key id='NeutralKFaultTemperature' for='node' attr.name='NeutralKFaultTemperature' attr.type='double'/>
<key id='KsquaredSsquaredAtWorkingTemp' for='node' attr.name='KsquaredSsquaredAtWorkingTemp' attr.type='boolean'/>
<key id='InsideDeltaFactor' for='node' attr.name='InsideDeltaFactor' attr.type='double'/>
<key id='LiveKsquaredSsquared' for='node' attr.name='LiveKsquaredSsquared' attr.type='double'/>
<key id='NeutralKsquaredSsquared' for='node' attr.name='NeutralKsquaredSsquared' attr.type='double'/>
<key id='PEKsquaredSsquared' for='node' attr.name='PEKsquaredSsquared' attr.type='double'/>
<key id='CrossSectionDescription' for='node' attr.name='CrossSectionDescription' attr.type='string'/>
<key id='BuswayLiveR' for='node' attr.name='BuswayLiveR' attr.type='double'/>
<key id='BuswayLiveRzero' for='node' attr.name='BuswayLiveRzero' attr.type='double'/>
<key id='BuswayNeutralRzero' for='node' attr.name='BuswayNeutralRzero' attr.type='double'/>
<key id='BuswayNeutralX' for='node' attr.name='BuswayNeutralX' attr.type='double'/>
<key id='BuswayNeutralR' for='node' attr.name='BuswayNeutralR' attr.type='double'/>
<key id='BuswayPERzero' for='node' attr.name='BuswayPERzero' attr.type='double'/>
<key id='BuswayPER' for='node' attr.name='BuswayPER' attr.type='double'/>
<key id='BuswayPEX' for='node' attr.name='BuswayPEX' attr.type='double'/>
<key id='PowerLoss' for='node' attr.name='PowerLoss' attr.type='double'/>
<key id='BuswayLivePeakCurrent' for='node' attr.name='BuswayLivePeakCurrent' attr.type='double'/>
<key id='BuswayNeutralPeakCurrent' for='node' attr.name='BuswayNeutralPeakCurrent' attr.type='double'/>
<key id='IkMax' for='node' attr.name='IkMax' attr.type='double'/>
<key id='IkMin' for='node' attr.name='IkMin' attr.type='double'/>
<key id='3I0Min' for='node' attr.name='3I0Min' attr.type='double'/>
<key id='3I0Max' for='node' attr.name='3I0Max' attr.type='double'/>
<key id='3I0To' for='node' attr.name='3I0To' attr.type='double'/>
<key id='Ce' for='node' attr.name='Ce' attr.type='double'/>
<key id='TypeFilter' for='node' attr.name='TypeFilter' attr.type='string'/>
<key id='FamilyFilter' for='node' attr.name='FamilyFilter' attr.type='string'/>
<key id='PolesFilter' for='node' attr.name='PolesFilter' attr.type='string'/>
<key id='VersionFilter' for='node' attr.name='VersionFilter' attr.type='string'/>
<key id='ReleaseFilter' for='node' attr.name='ReleaseFilter' attr.type='string'/>
<key id='RcdTypeFilter' for='node' attr.name='RcdTypeFilter' attr.type='string'/>
<key id='RcdVersionFilter' for='node' attr.name='RcdVersionFilter' attr.type='string'/>
<key id='IdnFilter' for='node' attr.name='IdnFilter' attr.type='double'/>
<key id='UimpRequested' for='node' attr.name='UimpRequested' attr.type='double'/>
<key id='InOverIBMinfactor' for='node' attr.name='InOverIBMinfactor' attr.type='double'/>
<key id='ServiceOrUltimateBreakingCapacity' for='node' attr.name='ServiceOrUltimateBreakingCapacity' attr.type='string'/>
<key id='IsParallel' for='node' attr.name='IsParallel' attr.type='boolean'/>
<key id='FuseLinkFilter' for='node' attr.name='FuseLinkFilter' attr.type='string'/>
<key id='FuseSizeFilter' for='node' attr.name='FuseSizeFilter' attr.type='string'/>
<key id='ContactorService' for='node' attr.name='ContactorService' attr.type='string'/>
<key id='OperationsLifeSpan' for='node' attr.name='OperationsLifeSpan' attr.type='int'/>
<key id='OperationFrequency' for='node' attr.name='OperationFrequency' attr.type='int'/>
<key id='IcwFilter' for='node' attr.name='IcwFilter' attr.type='double'/>
<key id='StepFilter' for='node' attr.name='StepFilter' attr.type='double'/>
<key id='MvDisconnectorSubType' for='node' attr.name='MvDisconnectorSubType' attr.type='string'/>
<key id='MvSwitchBoardName' for='node' attr.name='MvSwitchBoardName' attr.type='string'/>
<key id='In' for='node' attr.name='In' attr.type='double'/>
<key id='Icu' for='node' attr.name='Icu' attr.type='double'/>
<key id='Ics' for='node' attr.name='Ics' attr.type='double'/>
<key id='Icm' for='node' attr.name='Icm' attr.type='double'/>
<key id='Icw' for='node' attr.name='Icw' attr.type='double'/>
<key id='Installation' for='node' attr.name='Installation' attr.type='string'/>
<key id='TripUnitType' for='node' attr.name='TripUnitType' attr.type='string'/>
<key id='Terminals' for='node' attr.name='Terminals' attr.type='string'/>
<key id='Uimp' for='node' attr.name='Uimp' attr.type='double'/>
<key id='RcbModelName' for='node' attr.name='RcbModelName' attr.type='string'/>
<key id='TripTimeFinalCircuits' for='node' attr.name='TripTimeFinalCircuits' attr.type='double'/>
<key id='TripTimeOtherCircuits' for='node' attr.name='TripTimeOtherCircuits' attr.type='double'/>
<key id='Id' for='node' attr.name='Id' attr.type='double'/>
<key id='Td' for='node' attr.name='Td' attr.type='double'/>
<key id='Ith' for='node' attr.name='Ith' attr.type='double'/>
<key id='Im' for='node' attr.name='Im' attr.type='double'/>
<key id='IdMin' for='node' attr.name='IdMin' attr.type='double'/>
<key id='TdMin' for='node' attr.name='TdMin' attr.type='double'/>
<key id='Iu' for='node' attr.name='Iu' attr.type='double'/>
<key id='FuseLink' for='node' attr.name='FuseLink' attr.type='string'/>
<key id='FuseSize' for='node' attr.name='FuseSize' attr.type='string'/>
<key id='FuseHolderModelName' for='node' attr.name='FuseHolderModelName' attr.type='string'/>
<key id='ContactorIe' for='node' attr.name='ContactorIe' attr.type='double'/>
<key id='StarContactorIe' for='node' attr.name='StarContactorIe' attr.type='double'/>
<key id='CurrentTransfomerPrimaryIn' for='node' attr.name='CurrentTransfomerPrimaryIn' attr.type='double'/>
<key id='HomopolarTransformerPrimaryIn' for='node' attr.name='HomopolarTransformerPrimaryIn' attr.type='double'/>
<key id='CurrentTransfomerSecondaryIn' for='node' attr.name='CurrentTransfomerSecondaryIn' attr.type='double'/>
<key id='HomopolarTransformerSecondaryIn' for='node' attr.name='HomopolarTransformerSecondaryIn' attr.type='double'/>
<key id='CurrentTransformerModelName' for='node' attr.name='CurrentTransformerModelName' attr.type='string'/>
<key id='HomopolarTransformerModelName' for='node' attr.name='HomopolarTransformerModelName' attr.type='string'/>
<key id='TripUnitModelName' for='node' attr.name='TripUnitModelName' attr.type='string'/>
<key id='VoltageTransformerModelName' for='node' attr.name='VoltageTransformerModelName' attr.type='string'/>
<key id='TrafoEncloseModelName' for='node' attr.name='TrafoEncloseModelName' attr.type='string'/>
<key id='MVAccessoriesPresent' for='node' attr.name='MVAccessoriesPresent' attr.type='string'/>
<key id='IsMVAccessoriesPlaceHolder' for='node' attr.name='IsMVAccessoriesPlaceHolder' attr.type='boolean'/>
<key id='TypicalUnitId' for='node' attr.name='TypicalUnitId' attr.type='string'/>
<key id='TypicalUnit' for='node' attr.name='TypicalUnit' attr.type='string'/>
<key id='TypicalUnitName' for='node' attr.name='TypicalUnitName' attr.type='string'/>
<key id='TypicalUnitSign' for='node' attr.name='TypicalUnitSign' attr.type='string'/>
<key id='PanelNumber' for='node' attr.name='PanelNumber' attr.type='string'/>
<key id='DuplicateOf' for='node' attr.name='DuplicateOf' attr.type='string'/>
<key id='MvMacroPartMark' for='node' attr.name='MvMacroPartMark' attr.type='string'/>
<key id='FuseModelName' for='node' attr.name='FuseModelName' attr.type='string'/>
<key id='SwitchBoardFamily' for='node' attr.name='SwitchBoardFamily' attr.type='string'/>
<key id='Pn' for='node' attr.name='Pn' attr.type='double'/>
<key id='NominalPower' for='node' attr.name='NominalPower' attr.type='double'/>
<key id='Uf' for='node' attr.name='Uf' attr.type='double'/>
<key id='VoltageDropMaX' for='node' attr.name='VoltageDropMaX' attr.type='double'/>
<key id='MotorFeederStarterTypeFilter' for='node' attr.name='MotorFeederStarterTypeFilter' attr.type='string'/>
<key id='MotorFeederCoordinationTypeFilter' for='node' attr.name='MotorFeederCoordinationTypeFilter' attr.type='string'/>
<key id='MotorFeederProtectionFilter' for='node' attr.name='MotorFeederProtectionFilter' attr.type='string'/>
<key id='MotorFeederOlrFilter' for='node' attr.name='MotorFeederOlrFilter' attr.type='string'/>
<key id='MotorFeederContactorFilter' for='node' attr.name='MotorFeederContactorFilter' attr.type='string'/>
<key id='StarterType' for='node' attr.name='StarterType' attr.type='string'/>
<key id='StarterVariant' for='node' attr.name='StarterVariant' attr.type='string'/>
<key id='CoordinationType' for='node' attr.name='CoordinationType' attr.type='string'/>
<key id='MotorFeederType' for='node' attr.name='MotorFeederType' attr.type='string'/>
<key id='CosPhiForced' for='node' attr.name='CosPhiForced' attr.type='double'/>
<key id='Eta' for='node' attr.name='Eta' attr.type='double'/>
<key id='IlrInRatio' for='node' attr.name='IlrInRatio' attr.type='double'/>
<key id='MotorPhases' for='node' attr.name='MotorPhases' attr.type='int'/>
<key id='PolesNumber' for='node' attr.name='PolesNumber' attr.type='int'/>
<key id='CosPhiStart' for='node' attr.name='CosPhiStart' attr.type='double'/>
<key id='IStart' for='node' attr.name='IStart' attr.type='double'/>
<key id='tStart' for='node' attr.name='tStart' attr.type='double'/>
<key id='CmCnRatio' for='node' attr.name='CmCnRatio' attr.type='double'/>
<key id='CstartCnRatio' for='node' attr.name='CstartCnRatio' attr.type='double'/>
<key id='Rr' for='node' attr.name='Rr' attr.type='double'/>
<key id='Xr' for='node' attr.name='Xr' attr.type='double'/>
<key id='Rs' for='node' attr.name='Rs' attr.type='double'/>
<key id='Xs' for='node' attr.name='Xs' attr.type='double'/>
<key id='I0InRatio' for='node' attr.name='I0InRatio' attr.type='double'/>
<key id='ISecondK' for='node' attr.name='ISecondK' attr.type='double'/>
<key id='CosPhiSc' for='node' attr.name='CosPhiSc' attr.type='double'/>
<key id='ISecondKLN' for='node' attr.name='ISecondKLN' attr.type='double'/>
<key id='CosPhiScLN' for='node' attr.name='CosPhiScLN' attr.type='double'/>
<key id='ISecondKLE' for='node' attr.name='ISecondKLE' attr.type='double'/>
<key id='CosPhiScLE' for='node' attr.name='CosPhiScLE' attr.type='double'/>
<key id='Sk' for='node' attr.name='Sk' attr.type='double'/>
<key id='SkPE' for='node' attr.name='SkPE' attr.type='double'/>
<key id='SkN' for='node' attr.name='SkN' attr.type='double'/>
<key id='GensetType' for='node' attr.name='GensetType' attr.type='string'/>
<key id='Ra' for='node' attr.name='Ra' attr.type='double'/>
<key id='XdSecond' for='node' attr.name='XdSecond' attr.type='double'/>
<key id='XqSecond' for='node' attr.name='XqSecond' attr.type='double'/>
<key id='XdFirst' for='node' attr.name='XdFirst' attr.type='double'/>
<key id='Xd' for='node' attr.name='Xd' attr.type='double'/>
<key id='XSecond' for='node' attr.name='XSecond' attr.type='double'/>
<key id='X0' for='node' attr.name='X0' attr.type='double'/>
<key id='TdSecond' for='node' attr.name='TdSecond' attr.type='double'/>
<key id='TdFirst' for='node' attr.name='TdFirst' attr.type='double'/>
<key id='StarCenterAvailable' for='node' attr.name='StarCenterAvailable' attr.type='boolean'/>
<key id='IsPq' for='node' attr.name='IsPq' attr.type='boolean'/>
<key id='IScInRatio' for='node' attr.name='IScInRatio' attr.type='double'/>
<key id='UpsNoByPass' for='node' attr.name='UpsNoByPass' attr.type='boolean'/>
<key id='NormalizedR' for='node' attr.name='NormalizedR' attr.type='double'/>
<key id='NormalizedX' for='node' attr.name='NormalizedX' attr.type='double'/>
<key id='NormalizedZ' for='node' attr.name='NormalizedZ' attr.type='double'/>
<key id='Sr' for='node' attr.name='Sr' attr.type='double'/>
<key id='uRr' for='node' attr.name='uRr' attr.type='double'/>
<key id='ukr' for='node' attr.name='ukr' attr.type='double'/>
<key id='PrimaryWindingUn' for='node' attr.name='PrimaryWindingUn' attr.type='double'/>
<key id='SecondaryWindingUn' for='node' attr.name='SecondaryWindingUn' attr.type='double'/>
<key id='PrimaryWindingLineType' for='node' attr.name='PrimaryWindingLineType' attr.type='string'/>
<key id='SecondaryWindingLineType' for='node' attr.name='SecondaryWindingLineType' attr.type='string'/>
<key id='PrimaryWindingEarthingSystem' for='node' attr.name='PrimaryWindingEarthingSystem' attr.type='string'/>
<key id='SecondaryWindingEarthingSystem' for='node' attr.name='SecondaryWindingEarthingSystem' attr.type='string'/>
<key id='PrimaryWindingCouplingType' for='node' attr.name='PrimaryWindingCouplingType' attr.type='string'/>
<key id='SecondaryWindingCouplingType' for='node' attr.name='SecondaryWindingCouplingType' attr.type='string'/>
<key id='VoltageRegulatorPlug' for='node' attr.name='VoltageRegulatorPlug' attr.type='double'/>
<key id='TransformerSecondaryWindingNoLoadU' for='node' attr.name='TransformerSecondaryWindingNoLoadU' attr.type='double'/>
<key id='NormalizedZkt' for='node' attr.name='NormalizedZkt' attr.type='double'/>
<key id='kt' for='node' attr.name='kt' attr.type='double'/>
<key id='xt' for='node' attr.name='xt' attr.type='double'/>
<key id='TrafoR' for='node' attr.name='TrafoR' attr.type='double'/>
<key id='TrafoX' for='node' attr.name='TrafoX' attr.type='double'/>
<key id='TrafoZ' for='node' attr.name='TrafoZ' attr.type='double'/>
<key id='TrafoZkt' for='node' attr.name='TrafoZkt' attr.type='double'/>
<key id='DemandFactor' for='node' attr.name='DemandFactor' attr.type='double'/>
<key id='LinkName' for='node' attr.name='LinkName' attr.type='string'/>
<key id='SwitchBoardTemperature' for='node' attr.name='SwitchBoardTemperature' attr.type='double'/>
<key id='McbStandard' for='node' attr.name='McbStandard' attr.type='string'/>
<key id='SegregationForm' for='node' attr.name='SegregationForm' attr.type='string'/>
<key id='TerminalType' for='node' attr.name='TerminalType' attr.type='string'/>
<key id='UsageClass' for='node' attr.name='UsageClass' attr.type='string'/>
<key id='UimpL3' for='node' attr.name='UimpL3' attr.type='double'/>
<key id='UimpLNeutral' for='node' attr.name='UimpLNeutral' attr.type='double'/>
<key id='EnvironmentTemperature' for='graph' attr.name='EnvironmentTemperature' attr.type='double'/>
<key id='SwitchBoardInsideTemperature' for='graph' attr.name='SwitchBoardInsideTemperature' attr.type='double'/>
<key id='PlantFile' for='graph' attr.name='PlantFile' attr.type='string'/>
<key id='PlantDesigner' for='graph' attr.name='PlantDesigner' attr.type='string'/>
<key id='PlantBoard' for='graph' attr.name='PlantBoard' attr.type='string'/>
<key id='PlantProject' for='graph' attr.name='PlantProject' attr.type='string'/>
<key id='PlantRev1' for='graph' attr.name='PlantRev1' attr.type='string'/>
<key id='PlantRev2' for='graph' attr.name='PlantRev2' attr.type='string'/>
<key id='PlantRev3' for='graph' attr.name='PlantRev3' attr.type='string'/>
<key id='PlantSerial' for='graph' attr.name='PlantSerial' attr.type='string'/>
<key id='PlantApproval' for='graph' attr.name='PlantApproval' attr.type='string'/>
<key id='PlantDrawingNumber' for='graph' attr.name='PlantDrawingNumber' attr.type='string'/>
<key id='PlantMainCode' for='graph' attr.name='PlantMainCode' attr.type='string'/>
<key id='PlantAddress' for='graph' attr.name='PlantAddress' attr.type='string'/>
<key id='PlantCompany' for='graph' attr.name='PlantCompany' attr.type='string'/>
<key id='Customer' for='graph' attr.name='Customer' attr.type='string'/>
<key id='PlantDate' for='graph' attr.name='PlantDate' attr.type='string'/>
<key id='PlantDrafter' for='graph' attr.name='PlantDrafter' attr.type='string'/>
<key id='PlantNote1' for='graph' attr.name='PlantNote1' attr.type='string'/>
<key id='PlantNote2' for='graph' attr.name='PlantNote2' attr.type='string'/>
<key id='PlantNote3' for='graph' attr.name='PlantNote3' attr.type='string'/>
<key id='name' for='graph' attr.name='name' attr.type='string'/>
<key id='description' for='graph' attr.name='description' attr.type='string'/>
<key id='PlantRevisionNumber' for='graph' attr.name='PlantRevisionNumber' attr.type='string'/>
<key id='CurrentStamp' for='graph' attr.name='CurrentStamp' attr.type='string'/>
<key id='PlantEarthingSystem' for='graph' attr.name='PlantEarthingSystem' attr.type='string'/>
<key id='PlantFrequency' for='graph' attr.name='PlantFrequency' attr.type='string'/>
<key id='PlantLineType' for='graph' attr.name='PlantLineType' attr.type='string'/>
<key id='PlantGroundResistance' for='graph' attr.name='PlantGroundResistance' attr.type='double'/>
<key id='PlantLvUn' for='graph' attr.name='PlantLvUn' attr.type='double'/>
<key id='PlantMvUn' for='graph' attr.name='PlantMvUn' attr.type='double'/>
<key id='LiveAutoBalance' for='graph' attr.name='LiveAutoBalance' attr.type='boolean'/>
<key id='ComputeAllScenarios' for='graph' attr.name='ComputeAllScenarios' attr.type='string'/>
<key id='PreserveConstantCurrrentLoads' for='graph' attr.name='PreserveConstantCurrrentLoads' attr.type='boolean'/>
<key id='UseRatedCurrentToSizeBranches' for='graph' attr.name='UseRatedCurrentToSizeBranches' attr.type='boolean'/>
<key id='NeutralOptimization' for='graph' attr.name='NeutralOptimization' attr.type='boolean'/>
<key id='CableStandard' for='graph' attr.name='CableStandard' attr.type='string'/>
<key id='UseOnlySomeSections' for='graph' attr.name='UseOnlySomeSections' attr.type='boolean'/>
<key id='ShortCircuitStandard' for='graph' attr.name='ShortCircuitStandard' attr.type='string'/>
<key id='ShortCircuitTimes' for='graph' attr.name='ShortCircuitTimes'/>
<key id='PowerSupplyVoltageTolerance' for='graph' attr.name='PowerSupplyVoltageTolerance' attr.type='string'/>
<key id='OverExciting' for='graph' attr.name='OverExciting' attr.type='string'/>
<key id='BreakerFamilyAccordingToIB' for='graph' attr.name='BreakerFamilyAccordingToIB' attr.type='boolean'/>
<key id='ChooseAcbThreshold' for='graph' attr.name='ChooseAcbThreshold' attr.type='double'/>
<key id='ChooseMcbThreshold' for='graph' attr.name='ChooseMcbThreshold' attr.type='double'/>
<key id='UseDerating' for='graph' attr.name='UseDerating' attr.type='boolean'/>
<key id='ChooseElectronic' for='graph' attr.name='ChooseElectronic' attr.type='boolean'/>
<key id='ChooseElectronicThreshold' for='graph' attr.name='ChooseElectronicThreshold' attr.type='double'/>
<key id='CbDefaultPolesForLLLN' for='graph' attr.name='CbDefaultPolesForLLLN' attr.type='string'/>
<key id='CbDefaultPolesForLN' for='graph' attr.name='CbDefaultPolesForLN' attr.type='string'/>
<key id='DisabledProductFamilies' for='graph' attr.name='DisabledProductFamilies'/>
<key id='DisabledProductVariants' for='graph' attr.name='DisabledProductVariants'/>
<key id='MinBreakingCapacityForMCCB' for='graph' attr.name='MinBreakingCapacityForMCCB' attr.type='double'/>
<key id='MinBreakingCapacityForMCB' for='graph' attr.name='MinBreakingCapacityForMCB' attr.type='double'/>
<key id='IndirectTouchTripTimes' for='graph' attr.name='IndirectTouchTripTimes'/>
<key id='CosPhiIk3' for='graph' attr.name='CosPhiIk3' attr.type='double'/>
<key id='Ik1LNOverIk3Ratio' for='graph' attr.name='Ik1LNOverIk3Ratio' attr.type='double'/>
<key id='Ik1LPEOverIk3Ratio' for='graph' attr.name='Ik1LPEOverIk3Ratio' attr.type='double'/>
<key id='TransformersNumber' for='graph' attr.name='TransformersNumber' attr.type='int'/>
<key id='TransformersSr' for='graph' attr.name='TransformersSr' attr.type='double'/>
<key id='TransformersUkr' for='graph' attr.name='TransformersUkr' attr.type='double'/>
<key id='CapacityToGround' for='graph' attr.name='CapacityToGround' attr.type='double'/>
<key id='ShortCircuitCurrentToGround' for='graph' attr.name='ShortCircuitCurrentToGround' attr.type='double'/>
<key id='PetersensCoilReactance' for='graph' attr.name='PetersensCoilReactance' attr.type='double'/>
<key id='PetersensCoilsPresent' for='graph' attr.name='PetersensCoilsPresent' attr.type='boolean'/>
<key id='PetersensCoilResistance' for='graph' attr.name='PetersensCoilResistance' attr.type='double'/>
<key id='PowerSourceType' for='graph' attr.name='PowerSourceType' attr.type='string'/>
<key id='MvAvailable' for='graph' attr.name='MvAvailable' attr.type='boolean'/>
<key id='BackUpRequired' for='graph' attr.name='BackUpRequired' attr.type='boolean'/>
<key id='DiscriminationRequired' for='graph' attr.name='DiscriminationRequired' attr.type='boolean'/>
<key id='LabelsVisibility' for='graph' attr.name='LabelsVisibility'/>
<key id='Pages' for='graph' attr.name='Pages'/>
<key id='Scenarios' for='graph' attr.name='Scenarios'/>
<key id='Symbols' for='graph' attr.name='Symbols'/>
<key id='PageFrames' for='graph' attr.name='PageFrames'/>
<key id='projectLogo' for='graph' attr.name='projectLogo' attr.type='string'/>
<key id='Console' for='graph' attr.name='Console'/>
<key id='UserSettings' for='graph' attr.name='UserSettings'/>
<key id='ProjectIsComputed' for='graph' attr.name='ProjectIsComputed' attr.type='boolean'/>
<key id='appVersion' for='graph' attr.name='appVersion' attr.type='string'/>
<key id='Note' for='node' attr.name='Note' attr.type='string'/>
<key id='AllowFreeTransform' for='node' attr.name='AllowFreeTransform' attr.type='boolean'/>
<key id='TextColor' for='node' attr.name='TextColor' attr.type='string'/>
<key id='BorderColor' for='node' attr.name='BorderColor' attr.type='string'/>
<key id='FillColor' for='node' attr.name='FillColor' attr.type='string'/>
<key id='Height' for='node' attr.name='Height' attr.type='double'/>
<key id='Width' for='node' attr.name='Width' attr.type='double'/>
<key id='svgContent' for='node' attr.name='svgContent' attr.type='string'/>
<key id='MvPanelUr' for='node' attr.name='MvPanelUr' attr.type='double'/>
<key id='MvPanelIr' for='node' attr.name='MvPanelIr' attr.type='double'/>
<key id='MvPanelIcw' for='node' attr.name='MvPanelIcw' attr.type='double'/>
<key id='MvPanelBreakingTechnology' for='node' attr.name='MvPanelBreakingTechnology' attr.type='string'/>
<key id='TransformerStandard' for='node' attr.name='TransformerStandard' attr.type='string'/>
<key id='MvBoardMain' for='node' attr.name='MvBoardMain' attr.type='string'/>
<key id='MvBoardLocation' for='node' attr.name='MvBoardLocation' attr.type='string'/>
<key id='MvBoardFlexibility' for='node' attr.name='MvBoardFlexibility' attr.type='string'/>
<key id='MvBoardEasyAccess' for='node' attr.name='MvBoardEasyAccess' attr.type='string'/>
<key id='MvBoardInTank' for='node' attr.name='MvBoardInTank' attr.type='string'/>
<key id='MvBoardWithdrawable' for='node' attr.name='MvBoardWithdrawable' attr.type='string'/>
<key id='MvBoardCables' for='node' attr.name='MvBoardCables' attr.type='string'/>
<key id='MvPanelSelectionBy' for='node' attr.name='MvPanelSelectionBy' attr.type='string'/>
<key id='MvPanelApplication' for='node' attr.name='MvPanelApplication' attr.type='string'/>
<key id='MvPanelShowAdvanced' for='node' attr.name='MvPanelShowAdvanced' attr.type='string'/>
<key id='MvPanelProtectionFunctions' for='node' attr.name='MvPanelProtectionFunctions'/>
<key id='MvPanelCommunicationProtocol' for='node' attr.name='MvPanelCommunicationProtocol' attr.type='string'/>
<key id='MvPanelIsCEI' for='node' attr.name='MvPanelIsCEI' attr.type='string'/>
<key id='MvPanelMainOnly' for='node' attr.name='MvPanelMainOnly' attr.type='string'/>
<key id='MvPanelSensorsType' for='node' attr.name='MvPanelSensorsType' attr.type='string'/>
<key id='MainStandard' for='node' attr.name='MainStandard' attr.type='string'/>
<key id='ul' for='node' attr.name='ul' attr.type='string'/>
<key id='macroId' for='node' attr.name='macroId' attr.type='string'/>
<key id='macroDescription' for='node' attr.name='macroDescription' attr.type='string'/>
<key id='FunctionalEndurance' for='node' attr.name='FunctionalEndurance' attr.type='string' />
<key id='ISecondKMin' for='node' attr.name='ISecondKMin' attr.type='double' />
<key id='SkMin' for='node' attr.name='SkMin' attr.type='double' />
<key id='ShortCircuitCurrentToGroundMin' for='node' attr.name='ShortCircuitCurrentToGroundMin' attr.type='double' />
<key id='NonStandardSCNDTProtection' for='node' attr.name='NonStandardSCNDTProtection' attr.type='string' />
<key id='SCProtectionRequested' for='node' attr.name='SCProtectionRequested' attr.type='boolean'/>
<key id='NDTProtectionRequested' for='node' attr.name='NDTProtectionRequested' attr.type='boolean'/>
<key id='InsulationTransformerZone' for='node' attr.name='InsulationTransformerZone' attr.type='boolean'/>
<key id='TrafoPowerIncrease' for='node' attr.name='TrafoPowerIncrease' attr.type='double'/>
<key id='PowerSourceTransformer' for='node' attr.name='PowerSourceTransformer' attr.type='int'/>
<key id='BatteryChargingCurrent' for='node' attr.name='BatteryChargingCurrent' attr.type='double'/>
<key id='groupId' for='node' attr.name='groupId' attr.type='string'/>
<key id='Color' for='node' attr.name='Color' attr.type='string'/>
<key id='LvSwitchboardData' for='node' attr.name='LvSwitchboardData'/>
`;
