import React, { FunctionComponent } from 'react';
import Label from 'components/label';
import Combo from 'components/curves/Combo';
import Switch from 'components/generic/Switch';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { IOneCombo } from 'types/curves/oneCombo';

const OneCombo: FunctionComponent<IOneCombo> = ({
  title,
  bEnabled,
  on,
  options,
  value,
  onValueChange,
  onOnChange,
  curvesRequestIsRunning,
  onFocus,
}): React.ReactElement => {
  return (
    <Grid className="curve-function">
      <GridRow className="curve-function-row">
        {bEnabled === undefined ? (
          <GridColumn col={6}>
            <Label text={title} />
          </GridColumn>
        ) : null}
        {bEnabled !== undefined ? (
          <GridColumn col={2}>
            <Switch
              disabled={!bEnabled || curvesRequestIsRunning}
              value={on}
              textAlign="right"
              onChange={(event) => onOnChange(event.value)}
              label={title}
              name={title}
            />
          </GridColumn>
        ) : null}
      </GridRow>
      <GridRow className="curve-function-row">
        <GridColumn col={8}>
          <Combo
            on={on}
            options={options}
            value={value}
            onChange={onValueChange}
            onFocus={onFocus}
            isString
            // translateFunc={translateFunc}
            curvesRequestIsRunning={curvesRequestIsRunning}
            title={title}
            width={8}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default OneCombo;
