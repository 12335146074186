import { createAction } from 'redux-actions';
import { MODALS } from 'types/constants.d';
import { Actions } from 'types/modal';

export const ModalActions = {
  changeTab: createAction<Actions['changeTab']['payload']>(MODALS.CHANGE_TAB),
  updateModalParams: createAction<Actions['updateParams']['payload']>(MODALS.UPDATE_PARAMS),
  showModal: createAction<Actions['showModal']['payload']>(MODALS.SHOW_MODAL),
  showModalDialog: createAction<Actions['showModalDialog']['payload']>(MODALS.SHOW_MODAL_DIALOG),
} as const;
