/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'store';
import { Input, InputProps } from '@abb/abb-common-ux-react';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';

import { DeviceActions } from 'store/devices/actions';

import { IDOCwebInput, IDOCwebInputProps } from 'types/components/inputContainer';
import { isUnitsType } from 'types/calculations/type-guards';
import { isPlainValue } from 'types/type-guards';
import { isDevice, isStringError } from 'types/devices/type-guards';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

import { Device } from 'types/devices/device';
import { UnitsType } from 'types/calculations';
import { valueErrorValidation } from 'actions/inputErrorHandler';

const DOCwebInput = (props: IDOCwebInput): React.ReactElement => {
  const {
    className,
    label,
    name,
    type,
    disabled,

    onChange,
    onFocus,
    onDoubleClick,
    onFocusOut,
    onKeyDown,

    step,
    min,
    max,
    value,
    displayValue,
    icon,

    convert,
    cut,

    data,
    error,

    id,
    isRequired,
    showIconInvalid,
    // labelLeft,
    row,

    // valueErrorValidation,
    // updateElementErrors,
    additionalValidationData,
    placeholder,
  } = props;

  const [state, setState] = useState<{
    value: string | null;
    error: string | Record<string, unknown> | null;
  }>({
    value: null,
    error: null,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // TODO: Put this type in a proper place;
  type InputErrorObj = Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  useEffect(() => {
    setState({ ...state, value: '' });
    return () => {
      if (name && $(`input[name='${name}']`).is(':focus') && onFocusOut) {
        // $(`input[name=${name}]`).blur((event) => onFocusOut({name: name, value: event.target.value, type: type}, id));
        $(`input[name='${name}']`).blur((event) => onInputFocusOut(name, (event.target as HTMLInputElement).value, type, id));
      }
    };
  }, []);

  const isNaN = (val: number | string, precision?: number, toPrecision = false): string | number => {
    if (Number.isNaN(val) || val === 'Infinity') {
      return val;
    }
    if (val && !toPrecision && !Number.isNaN(val) && val !== 'Infinity') {
      return (val as number).toFixed(precision);
    }
    if (val && toPrecision && !Number.isNaN(val) && val !== 'Infinity') {
      return (val as number).toPrecision(precision);
    }
    return 0;
  };

  const isError = () => {
    if (
      (error && Object.keys(error).length > 0) ||
      state.error ||
      (data && data.errors && Object.keys(data.errors as Record<string, unknown>).length > 0)
    ) {
      if (state.error) {
        return true;
      }
      if (error) {
        return currentError !== undefined;
      }
      return (data?.errors as InputErrorObj)[name] !== undefined;
    }
    return false;
  };

  const processInputValue = (): string | number | null => {
    let val = null;
    // if (cut === undefined) {
    //   return value ?? '';
    // }
    if (data !== undefined) {
      if (convert !== undefined) {
        // convert value to some units
        if (isPlainValue(data[name])) {
          val = convert(data[name] as string, name as UnitsType);
        }
        // if value is specified - convert it
        if (value !== undefined && isUnitsType(name)) {
          val = convert(value, name);
        }
      }
      if (cut !== undefined && isUnitsType(name)) {
        // cut value to some digits after dot, taken from object params
        if (val === null && isPlainValue(data[name])) {
          val = cut(name, data[name] as string);
        }
        // if value is specified - cut it
        if (value !== undefined) {
          val = cut(name, value);
        }
      }
      if (cut === undefined && convert === undefined) {
        if (isPlainValue(data[name]) && isUnitsType(name) && !$(`input[name=${name}]`).is(':focus')) {
          val = parseFloat(UnitsDataAdapter.cutUnits(name, data[name] as string) as string);
        } else {
          val = data[name];
        }
        if (value !== undefined && isUnitsType(name) && !$(`input[name=${name}]`).is(':focus')) {
          val = UnitsDataAdapter.cutUnits(name, value);
        } else if (value !== undefined) {
          val = data[name];
        }
      }
    } else {
      val = convert && isUnitsType(name) ? convert(value ?? '', name) : value;
      if (isUnitsType(name)) {
        val = Number.isNaN(parseFloat(UnitsDataAdapter.cutUnits(name, value ?? '') as string))
          ? value
          : parseFloat(UnitsDataAdapter.cutUnits(name, value ?? '') as string);
      }
    }
    if (val == null || typeof state.value === 'object') {
      val = '';
    }
    if (state.error) {
      val = state.value;
    }

    if (displayValue !== undefined) {
      // TODO
      return isNaN(displayValue[0] as string, displayValue[1] as number, displayValue[2] as boolean);
    }
    // if (isPlainValue(val)) {
    //   return val;
    // }
    return val as string;
  };

  // TODO: properly handle val. should accept string and create one more value which will become number;
  const onInputValueChange = (_name: string, val: string | number, inputType: IDOCwebInputProps['type']) => {
    const startingFloat = (val as string).indexOf('.') !== -1 && !Number.isNaN(parseFloat((val as string).split('.')[0]));
    val = !Number.isNaN(parseFloat(val as string)) && !startingFloat && type !== 'text' ? parseFloat(val as string) : val;
    // TODO: add below additionalValidationData once ready
    const err = dispatch(
      valueErrorValidation(_name, val, data as Device, additionalValidationData as Record<string, unknown>)
    ) as string;
    setState({
      value: val as string,
      error: err,
    });

    if (err && isDevice(data)) {
      if (typeof err === 'object') {
        Object.keys(err as InputErrorObj).forEach((propName) => {
          dispatch(
            DeviceActions.updateElementErrors({
              id: (id || data.json?.id) ?? '',
              name: propName,
              error: (err ?? {})[propName as keyof typeof err] as string,
            })
          );
        });
      } else {
        dispatch(DeviceActions.updateElementErrors({ id: (id || data.json?.id) ?? '', name: _name, error: err }));
      }
    }
    if (!err && onChange) {
      onChange({ name: _name, value: val as string, type: inputType, eventType: 'onChange' });
      if (data && isDevice(data) && data.errors && !isStringError(data.errors) && data.errors[_name] !== undefined) {
        dispatch(DeviceActions.updateElementErrors({ id: (id || data.json?.id) ?? '', name: _name, error: err }));
      }
    }
  };

  const onInputFocusOut = (_name: string, val: string | number, _type: IDOCwebInput['type'], customId?: string) => {
    val = parseFloat(val as string) && _type !== 'text' ? parseFloat(val as string) : val;
    if (state.error) {
      setState({
        value: data?.[_name] as string,
        error: null,
      });

      if (state.error && isDevice(data)) {
        if (typeof state.error === 'object') {
          Object.keys(state.error).forEach((propName) => {
            dispatch(DeviceActions.updateElementErrors({ id: (id || data.json?.id) ?? '', name: propName, error: undefined }));
          });
        } else {
          dispatch(DeviceActions.updateElementErrors({ id: (id || data.json?.id) ?? '', name: _name, error: undefined }));
        }
      }
    }
    if (onFocusOut && !state.error) {
      let focusId = '';
      if (id) {
        focusId = id;
      } else if (customId) {
        focusId = customId;
      } else if (data && data.json) {
        focusId = (data as Device).json?.id;
      }
      onFocusOut({ name: _name, value: val as string, type: _type, eventType: 'onFocusOut' }, focusId);
    }
  };

  let currentError = typeof state.error === 'object' && state.error !== null ? state.error[name] : state.error;
  const ss = processInputValue() ?? '';
  return (
    <Input
      dataType={type as InputProps['dataType']}
      value={ss.toString()}
      type="normal"
      inputAttributes={{
        name,
        onBlur: (event: React.FocusEvent<HTMLInputElement>) => onInputFocusOut(name, event.target.value, type),
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          onChange ? onInputValueChange(name, event.target.value, type) : undefined,
        onFocus: (event: React.FocusEvent<HTMLInputElement>) =>
          onFocus ? onFocus({ name, value: event.target.value, type }) : null,
        onDoubleClick: (event: React.ChangeEvent<HTMLInputElement>) =>
          onDoubleClick ? onDoubleClick({ name, value: event.target.value, type }) : null,
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => (onKeyDown ? onKeyDown(event) : null),
        min,
        max,
        step,
        id,
        autoComplete: 'off',
        className: !state.error
          ? 'object-properties-input-field ABB_CommonUX_Input__inputElement'
          : 'object-properties-error-message ABB_CommonUX_Input__inputElement',
      }}
      required={isRequired}
      label={row ? undefined : t(label ?? '')}
      disabled={disabled}
      validator={() => {
        if (isError()) {
          let e = '';
          if (error && typeof error === 'string') {
            e = t(error);
          } else if (data?.errors) {
            e = (data.errors as Record<string, string>)[name];
          } else {
            e = typeof state.error === 'string' ? state.error : '';
          }
          return {
            valid: false,
            text: e,
          };
        }
        return { valid: true };
      }}
      showValidationBarWhenInvalid
      showValidationIconWhenInvalid={!!showIconInvalid}
      instantValidation
      icon={icon}
      className={className}
      placeholder={placeholder}
    />
  );
};

export default DOCwebInput;
