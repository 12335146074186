// import {
//   setProjectInfo,
// } from 'project/actions';
import { projectActions } from 'project/actions';
import { TDeviceEvent } from 'types/devices/device';
import { ThunkAction } from 'types/known-actions';

export const onChange =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch) => {
    const { value } = event;
    const { name } = event;
    dispatch(
      projectActions.setProjectInfo({
        info: {
          [name]: value,
        },
      })
    );
  };

export const onChangeDate =
  (event: TDeviceEvent): ThunkAction<void> =>
  (dispatch) => {
    dispatch(projectActions.setProjectInfo({ info: { [event.name]: event.value } }));
  };
