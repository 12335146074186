import React, { FunctionComponent } from 'react';
import { GridRow, GridColumn } from 'components/generic/grid';
import { ISelectGrid } from 'types/components/selectContainer';
import { useTranslation } from 'react-i18next';

const SelectGrid: FunctionComponent<ISelectGrid> = ({
  cols,
  divStyle,
  labelDivStyle,
  label,
  children,
  editableSelect,
  verticalAlignment,
}): React.ReactElement => {
  const { t } = useTranslation();
  const displayLabel = () => {
    return <span className={`DOCweb_CommonUX_Select__label ${labelDivStyle ?? ''}`}>{t(label ?? '')}</span>;
  };

  return (
    <GridRow verticalAlignment={verticalAlignment}>
      {cols && cols[1] > 0 ? <GridColumn col={cols[1]}>{displayLabel()}</GridColumn> : null}
      <GridColumn
        col={cols && cols[0] > 0 ? cols[0] : undefined}
        className={`${divStyle ?? ''} ${editableSelect ? 'select-editable' : ''}`}
      >
        {children}
      </GridColumn>
    </GridRow>
  );
};

export default SelectGrid;
