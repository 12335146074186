import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: '',
  objectType: EDeviceObjectType.LvMacro,
  deviceType: EDeviceType.FEEDER,
  Locked: false,

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
};
