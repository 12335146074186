import { EDeviceObjectType, EDeviceType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: '',
  objectType: EDeviceObjectType.TextArea,
  deviceType: EDeviceType.TEXTAREA,
  Note: '',
  PlaceHolderText: 'Add note in Properties',
  TextColor: '#000000',
  BorderColor: '#000000',
  FillColor: '#ffffff',
  Height: 10,
  Width: 55,
};
