/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { LOGO, PROJECT } from 'types/constants.d';
import { LogoKnownActions } from 'types/known-actions';
import * as LogoNamespace from 'types/logo';
// import * as ActionConstants from 'types/constants.d';

export const initialState: LogoNamespace.State = {
  projectLogo: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: LogoKnownActions): LogoNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGO.SET_LOGO:
        draft.projectLogo = action.payload.projectLogo;
        break;
      case PROJECT.LOAD_PROJECT:
        draft.projectLogo = action.payload.projectState.logo.projectLogo;
        break;
      default:
        return draft;
    }
  });
