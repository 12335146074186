import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';
import scCurrents from '../shortCircuitCurrents';

export default {
  ...defaultElement,
  ...scCurrents,
  symbol: 'FUSEBASE',
  objectType: EDeviceObjectType.LvFuseBase,
  deviceType: EDeviceType.FUSEBASE,
  Locked: false,

  // General info
  Name: '',
  Description: '',
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400.0,
  fuseSymbolId: -1,
  // Filters //////////////
  PreFiltersChanged: true,
  // Family
  FamilyFilter: '',
  familyFilterList: [],

  familyOutput: 'All possibilities',
  familyList: [],

  // Type
  TypeFilter: '',
  TypeFilterClient: '',
  typeFilterList: [],

  // PolesNumber
  PolesFilter: '3P',
  polesFilterList: ['3P'],
  polesOutput: '3P',
  polesList: [],

  // Ie
  ieOutput: 0,
  ieList: [],

  // Fuse size
  FuseSizeFilter: '',
  sizeFilterList: [],

  sizeOutput: 'All possibilities',
  sizeList: [],

  // Inc
  incOutput: 0,
  incFilter: 'All possibilities',
  incList: [],

  // Fuse link
  FuseLinkFilter: '',
  linksFilterList: [],

  linksOutput: 'All possibilities',
  linksList: [],

  // Relations
  Relations: [],
  protectionTypeList: [],
  defaultProtectionTypeList: ['Cables-Busways', 'Capacitor Banks', 'Pure residual current relays', 'Disconnector'],
  displayRelations: [],
  ProtectionAutoAssignOff: false,

  // Fuse In
  inOutput: 0,
  inList: [],

  // FamilyList: {},

  PolesTable: {
    LLLN: {
      TT: ['4P', '3P', '3P+N'],
      'TN-C': ['3P'],
      'TN-S': ['4P', '3P', '3P+N'],
      IT: ['4P', '3P', '3P+N'],
    },
    LLL: {
      TT: ['3P', '3P+N', '4P'],
      'TN-C': ['3P'],
      'TN-S': ['3P', '3P+N', '4P'],
      IT: ['3P', '3P+N', '4P'],
    },
    LLN: {
      TT: ['3P', '2P', '3P+N', '4P'],
      'TN-C': ['2P'],
      'TN-S': ['3P', '2P', '3P+N', '4P'],
      IT: ['3P', '2P', '3P+N', '4P'],
    },
    LL: {
      TT: ['2P', '3P', '3P+N', '4P'],
      'TN-C': ['2P'],
      'TN-S': ['2P', '3P', '3P+N', '4P'],
      IT: ['2P', '3P', '3P+N', '4P'],
    },
    LN: {
      TT: ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
      'TN-C': ['1P'],
      'TN-S': ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
      IT: ['2P', '1P+N', '1P', '3P', '3P+N', '4P'],
    },
  },
  DevicesList: [],
  DeviceId: -1,
  fuseLinksList: [],
  fuseLinksOutput: -1,
  ProductDescription: '',
  ProductIdList: null,
  FuseHolderModelName: '',
  Uimp: 2.5,
  // Values
  LoadCurrent: 0,
  Iz: 0,
  deviceDescriptionDisplay: [],
  deviceIcuDisplay: [],
  deviceIcsDisplay: [],
  deviceIcnDisplay: [],
  deviceRatedCurrentDisplay: [],
  deviceIcw1s: [],
  deviceStartIndex: 0,
  deviceEndIndex: 0,
  requestRunning: false,
  IsOffInScenario: {
    name: [],
  },
  initialized: false,
  CoordinationFilter: 'Not requested',
  CoordinationFilterList: ['Not requested'],
};
