/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */

import _ from 'lodash';
import { Device, PowerDevice } from 'types/devices/device';
import { EDeviceObjectType, EDeviceType } from 'types/devices/enums.d';
import { ThunkAction } from 'types/known-actions';
import { elementDefaultState } from 'types/devices/constants';
import { ApplicationState } from 'types/store';
import { DevicesState } from 'types/devices';
import { FEEDER_DEVICES, saferingTypes } from '../constants';

export const arraysEqual = (array1 = [], array2 = []) => {
  if (array1.length !== array2.length) return false;
  // eslint-disable-next-line no-plusplus
  for (let i = array1.length; i--; ) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
};

export const checkIfIe = () => {
  const userAgent = window.navigator.userAgent.indexOf('Trident/');
  if (userAgent > 0) {
    return true;
  }
  return false;
};

export const getFeederDevices = (feederId: string, devices: Record<string, Device>): Record<string, Device> => {
  return Object.keys(devices)
    .filter((id) => devices[id].feederId === feederId)
    .reduce(
      (children, id) => {
        // eslint-disable-next-line no-param-reassign
        children[id] = devices[id];
        return children;
      },
      {} as Record<string, Device>
    );
};

/**
 * Get's the correct id of the selected device
 * taking into account also that the device can
 * be inside a feeder
 * @param {*} deviceT  ype app type or array of app types
 */
export const getSelectedId =
  (deviceType?: EDeviceType): ThunkAction<string | undefined> =>
  (dispatch, getState): string | undefined => {
    const deviceTypes = typeof deviceType === 'string' ? [deviceType] : deviceType;
    const { devices, project } = getState();
    let { selectedDeviceId } = project;
    if (selectedDeviceId) {
      let feederId = null;
      if (!devices[selectedDeviceId]) {
        return undefined;
      }
      const selectedDeviceType = devices[selectedDeviceId].deviceType;
      const isTypical = devices[selectedDeviceId].objectType === EDeviceObjectType.MVTypicalUnit;
      if (isFeeder(selectedDeviceType) || isTypical) {
        if (Array.isArray(deviceTypes) && deviceTypes.length === 1 && selectedDeviceType === deviceTypes[0]) {
          return selectedDeviceId;
        }
        feederId = selectedDeviceId;
      } else if (devices[selectedDeviceId].feederId) {
        feederId = devices[selectedDeviceId].feederId;
      }
      if (feederId && deviceTypes) {
        // TODO: Add type guard instead of direct transformation!
        const children = getFeederDevices(feederId, devices as DevicesState<PowerDevice>);
        const id = Object.keys(children).find((childId) => deviceTypes.indexOf(devices[childId].deviceType) >= 0);
        if (!id) {
          if (console.warn) {
            console.warn(`getSelectedId can't find the id of ${deviceType as EDeviceType}`);
          }
        }
        return id;
      }
    }
    if (selectedDeviceId && devices[selectedDeviceId].base_device) {
      // TODO: I don't remember, but base_device is only for Typical Unit main device
      // If true - check if selected device is typical unit and then get base_device
      // type from there
      selectedDeviceId = devices[selectedDeviceId].base_device as string;
    }
    return selectedDeviceId;
  };

export const getDeviceIdFromMotorFeeder = (
  feederId: string,
  devices: Record<string, Device>,
  deviceType: EDeviceType
): string | undefined => {
  const children = getFeederDevices(feederId, devices);
  const id = Object.keys(children).find((childId) => devices[childId].deviceType === deviceType);
  return id;
};

export const getMotorIdFromMotorFeeder = (feederId: string, devices: Record<string, Device>): string | undefined =>
  getDeviceIdFromMotorFeeder(feederId, devices, EDeviceType.MOTOR);

export const getContactorIdFromMotorFeeder = (feederId: string, devices: Record<string, Device>): string | undefined =>
  getDeviceIdFromMotorFeeder(feederId, devices, EDeviceType.CONTACTOR);

export const isDeviceInsideMotorFeeder = (id: string, devices: ApplicationState['devices']): string | boolean | undefined => {
  const feederId = devices[id].feederId as string;
  return devices[id].feederId && devices[feederId].deviceType === EDeviceType.MOTOR_FEEDER;
};

// export const isFeeder = (deviceType) => {
//   return ['app.CB_FEEDER', 'app.MOTOR_FEEDER'].indexOf(deviceType) !== -1 || typicalUnitsNames.indexOf(deviceType) !== -1;
// };
export const isFeeder = (deviceType: EDeviceType): boolean => {
  return FEEDER_DEVICES.indexOf(deviceType) !== -1;
};

export const parseJSONProject = (
  project: string | { message: string; stack: string; err: string }
): boolean | ApplicationState => {
  if (typeof project !== 'string') {
    if (_.isEmpty(project) || (project.message && project.stack)) {
      console.error(project.err);
      console.error(project.stack);
    }
    return false;
  }
  const parsedProject = JSON.parse(project) as ApplicationState;
  return parsedProject;
};

export const getDeviceObjectId =
  (deviceId: string): ThunkAction<string | undefined> =>
  (dispatch, getState) => {
    const { devices } = getState();
    return devices[deviceId] ? devices[deviceId].ObjectId : undefined;
  };

export const uuid = () => {
  // credit: http://stackoverflow.com/posts/2117523/revisions
  // eslint-disable-next-line func-names
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// clear nulls after parsing project
export const clearNulls = (devices: Record<string, Device>): void => {
  Object.keys(devices).forEach((deviceId) => {
    Object.keys(devices[deviceId]).forEach((key) => {
      if (key === 'feederId' || key === 'targetPort') {
        return;
      }
      if (devices[deviceId][key] === null) {
        const { deviceType } = devices[deviceId];
        if (!deviceType) {
          console.warn(`${deviceId} has no deviceType`);
          return;
        }
        if (!elementDefaultState[deviceType]) {
          console.warn(`${deviceId} has device type not listed in elementDefaultState`);
          return;
        }
        if (elementDefaultState[deviceType][key]) {
          devices[deviceId][key] = elementDefaultState[deviceType][key];
          console.warn(`${deviceId}: ${key} has null value, set to default: ${elementDefaultState[deviceType][key] as string}`);
        } else {
          devices[deviceId][key] = undefined;
          console.warn(`${deviceId}: ${key} has null value, set to undefined`);
        }
      }
    });
  });
};

export const base64ToBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/png' });
};

export const arrayBufferToBase64 = (buffer: ArrayBufferLike) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const isSaferingTypicalUnit = (device: Record<string, unknown>): boolean => {
  if (saferingTypes.find((type) => device.TypicalUnit === type)) {
    return true;
  }
  return false;
};
