import { defaultElement } from '../default-element';
import { EDeviceObjectType, EDeviceType } from '../enums.d';

export default {
  ...defaultElement,
  symbol: 'HEATER_FEEDER',
  objectType: EDeviceObjectType.FEEDER,
  Locked: false,
  deviceType: EDeviceType.HEATER_FEEDER,

  protection: 'Fuse_switch_disconnector',
  contactor: 'CONTACTOR',
  cable: 'CABLE_LLLNPE',
  load: 'LOAD',
  initialized: false,
};
