import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';

import translationParameters from './translations/ParametrizedMsg';

type TTranslationParams = Array<string | number | boolean>;

export const locizeOptions = {
  projectId: 'dd606fcf-a9bd-4d80-abb0-31e196c591de',
  // apiKey: '', // YOU should not expose your apps API key to production!!!
  referenceLng: 'en',
};

const getFallbackLanguage = () => {
  let fallbackLanguage = 'en';
  const params = new window.URLSearchParams(window.location.search);
  const locize = params.get('locize');
  if (locize === 'true') {
    // fallbackLanguage = undefined;
    fallbackLanguage = '';
  }
  return fallbackLanguage;
};

export const initI18n = (): Promise<TFunction | void | undefined> =>
  i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(locizePlugin)
    .init({
      fallbackLng: getFallbackLanguage(),
      debug: false,
      saveMissing: true,

      keySeparator: false, // we do not use keys in form messages.welcome

      ns: ['translation', 'Frames'],
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: (parameters: { key: number; param: TTranslationParams } | TTranslationParams, value) => {
          // example of interpolation
          let retVal: string | undefined;
          const translationFunc = translationParameters[value ?? ''];
          if (translationFunc === undefined || !parameters) {
            return value ?? '';
          }
          if (!Array.isArray(parameters)) {
            retVal = translationFunc(parameters.param)?.[parameters.key];
          } else {
            retVal = translationFunc(parameters)?.[0];
          }
          return retVal ?? '';
        },
      },
      nsSeparator: '::',
      backend: locizeOptions,
      // editor: {
      //   ...locizeOptions,
      //   mode: 'window',
      //   // eslint-disable-next-line @typescript-eslint/no-misused-promises
      //   onEditorSaved: async (lng, ns) => {
      //     // reload that namespace in given language
      //     await i18n.reloadResources(lng, ns);
      //     // trigger an event on i18n which triggers a rerender
      //     // based on bindI18n below in react options
      //     i18n.emit('editorSaved');
      //   },
      // },
      react: {
        bindI18n: 'languageChanged editorSaved',
      },
    })
    .catch((err) => console.error(err));
i18n.languages = [];
i18n.init().catch(() => {});
export default i18n;
