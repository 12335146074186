/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelect } from 'types/components/selectContainer';
import { isPlainArrayList } from 'types/components/selectContainer/type-guard';

const Select: FunctionComponent<ISelect> = ({
  className,
  name,
  type,
  onChange,
  onFocus,
  disabled,
  data,
  selectList,
  valueList,
  value,
  useKeyAsValue,
  keyModifier,
  style,
  plainObjectModel,
  displayedAttribute,
  valueAttribute,
  borderless,
  namespace,
  dontTranslate,
}): React.ReactElement => {
  const { t } = useTranslation();
  // Render select with object as input  {0: {attr1: value, attr2: value}, 1:{attr1: value, attr2:value}}
  const renderObjectList = () => {
    if (!selectList) {
      // console.warn('No obj select list', name);
      return emptySelect();
    }

    if (isPlainArrayList(selectList)) {
      if (!plainObjectModel) console.warn('select items with plain object model are not objects', name, selectList);

      return emptySelect();
    }
    let selectValue = value;
    if (selectValue === undefined && data) selectValue = data[name] as string;
    const inputColor = selectList.find((item) => {
      return item.value === selectValue;
    })?.color as string;
    return (
      <div className={`${className ?? ''} DOCweb_CommonUX_Select__wrapper`}>
        <select
          className={`${borderless ? 'object-properties-input-field' : ''} DOCweb_CommonUX_Select__custom`}
          name={name}
          onFocus={(e) => {
            if (onFocus) {
              onFocus({
                name,
                value: e.target.value,
              });
            }
          }}
          onChange={(e) =>
            onChange({
              name,
              value: e.target.value,
              type: type ?? 'number',
              data: selectList.find((item) => {
                if (typeof item !== 'number') {
                  return item[valueAttribute ?? ''] === e.target.value;
                }
                return false;
              }),
            })
          }
          // TODO: Check if type is used somewhere
          // type={type === undefined ? 'number' : type}
          value={selectValue}
          disabled={disabled}
          style={{ color: inputColor }}
        >
          <option key="hiddenUnselectableOption" value="none" hidden aria-label="hiddenSelectOption" />
          {selectList.map((val, key) => (
            <option
              key={key}
              value={val[valueAttribute ?? ''] as string}
              style={{ color: val.color ? (val.color as string) : color(val[displayedAttribute ?? ''] as string) }}
            >
              {dontTranslate
                ? `${val[displayedAttribute ?? ''] as string}`
                : t(`${namespace ? `${namespace}::` : ''}${val[displayedAttribute ?? ''] as string}`)}
            </option>
          ))}
        </select>
      </div>
    );
  };
  const color = (attr: string): string => (style ? style[attr] : 'black');

  const emptySelect = () => {
    return (
      <div className={`${className ?? ''} DOCweb_CommonUX_Select__wrapper`}>
        <select
          className={`${borderless ? 'object-properties-input-field' : ''} DOCweb_CommonUX_Select__custom`}
          name={name}
          disabled
        >
          <option>&nbsp;</option>
        </select>
      </div>
    );
  };

  const renderArrayList = () => {
    if (!selectList) {
      // console.warn('No arr select list', name);
      return emptySelect();
    }

    if (!isPlainArrayList(selectList)) {
      if (plainObjectModel === true) console.warn('select items without plain object model are objects', name, selectList);
      return emptySelect();
    }
    const selectValue = data ? data[name] : undefined;
    return (
      <div className={`${className ?? ''} DOCweb_CommonUX_Select__wrapper`}>
        <select
          className={`${borderless ? 'object-properties-input-field' : ''} DOCweb_CommonUX_Select__custom`}
          name={name}
          onFocus={(e) => {
            if (onFocus) {
              onFocus({
                name,
                value: e.target.value,
              });
            }
          }}
          onChange={(e) =>
            onChange({
              name,
              value: e.target.value,
              type: type === undefined ? 'number' : type,
            })
          }
          // TODO: Check if type is used somewhere
          // type={type === undefined ? 'number' : type}
          value={value || value === '' ? value : (selectValue as string)}
          disabled={disabled}
        >
          <option key="hiddenUnselectableOption" value="none" hidden aria-label="hiddenUnselectableOption" />
          {(selectList as Array<string>).map((item, key) => {
            let val = item;
            if (valueList !== undefined && isPlainArrayList(valueList)) {
              val = valueList[key] as string;
            }

            if (useKeyAsValue === true) {
              val = `${key + (keyModifier ?? 0)}`;
            }

            return (
              <option key={selectList[key].toString()} value={val} style={{ color: color(item) }}>
                {dontTranslate ? `${item}` : t(`${namespace ? `${namespace}::` : ''}${item}`)}
              </option>
            );
          })}
        </select>
      </div>
    );
  };
  return plainObjectModel === true ? renderObjectList() : renderArrayList();
};

export default Select;
