import { getMvMacro } from 'devices/typicalUnits/actions';
import { getCableData } from 'devices/lvCable/actions';
import { setSelectedDevice } from 'project/actions';
import { selectCurrentDiagramForPrint } from 'ui/modals/printManager/actions';
import { removeCells } from 'devices/actions';
import { onFocusOutHandler as getCoordination } from 'ui/modals/coordination/actions';
import { ThunkAction } from 'types/known-actions';
import { ModalActions } from 'store/modal/actions';
import * as ModalNamespace from 'types/modal';
import { ApplicationState } from 'types/store';
import { EDeviceType } from 'types/devices/enums.d';
import { getBreakerFilters } from 'devices/circuitBreaker/actions';
import { getBreakerFilters as getMMsFilters } from 'devices/manualMotorStarter/actions';
import { getResidualCurrentFilters } from 'devices/residualCurrentCircuitBreaker/actions';
import { getFuseData } from 'devices/fuse/actions';
import { cloneDeep, isEqual } from 'lodash';
import { TRIGGER_STORE_PROPERTIES } from '../../constants';

export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_MODAL_DIALOG = 'SHOW_MODAL_DIALOG';
export const CHANGE_TAB = 'CHANGE_TAB';
export const UPDATE_MODAL_PARAMS = 'UPDATE_MODAL_PARAMS';

export const showModal =
  (name?: string, params: Record<string, unknown> | null = null): ThunkAction<void> =>
  (dispatch) => {
    // if (undoable) {
    //   dispatch(addPreviousUndoState());
    // }
    let modalName = '';
    if (name) {
      modalName = name;
    }
    dispatch(ModalActions.showModal({ name: modalName, params }));
  };

export const updateModalParams =
  (params: ModalNamespace.IUpdateParams['params']): ThunkAction<void> =>
  (dispatch) => {
    dispatch(ModalActions.updateModalParams({ params }));
  };

export const showModalDialog =
  (dialogParams: ModalNamespace.IShowModalDialog['dialogParams']): ThunkAction<void> =>
  (dispatch) => {
    dispatch(ModalActions.showModalDialog({ dialogParams }));
  };

const processTabChange =
  (name: string, fetchDeviceFilters: boolean): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.project.selectedDeviceId && fetchDeviceFilters) {
      const selectedDevice = state.devices[state.project.selectedDeviceId];
      if (!selectedDevice.requestRunning) {
        switch (selectedDevice.deviceType) {
          case EDeviceType.CB_TM:
            dispatch(getBreakerFilters(state.project.selectedDeviceId, 'BreakerFromCode'));
            break;
          case EDeviceType.MMS:
            dispatch(getMMsFilters(state.project.selectedDeviceId, 'BreakerFromCode'));
            break;
          case EDeviceType.RCCB:
            dispatch(getResidualCurrentFilters(state.project.selectedDeviceId, 'RcBreakerFromCode'));
            break;
          case EDeviceType.WIRELV:
            dispatch(getCableData(state.project.selectedDeviceId, 'Initial'))?.catch((error) => console.error(error));
            break;
          case EDeviceType.FUSEBASE:
            dispatch(getFuseData(state.project.selectedDeviceId, 'GetFuseFromCode'));
            break;
          case EDeviceType.CIRCUITBREAKERMV:
          case EDeviceType.FUSEMV:
            dispatch(getMvMacro(state.project.selectedDeviceId))?.catch((error) => console.error(error));
            break;
          default:
            console.error(`No filters for ${selectedDevice.deviceType}`);
            break;
        }
      }
    } else if (state.project.selectedDeviceId && !fetchDeviceFilters) {
      switch (name) {
        case 'Time':
        case 'Short-circuit':
          if (!state.devices[state.project.selectedDeviceId].coordsX) {
            dispatch(getCoordination({ name: 'coordinationXValue', type: 'number', value: '1' }));
          }
          break;
        case 'Current':
        case 'Energy':
        case 'Peak':
          if (!state.devices[state.project.selectedDeviceId].coordsY) {
            dispatch(getCoordination({ name: 'coordinationYValue', type: 'number', value: '1' }));
          }
          break;
        default:
          break;
      }
    }
  };

export const changeTab =
  (tabName: string, fetchDeviceFilters: boolean): ThunkAction<void> =>
  (dispatch) => {
    dispatch(ModalActions.changeTab({ tabName }));
    dispatch(processTabChange(tabName, fetchDeviceFilters));
  };

export const showWizard =
  (device?: Record<string, unknown>): ThunkAction<void> =>
  (dispatch) => {
    if (!device) {
      dispatch(setSelectedDevice(''));
    }
    dispatch(showModal('Wizard', { selectedDevice: cloneDeep(device) }));
    if (device) {
      dispatch(processTabChange('', true));
    }
  };

export const closeHandler =
  (modalName: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const selectedDevice = state.devices[state.project.selectedDeviceId || ''];
    const oldSelectedDevice =
      state.modal.params && state.modal.params.selectedDevice
        ? (state.modal.params.selectedDevice as Record<string, unknown>)
        : null;
    if (
      window.storeDeviceFunc &&
      oldSelectedDevice &&
      selectedDevice &&
      TRIGGER_STORE_PROPERTIES.some((prop) => !isEqual(oldSelectedDevice[prop], selectedDevice[prop]))
    ) {
      window.storeDeviceFunc();
      window.storeDeviceFunc = undefined;
    }
    if (modalName === 'Wizard' && state.wizard.wizardDeviceId) {
      dispatch(setSelectedDevice(''));
      dispatch(removeCells([state.wizard.wizardDeviceId]));
    }
    if (modalName === 'PrintManager') {
      dispatch(selectCurrentDiagramForPrint(true));
    }
    if (modalName === 'MailSupport' && window.sentryError) {
      setTimeout(() => window.location.reload(), 300);
    }
  };

export const modalIsOpened = (modal: ApplicationState['modal']) => {
  return modal.name !== undefined;
};

export const modalIsOpenedGlobal = (): ThunkAction<void> => (dispatch, getState) => modalIsOpened(getState().modal);
