export default {
  type: 'app.CURVE_DIAGRAM',
  ports: {
    items: [],
  },
  position: {
    x: 115,
    y: 5,
  },
  size: {
    width: 288.12,
    height: 205.79999999999998,
  },
  angle: 0,
  id: '4c823cce-53af-41ab-bbbf-21b0f3c13b83',
  markup: [
    {
      tagName: 'g',
      className: 'doc-device',
      selector: 'docCurves',
      children: [
        {
          selector: 'body',
          tagName: 'rect',
          className: 'bbox',
        },
        {
          selector: 'svg-curves',
          tagName: 'g',
        },
        {
          tagName: 'image',
          selector: 'image',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
      ],
    },
  ],
  z: 6,
  attrs: {
    image: {},
  },
};
