import React, { FunctionComponent } from 'react';
import CircuitBreakerSelectionTab from 'devices/circuitBreaker/modal/SelectionTab';
import FuseSelectionTab from 'devices/fuse/modal/SelectionTab';
// import OLRSelectionTab from 'devices/olrCircuitBreaker/modal/SelectionTab';
import MMSSelectionTab from 'devices/manualMotorStarter/modal/SelectionTab';
import RCCBSelectionTab from 'devices/residualCurrentCircuitBreaker/modal/SelectionTab';
import CableModalSelection from 'devices/lvCable/modal';
import SelectionModal from 'devices/typicalUnits/modal/selectionTab';
import { useSelector } from 'store';
import { EDeviceType } from 'types/devices/enums.d';
import { Device, PowerDevice } from 'types/devices/device';
import { ResidualCurrentCircuitBreaker } from 'types/devices/residualCurrentCircuitBreaker';
import { CircuitBreaker } from 'types/devices/circuitBreaker';
import { MMS } from 'types/devices/manualMotorStarter';
import { Fuse } from 'types/devices/fuse';

const PropertiesTab: FunctionComponent = (): React.ReactElement => {
  const project = useSelector((state) => state.project);
  const devices = useSelector((state) => state.devices);

  const deviceId = project.selectedDeviceId;
  if (deviceId) {
    const selectedDevice = devices[deviceId];
    if (selectedDevice && selectedDevice.deviceType === EDeviceType.CB_TM) {
      return (
        <CircuitBreakerSelectionTab
          selectedDeviceId={deviceId}
          selectedDevice={selectedDevice as Device<CircuitBreaker & PowerDevice>}
        />
      );
    }
    if (selectedDevice && selectedDevice.deviceType === EDeviceType.MMS) {
      return <MMSSelectionTab selectedDeviceId={deviceId} selectedDevice={selectedDevice as Device<MMS & PowerDevice>} />;
    }
    if (selectedDevice && selectedDevice.deviceType === EDeviceType.RCCB) {
      return (
        <RCCBSelectionTab selectedDeviceId={deviceId} selectedDevice={selectedDevice as Device<ResidualCurrentCircuitBreaker>} />
      );
    }
    if (selectedDevice && selectedDevice.deviceType === EDeviceType.FUSEBASE) {
      return <FuseSelectionTab selectedDeviceId={deviceId} selectedDevice={selectedDevice as Device<Fuse & PowerDevice>} />;
    }
    if (selectedDevice && selectedDevice.deviceType === EDeviceType.WIRELV) {
      return <CableModalSelection />;
    }
    if (
      selectedDevice &&
      (selectedDevice.deviceType === EDeviceType.CIRCUITBREAKERMV || selectedDevice.deviceType === EDeviceType.FUSEMV)
    ) {
      return <SelectionModal selectedDeviceId={deviceId} />;
    }
    return <></>;
  }
  return <></>;
};

export default PropertiesTab;
