export default {
  type: 'app.TEXTAREA',
  size: {
    width: 60,
    height: 50,
  },
  ports: {
    items: [
      {
        group: 'default',
        args: {
          x: 0,
          y: 0,
        },
        id: '31edd85d-10aa-4d1c-8e1d-08bfe20fb2ee',
      },
    ],
  },
  position: {
    x: 80,
    y: 110,
  },
  angle: 0,
  id: 'b007f96a-66b8-49aa-8269-1845a40032f0',
  z: 3,
  attrs: {},
};
