import React, { FunctionComponent } from 'react';

import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import { Table, TableHeader } from 'components/generic/table';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { IResidualCurrentCircuitBreaker } from 'types/devices/residualCurrentCircuitBreaker';
import { inputUpdateHandler } from '../actions';

// import {withTranslation} from 'react-i18next';
// import {isMobileOnly} from 'react-device-detect';

// class SelectionTab extends React.Component {
const SelectionTab: FunctionComponent<IResidualCurrentCircuitBreaker> = ({ selectedDevice }): React.ReactElement => {
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.variables);
  const { t } = useTranslation();
  if (!selectedDevice) {
    return <></>;
  }

  const filters = (): React.ReactElement[] => {
    const irList = [...selectedDevice.irOutputList];
    irList[0] = 'All possibilities';
    const idnList = [...selectedDevice.idnOutputList];
    idnList[0] = 'All possibilities';
    const obsoleteFamilies = variables.ObsoleteFamilies.name;
    const disabledFamilies = variables.DisabledProductFamilies.name;
    const { familyList } = selectedDevice;
    const familyColor: IResidualCurrentCircuitBreaker['familyColor'] = {};
    if (variables.ObsoleteFamilies && variables.DisabledProductFamilies) {
      disabledFamilies.forEach((family) => {
        familyColor[family] = 'grey';
      });
      obsoleteFamilies.forEach((family) => {
        familyColor[family] = '#CB6745';
      });
    }
    const sortedfamilyList: Array<string> = [];
    const ObsoleteFamilies: Array<string> = [];
    const DisabledProductFamilies: Array<string> = [];
    const familyFilterList: Array<string> = [];
    obsoleteFamilies.forEach((value) => (familyList.includes(value) ? ObsoleteFamilies.push(value) : null));
    disabledFamilies.forEach((value) =>
      familyList.includes(value) && !sortedfamilyList.includes(value) && !obsoleteFamilies.includes(value)
        ? DisabledProductFamilies.push(value)
        : null
    );
    familyList.forEach((value) =>
      !ObsoleteFamilies.includes(value) && !DisabledProductFamilies.includes(value) ? familyFilterList.push(value) : null
    );
    sortedfamilyList.push(...familyFilterList, ...DisabledProductFamilies, ...ObsoleteFamilies);
    return [
      <GridRow key={0}>
        <GridColumn col={8}>
          <h4>{t('DOCWEB_COMMON_MODAL_FILTERS')}</h4>
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={5}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="familyOutput"
            label="STRINGS_SUMTAB_FAMILY"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={sortedfamilyList}
            selectList={selectedDevice.familyList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
            style={familyColor}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={2}>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="PolesFilter"
            label="STRING_POLES"
            type="text"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.polesFilterList}
            selectList={selectedDevice.polesFilterList}
            data={selectedDevice}
            disabled
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="irOutput"
            label="STRING_IN"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.irOutputList}
            selectList={irList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="rcVersionOutput"
            label="STRINGS_IDC_SDI_ESECUTION_LABEL"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.rcVersionOutputList}
            selectList={selectedDevice.rcVersionOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="rcClassOutput"
            label="STRINGS_IDC_CLASS_TXT"
            type="string"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.rcClassOutputList}
            selectList={selectedDevice.rcClassOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="idnOutput"
            label="STRING_IDN"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.idnOutputList}
            selectList={idnList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={3}>
        <GridColumn col={2} offset={6} horizontalAlignment="center">
          <label>{selectedDevice.ProductDescription}</label>
        </GridColumn>
      </GridRow>,
      <GridRow key={4}>
        <GridColumn col={5}>
          <img className="circuit-breaker-img" src={`images/Foto/${selectedDevice.Picture}.bmp`} alt="" />
        </GridColumn>
      </GridRow>,
    ];
  };
  const rcdTable = (): React.ReactElement[] => {
    return [
      <GridRow key={0}>
        <GridColumn col={8}>
          <span>{t('DOCWEB_RCB_AVAIBLERCBTXT')}</span>
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={8}>
          <Table
            data={selectedDevice.DevicesList}
            selectedValue={selectedDevice.DeviceId}
            valueAttr="DeviceId"
            disabled={selectedDevice.requestRunning}
            onRowClick={(e) => dispatch(inputUpdateHandler(e))}
          >
            <TableHeader attr="Description" key={0}>
              {t('STRING_DESCRIPTION')}
            </TableHeader>
            <TableHeader attr="Ir" key={1}>
              {t('STRINGS_PROT_RATED_I')}
            </TableHeader>
            <TableHeader attr="Idn" key={2}>
              {t('STRING_IDN')}
            </TableHeader>
          </Table>
        </GridColumn>
      </GridRow>,
    ];
  };
  return (
    <Grid>
      {filters()}
      <hr />
      {rcdTable()}
    </Grid>
  );
};

export default SelectionTab;
