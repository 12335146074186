import { ITripUnit } from 'types/api/tripUnits';
import { IBackendResponse, IScheme } from 'types/compute';
import { IBreakerFilters } from 'types/deviceFilters';
import { Device } from 'types/devices/device';
import ApiCalls from './ApiCalls';

export default class GetCircuitBreaker {
  static async getCircuitBreakerFilters(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`BreakerFilter`, requestBody);
    return data;
  }

  static async store(requestBody: IScheme): Promise<IBackendResponse> {
    const { data } = await ApiCalls.post<IBackendResponse, IScheme>(`BreakerStore`, requestBody);
    return data;
  }

  static async wishes(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`BreakerMiss`, requestBody);
    return data;
  }

  static async getTripUnits(tripUnit: string): Promise<Array<ITripUnit>> {
    const { data } = await ApiCalls.get<Array<ITripUnit>>(`TripUnit/GetCircuitBreakerTripUnits/${tripUnit}`);
    return data;
  }

  static async getCircuitBreakerFilterGlobals(requestBody: IScheme): Promise<IBreakerFilters> {
    const { data } = await ApiCalls.post<IBreakerFilters, IScheme>(`BreakerFilterGlobals`, requestBody);
    return data;
  }

  static async getMMSFilterGlobals(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`MMSFilterGlobals`, requestBody);
    return data;
  }

  static async getOLRFilterGlobals(requestBody: IScheme): Promise<Partial<Device>> {
    const { data } = await ApiCalls.post<Partial<Device>, IScheme>(`OLRFilterGlobals`, requestBody);
    return data;
  }
}
