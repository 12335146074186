/* eslint-disable key-spacing */
import { ECellType, EDeviceObjectType, EDeviceType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  type: ECellType.BlockDevice,
  deviceType: EDeviceType.LV_SWITCHBOARD,
  symbol: '',
  SwitchBoardName: 'Switchboard0',
  objectType: EDeviceObjectType.SwitchBoard,
  SwitchBoardTemperature: '40',
  McbStandard: 'IEC90947-2',
  UsageClass: 'CATIII',
  SegregationForm: '1',
  TerminalType: 'All',
  ObjectId: 'SW',

  errors: {},
};
