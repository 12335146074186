/* eslint-disable @typescript-eslint/unbound-method */

import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { onFocusOutHandler, inputUpdateHandler } from '../actions';

interface ILvCableConfigurationTab {
  selectedDevice: Record<string, unknown>;
  selectedDeviceId: string;
}

const ConfigurationTab: React.FunctionComponent<ILvCableConfigurationTab> = ({
  selectedDevice,
  selectedDeviceId,
}): React.ReactElement => {
  const { t } = useTranslation();

  const isNotLLL = selectedDevice.LineType !== 'LLL';
  const isPEPresent = !!selectedDevice.PEPresent;

  const reductionFactorBody = (): React.ReactElement => {
    return (
      <>
        <GridRow key={'CableModalReductionFactorsGridRow2'}>
          <GridColumn col={2} key={'CableModalReductionFactorsGridRow3'}>
            <Input
              name="InstallationFactor"
              type="number"
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.InstallationFactor as string, 3]}
              data={selectedDevice}
              label={'INSTALL'}
              disabled={true}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsTEMP'}>
            <Input
              name="TemperatureFactor"
              type="number"
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.TemperatureFactor as string, 2]}
              label={'TEMP'}
              disabled={true}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsENV'}>
            <Input
              name="EnvoronmentalFactor"
              type="number"
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.EnvironmentFactor as string, 2]}
              label={'ENV'}
              disabled={true}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsADJ_CIRC'}>
            <Input
              name="LayoutFactor"
              type="number"
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.LayoutFactor as string, 2]}
              label={'ADJ_CIRC'}
              disabled={true}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsADJ_COND'}>
            <Input
              name="ConduitFactor"
              type="number"
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[selectedDevice.ConduitFactor as string, 2]}
              label={'ADJ_COND'}
              disabled={true}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsHARMONIC'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[1, 2]}
              label={'HARMONIC'}
              disabled={true}
              name={''}
              type={'string'}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsMISSED'}>
            <Input
              cols={[7, 0, 0, 0]}
              className={'info-project-input'}
              displayValue={[1, 2]}
              label={'MISSED'}
              disabled={true}
              name={''}
              type={'string'}
            />
          </GridColumn>
          <GridColumn col={2} key={'CableModalReductionFactorsUSER_F'}>
            <Input
              cols={[7, 0, 0, 0]}
              onChange={inputUpdateHandler}
              onFocusOut={onFocusOutHandler}
              id={selectedDeviceId}
              name={'CableCarryingCapacityUserFactor'}
              data={selectedDevice}
              className={'info-project-input'}
              type={'number'}
              label={'USER_F'}
            />
          </GridColumn>
          <GridColumn col={2} offset={6} key={'CableModalReductionFactorsTOTAL_F'}>
            <Input
              cols={[7, 0, 0, 0]}
              name={'CableCarryingCapacityUserFactor'}
              data={selectedDevice}
              className={'info-project-input'}
              displayValue={[selectedDevice.TotalReductionFactor as string, 2]}
              label={'TOTAL_F'}
              // additionalInputStringId={'KT'}
              disabled={true}
              type={'string'}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  const shortCircuitCurrents = (): React.ReactElement[] => {
    const MaxCurrentColIndex = [0, 1, 2, 3];
    const MinCurrentColIndex = [4, 5, 6, 7];
    const MaxCurrentLabel = 'DOCWEB_CABLE_MAXVALSATLINEBEG';
    const MinCurrentLabel = 'DOCWEB_CABLE_MINVALATEND';
    return [
      <GridRow key={'CableModalReductionFactorsshortCircuitCurrentsGridRow'}>
        <GridColumn col={8}>
          <h4>{t('STRINGS_IDC_SCCURRENTS_TXT')}</h4>
        </GridColumn>
      </GridRow>,
      <GridRow key={'CableModalReductionFactorsshortCircuitCurrentsGridRow1'}>
        <GridColumn col={1} key={'CableModalReductionFactorsIkMax'}>
          <Input
            cols={[7, 0, 0, 0]}
            className={'info-project-input'}
            name={'IkMax'}
            data={selectedDevice}
            label={'DOCCURVES_CABLE_REDUCTIONFACTORS_MAXIK'}
            disabled={true}
            cut={UnitsDataAdapter.cutUnits}
            convert={UnitsDataAdapter.convertMilliToBase}
            type={'string'}
          />
        </GridColumn>
        <GridColumn col={1} key={'CableModalReductionFactorsIkMin'}>
          <Input
            cols={[7, 0, 0, 0]}
            className={'info-project-input'}
            name={'IkMin'}
            data={selectedDevice}
            label={'DOCCURVES_CABLE_REDUCTIONFACTORS_MINIK'}
            disabled={true}
            cut={UnitsDataAdapter.cutUnits}
            convert={UnitsDataAdapter.convertMilliToBase}
            type={'string'}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={'CableModalReductionFactorsshortCircuitCurrentsGridRow2'}>
        {shortcircuitSection(MaxCurrentColIndex, MaxCurrentLabel)}
        {shortcircuitSection(MinCurrentColIndex, MinCurrentLabel)}
      </GridRow>,
    ];
  };

  const shortcircuitSection = (columnIndex: Array<number>, label: string): React.ReactElement[] => {
    const defaultLabels = ['LLL', 'LL', 'LN', 'L-PE'];
    return [
      <GridRow key={'CableModalReductionFactorsshortcircuitSectionGridRow'}>
        <GridColumn col={8}>
          <label style={{ fontSize: '14px' }}>{label}</label>
        </GridColumn>
      </GridRow>,
      shortcircuitSectionRow(defaultLabels[0], columnIndex[0]),
      shortcircuitSectionRow(defaultLabels[1], columnIndex[1]),
      isNotLLL ? (
        shortcircuitSectionRow(defaultLabels[2], columnIndex[2])
      ) : (
        <span key={'CableModalReductionFactorsisNotLLL'}></span>
      ),
      isPEPresent ? (
        shortcircuitSectionRow(defaultLabels[3], columnIndex[3])
      ) : (
        <span key={'CableModalReductionFactorsisPEPresent'}></span>
      ),
    ];
  };

  const shortcircuitSectionRow = (label: string, colIndex: number): React.ReactElement => {
    const EdgeSCCurrents = selectedDevice.EdgeSCCurrents as Array<Array<number>>;
    return (
      <GridRow key={'CableModalReductionFactorsshortcircuitSectionRowGridRow'}>
        <GridColumn col={1} verticalAlignment="middle">
          <label>{label}</label>
        </GridColumn>
        <GridColumn col={4}>
          <GridRow>
            <GridColumn col={2}>
              <Input
                cols={[8, 0, 0, 0]}
                type={'number'}
                className={'object-properties-input object-properties-input-border'}
                name={'scCurrentsValues'}
                // divStyle={'object-properties-input'}
                value={
                  EdgeSCCurrents && EdgeSCCurrents[0][colIndex] !== 0
                    ? (EdgeSCCurrents[0][colIndex] as unknown as string)
                    : undefined
                }
                disabled={true}
                convert={UnitsDataAdapter.convertMilliToBase}
              />
            </GridColumn>

            <GridColumn col={2}>
              <Input
                cols={[8, 0, 0, 0]}
                type={'number'}
                className={'object-properties-input object-properties-input-border'}
                name={'scCurrentsValues'}
                // divStyle={'object-properties-input'}
                value={
                  EdgeSCCurrents && EdgeSCCurrents[1][colIndex] !== 0
                    ? (EdgeSCCurrents[1][colIndex] as unknown as string)
                    : undefined
                }
                disabled={true}
                convert={UnitsDataAdapter.convertMilliToBase}
              />
            </GridColumn>
            <GridColumn col={2}>
              <Input
                cols={[8, 0, 0, 0]}
                type={'number'}
                className={'object-properties-input object-properties-input-border'}
                name={'scCurrentsValues'}
                // divStyle={'object-properties-input'}
                value={
                  EdgeSCCurrents && EdgeSCCurrents[2][colIndex] !== 0
                    ? (EdgeSCCurrents[2][colIndex] as unknown as string)
                    : undefined
                }
                disabled={true}
                convert={UnitsDataAdapter.convertMilliToBase}
              />
            </GridColumn>

            <GridColumn col={2}>
              <Input
                cols={[8, 0, 0, 0]}
                type={'number'}
                className={'object-properties-input object-properties-input-border'}
                name={'scCurrentsValues'}
                // divStyle={'object-properties-input'}
                value={
                  EdgeSCCurrents && EdgeSCCurrents[3][colIndex] !== 0
                    ? (EdgeSCCurrents[3][colIndex] as unknown as string)
                    : undefined
                }
                disabled={true}
                convert={UnitsDataAdapter.convertMilliToBase}
              />
            </GridColumn>
          </GridRow>
        </GridColumn>
        <GridColumn col={1} verticalAlignment="middle">
          <label>{t('STRINGS_IDC_KAMPER_TXT')}</label>
        </GridColumn>
      </GridRow>
    );
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn col={8}>
          <p className={'title'}>{t('STRINGS_WIREK_TITLE')}</p>
          {reductionFactorBody()}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default ConfigurationTab;
