/* eslint-disable react/display-name */
import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'store';
import { inputUpdateHandler } from 'devices/manualMotorStarter/actions';
import { useTranslation } from 'react-i18next';
import { IMMS } from 'types/devices/manualMotorStarter';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import { Table, TableHeader } from 'components/generic/table';

const SelectionTab: FunctionComponent<IMMS> = ({ selectedDevice }): React.ReactElement => {
  const dispatch = useDispatch();
  const variables = useSelector((state) => state.variables);
  const { t } = useTranslation();

  const filters = (): React.ReactElement[] => {
    const sizeList = [...selectedDevice.sizeOutputList];
    sizeList[0] = 'All possibilities';
    const icuList = [...selectedDevice.icuOutputList];
    icuList[0] = 'All possibilities';
    const inList = [...selectedDevice.inOutputList];
    inList[0] = 'All possibilities';
    const obsoleteFamilies = variables.ObsoleteFamilies.name;
    const disabledFamilies = variables.DisabledProductFamilies.name;
    const familyList = selectedDevice.familyOutputList;
    const familyColor: IMMS['familyColor'] = {};
    if (variables.ObsoleteFamilies && variables.DisabledProductFamilies) {
      disabledFamilies.forEach((family) => {
        familyColor[family] = 'grey';
      });
      obsoleteFamilies.forEach((family) => {
        familyColor[family] = '#CB6745';
      });
    }
    const sortedfamilyFilterList: Array<string> = [];
    const ObsoleteFamilies: Array<string> = [];
    const DisabledProductFamilies: Array<string> = [];
    const familyFilterList: Array<string> = [];
    obsoleteFamilies.forEach((value) => (familyList.includes(value) ? ObsoleteFamilies.push(value) : null));
    disabledFamilies.forEach((value) =>
      familyList.includes(value) && !sortedfamilyFilterList.includes(value) && !obsoleteFamilies.includes(value)
        ? DisabledProductFamilies.push(value)
        : null
    );
    familyList.forEach((value) =>
      !ObsoleteFamilies.includes(value) && !DisabledProductFamilies.includes(value) ? familyFilterList.push(value) : null
    );
    sortedfamilyFilterList.push(...familyFilterList, ...DisabledProductFamilies, ...ObsoleteFamilies);
    return [
      <GridRow key={0}>
        <GridColumn col={8}>
          <h4>{t('DOCWEB_COMMON_MODAL_FILTERS')}</h4>
        </GridColumn>
      </GridRow>,
      <GridRow key={1}>
        <GridColumn col={5}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="familyOutput"
            label="STRINGS_SUMTAB_FAMILY"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={sortedfamilyFilterList}
            selectList={sortedfamilyFilterList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
            style={familyColor}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={2}>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="polesOutput"
            label="STRING_POLES"
            type="text"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.polesOutputList}
            selectList={selectedDevice.polesOutputList}
            data={selectedDevice}
            disabled
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="versionOutput"
            label="STRINGS_IDC_SDI_ESECUTION_LABEL"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.versionOutputList}
            selectList={selectedDevice.versionOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="sizeOutput"
            label="STRING_SIZE"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.sizeOutputList}
            selectList={sizeList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[7, 8, 0]}
            className="info-project-input"
            name="icuOutput"
            label="STRINGS_FILTER_ICU_PRE"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.icuOutputList}
            selectList={icuList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
        <GridColumn col={1}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="inOutput"
            label="STRING_IN"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.inOutputList}
            selectList={inList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={3}>
        <GridColumn col={2}>
          <Select
            cols={[8, 8, 0]}
            className="info-project-input"
            name="terminalsOutput"
            label="DOCWEB_COMMON_MODAL_TERMINAL"
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            valueList={selectedDevice.terminalsOutputList}
            selectList={selectedDevice.terminalsOutputList}
            data={selectedDevice}
            disabled={selectedDevice.requestRunning}
            plainObjectModel
            displayedAttribute="STRING_DESCRIPTION"
            valueAttribute="Description"
            namespace="terminals"
          />
        </GridColumn>
      </GridRow>,
      <GridRow key={5}>
        <GridColumn col={2} offset={6} horizontalAlignment="center">
          <label>{t(selectedDevice.ProductDescription as string)}</label>
        </GridColumn>
      </GridRow>,
      <GridRow key={6}>
        <GridColumn col={5}>
          <img className="circuit-breaker-img" src={`images/Foto/${selectedDevice.Picture}.bmp`} alt="" />
        </GridColumn>
      </GridRow>,
    ];
  };
  const breakerTable = (): React.ReactElement => {
    return (
      <Grid>
        <GridRow>
          <GridColumn col={8}>
            <Table
              data={selectedDevice.DevicesList}
              selectedValue={selectedDevice.DeviceId}
              valueAttr="DeviceId"
              disabled={selectedDevice.requestRunning}
              onRowClick={(e) => dispatch(inputUpdateHandler(e))}
            >
              <TableHeader attr={'Description'} key={'ManualMotorStarterSelectionTabTableHeaderDescription'}>
                {t('STRING_DESCRIPTION')}
              </TableHeader>
              <TableHeader attr={'In'} key={'ManualMotorStarterSelectionTabTableHeaderIn'}>
                {t('STRING_IN')}
              </TableHeader>
              <TableHeader attr={'Ics'} key={'ManualMotorStarterSelectionTabTableHeaderIcs'}>
                {t('STRINGS_FILTER_ICS_PRE')}
              </TableHeader>
              <TableHeader attr={'Icu'} key={'ManualMotorStarterSelectionTabTableHeaderIcu'}>
                {t('STRINGS_FILTER_ICU_PRE')}
              </TableHeader>
              <TableHeader attr={'Icn'} key={'ManualMotorStarterSelectionTabTableHeaderIcn'}>
                {t('Icn')}
              </TableHeader>
              <TableHeader attr={'Icw1s'} key={'ManualMotorStarterSelectionTabTableHeaderIcw1s'}>
                {t('DOCWEB_CIRCUITBREAKER_ICW1S')}
              </TableHeader>
            </Table>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };
  return (
    <Grid>
      {filters()}
      <hr />
      {breakerTable()}
    </Grid>
  );
};

export default SelectionTab;
