import { Label } from 'types/labels/index.d';
import { TTypicalUnitWizardObject, TWizardObject } from './api/mvMacroConversion';
import { IScenarioItem } from './modal/scenarios/actions';

export const isPlainValue = (value: unknown | string | number): value is string | number => {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
};

export const isNumber = (value: unknown | number): value is number => {
  return !Number.isNaN(parseFloat(value as string));
};

export const isBoolean = (value: unknown | string | boolean): value is boolean => {
  return value === false || value === true;
};

export const isTypicalUnitMvMacroWizardObject = (value: TWizardObject): value is TTypicalUnitWizardObject => {
  return value.type === 'TypicalUnit';
};

export const isValueString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isLabelObject = (object: unknown): object is Label => {
  const { name, display } = object as Label;
  return typeof name === 'string' && typeof display === 'boolean';
};

export const isScenarioItem = (value: IScenarioItem | string): value is IScenarioItem => {
  return typeof value !== 'string';
};
