export enum EApiMethods {
  get = 'get',
  delete = 'delete',
}

export enum EApiDataMethods {
  post = 'post',
  put = 'put',
  patch = 'patch',
}
