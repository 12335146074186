import { IBackendResponse, IGetMvMacroGlobalResponse, IScheme } from 'types/compute';
import ApiCalls from './ApiCalls';

// export default class MvMacroAPI {
//   static getMacroFilters(requestBody) {
//     return ApiCalls.post(`GetMVMacro`, requestBody);
//   }
// }

export default class MvMacroAPI {
  static async getMacroFilters(requestBody: IScheme): Promise<IBackendResponse> {
    const { data } = await ApiCalls.post<Promise<IBackendResponse>, IScheme>(`GetMVMacro`, requestBody);
    return data;
  }

  // TODO: check if needed
  static async getMacroFiltersGlobal(requestBody: IScheme): Promise<IBackendResponse<IGetMvMacroGlobalResponse>> {
    const { data } = await ApiCalls.post<Promise<IBackendResponse<IGetMvMacroGlobalResponse>>, IScheme>(
      `GetMVMacroGlobals`,
      requestBody
    );
    return data;
  }
}
