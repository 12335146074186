/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react';
import Grid from 'components/generic/selectContainer/Grid';
import Select from 'components/generic/selectContainer/Select';
import Input from 'components/generic/inputContainer/Input';
import { IEditableSelect } from 'types/components/editableSelect';
import { ISelectGrid } from 'types/components/selectContainer';

const DOCwebEditableSelect: FunctionComponent<IEditableSelect & ISelectGrid> = (props): React.ReactElement => {
  return (
    <Grid {...props} editableSelect>
      <Select {...props} onChange={props.onFocusOut ? props.onFocusOut : props.onChange} />
      <Input {...props} label={undefined} className="editable-input" />
    </Grid>
  );
};
export default DOCwebEditableSelect;
