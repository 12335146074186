/* eslint-disable no-param-reassign */
import { isFeeder, uuid } from 'actions/utils';

import defaultLvCableState from 'types/devices/lvCable/initial-state';

import defaultMvCableState from 'types/devices/mvCable/initial-state';

import { defaultLvUtilityState, defaultLvUtilitySmallState } from 'types/devices/lvUtility/initial-state';

import impedanceDefaultState from 'types/devices/impedance/initial-state';

import loadDefaultState from 'types/devices/load/initial-state';

import circuitBreakerDefaultState from 'types/devices/circuitBreaker/initial-state';

import busbarDefaultState from 'types/devices/busbar/initial-state';

import arrivalDefaultState from 'types/devices/arrival/initial-state';

import departureDefaultState from 'types/devices/departure/initial-state';

import typicalFeederDefaultState from 'types/devices/typicalFeeder/initial-state';

import fuseDefaultState from 'types/devices/fuse/initial-state';

import contactorDefaultState from 'types/devices/contactor/initial-state';

import manualMotorStarterDefaultState from 'types/devices/manualMotorStarter/initial-state';

import motorDefaultState from 'types/devices/motor/initial-state';

import overloadRelayDefaultState from 'types/devices/overloadRelay/initial-state';

import transformerDefaultState from 'types/devices/trafo/initial-state';
import transformer3DefaultState from 'types/devices/trafo/initial-state-trafo3';

import { generatorDefaultState, defaultGeneratorSmallState } from 'types/devices/generator/initial-state';

import {
  defaultBioGeneratorState,
  defaultPHOTOGeneratorState,
  defaultWindGeneratorState,
} from 'types/devices/renewableGenerator/initial-state';

import { mvUtilityDefaultState, defaultMvUtilitySmallState } from 'types/devices/mvUtility/initial-state';

import lvDisconnectorDefaultState from 'types/devices/lvDisconnector/initial-state';

import atsDisconnectorDefaultState from 'types/devices/ats/initial-state';

import residualCurrentCircuitBreakerDefaultState from 'types/devices/residualCurrentCircuitBreaker/initial-state';

import softstarterDefaultState from 'types/devices/softstarter/initial-state';

import capacitorDefaultState from 'types/devices/capacitorBank/initial-state';

import buswaysDefaultState from 'types/devices/busways/initial-state';

import lvSwitchboardDefaultState from 'types/devices/lvSwitchboard/initial-state';
import mvSwitchboardDefaultState from 'types/devices/mvSwitchboard/initial-state';
import blockUtilityDefaultState from 'types/devices/blockUtility/initial-state';
import blockGeneratorDefaultState from 'types/devices/blockGenerator/initial-state';
import blockTrafoDefaultState from 'types/devices/blockTrafo/initial-state';
import lineDefaultState from 'types/devices/line/initial-state';
import textAreaDefaultState from 'types/devices/textArea/initial-state';

import DEFAULT_MV_CIRCUIT_BREAKER_STATE from 'types/devices/mvCircuitBreaker/initial-state';
import DEFAULT_MV_CIRCUIT_BREAKER_W_STATE from 'types/devices/mvCircuitBreakerW/initial-state';
import DEFAULT_MV_SWITCH_DISONNECTOR_STATE from 'types/devices/mvSwitchDisconnector/initial-state';
import DEFAULT_MV_EARTH_DISONNECTOR_STATE from 'types/devices/mvEarthDisconnector/initial-state';
import DEFAULT_MV_DOWN_DISONNECTOR_STATE from 'types/devices/mvDownDisconnector/initial-state';
import DEFAULT_MV_DISONNECTOR_STATE from 'types/devices/mvDisconnector/initial-state';
import DEFAULT_MV_FUSE_STATE from 'types/devices/mvFuse/initial-state';
import DEFAULT_CONNECTOON_STATE from 'types/devices/connectoon/initial-state';

import {
  upsNoByPassDefaultState,
  upsBypassDefaultState,
  upsDefaultState,
  ups2inputDefaultState,
} from 'types/devices/ups/initial-state';

import probeDefaultState from 'types/devices/probe/initial-state';

import blockDefaultJson from 'project/utils/blockJson';
import busbarJson from 'project/utils/busbarJson';

import lineDefaultJson from 'project/utils/lineJson';
import linkDefaultJson from 'project/utils/linkJson';
import textLinkJson from 'project/utils/textLinkJson';

import textAreaDefaultJson from 'project/utils/textAreaJson';

import curveDiagramDefaultState from 'types/devices/curveDiagram/initial-state';
import curveDiagramDefaultJson from 'project/utils/curveDiagramJson';

import symbolDefaultJson from 'project/utils/symbolDefaultJson';
import { Symbols as symbolPorts } from 'project/utils/symbols';

import { BUSBAR_PORT_STEP, BUSBAR_MIN_WIDTH, GRID_SIZE } from 'components/drawingArea/Constants';
import { ECellType, EDeviceObjectType, EDeviceType } from 'types/devices/enums.d';
import { Device, DeviceInitialState, IDefaultJson, IJsonPort, PlainPoint } from 'types/devices/device';
import _ from 'lodash';
import { calculateRatedPower } from 'calculations/motorCalculations';
import symbolPortsDefaults from './utils/symbolPortsDefaults';
import { LOAD_CHARGER_TYPES } from '../constants';
import { clearNulls } from './actions/utils';
import { getBlockDevicePorts } from './utils/blockDevicePorts';

export const genericStates = {
  [EDeviceType.UTILITY]: {
    defaultState: defaultLvUtilityState,
  },
  [EDeviceType.UTILITY_SMALL]: {
    defaultState: defaultLvUtilitySmallState,
  },
  [EDeviceType.IMPEDANCE]: {
    defaultState: impedanceDefaultState,
  },
  [EDeviceType.IMPEDANCE_CT]: {
    defaultState: impedanceDefaultState,
  },
  [EDeviceType.IMPEDANCE_HORZ_PHANTOM]: {
    defaultState: impedanceDefaultState,
  },
  [EDeviceType.AUX_IMP_HIGH_POWER_METER]: {
    defaultState: impedanceDefaultState,
  },
  [EDeviceType.IMPEDANCE_AUX_HIGH_POWER_METER]: {
    defaultState: impedanceDefaultState,
  },
  [EDeviceType.IMPEDANCE_PHANTOM]: {
    defaultState: impedanceDefaultState,
  },
  [EDeviceType.LOAD]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_CT]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_TIME_SWITCH]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_INDICATOR]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_TIME_ASTRO_SWITCH]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_DAYLIGHT_SWITCH]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_OVR]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.LOAD_PLUG]: {
    defaultState: loadDefaultState,
  },
  [EDeviceType.CB_TM]: {
    defaultState: circuitBreakerDefaultState,
  },
  [EDeviceType.WIRELV]: {
    defaultState: defaultLvCableState,
  },
  [EDeviceType.WIREMV]: {
    defaultState: defaultMvCableState,
  },
  [EDeviceType.ARRIVAL]: {
    defaultState: arrivalDefaultState,
  },
  [EDeviceType.DEPARTURE]: {
    defaultState: departureDefaultState,
  },
  [EDeviceType.TYPICAL_FEEDER]: {
    defaultState: typicalFeederDefaultState,
  },
  [EDeviceType.FUSEBASE]: {
    defaultState: fuseDefaultState,
  },
  [EDeviceType.CONTACTOR]: {
    defaultState: contactorDefaultState,
  },
  [EDeviceType.MMS]: {
    defaultState: manualMotorStarterDefaultState,
  },
  [EDeviceType.MOTOR]: {
    defaultState: motorDefaultState,
  },
  [EDeviceType.OLR]: {
    defaultState: overloadRelayDefaultState,
  },
  [EDeviceType.TRAFO2]: {
    defaultState: transformerDefaultState,
  },
  [EDeviceType.TRAFO3]: {
    defaultState: { ...transformerDefaultState, ...transformer3DefaultState },
  },
  [EDeviceType.GENERATOR]: {
    defaultState: generatorDefaultState,
  },
  [EDeviceType.GENERATOR_SMALL]: {
    defaultState: defaultGeneratorSmallState,
  },
  [EDeviceType.GENERATOR_BIO]: {
    defaultState: defaultBioGeneratorState,
  },
  [EDeviceType.GENERATOR_WIND]: {
    defaultState: defaultWindGeneratorState,
  },
  [EDeviceType.GENERATOR_PHOTO]: {
    defaultState: defaultPHOTOGeneratorState,
  },
  [EDeviceType.UTILITY_MV_SHORT]: {
    defaultState: mvUtilityDefaultState,
  },
  [EDeviceType.UTILITY_MV]: {
    defaultState: mvUtilityDefaultState,
  },
  [EDeviceType.UTILITY_MV_SMALL]: {
    defaultState: defaultMvUtilitySmallState,
  },
  [EDeviceType.DISCONNECTORLV]: {
    defaultState: lvDisconnectorDefaultState,
  },
  [EDeviceType.ATS]: {
    defaultState: atsDisconnectorDefaultState,
  },
  [EDeviceType.RCCB]: {
    defaultState: residualCurrentCircuitBreakerDefaultState,
  },
  [EDeviceType.SOFTSTARTER]: {
    defaultState: softstarterDefaultState,
  },
  [EDeviceType.BUSDUCT]: {
    defaultState: buswaysDefaultState,
  },
  [EDeviceType.CAPACITORBANKS]: {
    defaultState: capacitorDefaultState,
  },
  BLOCK: {
    defaultState: {},
    defaultJson: blockDefaultJson,
  },
  [EDeviceType.LINE]: {
    defaultState: lineDefaultState,
    defaultJson: lineDefaultJson,
  },
  [EDeviceType.TEXTAREA]: {
    defaultState: textAreaDefaultState,
    defaultJson: textAreaDefaultJson,
  },
  [EDeviceType.CURVE_DIAGRAM]: {
    defaultState: curveDiagramDefaultState,
    defaultJson: curveDiagramDefaultJson,
  },
  [EDeviceType.UPS]: {
    defaultState: upsDefaultState,
  },
  [EDeviceType.UPS_BYPASS]: {
    defaultState: upsBypassDefaultState,
  },
  [EDeviceType.UPS_NOBYPASS]: {
    defaultState: upsNoByPassDefaultState,
  },
  [EDeviceType.UPS_2_INPUT]: {
    defaultState: ups2inputDefaultState,
  },
  [EDeviceType.BUSBAR]: {
    defaultState: busbarDefaultState,
    defaultJson: busbarJson,
  },
  [EDeviceType.CIRCUITBREAKERMV]: {
    defaultState: DEFAULT_MV_CIRCUIT_BREAKER_STATE,
  },
  [EDeviceType.CIRCUITBREAKERMVW]: {
    defaultState: DEFAULT_MV_CIRCUIT_BREAKER_W_STATE,
  },
  [EDeviceType.MVDISC]: {
    defaultState: DEFAULT_MV_DISONNECTOR_STATE,
  },
  [EDeviceType.MVEDISC]: {
    defaultState: DEFAULT_MV_EARTH_DISONNECTOR_STATE,
  },
  [EDeviceType.MVDISC_DOWN]: {
    defaultState: DEFAULT_MV_DOWN_DISONNECTOR_STATE,
  },
  [EDeviceType.FUSEMV]: {
    defaultState: DEFAULT_MV_FUSE_STATE,
  },
  [EDeviceType.MVSWITCH]: {
    defaultState: DEFAULT_MV_SWITCH_DISONNECTOR_STATE,
  },
  [EDeviceType.CONNECTOON]: {
    defaultState: DEFAULT_CONNECTOON_STATE,
  },
  [EDeviceType.MULTIMETER]: {
    defaultState: probeDefaultState,
  },
  // VD4G project
  [EDeviceType.GENERATOR_GENERIC]: {
    defaultState: { ...generatorDefaultState, symbol: 'GENERATOR_GENERIC', deviceType: EDeviceType.GENERATOR_GENERIC },
  },
  [EDeviceType.TRAFO2_GENERIC]: {
    defaultState: { ...transformerDefaultState, symbol: 'TRAFO2_GENERIC', deviceType: EDeviceType.TRAFO2_GENERIC },
  },
  [EDeviceType.UTILITY_GENERIC]: {
    defaultState: { ...defaultLvUtilityState, symbol: 'UTILITY_GENERIC', deviceType: EDeviceType.UTILITY_GENERIC },
  },
  [EDeviceType.CIRCUITBREAKERMV_GENERIC]: {
    defaultState: {
      ...DEFAULT_MV_CIRCUIT_BREAKER_STATE,
      symbol: 'CIRCUITBREAKERMV_GENERIC',
      deviceType: EDeviceType.CIRCUITBREAKERMV_GENERIC,
    },
  },
} as Record<string, { defaultState: Partial<DeviceInitialState>; defaultJson?: IDefaultJson }>;
/**
 * All the approach for import in this file is shit/old/not so generic
 * It needs to be fully rewritten, firstly by clarifying the objectType/deviceType/symbol ...
 * Below is the version that implements what is needed for managing the block diargam
 *   e.g. creating devices on a different page
 */

export const deviceFromType = (type: EDeviceType, id: string, page: number, x: number, y: number): Device => {
  if (!id) {
    id = uuid();
  }

  const isBusbar = type === EDeviceType.BUSBAR;
  const state = getDeviceGenericState(type);
  let element: Device = {
    ..._.cloneDeep(state.defaultState),
    page,
    deviceType: type,
    json: _.cloneDeep(state.defaultJson),
  } as Device;

  // Fix id's
  if (element.json) {
    element.json.id = id;
    if (element.json.position) {
      element.json.position.x = x;
      element.json.position.y = y;
    }
  }

  if (isBusbar) {
    const rawCoordinates = {
      x,
      y,
    };
    element = generateBusbar(element, null, rawCoordinates);
  } else {
    element.x = x;
    element.y = y;
    // eslint-disable-next-line no-return-assign
    element.json?.ports.items.forEach((item) => (item.id = uuid()));
  }

  return element;
};

export const loadElements = (devices: Record<string, Device>): Record<string, Device> => {
  // First generate busbars
  Object.keys(devices).forEach((deviceId) => {
    const device = devices[deviceId];
    const isBusbar = device.symbol === 'BUSBAR';
    const isLine = device.type === ECellType.Line;
    const isConnectoon = device.deviceType === EDeviceType.CONNECTOON;
    if (isBusbar) {
      let element;
      const state = _.cloneDeep(getDeviceGenericState(device.deviceType));
      element = {
        ...state.defaultState,
        ..._.cloneDeep(device),
        deviceType: device.deviceType,
        json: state.defaultJson,
      };
      element = generateBusbar(element, device);
      element.json.id = deviceId;
      devices[deviceId] = element;
    }
    if (isLine) {
      let element;
      const state = _.cloneDeep(getDeviceGenericState(device.deviceType));
      element = {
        ...state.defaultState,
        ..._.cloneDeep(device),
        deviceType: device.deviceType,
        json: state.defaultJson,
      };
      element = generateLine(element, device);
      element.json.id = deviceId;
      devices[deviceId] = element;
    }
    if (isConnectoon) {
      const state = _.cloneDeep(getDeviceGenericState(device.deviceType));
      let element = {
        ...state.defaultState,
        ..._.cloneDeep(device),
        deviceType: device.deviceType,
        json: state.defaultJson,
      };
      const cableIsPresent =
        Object.keys(devices).find(
          (devId) => devices[devId].feederId === element.feederId && devices[devId].objectType === EDeviceObjectType.MvCable
        ) !== undefined;
      element = generateConnectoon(element, cableIsPresent);
      element.type = ECellType.CONNECTOON;
      element.json.id = deviceId;
      devices[deviceId] = element;
    }
  });

  Object.keys(devices).forEach((deviceId) => {
    const device = devices[deviceId];
    let element: Device | undefined;
    const id = deviceId;
    const isBusbar = device.symbol === 'BUSBAR';
    const isLine = device.type === ECellType.Line;
    const isConnectoon = device.symbol === 'CONNECTOON';
    const isLink = device.type === ECellType.Connection || device.type === ECellType.BlockConnection;
    let state = null;
    if (isFeeder(device.deviceType)) {
      return;
    }
    if (!isBusbar && !isConnectoon && !isLine) {
      if (!isLink) {
        state = _.cloneDeep(getDeviceGenericState(device.deviceType, device.symbol));
        state.defaultState.symbol = device.symbol;
      }
      if (state) {
        if (device.deviceType === EDeviceType.MOTOR) {
          device.RatedPower = calculateRatedPower(device.Eta as number, device.Pn as number);
        } else if (device.deviceType === EDeviceType.CURVE_DIAGRAM && state.defaultJson.size) {
          state.defaultJson.size.width = (device.x2 as number) - device.x;
          state.defaultJson.size.height = device.y2 as number;
        } else if (device.deviceType === EDeviceType.LOAD && device.ChargerType) {
          device.chargerTypes = [...LOAD_CHARGER_TYPES];
          device.initialized = false;
        } else if (device.deviceType === EDeviceType.CB_TM && Array.isArray(device.TripUnitFunction)) {
          device.TripUnitFunctions = device.TripUnitFunction.map((trip) => (trip as { '@': { name: string } })['@'].name);
          delete device.TripUnitFunction;
        }
        if (
          device.AvailableBackup &&
          (device.AvailableBackup as { Relation: Array<unknown> }).Relation &&
          Object.keys((device.AvailableBackup as { Relation: Array<unknown> }).Relation).length > 0
        ) {
          if (!device.CoordinationFilterList) {
            device.CoordinationFilterList = ['Not requested'];
          }
          (device.CoordinationFilterList as Array<string>).push('Back-up');
        }
        if (
          device.AvailableDiscrimination &&
          (device.AvailableDiscrimination as { Relation: Array<unknown> }).Relation &&
          Object.keys((device.AvailableDiscrimination as { Relation: Array<unknown> }).Relation).length > 0
        ) {
          if (!device.CoordinationFilterList) {
            device.CoordinationFilterList = ['Not requested'];
          }
          (device.CoordinationFilterList as Array<string>).push('Selectivity');
        }
        element = {
          ...state.defaultState,
          ..._.cloneDeep(device),
          deviceType: device.deviceType,
          json: state.defaultJson,
        };
      } else {
        element = { ...device };
      }
      if (isLink) {
        if (device.deviceType === EDeviceType.TEXTLINK) {
          generateTextLink(element, id);
        } else {
          generateLink(element, id, devices);
          if ((element.json.target as IJsonPort).port === '' || (element.json.source as IJsonPort).port === '') {
            element = undefined;
            delete devices[deviceId];
          }
        }
      } else {
        element = fixElementIds(element, id, false);
      }
      if (element) {
        devices[deviceId] = element;
      }
    }
  });
  // generateFeeders(devices);
  // generateTypicalUnits(devices);
  clearNulls(devices);
  return devices;
};

const generateBusbar = (element: Device, device: Device | null, raw: { x: number; y: number } | null = null): Device => {
  if (device) {
    element.x = device.x;
    element.y = device.y;
    element.x2 = device.x2 as number;
    element.y2 = device.y2 as number;
    if (element.json.position) {
      element.json.position.x = device.x;
      element.json.position.y = device.y2 as number;
    }
  } else if (raw) {
    element.x = raw.x;
    element.y = raw.y;
    element.x2 = raw.x + BUSBAR_MIN_WIDTH;
    element.y2 = raw.y;
  }
  const busbarWidth = device ? (device.x2 as number) - device.x : (element.x2 as number) - element.x;
  if (element.json.size) {
    element.json.size.width = Math.abs((element.x2 as number) - element.x);
    element.json.size.height = 3;
  }
  element.json.ports.items = [];
  for (let i = 0; i <= busbarWidth; i += BUSBAR_PORT_STEP * GRID_SIZE) {
    element.json.ports.items.push({
      group: 'default',
      args: {
        x: i,
        y: 0,
      },
      id: uuid(),
    });
  }
  return element;
};

const generateConnectoon = (element: Device, cableIsPresent: boolean): Device => {
  const width = Math.abs(element.x - (element.x2 ?? 0));
  const height = Math.abs(element.y - (element.y2 ?? 0));
  element.json.size = {
    width: width === 0 ? 0.01 : width,
    height: height === 0 ? 0.01 : height,
  };
  element.json.cableIsPresent = cableIsPresent;
  element.json.position = {
    x: element.x,
    y: element.y,
  };
  element.json.ports = {
    // groups: symbolDefaultJson.groups,
    groups: symbolPortsDefaults.groups,
    items: [
      {
        group: 'default',
        args: { x: 0, y: 0 },
        id: uuid(),
      },
      {
        group: 'default',
        args: { x: width, y: height },
        id: uuid(),
      },
    ],
  };
  return element;
};

const fixElementIds = (element: Device, id: string, raw = true): Device => {
  // Fix id's
  element.json.id = id;
  if (element.json.position) {
    element.json.position.x = element.x;
    element.json.position.y = element.y;
  }
  if (raw) {
    // eslint-disable-next-line no-return-assign
    element.json.ports.items.forEach((item) => (item.id = uuid()));
  } else {
    // eslint-disable-next-line no-return-assign
    element.json.ports.items.forEach((port, index) =>
      element.ports ? (port.id = element.ports[index] ? element.ports[index] : uuid()) : '0'
    );
  }
  element.errors = {};

  return element;
};

const generateLink = (element: Device, id: string, devices: Record<string, Device>) => {
  element.json = _.cloneDeep(linkDefaultJson) as unknown as IDefaultJson;
  element.json.id = id;
  if (element.json.target && element.json.source) {
    (element.json.source as IJsonPort).id = element.sourceId as string;
    (element.json.target as IJsonPort).id = element.targetId as string;
  }
  // if (element.vertices.length >= 3) {
  //   element.json.vertices = element.vertices.filter((vertex, index) => index != 0 && index != element.vertices.length - 1);
  // }
  element.json.vertices = element.userVertices;
  if (!element.sourcePort && element.page !== 0) {
    const busbar = devices[element.sourceId as string];
    if (busbar && busbar.json.ports && element.vertices) {
      const points = busbar.json.ports.items.map((port) => ({
        x: port.args.x + busbar.x,
        y: port.args.y + busbar.y,
      }));
      let lastConnectionVertex = element.vertices[element.vertices.length - 1];
      lastConnectionVertex = points.reduce((a, b) =>
        calculateDistance(a, lastConnectionVertex) < calculateDistance(b, lastConnectionVertex) ? a : b
      );
      const foundPort =
        busbar.json.ports.items.find((port) => port.args.x + busbar.x === lastConnectionVertex.x) || busbar.json.ports.items[0];
      (element.json.source as IJsonPort).port = foundPort.id;
    }
  } else if (element.json.source) {
    (element.json.source as IJsonPort).port = element.sourcePort as string;
  }
  if (!element.targetPort && element.page !== 0) {
    const busbar = devices[element.targetId as string];
    if (busbar && busbar.json.ports && element.vertices) {
      const points = busbar.json.ports.items.map((port) => ({
        x: port.args.x + busbar.x,
        y: port.args.y + busbar.y,
      }));
      let lastConnectionVertex = element.vertices[element.vertices.length - 1];
      lastConnectionVertex = points.reduce((a, b) =>
        calculateDistance(a, lastConnectionVertex) < calculateDistance(b, lastConnectionVertex) ? a : b
      );
      const foundPort =
        busbar.json.ports.items.find((port) => port.args.x + busbar.x === lastConnectionVertex.x) || busbar.json.ports.items[0];
      (element.json.target as IJsonPort).port = foundPort.id;
    }
  } else if (element.json.target) {
    (element.json.target as IJsonPort).port = element.targetPort as string;
  }
};

const generateTextLink = (element: Device, id: string): void => {
  element.json = _.cloneDeep(textLinkJson) as unknown as IDefaultJson;
  element.json.id = id;
  if (element.json.source) {
    (element.json.source as IJsonPort).id = element.sourceId as string;
    (element.json.source as IJsonPort).port = element.sourcePort as string;
  }
  element.json.vertices = element.vertices;

  if (element.vertices) {
    element.json.target = element.vertices[element.vertices.length - 1] as unknown as IJsonPort;
  }
};

const isBlockDevice = (deviceType: EDeviceType): boolean => {
  return (
    [
      EDeviceType.LV_SWITCHBOARD,
      EDeviceType.MV_SWITCHBOARD,
      EDeviceType.BLOCK_UTILITY,
      EDeviceType.BLOCK_GENERATOR,
      EDeviceType.BLOCK_TRAFO,
    ].indexOf(deviceType) !== -1
  );
};

const getDeviceGenericState = (
  deviceType: EDeviceType,
  customSymbol?: string
): { defaultState: DeviceInitialState; defaultJson: IDefaultJson } => {
  let state = genericStates[deviceType];
  if (!state && isBlockDevice(deviceType)) {
    state = _.cloneDeep(genericStates.BLOCK);
    if (state.defaultJson) {
      state.defaultJson.type = deviceType;
    }
    switch (deviceType) {
      case EDeviceType.LV_SWITCHBOARD:
        state.defaultState = lvSwitchboardDefaultState;
        break;
      case EDeviceType.MV_SWITCHBOARD:
        state.defaultState = mvSwitchboardDefaultState;
        break;
      case EDeviceType.BLOCK_UTILITY:
        state.defaultState = blockUtilityDefaultState;
        break;
      case EDeviceType.BLOCK_GENERATOR:
        state.defaultState = blockGeneratorDefaultState;
        break;
      case EDeviceType.BLOCK_TRAFO:
        state.defaultState = blockTrafoDefaultState;
        break;
      default:
        state.defaultState = {};
        break;
    }
    const blockPorts = getBlockDevicePorts(deviceType);
    if (blockPorts && state.defaultJson) {
      state.defaultJson.ports = blockPorts;
    }
  }
  if (!state.defaultJson) {
    state.defaultJson = { ..._.cloneDeep(symbolDefaultJson), type: deviceType } as unknown as IDefaultJson;
    if (state.defaultState.symbol !== 'CONNECTOON') {
      state.defaultJson.ports.items = symbolPorts[
        (customSymbol as keyof typeof symbolPorts) || (state.defaultState.symbol as string)
      ].map((port) => ({
        group: 'default',
        args: port,
        id: '',
      }));
    }
  }

  if (customSymbol && state.defaultJson.ports.items.length > 0) {
    state.defaultJson.ports.items = symbolPorts[customSymbol as keyof typeof symbolPorts].map((port) => ({
      group: 'default',
      args: port,
      id: '',
    }));
  }
  return state as { defaultState: DeviceInitialState; defaultJson: IDefaultJson };
};

const generateLine = (element: Device, device: Device, raw: { x: number; y: number } | null = null): Device => {
  if (device) {
    element.x = device.x;
    element.y = device.y;
    element.x2 = device.x2 ? device.x2 : device.x + 50;
    if (element.json.position) {
      element.json.position.x = device.x;
      element.json.position.y = device.y;
    }
  } else if (raw) {
    element.x = raw.x;
    element.y = raw.y;
    element.x2 = raw.x + 50;
    element.y2 = raw.y;
  }
  if (element.json.size) {
    element.json.size.width = Math.abs((element.x2 as number) - element.x);
    element.json.size.height = 3;
  }
  return element;
};

export const calculateDistance = (p1: PlainPoint, p2: PlainPoint): number => {
  return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
};
