export default {
  groups: {
    default: {
      position: {
        name: 'absolute',
      },
      attrs: {
        '.joint-port-body': {
          fill: 'transparent',
          stroke: 'red',
          strokeWidth: 'inherit',
          r: 3,
          magnet: 'passive',
          fillOpacity: 0.1,
          strokeOpacity: 1,
        },
      },
    },
  },
};
