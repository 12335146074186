import React from 'react';
import ModalWindow from 'ui/modals/ModalWindow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import CoordinationTabX from './componentXaxis';
import CoordinationTabY from './componentYaxis';

import { timeCurvesDiagramList, a2sCurvesDiagramList } from './constants';

const CoordinationModal = (): React.ReactElement => {
  const project = useSelector((state) => state.project);
  const devices = useSelector((state) => state.devices);

  const { t } = useTranslation();

  const selectedDevice = devices[project.selectedDeviceId as string];

  let firstTabName = '';
  let secondTabName = '';
  let diagramType = '';

  if (selectedDevice && timeCurvesDiagramList.includes(selectedDevice.diagram as string)) {
    firstTabName = t('CURVES_TIME');
    secondTabName = t('CURVE_CURRENT');
    diagramType = 'Time/Current';
  } else if (selectedDevice && a2sCurvesDiagramList.includes(selectedDevice.diagram as string)) {
    firstTabName = t('CURVES_ENERGY');
    secondTabName = t('CURVES_FAULT_CURRENT');
    diagramType = 'ShortCircuit/Energy';
  } else if ((selectedDevice && (selectedDevice.diagram as string)) === 'PEAK') {
    firstTabName = t('CURVES_FAULT_CURRENT');
    secondTabName = t('CURVES_PEAK');
    diagramType = 'ShortCircuit/Peak';
  } else {
    firstTabName = t('CURVES_TIME');
    secondTabName = t('CURVE_CURRENT');
    diagramType = 'Time/Current';
  }
  return (
    <ModalWindow
      title="DOC_COORDINATES"
      tabs={{
        [firstTabName]: (
          <CoordinationTabX
            selectedDevice={selectedDevice}
            diagramType={diagramType}
            selectedDeviceId={project.selectedDeviceId as string}
          />
        ),
        [secondTabName]: (
          <CoordinationTabY
            selectedDevice={selectedDevice}
            diagramType={diagramType}
            selectedDeviceId={project.selectedDeviceId as string}
          />
        ),
      }}
    />
  );
};

export default CoordinationModal;
