import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  objectType: EDeviceObjectType.MvSwitchDisconnector,
  deviceType: EDeviceType.MVSWITCH,
  Locked: false,
  symbol: 'MVSWITCH',

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
};
