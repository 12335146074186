import DEFAULT_IMPEDANCE_STATE from 'types/devices/impedance/initial-state';
import DEFAULT_LOAD_STATE from 'types/devices/load/initial-state';
import DEFAULT_LV_CABLE_STATE from 'types/devices/lvCable/initial-state';
import DEFAULT_MV_CABLE_STATE from 'types/devices/mvCable/initial-state';
import {
  defaultLvUtilitySmallState as DEFAULT_LV_UTILITY_SMALL_STATE,
  defaultLvUtilityState as DEFAULT_LV_UTILITY_STATE,
} from 'types/devices/lvUtility/initial-state';
// -------------
import DEFAULT_LINK_STATE from 'types/devices/link/initial-state';
import DEFAULT_BUSBAR_STATE from 'types/devices/busbar/initial-state';
import DEFAULT_CB_TM_STATE from 'types/devices/circuitBreaker/initial-state';
import DEFAULT_ARRIVAL_STATE from 'types/devices/arrival/initial-state';
import DEFAULT_DEPARTURE_STATE from 'types/devices/departure/initial-state';
import DEFAULT_TYPICALFEEDER_STATE from 'types/devices/typicalFeeder/initial-state';
import DEFAULT_FUSEBASE_STATE from 'types/devices/fuse/initial-state';
import DEFAULT_CB_FEEDER_STATE from 'types/devices/cbFeeder/initial-state';
import DEFAULT_TRAFO_FEEDER_STATE from 'types/devices/trafoFeeder/initial-state';
import DEFAULT_MOTOR_FEEDER_STATE from 'types/devices/motorFeeder/initial-state';
import DEFAULT_CONTACTOR_STATE from 'types/devices/contactor/initial-state';
import DEFAULT_MANUAL_MOTOR_STARTER_STATE from 'types/devices/manualMotorStarter/initial-state';
import DEFAULT_OVERLOAD_RELAY_STATE from 'types/devices/overloadRelay/initial-state';
import DEFAULT_MOTOR_STATE from 'types/devices/motor/initial-state';
import DEFAULT_TRANSFORMER_STATE from 'types/devices/trafo/initial-state';
import DEFAULT_TRANSFORMER_STATE3 from 'types/devices/trafo/initial-state-trafo3';
import {
  generatorDefaultState as DEFAULT_GENERATOR_STATE,
  defaultGeneratorSmallState as DEFAULT_GENERATOR_SMALL_STATE,
} from 'types/devices/generator/initial-state';
import {
  mvUtilityDefaultState as DEFAULT_MV_UTILITY_STATE,
  defaultMvUtilitySmallState as DEFAULT_MV_UTILITY_SMALL_STATE,
} from 'types/devices/mvUtility/initial-state';
import DEFAULT_SOFTSTARTER_STATE from 'types/devices/softstarter/initial-state';
import DEFAULT_LV_SWITCHBOARD_STATE from 'types/devices/lvSwitchboard/initial-state';
import DEFAULT_MV_SWITCHBOARD_STATE from 'types/devices/mvSwitchboard/initial-state';
import DEFAULT_BLOCK_UTILITY_STATE from 'types/devices/blockUtility/initial-state';
import DEFAULT_BLOCK_TRAFO_STATE from 'types/devices/blockTrafo/initial-state';
import DEFAULT_BLOCK_GENERATOR_STATE from 'types/devices/blockGenerator/initial-state';
import DEFAULT_BLOCK_UPS_STATE from 'types/devices/blockUps/initial-state';
import DEFAULT_LV_DISCONNECTOR_STATE from 'types/devices/lvDisconnector/initial-state';
import DEFAULT_RESIDUAL_CURRENT_CIRCUIT_BREAKER_STATE from 'types/devices/residualCurrentCircuitBreaker/initial-state';
import DEFAULT_BUSWAYS_STATE from 'types/devices/busways/initial-state';
import DEFAULT_TEXT_AREA_STATE from 'types/devices/textArea/initial-state';
import DEFAULT_LINE_STATE from 'types/devices/line/initial-state';
import {
  upsDefaultState as DEFAULT_UPS_STATE,
  upsBypassDefaultState as DEFAULT_UPS_BYPASS_STATE,
  upsNoByPassDefaultState as DEFAULT_UPS_NOBYPASS_STATE,
  ups2inputDefaultState as DEFAULT_UPS_2_INPUT_STATE,
} from 'types/devices/ups/initial-state';
import DEFAULT_CAPACITOR_BANK_STATE from 'types/devices/capacitorBank/initial-state';
import DEFAULT_CURVE_DIAGRAM_STATE from 'types/devices/curveDiagram/initial-state';
import DEFAULT_CONNECTOON_STATE from 'types/devices/connectoon/initial-state';
import DEFAULT_MV_TYPICAL_UNIT_STATE from 'types/devices/mvTypicalUnit/initial-state';
import DEFAULT_LV_MACRO_STATE from 'types/devices/lvMacro/initial-state';
import DEFAULT_MV_CIRCUIT_BREAKER_STATE from 'types/devices/mvCircuitBreaker/initial-state';
import DEFAULT_MV_CIRCUIT_BREAKER_W_STATE from 'types/devices/mvCircuitBreakerW/initial-state';
import DEFAULT_MV_DISONNECTOR_STATE from 'types/devices/mvDisconnector/initial-state';
import DEFAULT_MV_EARTH_DISONNECTOR_STATE from 'types/devices/mvEarthDisconnector/initial-state';
import DEFAULT_MV_DOWN_DISONNECTOR_STATE from 'types/devices/mvDownDisconnector/initial-state';
import DEFAULT_MV_FUSE_STATE from 'types/devices/mvFuse/initial-state';
import DEFAULT_MV_SWITCH_DISONNECTOR_STATE from 'types/devices/mvSwitchDisconnector/initial-state';
import DEFAULT_PROBE_STATE from 'types/devices/probe/initial-state';
import DEFAULT_MEDICAL_FEEDER_STATE from 'types/devices/medicalRoomFeeder/initial-state';
import DEFAULT_HEATER_FEEDER_STATE from 'types/devices/heaterFeeder/initial-state';
import DEFAULT_VEHICLE_CHARGER_FEEDER_STATE from 'types/devices/vehicleChargerFeeder/initial-state';
import DEFAULT_ATS_STATE from 'types/devices/ats/initial-state';
import {
  defaultBioGeneratorState,
  defaultPHOTOGeneratorState,
  defaultWindGeneratorState,
} from 'types/devices/renewableGenerator/initial-state';
import { cloneDeep } from 'lodash';
import { EDeviceType } from './enums.d';
import { Device } from './device';

export const elementObjectIdCounter = Object.values(EDeviceType).reduce(
  (objIdCounter, deviceType) => {
    // eslint-disable-next-line no-param-reassign
    objIdCounter[deviceType] = 1;
    return objIdCounter;
  },
  {} as Record<EDeviceType, number>
);

export const elementObjectSignCounter = cloneDeep(elementObjectIdCounter);

export const elementObjectSignPrefix: Record<EDeviceType, string> = {
  'app.IMPEDANCE': '-Z',
  'app.LOAD': '-L',
  'app.WIRELV': '-WC',
  'app.WIREMV': '-WC',
  'app.UTILITY': '-U',
  'app.UTILITY_SMALL': '-U',
  'app.UTILITY_GENERIC': '-U',
  'app.LINK': '',
  'app.BUSBAR': '-B',
  'app.CB_TM': '-QF',
  'app.ARRIVAL': '',
  'app.DEPARTURE': '-W',
  'app.TYPICAL_FEEDER': '-W',
  // 'app.TYPICAL_BREAKER_MOTOR': '-W',
  // 'app.TYPICAL_BREAKER_LOAD': '-W',
  'app.FUSEBASE': '-FU',
  'app.CB_FEEDER': '',
  'app.MEDICAL_ROOM_FEEDER': '',
  'app.HEATER_FEEDER': '',
  'app.VEHICLE_CHARGER_FEEDER': '',
  'app.TRAFO_FEEDER': '',
  'app.CONTACTOR': '-K',
  'app.MMS': '-QF',
  'app.OLR': '-FR',
  'app.MOTOR': '-MS',
  'app.MOTOR_FEEDER': '',
  'app.TRAFO2': '-TM',
  'app.TRAFO3': '-TM',
  'app.TRAFO2_GENERIC': '-TM',
  'app.GENERATOR': '-G',
  'app.GENERATOR_SMALL': '-G',
  'app.GENERATOR_BIO': '-G',
  'app.GENERATOR_PHOTO': '-G',
  'app.GENERATOR_WIND': '-G',
  'app.GENERATOR_GENERIC': '-G',
  'app.UTILITY_MV_SHORT': '-U',
  'app.UTILITY_MV': '-U',
  'app.UTILITY_MV_SMALL': '-U',
  'app.SOFTSTARTER': '-SS',
  'app.LV_SWITCHBOARD': '+Q',
  'app.MV_SWITCHBOARD': '+Q',
  'app.BLOCK_UTILITY': '-U',
  'app.BLOCK_TRAFO': '-TM',
  'app.BLOCK_GENERATOR': '-G',
  'app.BLOCK_UPS': '-U',
  'app.DISCONNECTORLV': '-QS',
  'app.RCCB': '-QD',
  'app.ATS': '-ATS',
  'app.UPS': '-U',
  'app.UPS_2_INPUT': '-U',
  'app.UPS_BYPASS': '-U',
  'app.UPS_NOBYPASS': '-U',
  'app.BUSDUCT': '-BW',
  'app.TEXTAREA': '-TA',
  'app.LINE': '-LI',
  'app.CURVE_DIAGRAM': '-CD',
  'app.CAPACITORBANKS': '-C',
  'app.BME_IN': '',
  'app.BME_OUT': '',
  'app.DRC': '',
  'app.DRS_LEFT': '',
  'app.DRS_RIGHT': '',
  'app.HBC': '',
  'app.HBS_LEFT': '',
  'app.HBS_RIGHT': '',
  'app.RLC': '',
  'app.RRC': '',
  'app.SBC': '',
  'app.SBC_W': '',
  'app.SBC_W_OUT': '',
  'app.SBC_W_IN': '',
  'app.SBM': '',
  'app.SBR_LEFT': '',
  'app.SBR_RIGHT': '',
  'app.SBS_LEFT': '',
  'app.SBS_RIGHT': '',
  'app.SBS_W_RIGHT': '',
  // 'app.SDC': '',
  'app.SDD': '',
  'app.SDM': '',
  'app.SDS_LEFT': '',
  'app.SDS_RIGHT': '',
  'app.SFC': '',
  'app.SFS_LEFT': '',
  'app.SFV': '',
  'app.TRAFOENCLOS': '',
  'app.UMP': '',
  'app.WBC': '',
  'app.WBS_LEFT': '',
  'app.WBS_RIGHT': '',
  'app.DRC_IN': '',
  'app.HBC_IN': '',
  'app.SBC_IN': '',
  'app.RLC_IN': '',
  'app.RRC_IN': '',
  'app.SDC_IN': '',
  'app.WBC_IN': '',
  'app.DRC_OUT': '',
  'app.HBC_OUT': '',
  'app.SBC_OUT': '',
  'app.RLC_OUT': '',
  'app.RRC_OUT': '',
  'app.SDC_OUT': '',
  'app.WBC_OUT': '',

  'app.IF_IN': '',
  'app.IF_OUT': '',
  'app.M': '',
  'app.BT_LEFT': '',
  'app.BT_RIGHT': '',
  'app.RM_LEFT': '',
  'app.RM_RIGHT': '',

  'app.ZS2_BT_LEFT': '',
  'app.ZS2_BT_RIGHT': '',
  'app.ZS2_IF_IN': '',
  'app.ZS2_IF_OUT': '',
  'app.ZS2_M': '',
  'app.ZS2_RM_LEFT': '',
  'app.ZS2_RM_RIGHT': '',
  'app.BME': '',
  'app.SDC': '',
  'app.SBC.svg': '',
  'app.INCOMING_DRC': '',

  'app.MVEDISC': '-QCE',
  'app.MVSWITCH': '-QBD',
  'app.WIREMV_IN': '-WC',
  // 'app.WIREMV_OUT': '-WC',
  'app.CIRCUITBREAKERMV': '-QAB',
  'app.CIRCUITBREAKERMVW': '-QAB',
  'app.CIRCUITBREAKERMV_GENERIC': '-QAB',
  'app.MVDISC': '-QBS',
  'app.MVDISC_DOWN': '-QBS',
  'app.FUSEMV': '-FCF',
  'app.CONNECTOON': '',
  'app.MULTIMETER': '-P',

  // generic TUs
  'app.INCOMING_DIR': '',
  'app.INCOMING_FIXCBD': '',
  'app.INCOMING_WITHCBD': '',
  'app.INCOMING_REMCBD': '',
  'app.INCOMING_D': '',
  'app.INCOMING_WITHCB': '',

  'app.OUTGOING_DIR': '',
  'app.OUTGOING_FIXCBD': '',
  'app.OUTGOING_WITHCBD': '',
  'app.OUTGOING_REMCBD': '',
  'app.OUTGOING_D': '',
  'app.OUTGOING_FUXD': '',
  'app.OUTGOING_WITHCB': '',

  'app.BUSTIE_FIXCBD_RIGHT': '',
  'app.BUSTIE_WITHCBD_RIGHT': '',
  'app.BUSTIE_REMCBD_RIGHT': '',
  'app.BUSTIE_D_RIGHT': '',
  'app.BUSTIE_FUXD_LEFT': '',
  'app.BUSTIE_WITHCB_RIGHT': '',
  'app.RISER_LEFT': '',
  'app.RISER_RIGHT': '',

  'app.MEASUREMENT': '',
  // 'app.INCOMING_DRC': '',
  // 'app.INCOMING_HBC': '',
  // 'app.INCOMING_SBC_W': '',
  // 'app.INCOMING_SBC': '',
  // 'app.INCOMING_SDC': '',
  // 'app.INCOMING_WBC': '',
  // 'app.OUTCOMING_DRC': '',
  // 'app.OUTCOMING_HBC': '',
  // 'app.OUTCOMING_SBC_W': '',
  // 'app.OUTCOMING_SBC': '',
  // 'app.OUTCOMING_SDC': '',
  // 'app.OUTCOMING_SFC': '',
  // 'app.OUTCOMING_WBC': '',
  // 'app.BUSTIE_HBS_RIGHT': '',
  // 'app.BUSTIE_SBS_W_RIGHT': '',
  // 'app.BUSTIE_SBS_RIGHT': '',
  // 'app.BUSTIE_SDS_RIGHT': '',
  // 'app.BUSTIE_WBS_RIGHT': '',
  // 'app.RISER_DRS_RIGHT': '',
  // 'app.RISER_DRS_LEFT': '',
  // 'app.BUSTIE_SFS_LEFT': '',
  'app.MEASUREMENT_SFV': '',
  // TODO: Was not present before
  'app.DOC_CURVES': '',
  'app.TEXTLINK': '',
  'app.FEEDER': '',

  'app.MVDISCFUSE': '',
  'app.MVSWITCHFUSE': '',

  // safering
  'app.C': '',
  'app.F': '',
  'app.V': '',
  'app.DE': '',

  'app.C_LIN': '',
  'app.C_INT': '',
  'app.C_RIN': '',
  'app.F_RIN': '',
  'app.V_RIN': '',
  'app.V_INT': '',
  'app.F_INT': '',
  // 'app.F_RIN': '',
  'app.DE_LIN': '',

  'app.IF_COMPACT_SF6_IEC_IN': '',
  'app.IF_COMPACT_ECO_IEC_IN': '',
  'app.IF_COMPACT_SF6_IND_IN': '',
  'app.IF_COMPACT_ECO_IND_IN': '',
  'app.IF_STANDARD_SF6_IEC_IN': '',
  'app.IF_STANDARD_ECO_IEC_IN': '',
  'app.IF_STANDARD_SF6_IND_IN': '',
  'app.IF_STANDARD_ECO_IND_IN': '',
  'app.IF_COMPACT_SF6_IEC_OUT': '',
  'app.IF_COMPACT_ECO_IEC_OUT': '',
  'app.IF_COMPACT_SF6_IND_OUT': '',
  'app.IF_COMPACT_ECO_IND_OUT': '',
  'app.IF_STANDARD_SF6_IEC_OUT': '',
  'app.IF_STANDARD_ECO_IEC_OUT': '',
  'app.IF_STANDARD_SF6_IND_OUT': '',
  'app.IF_STANDARD_ECO_IND_OUT': '',
  'app.BT_BR_SF6_IEC': '',
  'app.BT_BR_ECO_IEC': '',
  'app.BT_BR__SF6_IEC': '',
  'app.BT_BR__ECO_IEC': '',
  'app.BT_RIGHT_SF6_IND': '',
  'app.BT_RIGHT_ECO_IND': '',
  'app.BR_RIGHT_SF6_IND': '',
  'app.BR_RIGHT_ECO_IND': '',
  'app.BT_LEFT_SF6_IND': '',
  'app.BT_LEFT_ECO_IND': '',
  'app.BR_LEFT_SF6_IND': '',
  'app.BR_LEFT_ECO_IND': '',
  // Lv macros
  'app.LVCABLE': '',
  'app.LVCB_EL_F': '',
  'app.LVCB_EL_W': '',
  'app.LVCB_MORC_F': '',
  'app.LVCB_MORC_W': '',
  'app.LVCB_MO_F': '',
  'app.LVCB_MO_W': '',
  'app.LVCB_TMRC_F': '',
  'app.LVCB_TMRC_W': '',
  'app.LVCB_TM_F': '',
  'app.LVCB_TM_W': '',
  'app.LVDISC_F': '',
  'app.LVDISC_W': '',
  'app.LVRCD_F': '',

  // auxillaries
  'app.AUX_CT': '',
  'app.AUX_DAYLIGHT_SWITCH': '',
  'app.AUX_HIGH_POWER_METER': '',
  'app.AUX_INDICATOR': '',
  'app.AUX_MULTIMETER': '',
  'app.AUX_OVR': '',
  'app.AUX_PLUG': '',
  'app.AUX_TIME_ASTRO_SWITCH': '',
  'app.AUX_TIME_SWITCH': '',

  // aux impedances
  'app.IMPEDANCE_AUX_HIGH_POWER_METER': '-Z',
  'app.IMPEDANCE_CT': '-Z',
  'app.IMPEDANCE_HORZ_PHANTOM': '-Z',
  'app.IMPEDANCE_PHANTOM': '-Z',
  'app.AUX_IMP_HIGH_POWER_METER': '-Z',

  // aux loads
  'app.LOAD_CT': '-L',
  'app.LOAD_DAYLIGHT_SWITCH': '-L',
  'app.LOAD_INDICATOR': '-L',
  'app.LOAD_OVR': '-L',
  'app.LOAD_PLUG': '-L',
  'app.LOAD_TIME_ASTRO_SWITCH': '-L',
  'app.LOAD_TIME_SWITCH': '-L',

  // lose components
  'app.LOOSE_D': '',
  'app.LOOSE_DF': '',
  'app.LOOSE_FB': '',
  'app.LOOSE_M': '',
  'app.LOOSE_WB': '',
};

export const elementObjectIdPrefix: Record<EDeviceType, string> = {
  'app.IMPEDANCE': 'S',
  'app.LOAD': 'C',
  'app.WIRELV': 'H',
  'app.WIREMV': 'H',
  'app.UTILITY': 'A',
  'app.UTILITY_SMALL': 'A',
  'app.UTILITY_GENERIC': 'A',
  'app.LINK': 'U',
  'app.BUSBAR': 'T',
  'app.CB_TM': 'K',
  'app.ARRIVAL': 'V',
  'app.DEPARTURE': 'W',
  'app.TYPICAL_FEEDER': 'W',
  // 'app.TYPICAL_BREAKER_MOTOR': "W",
  // 'app.TYPICAL_BREAKER_LOAD': 'W',
  'app.FUSEBASE': 'Q',
  'app.CB_FEEDER': '',
  'app.MEDICAL_ROOM_FEEDER': '',
  'app.HEATER_FEEDER': '',
  'app.VEHICLE_CHARGER_FEEDER': '',
  'app.TRAFO_FEEDER': '',
  'app.CONTACTOR': 'O',
  'app.MMS': 'f',
  'app.OLR': 'P',
  'app.MOTOR': 'D',
  'app.MOTOR_FEEDER': '',
  'app.TRAFO2': 'I',
  'app.TRAFO3': 'I',
  'app.TRAFO2_GENERIC': 'I',
  'app.GENERATOR': 'B',
  'app.GENERATOR_SMALL': 'B',
  'app.GENERATOR_BIO': 'B',
  'app.GENERATOR_PHOTO': 'B',
  'app.GENERATOR_WIND': 'B',
  'app.GENERATOR_GENERIC': 'B',
  'app.UTILITY_MV_SHORT': 'A',
  'app.UTILITY_MV': 'A',
  'app.UTILITY_MV_SMALL': 'A',
  'app.SOFTSTARTER': 'h',
  'app.LV_SWITCHBOARD': 'e',
  'app.MV_SWITCHBOARD': 'e',
  'app.BLOCK_UTILITY': 'A',
  'app.BLOCK_TRAFO': 'I',
  'app.BLOCK_GENERATOR': 'B',
  'app.BLOCK_UPS': 'k',
  'app.DISCONNECTORLV': 'M',
  'app.RCCB': 'Y',
  'app.BUSDUCT': 'Z',
  'app.TEXTAREA': 'TEXT_AREA',
  'app.LINE': 'LINE',
  'app.UPS': 'd',
  'app.CAPACITORBANKS': 'E',
  'app.CURVE_DIAGRAM': 'z',
  'app.ATS': 'j',
  'app.UPS_2_INPUT': 'k',
  'app.UPS_BYPASS': 'k',
  'app.UPS_NOBYPASS': 'k',
  // start ty
  'app.BME_IN': '',
  'app.BME_OUT': '',
  'app.DRC_IN': '',
  'app.DRC_OUT': '',
  'app.DRS_LEFT': '',
  'app.DRS_RIGHT': '',
  'app.HBC_IN': '',
  'app.HBC_OUT': '',
  'app.HBS_LEFT': '',
  'app.HBS_RIGHT': '',
  'app.RLC_IN': '',
  'app.RLC_OUT': '',
  'app.RRC_IN': '',
  'app.RRC_OUT': '',
  'app.SBC_IN': '',
  'app.SBC': '',
  'app.SBC_W_IN': '',
  'app.SBC_W_OUT': '',
  'app.SBM': '',
  'app.SBR_LEFT': '',
  'app.SBR_RIGHT': '',
  'app.SBS_LEFT': '',
  'app.SBS_RIGHT': '',
  'app.SBS_W_RIGHT': '',
  'app.SDC_IN': '',
  'app.SDC_OUT': '',
  'app.SDD': '',
  'app.SDM': '',
  'app.SDS_LEFT': '',
  'app.SDS_RIGHT': '',
  'app.SFC': '',
  'app.SFS_LEFT': '',
  'app.SFV': '',
  'app.TRAFOENCLOS': '',
  'app.UMP': '',
  'app.WBC_IN': '',
  'app.WBC_OUT': '',
  'app.WBS_LEFT': '',
  'app.WBS_RIGHT': '',
  'app.DRC': '',
  'app.HBC': '',
  'app.RLC': '',
  'app.RRC': '',
  'app.SBC_W': '',
  // 'app.SDC': '',
  'app.WBC': '',
  'app.SBC_OUT': '',
  'app.BME': '',
  'app.SDC': '',
  'app.SBC.svg': '',
  'app.INCOMING_DRC': '',

  'app.IF_IN': '',
  'app.IF_OUT': '',
  'app.M': '',
  'app.BT_LEFT': '',
  'app.BT_RIGHT': '',
  'app.RM_LEFT': '',
  'app.RM_RIGHT': '',

  'app.ZS2_BT_LEFT': '',
  'app.ZS2_BT_RIGHT': '',
  'app.ZS2_IF_IN': '',
  'app.ZS2_IF_OUT': '',
  'app.ZS2_M': '',
  'app.ZS2_RM_LEFT': '',
  'app.ZS2_RM_RIGHT': '',
  // end ty
  'app.MVEDISC': 'N',
  'app.CIRCUITBREAKERMV': 'L',
  'app.CIRCUITBREAKERMVW': 'L',
  'app.CIRCUITBREAKERMV_GENERIC': 'L',
  'app.MVDISC': 'N',
  'app.MVDISC_DOWN': 'N',
  'app.MVSWITCH': 'N',
  'app.FUSEMV': 'R',
  'app.CONNECTOON': 'U',

  'app.MULTIMETER': 'b',

  // genericTUs
  'app.INCOMING_DIR': '',
  'app.INCOMING_FIXCBD': '',
  'app.INCOMING_WITHCBD': '',
  'app.INCOMING_REMCBD': '',
  'app.INCOMING_D': '',
  'app.INCOMING_WITHCB': '',

  'app.OUTGOING_DIR': '',
  'app.OUTGOING_FIXCBD': '',
  'app.OUTGOING_WITHCBD': '',
  'app.OUTGOING_REMCBD': '',
  'app.OUTGOING_D': '',
  'app.OUTGOING_FUXD': '',
  'app.OUTGOING_WITHCB': '',

  'app.BUSTIE_FIXCBD_RIGHT': '',
  'app.BUSTIE_WITHCBD_RIGHT': '',
  'app.BUSTIE_REMCBD_RIGHT': '',
  'app.BUSTIE_D_RIGHT': '',
  'app.BUSTIE_FUXD_LEFT': '',
  'app.BUSTIE_WITHCB_RIGHT': '',
  'app.RISER_LEFT': '',
  'app.RISER_RIGHT': '',

  'app.MEASUREMENT': '',
  'app.MEASUREMENT_SFV': '',

  // safering
  'app.C': '',
  'app.F': '',
  'app.V': '',
  'app.DE': '',

  'app.C_LIN': '',
  'app.C_INT': '',
  'app.C_RIN': '',
  'app.F_RIN': '',
  'app.V_RIN': '',
  'app.V_INT': '',
  'app.F_INT': '',
  'app.DE_LIN': '',

  // TODO: Was not present before
  'app.DOC_CURVES': '',
  'app.TEXTLINK': '',
  'app.FEEDER': '',

  'app.WIREMV_IN': '',

  'app.MVDISCFUSE': '',
  'app.MVSWITCHFUSE': '',

  'app.IF_COMPACT_SF6_IEC_IN': '',
  'app.IF_COMPACT_ECO_IEC_IN': '',
  'app.IF_COMPACT_SF6_IND_IN': '',
  'app.IF_COMPACT_ECO_IND_IN': '',
  'app.IF_STANDARD_SF6_IEC_IN': '',
  'app.IF_STANDARD_ECO_IEC_IN': '',
  'app.IF_STANDARD_SF6_IND_IN': '',
  'app.IF_STANDARD_ECO_IND_IN': '',
  'app.IF_COMPACT_SF6_IEC_OUT': '',
  'app.IF_COMPACT_ECO_IEC_OUT': '',
  'app.IF_COMPACT_SF6_IND_OUT': '',
  'app.IF_COMPACT_ECO_IND_OUT': '',
  'app.IF_STANDARD_SF6_IEC_OUT': '',
  'app.IF_STANDARD_ECO_IEC_OUT': '',
  'app.IF_STANDARD_SF6_IND_OUT': '',
  'app.IF_STANDARD_ECO_IND_OUT': '',
  'app.BT_BR_SF6_IEC': '',
  'app.BT_BR_ECO_IEC': '',
  'app.BT_BR__SF6_IEC': '',
  'app.BT_BR__ECO_IEC': '',
  'app.BT_RIGHT_SF6_IND': '',
  'app.BT_RIGHT_ECO_IND': '',
  'app.BR_RIGHT_SF6_IND': '',
  'app.BR_RIGHT_ECO_IND': '',
  'app.BT_LEFT_SF6_IND': '',
  'app.BT_LEFT_ECO_IND': '',
  'app.BR_LEFT_SF6_IND': '',
  'app.BR_LEFT_ECO_IND': '',
  // Lv macros
  'app.LVCABLE': '',
  'app.LVCB_EL_F': '',
  'app.LVCB_EL_W': '',
  'app.LVCB_MORC_F': '',
  'app.LVCB_MORC_W': '',
  'app.LVCB_MO_F': '',
  'app.LVCB_MO_W': '',
  'app.LVCB_TMRC_F': '',
  'app.LVCB_TMRC_W': '',
  'app.LVCB_TM_F': '',
  'app.LVCB_TM_W': '',
  'app.LVDISC_F': '',
  'app.LVDISC_W': '',
  'app.LVRCD_F': '',

  // auxillaries
  'app.AUX_CT': '',
  'app.AUX_DAYLIGHT_SWITCH': '',
  'app.AUX_HIGH_POWER_METER': '',
  'app.AUX_INDICATOR': '',
  'app.AUX_MULTIMETER': '',
  'app.AUX_OVR': '',
  'app.AUX_PLUG': '',
  'app.AUX_TIME_ASTRO_SWITCH': '',
  'app.AUX_TIME_SWITCH': '',
  // aux impedances
  'app.IMPEDANCE_AUX_HIGH_POWER_METER': 'S',
  'app.IMPEDANCE_CT': 'S',
  'app.IMPEDANCE_HORZ_PHANTOM': 'S',
  'app.IMPEDANCE_PHANTOM': 'S',
  'app.AUX_IMP_HIGH_POWER_METER': 'S',

  // aux loads
  'app.LOAD_CT': 'C',
  'app.LOAD_DAYLIGHT_SWITCH': 'C',
  'app.LOAD_INDICATOR': 'C',
  'app.LOAD_OVR': 'C',
  'app.LOAD_PLUG': 'C',
  'app.LOAD_TIME_ASTRO_SWITCH': 'C',
  'app.LOAD_TIME_SWITCH': 'C',

  // loose components
  'app.LOOSE_D': '',
  'app.LOOSE_DF': '',
  'app.LOOSE_FB': '',
  'app.LOOSE_M': '',
  'app.LOOSE_WB': '',
};

export const elementDefaultState: Record<EDeviceType, Partial<Device>> = {
  'app.IMPEDANCE': DEFAULT_IMPEDANCE_STATE,
  'app.IMPEDANCE_CT': DEFAULT_IMPEDANCE_STATE,
  'app.IMPEDANCE_HORZ_PHANTOM': DEFAULT_IMPEDANCE_STATE,
  'app.IMPEDANCE_PHANTOM': DEFAULT_IMPEDANCE_STATE,
  'app.LOAD': DEFAULT_LOAD_STATE,
  'app.WIRELV': DEFAULT_LV_CABLE_STATE,
  'app.WIREMV': DEFAULT_MV_CABLE_STATE,
  'app.UTILITY': DEFAULT_LV_UTILITY_STATE,
  'app.UTILITY_SMALL': DEFAULT_LV_UTILITY_SMALL_STATE,
  'app.UTILITY_GENERIC': { ...DEFAULT_LV_UTILITY_STATE, symbol: 'UTILITY_GENERIC' },
  'app.LINK': DEFAULT_LINK_STATE,
  'app.BUSBAR': DEFAULT_BUSBAR_STATE,
  'app.CB_TM': DEFAULT_CB_TM_STATE,
  'app.ARRIVAL': DEFAULT_ARRIVAL_STATE,
  'app.DEPARTURE': DEFAULT_DEPARTURE_STATE,
  'app.FUSEBASE': DEFAULT_FUSEBASE_STATE,
  'app.CB_FEEDER': DEFAULT_CB_FEEDER_STATE,
  'app.TRAFO_FEEDER': DEFAULT_TRAFO_FEEDER_STATE,
  'app.MEDICAL_ROOM_FEEDER': DEFAULT_MEDICAL_FEEDER_STATE,
  'app.HEATER_FEEDER': DEFAULT_HEATER_FEEDER_STATE,
  'app.VEHICLE_CHARGER_FEEDER': DEFAULT_VEHICLE_CHARGER_FEEDER_STATE,
  'app.MOTOR_FEEDER': DEFAULT_MOTOR_FEEDER_STATE,
  'app.CONTACTOR': DEFAULT_CONTACTOR_STATE,
  'app.MMS': DEFAULT_MANUAL_MOTOR_STARTER_STATE,
  'app.OLR': DEFAULT_OVERLOAD_RELAY_STATE,
  'app.MOTOR': DEFAULT_MOTOR_STATE,
  'app.TRAFO2': DEFAULT_TRANSFORMER_STATE,
  'app.TRAFO3': { ...DEFAULT_TRANSFORMER_STATE, ...DEFAULT_TRANSFORMER_STATE3 },
  'app.TRAFO2_GENERIC': { ...DEFAULT_TRANSFORMER_STATE, symbol: 'TRAFO2_GENERIC' },
  'app.GENERATOR': DEFAULT_GENERATOR_STATE,
  'app.GENERATOR_SMALL': DEFAULT_GENERATOR_SMALL_STATE,
  'app.GENERATOR_BIO': defaultBioGeneratorState,
  'app.GENERATOR_PHOTO': defaultPHOTOGeneratorState,
  'app.GENERATOR_WIND': defaultWindGeneratorState,
  'app.GENERATOR_GENERIC': { ...DEFAULT_GENERATOR_STATE, symbol: 'GENERATOR_GENERIC' },
  'app.UTILITY_MV_SHORT': DEFAULT_MV_UTILITY_STATE,
  'app.UTILITY_MV': DEFAULT_MV_UTILITY_STATE,
  'app.UTILITY_MV_SMALL': DEFAULT_MV_UTILITY_SMALL_STATE,
  'app.SOFTSTARTER': DEFAULT_SOFTSTARTER_STATE,
  'app.LV_SWITCHBOARD': DEFAULT_LV_SWITCHBOARD_STATE,
  'app.MV_SWITCHBOARD': DEFAULT_MV_SWITCHBOARD_STATE,
  'app.BLOCK_UTILITY': DEFAULT_BLOCK_UTILITY_STATE,
  'app.BLOCK_TRAFO': DEFAULT_BLOCK_TRAFO_STATE,
  'app.BLOCK_GENERATOR': DEFAULT_BLOCK_GENERATOR_STATE,
  'app.BLOCK_UPS': DEFAULT_BLOCK_UPS_STATE,
  'app.DISCONNECTORLV': DEFAULT_LV_DISCONNECTOR_STATE,
  'app.RCCB': DEFAULT_RESIDUAL_CURRENT_CIRCUIT_BREAKER_STATE,
  'app.BUSDUCT': DEFAULT_BUSWAYS_STATE,
  'app.TEXTAREA': DEFAULT_TEXT_AREA_STATE,
  'app.LINE': DEFAULT_LINE_STATE,
  'app.UPS': DEFAULT_UPS_STATE,
  'app.UPS_2_INPUT': DEFAULT_UPS_2_INPUT_STATE,
  'app.UPS_BYPASS': DEFAULT_UPS_BYPASS_STATE,
  'app.UPS_NOBYPASS': DEFAULT_UPS_NOBYPASS_STATE,
  'app.CAPACITORBANKS': DEFAULT_CAPACITOR_BANK_STATE,
  'app.CURVE_DIAGRAM': DEFAULT_CURVE_DIAGRAM_STATE,
  'app.ATS': DEFAULT_ATS_STATE,
  // TUs
  'app.CONNECTOON': DEFAULT_CONNECTOON_STATE,
  'app.BME_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BME_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DRC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DRC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DRS_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DRS_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.HBC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.HBC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.HBS_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.HBS_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RLC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RLC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RRC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RRC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC_W_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC_W_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC_W': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBM': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBR_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBR_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBS_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBS_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBS_W_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  // 'app.SDC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDM': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDS_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDS_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SFC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SFS_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SFV': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.TRAFOENCLOS': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.UMP': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.WBC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.WBC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.WBS_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.WBS_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.CIRCUITBREAKERMV': DEFAULT_MV_CIRCUIT_BREAKER_STATE,
  'app.CIRCUITBREAKERMVW': DEFAULT_MV_CIRCUIT_BREAKER_W_STATE,
  'app.CIRCUITBREAKERMV_GENERIC': { ...DEFAULT_MV_CIRCUIT_BREAKER_STATE, symbol: 'CIRCUITBREAKERMV_GENERIC' },
  'app.MVDISC': DEFAULT_MV_DISONNECTOR_STATE,
  'app.MVEDISC': DEFAULT_MV_EARTH_DISONNECTOR_STATE,
  'app.MVDISC_DOWN': DEFAULT_MV_DOWN_DISONNECTOR_STATE,
  'app.FUSEMV': DEFAULT_MV_FUSE_STATE,
  'app.MVSWITCH': DEFAULT_MV_SWITCH_DISONNECTOR_STATE,
  'app.WIREMV_IN': DEFAULT_MV_CABLE_STATE,

  'app.HBC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DRC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RLC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RRC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.WBC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BME': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SDC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.SBC.svg': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.INCOMING_DRC': DEFAULT_MV_TYPICAL_UNIT_STATE,

  'app.IF_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.M': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RM_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RM_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.MULTIMETER': DEFAULT_PROBE_STATE,

  'app.ZS2_BT_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.ZS2_BT_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.ZS2_IF_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.ZS2_IF_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.ZS2_M': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.ZS2_RM_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.ZS2_RM_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,

  // generics TUs
  'app.INCOMING_DIR': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.INCOMING_FIXCBD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.INCOMING_WITHCBD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.INCOMING_REMCBD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.INCOMING_D': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.INCOMING_WITHCB': DEFAULT_MV_TYPICAL_UNIT_STATE,

  'app.OUTGOING_DIR': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.OUTGOING_FIXCBD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.OUTGOING_WITHCBD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.OUTGOING_REMCBD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.OUTGOING_D': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.OUTGOING_FUXD': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.OUTGOING_WITHCB': DEFAULT_MV_TYPICAL_UNIT_STATE,

  'app.BUSTIE_FIXCBD_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BUSTIE_WITHCBD_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BUSTIE_REMCBD_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BUSTIE_D_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BUSTIE_FUXD_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BUSTIE_WITHCB_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RISER_LEFT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.RISER_RIGHT': DEFAULT_MV_TYPICAL_UNIT_STATE,

  'app.MEASUREMENT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.MEASUREMENT_SFV': DEFAULT_MV_TYPICAL_UNIT_STATE,
  // generic typical units end

  // safering
  'app.C': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.F': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.V': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DE': DEFAULT_MV_TYPICAL_UNIT_STATE,

  'app.C_LIN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.C_INT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.C_RIN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.F_RIN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.V_RIN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.V_INT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.F_INT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.DE_LIN': DEFAULT_MV_TYPICAL_UNIT_STATE,

  'app.DOC_CURVES': DEFAULT_CURVE_DIAGRAM_STATE,
  'app.FEEDER': DEFAULT_CB_FEEDER_STATE,
  'app.TEXTLINK': DEFAULT_LINK_STATE,
  'app.MVDISCFUSE': {},
  'app.MVSWITCHFUSE': {},

  'app.TYPICAL_FEEDER': DEFAULT_TYPICALFEEDER_STATE,
  // 'app.TYPICAL_BREAKER_MOTOR': DEFAULT_TYPICALFEEDER_STATE,
  // 'app.TYPICAL_BREAKER_LOAD': DEFAULT_TYPICALFEEDER_STATE,

  'app.IF_COMPACT_SF6_IEC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_ECO_IEC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_SF6_IND_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_ECO_IND_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_SF6_IEC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_ECO_IEC_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_SF6_IND_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_ECO_IND_IN': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_SF6_IEC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_ECO_IEC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_SF6_IND_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_COMPACT_ECO_IND_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_SF6_IEC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_ECO_IEC_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_SF6_IND_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.IF_STANDARD_ECO_IND_OUT': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_BR_SF6_IEC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_BR_ECO_IEC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_BR__SF6_IEC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_BR__ECO_IEC': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_RIGHT_SF6_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_RIGHT_ECO_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BR_RIGHT_SF6_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BR_RIGHT_ECO_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_LEFT_SF6_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BT_LEFT_ECO_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BR_LEFT_SF6_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.BR_LEFT_ECO_IND': DEFAULT_MV_TYPICAL_UNIT_STATE,
  // Lv macros
  'app.LVCB_EL_F': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_EL_W': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_MORC_F': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_MORC_W': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_MO_F': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_MO_W': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_TMRC_F': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_TMRC_W': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_TM_F': DEFAULT_LV_MACRO_STATE,
  'app.LVCB_TM_W': DEFAULT_LV_MACRO_STATE,
  'app.LVDISC_F': DEFAULT_LV_MACRO_STATE,
  'app.LVDISC_W': DEFAULT_LV_MACRO_STATE,
  'app.LVRCD_F': DEFAULT_LV_MACRO_STATE,
  'app.LVCABLE': DEFAULT_LV_MACRO_STATE,

  // auxillariesMacros
  'app.AUX_INDICATOR': DEFAULT_LV_MACRO_STATE,
  'app.AUX_CT': DEFAULT_LV_MACRO_STATE,
  'app.AUX_DAYLIGHT_SWITCH': DEFAULT_LV_MACRO_STATE,
  'app.AUX_OVR': DEFAULT_LV_MACRO_STATE,
  'app.AUX_PLUG': DEFAULT_LV_MACRO_STATE,
  'app.AUX_TIME_ASTRO_SWITCH': DEFAULT_LV_MACRO_STATE,
  'app.AUX_TIME_SWITCH': DEFAULT_LV_MACRO_STATE,
  'app.AUX_HIGH_POWER_METER': DEFAULT_LV_MACRO_STATE,
  'app.AUX_MULTIMETER': DEFAULT_LV_MACRO_STATE,

  // auxillaries load
  'app.LOAD_CT': DEFAULT_LOAD_STATE,
  'app.LOAD_DAYLIGHT_SWITCH': DEFAULT_LOAD_STATE,
  'app.LOAD_INDICATOR': DEFAULT_LOAD_STATE,
  'app.LOAD_OVR': DEFAULT_LOAD_STATE,
  'app.LOAD_PLUG': DEFAULT_LOAD_STATE,
  'app.LOAD_TIME_ASTRO_SWITCH': DEFAULT_LOAD_STATE,
  'app.LOAD_TIME_SWITCH': DEFAULT_LOAD_STATE,

  // auxillaries impedances
  'app.AUX_IMP_HIGH_POWER_METER': DEFAULT_IMPEDANCE_STATE,
  'app.IMPEDANCE_AUX_HIGH_POWER_METER': DEFAULT_IMPEDANCE_STATE,

  // loose component
  'app.LOOSE_D': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.LOOSE_DF': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.LOOSE_FB': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.LOOSE_M': DEFAULT_MV_TYPICAL_UNIT_STATE,
  'app.LOOSE_WB': DEFAULT_MV_TYPICAL_UNIT_STATE,
};
