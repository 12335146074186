/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from 'i18next';
import { batch } from 'react-redux';
import { updateElementParams, deleteElementParams, updateAllElementsParams } from 'devices/actions';
import { CURVES } from 'types/constants.d';
import * as CurvesNamespace from 'types/curves';
import { ICurvesThunkActions } from 'types/curves/actions';
import { EDeviceObjectType, EDeviceType } from 'types/devices/enums.d';
import { ThunkAction } from 'types/known-actions';
import { Device } from 'types/devices/device';
import { ApplicationState } from 'types/store';
import _ from 'lodash';
import { createAction } from 'redux-actions';
import { IGetMvMacroResponse, IRequestQueue, IScheme } from 'types/compute';
import { ICurvesResponse } from 'types/api/curves';
import { dependentAPIRequestIsRunning, pushNotification, runRequestFromQueue } from 'compute/actions';
import { isGetMvMacroResponse } from 'types/curves/type-guards';
import { IJsonOutputMessage, IMessage } from 'types/project/messages';
import buildNetwork from 'project/buildNetwork';
import CurvesAPI from 'api/curves';
import { ICurveDiagram, ICurveSettingPath } from 'types/curves';
import { base64ToBlob, getDeviceObjectId, getSelectedId, isFeeder } from 'actions/utils';
import { deviceHasCurves, DEFAULT_CURVES_SETTINGS_PARAMS } from 'curves/Constants';
import {
  setCurvesWarnings,
  setCurvesErrors,
  setCurvesNotifications,
  copyToCurvesMessages,
  showTimesFromResponse,
} from 'project/actions/projectMessages';
import { KonvaEventObject } from 'konva/lib/Node';
import generateSvg from 'actions/konvaToSvg';
import { getObjectSign } from 'store/devices/utils';
// import {
//   setCurvesWarnings,
//   setCurvesErrors,
//   setCurvesNotifications,
//   showTimesFromResponse,
//   copyToCurvesMessages,
// } from 'actions/projectMessages';
// import { getObjectSign } from 'reducers/devices/utils';
// import { dependentAPIRequestIsRunning, runRequestFromQueue, pushNotification } from 'compute/actions';

export const curvesActions = {
  setCurrentCurves: createAction<CurvesNamespace.Actions['setCurrentCurves']['payload']>(CURVES.SET_CURRENT_CURVES),
  setCurrentCurvesDirty: createAction<CurvesNamespace.Actions['setCurrentCurvesDirty']['payload']>(
    CURVES.SET_CURRENT_CURVES_DIRTY
  ),
  setCurvesRequestRunning: createAction<CurvesNamespace.Actions['setCurvesRequestRunning']['payload']>(
    CURVES.SET_CURVES_REQUEST_RUNNING
  ),
  setCustomCurves: createAction<CurvesNamespace.Actions['setCustomCurves']['payload']>(CURVES.SET_CUSTOM_CURVES),
  setCurvesControls: createAction<CurvesNamespace.Actions['setCurvesControls']['payload']>(CURVES.SET_CURVES_CONTROL),
  setSelectedCurveId: createAction<CurvesNamespace.Actions['setSelectedCurveId']['payload']>(CURVES.SET_SELECTED_CURVE),
  setCheckedCurves: createAction<CurvesNamespace.Actions['setCheckedCurves']['payload']>(CURVES.SET_CHECKED_CURVES),
};

export const setSelectedCurveId: ICurvesThunkActions['setSelectedCurveId'] = (curveDeviceId) => (dispatch, getState) => {
  dispatch(curvesActions.setSelectedCurveId({ curveId: curveDeviceId }));
};

export const setCurvesDiagram: ICurvesThunkActions['setCurvesDiagram'] = (diagram) => (dispatch, getState) => {
  const state = getState();
  let { selectedDeviceId } = state.project;
  const selectedDevice = state.devices[selectedDeviceId ?? ''];
  if (isFeeder(selectedDevice.deviceType)) {
    if (selectedDevice.objectType === EDeviceObjectType.MVTypicalUnit) {
      selectedDeviceId =
        dispatch(getSelectedId(EDeviceType.WIREMV)) ||
        dispatch(getSelectedId(EDeviceType.CIRCUITBREAKERMV)) ||
        dispatch(getSelectedId(EDeviceType.CIRCUITBREAKERMVW));
    } else {
      selectedDeviceId = dispatch(getSelectedId(EDeviceType.WIRELV));
    }
  }
  if (selectedDeviceId) {
    dispatch(updateElementParams(selectedDeviceId, { diagram }, true));
  }
};

export const setCurveLabelText: ICurvesThunkActions['setCurveLabelText'] = (id, objectSign, text) =>
  updateElementParams(
    id,
    {
      [`curveLabelText${objectSign}`]: text,
    },
    true
  );

export const setCurveLabelTextChecked: ICurvesThunkActions['setCurveLabelTextChecked'] = (id, objectSign, checked) =>
  updateElementParams(
    id,
    {
      [`curveLabelTextChecked${objectSign}`]: checked,
    },
    true
  );

export const setCurvesOfProtectedDevicesChecked: ICurvesThunkActions['setCurvesOfProtectedDevicesChecked'] = (id, checked) =>
  updateElementParams(
    id,
    {
      curvesOfProtectedDevicesChecked: checked,
    },
    true
  );

export const setCurveCurrentChecked: ICurvesThunkActions['setCurveCurrentChecked'] =
  (id, curveCurrent, checked) => (dispatch, getState) => {
    const selectedDevice = getState().devices[id];
    let showData: Record<string, unknown>;
    if (!selectedDevice[curveCurrent.storeAt[0]]) {
      showData = _.cloneDeep({
        [curveCurrent.storeAt[0]]: { [curveCurrent.storeAt[1]]: [{ '@': { id: curveCurrent.attribute }, '#': checked }] },
      });
    } else {
      showData = _.cloneDeep({ [curveCurrent.storeAt[0]]: selectedDevice[curveCurrent.storeAt[0]] });
      const dataIndex = (
        (showData[curveCurrent.storeAt[0]] as Record<string, unknown>).show as Array<ICurveSettingPath>
      ).findIndex((sh) => sh['@'] && sh['@'].id === curveCurrent.attribute);

      if (dataIndex !== -1) {
        showData = _.cloneDeep({
          [curveCurrent.storeAt[0]]: { [curveCurrent.storeAt[1]]: [{ '@': { id: curveCurrent.attribute }, '#': checked }] },
        });
      } else {
        (
          (showData[curveCurrent.storeAt[0]] as Record<string, unknown>)[curveCurrent.storeAt[1]] as Array<ICurveSettingPath>
        ).push({
          '@': { id: curveCurrent.attribute },
          '#': checked,
        });
      }
    }
    dispatch(updateElementParams(id, showData, true));
  };

export const getDeviceCurves: ICurvesThunkActions['getDeviceCurves'] =
  (force = false, fromTest = false) =>
  (dispatch, getState) => {
    const state = getState();
    const request: IRequestQueue = { name: 'deviceCurves', func: _.partial(getDeviceCurves, force, fromTest) };
    if (dispatch(dependentAPIRequestIsRunning(request))) {
      return;
    }
    const counter = state.curves.curvesRequestCounter + 1;
    let { selectedDeviceId } = state.project;
    if (!selectedDeviceId || !state.devices[selectedDeviceId]) {
      return;
    }

    const selectedDevice = state.devices[selectedDeviceId];
    const isTypicalUnit = selectedDevice.objectType === EDeviceObjectType.MVTypicalUnit;
    const hasCurves = deviceHasCurves(selectedDevice.deviceType) || isTypicalUnit;
    // TODO: Add || state.curves.curvesRequestRunning && !fromTest
    if (!hasCurves) {
      return;
    }
    dispatch(curvesActions.setCurvesRequestRunning({ running: true, counter }));

    const requestBody = _.cloneDeep(buildNetwork(state));
    if (!requestBody) {
      console.warn('No request body from build network (in action/curves)');
      dispatch(curvesActions.setCurvesRequestRunning({ running: false, counter }));
      return;
    }

    if (isFeeder(selectedDevice.deviceType) || isTypicalUnit) {
      selectedDeviceId = isTypicalUnit
        ? dispatch(getSelectedId(EDeviceType.WIREMV)) ||
          dispatch(getSelectedId(EDeviceType.CIRCUITBREAKERMV)) ||
          dispatch(getSelectedId(EDeviceType.CIRCUITBREAKERMVW)) ||
          dispatch(getSelectedId(EDeviceType.MVSWITCH)) ||
          dispatch(getSelectedId(EDeviceType.FUSEMV))
        : dispatch(getSelectedId(EDeviceType.WIRELV));
    }
    if (!selectedDeviceId) {
      dispatch(curvesActions.setCurvesRequestRunning({ running: false, counter }));
      return;
    }
    requestBody.variables.WEBDOC_CURRENT_DEVICE = selectedDeviceId;
    prepareCheckedCurves(state, requestBody, selectedDeviceId);

    CurvesAPI.curvesParams(requestBody)
      .then((response) => {
        if (response.stderr) {
          // fallback
          dispatch(curvesActions.setCurvesRequestRunning({ running: false }));

          window.docCaptureBackendError('CurvesAPI.curvesParams', response.stderr);
          return;
        }
        dispatch(processCurvesResponse(response, counter, selectedDeviceId));
      })
      .catch((error) => {
        console.error(error);
        dispatch(pushNotification('alarm', 'DOCWEB_ERROR_CURVESREQUEST'));
      });
  };

const prepareCheckedCurves = (state: ApplicationState, requestBody: IScheme, selectedDeviceId: string) => {
  const selectedDeviceObjectId = state.devices[selectedDeviceId].ObjectId;
  const checkedCurves = _.cloneDeep(state.curves.checkedCurves);
  const index = checkedCurves.findIndex((objectIdString) => objectIdString === selectedDeviceObjectId);
  if (index !== -1) {
    checkedCurves.splice(index, 1);
  }
  requestBody.scheme[selectedDeviceId].checkedCurves = checkedCurves;
};

export const resetCurvesSettings: ICurvesThunkActions['resetCurvesSettings'] = (selectedCurveDeviceId) => (dispatch) => {
  batch(() => {
    dispatch(deleteElementParams(selectedCurveDeviceId, DEFAULT_CURVES_SETTINGS_PARAMS, true));
    dispatch(getDeviceCurves());
  });
};

const parseCurvesNotifications: ICurvesThunkActions['parseCurvesNotifications'] = (curves, response) => (dispatch) => {
  // filtering notifications
  // include only notifications which objectId belongs to jsonOutput[].id
  // filtering by notifications.id which is like "CurveCheck.<ID1>.<ID2>.<someNumber>
  // check if ID1 or ID2 is equal to any object ID of devices mentioned in jsonOutput
  if (curves === null) {
    return undefined;
  }
  const curvesDevices: Array<string> = [];
  if (curves) {
    curves.forEach((item) => {
      if (item !== null && item.id) {
        item.id.split('\n').forEach((devId) => {
          const objectId = dispatch(getDeviceObjectId(devId));
          if (objectId) {
            curvesDevices.push(objectId);
          }
        });
      }
    });
    const curvesNotifications: Array<IJsonOutputMessage> = [];
    if (response.results.notifications !== undefined) {
      response.results.notifications.forEach((notification) => {
        curvesDevices.forEach((deviceObjectId) => {
          if (
            (deviceObjectId === notification.id.split('.')[1] || deviceObjectId === notification.id.split('.')[2]) &&
            !curvesNotifications.some((notif) => notif.id === notification.id)
          ) {
            curvesNotifications.push(notification);
          }
        });
      });
    }
    return curvesNotifications;
  }
  return undefined;
};

const structurizeMessages = (messages: Array<IJsonOutputMessage>, getState: () => ApplicationState): Array<IMessage> => {
  const { devices } = getState();
  // Parse messages
  const newNotifications = messages.map((item) => {
    const messagePars: Record<string, { param: Array<string>; key: number }> = {};
    if (item.pars) {
      item.pars.forEach((parameter, key) => {
        messagePars[`value[${key}]`] = { param: item.pars, key };
      });
    }
    return {
      id: item.handle,
      title: getObjectSign(devices[item.handle]),
      message: i18next.t(item.message.substr(1), messagePars),
      messageId: item.id,
      diagram: item.data?.pars[1],
      settingId: item.data?.pars[0],

      // for sorting curves notifications
      deviceId1: item.id.split('.')[1],
      deviceId2: item.id.split('.')[2],
    };
  });
  return newNotifications;
};

export const getCurveDiagramFromName = (curve2Show: Array<ICurveDiagram>, diagramName: string): number | null => {
  const filterDiagram = curve2Show.filter((curve) => curve.name === diagramName);
  const diagram = filterDiagram[0] ? filterDiagram[0].curveType : null;
  return diagram;
};

const processCurvesMessages =
  (newMessages: IJsonOutputMessage[], notifications: IJsonOutputMessage[], curvesMessages: IMessage[]): ThunkAction<IMessage[]> =>
  (dispatch, getState) => {
    const newCurvesMessages = [...curvesMessages];
    const structurizedMessages = structurizeMessages(newMessages, getState);
    // If the same message => replace it, if new => add
    if (structurizedMessages.length > 0 && structurizedMessages[0]) {
      structurizedMessages.forEach((newMessage) => {
        let isSameMessage = false;
        for (let i = 0; i < newCurvesMessages.length; i += 1) {
          if (newMessage.messageId === newCurvesMessages[i].messageId && newMessage.id === newCurvesMessages[i].id) {
            isSameMessage = true;
            newCurvesMessages[i] = newMessage;
            break;
          }
        }
        if (!isSameMessage) {
          newCurvesMessages.push(newMessage);
        }
      });
    }
    // if in new curvesMessages we don't have old message related to current element curve we need to delete it
    newCurvesMessages.forEach((oldMessage, index) => {
      if (
        notifications &&
        notifications.some((notification) => notification.id === oldMessage.messageId && notification.handle === oldMessage.id)
      ) {
        newCurvesMessages.splice(index, 1);
      }
    });
    return newCurvesMessages;
  };

export const processCurvesResponse =
  (response: ICurvesResponse | IGetMvMacroResponse, counter?: number, selectedDeviceId?: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const fromTest = !window.app;
    const curvesState = state.curves;
    const projectState = state.project;
    // This is path where is curves in response
    // Naming'curves' is for GetMV api
    const counterCheck = !fromTest && !isGetMvMacroResponse(response) ? counter === curvesState.curvesRequestCounter : true;
    if (isGetMvMacroResponse(response) || (curvesState.curvesRequestRunning && counterCheck)) {
      let curves: IGetMvMacroResponse['curves'] | null | ICurvesResponse['jsonOutput'] = null;
      const curvesDrag: null | ICurvesResponse['jsonCurvesDrag'] = response.jsonCurvesDrag as ICurvesResponse['jsonCurvesDrag'];
      if (isGetMvMacroResponse(response)) {
        curves = Array.isArray(response.curves) && response.curves.length > 0 ? response.curves : null;
      } else {
        curves = Array.isArray(response.jsonOutput) && response.jsonOutput.length > 0 ? response.jsonOutput : null;
      }
      const curve2Show: CurvesNamespace.ICurveDiagram[] | null =
        response.curve2Show && response.curve2Show.length > 0 ? response.curve2Show : null;
      const params =
        curves && response.jsonCurvesParams && Object.keys(response.jsonCurvesParams).length > 0
          ? response.jsonCurvesParams
          : null;
      let docxdata: Device | null = null;
      if (!isGetMvMacroResponse(response)) {
        docxdata = response.results && response.results.docxdata ? response.results.docxdata[selectedDeviceId ?? ''] : null;
      }
      if (params && docxdata && !isGetMvMacroResponse(response)) {
        dispatch(updateAllElementsParams(response.results.docxdata));
      }

      const selectedDevice = state.devices[selectedDeviceId ?? ''];
      if (selectedDevice) {
        dispatch(curvesActions.setCurrentCurves({ curves, params, curve2Show, curvesDrag }));
        if (curve2Show && !curve2Show.some((curve) => state.devices[selectedDeviceId ?? ''].diagram === curve.id)) {
          const lastDiagram = curve2Show[curve2Show.length - 1].id;
          const diagram =
            [EDeviceType.CIRCUITBREAKERMV, EDeviceType.CIRCUITBREAKERMVW, EDeviceType.CB_TM, EDeviceType.FUSEBASE].includes(
              state.devices[selectedDeviceId ?? ''].deviceType
            ) && lastDiagram !== 'PEAK'
              ? lastDiagram
              : curve2Show[0].id;
          dispatch(updateElementParams(selectedDeviceId ?? '', { diagram }, true));
        }
        dispatch(curvesActions.setCurvesRequestRunning({ running: false }));
        if (response.results && !isGetMvMacroResponse(response)) {
          const curvesWarningMessages = projectState.curvesWarnings;
          const curvesErrorMessages = projectState.curvesErrors;
          const curvesNotificationsMessages = dispatch(parseCurvesNotifications(curves, response));
          batch(() => {
            dispatch(
              setCurvesWarnings(
                dispatch(processCurvesMessages(response.results.warnings, response.results.notifications, curvesWarningMessages))
              )
            );
            dispatch(
              setCurvesErrors(
                dispatch(processCurvesMessages(response.results.errors, response.results.notifications, curvesErrorMessages))
              )
            );
            if (curvesNotificationsMessages) {
              dispatch(setCurvesNotifications(structurizeMessages(curvesNotificationsMessages, getState)));
            }
            dispatch(copyToCurvesMessages(response.results));
          });
        }
      }
    }
    if (response.results && !isGetMvMacroResponse(response)) {
      showTimesFromResponse(response.results.console);
    }
    dispatch(runRequestFromQueue());
  };

export const zoomCurvesOnClick = (out = false) => {
  const evt = document.createEvent('MouseEvents') as MouseEvent & { deltaY: number };
  evt.initEvent('wheel', true, true);
  if (out) {
    evt.deltaY = 1;
  } else {
    evt.deltaY = -1;
  }
  const wheelEvent = {
    evt,
    currentTarget: window.miniCurves.stage,
    type: 'contentWheel',
  } as unknown as KonvaEventObject<WheelEvent>;
  window.miniCurves.onContentWheel(wheelEvent, true);
};

export const copySvgCurveDiagramToClipboard = async (svgFormat = false) => {
  if (svgFormat) {
    const svg = generateSvg(window.miniCurves.width, window.miniCurves.height);

    const placeholder = document.createElement('textarea');
    document.body.appendChild(placeholder);
    placeholder.value = svg;
    placeholder.select();
    try {
      document.execCommand('copy');
      console.log('Copied to clipboard!');
    } catch (err) {
      console.log(`Cannot copy...\n${err as string}`);
    }
    document.body.removeChild(placeholder);
  } else {
    await window.miniCurves.stage.toImage({
      x: 0,
      y: 0,
      width: window.miniCurves.stage.width(),
      height: window.miniCurves.stage.height(),
      pixelRatio: 2,
      callback(img) {
        // For test
        // const popup = window.open();
        // popup?.document.write(img.outerHTML);
        // popup?.print();
        // document.body.appendChild(a);
        try {
          navigator.clipboard
            .write([
              new ClipboardItem({
                'image/png': base64ToBlob(img.src),
              }),
            ])
            .catch((err) => console.error(err));
        } catch (error) {
          console.error(error);
        }
      },
    });
  }
};
