/* eslint-disable import/no-anonymous-default-export */
import { initUser } from 'actions/user';

import { initApp } from 'actions/app';
import { ThunkAction } from 'types/known-actions';

// import {
//   fetchScStandards,
// } from 'standards/actions';

export default (): ThunkAction<void> => (dispatch, getState) => {
  return Promise.all([dispatch(initUser()), dispatch(initApp())]);
};
