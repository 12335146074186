import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'MOTORYD',
  UserDescription1: '',
  objectType: EDeviceObjectType.Motor,
  deviceType: EDeviceType.MOTOR,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  Uref: 400,
  frequency: 50,
  Uf: 1,
  VoltageDropMaX: 4,
  NominalVoltage: 400,
  Pn: 30000,
  Eta: 0.851,
  CosPhiNominal: 0.86,
  CosPhiRequested: 0.86,
  IlrInRatio: 7,
  I0InRatio: 0.2,
  PolesNumber: 1,
  Rr: 3.65,
  Xr: 6.3,
  Rs: 3.65,
  Xs: 6.3,
  RatedPower: 35252.6,
  ProductDescription: 'M2BA  100 L2 A',
  LoadCurrent: 59.166,
  ___RM: 7.3,
  ___XM: 12.6,
  ___RMXM: 0.579365,
  ___Q: 0,
  StarterType: 'SD',
  StarterVariant: 'Normal',
  CoordinationType: 'Type1',
  IzIbRatio: 3,

  //
  IStart: 41.4162396256871,
  tStart: 10,
  CmCnRatio: 2.2,
  CstartCnRatio: 2.2,
  CosPhiStart: 0.15,
  J: 0.004,
  MotorSpeed: 2870,

  // Motor coordination properties
  MotorFeederStarterTypeFilter: 'All possibilities',
  MotorFeederCoordinationTypeFilter: 'All possibilities',
  MotorFeederProtectionFilter: 'All possibilities',
  MotorFeederOlrFilter: 'All possibilities',
  motorEfficiencyClass: 'All possibilities',
  MotorFeederContactorFilter: 'All possibilities',
  MotorFeederSymbols: [],
  motorSymbol: 'MOTORYD',

  motorList: [],
  userList: [],
  importErrorList: [],
  importError: '',
};
