export enum ECustomCurveOption {
  Curve = 'Curve',
  AreaUp = 'Area Up',
  AreaLow = 'Area Low',
}

export enum ECurvesFilters {
  All = 'All project devices',
  Custom = 'Custom devices',
}
