/* eslint-disable no-param-reassign */
import Konva from 'konva';
import Grid from 'curves/Grid';
import CurveTypeIndex from 'curves/curveType/CurveTypeIndex';
import CurveTypeRange from 'curves/curveType/CurveTypeRange';
import GridSizeConfig from 'curves/GridSizeConfig';
import Hatch from 'curves/shapes/Hatch';
import Line from 'curves/shapes/Line';
import CurrentLine from 'curves/shapes/CurrentLine';
import PeakPerspectiveLine from 'curves/shapes/PeakPerspectiveLine';
import GridLabels from 'curves/GridLabels';
import CurveTypes from 'curves/curveType';
import {
  visibleDiagrams,
  zoomFactor,
  maxZoom,
  minZoom,
  GRID_OFFSET_X,
  GRID_OFFSET_Y,
  DEFAULT_GRID_BORDER_CONFIG,
  ABB_GREY_255,
  customCurvesCurveType,
  customCurvesDiagrams,
  CURRENT_CURVE_LINE_DASH,
} from 'curves/Constants';
// import { abbGrey255 } from 'styles/colors.scss';
import { Device } from 'types/devices/device';
import { ICustomCurve } from 'types/curves';
import { State as CurvesState } from 'types/curves/index';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { getCurveColor, getCurveLabelText } from './actions/utils';
import { getCurrentCheckValue } from './actions/functionValues';
import { drawLegend } from './curvesLegend';
import { drawCustomCurve } from './customCurve';
import { drawDragCurve } from './actions/drag';

const getVisibleMiddlePoint = (curveType: CurveTypes, points: Array<Array<{ x: number; y: number }>>) => {
  const range = CurveTypeRange[curveType as keyof typeof CurveTypeRange];
  const minX = Math.log10(range.minX / range.originX);
  const maxX = Math.log10(range.maxX / range.originX);
  const minY = Math.log10(range.minY / range.originY);
  const maxY = Math.log10(range.maxY / range.originY);
  let visibleShapePoints;
  if (points[1].length > 0) {
    visibleShapePoints = findMiddlePoint(points[1], minX, maxX, minY, maxY);
  } else {
    for (let i = 0; i < points.length; i += 1) {
      const shapePoints = points[i];
      if (shapePoints.length > 0) {
        const visiblePoints = findMiddlePoint(shapePoints, minX, maxX, minY, maxY);
        if (visiblePoints.length > 0) {
          visibleShapePoints = visiblePoints;
          break;
        }
      }
    }
  }
  if (visibleShapePoints) {
    const index = Math.floor(visibleShapePoints.length / 2);
    return visibleShapePoints[index];
  }
  return false;
};

const findMiddlePoint = (
  shapePoints: Array<{ x: number; y: number }>,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number
): Array<{ x: number; y: number }> => {
  const visiblePoints = new Array<{ x: number; y: number }>();
  shapePoints.forEach((point) => {
    if (point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY) {
      visiblePoints.push(point);
    }
  });
  return visiblePoints;
};

export default class Curves {
  width: number;

  height: number;

  scale: number;

  stage: Konva.Stage;

  curvesLayer: Konva.Layer;

  labelsLayer: Konva.Layer;

  currentLabelsLayerX: Konva.Group;

  progressCircleLayer: Konva.Layer;

  progressCircleAnimation: Konva.Animation;

  labelsLayerX: GridLabels;

  labelsLayerY: GridLabels;

  sizeConfig: GridSizeConfig;

  devices: Record<string, Device>;

  curves: CurvesState;

  userCurves?: ICustomCurve;

  selectedDeviceId: string;

  prevScreenX: number;

  prevScreenY: number;

  tabletPointer: { x: number; y: number };

  ourZoomPosition: { x: number; y: number } | null;

  touchDistance: number;

  curvesIsDrawing: number;

  dragCurveIndex: number;

  diagramPrint: string | boolean;

  legendCoordinates: { x: number; y: number };

  constructor(container: HTMLDivElement, width: number, height: number) {
    window.miniCurves = this;
    this.width = width;
    this.height = height;
    this.scale = 1.0;
    this.stage = new Konva.Stage({
      container,
      width: this.width,
      height: this.height,
    });
    this.tabletPointer = { x: 0, y: 0 };
    this.touchDistance = 0;
    // console.log('Curves init width:', this.width, this.height);

    this.curvesLayer = new Konva.Layer();
    this.stage.add(this.curvesLayer);

    this.labelsLayer = new Konva.Layer();
    this.stage.add(this.labelsLayer);

    // bind event handlers
    this.stage.on('wheel', this.onContentWheel.bind(this));
    this.stage.on('mousemove', this.onContentMousemove.bind(this));
    this.stage.on('contextmenu', (konvaEvt) => {
      konvaEvt.evt.preventDefault();
    });
    this.stage
      .getContent()
      .addEventListener('touchmove', (event) => this.onTouchMove(event as TouchEvent & { movementY: number; movementX: number }));
    this.stage.getContent().addEventListener('touchstart', (event) => this.onTouchStart(event));
    this.stage.getContent().addEventListener('touchend', (event) => this.onTouchEnd(event));
    this.stage.on('mousedown', (evt) => {
      let mouseDownButton = evt.evt.buttons;
      if (mouseDownButton === 4) {
        this.stage.on('mousemove', (evtM) => {
          this.onContentMousemove(evtM);
        });
        this.stage.on('mouseup', () => {
          mouseDownButton = -1;
          this.stage.removeEventListener('mousemove');
          this.stage.removeEventListener('mouseup');
        });
      }
    });

    this.drawCurves();
  }

  onTouchStart(event: TouchEvent): void {
    event.preventDefault();
    this.prevScreenX = event.touches[0].pageX;
    this.prevScreenY = event.touches[0].pageY;
  }

  onTouchEnd(event: TouchEvent): void {
    if (event.touches.length < 2) {
      this.tabletPointer = { x: 0, y: 0 };
      this.touchDistance = 0;
    }
  }

  onTouchMove(event: TouchEvent & { movementY: number; movementX: number }): void {
    event.preventDefault();
    if (event.touches.length === 1) {
      if (event.movementX === undefined || event.movementY === undefined) {
        if (this.prevScreenX === undefined) {
          this.prevScreenX = event.touches[0].pageX;
          this.prevScreenY = event.touches[0].pageY;
          return;
        }
        event.movementX = event.touches[0].pageX - this.prevScreenX;
        event.movementY = event.touches[0].pageY - this.prevScreenY;
        // TODO: Check because pageX and PageY are undefine, maybe use instead event.touches[0].pageY
        // this.prevScreenX = event.pageX;
        // this.prevScreenY = event.pageY;
      }

      event.preventDefault();
      const newPos = {
        x: this.curvesLayer.x() + event.movementX,
        y: this.curvesLayer.y() + event.movementY,
      };
      this.panLimit(newPos, this.scale);
      this.curvesLayer.x(newPos.x);
      this.curvesLayer.y(newPos.y);

      // Update X and Y of the labels
      this.labelsLayerX.x(newPos.x);
      this.currentLabelsLayerX.x(newPos.x);
      this.labelsLayerY.y(newPos.y);

      this.redraw();
    }
    if (event.touches.length === 2) {
      if (this.touchDistance === 0) {
        this.touchDistance = Math.hypot(
          event.touches[0].clientX - event.touches[1].clientX,
          event.touches[0].clientY - event.touches[1].clientY
        );
      }
      if (this.tabletPointer.x === 0 && this.tabletPointer.y === 0) {
        this.tabletPointer.x = (event.touches[0].clientX + event.touches[1].clientX) / 2;
        this.tabletPointer.y = (event.touches[0].clientY + event.touches[1].clientY) / 2;
      }

      const newTouchDistance = Math.hypot(
        event.touches[0].clientX - event.touches[1].clientX,
        event.touches[0].clientY - event.touches[1].clientY
      );
      const distanceDifference = newTouchDistance - this.touchDistance;
      if (distanceDifference < -10 || distanceDifference > 10) {
        /**
         * Compute new scale taking into account the min and max scale
         */
        const newScale = distanceDifference > 0 ? Math.min(maxZoom, this.scale * 1.1) : Math.max(minZoom, this.scale / 1.1);
        /**
         * Compute the new offset of the grid/curves
         * based on mouse position
         */
        const deltaScale = newScale / this.scale;

        const zoomPoint = this.tabletPointer;

        this.ourZoomPosition = zoomPoint;
        // zoomPoint.x -= GRID_OFFSET_X;
        const newPos = {
          x: zoomPoint.x - (zoomPoint.x - this.curvesLayer.x()) * deltaScale,
          y: zoomPoint.y - (zoomPoint.y - this.curvesLayer.y()) * deltaScale,
        };
        this.ourZoomPosition = newPos;
        // this.stage.position(newPos);
        this.panLimit(newPos, newScale);
        this.curvesLayer.x(newPos.x);
        this.curvesLayer.y(newPos.y);
        this.scale = newScale;
        this.touchDistance = newTouchDistance;
        this.drawCurves();
      }
    }
  }

  onResize(width: number, height: number): void {
    this.width = width;
    this.height = height;
    this.stage.width(this.width);
    this.stage.height(this.height);
  }

  /**
   * Limit the graph (newPosition) to the bound of the drawing scene
   * @param {*} newPosition The desired position, is adjusted in place
   */
  panLimit(newPosition: { x: number; y: number }, scale: number): void {
    if (newPosition.x > 0) {
      newPosition.x = 0;
    } else if (newPosition.x + this.sizeConfig.initialWidth * scale < this.sizeConfig.initialWidth) {
      newPosition.x = this.sizeConfig.initialWidth - this.sizeConfig.initialWidth * scale;
    }
    if (newPosition.y > 0) {
      newPosition.y = 0;
    } else if (newPosition.y + this.sizeConfig.initialHeight * scale < this.sizeConfig.initialHeight) {
      newPosition.y = this.sizeConfig.initialHeight - this.sizeConfig.initialHeight * scale;
    }
    if (this.curvesIsDrawing) {
      clearTimeout(this.curvesIsDrawing);
    }
    this.curvesIsDrawing = window.setTimeout(() => this.drawCurves());
  }

  onContentWheel(contentEvent: KonvaEventObject<WheelEvent>, onClick = false): void {
    const event = contentEvent.evt;
    event.preventDefault();
    /**
     * Compute new scale taking into account the min and max scale
     */
    const newScale = event.deltaY < 0 ? Math.min(maxZoom, this.scale * zoomFactor) : Math.max(minZoom, this.scale / zoomFactor);
    /**
     * Compute the new offset of the grid/curves
     * based on mouse position
     */
    const deltaScale = newScale / this.scale;
    let mouse;
    if (onClick) {
      mouse = {
        x: this.stage.width() / 2,
        y: this.stage.height() / 2,
      };
    } else {
      mouse = this.stage.getPointerPosition() as Vector2d;
    }
    this.ourZoomPosition = mouse;
    mouse.x -= GRID_OFFSET_X;
    if (mouse) {
      mouse.x -= GRID_OFFSET_X;
      const newPos = {
        x: mouse.x - (mouse.x - this.curvesLayer.x()) * deltaScale,
        y: mouse.y - (mouse.y - this.curvesLayer.y()) * deltaScale,
      };
      this.ourZoomPosition = newPos;
      // this.stage.position(newPos);
      this.panLimit(newPos, newScale);
      this.curvesLayer.x(newPos.x);
      this.curvesLayer.y(newPos.y);
      this.scale = newScale;
      this.drawCurves();
    }
  }

  onContentMousemove(contentEvent: KonvaEventObject<MouseEvent>): void {
    const event = contentEvent.evt;

    /**
     * Fix for IE not supporting event.movementX and event.movementY
     */
    let { movementX } = event;
    let { movementY } = event;
    if (event.movementX === undefined || event.movementY === undefined) {
      if (this.prevScreenX === undefined) {
        this.prevScreenX = event.screenX;
        this.prevScreenY = event.screenY;
        return;
      }
      movementX = event.screenX - this.prevScreenX;
      movementY = event.screenY - this.prevScreenY;
      this.prevScreenX = event.screenX;
      this.prevScreenY = event.screenY;
    }

    event.preventDefault();
    // perform pan if mouse wheel is pressed
    if (event.buttons === 4) {
      const newPos = {
        x: this.curvesLayer.x() + (movementX ?? 0),
        y: this.curvesLayer.y() + (movementY ?? 0),
      };
      this.panLimit(newPos, this.scale);
      this.curvesLayer.x(newPos.x);
      this.curvesLayer.y(newPos.y);

      // Update X and Y of the labels
      this.labelsLayerX.x(newPos.x);
      this.currentLabelsLayerX.x(newPos.x);
      this.labelsLayerY.y(newPos.y);

      this.redraw();
    }
  }

  redraw(): void {
    /**
     * Use batchDraw() instead of draw() for better performance
     * https://konvajs.github.io/docs/performance/Batch_Draw.html
     */
    this.stage.batchDraw();
  }

  drawCurrent(curvesGroup: Konva.Group, curveDevice: Device, point: number, legendGroup: Konva.Group): void {
    // Crash if delete device with opened curves and then click on another device (No curveDevice.ObjectSign)
    if (!curveDevice) {
      return;
    }
    const curveLabel = getCurveLabelText(curveDevice);
    let dash: number[] | undefined;
    const currents = curvesGroup.children?.filter((ch) => (ch as CurrentLine).curveShapeName === 'CurrentLine');
    if (currents && currents.length > 0) {
      CURRENT_CURVE_LINE_DASH.forEach((currentDash) => {
        if (
          !currents.some(
            (currentLine) =>
              (currentLine.attrs as { dash?: number[] }).dash &&
              (currentLine.attrs as { dash?: number[] }).dash?.length === currentDash.length
          )
        ) {
          dash = currentDash;
        }
      });
    }
    const line = new CurrentLine(point, this.sizeConfig, dash);
    // const cLabel = new CurrentLabel(point, this.sizeConfig, label);
    drawLegend(legendGroup, curveLabel.text, this.sizeConfig, curveDevice, dash || true, undefined, point);
    curvesGroup.add(line);
    // this.currentLabelsLayerX.add(cLabel);
  }

  drawCurves(): void {
    this.curvesLayer.destroyChildren();
    this.labelsLayer.destroyChildren();
    const curvesColor: Record<string, string> = {};

    if (this.selectedDeviceId) {
      const selectedDevice = this.devices[this.selectedDeviceId];
      const customCurveDevice = this.userCurves;
      let diagramCurve =
        this.curves.curve2Show && selectedDevice && selectedDevice.diagram
          ? this.curves.curve2Show.find((curve) => curve.id === selectedDevice.diagram)
          : null;
      if (!diagramCurve && this.curves.curve2Show) {
        [diagramCurve] = this.curves.curve2Show;
      }

      const curveType = customCurveDevice
        ? visibleDiagrams[customCurvesCurveType[customCurvesDiagrams.indexOf(customCurveDevice.selectedDiagram)]]
        : visibleDiagrams[diagramCurve ? diagramCurve.curveType : 0];

      if (!curveType) {
        return;
      }

      const width = this.width - GRID_OFFSET_X;
      const height = this.height - GRID_OFFSET_Y;
      const { scale } = this;
      // Current vertical line cannot be draw if X is less than - 10
      const minCurrentX = -10;

      const curvesGroup = new Konva.Group({ x: GRID_OFFSET_X, y: 0 });
      const legendGroup = new Konva.Group({ x: GRID_OFFSET_X, y: 0 });
      this.curvesLayer.add(curvesGroup);
      this.curvesLayer.add(legendGroup);
      legendGroup.on('dragend', (e) => {
        // console.log(e);
        if (e.target) {
          this.legendCoordinates = { x: Math.abs(e.target.x()), y: Math.abs(e.target.y()) };
        }
      });

      this.sizeConfig = new GridSizeConfig(width, height, scale, curveType);
      const grid = new Grid(this.sizeConfig);
      curvesGroup.add(grid);

      this.currentLabelsLayerX = new Konva.Group({
        x: this.curvesLayer.x(),
      });
      if (this.curves.curves && this.curves.curves[0] && this.curves.curve2Show && !customCurveDevice) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (curveType === CurveTypes.CL) {
          const peak = new PeakPerspectiveLine(this.sizeConfig);
          curvesGroup.add(peak);
        }
        // Display curves of protecting devices
        const curvesOfProtectedDevicesChecked = selectedDevice.curvesOfProtectedDevicesChecked !== false;
        const currentCurves = curvesOfProtectedDevicesChecked ? this.curves.curves : this.curves.curves.slice(0, 1);
        // draw curves
        if (diagramCurve) {
          const downstream = currentCurves[Math.min(...diagramCurve.pointsIndices)];
          for (let i = 0; i < diagramCurve.pointsIndices.length; i += 1) {
            const curve = currentCurves[diagramCurve.pointsIndices[i]];
            if (curve) {
              const points = curve.points[CurveTypeIndex[curveType]];
              const currents = curve.currents[CurveTypeIndex[curveType]];
              const curveDevice = this.devices[curve.id];
              if (points[0].length && points[1].length) {
                // downstream ? DEFAULT_CURVE_STROKE_COLOR : UPSTREAM_CURVE_COLOR;
                const hatch = new Hatch(
                  points[0],
                  points[1],
                  this.sizeConfig,
                  getCurveColor(curvesColor, curve.id, downstream.id === curve.id),
                  this.curvesLayer.x(),
                  this.curvesLayer.y(),
                  curve.id
                );
                curvesGroup.add(hatch);

                hatch.on('mouseenter', (e) => {
                  const eventDiagram = e.evt as MouseEvent & { layerX: number; layerY: number };
                  if (diagramCurve) {
                    drawDragCurve(hatch, eventDiagram, points[0], diagramCurve, curve.index, curvesGroup, curveType, curve.id);
                  }
                });
                // hatch.on('click', (e) => {
                // window.app.props.setSelectedCurveId(e.target.attrs.deviceId);
                // });
              } else if (points[1].length) {
                const line = new Line(
                  points[1],
                  this.sizeConfig,
                  true,
                  getCurveColor(curvesColor, curve.id, downstream.id === curve.id),
                  this.curvesLayer.x(),
                  this.curvesLayer.y(),
                  curve.id
                );
                curvesGroup.add(line);
                line.on('mouseenter', (e) => {
                  const eventDiagram = e.evt as MouseEvent & { layerX: number; layerY: number };
                  if (diagramCurve) {
                    drawDragCurve(line, eventDiagram, points[1], diagramCurve, curve.index, curvesGroup, curveType, curve.id);
                  }
                });
                // line.on('click', (e) => {
                //   // window.app.props.setSelectedCurveId(e.target.attrs.deviceId);
                // });
              }
              for (let l = 2; l < points.length; l += 1) {
                if (points[l].length) {
                  let line: Line;
                  let line2: Line | undefined;
                  if (points[l].length === 1) {
                    const intersection1 = [points[l][0]];
                    const intersection2 = [points[l][0]];
                    for (let j = 1; j < 3; j += 1) {
                      intersection1.unshift({ x: points[l][0].x - 0.1 * j, y: points[l][0].y - 0.1 * j });
                      intersection1.push({ x: points[l][0].x + 0.1 * j, y: points[l][0].y + 0.1 * j });
                      intersection2.unshift({ x: points[l][0].x - 0.1 * j, y: points[l][0].y + 0.1 * j });
                      intersection2.push({ x: points[l][0].x + 0.1 * j, y: points[l][0].y - 0.1 * j });
                    }
                    const colorX = getCurveColor(curvesColor, curve.id);
                    line = new Line(intersection1, this.sizeConfig, false, colorX, this.curvesLayer.x(), this.curvesLayer.y());
                    line2 = new Line(intersection2, this.sizeConfig, false, colorX, this.curvesLayer.x(), this.curvesLayer.y());
                  } else {
                    line = new Line(
                      points[i],
                      this.sizeConfig,
                      false,
                      getCurveColor(curvesColor, curve.id),
                      this.curvesLayer.x(),
                      this.curvesLayer.y()
                    );
                  }
                  curvesGroup.add(line);
                  if (line2) {
                    curvesGroup.add(line2);
                  }
                }
              }

              const curveCurrents = diagramCurve.currents2Show;
              // draw curve currents
              curveCurrents.forEach((current) => {
                const currentIndices = current.currents ? current.currents : null;
                const checked = getCurrentCheckValue(curveDevice, current);
                if (checked) {
                  if (currentIndices) {
                    currentIndices.forEach((ind) => {
                      const point = currents[ind];
                      if (point > minCurrentX) {
                        this.drawCurrent(curvesGroup, curveDevice, point, legendGroup);
                      }
                    });
                  } else {
                    const point = diagramCurve?.selectivityLevel;
                    if (point && point > minCurrentX && downstream.id === curve.id) {
                      this.drawCurrent(curvesGroup, curveDevice, point, legendGroup);
                    }
                  }
                }
              });
              // draw the curve label text
              const curveLabel = getCurveLabelText(this.devices[curve.id]);
              if (curveLabel.checked) {
                let curveLabelTextPos = getVisibleMiddlePoint(curveType, points);
                if (curveLabelTextPos && typeof curveLabelTextPos !== 'boolean') {
                  drawLegend(legendGroup, curveLabel.text, this.sizeConfig, this.devices[curve.id], false, curvesColor[curve.id]);
                  curveLabelTextPos = this.sizeConfig.adjustPoint(curveLabelTextPos);
                  let labelPositionX = curveLabelTextPos.x + 18;
                  let labelPositionY = curveLabelTextPos.y + i * 25;
                  if (this.sizeConfig.initialHeight < labelPositionY) {
                    labelPositionY -= 50;
                  }
                  if (this.sizeConfig.initialWidth < labelPositionX) {
                    labelPositionX -= 50;
                  }
                }
              }
            }
          }
        }
      }
      if (customCurveDevice && this.curves.customCurves.selectedDeviceId === 'new') {
        const customDiagram = customCurveDevice.selectedDiagram
          ? undefined
          : Object.keys(customCurveDevice.curves).find((diagramName) => customCurveDevice.curves[diagramName].points.length > 0);
        drawCustomCurve(customCurveDevice, curvesGroup, curvesColor, this, 'customCurve.id', customDiagram);
      } else if (
        this.curves.customCurves.devices &&
        this.curves.checkedCurves &&
        Object.keys(this.curves.customCurves.devices).length > 0
      ) {
        Object.keys(this.curves.customCurves.devices).forEach((customId) => {
          const customDevice = this.curves.customCurves.devices[customId];
          if (customDevice && this.curves.checkedCurves.includes(customId)) {
            const curveTypeKeyIndex = Object.values(CurveTypes).indexOf(curveType);
            const customCurveType = Object.keys(CurveTypes)[curveTypeKeyIndex];
            if (customDevice.curves[customCurveType === 'CL' ? 'PEAK' : customCurveType]) {
              drawCustomCurve(customDevice, curvesGroup, curvesColor, this, customId, customCurveType);
            }
          }
        });
      }
      this.drawGridBorder(grid, width, height);
      // window.app.setCurveSymbolColors(curvesColor);
    }

    this.redraw();
  }

  drawGridBorder(grid: Grid, width: number, height: number): void {
    /**
     * Draw the border of the grid
     * This is done outside Grid.js as it should not be scaled
     * It's important to do it after the grid is drawn
     * because the colors of the grid lines and curves can be different
     */
    const borderWidth = width;
    const borderHeight = height;
    [
      [0.5, 0, 0.5, borderHeight], // left edge
      [0, borderHeight - 0.5, borderWidth, borderHeight - 0.5], // bottom edge
      [borderWidth - 0.5, 0, borderWidth - 0.5, borderHeight], // right edge
      [0, 0.5, borderWidth, 0.5], // top edge
    ].forEach((points) => {
      const line = new Konva.Line({ ...DEFAULT_GRID_BORDER_CONFIG, x: GRID_OFFSET_X, points });
      this.labelsLayer.add(line);
    });
    this.labelsLayer.add(this.currentLabelsLayerX);

    this.labelsLayer.add(
      new Konva.Rect({
        x: 0,
        y: 0,
        width: GRID_OFFSET_X,
        height: this.height,
        fill: ABB_GREY_255,
      })
    );
    this.labelsLayer.add(
      new Konva.Rect({
        x: 0,
        y: this.height - GRID_OFFSET_Y,
        width: this.width,
        height: GRID_OFFSET_Y,
        fill: ABB_GREY_255,
      })
    );
    let labels = new GridLabels(
      {
        x: this.curvesLayer.x(),
      },
      grid,
      this.sizeConfig,
      this.width,
      this.height,
      true
    );
    this.labelsLayerX = labels;
    this.labelsLayer.add(this.labelsLayerX);
    labels = new GridLabels(
      {
        y: this.curvesLayer.y(),
      },
      grid,
      this.sizeConfig,
      this.width,
      this.height,
      false
    );
    this.labelsLayerY = labels;
    this.labelsLayer.add(this.labelsLayerY);

    this.labelsLayer.add(
      new Konva.Rect({
        x: 0,
        y: this.height - GRID_OFFSET_Y,
        width: GRID_OFFSET_X,
        height: GRID_OFFSET_Y,
        fill: ABB_GREY_255,
      })
    );

    if (this.progressCircleLayer) {
      this.progressCircleLayer.destroy();
    }
    if (this.curves.curvesRequestRunning) {
      this.curvesLayer.opacity(0.2);
      this.labelsLayer.opacity(0.2);

      this.progressCircleLayer = new Konva.Layer();
      const arc = new Konva.Arc({
        x: this.stage.width() / 2 + 25,
        y: this.stage.height() / 2 - 20,
        innerRadius: 20,
        outerRadius: 25,
        angle: 80,
        fill: '#36f',
        strokeWidth: 1,
      });

      this.progressCircleLayer.add(arc);
      this.stage.add(this.progressCircleLayer);

      const angularSpeed = 900;
      this.progressCircleAnimation = new Konva.Animation((frame) => {
        const timeDiff = frame?.timeDiff ? frame?.timeDiff : 1;
        const angleDiff = (timeDiff * angularSpeed) / 1000;
        arc.rotate(angleDiff);
      }, this.progressCircleLayer);

      this.progressCircleAnimation.start();
    } else {
      this.curvesLayer.opacity(1);
      this.labelsLayer.opacity(0.75);
      if (this.progressCircleLayer) {
        this.progressCircleAnimation.stop();
        this.progressCircleLayer.destroy();
      }
    }
  }
}
