/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Input from 'components/generic/inputContainer/Input';
import Radio from 'components/generic/Radio';
import { Button } from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import _ from 'lodash';
import GraphTab from 'curves/toolbar/GraphTab';
import DOCwebEditableSelect from 'components/generic/editableSelect';
import { ECustomCurveOption } from 'types/curves/enums.d';
import FileReaderInput from 'react-file-reader-input';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { GridColumn, GridRow } from 'components/generic/grid';
import { saveDevice, excelHandler, exportExcelDevice } from './actions';
import CustomCurvesDeviceExpanderTab from './ExpanderTab';
import { projectCommonData } from '../../../constants';
import { newDeviceState } from './constants';

const CustomCurvesParameters: React.FunctionComponent = (): React.ReactElement => {
  const deviceId = useSelector((state) => state.curves.customCurves.selectedDeviceId);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const device = useSelector((state) => state.curves.customCurves.devices[deviceId ?? ''], _.isEqual);
  const PlantLvUn = useSelector((state) => state.variables.PlantLvUn);
  const dispatch = useDispatch();

  const [voltage, setVoltage] = useState(400);
  const [deviceName, setDeviceName] = useState(device?.name ?? '');
  const [deviceState, setDeviceState] = useState(device);
  const { t } = useTranslation();

  useEffect(() => {
    if (deviceId === 'new') {
      setDeviceState(newDeviceState);
      if (PlantLvUn) {
        setVoltage(PlantLvUn);
      } else {
        setVoltage(400);
      }
    }
  }, []);

  const setDeviceOption = (option: ECustomCurveOption) => {
    const state = _.cloneDeep(deviceState);
    if (!state) return;
    state.option = option;
    setDeviceState(state);
  };

  const onLoadExcelHandler = (e: React.ChangeEvent<HTMLInputElement>, results: FileReaderInput.Result[]) => {
    dispatch(excelHandler(e, results, setDeviceState, setVoltage, setDeviceName));
  };

  const downloadTemplate = (): void => {
    axios
      .get('/excelTemplates/User Defined Curves Template.xlsx', {
        responseType: 'blob',
      })
      .then((file) => {
        fileDownload(file.data as Blob, 'User Defined Curves Template.xlsx');
      })
      .catch(console.warn);
  };

  const parameters = () => {
    if (!deviceState) return <></>;
    return (
      <>
        <GridRow>
          <GridColumn col={4}>
            <Input
              // className="labelBold"
              className="labelBold"
              label={t('DOCWEB_CUSTOMCURVESDEVICE_NAME')}
              cols={[8]}
              name="name"
              type="text"
              value={deviceName}
              onChange={(e) => setDeviceName(e.value)}
              placeholder={t('DOCWEB_CUSTOM_CURVES_NAME_PLACEHOLDER')}
            />
          </GridColumn>
          <GridColumn col={3} offset={1}>
            <DOCwebEditableSelect
              labelDivStyle="labelBold"
              className="labelBold"
              label={t('DOCWEB_CUSTOMCURVEDEVICE_VOLTAGE')}
              type="number"
              cols={[7, 7, 0, 0]}
              name="voltage"
              selectList={projectCommonData.voltageLow}
              value={voltage.toString()}
              onChange={(e) => setVoltage(Number(e.value))}
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <span style={{ fontWeight: '600' }}>{t('DOCWEB_CUSTOMDEVICECURVES_SETCHARACTERISTICS')}</span>
          <GridRow>
            <GridColumn>
              <FileReaderInput
                accept=".xls,.xlsx"
                className="padding-icon-start-page"
                onChange={(e, results) => onLoadExcelHandler(e, results)}
              >
                <Button
                  sizeClass="medium"
                  icon="abb/import"
                  type="discreet-blue"
                  text={t('DOCWEB_BUTTON_IMPORT')}
                  // onClick={(e) => console.log(e)}
                />
              </FileReaderInput>
            </GridColumn>
            <GridColumn>
              <Button
                sizeClass="medium"
                icon="abb/export"
                type="discreet-blue"
                text={t('DOCWEB_BUTTON_EXPORT')}
                onClick={() => exportExcelDevice({ ...deviceState, name: deviceName, voltage })}
              />
            </GridColumn>
            <GridColumn>
              <Button
                sizeClass="medium"
                type="discreet-blue"
                text={t('DOCWEB_IMPORT_FOOTER_TEMPLATEBTN')}
                onClick={() => downloadTemplate()}
              />
            </GridColumn>
          </GridRow>
          <CustomCurvesDeviceExpanderTab device={deviceState} setDeviceState={setDeviceState} />
        </GridRow>
        <GridRow>
          <GridColumn>
            <GridRow horizontalAlignment="center">
              <img src="assets/Curve.png" alt="curves" />
            </GridRow>
            <GridRow>
              <GridColumn col={2} offset={2}>
                <Radio
                  col={8}
                  additionalClassName="deviceParametersRadio"
                  name="curveOption"
                  id="curveOption"
                  checked={deviceState.option === ECustomCurveOption.Curve}
                  onChange={() => {
                    setDeviceOption(ECustomCurveOption.Curve);
                  }}
                />
              </GridColumn>
              <GridColumn col={4}>{t('DOCWEB_CURVETYPE_CURVE')}</GridColumn>
            </GridRow>
          </GridColumn>
          <GridColumn>
            <GridRow horizontalAlignment="center">
              <img src="assets/CurveAreaUp.png" alt="area (up)" />
            </GridRow>
            <GridRow>
              <GridColumn col={2} offset={1}>
                <Radio
                  additionalClassName="deviceParametersRadio"
                  col={8}
                  name="curveOption"
                  id="curveOption"
                  checked={deviceState.option === ECustomCurveOption.AreaUp}
                  onChange={() => {
                    setDeviceOption(ECustomCurveOption.AreaUp);
                  }}
                />
              </GridColumn>
              <GridColumn col={5}>{t('DOCWEB_CURVETYPE_AREAUP')}</GridColumn>
            </GridRow>
          </GridColumn>
          <GridColumn>
            <GridRow horizontalAlignment="center">
              <img src="assets/CurveAreaDown.png" alt="Area (low)" />
            </GridRow>
            <GridRow>
              <GridColumn col={2} offset={1}>
                <Radio
                  col={8}
                  additionalClassName="deviceParametersRadio"
                  name="CurveOption"
                  id="curveOption"
                  // value="Area (low)"
                  checked={deviceState.option === ECustomCurveOption.AreaLow}
                  onChange={() => {
                    setDeviceOption(ECustomCurveOption.AreaLow);
                  }}
                />
              </GridColumn>
              <GridColumn col={5}>{t('DOCWEB_CURVETYPE_AREADOWN')}</GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <GridRow>
          <Button
            type="primary-blue"
            text={t('DOCWEB_SAVEMACROMODAL_SAVEBUTTON')}
            onClick={() => dispatch(saveDevice(deviceId, deviceState, deviceName, voltage))}
            disabled={!deviceName || !deviceState.curves[deviceState.selectedDiagram]}
          />
        </GridRow>
      </>
    );
  };

  return (
    <GridRow>
      <GridColumn col={5}>
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <GraphTab selectedDeviceId={deviceId ?? ''} userDevice={deviceState} initialDiagram="" />
        </div>
      </GridColumn>
      <GridColumn col={3}>{parameters()}</GridColumn>
    </GridRow>
  );
};

export default CustomCurvesParameters;
