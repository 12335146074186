import { STANDARDS } from 'types/constants.d';
import { ActionCreator } from 'types/known-actions';

export enum EPowerSupply {
  LVU = 'LVU',
  MVU = 'MVU',
  LVG = 'LVG',
}

export interface State {
  [key: string]: unknown;
  ScStandard: number;
  CableStandard: number;
  cableStandardFetched: boolean;
  ScStandardList: Array<string>;
  CableStandardList: Array<string>;
  powerSupply: EPowerSupply;
}

export interface Actions {
  setStandard: ActionCreator<STANDARDS.SET_STANDARD, Partial<State>>;
}
