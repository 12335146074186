import _ from 'lodash';
import { VariablesActions } from 'store/variables/actions';
import { ICheckBoxOnChange } from 'types/components/checkbox';
import { ISelectOnChange } from 'types/components/selectContainer';
import { ThunkAction } from 'types/known-actions';
import { DisabledFamilies } from 'types/variables/store';

export const TAB_NAME = 'Circuit Breaker';

export const inputUpdateHandler =
  (event: ISelectOnChange): ThunkAction =>
  (dispatch, getState) => {
    const { value, name } = event;
    dispatch(VariablesActions.setVariables({ [name]: value }));
  };

export const checkHandler =
  (data: ICheckBoxOnChange): ThunkAction =>
  (dispatch, getState) => {
    const { name } = data;
    const { variables } = getState();
    if (name !== undefined) {
      const value = !variables[name];
      dispatch(VariablesActions.setVariables({ [name]: value }));
    }
  };

export const checkboxUpdateHandler =
  (data: ICheckBoxOnChange, list: DisabledFamilies): ThunkAction<void> =>
  (dispatch, getState) => {
    const { name } = data;
    if (name === undefined) {
      return;
    }
    const disabledList = _.cloneDeep(getState().variables[list].name);
    if (disabledList.includes(name)) {
      disabledList.splice(disabledList.indexOf(name), 1);
    } else if (!disabledList.includes(name)) {
      disabledList.push(name);
    }
    dispatch(
      VariablesActions.setVariables({
        [list]: { name: [...disabledList] },
      })
    );
  };
