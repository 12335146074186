export default {
  type: 'app.BUSBAR',
  ports: {
    groups: {
      default: {
        position: {
          name: 'absolute',
        },
        attrs: {
          '.joint-port-body': {
            fill: 'transparent',
            stroke: 'red',
            strokeWidth: 'inherit',
            r: 3,
            magnet: 'passive',
            fillOpacity: 0.1,
            strokeOpacity: 1,
          },
        },
      },
    },
    items: [
      {
        group: 'default',
        args: {
          x: 0,
          y: 0,
        },
        id: 'ba138a8e-1ce3-4e45-bd9f-8a0e4e2cd23f',
      },
      {
        group: 'default',
        args: {
          x: 5,
          y: 0,
        },
        id: '4a06c2ca-1403-41f2-8dca-ed2f3d2ac394',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: '69fa24b9-8a15-4d9a-bf31-a94d02249c3b',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: 'e3a506a8-403c-4e0c-9902-dfd920bfa0fc',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: '5c6bd757-08ce-47fb-b057-a82b6465892a',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: '0c6d43a2-a601-4daa-a328-6e536acac4ae',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: '5fd95ec4-8bae-4f99-8b81-3fad4b1dfd6e',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: '0aed7f67-012a-4b9c-8c66-57d136aa8a31',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: 'c49409e1-d38d-4cde-b16e-19bcb77196ec',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: 'd7ea6187-780f-44b3-8cd3-9f9f88832d2c',
      },
      {
        group: 'default',
        args: {
          x: 10,
          y: 0,
        },
        id: '0f9a2383-7c0a-4639-a72b-3582ab15de4f',
      },
    ],
  },
  position: {
    x: 85,
    y: 50,
  },
  size: {
    width: 50,
    height: 3,
  },
  angle: 0,
  id: '5d4b2578-df05-40d3-a94d-82e476e99c50',
  z: 4,
  attrs: {},
};
