import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import React from 'react';
import { useDispatch, useSelector } from 'store';
import { ISelectList } from 'types/components/selectContainer';
import { inputUpdateHandler } from '../actions';

interface IThisModal {
  selectedDeviceId: string;
}
export const SelectionModal: React.FunctionComponent<IThisModal> = ({ selectedDeviceId }): React.ReactElement => {
  const devices = useSelector((state) => state.devices);
  const device = devices[selectedDeviceId];
  const dispatch = useDispatch();
  return (
    <Grid>
      <GridRow key={'selectionTabCircuitBreakerGridRow1'}>
        {device.tripUnit ? (
          <GridColumn col={3}>
            <Select
              cols={[8, 8, 0]}
              className={'info-project-input'}
              displayName={'STRINGS_IDC_RELAY_TXT'}
              name="tripUnitValue"
              label={'STRINGS_IDC_RELAY_TXT'}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              selectList={device.tripUnit as ISelectList}
              data={device}
              plainObjectModel={true}
              displayedAttribute={'value'}
              valueAttribute={'value'}
            />
          </GridColumn>
        ) : null}
        {device.currentTransformer ? (
          <GridColumn col={3} offset={1}>
            <Select
              cols={[8, 8, 0]}
              className={'info-project-input'}
              displayName={'DOCWEB_TU_CTRAFOSENSOR'}
              name="currentTransformerValue"
              label={'DOCWEB_TU_CTRAFOSENSOR'}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              selectList={device.currentTransformer as ISelectList}
              data={device}
              plainObjectModel={true}
              displayedAttribute={'value'}
              valueAttribute={'value'}
            />
          </GridColumn>
        ) : null}
      </GridRow>
      {device.homopolarTransformer ? (
        <GridRow>
          <GridColumn col={3}>
            <Select
              cols={[8, 8, 0]}
              className={'info-project-input'}
              displayName={'DOCWEB_TU_HTRAFO'}
              name="homopolarTransformerValue"
              label={'DOCWEB_TU_HTRAFO'}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              selectList={device.homopolarTransformer as ISelectList}
              data={device}
              plainObjectModel={true}
              displayedAttribute={'value'}
              valueAttribute={'value'}
            />
          </GridColumn>
        </GridRow>
      ) : null}
      <GridRow>
        {device.mvFuse ? (
          <GridColumn col={3}>
            <Select
              cols={[8, 8, 0]}
              className={'info-project-input'}
              displayName={'STRING_FUSE'}
              name="mvFuseValue"
              label={'STRING_FUSE'}
              onChange={(e) => dispatch(inputUpdateHandler(e))}
              selectList={device.mvFuse as ISelectList}
              data={device}
              plainObjectModel={true}
              displayedAttribute={'value'}
              valueAttribute={'value'}
            />
          </GridColumn>
        ) : null}
      </GridRow>
    </Grid>
  );
};

export default SelectionModal;
