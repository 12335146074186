export default {
  angle: 0,
  attrs: {},
  id: '8efd5890-0ac0-4bd2-96b6-e929dce98e48',
  ports: { items: Array(0) },
  position: { x: 10, y: 10 },
  size: { width: 1, height: 1 },
  type: 'app.BLOCK_UTILITY',
  z: 1,
};
