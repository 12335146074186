/* eslint-disable no-restricted-properties */
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { ELvLineType } from 'types/devices/enums.d';
import * as generalCalculations from './generalCalculations';

export const calculateSk = (voltage: number, ik: string): number => {
  const ikVal = UnitsDataAdapter.convertMilliToBase(ik) as number;
  return (Math.sqrt(3) * voltage * ikVal) / 1000;
};

export const calculateIk = (
  voltage: number,
  sk: string,
  trafoNumber: number,
  sr: number,
  ukr: string,
  lineType: ELvLineType
): number => {
  if (trafoNumber === 0) {
    const skVal = UnitsDataAdapter.convertMicroToBase(sk) as number;
    return (1000 * skVal) / (Math.sqrt(3) * voltage);
  }
  return (110 * trafoNumber * sr) / (parseFloat(ukr) * calculateVfac(voltage, lineType));
};

const calculateVfac = (voltage: number, lineType: ELvLineType): number => {
  return (voltage * generalCalculations.getPh(lineType)) / generalCalculations.getVh(lineType);
};

export const calculateCe = (io: number, voltage: number, frequency: number): number => {
  const omega = 2 * Math.PI * frequency;
  return (io * 10 ** 6) / (Math.sqrt(3) * (1.1 * voltage) * omega);
};

export const calculateR = (voltage: number, io: number): number => {
  return (1.1 * voltage) / (Math.sqrt(3) * io);
};

export const calculateL = (ce: number, frequency: number): number => {
  const omega = 2 * Math.PI * frequency;
  return 10 ** 9 / (3 * omega ** 2 * ce);
};

export const calculateIo = (voltage: number, ce: number, frequency: number, petersenCoil = false, r = 0, l = 0): number => {
  const omega = 2 * Math.PI * frequency;
  const mVoltage = 1.1 * voltage;
  const rRec = petersenCoil ? 1 / r : 0;
  const xRec = petersenCoil ? 10 ** -6 * omega * ce - 1 / (3 * 10 ** -3 * omega * l) : 10 ** -6 * omega * ce;
  return (mVoltage * Math.sqrt(rRec ** 2 + xRec ** 2)) / Math.sqrt(3);
};
