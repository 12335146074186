import FieldsValidation from 'fieldsValidation';
import { ThunkAction } from 'types/known-actions';
import { Device } from 'types/devices/device';
import { isNumber } from 'types/type-guards';
import { EDeviceType } from 'types/devices/enums.d';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { calculateMaxForcedCosPhi } from 'calculations/motorCalculations';

const inputUpdateImpedanceValues = {
  LiveResistance: 'Phase resistance (R)',
  NeutralResistance: 'Neutral resistance (RN)',
  PEResistance: 'PE resistance (RPE)',
  LiveReactance: 'Phase reactance (X)',
  NeutralReactance: 'Neutral reactance (XN)',
  PEReactance: 'PE reactance (XPE)',
} as const;

// TODO: Change "as number, as smth to typeguards ones all devices states would be implemented"
export const valueErrorValidation =
  (
    name: string,
    value: string | number,
    selectedDevice: Device,
    additionalData?: Record<string, unknown>
    // TODO: Replace unknown to possible types and interfaces
  ): ThunkAction<string | undefined | unknown> =>
    (dispatch, getState) => {
      const deviceType = selectedDevice ? selectedDevice.deviceType : null;
      // let shortCircuitTimesObject = {};
      switch (name) {
        case 'Uref':
        case 'Pn':
        case 'Qn':
        case 'In':
        case 'TA':
        case 'Ra':
        case 'XdSecond':
        case 'OverExciting':
        case 'TdFirst':
        case 'TdSecond':
        case 'LiveResistance':
        case 'NeutralResistance':
        case 'PEResistance':
        case 'LiveReactance':
        case 'NeutralReactance':
        case 'IBUsed':
        case 'IScInRatio':
        case 'PEReactance':
        case 'ChooseMcbThreshold':
        case 'ChooseAcbThreshold':
        case 'ChooseElectronicThreshold': {
          if (deviceType === EDeviceType.IMPEDANCE) {
            return FieldsValidation.validateLowerValue(
              0,
              value,
              inputUpdateImpedanceValues[name as keyof typeof inputUpdateImpedanceValues]
            );
          }
          if (name === 'IScInRatio' && deviceType === EDeviceType.UPS) {
            return FieldsValidation.validateRange(1, 5, value, 'IScInRatio');
          }
          return FieldsValidation.validateLowerValue(0, value, name);
        }
        case 'DemandFactor':
        case 'coldSeasonDemandFactor':
          return FieldsValidation.validateRange(0, 1, value, 'Demand factor');
        case 'NominalVoltage':
          return FieldsValidation.validateExactRange(0, 300000, value, 'Un');
        case 'CosPhiNominal': {
          if (deviceType === EDeviceType.UPS || deviceType === EDeviceType.LOAD) {
            return FieldsValidation.validateRange(0, 1, value, 'Cos\u03C6n');
          }
          return FieldsValidation.validateExactRange(0, 1, value, 'Cos\u03C6n');
        }
        case 'NominalPower': {
          // if (deviceType === EDeviceType.CAPACITORBANKS) {
          //   const MaxQn = dispatch(calculateMaxQn(parseFloat(value as string)));
          //   return FieldsValidation.validateRange(0, parseFloat(MaxQn.toFixed(0)), value, 'Qn');
          // }
          return FieldsValidation.validateLowerValue(0, value, name);
        }
        case 'VoltageDropMaX':
          return FieldsValidation.validateRange(0, 100, value, 'Voltage Drop');
        case 'DesPowerFactor':
          return FieldsValidation.validateRange(
            selectedDevice.cosPhi ? parseFloat((selectedDevice.cosPhi as number).toFixed(2)) : 0,
            1,
            value,
            'Desired Cos\u03C6'
          );
        case 'LoadCurrent': {
          if (deviceType === 'app.LOAD') {
            return FieldsValidation.validateLowerValue(0, value, 'In');
          }
          return FieldsValidation.validateLowerValue(0, value, name);
        }

        case 'Uf':
        case 'coldSeasonUf': {
          const error = FieldsValidation.validateRange(0, 100, value, 'UF');
          if (!error) {
            return FieldsValidation.validateIntegerTypeField(value, 'UF');
          }
          return error;
        }
        case 'ComputedCosPhi':
        case 'CosPhiIk3':
          return FieldsValidation.validateRange(0, 1, value, 'cosPhi');
        case 'dUMax':
          return FieldsValidation.validateExactRange(0, 10, value, 'dU%');
        case 'CableLength':
        case 'PELength':
          return FieldsValidation.validateLowerValue(0.01, value, 'Length');
        case 'CableCarryingCapacityUserFactor':
          return FieldsValidation.validateLowerValue(0, value, 'User factor');
        case 'AmbientTemperature':
          return FieldsValidation.validateExactRange(-60, 60, value, 'Ambient temperature ');
        case 'IzIbRatio':
          return FieldsValidation.validateExactRange(0, 10, value, 'Minimal Iz / Ib');
        case 'ISecondK':
          return FieldsValidation.validateExactLowerValue(0, value, 'I"k');
        case 'sk':
          return FieldsValidation.validateExactLowerValue(0, value, 'S"k');
        // case 'ISecondKMin':
        //   return FieldsValidation.validateExactLowerValue(0, value, 'I"k Minimum');
        case 'SkMin':
          return FieldsValidation.validateExactLowerValue(0, value, 'S"k Minimum');
        case 'PetersensCoilResistance':
        case 'ShortCircuitCurrentToGround':
        case 'PetersensCoilReactance':
        case 'CapacityToGround':
        case 'Sr': {
          // if (deviceType === EDeviceType.TRAFO2) {
          //   const insulated = trafoIsInsulated(selectedDevice.objectType);
          //   if (insulated && getState().variables.ShortCircuitStandard === 'VDE 0102') {
          //     return FieldsValidation.validateRange(3150, 8000, UnitsDataAdapter.convertKiloToBase(value.toString()), name);
          //   }
          //   if (insulated) {
          //     return FieldsValidation.validateRange(500, 10000, UnitsDataAdapter.convertKiloToBase(value.toString()), name);
          //   }
          //   return FieldsValidation.validateExactLowerValue(0, value, name);
          // }
          return FieldsValidation.validateLowerValue(0, value, 'Rated Power');
        }
        case 'Eta':
          return FieldsValidation.validateRange(0, 1, value, 'Eta');
        case 'RatedPower':
          return FieldsValidation.validateLowerValue(0, value, 'P');
        case 'CosPhiRequested': {
          return FieldsValidation.validateRange(0, 1, value, 'cos\u03C6n');
        }
        case 'CosPhiDesired': {
          const maxForcedCosPhi = calculateMaxForcedCosPhi(
            selectedDevice.Eta as number,
            selectedDevice.I0InRatio as number,
            selectedDevice.CosPhiNominal as number
          );
          return FieldsValidation.validateRange(selectedDevice.CosPhiNominal as number, maxForcedCosPhi, value, 'cos\u03C6n');
        }
        case 'IlrInRatio':
          return FieldsValidation.validateLowerValue(0, value, 'Ilr/In');
        case 'I0InRatio':
          return FieldsValidation.validateLowerValue(0, value, 'Io/In');
        case 'PolesNumber':
          return FieldsValidation.validateLowerValue(0, value, 'p');
        case '___RM':
          return FieldsValidation.validateRange(0, 100, value, 'Rm');
        case '___XM':
          return FieldsValidation.validateRange(0, 100, value, 'Xm');
        case 'DUMax':
          return FieldsValidation.validateExactRange(0, 10, value, 'dU%');
        case 'PrimaryWindingUn':
          return FieldsValidation.validateLowerValue(0, value, 'Primary Rated Voltage');
        case 'SecondaryWindingUn': {
          // const errors = {} as {
          //   SecondaryWindingUn?: string;
          //   TransformerSecondaryWindingNoLoadU?: string;
          // };
          // if (trafoIsInsulated(selectedDevice.objectType)) {
          //   return FieldsValidation.validateRange(0, 250, value, 'SecondaryWindingUn');
          // }
          return FieldsValidation.validateLowerValue(1, value, 'Secondary Rated Voltage');
          // errors.TransformerSecondaryWindingNoLoadU = FieldsValidation.validateLowerValueWithSpecifiedText(
          //   selectedDevice.SecondaryWindingUn as number,
          //   value,
          //   'No-Load Voltage should be greater or equal to Secondary Rated Power'
          // );
          // return errors;
        }
        case 'VoltageRegulatorPlug':
          return FieldsValidation.validateRange(-10, 10, value, 'Plug');
        case 'TransformerSecondaryWindingNoLoadU':
          return FieldsValidation.validateLowerValueWithSpecifiedText(
            selectedDevice.SecondaryWindingUn as number,
            value,
            'No-Load Voltage should be greater or equal to Secondary Rated Power'
          );
        case 'ukr':
          return FieldsValidation.validateRange(0, 100, value, 'uKr');
        case 'uRr':
          return FieldsValidation.validateRange(0, 100, value, 'Losses');
        case 'Ik1LNOverIk3Ratio':
          return FieldsValidation.validateExactRange(0, 200, value, 'Ik LN / Ik LLL [%]');
        case 'Ik1LPEOverIk3Ratio':
          return FieldsValidation.validateExactRange(0, 200, value, 'Ik L-PE / Ik LLL [%]');
        case 'IFETemperature':
          return FieldsValidation.validateExactRange(30, 300, value, 'Hot section Temperature');
        case 'CableLengthIFE':
          return FieldsValidation.validateRange(0, selectedDevice.CableLength as number, value, 'IFE Length');
        case 'PELengthIFE':
          return FieldsValidation.validateRange(0, selectedDevice.PELength as number, value, 'IFE PE Length');
        case 'ISecondKMin':
          return FieldsValidation.validateRange(
            0,
            UnitsDataAdapter.convertMilliToBase(selectedDevice.ISecondK as string) as number,
            value,
            'I"K Min'
          );
        case 'ShortCircuitCurrentToGroundMin':
          return FieldsValidation.validateRange(0, selectedDevice.ShortCircuitCurrentToGround as number, value, '3Io Min');

        case 'BatteryChargingCurrent':
          return FieldsValidation.validateRange(1, 25, value, 'BatteryChargingCurrent');
        case 'value': {
          // TODO: Implement below once ready variables state
          const shortCircuitTimesObject = getState().variables.ShortCircuitTimesObject;
          const id = additionalData && additionalData.id ? additionalData.id : false;
          if (id && isNumber(id) && id < 3) {
            return FieldsValidation.validateExactRange(
              shortCircuitTimesObject[`time${id - 1}`].value,
              shortCircuitTimesObject[`time${id + 1}`].value,
              value,
              `time ${id}`
            );
          }
          if (id && isNumber(id)) {
            return FieldsValidation.validateLowerValue(shortCircuitTimesObject[`time${id - 1}`].value, value, `time ${id}`);
          }
          return 'dev no id provided';
        }
        case 'IncomerMin':
        case 'MotorMin':
        case 'OutcomerMin':
        case 'firstValue': {
          if (!additionalData) {
            return 'noAdditionalDataSet';
          }
          return FieldsValidation.validateRange(0, additionalData.MaxValue as number, value, 'Value');
        }
        case 'IncomerMax':
        case 'MotorMax':
        case 'OutcomerMax':
        case 'secondValue': {
          if (!additionalData) {
            return 'noAdditionalDataSet';
          }
          return FieldsValidation.validateExactLowerValue(additionalData.MinValue as number, value, 'Value');
        }
        case 'SwitchBoardName': {
          const { devices } = getState();
          const deviceIds = Object.keys(devices);
          for (let i = 0; i < deviceIds.length; i += 1) {
            if (
              devices[deviceIds[i]].deviceType === EDeviceType.LV_SWITCHBOARD ||
              devices[deviceIds[i]].deviceType === EDeviceType.MV_SWITCHBOARD
            ) {
              if (devices[deviceIds[i]].SwitchBoardName === value) {
                return `${value} duplicate switchboard name`;
              }
            }
          }
          return undefined;
        }
        case 'coordinationYValue':
        case 'coordinationXValue':
          return FieldsValidation.validateForValidNumber(value);
        default:
          return '';
      }
    };
