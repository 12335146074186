import { EApparentPowerType, EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export const generatorDefaultState: Record<string, unknown> = {
  ...defaultElement,
  symbol: 'GENERATOR',
  objectType: EDeviceObjectType.Genset,
  deviceType: EDeviceType.GENERATOR,
  generatorList: [],
  Desrciption: '',
  Frequency: 50,
  Pn: 2000000,
  Qn: 1500000,
  In: 3608.44,
  ApparentPowerType: EApparentPowerType.RatedPower,
  ProductDescription: '',
  NominalVoltage: 400,
  Uref: 400.0,
  LineType: ELvLineType.LLLN,
  EarthingSystem: EEarthingSystem.TNS,
  NominalPower: 2500000.0,
  CosPhiNominal: 0.8,
  GensetType: 'LVT',
  Ra: 0.000463,
  TA: 55,
  XdSecond: 225,
  XqSecond: 12.5,
  XdFirst: 30,
  Xd: 12.5,
  XSecond: 12.5,
  X0: 8,
  TdSecond: 0.02,
  TdFirst: 0.65,
  StarCenterAvailable: false,
  GroundingResistance: 10,
  IsPq: false,
  MaxContactVoltage: 50,
  userList: [],

  // buttonErrorHandling
  addGeneratorError: '',
  importError: '',

  // for import from excel
  importErrorList: [],
};

export const defaultGeneratorSmallState = {
  ...generatorDefaultState,
  objectType: EDeviceObjectType.GeneratorSmall,
  deviceType: EDeviceType.GENERATOR_SMALL,
};
