import { getCableDataWizard } from 'devices/lvCable/actions';
import { ThunkAction } from 'types/known-actions';
import { ApplicationState } from 'types/store';
import { ISelectOnChange } from 'types/components/selectContainer';
import { IDOCwebInputProps } from 'types/components/inputContainer';
import { IWizardThunkActions, TUpdateWizardParamsEvent, TWizardUtilityCategory } from 'types/wizard';
import { isEventType } from 'types/wizard/type-guards';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import FieldsValidation from 'fieldsValidation';
import { calculateIk, calculateSk } from 'calculations/lvUtility';
import { EPowerSupply } from 'types/standards/index.d';
import { ISwitchOnChangeProps } from 'types/components/switch';
import { ICheckBoxOnChange } from 'types/components/checkbox';
import { createAction } from 'redux-actions';
import { Wizard } from 'types/wizard/store';
import * as Constants from 'types/constants.d';
import { calculateUkr } from 'calculations/generalCalculations';
import { VariablesActions } from 'store/variables/actions';
import _ from 'lodash';

export const wizardActions = {
  updateWizardParams: createAction<Wizard.Actions['updateWizardParams']['payload']>(Constants.WIZARD.UPDATE_WIZARD_PARAMS),
  updatePowerSupplyErrors: createAction<Wizard.Actions['updatePowerSupplyErrors']['payload']>(
    Constants.WIZARD.UPDATE_POWER_SUPPLY_ERRORS
  ),
};

export const updateWizardParams: IWizardThunkActions['updateWizardParams'] = (event, category) => (dispatch) => {
  let wizardObject = {};
  // TODO : Refactor this or write more code like this, at least decide what to do.
  if (isEventType(event)) {
    const { name } = event;
    const { value } = event;
    switch (event.name) {
      // case 'ScStandard':
      // case 'CableStandard':
      //   wizardObject = {
      //     [name]: parseFloat(value),
      //   };
      //   break;
      case 'powerSupply':
        // eslint-disable-next-line no-param-reassign
        category = 'standards';
        wizardObject = {
          [name]: value,
        };
        break;
      case 'NominalVoltage':
      case 'Uref':
        wizardObject = {
          NominalVoltage: value,
          Uref: value,
        };
        break;
      default:
        wizardObject = {
          [name]: value,
        };
    }
  } else {
    wizardObject = event;
  }
  dispatch(
    wizardActions.updateWizardParams({
      wizardObject,
      category,
    })
  );
  if (isEventType(event) && (event.name === 'ShortCircuitStandard' || event.name === 'CableStandard')) {
    dispatch(getCableDataWizard(event.name));
  }
};

export const getCategory = (state: ApplicationState['wizard']): TWizardUtilityCategory => {
  let category: TWizardUtilityCategory = 'lvUtilitySettings';
  switch (state.standards.powerSupply) {
    case EPowerSupply.LVU:
      category = 'lvUtilitySettings';
      break;
    case EPowerSupply.MVU:
      category = 'mvUtilitySettings';
      break;
    case EPowerSupply.LVG:
      category = 'generatorSettings';
      break;
    default:
      break;
  }
  return category;
};

export const toggleButtonHandler =
  (name: string, value: string): ThunkAction<void> =>
  (dispatch, getState) => {
    let category;
    if (name === 'tolerance') {
      category = getCategory(getState().wizard);
    }
    const wizardObject = {
      [name]: value,
    };
    dispatch(
      wizardActions.updateWizardParams({
        wizardObject,
        category,
      })
    );
  };

export const updateSettings =
  (event: IDOCwebInputProps | ICheckBoxOnChange | ISelectOnChange | ISwitchOnChangeProps): ThunkAction<void> =>
  (dispatch, getState) => {
    let { name } = event;
    const state = getState();
    const category = getCategory(state.wizard);
    const mvUtilitySelected = state.wizard.standards.powerSupply === 'MVU';
    const additionalData: Record<string, unknown> = {};
    let value =
      event.type === 'number' && !Number.isNaN(parseFloat(event?.value ?? '-1')) ? parseFloat(event?.value ?? '-1') : event.value;
    if (event.type === 'checkbox') {
      value = event.checked ?? false;
    } else if (event.type === 'switch' && (event as ISwitchOnChangeProps).id === 'switchTransformatorsData') {
      value = event.value === true ? 1 : 0;
      name = 'PowerSourceTransformer';
      dispatch(
        updateSettings({
          name: 'ISecondKMin',
          value: UnitsDataAdapter.convertMilliToBase(state.wizard.lvUtilitySettings?.ISecondK as string).toString(),
        })
      );
    } else if ((event as ISwitchOnChangeProps).id === 'computeShortCircuitCurrent') {
      value = event.value;
      name = 'computeShortCircuitCurrent';
    }
    if (event.name === 'sk' || event.name === 'SkMin') {
      value = UnitsDataAdapter.convertMegaToBase(value.toString());
    }
    const lvDefaultsProperties = ['PlantLvUn', 'PlantLineType', 'PlantEarthingSystem', 'PlantFrequency'];
    switch (event.name) {
      case 'ISecondK':
        dispatch(
          wizardActions.updatePowerSupplyErrors({
            name: name ?? '',
            value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'I"k'),
            category,
          })
        );
        value = UnitsDataAdapter.convertKiloToBase(value?.toString() ?? '');
        break;
      case 'sk':
        dispatch(
          wizardActions.updatePowerSupplyErrors({
            name: name ?? '',
            value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'S"k'),
            category,
          })
        );
        // value = UnitsDataAdapter.convertMegaToBase(value?.toString() ?? '');
        break;
      case 'ISecondKMin':
        dispatch(
          wizardActions.updatePowerSupplyErrors({
            name: name ?? '',
            value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'I"k min'),
            category,
          })
        );
        value = UnitsDataAdapter.convertKiloToBase(value?.toString() ?? '');
        break;
      case 'SkMin':
        dispatch(
          wizardActions.updatePowerSupplyErrors({
            name: name ?? '',
            value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'S"k min'),
            category,
          })
        );
        // value = UnitsDataAdapter.convertMegaToBase(value?.toString() ?? '');
        break;
      case 'NominalVoltage':
        dispatch(
          wizardActions.updatePowerSupplyErrors({
            name: name ?? '',
            value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'Un'),
            category,
          })
        );
        dispatch(updateWizardParams({ NominalVoltage: value, Uref: value }, category));
        dispatch(updateWizardParams({ PlantMvUn: value }, 'variables'));
        break;
      case 'PlantLvUn':
        dispatch(
          updateWizardParams(
            {
              NominalVoltage: value,
              Uref: value,
            },
            'lvUtilitySettings'
          )
        );
        dispatch(
          updateWizardParams(
            {
              NominalVoltage: value,
            },
            'generatorSettings'
          )
        );
        break;
      case 'PlantLineType':
        dispatch(
          updateWizardParams(
            {
              LineType: value,
            },
            'lvUtilitySettings'
          )
        );
        dispatch(
          updateWizardParams(
            {
              LineType: value,
            },
            'generatorSettings'
          )
        );
        break;
      case 'PlantEarthingSystem':
        dispatch(
          updateWizardParams(
            {
              EarthingSystem: value,
            },
            'lvUtilitySettings'
          )
        );
        dispatch(
          updateWizardParams(
            {
              EarthingSystem: value,
            },
            'generatorSettings'
          )
        );
        break;
      case 'PlantFrequency':
        dispatch(changeTimesByFrequency(parseFloat(value?.toString() ?? '-1')));
        dispatch(
          updateWizardParams(
            {
              frequency: value,
            },
            'lvUtilitySettings'
          )
        );
        dispatch(
          updateWizardParams(
            {
              frequency: value,
            },
            'mvUtilitySettings'
          )
        );
        break;
      case 'ukr':
        dispatch(
          wizardActions.updatePowerSupplyErrors({
            name: name ?? '',
            value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'Ukr'),
            category,
          })
        );
        break;
      case 'Sr':
        {
          dispatch(
            wizardActions.updatePowerSupplyErrors({
              name: name ?? '',
              value: FieldsValidation.validateExactLowerValue(0, value?.toString() ?? '', 'Sr'),
              category,
            })
          );
          const ukrValue = calculateUkr(parseFloat(value?.toString() ?? '-1'));
          additionalData.ukr = ukrValue;
        }
        break;
      case 'PetersensCoilsPresent':
        dispatch(updateWizardParams(event as TUpdateWizardParamsEvent, 'variables'));
        break;
      default:
        break;
    }

    if (lvDefaultsProperties.includes(event.name)) {
      dispatch(
        updateWizardParams(
          {
            [name]: value,
          },
          'variables'
        )
      );
      dispatch(
        updateWizardParams(
          {
            [name]: value,
          },
          'generatorSettings'
        )
      );
    }
    if (!mvUtilitySelected || !lvDefaultsProperties.includes(event.name)) {
      dispatch(
        updateWizardParams(
          {
            [name]: value,
            ...additionalData,
          },
          category
        )
      );
    }
    if (name === 'ISecondK' || name === 'ISecondKMin' || name === 'NominalVoltage') {
      if (mvUtilitySelected && state.wizard.mvUtilitySettings.computeShortCircuitCurrent) {
        if (name === 'NominalVoltage') {
          dispatch(skUpdateHandler('SkMin'));
          dispatch(skUpdateHandler(name));
        } else {
          dispatch(skUpdateHandler(name));
        }
      } else if (name === 'NominalVoltage') {
        dispatch(ikUpdateHandler('ISecondKMin'));
        dispatch(ikUpdateHandler(name));
      } else {
        dispatch(ikUpdateHandler(name));
      }
    }

    if (name === 'sk' || name === 'SkMin') {
      dispatch(skUpdateHandler(name));
    }

    switch (event.name) {
      case 'Sr':
      case 'ukr':
      case 'PowerSourceTransformer':
        dispatch(skUpdateHandler('sk'));
        dispatch(ikUpdateHandler('ISecondK'));

        break;
      default:
        break;
    }
  };

export const ikUpdateHandler =
  (name: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const category = getCategory(state.wizard);
    const settings = category === 'mvUtilitySettings' ? state.wizard.mvUtilitySettings : state.wizard.lvUtilitySettings;
    const sk = calculateSk(settings.Uref as number, settings.ISecondK as string).toPrecision(4);

    const toUpdate = name === 'ISecondKMin' ? 'SkMin' : 'sk';
    dispatch(
      wizardActions.updatePowerSupplyErrors({
        name: toUpdate,
        value: FieldsValidation.validateExactLowerValue(0, parseFloat(settings[name] as string), 'I"k'),
        category,
      })
    );
    dispatch(
      wizardActions.updatePowerSupplyErrors({
        name: toUpdate,
        value: FieldsValidation.validateExactLowerValue(0, parseFloat(sk), 'S"k'),
        category,
      })
    );
    dispatch(updateWizardParams({ [toUpdate]: UnitsDataAdapter.convertMegaToBase(parseFloat(sk).toString()) }, category));
  };

const skUpdateHandler =
  (name: string): ThunkAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const category = getCategory(state.wizard);
    const settings = category === 'mvUtilitySettings' ? state.wizard.mvUtilitySettings : state.wizard.lvUtilitySettings;
    const ik = calculateIk(
      settings.Uref as number,
      settings.sk as string,
      settings.PowerSourceTransformer as number,
      settings.Sr as number,
      settings.ukr as string,
      settings.LineType
    ).toPrecision(4);

    const toUpdate = name === 'SkMin' ? 'ISecondKMin' : 'ISecondK';
    dispatch(
      wizardActions.updatePowerSupplyErrors({
        name: toUpdate,
        value: FieldsValidation.validateExactLowerValue(0, parseFloat(ik), 'I"k'),
        category,
      })
    );
    dispatch(
      wizardActions.updatePowerSupplyErrors({
        name: toUpdate,
        value: FieldsValidation.validateExactLowerValue(0, parseFloat(settings[name] as string), 'S"k'),
        category,
      })
    );
    dispatch(updateWizardParams({ [toUpdate]: UnitsDataAdapter.convertKiloToBase(ik) }, category));
  };

const changeTimesByFrequency =
  (value: number): ThunkAction<void> =>
  (dispatch, getState) => {
    const { ShortCircuitTimesObject } = _.cloneDeep(getState().variables);
    let time0 = 10;
    let time1 = 20;
    if (value === 60) {
      time0 = 8;
      time1 = time0 * 2;
    }
    if (ShortCircuitTimesObject !== undefined) {
      ShortCircuitTimesObject.time0.value = time0;
      ShortCircuitTimesObject.time1.value = time1;
      dispatch(
        VariablesActions.setVariables({
          ShortCircuitTimesObject,
          PlantFrequency: value,
        })
      );
    }
  };
