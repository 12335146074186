/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { ConnectionsKnownActions } from 'types/known-actions';
import * as ConnectionsNamespace from 'types/connections';
import { CONNECTIONS, PROJECT, USER } from 'types/constants.d';

export const initialState: ConnectionsNamespace.State = {};

// TODO: assign proper type
// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: ConnectionsKnownActions): ConnectionsNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER.PASTE_MACRO:
        return {
          ...state,
          ...action.payload.pageEdges,
        };
      case CONNECTIONS.UPDATE_CONNECTIONS:
        return { ...state, ...action.payload };
      case PROJECT.LOAD_PROJECT:
        return {
          ...action.payload.projectState.connections,
        };
      // TODO: LOAD_PROJECT_INTRO_JS
      default:
        return draft;
    }
  });
