import '@abb/abb-common-ux-react/styles.css';
import './styles/site.scss';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import WizardDialog from 'ui/deviceWizard/WizardDialog';
import StartUpWizardDialog from 'ui/startupWizard/WizardDialog';
import { AppContent, AppContainer, AppLeftPane, SplashScreen, NotificationContainer } from '@abb/abb-common-ux-react';

import Login from 'login';
import GraphTab from 'curves/toolbar/GraphTab';
import DiagramTabs from 'components/curves/DiagramTabs';
import MessageDialog from 'ui/modals/messageDialog';
import CableModal from 'devices/lvCable/modal';
import CoordinationModal from 'ui/modals/coordination';
import PrintManagerModal from 'ui/modals/printManager';
import MailSupport from 'ui/modals/mailSupport';
import CurvesToolbar from 'curves/toolbar';
import Errors from 'compute/errors';
import AutoSave from 'components/AutoSave';
import { useTranslation } from 'react-i18next';
import ProjectPropertiesModal from 'ui/modals/projectProperties';
import { isMobile, isMobileOnly, deviceType } from 'react-device-detect';

import init from 'init';
import { getCableDataProjectProperties } from 'devices/lvCable/actions';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import LeftPanelDeviceTable from 'ui/leftPanel/deviceTable';
import CustomCurvesDeviceModal from 'ui/modals/customCurveDevices';
import { ICustomCurve } from 'types/curves';
import Navbar from './ui/Navbar';

const App = (): React.ReactElement => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const project = useSelector((state) => state.project);
  const compute = useSelector((state) => state.compute);
  const variables = useSelector((state) => state.variables);
  const curves = useSelector((state) => state.curves);
  const isAuthenticated = useIsAuthenticated();

  const { t } = useTranslation();

  // const [errorTabIsOpened, setErrorTabIsOpened] = useState(false);
  // const [initial, setInitial] = useState(true);
  const [orientationState, setOrientationState] = useState(1);

  // TODO: fix using deprecated window.orientation
  useEffect(() => {
    window.addEventListener(
      'orientationChange',
      () => {
        setOrientationState(window.orientation);
      },
      false
    );
    dispatch(getCableDataProjectProperties('Initial'));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(init());
    }
  }, [isAuthenticated]);

  const renderCurrentModal = (): React.ReactElement => {
    switch (modal.name) {
      case 'CableModal':
        return <CableModal />;
      case 'Wizard':
        return <WizardDialog />;
      case 'StartUpWizard':
        return <StartUpWizardDialog />;
      case 'Coordination':
        return <CoordinationModal />;
      case 'PrintManager':
        return <PrintManagerModal />;
      case 'ProjectProperties':
        return <ProjectPropertiesModal />;
      case 'MailSupport':
        return <MailSupport />;
      case 'CustomCurvesDeviceModal':
        return <CustomCurvesDeviceModal />;
      default:
        return <></>;
    }
  };

  // const setErrorTabOpen = (force = false) => {
  //   if (force) {
  //     setErrorTabIsOpened(true);
  //   } else {
  //     setErrorTabIsOpened(!errorTabIsOpened);
  //   }
  // };

  const renderModalDialog = (): React.ReactElement => {
    if (modal.dialogParams) {
      return <MessageDialog />;
    }
    return <></>;
  };

  const loadingProject = () => {
    return (
      <div className="splash_screen_background">
        <SplashScreen
          className="splash_screen ABB_CommonUX_LoginScreen__light"
          productName={variables.MainStandard === 'UL' ? t('STR_TITLE_DOC_UL') : t('STR_TITLE_DOC')}
          productFullName={t('CURVES_WEB_LOADING_PROJECT')}
          productSubName={compute.loadingMessage}
          progress={compute.loadingPercentage}
        />
      </div>
    );
  };

  const deviceProps = () => {
    return (
      <AppLeftPane
        // visibility={collapsed ? 'hidden' : 'full'}
        showRightBorder={true}
        showVisibilityToggle={false}
        className={'DOCweb__CommonUX_AppLeftPane_Stencil'}
      >
        <LeftPanelDeviceTable selectedDeviceId={project.selectedDeviceId} />
      </AppLeftPane>
    );
  };

  const curvesToolbar = (): React.ReactElement => {
    return (
      <AppLeftPane
        className={`ABB_CommonUX_AppLeftPane__expanded_${deviceType}`}
        style={{ borderLeft: '1px solid #848484' }}
        showVisibilityToggle={false}
        visibility={'full'}
      >
        <CurvesToolbar />
      </AppLeftPane>
    );
  };

  const orientationType = window.orientation;
  const isPortrait = (orientationType === 0 || orientationState === 0) && isMobile;
  let customDevice: ICustomCurve | undefined;
  let customDeviceId: string | undefined;
  if ((!project.selectedDeviceId || !curves.curve2Show) && curves.checkedCurves.length > 0) {
    customDeviceId = Object.keys(curves.customCurves.devices).find((customId) => curves.checkedCurves.includes(customId));
    if (customDeviceId) {
      customDevice = curves.customCurves.devices[customDeviceId];
    }
  }

  return (
    <AuthenticatedTemplate>
      <Suspense fallback={<Login />}>
        <AppContainer>
          <AutoSave />
          <NotificationContainer
            notifications={compute.notifications.map((notification) => ({
              ...notification,
              text: t(notification.text),
            }))}
            style={{ position: 'absolute', top: '0px', right: '0px' }}
            actionHandler={{
              remove: () => {
                // console.log(notificationId);
              },
            }}
          />
          <Navbar />
          <AppContent>
            {isPortrait || isMobileOnly ? null : deviceProps()}
            <AppContent className="DOCweb__CommonUX_AppContent_DrawingArea">
              <AppContent>
                <div className="panel-group-container" id="app">
                  <DiagramTabs selectedDeviceId={(project.selectedDeviceId as string) || (customDeviceId as string)} />
                  <GraphTab
                    selectedDeviceId={(project.selectedDeviceId as string) || (customDeviceId as string)}
                    initialDiagram={''}
                    userDevice={customDevice}
                  />
                  <Errors />
                  <div id="diagram-tabs-container"></div>
                </div>
              </AppContent>
            </AppContent>
            {isPortrait ? <AppLeftPane /> : curvesToolbar()}
          </AppContent>
          {renderCurrentModal()}
          {renderModalDialog()}
          {compute.projectIsLoading ? loadingProject() : null}
        </AppContainer>
      </Suspense>
    </AuthenticatedTemplate>
  );
};

export default App;
