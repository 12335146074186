import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  symbol: 'MOTOR_FEEDER',
  objectType: EDeviceObjectType.FEEDER,
  Locked: false,
  deviceType: EDeviceType.MOTOR_FEEDER,

  protection: 'TMP',
  contactor: 'CONTACTOR',
  softstarter: 'SOFTSTARTER',
  overloadRelayDefault: 'OLR',
  cable: 'CABLE_LLLNPE',
  motor: 'MOTOR',

  MotorFeederStarterTypeFilter: 'All possibilities',
  MotorFeederStarterTypeFilterList: [],

  MotorFeederCoordinationTypeFilter: 'All possibilities',
  MotorFeederCoordinationTypeFilterList: [],

  MotorFeederProtectionFilter: 'All possibilities',
  MotorFeederProtectionFilterList: [],

  MotorFeederOlrFilter: 'All possibilities',
  MotorFeederOlrFilterList: [],

  motorEfficiencyClass: 'All possibilities',
  motorEfficiencyClassList: ['All possibilities', 'IE1/IE2', 'IE3'],

  MotorFeederContactorFilter: 'All possibilities',
  MotorFeederContactorFilterList: [],
  bStarContactor: null,
  MotorFeederSymbols: [],
  motorSymbol: '',
  initialized: false,
};
