/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Select from 'components/generic/selectContainer';
import { Variables } from 'types/variables/store';
import { distributionTable, projectCommonData } from '../../constants';
import { updateSettings } from './actions';

const Settings: FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wizard = useSelector((state) => state.wizard);
  // const variables = useSelector((state) => state.variables);

  const lvDefaultsBody = () => {
    const data = wizard.variables as Variables.State;
    return (
      <>
        {/* {variables.MainStandard === 'UL' ? (
          <GridRow key={1}>
            <GridColumn col={8}>
              <hr />
            </GridColumn>
          </GridRow>
        ) : null} */}
        <GridRow key={2}>
          <GridColumn col={8}>
            <h4>{t('DOCWEB_MODAL_LV_DEFAULT_VALUES')}</h4>
          </GridColumn>
        </GridRow>
        <GridRow id="networkSettingsWizard" verticalAlignment={'bottom'} key={3}>
          <GridColumn col={2}>
            <Select
              cols={[7, 8, 0]}
              className={'info-project-input'}
              name={'PlantLvUn'}
              onChange={(e) => dispatch(updateSettings(e))}
              type={'number'}
              selectList={projectCommonData.voltageLow}
              data={data}
              label={'[V]'}
              disabled={wizard.creatingProject}
            />
          </GridColumn>
          <GridColumn col={2}>
            <Select
              cols={[7, 8, 0]}
              label={''}
              name={'PlantLineType'}
              onChange={(e) => dispatch(updateSettings(e))}
              type={'string'}
              selectList={projectCommonData.lineType}
              data={data}
              disabled={wizard.creatingProject}
            />
          </GridColumn>
          <GridColumn col={2}>
            <Select
              cols={[7, 8, 0]}
              label={''}
              name={'PlantEarthingSystem'}
              onChange={(e) => dispatch(updateSettings(e))}
              type={'string'}
              selectList={distributionTable[data.PlantLineType]}
              data={data}
              disabled={wizard.creatingProject}
            />
          </GridColumn>
          <GridColumn col={2}>
            <Select
              cols={[7, 8, 0]}
              label={'DOCWEB_DIMENSIONS_HZ'}
              className={'info-project-input'}
              name={'PlantFrequency'}
              onChange={(e) => dispatch(updateSettings(e))}
              type={'number'}
              selectList={projectCommonData.frequency}
              data={data}
              disabled={wizard.creatingProject}
            />
          </GridColumn>
        </GridRow>
      </>
    );
  };

  return <Grid>{lvDefaultsBody()}</Grid>;
};

export default Settings;
