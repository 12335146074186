import React, { useEffect } from 'react';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { Table, TableHeader } from 'components/generic/table';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '@abb/abb-common-ux-react';
import EditableSelect from 'components/generic/editableSelect';
import { useDispatch, useSelector } from 'store';
import { ICoordsPage } from 'types/modal/coordinationModal/types';
import { selectionList, timeSelectionList } from './constants';
import { inputUpdateHandler, onFocusOutHandler, onModalClose } from './actions';

const CoordinationXAxisComponent: React.FunctionComponent<ICoordsPage> = ({
  selectedDevice,
  selectedDeviceId,
  diagramType,
}): React.ReactElement => {
  const dispatch = useDispatch();

  const curves = useSelector((state) => state.curves);

  const { t } = useTranslation();

  useEffect(() => {
    return () => dispatch(onModalClose(selectedDeviceId, 'coordsY', 'coordinationYvalue'));
  }, []);

  let label = '';
  if (diagramType === 'Time/Current') {
    label = '* Time [s]';
  }
  if (diagramType === 'ShortCircuit/Energy') {
    label = '* Energy kA\u00B2S';
  }
  if (diagramType === 'ShortCircuit/Peak') {
    label = '* Peak [kA]';
  }

  const customInputs = [
    {
      index: 2,
      input: (item: Record<string, unknown>) => (
        <div>
          <span>{item.min === '' ? item.min : (parseFloat(item.min as string) / 1000).toFixed(3)}</span>
        </div>
      ),
    },
    {
      index: 3,
      input: (item: Record<string, unknown>) => (
        <div>
          <span>{item.max === '' ? item.max : (parseFloat(item.max as string) / 1000).toFixed(3)}</span>
        </div>
      ),
    },
  ];

  return (
    <Grid>
      <GridRow>
        <GridColumn col={1}>
          <EditableSelect
            cols={[7, 8, 0, 0]}
            type={'number'}
            onChange={(e) => dispatch(inputUpdateHandler(e))}
            onFocusOut={(e) => dispatch(onFocusOutHandler(e))}
            className={'info-project-input'}
            name={'coordinationYValue'}
            label={label}
            selectList={diagramType === 'Time/Current' ? timeSelectionList : selectionList}
            valueList={diagramType === 'Time/Current' ? timeSelectionList : selectionList}
            step={0.1}
            // disabled={this.props.curves.coordinationRequestRunning}
            data={selectedDevice}
          />
        </GridColumn>
        {curves.coordinationRequestRunning ? (
          <GridColumn col={1}>
            <LoadingIndicator className={'previewText'} sizeClass={'small'} type={'radial'} determinate={false} color={'blue'} />
          </GridColumn>
        ) : null}
      </GridRow>
      <hr></hr>
      {selectedDevice && selectedDevice.coordsY ? (
        <GridRow>
          <GridColumn>
            <Table
              data={selectedDevice.coordsY as Record<string, unknown>[]}
              valueAttr={'DeviceId'}
              customInputs={customInputs}
              maxHeight={150}
              specificColumnWidth={['200px', '200px', '100px', '100px']}
              selectedValue=""
            >
              <TableHeader attr={'deviceSign'} key={'coordsYdeviceSign'}>
                {t('DOCWEB_MODAL_DEVICE')}
              </TableHeader>
              <TableHeader attr={'switchboardName'} key={'coordsYswitchboardSign'}>
                {t('STRING_LOCATION')}
              </TableHeader>
              <TableHeader attr={'min'} key={'coordsYminValue'}>
                {t('DOCWEB_MODAL_MINKA')}
              </TableHeader>
              <TableHeader attr={'max'} key={'coordsYmaxValue'}>
                {t('DOCWEB_MODAL_MAXKA')}
              </TableHeader>
            </Table>
          </GridColumn>
        </GridRow>
      ) : null}
    </Grid>
  );
};

export default CoordinationXAxisComponent;
