/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-use-before-define */
import CurveTypeRange from 'curves/curveType/CurveTypeRange';
import { CurveCoord } from 'types/curves';
import { GRID_OFFSET_X } from './Constants';
import CurveTypes from './curveType';

function clipPoints(p: CurveCoord, v: CurveCoord, inPoints: Array<CurveCoord>, bCycle: boolean) {
  let lastPt;
  if (bCycle && inPoints.length > 0) {
    lastPt = inPoints[inPoints.length - 1];
  } else if (inPoints.length > 0) {
    [lastPt] = inPoints;
  } else {
    lastPt = { x: 0, y: 0 };
  }
  let bLastIn = isIn(p, v, lastPt);
  const ret = [];
  for (let i = 0; i < inPoints.length; i += 1) {
    const bIn = isIn(p, v, inPoints[i]);
    if (bIn) {
      if (!bLastIn) {
        ret.push(compPt(p, v, lastPt, inPoints[i]));
      }
      ret.push(inPoints[i]);
    } else if (bLastIn) {
      ret.push(compPt(p, v, lastPt, inPoints[i]));
    }
    bLastIn = bIn;
    lastPt = inPoints[i];
  }
  return ret;
}
function sub(p1: CurveCoord, p2: CurveCoord) {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
}
function product(v1: CurveCoord, v2: CurveCoord) {
  return v1.x * v2.x + v1.y * v2.y;
}
function isIn(p: CurveCoord, v: CurveCoord, pt: CurveCoord) {
  return product(sub(pt, p), v) > 0;
}
function mul(v: CurveCoord, c: number) {
  return { x: v.x * c, y: v.y * c };
}
function add(p: CurveCoord, v: CurveCoord) {
  return { x: p.x + v.x, y: p.y + v.y };
}
function compPt(p: CurveCoord, v: CurveCoord, pt1: CurveCoord, pt2: CurveCoord) {
  const vp = sub(pt2, pt1);
  const a = product(sub(p, pt1), v);
  const b = product(sub(pt2, p), v);
  if (Math.abs(a + b) < 1e-9) {
    return pt1;
  }
  return add(pt1, mul(vp, a / (a + b)));
}

export default class GridSizeConfig {
  initialWidth: number;

  initialHeight: number;

  width: number;

  height: number;

  curveType: CurveTypes;

  minX: number;

  maxX: number;

  minY: number;

  maxY: number;

  originX: number;

  nx: number;

  ny: number;

  nxOffset: number;

  nyOffset: number;

  dx: number;

  dy: number;

  constructor(width: number, height: number, scale: number, curveType: CurveTypes) {
    this.initialWidth = width;
    this.initialHeight = height;
    this.width = width * scale;
    this.height = height * scale;
    this.curveType = curveType;
    const range = CurveTypeRange[this.curveType as keyof typeof CurveTypeRange];
    this.minX = range.minX;
    this.maxX = range.maxX;
    this.minY = range.minY;
    this.maxY = range.maxY;
    this.originX = range.originX;
    // this.originY = range.originY;
    this.nx = Math.floor(Math.log10(range.maxX / range.minX));
    this.ny = Math.floor(Math.log10(range.maxY / range.minY));
    this.nxOffset = Math.floor(Math.log10(range.originX / range.minX));
    this.nyOffset = Math.floor(Math.log10(range.originY / range.minY));
    this.dx = this.width / this.nx;
    this.dy = this.height / this.ny;
  }

  adjustPoints(inPoints: Array<CurveCoord>, offX: number, offY: number, bCycle = false): Array<number> {
    const xUnit = this.width / this.nx;
    const yUnit = this.height / this.ny;
    const xOffset = this.nxOffset * xUnit;
    const yOffset = this.nyOffset * yUnit;
    const points = new Array<CurveCoord>();
    for (let i = 0; i < inPoints.length; i += 1) {
      points.push({ x: inPoints[i].x * xUnit + xOffset, y: this.height - (inPoints[i].y * yUnit + yOffset) });
    }
    const minX = -offX;
    const maxX = this.initialWidth - offX;
    const minY = -offY;
    const maxY = this.initialHeight - offY;

    const clipped = clipPoints(
      { x: minX, y: 0 },
      { x: 1, y: 0 },
      clipPoints(
        { x: 0, y: minY },
        { x: 0, y: 1 },
        clipPoints({ x: maxX, y: 0 }, { x: -1, y: 0 }, clipPoints({ x: 0, y: maxY }, { x: 0, y: -1 }, points, bCycle), bCycle),
        bCycle
      ),
      bCycle
    );
    const ret = [];
    for (let i = 0; i < clipped.length; i += 1) {
      ret.push(clipped[i].x);
      ret.push(clipped[i].y);
    }
    return ret;
  }

  adjustPoint(point: CurveCoord): CurveCoord {
    const xUnit = this.width / this.nx;
    const yUnit = this.height / this.ny;
    const xOffset = this.nxOffset * xUnit;
    const yOffset = this.nyOffset * yUnit;
    return {
      x: point.x * xUnit + xOffset,
      y: this.height - (point.y * yUnit + yOffset),
    };
  }

  revertAdjustedPoint(point: CurveCoord): CurveCoord {
    const xUnit = this.width / this.nx;
    const yUnit = this.height / this.ny;
    const xOffset = this.nxOffset * xUnit;
    const yOffset = this.nyOffset * yUnit;
    return {
      x: (point.x - GRID_OFFSET_X - xOffset) / xUnit,
      y: (this.height - point.y - yOffset) / yUnit,
    };
  }

  adjustXPoint(x: number): number {
    const xUnit = this.width / this.nx;
    const xOffset = this.nxOffset * xUnit;
    return x * xUnit + xOffset;
  }

  getLabelForX(x: number): string {
    /**
     * TODO:
     * Try to use convert from CurveTypeAxisFormatter
     * And fix toFixed(2) to not show symbols after comma if value > 100
     * Or only 1 symbol after comma if value > 10 < 100
     */
    let label;
    const labelValue = 10 ** (Math.log10(this.originX) + x);
    if (labelValue === 1000000) {
      label = `${(labelValue / 10 ** 6).toFixed(2)}MA`;
    } else if (labelValue < 1) {
      label = `${(labelValue * 10 ** 3).toFixed(0)}mA`;
    } else if (labelValue >= 1000) {
      label = `${(labelValue / 10 ** 3).toFixed(2)}kA`;
    } else {
      label = `${labelValue.toFixed(1)}A`;
    }
    return label;
  }
}
