import { EDeviceObjectType, EDeviceType, EEarthingSystem, ELvLineType } from '../enums.d';
import { defaultElement } from '../default-element';

export default {
  ...defaultElement,
  objectType: EDeviceObjectType.MvCircuitBreakerW,
  deviceType: EDeviceType.CIRCUITBREAKERMVW,
  symbol: 'CIRCUITBREAKERMVW',
  Locked: false,

  LineType: ELvLineType.LLL,
  EarthingSystem: EEarthingSystem.IT,
};
