/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { PROJECT, STANDARDS } from 'types/constants.d';
import { StandardsKnownActions } from 'types/known-actions';
import * as StandardsNamespace from 'types/standards/index.d';
// import * as ActionConstants from 'types/constants.d';

export const initialState: StandardsNamespace.State = {
  ScStandard: -1,
  CableStandard: -1,
  cableStandardFetched: false,
  ScStandardList: [],
  CableStandardList: [],
  powerSupply: StandardsNamespace.EPowerSupply.LVU,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: StandardsKnownActions): StandardsNamespace.State =>
  produce(state, (draft) => {
    switch (action.type) {
      case STANDARDS.SET_STANDARD:
        return { ...state, ...action.payload };
      case PROJECT.LOAD_PROJECT:
        return action.payload.projectState.standards;
      default:
        break;
    }
  });
