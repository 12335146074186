/* eslint-disable consistent-return */
import { State } from 'types/docWebData';
import { DocWebDataKnownActions } from 'types/known-actions';
import { EPaperAlign, EPaperLayout, EPaperList } from 'types/project/store.d';
import produce from 'immer';
import _ from 'lodash';
import labelsDefaultState from './labels';

export const initialState: State = {
  connections: {},
  pages: {
    block: {
      name: 'Block Diagram',
      number: 0,
      editable: false,
      errors: [],
      frame: EPaperList.A3,
      pageAlign: EPaperAlign.Horizontal,
      layout: EPaperLayout.Free,
    },
    'e6965058-1be8-4db0-aee3-bbbbc8aef654': {
      name: `New page 1`,
      editable: true,
      number: 1,
      errors: [],
      pageAlign: EPaperAlign.Horizontal,
      frame: EPaperList.A3,
      layout: EPaperLayout.Free,
    },
  },
  logo: {},
  labels: { ...labelsDefaultState },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: DocWebDataKnownActions): State =>
  produce(state, () => {
    switch (action.type) {
      case 'PROJECT/LOAD_PROJECT':
        return _.merge({}, initialState, {
          pages: action.payload.pages,
          connections: action.payload.connections,
          labels: action.payload.labels,
          logo: action.payload.logo,
        });
      default:
        break;
    }
  });
