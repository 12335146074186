/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Icon, Tooltip, WithTooltip } from '@abb/abb-common-ux-react';
import { useTranslation } from 'react-i18next';
import { ITable, ITableCloneParams, ITableRow } from 'types/components/table';
import { uuid } from 'actions/utils';
import UnitsDataAdapter from 'calculations/UnitsDataAdapter';
import { UnitsType } from 'types/calculations';
import { useDispatch } from 'store';
import TableHeader from './TableHeader';
import Input from '../inputContainer/Input';

import './style.scss';
import Radio from '../Radio';

const Table: FunctionComponent<ITable> = ({
  children,
  onRowClick,
  data,
  onDelete,
  onEdit,
  onEditDisabled,
  onDeleteDisabled,
  searchable,
  selectedValue,
  valueAttr,
  customName,
  disabled,
  multipleSelections,
  customInputs,
  noDataText,
  maxHeight,
  specificColumnWidth,
  additionalClassName,
  cellsClassName,
  noRadio,
  onAdd,
}): React.ReactElement => {
  const [state, setState] = useState({
    spliceData: [],
    sort: '',
    search: '',
    Type: '',
    ascending: false,
  });
  const [numberOfRows, setNumberOfRows] = useState(50);
  const [tableUuid] = useState(uuid());
  const [listener, setListener] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableAttributes, setTableAttributes] = useState([] as Array<string> | Array<string | string[]>);
  useEffect(() => {
    spliceData();
  }, []);

  const spliceHandler = () => {
    let element = $(`.${tableUuid}`);
    element = $(element[element.length - 1]);
    if (element !== undefined) {
      if ((element.scrollTop() ?? 0) + Math.floor(element.innerHeight() ?? 0) >= element[0].scrollHeight - 1) {
        spliceData();
      }
    }
  };

  useEffect(() => {
    let element = $(`.${tableUuid}`);
    element = $(element[element.length - 1]);
    if (listener) {
      element.off();
    }
    element.on('scroll', spliceHandler);
    setListener(true);
  }, [data, numberOfRows]);

  useEffect(() => {
    const attributes: string[] | string[][] = [];
    if (children && Array.isArray(children)) {
      // eslint-disable-next-line no-return-assign
      (children as { props: { attr: string | string[] } }[]).forEach((child, key) => (attributes[key] = child.props.attr));
    }
    setTableAttributes(attributes);
  }, []);
  const spliceData = () => {
    if (data && numberOfRows < data.length) {
      setNumberOfRows(numberOfRows + 50);
    }
  };

  const onHeaderClick = (attr: string, ascending: boolean) => {
    setState({ ...state, sort: attr, ascending });
  };

  const sort = (a: Record<string, unknown>, b: Record<string, unknown>): number => {
    if (!state.sort) {
      return 1;
    }
    const sortableA = a[state.sort] as string;
    const sortableB = b[state.sort] as string;
    if (state.ascending) {
      return sortableA > sortableB ? -1 : 1;
    }
    return sortableA < sortableB ? -1 : 1;
  };

  // TODO:, i'am sure we will need to debug this search to work correctly with translated things
  const search = (item: Record<string, unknown>) => {
    if (!state.search || (state.search && state.search.trim().length === 0)) {
      return true;
    }
    return !!tableAttributes.some((attribute) => {
      const projectNameKeyWords = Array.isArray(attribute)
        ? attribute.map((attr) => `${item[attr] as string}`.toUpperCase()).join(' ')
        : `${item[attribute] as string}`.toUpperCase();

      const searchKeyWords = `${state.search.toString().trim()}`.toUpperCase().split(' ');

      if (projectNameKeyWords && projectNameKeyWords !== 'UNDEFINED') {
        // return searchKeyWords.some((keyword) => {
        return searchKeyWords.every((keyword) => {
          return projectNameKeyWords.includes(keyword);
        });
      }
      return false;
    });
  };

  const onRowClickAction = (item: ITableRow['data']) => {
    const event = { data: item } as ITableRow;
    if (!disabled && onRowClick) {
      if (valueAttr) {
        event.name = customName || valueAttr;
        event.value = item[valueAttr] as string;
      }
      if (selectedValue && valueAttr && selectedValue === item[valueAttr] && !multipleSelections) {
        return;
      }
      onRowClick(event);
    }
  };

  const newChildren = Array.isArray(children) ? children : [children];
  const newNewChildren = newChildren.map((child, key) =>
    React.cloneElement(child as React.ReactElement<ITableCloneParams>, {
      onHeaderClick,
      sort: state.sort,
      delete: onDelete !== undefined,
      width: specificColumnWidth ? specificColumnWidth[key] : 'auto',
    })
  );
  const newData = [...data].filter(search).sort(sort).splice(0, numberOfRows);
  return (
    <div style={{ marginLeft: '5px' }}>
      {searchable ? (
        <Input
          key="search"
          cols={[3, 0, 0, 0]}
          type="text"
          name="search"
          // label={t('Search')}
          onChange={(d) => setState({ ...state, search: d.value })}
          data={state}
          className={'DOCweb_CommonUX__Table__Search'}
          value={state.search}
          icon="abb/search"
          placeholder="Search"
        />
      ) : null}
      {newData ? (
        <table className={`DOCweb_CommonUX__Table ${additionalClassName || ''}`} key="table">
          <thead>
            <tr>
              {!noRadio ? (
                <TableHeader width={'30px'} sort={false} noHover={true}>
                  {onAdd ? (
                    <Icon
                      name={`abb/new-document`}
                      sizeClass={'small'}
                      className={'DOCweb_CommonUX__TableHeader_Icon'}
                      onClick={() => onAdd()}
                    />
                  ) : (
                    ''
                  )}
                </TableHeader>
              ) : undefined}
              {newNewChildren}
              {onDelete ? (
                <TableHeader width="30px" sort={false}>
                  &nbsp;
                </TableHeader>
              ) : undefined}
              {onEdit ? (
                <TableHeader width={'30px'} sort={false}>
                  &nbsp;
                </TableHeader>
              ) : undefined}
            </tr>
          </thead>
          <tbody style={{ maxHeight: `${maxHeight ?? 'undefined'}px` }} className={tableUuid}>
            {(!newData || newData.length <= 0) && noDataText ? (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={newChildren.length}>{t(noDataText)}</td>
              </tr>
            ) : (
              newData.map((item, key) => {
                const onEditDisabledValue = onEditDisabled ? onEditDisabled(item) : false;
                const onDeleteDisabledValue = onDeleteDisabled ? dispatch(onDeleteDisabled()) : false;
                return (
                  <tr
                    key={key}
                    className={`DOCweb_CommonUX__TableItem ${disabled ? 'DOCweb_CommonUX__TableItem_Disabled' : ''} ${
                      noRadio && !!selectedValue && item[valueAttr] === selectedValue ? 'DOCweb_CommonUX__TableItem_Selected' : ''
                    }`}
                  >
                    {!noRadio ? (
                      <td key={`${key} + tableTD0`} style={{ width: '30px', verticalAlign: 'baseline' }}>
                        <Radio
                          value={`${selectedValue as string}`}
                          name={`${selectedValue as string}`}
                          onChange={onRowClick ? () => onRowClickAction(item) : undefined}
                          checked={!!selectedValue && item[valueAttr] === (selectedValue as string)}
                        />
                      </td>
                    ) : undefined}
                    {newNewChildren.map((child, tdKey) => {
                      const tdText = Array.isArray(child.props.attr)
                        ? child.props.attr.map(
                            (attrName) => `${(item[attrName as string] as string) ? (item[attrName as string] as string) : ''} `
                          )
                        : UnitsDataAdapter.cutUnits(child.props.attr as UnitsType, item[child.props.attr] as string);
                      return customInputs && customInputs.find((input) => input.index === tdKey) ? (
                        <td
                          className={cellsClassName ?? ''}
                          onClick={() => onRowClickAction(item)}
                          key={tdKey}
                          style={{ width: specificColumnWidth ? `${specificColumnWidth[tdKey]}` : 'auto' }}
                          role="presentation"
                        >
                          {customInputs.find((input) => input.index === tdKey)?.input(item)}
                        </td>
                      ) : (
                        <td
                          className={cellsClassName ?? ''}
                          key={tdKey}
                          onClick={() => onRowClickAction(item)}
                          style={{ width: specificColumnWidth ? `${specificColumnWidth[tdKey]}` : 'auto' }}
                          role="presentation"
                        >
                          {child.props.showTooltip && child.props.attr ? (
                            <WithTooltip>
                              <span>{tdText}</span>
                              <Tooltip>{tdText}</Tooltip>
                            </WithTooltip>
                          ) : (
                            tdText
                          )}
                        </td>
                      );
                    })}
                    {onDelete ? (
                      <td
                        style={{ width: '30px' }}
                        key={`${key}tableTD3`}
                        className={`DOCweb_CommonUX__TableItem_Delete ${onDeleteDisabledValue ? 'disabled' : ''}`}
                        onClick={
                          !onDeleteDisabledValue
                            ? (e) => {
                                e?.stopPropagation();
                                onDelete(item);
                              }
                            : undefined
                        }
                      >
                        <Icon name="abb/trash" sizeClass={'small'} />
                      </td>
                    ) : undefined}
                    {onEdit ? (
                      <td
                        style={{ width: '30px' }}
                        key={`${key}tableTD4`}
                        className={`DOCweb_CommonUX__TableItem_Delete ${onEditDisabledValue ? 'disabled' : ''}`}
                        onClick={
                          !onEditDisabledValue
                            ? (e) => {
                                e.stopPropagation();
                                onEdit(item);
                              }
                            : undefined
                        }
                      >
                        <Icon name="abb/settings" sizeClass={'small'} />
                      </td>
                    ) : undefined}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default Table;
