export enum ESymbolStatus {
  NotChecked = 'UnChecked',
  Error = 'Error',
  OK = 'OK',
  Warning1 = 'Warning',
  Warning2 = 'CurvesWarning',
  Warning3 = 'PermanentWarning',
  Offline = 'Offline',
}

export enum EDeviceType {
  RCCB = 'app.RCCB',
  DISCONNECTORLV = 'app.DISCONNECTORLV',
  MOTOR = 'app.MOTOR',
  CB_TM = 'app.CB_TM',
  FUSEBASE = 'app.FUSEBASE',
  CB_FEEDER = 'app.CB_FEEDER',
  TRAFO_FEEDER = 'app.TRAFO_FEEDER',
  MOTOR_FEEDER = 'app.MOTOR_FEEDER',
  MEDICAL_ROOM_FEEDER = 'app.MEDICAL_ROOM_FEEDER',
  HEATER_FEEDER = 'app.HEATER_FEEDER',
  VEHICLE_CHARGER_FEEDER = 'app.VEHICLE_CHARGER_FEEDER',
  CONTACTOR = 'app.CONTACTOR',
  MMS = 'app.MMS',
  OLR = 'app.OLR',
  SOFTSTARTER = 'app.SOFTSTARTER',
  MVEDISC = 'app.MVEDISC',
  CIRCUITBREAKERMV = 'app.CIRCUITBREAKERMV',
  CIRCUITBREAKERMVW = 'app.CIRCUITBREAKERMVW',
  CIRCUITBREAKERMV_GENERIC = 'app.CIRCUITBREAKERMV_GENERIC',
  MVDISC = 'app.MVDISC',
  MVDISC_DOWN = 'app.MVDISC_DOWN',
  MVSWITCH = 'app.MVSWITCH',
  FUSEMV = 'app.FUSEMV',
  CONNECTOON = 'app.CONNECTOON',
  BUSBAR = 'app.BUSBAR',
  WIREMV = 'app.WIREMV',

  WIRELV = 'app.WIRELV',
  WIREMV_IN = 'app.WIREMV_IN',
  UPS = 'app.UPS',
  UPS_2_INPUT = 'app.UPS_2_INPUT',
  UPS_BYPASS = 'app.UPS_BYPASS',
  UPS_NOBYPASS = 'app.UPS_NOBYPASS',
  BUSDUCT = 'app.BUSDUCT',
  ATS = 'app.ATS',
  LOAD = 'app.LOAD',
  LV_SWITCHBOARD = 'app.LV_SWITCHBOARD',
  MV_SWITCHBOARD = 'app.MV_SWITCHBOARD',
  BLOCK_UTILITY = 'app.BLOCK_UTILITY',
  BLOCK_TRAFO = 'app.BLOCK_TRAFO',
  BLOCK_GENERATOR = 'app.BLOCK_GENERATOR',
  BLOCK_UPS = 'app.BLOCK_UPS',
  TEXTAREA = 'app.TEXTAREA',
  LINE = 'app.LINE',
  CURVE_DIAGRAM = 'app.CURVE_DIAGRAM',
  DOC_CURVES = 'app.DOC_CURVES',
  TEXTLINK = 'app.TEXTLINK',
  UTILITY = 'app.UTILITY',
  UTILITY_GENERIC = 'app.UTILITY_GENERIC',
  UTILITY_SMALL = 'app.UTILITY_SMALL',
  UTILITY_MV_SHORT = 'app.UTILITY_MV_SHORT',
  UTILITY_MV = 'app.UTILITY_MV',
  UTILITY_MV_SMALL = 'app.UTILITY_MV_SMALL',
  GENERATOR = 'app.GENERATOR',
  GENERATOR_GENERIC = 'app.GENERATOR_GENERIC',
  GENERATOR_SMALL = 'app.GENERATOR_SMALL',
  GENERATOR_BIO = 'app.GENERATOR_BIO',
  GENERATOR_PHOTO = 'app.GENERATOR_PHOTO',
  GENERATOR_WIND = 'app.GENERATOR_WIND',
  TRAFO2 = 'app.TRAFO2',
  TRAFO2_GENERIC = 'app.TRAFO2_GENERIC',
  TRAFO3 = 'app.TRAFO3',
  ARRIVAL = 'app.ARRIVAL',
  DEPARTURE = 'app.DEPARTURE',
  LINK = 'app.LINK',
  IMPEDANCE = 'app.IMPEDANCE',
  CAPACITORBANKS = 'app.CAPACITORBANKS',
  BME_IN = 'app.BME_IN',
  BME_OUT = 'app.BME_OUT',
  BME = 'app.BME',
  DRC_IN = 'app.DRC_IN',
  DRC_OUT = 'app.DRC_OUT',
  DRS_LEFT = 'app.DRS_LEFT',
  DRS_RIGHT = 'app.DRS_RIGHT',
  HBC_IN = 'app.HBC_IN',
  HBC_OUT = 'app.HBC_OUT',
  HBS_LEFT = 'app.HBS_LEFT',
  HBS_RIGHT = 'app.HBS_RIGHT',
  RLC_IN = 'app.RLC_IN',
  RLC_OUT = 'app.RLC_OUT',
  RRC_IN = 'app.RRC_IN',
  RRC_OUT = 'app.RRC_OUT',
  SBC_IN = 'app.SBC_IN',
  SBC_OUT = 'app.SBC_OUT',
  SBC_W_IN = 'app.SBC_W_IN',
  SBC_W_OUT = 'app.SBC_W_OUT',
  SBM = 'app.SBM',
  SBR_LEFT = 'app.SBR_LEFT',
  SBR_RIGHT = 'app.SBR_RIGHT',
  SBS_LEFT = 'app.SBS_LEFT',
  SBS_RIGHT = 'app.SBS_RIGHT',
  SBS_W_RIGHT = 'app.SBS_W_RIGHT',
  SDC_IN = 'app.SDC_IN',
  SDC_OUT = 'app.SDC_OUT',
  SDC = 'app.SDC',
  SDD = 'app.SDD',
  SDM = 'app.SDM',
  SDS_LEFT = 'app.SDS_LEFT',
  SDS_RIGHT = 'app.SDS_RIGHT',
  SFC = 'app.SFC',
  SFS_LEFT = 'app.SFS_LEFT',
  SFV = 'app.SFV',
  TRAFOENCLOS = 'app.TRAFOENCLOS',
  UMP = 'app.UMP',
  WBC_IN = 'app.WBC_IN',
  WBC_OUT = 'app.WBC_OUT',
  WBS_LEFT = 'app.WBS_LEFT',
  WBS_RIGHT = 'app.WBS_RIGHT',
  MULTIMETER = 'app.MULTIMETER',

  DRC = 'app.DRC',
  HBC = 'app.HBC',
  RLC = 'app.RLC',
  RRC = 'app.RRC',
  SBC = 'app.SBC',
  SBC_W = 'app.SBC_W',
  SBC_SVG = 'app.SBC.svg',
  WBC = 'app.WBC',
  IF_IN = 'app.IF_IN',
  ZS2_IF_IN = 'app.ZS2_IF_IN',
  IF_OUT = 'app.IF_OUT',
  ZS2_IF_OUT = 'app.ZS2_IF_OUT',
  M = 'app.M',
  ZS2_M = 'app.ZS2_M',
  BT_LEFT = 'app.BT_LEFT',
  ZS2_BT_LEFT = 'app.ZS2_BT_LEFT',
  BT_RIGHT = 'app.BT_RIGHT',
  ZS2_BT_RIGHT = 'app.ZS2_BT_RIGHT',
  RM_LEFT = 'app.RM_LEFT',
  ZS2_RM_LEFT = 'app.ZS2_RM_LEFT',
  RM_RIGHT = 'app.RM_RIGHT',
  ZS2_RM_RIGHT = 'app.ZS2_RM_RIGHT',

  // generic TUs
  INCOMING_DIR = 'app.INCOMING_DIR',
  INCOMING_DRC = 'app.INCOMING_DRC',
  INCOMING_FIXCBD = 'app.INCOMING_FIXCBD',
  INCOMING_WITHCBD = 'app.INCOMING_WITHCBD',
  INCOMING_REMCBD = 'app.INCOMING_REMCBD',
  INCOMING_D = 'app.INCOMING_D',
  INCOMING_WITHCB = 'app.INCOMING_WITHCB',

  OUTGOING_DIR = 'app.OUTGOING_DIR',
  OUTGOING_FIXCBD = 'app.OUTGOING_FIXCBD',
  OUTGOING_WITHCBD = 'app.OUTGOING_WITHCBD',
  OUTGOING_REMCBD = 'app.OUTGOING_REMCBD',
  OUTGOING_D = 'app.OUTGOING_D',
  OUTGOING_FUXD = 'app.OUTGOING_FUXD',
  OUTGOING_WITHCB = 'app.OUTGOING_WITHCB',

  BUSTIE_FIXCBD_RIGHT = 'app.BUSTIE_FIXCBD_RIGHT',
  BUSTIE_WITHCBD_RIGHT = 'app.BUSTIE_WITHCBD_RIGHT',
  BUSTIE_REMCBD_RIGHT = 'app.BUSTIE_REMCBD_RIGHT',
  BUSTIE_D_RIGHT = 'app.BUSTIE_D_RIGHT',
  BUSTIE_WITHCB_RIGHT = 'app.BUSTIE_WITHCB_RIGHT',
  BUSTIE_FUXD_LEFT = 'app.BUSTIE_FUXD_LEFT',
  RISER_LEFT = 'app.RISER_LEFT',
  RISER_RIGHT = 'app.RISER_RIGHT',

  MEASUREMENT = 'app.MEASUREMENT',
  MEASUREMENT_SFV = 'app.MEASUREMENT_SFV',

  FEEDER = 'app.FEEDER',
  TYPICAL_FEEDER = 'app.TYPICAL_FEEDER',

  // CIRCUITBREAKERMV_SMALL = 'app.CIRCUITBREAKERMV_SMALL',
  // Not used in docweb but present in symbols
  // CABLE_LLLN = 'app.CABLE_LLLN',
  // CABLE_LLLNPE = 'app.CABLE_LLLNPE',
  // CABLE_LPEN = 'app.CABLE_LPEN',
  // CB_MORC = 'app.CB_MORC',
  // CB_MORCTO_W = 'app.CB_MORCTO_W',
  // CB_TMRC_W = 'app.CB_TMRC_W',
  // DT_AMP_1F = 'app.DT_AMP_1F',
  // DT_AMP_3F = 'app.DT_AMP_3F',
  // TRAFO2_13 = 'app.TRAFO2_13',
  // DT_COMM_A = 'app.DT_COMM_A',
  // DT_COMM_V = 'app.DT_COMM_V',
  // DT_DISC = 'app.DT_DISC',
  // DT_ENERMETER_3F = 'app.DT_ENERMETER_3F',
  // DT_ENERMETER_UTF = 'app.DT_ENERMETER_UTF',
  // DT_INT_ORA = 'app.DT_INT_ORA',
  MVDISCFUSE = 'app.MVDISCFUSE',
  MVSWITCHFUSE = 'app.MVSWITCHFUSE',

  // safering
  C = 'app.C',
  F = 'app.F',
  V = 'app.V',
  DE = 'app.DE',

  C_LIN = 'app.C_LIN',
  C_INT = 'app.C_INT',
  C_RIN = 'app.C_RIN',
  F_RIN = 'app.F_RIN',
  V_RIN = 'app.V_RIN',
  V_INT = 'app.V_INT',
  F_INT = 'app.F_INT',
  DE_LIN = 'app.DE_LIN',

  IF_COMPACT_SF6_IEC_IN = 'app.IF_COMPACT_SF6_IEC_IN',
  IF_COMPACT_ECO_IEC_IN = 'app.IF_COMPACT_ECO_IEC_IN',
  IF_COMPACT_SF6_IND_IN = 'app.IF_COMPACT_SF6_IND_IN',
  IF_COMPACT_ECO_IND_IN = 'app.IF_COMPACT_ECO_IND_IN',
  IF_STANDARD_SF6_IEC_IN = 'app.IF_STANDARD_SF6_IEC_IN',
  IF_STANDARD_ECO_IEC_IN = 'app.IF_STANDARD_ECO_IEC_IN',
  IF_STANDARD_SF6_IND_IN = 'app.IF_STANDARD_SF6_IND_IN',
  IF_STANDARD_ECO_IND_IN = 'app.IF_STANDARD_ECO_IND_IN',
  IF_COMPACT_SF6_IEC_OUT = 'app.IF_COMPACT_SF6_IEC_OUT',
  IF_COMPACT_ECO_IEC_OUT = 'app.IF_COMPACT_ECO_IEC_OUT',
  IF_COMPACT_SF6_IND_OUT = 'app.IF_COMPACT_SF6_IND_OUT',
  IF_COMPACT_ECO_IND_OUT = 'app.IF_COMPACT_ECO_IND_OUT',
  IF_STANDARD_SF6_IEC_OUT = 'app.IF_STANDARD_SF6_IEC_OUT',
  IF_STANDARD_ECO_IEC_OUT = 'app.IF_STANDARD_ECO_IEC_OUT',
  IF_STANDARD_SF6_IND_OUT = 'app.IF_STANDARD_SF6_IND_OUT',
  IF_STANDARD_ECO_IND_OUT = 'app.IF_STANDARD_ECO_IND_OUT',
  BT_BR_SF6_IEC = 'app.BT_BR_SF6_IEC',
  BT_BR_ECO_IEC = 'app.BT_BR_ECO_IEC',
  BT_BR__SF6_IEC = 'app.BT_BR__SF6_IEC',
  BT_BR__ECO_IEC = 'app.BT_BR__ECO_IEC',
  BT_RIGHT_SF6_IND = 'app.BT_RIGHT_SF6_IND',
  BT_RIGHT_ECO_IND = 'app.BT_RIGHT_ECO_IND',
  BR_RIGHT_SF6_IND = 'app.BR_RIGHT_SF6_IND',
  BR_RIGHT_ECO_IND = 'app.BR_RIGHT_ECO_IND',
  BT_LEFT_SF6_IND = 'app.BT_LEFT_SF6_IND',
  BT_LEFT_ECO_IND = 'app.BT_LEFT_ECO_IND',
  BR_LEFT_SF6_IND = 'app.BR_LEFT_SF6_IND',
  BR_LEFT_ECO_IND = 'app.BR_LEFT_ECO_IND',

  // TYPICAL_FEEDER = 'app.TYPICAL_FEEDER',
  // TYPICAL_BREAKER_MOTOR = 'app.TYPICAL_BREAKER_MOTOR',
  // TYPICAL_BREAKER_LOAD = 'app.TYPICAL_BREAKER_LOAD',
  // Lv Macros
  LVCABLE = 'app.LVCABLE',
  LVCB_EL_F = 'app.LVCB_EL_F',
  LVCB_EL_W = 'app.LVCB_EL_W',
  LVCB_MORC_F = 'app.LVCB_MORC_F',
  LVCB_MORC_W = 'app.LVCB_MORC_W',
  LVCB_MO_F = 'app.LVCB_MO_F',
  LVCB_MO_W = 'app.LVCB_MO_W',
  LVCB_TMRC_F = 'app.LVCB_TMRC_F',
  LVCB_TMRC_W = 'app.LVCB_TMRC_W',
  LVCB_TM_F = 'app.LVCB_TM_F',
  LVCB_TM_W = 'app.LVCB_TM_W',
  LVDISC_F = 'app.LVDISC_F',
  LVDISC_W = 'app.LVDISC_W',
  LVRCD_F = 'app.LVRCD_F',

  // auxillaries devices
  LOAD_CT = 'app.LOAD_CT',
  LOAD_DAYLIGHT_SWITCH = 'app.LOAD_DAYLIGHT_SWITCH',
  LOAD_INDICATOR = 'app.LOAD_INDICATOR',
  LOAD_OVR = 'app.LOAD_OVR',
  LOAD_PLUG = 'app.LOAD_PLUG',
  LOAD_TIME_ASTRO_SWITCH = 'app.LOAD_TIME_ASTRO_SWITCH',
  LOAD_TIME_SWITCH = 'app.LOAD_TIME_SWITCH',
  IMPEDANCE_CT = 'app.IMPEDANCE_CT',
  IMPEDANCE_PHANTOM = 'app.IMPEDANCE_PHANTOM',
  IMPEDANCE_HORZ_PHANTOM = 'app.IMPEDANCE_HORZ_PHANTOM',
  IMPEDANCE_AUX_HIGH_POWER_METER = 'app.IMPEDANCE_AUX_HIGH_POWER_METER',

  // auxillaries macros
  AUX_CT = 'app.AUX_CT',
  AUX_DAYLIGHT_SWITCH = 'app.AUX_DAYLIGHT_SWITCH',
  AUX_INDICATOR = 'app.AUX_INDICATOR',
  AUX_OVR = 'app.AUX_OVR',
  AUX_PLUG = 'app.AUX_PLUG',
  AUX_TIME_ASTRO_SWITCH = 'app.AUX_TIME_ASTRO_SWITCH',
  AUX_TIME_SWITCH = 'app.AUX_TIME_SWITCH',
  AUX_HIGH_POWER_METER = 'app.AUX_HIGH_POWER_METER',
  AUX_MULTIMETER = 'app.AUX_MULTIMETER',
  AUX_IMP_HIGH_POWER_METER = 'app.AUX_IMP_HIGH_POWER_METER',

  // loose component
  LOOSE_D = 'app.LOOSE_D',
  LOOSE_DF = 'app.LOOSE_DF',
  LOOSE_FB = 'app.LOOSE_FB',
  LOOSE_M = 'app.LOOSE_M',
  LOOSE_WB = 'app.LOOSE_WB',
}

export enum ESwitchboardDevice {
  ATS = 'app.ATS',
  RCCB = 'app.RCCB',
  DISCONNECTORLV = 'app.DISCONNECTORLV',
  CB_TM = 'app.CB_TM',
  FUSEBASE = 'app.FUSEBASE',
  CB_FEEDER = 'app.CB_FEEDER',
  TRAFO_FEEDER = 'app.TRAFO_FEEDER',
  MOTOR_FEEDER = 'app.MOTOR_FEEDER',
  HEATER_FEEDER = 'app.HEATER_FEEDER',
  VEHICLE_CHARGER_FEEDER = 'app.VEHICLE_CHARGER_FEEDER',
  CONTACTOR = 'app.CONTACTOR',
  MMS = 'app.MMS',
  OLR = 'app.OLR',
  SOFTSTARTER = 'app.SOFTSTARTER',
  MVEDISC = 'app.MVEDISC',
  CIRCUITBREAKERMV = 'app.CIRCUITBREAKERMV',
  CIRCUITBREAKERMVW = 'app.CIRCUITBREAKERMVW',
  MVDISC = 'app.MVDISC',
  MVDISC_DOWN = 'app.MVDISC_DOWN',
  MVSWITCH = 'app.MVSWITCH',
  FUSEMV = 'app.FUSEMV',
  CONNECTOON = 'app.CONNECTOON',
  BUSBAR = 'app.BUSBAR',
}

export enum ETypicalUnitNames {
  BME_IN = 'app.BME_IN',
  BME_OUT = 'app.BME_OUT',
  DRC_IN = 'app.DRC_IN',
  DRC_OUT = 'app.DRC_OUT',
  DRS_LEFT = 'app.DRS_LEFT',
  DRS_RIGHT = 'app.DRS_RIGHT',
  HBC_IN = 'app.HBC_IN',
  HBC_OUT = 'app.HBC_OUT',
  HBS_LEFT = 'app.HBS_LEFT',
  HBS_RIGHT = 'app.HBS_RIGHT',
  RLC_IN = 'app.RLC_IN',
  RLC_OUT = 'app.RLC_OUT',
  RRC_IN = 'app.RRC_IN',
  RRC_OUT = 'app.RRC_OUT',
  SBC_IN = 'app.SBC_IN',
  SBC_OUT = 'app.SBC_OUT',
  SBC_W_IN = 'app.SBC_W_IN',
  SBC_W_OUT = 'app.SBC_W_OUT',
  SBS_W_RIGHT = 'app.SBS_W_RIGHT',
  SFS_LEFT = 'app.SFS_LEFT',
  SDC_IN = 'app.SDC_IN',
  SBR_LEFT = 'app.SBR_LEFT',
  SBR_RIGHT = 'app.SBR_RIGHT',
  SDD = 'app.SDD',
  WBC_IN = 'app.WBC_IN',
  SDC_OUT = 'app.SDC_OUT',
  SFC = 'app.SFC',
  WBC_OUT = 'app.WBC_OUT',
  SBS_LEFT = 'app.SBS_LEFT',
  SBS_RIGHT = 'app.SBS_RIGHT',
  SDS_LEFT = 'app.SDS_LEFT',
  SDS_RIGHT = 'app.SDS_RIGHT',
  WBS_LEFT = 'app.WBS_LEFT',
  WBS_RIGHT = 'app.WBS_RIGHT',
  SBM = 'app.SBM',
  SDM = 'app.SDM',
  SFV = 'app.SFV',
  UMP = 'app.UMP',
  TRAFOENCLOS = 'app.TRAFOENCLOS',

  DRC = 'app.DRC',
  HBC = 'app.HBC',
  RLC = 'app.RLC',
  RRC = 'app.RRC',
  SBC = 'app.SBC',
  SBC_W = 'app.SBC_W',
  WBC = 'app.WBC',
  IF_IN = 'app.IF_IN',
  IF_OUT = 'app.IF_OUT',
  M = 'app.M',
  BT_LEFT = 'app.BT_LEFT',
  BT_RIGHT = 'app.BT_RIGHT',
  RM_LEFT = 'app.RM_LEFT',
  RM_RIGHT = 'app.RM_RIGHT',
  ZS2_IF_IN = 'app.ZS2_IF_IN',
  ZS2_IF_OUT = 'app.ZS2_IF_OUT',
  ZS2_M = 'app.ZS2_M',
  ZS2_BT_LEFT = 'app.ZS2_BT_LEFT',
  ZS2_BT_RIGHT = 'app.ZS2_BT_RIGHT',
  ZS2_RM_LEFT = 'app.ZS2_RM_LEFT',
  ZS2_RM_RIGHT = 'app.ZS2_RM_RIGHT',

  // generic TUs
  INCOMING_DIR = 'app.INCOMING_DIR',
  INCOMING_FIXCBD = 'app.INCOMING_FIXCBD',
  INCOMING_WITHCBD = 'app.INCOMING_WITHCBD',
  INCOMING_REMCBD = 'app.INCOMING_REMCBD',
  INCOMING_D = 'app.INCOMING_D',
  INCOMING_WITHCB = 'app.INCOMING_WITHCB',

  OUTGOING_DIR = 'app.OUTGOING_DIR',
  OUTGOING_FIXCBD = 'app.OUTGOING_FIXCBD',
  OUTGOING_WITHCBD = 'app.OUTGOING_WITHCBD',
  OUTGOING_REMCBD = 'app.OUTGOING_REMCBD',
  OUTGOING_D = 'app.OUTGOING_D',
  OUTGOING_FUXD = 'app.OUTGOING_FUXD',
  OUTGOING_WITHCB = 'app.OUTGOING_WITHCB',

  BUSTIE_FIXCBD_RIGHT = 'app.BUSTIE_FIXCBD_RIGHT',
  BUSTIE_WITHCBD_RIGHT = 'app.BUSTIE_WITHCBD_RIGHT',
  BUSTIE_REMCBD_RIGHT = 'app.BUSTIE_REMCBD_RIGHT',
  BUSTIE_D_RIGHT = 'app.BUSTIE_D_RIGHT',
  BUSTIE_WITHCB_RIGHT = 'app.BUSTIE_WITHCB_RIGHT',
  BUSTIE_FUXD_LEFT = 'app.BUSTIE_FUXD_LEFT',
  RISER_LEFT = 'app.RISER_LEFT',
  RISER_RIGHT = 'app.RISER_RIGHT',

  MEASUREMENT = 'app.MEASUREMENT',
  MEASUREMENT_SFV = 'app.MEASUREMENT_SFV',

  // safering
  C = 'app.C',
  F = 'app.F',
  V = 'app.V',
  DE = 'app.DE',

  C_LIN = 'app.C_LIN',
  C_INT = 'app.C_INT',
  C_RIN = 'app.C_RIN',
  F_RIN = 'app.F_RIN',
  V_RIN = 'app.V_RIN',
  V_INT = 'app.V_INT',
  F_INT = 'app.F_INT',
  DE_LIN = 'app.DE_LIN',

  // PrimeGear
  IF_COMPACT_SF6_IEC_IN = 'app.IF_COMPACT_SF6_IEC_IN',
  IF_COMPACT_ECO_IEC_IN = 'app.IF_COMPACT_ECO_IEC_IN',
  IF_COMPACT_SF6_IND_IN = 'app.IF_COMPACT_SF6_IND_IN',
  IF_COMPACT_ECO_IND_IN = 'app.IF_COMPACT_ECO_IND_IN',
  IF_STANDARD_SF6_IEC_IN = 'app.IF_STANDARD_SF6_IEC_IN',
  IF_STANDARD_ECO_IEC_IN = 'app.IF_STANDARD_ECO_IEC_IN',
  IF_STANDARD_SF6_IND_IN = 'app.IF_STANDARD_SF6_IND_IN',
  IF_STANDARD_ECO_IND_IN = 'app.IF_STANDARD_ECO_IND_IN',
  IF_COMPACT_SF6_IEC_OUT = 'app.IF_COMPACT_SF6_IEC_OUT',
  IF_COMPACT_ECO_IEC_OUT = 'app.IF_COMPACT_ECO_IEC_OUT',
  IF_COMPACT_SF6_IND_OUT = 'app.IF_COMPACT_SF6_IND_OUT',
  IF_COMPACT_ECO_IND_OUT = 'app.IF_COMPACT_ECO_IND_OUT',
  IF_STANDARD_SF6_IEC_OUT = 'app.IF_STANDARD_SF6_IEC_OUT',
  IF_STANDARD_ECO_IEC_OUT = 'app.IF_STANDARD_ECO_IEC_OUT',
  IF_STANDARD_SF6_IND_OUT = 'app.IF_STANDARD_SF6_IND_OUT',
  IF_STANDARD_ECO_IND_OUT = 'app.IF_STANDARD_ECO_IND_OUT',
  BT_BR_SF6_IEC = 'app.BT_BR_SF6_IEC',
  BT_BR_ECO_IEC = 'app.BT_BR_ECO_IEC',
  BT_BR__SF6_IEC = 'app.BT_BR__SF6_IEC',
  BT_BR__ECO_IEC = 'app.BT_BR__ECO_IEC',
  BT_RIGHT_SF6_IND = 'app.BT_RIGHT_SF6_IND',
  BT_RIGHT_ECO_IND = 'app.BT_RIGHT_ECO_IND',
  BR_RIGHT_SF6_IND = 'app.BR_RIGHT_SF6_IND',
  BR_RIGHT_ECO_IND = 'app.BR_RIGHT_ECO_IND',
  BT_LEFT_SF6_IND = 'app.BT_LEFT_SF6_IND',
  BT_LEFT_ECO_IND = 'app.BT_LEFT_ECO_IND',
  BR_LEFT_SF6_IND = 'app.BR_LEFT_SF6_IND',
  BR_LEFT_ECO_IND = 'app.BR_LEFT_ECO_IND',

  // loose?
  LOOSE_D = 'app.LOOSE_D',
  LOOSE_DF = 'app.LOOSE_DF',
  LOOSE_FB = 'app.LOOSE_FB',
  LOOSE_M = 'app.LOOSE_M',
  LOOSE_WB = 'app.LOOSE_WB',
}

export enum EDeviceObjectType {
  MVTypicalUnit = 'MVTypicalUnit',
  Load = 'Load',
  HeaterLoad = 'HeaterLoad',
  ChargerLoad = 'ChargerLoad',
  CB = 'CB',
  MMS_MO = 'MMS_MO',
  Connection = 'Connection',
  Busbar = 'Busbar',
  SwitchBoard = 'SwitchBoard',
  MvSwitchBoard = 'MvSwitchBoard',
  Unknown = 'UNKNOWN_OBJECT_TYPE',
  MvCable = 'MvCable',
  PowerSupply = 'PowerSupply',
  Genset = 'Genset',
  GeneratorSmall = 'GeneratorSmall',
  UtilityMVSmall = 'UtilityMVSmall',
  UtilitySmall = 'UtilitySmall',
  GensetBio = 'GensetBio',
  GensetPhoto = 'GensetPhoto',
  GensetWind = 'GensetWind',
  Transformer = 'Transformer',
  Transformer3 = 'Transformer3',
  Line = 'Line',
  TextArea = 'TextArea',
  Ats = 'Ats',

  // added for scenarios
  LvCircuitBreaker = 'LvCircuitBreaker',
  LvCbLsi = 'LvCbLsi',
  LvCbLsiRc = 'LvCbLsiRc',
  LvCbI = 'LvCbI',
  LvCbIRc = 'LvCbIRc',
  LvCbLsiW = 'LvCbLsiW',
  LvCbLsiRcW = 'LvCbLsiRcW',
  LvCbIW = 'LvCbIW',
  LvCbIRcW = 'LvCbIRcW',
  LvSwitchFuse = 'LvSwitchFuse',
  LvDisconnectorFuse = 'LvDisconnectorFuse',
  LvSwitchDisconnectorFuse = 'LvSwitchDisconnectorFuse',
  LvFuseSwitch = 'LvFuseSwitch',
  LvFuseDisconnector = 'LvFuseDisconnector',
  LvFuseSwitchDisconnector = 'LvFuseSwitchDisconnector',
  LvDisconnector = 'LvDisconnector',
  RcCb = 'RcCb',
  LvContactor = 'LvContactor',
  LvSwitch = 'LvSwitch',
  LvSwitchDisconnector = 'LvSwitchDisconnector',
  Mms = 'Mms',
  MmsMo = 'MmsMo',
  MmsTm = 'MmsTm',
  LvOverLoadRelay = 'LvOverLoadRelay',
  MvEarthDisconnector = 'MvEarthDisconnector',
  MvCircuitBreakerW = 'MvCircuitBreakerW',
  MvCircuitBreaker = 'MvCircuitBreaker',
  MvDownDisconnector = 'MvDownDisconnector',
  MvDisconnector = 'MvDisconnector',
  MvFuseSwitchDisconnector = 'MvFuseSwitchDisconnector',
  MvFuseGear = 'MvFuseGear',
  MvSwitchDisconnector = 'MvSwitchDisconnector',
  CurveDiagram = 'CurveDiagram',

  Impedance = 'Impedance',
  LinkIn = 'LinkIn',
  LinkOut = 'LinkOut',
  LvFuseBase = 'LvFuseBase',
  FEEDER = 'FEEDER',
  Motor = 'Motor',
  SoftStarterGeneric = 'SoftStarterGeneric',
  Busway = 'BusWay',
  Ups = 'Ups',
  Ups2Input = 'Ups2Input',
  UpsBypass = 'UpsBypass',
  UpsNoBypass = 'UpsNoBypass',
  Capacitor = 'Capacitor',
  Probe = 'Probe',
  TransformerDeltaStarg = 'TransformerDeltaStarg',
  TransformerDeltaDelta = 'TransformerDeltaDelta',
  TransformerDeltaStar = 'TransformerDeltaStar',
  TransformerStargDelta = 'TransformerStargDelta',
  TransformerStargStar = 'TransformerStargStar',
  TransformerStargStarg = 'TransformerStargStarg',
  TransformerStarDelta = 'TransformerStarDelta',
  TransformerStarStar = 'TransformerStarStar',
  TransformerStarStarg = 'TransformerStarStarg',
  InsulationTransformerStarStar = 'InsulationTransformerStarStar',
  InsulationTransformerStarDelta = 'InsulationTransformerStarDelta',
  InsulationTransformer = 'InsulationTransformer',
  LvCableLLLNPE = 'LvCableLLLNPE',
  LvCableLLLPEN = 'LvCableLLLPEN',
  LvCableLLLPE = 'LvCableLLLPE',
  LvCableLLPE = 'LvCableLLPE',
  LvCableLNPE = 'LvCableLNPE',
  LvCableLPEN = 'LvCableLPEN',
  LvCableLLLN = 'LvCableLLLN',
  LvCableLN = 'LvCableLN',
  LvCableLL = 'LvCableLL',
  LvCableLLL = 'LvCableLLL',
  CableStarDelta = 'CableStarDelta',
  CableStarDeltaPE = 'CableStarDeltaPE',
  MotorDol = 'MotorDol',
  TypicalBreakerLoad = 'TypicalBreakerLoad',
  TypicalBreakerHeater = 'TypicalBreakerHeater',
  TypicalBreakerMotor = 'TypicalBreakerMotor',
  TypicalFuseHeater = 'TypicalFuseHeater',
  TypicalFuseLoad = 'TypicalFuseLoad',
  TypicalFuseMotor = 'TypicalFuseMotor',
  LvMacro = 'LvMacro',
  AuxTrafoInsulating = 'AuxTrafoInsulating',
  AuxTrafoSafety = 'AuxTrafoSafety',
  // AuxTrafoInsulated = 'AuxTrafoInsulated',
  // AuxTrafoSeparated = 'AuxTrafoSeparated',
  AuxTrafoSwitching = 'AuxTrafoSwitching', // switching power supply
  AuxTrafo = 'AuxTrafo',
  AuxLowPowerMeter = 'AuxLowPowerMeter',
}

export enum ECellType {
  Device = 'DEVICE',
  BlockDevice = 'BLOCK_DEVICE',
  Connection = 'CONNECTION',
  BusBar = 'BUSBAR',
  BlockConnection = 'BLOCK_CONNECTION',
  Line = 'Line',
  CURVE_DIAGRAM = 'CURVE_DIAGRAM',
  CONNECTOON = 'CONNECTOON',
}

export type ISwitchboardDeviceTypes = {
  [deviceType in ESwitchboardDevice]: Array<SupportedSwitchboard>;
};

export enum ELvLineType {
  LLLN = 'LLLN',
  LLL = 'LLL',
  LLN = 'LLN',
  L2L3 = 'L2L3',
  L1L3 = 'L1L3',
  L1L2 = 'L1L2',
  L1N = 'L1N',
  L2N = 'L2N',
  L3N = 'L3N',
  LN = 'LN',
  LL = 'LL',
}

export enum MvLineType {
  LLL = 'LLL',
}
export interface LineType {
  LV: 'LLLN' | 'LLL' | 'L2L3' | 'L1L3' | 'L1L2' | 'L1N' | 'L2N' | 'L3N';
  MV: 'LLL';
}

export interface DistributionSystem {
  LV: 'TT' | 'TN-C' | 'TN-S' | 'IT';
  MV: 'IT';
}

export enum EEarthingSystem {
  TT = 'TT',
  TNC = 'TN-C',
  TNS = 'TN-S',
  IT = 'IT',
}

export type SupportedSwitchboard = EDeviceType.LV_SWITCHBOARD | EDeviceType.MV_SWITCHBOARD;

export enum ETypeFilters {
  TMP = 'TMP',
  MO = 'MO',
  TMRCR = 'TMRCR',
  MORCR = 'MORCR',
}

export enum EApparentPowerType {
  RatedPower = 'Rated Power',
  RatedCurrent = 'Rated Current',
}

export enum ENeutral {
  NeutralInsulated = 'Neutral insulated',
  NeutralBalanced = 'Neutral balanced',
}

export enum ETolerance {
  tolerance10 = '+10% tolerance',
  tolerance6 = '+6% tolerance',
}

export enum EBreakerSymbols {
  LvCbLsi = 'LvCbLsi',
  LvCbLsiW = 'LvCbLsiW',
  LvCbLsiRc = 'LvCbLsiRc',
  LvCbLsiRcW = 'LvCbLsiRcW',
  LvCbI = 'LvCbI',
  LvCbIW = 'LvCbIW',
  LvCbIRc = 'LvCbIRc',
  LvCbIRcW = 'LvCbIRcW',
  MmsTm = 'MmsTm',
  MmsMo = 'MmsMo',
}

export enum EBreakerTypes {
  TMP = 'TMP',
  TMRCR = 'TMRCR',
  MO = 'MO',
  MORCR = 'MORCR',
  MMSTM = 'MMSTM',
  MMSMO = 'MMSMO',
}

export enum EDeviceVersion {
  F = 'F',
  P = 'P',
  W = 'W',
  AllPossibilities = 'All possibilities',
}

export enum ETypeListTranslate {
  OverAndShortCP = 'Overload and Short Circuit protection',
  ShortCProtOnly = 'Short Circuit protection only',
  OverAndShortCPRCD = 'Overload and Short Circuit protection + RCD',
  ShortCProtRCD = 'Short Circuit protection + RCD',
}

export enum EConvertProtection {
  'MCCB' = EDeviceType.CB_TM,
  'MMS' = EDeviceType.MMS,
  'Fuses' = EDeviceType.FUSEBASE,
  'ACB' = EDeviceType.CB_TM,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FEEDER_PROTECTION_DEVICE_TYPES {
  CB_TM = EDeviceType.CB_TM,
  MMS = EDeviceType.MMS,
  FUSEBASE = EDeviceType.FUSEBASE,
  ACB = EDeviceType.CB_TM,
}

export enum GenericTypicalUnits {
  'INCOMING_DRC' = 'Direct Incoming',
  'INCOMING_HBC' = 'In CB and Disconnector',
  'INCOMING_HBC-W' = 'In with. CB + Disconnector',
  'INCOMING_SBC' = 'In plug-in CB + Disconnector',
  'INCOMING_SDC' = 'In Disconnector',
  'INCOMING_WBC' = 'In Withdrawable CB',
  'OUTCOMING_DRC' = 'Direct Outcoming',
  'OUTCOMING_HBC' = 'Out CD and Disconnector',
  'OUTCOMING_HBC-W' = 'Out with. CB + Disconnector',
  'OUTCOMING_SBC' = 'Out plug-in CB + Disconnector',
  'OUTCOMING_SDC' = 'Out Disconnector',
  'OUTCOMING_SFC' = 'Out Fuse + Disconnector',
  'OUTCOMING_WBC' = 'Out Withdrawable CB',
  'MEASUREMENT_SFV' = 'Measurement',
}

export enum ECouplingList {
  Delta = 'Delta',
  Star = 'Star',
  StarGrounded = 'Star-grounded',
  Insulated = 'Insulated',
  Grounded = 'Grounded',
}

export enum PwSwEarthingSystem {
  PrimaryWindingEarthingSystem = 'PrimaryWindingEarthingSystem',
  SecondaryWindingEarthingSystem = 'SecondaryWindingEarthingSystem',
}

export enum PwSwCouplingType {
  PrimaryWindingCouplingType = 'PrimaryWindingCouplingType',
  SecondaryWindingCouplingType = 'SecondaryWindingCouplingType',
}

export enum PwSwLineType {
  PrimaryWindingLineType = 'PrimaryWindingLineType',
  SecondaryWindingLineType = 'SecondaryWindingLineType',
}

export enum DataSource {
  BackupPropertiesList = 'BackupPropertiesList',
  DiscriminationPropertiesList = 'DiscriminationPropertiesList',
}

export enum Stream {
  backUpUpStream = 'backUpUpStream',
  discriminationUpStream = 'discriminationUpStream',
}

export enum EPoles {
  P4 = '4P',
  P3 = '3P',
  P3N = '3P+N',
  P2 = '2P',
  P1N = '1P+N',
  P1 = '1P',
}
