export default {
  type: '',
  ports: {
    groups: {
      default: {
        position: {
          name: 'absolute',
        },
        attrs: {
          '.joint-port-body': {
            fill: 'transparent',
            stroke: 'red',
            strokeWidth: 'inherit',
            r: 3,
            magnet: 'passive',
            fillOpacity: 0.1,
            strokeOpacity: 1,
          },
        },
      },
    },
    items: [],
  },
  position: {
    x: 5,
    y: 10,
  },
  size: {
    width: 1,
    height: 1,
  },
  angle: 0,
  id: 'da2f2a3f-6e33-4a90-9ef6-6850a11b4795',
  z: 1,
  attrs: {},
};

// export default {
//   groups: {
//     default: {
//       position: {
//         name: 'absolute',
//       },
//       attrs: {
//         '.joint-port-body': {
//           fill: 'transparent',
//           stroke: 'red',
//           strokeWidth: 'inherit',
//           r: 3,
//           magnet: 'passive',
//           fillOpacity: 0.1,
//           strokeOpacity: 1,
//         },
//       },
//     },
//   },
// };
