/* eslint-disable no-param-reassign */
import { ICurveDiagram, ICurves } from 'types/curves';
import _ from 'lodash';
import CurveTypes from 'curves/curveType';
import { Group } from 'konva/lib/Group';
import Line from 'curves/shapes/Line';
import Hatch from 'curves/shapes/Hatch';
import CurveTypeIndex from 'curves/curveType/CurveTypeIndex';
import { DRAG_CURVE_COLOR } from 'curves/Constants';
import { updateElementParams } from 'devices/actions';
import { store } from 'store/configureStore';
import { AnyAction } from 'redux';
import { getDeviceCurves } from '.';

const segmentDist = (p1: { x: number; y: number }, p2: { x: number; y: number }, p: { x: number; y: number }) => {
  // segment vector;
  const vx = p2.x - p1.x;
  const vy = p2.y - p1.y;

  // length of segment
  const d = Math.sqrt(vx * vx + vy * vy);
  if (d < 1e-9)
    // zero segment?
    return 1e38;

  // point vector;
  const wx = p.x - p1.x;
  const wy = p.y - p1.y;

  // distance from ray
  const dist = Math.abs(vy * wx - vx * wy) / d;

  // closest point is on segment?
  const t = (vx * wx + vy * wy) / d;
  if (t < 0 || t > d) return 1e38;

  return dist;
};

const moveDragCurve = (
  curveForDrag: ICurves,
  curveType: CurveTypes,
  closestDragCurve: { x: number; y: number; desc: number },
  curvesGroup: Group,
  isLine: boolean
): Hatch | Line => {
  const points = curveForDrag.points[CurveTypeIndex[curveType]];
  let newDragCurve: Hatch | Line;
  if (isLine) {
    newDragCurve = new Line(
      points[1],
      window.miniCurves.sizeConfig,
      true,
      DRAG_CURVE_COLOR,
      window.miniCurves.curvesLayer.x(),
      window.miniCurves.curvesLayer.y()
    );
  } else {
    newDragCurve = new Hatch(
      points[0],
      points[1],
      window.miniCurves.sizeConfig,
      DRAG_CURVE_COLOR,
      window.miniCurves.curvesLayer.x(),
      window.miniCurves.curvesLayer.y()
    );
  }
  newDragCurve.name(closestDragCurve.desc.toString());
  curvesGroup.add(newDragCurve);
  window.miniCurves.redraw();
  return newDragCurve;
};

const setEventsListening = (value: boolean) => {
  const layerChild = window.miniCurves.curvesLayer.children ? (window.miniCurves.curvesLayer.children[0] as Hatch) : undefined;
  if (layerChild) {
    layerChild
      .getChildren((child) => child instanceof Hatch || child instanceof Line)
      .forEach((childCurve) => (childCurve as Hatch).listening(value));
  }
};

export const drawDragCurve = (
  originalDragCurve: Hatch | Line,
  eventDiagram: MouseEvent & { layerX: number; layerY: number },
  points: {
    x: number;
    y: number;
    desc?: number;
  }[],
  diagramCurve: ICurveDiagram,
  curveIndex: number,
  curvesGroup: Group,
  curveType: CurveTypes,
  dragDeviceId: string
): void => {
  const { devices } = window.miniCurves;
  if (!window.miniCurves.sizeConfig) return;
  const originalPoint = window.miniCurves.sizeConfig.revertAdjustedPoint({
    x: eventDiagram.layerX * window.miniCurves.scale,
    y: eventDiagram.layerY * window.miniCurves.scale,
  });
  let dist = 999999;
  let closestDragCurve: { x: number; y: number; desc: number } | undefined;
  for (let i = 0; i < points.length; i += 1) {
    if (points.length - 1 > i) {
      const newDist = segmentDist(points[i], points[i + 1], originalPoint);
      if (newDist < dist) {
        dist = newDist;
        closestDragCurve = points[i] as { x: number; y: number; desc: number };
      }
    }
  }

  if (closestDragCurve) {
    if (curvesGroup.getChildren((child) => child.name() === closestDragCurve?.desc.toString()).length > 0) {
      return;
    }
    if (closestDragCurve.desc && window.miniCurves.curves.curvesDrag && window.miniCurves.curves.curvesDrag[dragDeviceId]) {
      const curvesForDrag = window.miniCurves.curves.curvesDrag[dragDeviceId][curveIndex][closestDragCurve.desc];
      if (curvesForDrag && diagramCurve) {
        let currentValue = _.get(window.miniCurves.devices[dragDeviceId], curvesForDrag.paramName) as string | number;
        let selectedCurveIndex = curvesForDrag.values.indexOf(currentValue);
        let drawnDragCurves: Array<Hatch>;
        if (selectedCurveIndex === -1) {
          let tripValues = new Array<number>();
          if (typeof currentValue === 'string') {
            curvesForDrag.values.forEach((val) => {
              tripValues.push(parseInt((val as string).replace(/[^0-9]/g, ''), 10));
            });
            currentValue = parseInt(currentValue.replace(/[^0-9]/g, ''), 10);
          } else {
            tripValues = [...curvesForDrag.values] as number[];
          }
          let nearestVal = tripValues[0];
          selectedCurveIndex = 0;
          tripValues.forEach((val, index) => {
            if (Math.abs((currentValue as number) - val) < Math.abs((currentValue as number) - nearestVal)) {
              nearestVal = val;
              selectedCurveIndex = index;
            }
          });
        }

        const selectedCurve = curvesForDrag.curves[selectedCurveIndex];
        const dragPoints = selectedCurve.points[CurveTypeIndex[curveType]];
        let newDragCurve: Hatch | Line;
        if (originalDragCurve instanceof Line) {
          newDragCurve = new Line(
            points,
            window.miniCurves.sizeConfig,
            true,
            DRAG_CURVE_COLOR,
            window.miniCurves.curvesLayer.x(),
            window.miniCurves.curvesLayer.y()
          );
        } else {
          newDragCurve = new Hatch(
            dragPoints[0],
            dragPoints[1],
            window.miniCurves.sizeConfig,
            DRAG_CURVE_COLOR,
            window.miniCurves.curvesLayer.x(),
            window.miniCurves.curvesLayer.y()
          );
        }
        newDragCurve.name(closestDragCurve.desc.toString());
        curvesGroup.add(newDragCurve);

        const layerChild = window.miniCurves.curvesLayer.children
          ? (window.miniCurves.curvesLayer.children[0] as Hatch)
          : undefined;

        if (layerChild) {
          drawnDragCurves = layerChild.getChildren(
            (child) => child.name() !== undefined && child.name() !== '' && child.name() !== closestDragCurve?.desc.toString()
          ) as Array<Hatch>;
        } else {
          drawnDragCurves = [];
        }

        if (drawnDragCurves && drawnDragCurves.length > 0) {
          drawnDragCurves.forEach((child) => {
            child.remove();
          });
        }

        newDragCurve.on('mouseenter', () => {
          if (curvesForDrag.bY) {
            // eslint-disable-next-line no-param-reassign
            window.miniCurves.stage.container().style.cursor = 'n-resize';
          } else {
            // eslint-disable-next-line no-param-reassign
            window.miniCurves.stage.container().style.cursor = 'e-resize';
          }
          originalDragCurve.removeEventListener('mouseleave');
          if (!window.miniCurves.stage.eventListeners.mouseleave) {
            window.miniCurves.stage.on('mouseleave', () => {
              // console.log('LEAVE');
              const mouseleaveLayerChild = window.miniCurves.curvesLayer.children
                ? (window.miniCurves.curvesLayer.children[0] as Hatch)
                : undefined;

              if (mouseleaveLayerChild) {
                mouseleaveLayerChild
                  .getChildren((child) => child.name() !== undefined && child.name() !== '')
                  .forEach((drawnDragCurve) => (drawnDragCurve as Hatch).remove());
              }
              window.miniCurves.redraw();
            });
          }
        });

        newDragCurve.on('mouseleave', () => {
          window.miniCurves.stage.container().style.cursor = 'default';
          newDragCurve.remove();
          window.miniCurves.redraw();
        });

        newDragCurve.on('mousedown', () => {
          // const downKonvaE = downE.evt as MouseEvent & { layerX: number; layerY: number };
          // originalDragCurve.removeEventListener('mouseleave');
          window.miniCurves.dragCurveIndex = selectedCurveIndex;
          let previousHatch = newDragCurve;
          if (!window.miniCurves.stage.eventListeners.mousemove) {
            window.miniCurves.stage.on('mousemove', (e) => {
              setEventsListening(false);
              const moveEvent = e.evt as MouseEvent & { layerX: number; layerY: number };
              const isLine = previousHatch instanceof Line;
              const konvaCursorPoint = window.miniCurves.sizeConfig.revertAdjustedPoint({
                x: moveEvent.layerX * window.miniCurves.scale,
                y: moveEvent.layerY * window.miniCurves.scale,
              });
              let dist1 = Number.MAX_SAFE_INTEGER;
              let curveForDrag;
              for (let i = 0; i < curvesForDrag.curves.length; i += 1) {
                const currentDragCurve = isLine
                  ? curvesForDrag.curves[i].points[CurveTypeIndex[curveType]][1]
                  : curvesForDrag.curves[i].points[CurveTypeIndex[curveType]][0];
                for (let j = 0; j < currentDragCurve.length; j += 1) {
                  if (currentDragCurve.length - 1 > j) {
                    const newDist = segmentDist(currentDragCurve[j], currentDragCurve[j + 1], konvaCursorPoint);
                    if (newDist < dist1) {
                      dist1 = newDist;
                      curveForDrag = curvesForDrag.curves[i];
                      window.miniCurves.dragCurveIndex = i;
                    }
                  }
                }
              }

              if (curveForDrag && closestDragCurve) {
                previousHatch.remove();
                previousHatch = moveDragCurve(curveForDrag, curveType, closestDragCurve, curvesGroup, isLine);
              }
            });
          }
          if (!window.miniCurves.stage.eventListeners.mouseup) {
            window.miniCurves.stage.on('mouseup', () => {
              const device = _.cloneDeep(devices[dragDeviceId]);
              const oldValue = _.get(device, curvesForDrag.paramName) as number | string;
              if (oldValue !== curvesForDrag.values[window.miniCurves.dragCurveIndex]) {
                _.set(device, curvesForDrag.paramName, curvesForDrag.values[window.miniCurves.dragCurveIndex]);
                const rootNode = curvesForDrag.paramName[0];
                const updatedTripUnit = { [rootNode]: device[rootNode] };
                store.dispatch(updateElementParams(dragDeviceId, updatedTripUnit, true) as unknown as AnyAction);
                store.dispatch(getDeviceCurves() as unknown as AnyAction);
              } else {
                previousHatch.remove();
                setEventsListening(true);
                window.miniCurves.redraw();
              }
              window.miniCurves.stage.removeEventListener('mousemove');
              window.miniCurves.stage.removeEventListener('mouseup');
              window.miniCurves.stage.container().style.cursor = 'default';
            });
          }
        });
      }
      window.miniCurves.redraw();
    }
    // console.log(closestDragCurve);
  }
};
