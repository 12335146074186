import { useTranslation } from 'react-i18next';
import { ICurvesLabelInput } from 'types/curves/components';
import React, { FunctionComponent } from 'react';
import { IGridColumn } from 'types/components/grid';
import CheckBox from 'components/generic/CheckBox';
import { Grid, GridColumn, GridRow } from 'components/generic/grid';
import Input from 'components/generic/inputContainer';
import { Icon } from '@abb/abb-common-ux-react';

const LabelInput: FunctionComponent<ICurvesLabelInput> = ({
  text,
  value,
  checked,
  readonly = false,
  enabled = true,
  onTextChange,
  onCheckedChange,
  onlyLabelCheckbox = false,
  isSwitch,
}): React.ReactElement => {
  const { t } = useTranslation();

  const renderCheckbox = (checkboxWidth: IGridColumn['col']): React.ReactElement => {
    return (
      <GridColumn col={checkboxWidth} className="curves-show-eye-column">
        <CheckBox
          name=""
          value={checked}
          onChange={(event) => {
            if (onCheckedChange) {
              onCheckedChange(event.value);
            }
          }}
        />
      </GridColumn>
    );
  };
  const renderSwitch = (checkboxWidth: IGridColumn['col']) => {
    return (
      <GridColumn col={checkboxWidth} className={'curves-show-eye-column'}>
        <Icon
          name="abb/hide"
          sizeClass="medium"
          className={checked ? 'DOCWeb_eye-switch-off' : 'DOCWeb_eye-switch-on'}
          onClick={() => {
            if (onCheckedChange) {
              onCheckedChange(!checked);
            }
          }}
        />
        {/* <Switch
          value={this.props.checked}
          onChange={(event) => {
            if (this.props.onCheckedChange) {
              this.props.onCheckedChange(event.value);
            }
          }}
        /> */}
      </GridColumn>
    );
  };
  let nameWidth: IGridColumn['col'] = text === 'Label' ? 2 : 4;
  const inputWidth = text === 'Label' ? 4 : 2;
  if (onlyLabelCheckbox) {
    nameWidth = 6;
  }
  const checkboxWidth = 2;
  return onlyLabelCheckbox ? (
    <Grid>
      <GridRow>
        <GridColumn col={nameWidth}>
          <label htmlFor="curvesLabelInputCheckBox">{t(text)}</label>
        </GridColumn>
        <GridColumn col={checkboxWidth} style={{ borderLeft: '1px solid #9f9f9f' }}>
          <CheckBox
            name="curvesLabelInputCheckBox"
            value={checked}
            onChange={(event) => {
              if (onCheckedChange) {
                onCheckedChange(event.value);
              }
            }}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  ) : (
    <Grid>
      <GridRow className="curves-show-row">
        <GridColumn col={nameWidth} className={'curves-show-text-column'}>
          <label htmlFor="curvesLabelInput">{text}</label>
        </GridColumn>
        <GridColumn col={inputWidth} className="curves-show-input-column">
          <Input
            type="text"
            name="curvesLabelInput"
            value={value}
            onChange={(event) => {
              if (onTextChange) {
                onTextChange(event.value.toString());
              }
            }}
            disabled={readonly ? true : !enabled}
          />
        </GridColumn>
        {isSwitch ? renderSwitch(checkboxWidth) : renderCheckbox(checkboxWidth)}
      </GridRow>
    </Grid>
  );
};

export default LabelInput;
