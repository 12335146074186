import {
  EBreakerSymbols,
  EBreakerTypes,
  EDeviceType,
  EDeviceVersion,
  ESwitchboardDevice,
  ISwitchboardDeviceTypes,
  SupportedSwitchboard,
} from 'types/devices/enums.d';

export const projectCommonData = {
  voltageLow: [24, 48, 110, 127, 220, 230, 240, 380, 400, 415, 440, 480, 500, 525, 550, 600, 690, 800, 1000],
  voltageMedium: [1500, 3000, 6000, 10000, 10500, 11000, 15000, 20000, 22000, 23000, 24000],
  voltage: [
    24, 48, 110, 127, 220, 230, 240, 380, 400, 415, 440, 480, 500, 600, 690, 800, 1000, 1500, 3000, 6000, 10000, 10500, 11000,
    15000, 20000, 22000, 23000, 30000, 36000,
  ],
  lineType: ['LLLN', 'LLL', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  distributionSystem: ['TT', 'TN-C', 'TN-S', 'IT'],
  sCurrents: [
    'SCTriMax',
    'SCTriPeak',
    'SCBiMax',
    'SCBiPeak',
    'SCLNMax',
    'SCLNPeak',
    'SCPEMax',
    'SCPEPeak',
    'SCBiTriMin',
    'SCLNMin',
    'SCPEMin',
  ],
  frequency: [50, 60],

  // !!!! TODO - CHECK GENTYPES ASSOTIATION WITH DATABASE!!!!
  // !!! are 1 turbogenerator for LowVoltage or Medium? because in API it's for MV, here is for LV (genDescMW uses 6, which is LV in API)

  generatorDescriptionForGeneratorList: [
    'Turbogenerator MV',
    'Sailent pole generator with damper windings (Fast Rotor - up to 16 Poles)',
    'Sailent pole generator with damper windings (Fast Rotor - over 16 Poles)',
    'Sailent pole generator without damper windings (Fast Rotor - up to 16 Poles)',
    'Sailent pole generator without damper windings (Fast Rotor - over 16 Poles)',
    'Turbogenerator LV',
    'Sailent pole generator (2 Poles)',
    'Sailent pole generator (4 Poles)',
    'Sailent pole generator (6 Poles)',
    'Sailent pole generator (8 Poles)',
  ],

  genTypes: ['MVT', 'MVDF16', 'MVDS16', 'MVNDF16', 'MVNDS16', 'LVT', 'LV2', 'LV4', 'LV6', 'LV8'],
  genTypesLV: ['LVT', 'LV2', 'LV4', 'LV6', 'LV8'],
  // generatorDescriptionIdLV: [6, 7, 8, 9, 10],
  genTypesMV: ['MVT', 'MVDF16', 'MVDS16', 'MVNDF16', 'MVNDS16'],
  // generatorDescriptionIdMV: [1, 2, 3, 4, 5],
  generatorDescriptionLV: [
    'Turbogenerator',
    'Sailent pole generator (2 Poles)',
    'Sailent pole generator (4 Poles)',
    'Sailent pole generator (6 Poles)',
    'Sailent pole generator (8 Poles)',
  ],
  generatorDescriptionMV: [
    'Turbogenerator',
    'Sailent pole generator with damper windings (Fast Rotor - up to 16 Poles)',
    'Sailent pole generator with damper windings (Fast Rotor - over 16 Poles)',
    'Sailent pole generator without damper windings (Fast Rotor - up to 16 Poles)',
    'Sailent pole generator without damper windings (Fast Rotor - over 16 Poles)',
  ],
  windings: ['Delta', 'Star', 'Star-grounded'],
  plug: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10],
  sr: [25, 40, 50, 63, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2000, 2500],
  ukr: [4, 6],
  losses: [6, 5.8, 3.6, 2.9, 2.7, 2.5, 2.35, 2.2, 2.1, 1.9, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2],
  transformatorsNumber: ['1 Trafo', '2 Trafos', '3 Trafos'],
  transformatorsNumberValues: [1, 2, 3],
  loads: ['Fixed rated current', 'Fixed rated power'],
  scenarios: ['Calculate all', 'Calculate actual only'],
  standardsMiniature: ['IEC 60947-2 (industrial use)', 'IEC 60898 (civil/tertiary use)'],
  neutral: ['Neutral insulated', 'Neutral balanced'],
};

export const switchboardLists = {
  McbStandardList: [
    { id: 'IEC60898', idString: 'IEC 60898 (civil/tertiary use)', translateString: 'IEC 60898 (civil/tertiary use)' },
    { id: 'IEC90947-2', idString: 'IEC 60947-2 (industrial use)', translateString: 'IEC 60947-2 (industrial use)' },
  ],
  UsageClassList: [
    { id: 'CATI', idString: 'Cat. I: specially protected equipment', translateString: 'Cat. I: specially protected equipment' },
    {
      id: 'CATII',
      idString: 'Cat. II: appliances and current-using equipment',
      translateString: 'Cat. II: appliances and current-using equipment',
    },
    {
      id: 'CATIII',
      idString: 'Cat. III: distribution and final circuits',
      translateString: 'Cat. III: distribution and final circuits',
    },
    { id: 'CATIV', idString: 'Cat. IV: origin of installation', translateString: 'Cat. IV: origin of installation' },
  ],
  SegregationFormList: ['1', '2a', '2b', '3a', '3a', '4b'],
  TerminalTypeList: ['Front', 'Rear', 'All'],
};

export const polesTable = {
  LLLN: {
    TT: ['4P', '3P', '3P+N'],
    'TN-C': ['3P'],
    'TN-S': ['4P', '3P', '3P+N'],
    IT: ['4P', '3P', '3P+N'],
  },
  LLL: {
    TT: ['3P', '3P+N', '4P'],
    'TN-C': ['3P'],
    'TN-S': ['3P', '3P+N', '4P'],
    IT: ['3P', '3P+N', '4P'],
  },
  LLN: {
    TT: ['3P', '2P', '3P+N', '4P'],
    'TN-C': ['2P'],
    'TN-S': ['3P', '2P', '3P+N', '4P'],
    IT: ['3P', '2P', '3P+N', '4P'],
  },
  LL: {
    TT: ['2P', '3P', '3P+N', '4P'],
    'TN-C': ['2P'],
    'TN-S': ['2P', '3P', '3P+N', '4P'],
    IT: ['2P', '3P', '3P+N', '4P'],
  },
  L2L3: {
    TT: ['2P', '3P', '3P+N', '4P'],
    'TN-C': ['2P'],
    'TN-S': ['2P', '3P', '3P+N', '4P'],
    IT: ['2P', '3P', '3P+N', '4P'],
  },
  L1L3: {
    TT: ['2P', '3P', '3P+N', '4P'],
    'TN-C': ['2P'],
    'TN-S': ['2P', '3P', '3P+N', '4P'],
    IT: ['2P', '3P', '3P+N', '4P'],
  },
  L1L2: {
    TT: ['2P', '3P', '3P+N', '4P'],
    'TN-C': ['2P'],
    'TN-S': ['2P', '3P', '3P+N', '4P'],
    IT: ['2P', '3P', '3P+N', '4P'],
  },
  L1N: {
    TT: ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    'TN-C': ['1P'],
    'TN-S': ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    IT: ['2P', '1P+N', '1P', '3P', '3P+N', '4P'],
  },
  L2N: {
    TT: ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    'TN-C': ['1P'],
    'TN-S': ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    IT: ['2P', '1P+N', '1P', '3P', '3P+N', '4P'],
  },
  L3N: {
    TT: ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    'TN-C': ['1P'],
    'TN-S': ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    IT: ['2P', '1P+N', '1P', '3P', '3P+N', '4P'],
  },
  LN: {
    TT: ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    'TN-C': ['1P'],
    'TN-S': ['1P+N', '1P', '2P', '3P', '3P+N', '4P'],
    IT: ['2P', '1P+N', '1P', '3P', '3P+N', '4P'],
  },
};

export const availableLanguages = ['es-ES', 'fr-FR', 'it-IT', 'pt-PT', 'ru', 'zh-Hans', 'en', 'tr-TR'];
export const phLanguages: Record<string, string> = { en: 'en', it: 'it-IT', de: 'de-DE', es: 'es-ES', nl: 'nl-NL', fr: 'fr-FR' };

export const disconnectorPolesTable = {
  LLLN: ['4P', '3P', '2P', '1P'],
  LLL: ['3P', '2P', '1P'],
  LL: ['2P', '1P'],
  L2L3: ['2P', '1P'],
  L1L3: ['2P', '1P'],
  L1L2: ['2P', '1P'],
  L1N: ['2P', '1P'],
  L2N: ['2P', '1P'],
  L3N: ['2P', '1P'],
  LN: ['2P', '1P'],
};

export const distributionTable = {
  LLLN: ['TN-S', 'TN-C', 'TT', 'IT'],
  LLL: ['TN-S', 'TT', 'IT'],
  LLN: ['TN-S', 'TN-C', 'TT', 'IT'],
  LN: ['TN-S', 'TN-C', 'TT', 'IT'],
  LL: ['TN-S', 'TT', 'IT'],
  L2L3: ['TN-S', 'TT', 'IT'],
  L1L3: ['TN-S', 'TT', 'IT'],
  L1L2: ['TN-S', 'TT', 'IT'],
  L1N: ['TN-S', 'TN-C', 'TT', 'IT'],
  L2N: ['TN-S', 'TN-C', 'TT', 'IT'],
  L3N: ['TN-S', 'TN-C', 'TT', 'IT'],
};

export const windingTable = {
  LLLN: {
    'TN-S': ['Star-grounded'],
    'TN-C': ['Star-grounded'],
    TT: ['Star-grounded'],
    IT: ['Star'],
  },
  LLL: {
    'TN-S': ['Star-grounded'],
    'TN-C': ['Star-grounded'],
    TT: ['Star-grounded'],
    IT: ['Delta', 'Star'],
  },
};

export const trafoSymbols = {
  Delta: {
    Delta: 'TransformerDeltaDelta',
    Star: 'TransformerDeltaStar',
    'Star-grounded': 'TransformerDeltaStarg',
  },
  'Star-grounded': {
    Delta: 'TransformerStargDelta',
    Star: 'TransformerStargStar',
    'Star-grounded': 'TransformerStargStarg',
  },
  Star: {
    Delta: 'TransformerStarDelta',
    Star: 'TransformerStarStar',
    'Star-grounded': 'TransformerStarStarg',
  },
};

export const secondaryTrafoPhases = {
  LLLN: ['LLLN', 'LLL'],
  LLL: ['LLLN', 'LLL'],
  LLN: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  LN: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  LL: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  L2L3: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  L1L3: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  L1L2: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  L1N: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  L2N: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
  L3N: ['LLN', 'L2L3', 'L1L3', 'L1L2', 'L1N', 'L2N', 'L3N'],
};

export const breakerSymbols = {
  [EBreakerTypes.MMSTM]: {
    [EDeviceVersion.AllPossibilities]: EBreakerSymbols.MmsTm,
    [EDeviceVersion.F]: EBreakerSymbols.MmsTm,
    [EDeviceVersion.P]: EBreakerSymbols.MmsTm,
    [EDeviceVersion.W]: EBreakerSymbols.MmsTm,
  },
  [EBreakerTypes.MMSMO]: {
    [EDeviceVersion.AllPossibilities]: EBreakerSymbols.MmsMo,
    [EDeviceVersion.F]: EBreakerSymbols.MmsMo,
    [EDeviceVersion.P]: EBreakerSymbols.MmsMo,
    [EDeviceVersion.W]: EBreakerSymbols.MmsMo,
  },
  [EBreakerTypes.TMP]: {
    [EDeviceVersion.AllPossibilities]: EBreakerSymbols.LvCbLsi,
    [EDeviceVersion.F]: EBreakerSymbols.LvCbLsi,
    [EDeviceVersion.P]: EBreakerSymbols.LvCbLsiW,
    [EDeviceVersion.W]: EBreakerSymbols.LvCbLsiW,
  },
  [EBreakerTypes.TMRCR]: {
    [EDeviceVersion.AllPossibilities]: EBreakerSymbols.LvCbLsiRc,
    [EDeviceVersion.F]: EBreakerSymbols.LvCbLsiRc,
    [EDeviceVersion.P]: EBreakerSymbols.LvCbLsiRcW,
    [EDeviceVersion.W]: EBreakerSymbols.LvCbLsiRcW,
  },
  [EBreakerTypes.MO]: {
    [EDeviceVersion.AllPossibilities]: EBreakerSymbols.LvCbI,
    [EDeviceVersion.F]: EBreakerSymbols.LvCbI,
    [EDeviceVersion.P]: EBreakerSymbols.LvCbIW,
    [EDeviceVersion.W]: EBreakerSymbols.LvCbIW,
  },
  [EBreakerTypes.MORCR]: {
    [EDeviceVersion.AllPossibilities]: EBreakerSymbols.LvCbIRc,
    [EDeviceVersion.F]: EBreakerSymbols.LvCbIRc,
    [EDeviceVersion.P]: EBreakerSymbols.LvCbIRcW,
    [EDeviceVersion.W]: EBreakerSymbols.LvCbIRcW,
  },
};

export const mmsSymbols = {
  [EBreakerTypes.MMSTM]: {
    [EDeviceVersion.AllPossibilities]: 'MmsTm',
    [EDeviceVersion.F]: 'MmsTm',
    [EDeviceVersion.P]: 'MmsTm',
    [EDeviceVersion.W]: 'MmsTm',
  },
  [EBreakerTypes.MMSMO]: {
    [EDeviceVersion.AllPossibilities]: 'MmsMo',
    [EDeviceVersion.F]: 'MmsMo',
    [EDeviceVersion.P]: 'MmsMo',
    [EDeviceVersion.W]: 'MmsMo',
  },
};

export const cablePESymbols = {
  LLLN: {
    TT: 'LvCableLLLNPE',
    'TN-C': 'LvCableLLLPEN',
    'TN-S': 'LvCableLLLNPE',
    IT: 'LvCableLLLNPE',
  },
  LLL: {
    TT: 'LvCableLLLPE',
    'TN-C': 'LvCableLLLPE',
    'TN-S': 'LvCableLLLPE',
    IT: 'LvCableLLLPE',
  },
  L1L2: {
    TT: 'LvCableLLPE',
    'TN-C': 'LvCableLLPE',
    'TN-S': 'LvCableLLPE',
    IT: 'LvCableLLPE',
  },
  L2L3: {
    TT: 'LvCableLLPE',
    'TN-C': 'LvCableLLPE',
    'TN-S': 'LvCableLLPE',
    IT: 'LvCableLLPE',
  },
  L1L3: {
    TT: 'LvCableLLPE',
    'TN-C': 'LvCableLLPE',
    'TN-S': 'LvCableLLPE',
    IT: 'LvCableLLPE',
  },
  L1N: {
    TT: 'LvCableLNPE',
    'TN-C': 'LvCableLPEN',
    'TN-S': 'LvCableLNPE',
    IT: 'LvCableLNPE',
  },
  L2N: {
    TT: 'LvCableLNPE',
    'TN-C': 'LvCableLPEN',
    'TN-S': 'LvCableLNPE',
    IT: 'LvCableLNPE',
  },
  L3N: {
    TT: 'LvCableLNPE',
    'TN-C': 'LvCableLPEN',
    'TN-S': 'LvCableLNPE',
    IT: 'LvCableLNPE',
  },
};

export const cableSymbols = {
  LLLN: {
    TT: 'LvCableLLLN',
    'TN-C': 'LvCableLLLN',
    'TN-S': 'LvCableLLLN',
    IT: 'LvCableLLLN',
  },
  LLL: {
    TT: 'LvCableLLL',
    'TN-C': 'LvCableLLL',
    'TN-S': 'LvCableLLL',
    IT: 'LvCableLLL',
  },
  L1L2: {
    TT: 'LvCableLL',
    'TN-C': 'LvCableLL',
    'TN-S': 'LvCableLL',
    IT: 'LvCableLL',
  },
  L2L3: {
    TT: 'LvCableLL',
    'TN-C': 'LvCableLL',
    'TN-S': 'LvCableLL',
    IT: 'LvCableLL',
  },
  L1L3: {
    TT: 'LvCableLL',
    'TN-C': 'LvCableLL',
    'TN-S': 'LvCableLL',
    IT: 'LvCableLL',
  },
  L1N: {
    TT: 'LvCableLN',
    'TN-C': 'LvCableLN',
    'TN-S': 'LvCableLN',
    IT: 'LvCableLN',
  },
  L2N: {
    TT: 'LvCableLN',
    'TN-C': 'LvCableLN',
    'TN-S': 'LvCableLN',
    IT: 'LvCableLN',
  },
  L3N: {
    TT: 'LvCableLN',
    'TN-C': 'LvCableLN',
    'TN-S': 'LvCableLN',
    IT: 'LvCableLN',
  },
};

export const layouts = {
  free: {
    frame: 'A3',
    image: 'assets/stamps/A3.png',
  },
  LVU: {
    frame: 'LV-A309',
    image: 'assets/stamps/LV-A309.png',
  },
  MVU: {
    frame: 'MV-07',
    image: 'assets/stamps/MV-07.png',
  },
  LVG: {
    frame: 'LV-A309',
    image: 'assets/stamps/LV-A309.png',
  },
};

const switchboardDeviceTypes: ISwitchboardDeviceTypes = {
  'app.RCCB': [EDeviceType.LV_SWITCHBOARD],
  'app.ATS': [EDeviceType.LV_SWITCHBOARD],
  'app.DISCONNECTORLV': [EDeviceType.LV_SWITCHBOARD],
  'app.BUSBAR': [EDeviceType.LV_SWITCHBOARD, EDeviceType.MV_SWITCHBOARD],
  'app.CB_TM': [EDeviceType.LV_SWITCHBOARD],
  'app.FUSEBASE': [EDeviceType.LV_SWITCHBOARD],
  'app.CB_FEEDER': [EDeviceType.LV_SWITCHBOARD],
  'app.TRAFO_FEEDER': [EDeviceType.LV_SWITCHBOARD],
  'app.MOTOR_FEEDER': [EDeviceType.LV_SWITCHBOARD],
  'app.HEATER_FEEDER': [EDeviceType.LV_SWITCHBOARD],
  'app.VEHICLE_CHARGER_FEEDER': [EDeviceType.LV_SWITCHBOARD],
  'app.CONTACTOR': [EDeviceType.LV_SWITCHBOARD],
  'app.MMS': [EDeviceType.LV_SWITCHBOARD],
  'app.OLR': [EDeviceType.LV_SWITCHBOARD],
  'app.SOFTSTARTER': [EDeviceType.LV_SWITCHBOARD],
  'app.MVEDISC': [EDeviceType.MV_SWITCHBOARD],
  'app.CIRCUITBREAKERMV': [EDeviceType.MV_SWITCHBOARD],
  'app.CIRCUITBREAKERMVW': [EDeviceType.MV_SWITCHBOARD],
  'app.MVDISC': [EDeviceType.MV_SWITCHBOARD],
  'app.MVDISC_DOWN': [EDeviceType.MV_SWITCHBOARD],
  'app.MVSWITCH': [EDeviceType.MV_SWITCHBOARD],
  'app.FUSEMV': [EDeviceType.MV_SWITCHBOARD],
  'app.CONNECTOON': [EDeviceType.MV_SWITCHBOARD],
};

export const supportSwitchboard = (deviceType: ESwitchboardDevice | EDeviceType): Array<SupportedSwitchboard> => {
  return switchboardDeviceTypes[deviceType as ESwitchboardDevice];
};

export const pageSizes = {
  A3: {
    width: 297,
    height: 420,
  },
  A4: {
    width: 210,
    height: 297,
  },
};

export const scenarioObjectTypes = [
  'LvCircuitBreaker',
  'LvCbLsi',
  'LvCbLsiRc',
  'LvCbI',
  'LvCbIRc',
  'LvCbLsiW',
  'LvCbLsiRcW',
  'LvCbIW',
  'LvCbIRcW',
  'LvSwitchFuse',
  'LvDisconnectorFuse',
  'LvSwitchDisconnectorFuse',
  'LvFuseSwitch',
  'LvFuseDisconnector',
  'LvFuseSwitchDisconnector',
  'LvDisconnector',
  'RcCb',
  'LvContactor',
  'LvSwitch',
  'LvSwitchDisconnector',
  'Mms',
  'MmsMo',
  'MmsTm',
  'LvOverLoadRelay',
];

export const NOT_COMPUTED_ELEMENTS = ['app.CB_FEEDER', 'app.MOTOR_FEEDER', 'app.TEXTAREA', 'app.LINE', 'app.CURVE_DIAGRAM'];

export const FEEDER_DEVICES = [
  EDeviceType.VEHICLE_CHARGER_FEEDER,
  EDeviceType.HEATER_FEEDER,
  EDeviceType.MEDICAL_ROOM_FEEDER,
  EDeviceType.MOTOR_FEEDER,
  EDeviceType.CB_FEEDER,
];

export const LOAD_CHARGER_TYPES = ['WallBox', 'DCFastCharger', 'HighPowerFastCharger'];

export const TYPICAL_UNIT_NAMES = [
  'app.BME_IN',
  'app.BME_OUT',
  'app.DRC_IN',
  'app.DRC_OUT',
  'app.DRS_LEFT',
  'app.DRS_RIGHT',
  'app.HBC_IN',
  'app.HBC_OUT',
  'app.HBS_LEFT',
  'app.HBS_RIGHT',
  'app.RLC_IN',
  'app.RLC_OUT',
  'app.RRC_IN',
  'app.RRC_OUT',
  'app.SBC_IN',
  'app.SBC_OUT',
  'app.SBC_W_IN',
  'app.SBC_W_OUT',
  'app.SBM',
  'app.SBR_LEFT',
  'app.SBR_RIGHT',
  'app.SBS_LEFT',
  'app.SBS_RIGHT',
  'app.SBS_W_RIGHT',
  'app.SDC_IN',
  'app.SDC_OUT',
  'app.SDD',
  'app.SDM',
  'app.SDS_LEFT',
  'app.SDS_RIGHT',
  'app.SFC',
  'app.SFS_LEFT',
  'app.SFV',
  'app.TRAFOENCLOS',
  'app.UMP',
  'app.WBC_IN',
  'app.WBC_OUT',
  'app.WBS_LEFT',
  'app.WBS_RIGHT',
  'app.BME_IN',
  'app.DRC_IN',
  'app.HBC_IN',
  'app.SBC_IN',
  'app.RLC_IN',
  'app.RRC_IN',
  'app.SDC_IN',
  'app.SBR_LEFT',
  'app.SBR_RIGHT',
  'app.SDD',
  'app.WBC_IN',
  'app.BME_OUT',
  'app.DRC_OUT',
  'app.HBC_OUT',
  'app.SBC_OUT',
  'app.RLC_OUT',
  'app.RRC_OUT',
  'app.SDC_OUT',
  'app.SFC',
  'app.WBC_OUT',
  'app.DRS_LEFT',
  'app.DRS_RIGHT',
  'app.HBS_LEFT',
  'app.HBS_RIGHT',
  'app.SBM',
  'app.SBS_LEFT',
  'app.SBS_RIGHT',
  'app.SDS_LEFT',
  'app.SDS_RIGHT',
  'app.WBS_LEFT',
  'app.WBS_RIGHT',
  'app.SDM',
  'app.SBM',
  'app.SDM',
  'app.SFV',
  'app.UMP',
  'app.TRAFOENCLOS',

  'app.IF_IN',
  'app.IF_OUT',
  'app.M',
  'app.BT_LEFT',
  'app.BT_RIGHT',
  'app.RM_LEFT',
  'app.RM_RIGHT',

  // generic TUs
  'app.INCOMING_DIR',
  'app.INCOMING_FIXCBD',
  'app.INCOMING_WITHCBD',
  'app.INCOMING_REMCBD',
  'app.INCOMING_D',
  'app.INCOMING_WITHCB',
  'app.OUTGOING_DIR',
  'app.OUTGOING_FIXCBD',
  'app.OUTGOING_WITHCBD',
  'app.OUTGOING_REMCBD',
  'app.OUTGOING_D',
  'app.OUTGOING_FUXD',
  'app.OUTGOING_WITHCB',
  'app.BUSTIE_FIXCBD_RIGHT',
  'app.BUSTIE_WITHCBD_RIGHT',
  'app.BUSTIE_REMCBD_RIGHT',
  'app.BUSTIE_D_RIGHT',
  'app.BUSTIE_WITHCB_RIGHT',
  'app.RISER_RIGHT',
  'app.RISER_LEFT',
  'app.BUSTIE_FUXD_LEFT',
  'app.MEASUREMENT',
];

export const FEEDER_PROTECTION_DEVICE_TYPES = ['app.CB_TM', 'app.MMS', 'app.FUSEBASE'];
export const lineWidthList = [1, 2, 3, 4, 5];

export const polesOptionsTableLLLN = ['3P', '3P+N', '4P'];
export const polesOptionsTableLN = ['1P', '1P+N', '2P'];
export const optionsBreakersICUList = [4.5, 6, 10, 15, 25, 36, 50];
export const genTypes = ['MVT', 'MVDF16', 'MVDS16', 'MVNDF16', 'MVNDS16', 'LVT', 'LV2', 'LV4', 'LV6', 'LV8'];

// There are the generator Properties for database, DOC3 export file and our app
export const DTBGenProps = [
  'Description',
  'Type',
  'Vn',
  'An',
  'StartCenterAvailable',
  'Cosfin',
  'Fn',
  'Ra',
  'XdMin',
  'Xd',
  'X2',
  'Xo',
  'XdSec',
  'XqSec',
  'TdSec',
  'TdMin',
];
export const importGenProps = [
  'Model',
  'Type',
  'Un',
  'Sn',
  'SG',
  'Cosphi n',
  'fr',
  'Ra',
  `xd'`,
  'xd',
  'x2',
  'x0',
  `xd''`,
  `xq''`,
  `Td''`,
  `Td'`,
];
export const appGenProps = [
  'ProductDescription',
  'GensetType',
  'NominalVoltage',
  'NominalPower',
  'StartCenterAvailable',
  'CosPhiNominal',
  'Frequency',
  'Ra',
  'XdFirst',
  'Xd',
  'XSecond',
  'X0',
  'XdSecond',
  'XqSecond',
  'TdSecond',
  'TdFirst',
];
export const genValueTypes = [
  'string',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
];

export const genValueRanges = [
  // model, Type, Un, Sn
  undefined,
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  0,
  0,
  // SG, cosphi n, fr, Ra
  [0, 1],
  [0, 1],
  [50, 60],
  0,
  // XdMin, Xd, X2, Xo
  0,
  0,
  0,
  0,
  // XdSec, XqSec, TdSec, TdMin
  0,
  0,
  0,
  0,
];

export const genValueCheckTypes = [
  'noCheck',
  'exactValue',
  'higherThan',
  'higherThan',
  'exactValue',
  'exactRange',
  'exactValue',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
];

export const DTBTrafoProps = [
  'description',
  'ratedVoltPrimaryWinding',
  'phases',
  'ratedVoltSecondaryWinding',
  'voltSecondaryWindingNoLoad',
  'ratedPower',
  'voltageUnderShortCircuit',
  'lossesDuringShortCircuitAbs',
  'lossesDuringShortCircuitPct',
  'upperRangePlugs',
  'lowerRangePlugs',
  'stepUpperLowerRange',
];

export const importTrafoProps = [
  'Model',
  'Un prim.',
  'Phases',
  'Un sec.',
  'U No-load voltage (sec.)',
  'Sn',
  'Usc %',
  'Losses % W',
  'Losses %',
  'Plug up',
  'Plug down',
  'Step',
];

export const appTrafoProps = [
  'ProductDescription',
  'PrimaryWindingUn',
  'PrimaryWindingLineType',
  'SecondaryWindingUn',
  'TransformerSecondaryWindingNoLoadU',
  'Sr',
  'ukr',
  'lossesABS',
  'uRr',
  'plugUpperRange',
  'plugLowerRange',
  'plugStep',
];

export const trafoValueTypes = [
  'string',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
];

export const trafoValueRanges = [
  // model, UnPrim, Phases, Un sec
  undefined,
  0,
  [1, 3],
  0,
  // U No-load voltage (sec.), Sn, Usc %
  0,
  0,
  0,
  // Losses% W, Losses %, 'Plug up'
  0,
  [0, 100],
  [0, 100],
  // 'Plug down', 'Step'
  [-100, 0],
  0,
];

export const trafoValueCheckTypes = [
  'noCheck',
  'higherThan',
  'exactValue',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'range',
  'lowerExactRange',
  'higherExactRange',
  'higherThan',
];

export const appMotorProps = [
  'ProductDescription',
  'NominalVoltage',
  'frequency',
  'LineType',
  'Pn',
  'Eta',
  'MotorSpeed',
  'IlrInRatio',
  'I0InRatio',
  'CosPhiNominal',
  'PolesNumber',
  'CosPhiStart',
  'IStart',
  'tStart',
  'CstartCnRatio',
  'CmCnRatio',
  'J',
  'Rs',
  'Xs',
  'Rr',
  'Xr',
  '___RM',
  '___XM',
];

export const DTBMotorProps = [
  'description',
  'vn',
  'fn',
  'phases',
  'pn',
  'etan',
  'speed',
  'ilrIn',
  'i0In',
  'cosfin',
  'p',
  'cosfiavv',
  'iavv',
  'tavv',
  'cavvCn',
  'cmCn',
  'j',
  'rs',
  'xs',
  'rr',
  'xr',
  'rmm',
  'xmm',
];

export const importMotorProps = [
  'Model',
  'Un',
  'Fn',
  'Phases',
  'Pn',
  'Efficiency',
  'Speed',
  'Ilr/In',
  'Io/In',
  'Cosphi',
  'Poles',
  'Cosphi start',
  'I start',
  't start',
  'C start/Cn',
  'CM/Cn',
  'J',
  'Rs',
  'Xs',
  'Rr',
  'Xr',
  'Rm',
  'Xm',
];

export const motorValueTypes = [
  'string',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
  'number',
];

export const motorCheckTypes = [
  // model, Un, Fn, Phases, Pn,
  'noCheck',
  'higherThan',
  'exactValue',
  'exactValue',
  'higherThan',
  // Efficeiency, Speed, 'Ilr/In', 'Io/In', 'Cosphi'
  'range',
  'higherThan',
  'higherThan',
  'lowerThan',
  'exactRange',
  // 'Poles', 'Cosphi start', 'I start', 't start', 'C start/cn', 'CM/Cn', 'J',
  'IntegerEqualOrHigher',
  'exactRange',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  'higherThan',
  // 'Rs', 'Xs', 'Rr', 'Xr', 'Rm', 'Xm'
  'exactRange',
  'exactRange',
  'exactRange',
  'exactRange',
  'exactRange',
  'exactRange',
];

export const motorValueRanges = [
  undefined,
  0,
  [50, 60],
  [1, 3],
  0,
  [0, 1],
  0,
  1,
  1,
  [0, 1],
  1,
  [0, 1],
  0,
  0,
  0,
  0,
  0,
  [0, 100],
  [0, 100],
  [0, 100],
  [0, 100],
  [0, 100],
  [0, 100],
];

export const leftInfoFields = [
  {
    name: 'PlantApproval',
    title: 'STRING_APPROVAL',
    type: 'text',
  },
  {
    name: 'PlantDate',
    title: 'STRING_DATE',
    type: 'date',
  },
  {
    name: 'PlantDrawingNumber',
    title: 'STRING_DRAWINGNUMBER',
    type: 'text',
  },
  {
    name: 'PlantFile',
    title: 'STRING_FILENAME',
    type: 'text',
  },
  {
    name: 'PlantMainCode',
    title: 'STRING_MAINCODE',
    type: 'text',
  },
  {
    name: 'PlantNote1',
    title: 'STRING_OBJ1',
    type: 'text',
  },
  {
    name: 'PlantNote2',
    title: 'STRING_OBJ2',
    type: 'text',
  },
  {
    name: 'PlantNote3',
    title: 'STRING_OBJ3',
    type: 'text',
  },
];

export const rightInfoFields = [
  {
    name: 'Customer',
    title: 'STRING_CUSTOMER',
    type: 'text',
  },
  {
    name: 'PlantDesigner',
    title: 'STRING_DESIGNER',
    type: 'text',
  },
  {
    name: 'PlantDrafter',
    title: 'STRING_DRAFTER',
    type: 'text',
  },
  {
    name: 'PlantBoard',
    title: 'STRING_LOCATION',
    type: 'text',
  },
  {
    name: 'PlantProject',
    title: 'STRING_PROJECT',
    type: 'text',
  },
  {
    name: 'PlantRev1',
    title: 'DOCWEB_STRING_DATE_REVISION_1',
    type: 'date',
  },
  {
    name: 'PlantRev2',
    title: 'DOCWEB_STRING_DATE_REVISION_2',
    type: 'date',
  },
  {
    name: 'PlantRev3',
    title: 'DOCWEB_STRING_DATE_REVISION_3',
    type: 'date',
  },
  {
    name: 'PlantSerial',
    title: 'STRING_SERIAL',
    type: 'text',
  },
];

export const layoutList = {
  free: ['Vertical layout', 'Horizontal layout'],
  columns: ['Horizontal layout'],
};

export const pageSizesList = {
  free: {
    'Horizontal layout': ['A0', 'A1', 'A2', 'A3'],
    'Vertical layout': ['A0V', 'A1V', 'A2V', 'A3V'],
  },
  columns: {
    'Horizontal layout': ['LV-A309', 'MV-07'],
  },
};

export const settingsOfPages = {
  A0: {
    pageAlign: 'Horizontal layout',
    layout: 'free',
  },
  A1: {
    pageAlign: 'Horizontal layout',
    layout: 'free',
  },
  A2: {
    pageAlign: 'Horizontal layout',
    layout: 'free',
  },
  A3: {
    pageAlign: 'Horizontal layout',
    layout: 'free',
  },
  A0V: {
    pageAlign: 'Vertical layout',
    layout: 'free',
  },
  A1V: {
    pageAlign: 'Vertical layout',
    layout: 'free',
  },
  A2V: {
    pageAlign: 'Vertical layout',
    layout: 'free',
  },
  A3V: {
    pageAlign: 'Vertical layout',
    layout: 'free',
  },
  'LV-A309': {
    pageAlign: 'Horizontal layout',
    layout: 'columns',
  },
  'MV-07': {
    pageAlign: 'Horizontal layout',
    layout: 'columns',
  },
};

export const frames = {
  A0: {
    num: 0,
  },
  A1: {
    num: 0,
  },
  A2: {
    num: 0,
  },
  A0V: {
    num: 0,
  },
  A1V: {
    num: 0,
  },
  A2V: {
    num: 0,
  },
  A3: {
    num: 0,
  },
  'LV-A309': {
    num: 0,
    xleft: 90,
    step: 35,
  },
  'MV-07': {
    num: 0,
    xleft: 85,
    step: 50,
  },
};

export const saferingTypes = ['C', 'V', 'F', 'DE'];

export const VERSION_FILTER_PRIORITY = ['All possibilities', 'F', 'W', 'P'];

export const TRIGGER_STORE_PROPERTIES = [
  'LineType',
  'Uref',
  'LoadCurrent',
  'ScIEC60909L3Ib',
  'ScIEC60909LPEIbMin',
  'DeviceId',
  'RcbId',
  'fuseLinksOutput',
];
