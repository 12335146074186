import { ECellType, EDeviceObjectType, EDeviceType } from '../enums.d';

export default {
  type: ECellType.CURVE_DIAGRAM,
  symbol: '',
  objectType: EDeviceObjectType.CurveDiagram,
  deviceType: EDeviceType.CURVE_DIAGRAM,
  x: 0,
  y: 0,
  x2: 0,
  y2: 0,
  svgContent: '',
};
