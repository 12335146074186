import { utils, WorkBook, read } from 'xlsx';

import { ThunkAction } from 'types/known-actions';

import { isNumber, isValueString } from 'types/type-guards';

import { updateElementParams } from 'devices/actions';
import { EImportExcelCheckTypes } from 'types/excelImport/enums.d';

export const toJson = (workbook: WorkBook, range = 0, f = false): Record<string, unknown[]> => {
  const result: Record<string, unknown[]> = {};
  workbook.SheetNames.forEach((sheet) => {
    const roa = utils.sheet_to_json(workbook.Sheets[sheet], { range });
    if (roa.length) {
      let sheetName = '';
      if (!f) {
        sheetName = `sheet${Object.keys(result).length}`;
      } else {
        sheetName = `${sheet}`;
      }
      result[sheetName] = roa;
    }
  });
  return result;
};

export const clearImportErrorLog =
  (elementId: string): ThunkAction<void> =>
  (dispatch) => {
    dispatch(updateElementParams(elementId, { importErrorList: [] }, true));
  };

// const errorConsoleLog = (type: string) => {
//   console.log(`excelImport ${type} => checkRange was not provided`);
// };

// placeholder for rangeCheck

export const valueRangeCheck = (
  checkedValue: string | number,
  checkType: string,
  checkRange: string[] | number[] | string | number | undefined
): boolean => {
  if (checkType === EImportExcelCheckTypes.noCheck || !checkType) {
    return true;
  }

  if (checkRange && Array.isArray(checkRange)) {
    switch (checkType) {
      case EImportExcelCheckTypes.exactValue:
        return checkRange.includes(checkedValue as unknown as never);
      case EImportExcelCheckTypes.range:
        // range with not included values
        return checkedValue > checkRange[0] && checkedValue < checkRange[1];
      case EImportExcelCheckTypes.exactRange:
        // range with included values
        return checkedValue >= checkRange[0] && checkedValue <= checkRange[1];
      case EImportExcelCheckTypes.lowerExactRange:
        return checkedValue >= checkRange[0] && checkedValue < checkRange[1];
      case EImportExcelCheckTypes.higherExactRange:
        return checkedValue > checkRange[0] && checkedValue <= checkRange[1];
      default:
        return true;
    }
  }
  if ((checkRange || checkRange === 0) && (typeof checkRange === 'string' || typeof checkRange === 'number')) {
    switch (checkType) {
      case EImportExcelCheckTypes.higherThan:
        return checkedValue > checkRange;
      case EImportExcelCheckTypes.EqualOrHigher:
        return checkedValue >= checkRange;
      case EImportExcelCheckTypes.lowerThan:
        return checkedValue < checkRange;
      case EImportExcelCheckTypes.EqualOrLower:
        return checkedValue <= checkRange;
      case EImportExcelCheckTypes.IntegerEqualOrHigher:
        return checkedValue >= checkRange && Number.isInteger(checkedValue);
      default:
        return true;
    }
  }

  return false;
};

// checkedEntry is an object {descriptio: 1, vn: 2, ...etc}
export const validateImportList = (
  checkedEntry: Record<string, string | number>,
  propsToCheck: string[],
  valueTypes: string[],
  valueCheckTypes: string[],
  valueRanges: (string[] | number[] | number | undefined)[],
  importFeeder?: boolean
): { isValid: boolean; error: string } => {
  const noProperty: string[] = [];
  // probably only white spaces == not property
  // const onlyWhiteSpaces = [];
  const notANumber: string[] = [];
  const invalidValue: string[] = [];
  propsToCheck.forEach((property, index) => {
    if (!checkedEntry[property] && checkedEntry[property] !== 0) {
      noProperty.push(property);
      // add note what property is missing in xml
    } else {
      let isValidValue = true;
      if (valueTypes[index] === 'string') {
        const checkedValue = checkedEntry[property];
        if (isValueString(checkedValue) && checkedValue.trim().length <= 0) {
          noProperty.push(property);
          isValidValue = false;
        }
      } else if (valueTypes[index] === 'number') {
        const checkedValue = checkedEntry[property];
        if (!isNumber(checkedValue)) {
          notANumber.push(property);
          isValidValue = false;
        }
      }
      if (isValidValue) {
        if (!valueRangeCheck(checkedEntry[property], valueCheckTypes[index], valueRanges[index])) {
          invalidValue.push(property);
          // add note value is not in range
        }
      }
    }
  });
  let logString = 'OK';
  if (!importFeeder) {
    if (noProperty.length > 0 || notANumber.length > 0 || invalidValue.length > 0) {
      logString = '';
      if (noProperty.length > 0) {
        logString += 'Missing values for: ';
        // noProperty.forEach((item, index) => {logString = logString + item + (index != noProperty.length - 1 ? ', ' : ' '));
        noProperty.forEach((item, index) => {
          logString = logString + item + (index !== noProperty.length - 1 ? ', ' : ' ');
        });
        logString += '\r\n';
      }
      if (notANumber.length > 0) {
        logString += 'Values in invalid format supplied for: ';
        notANumber.forEach((item, index) => {
          logString = logString + item + (index !== notANumber.length - 1 ? ', ' : ' ');
        });
        logString += '\r\n';
      }
      if (invalidValue.length > 0) {
        logString += 'Supplied values is out of range: ';
        invalidValue.forEach((item, index) => {
          logString = logString + item + (index !== invalidValue.length - 1 ? ', ' : ' ');
        });
        logString += '\r\n';
      }
    }
  }
  let validationResult = true;
  if (logString !== 'OK') {
    validationResult = false;
  }
  return { isValid: validationResult, error: logString };
};

export const fileTypeValidation = (file: File): { isValid: boolean; error: string } => {
  let result;
  if (file.name.toLowerCase().endsWith('.xls') || file.name.toLowerCase().endsWith('.xlsx')) {
    result = { isValid: true, error: 'none' };
    if (file.size > 10485760) {
      result = { isValid: false, error: 'size' };
    }
  } else {
    result = { isValid: false, error: 'type' };
  }
  return result;
};

export const fileReaderImportExcelFunction =
  (
    rABS: boolean,
    file: ProgressEvent<FileReader>,
    elementId: string | undefined,
    afterCheckFunction: (importedEntries: Record<string, unknown[]>, elementId: string) => ThunkAction<void>,
    range?: number
  ): ThunkAction<void> =>
  (dispatch) => {
    if (!elementId) {
      console.log('excelImport: elementId was undefined or not present');
      return undefined;
    }
    let data = file.target?.result;
    if (!rABS && data) {
      data = new Uint8Array(data as ArrayBufferLike);
    }
    let workbook;
    try {
      // if XLSX gets renamed .zip archive - it will crash... anyway if it's crashed probably provided file is not valid
      workbook = read(data, { type: rABS ? 'binary' : 'array' }); // read data from excelFile
    } catch (err) {
      console.warn('sheetJs.XLSX.read crash');
      dispatch(updateElementParams(elementId, { importError: 'File is not a valid excel file or damaged' }, true));
      setTimeout(() => {
        dispatch(updateElementParams(elementId, { importError: '' }, true));
      }, 5000);
      return undefined;
    }
    // check if provided file is a valid workbook (excel file produces far more complex object that most of other files)
    // if XLSX output objject with only 'SheetNames' and 'Sheets' - it's in most cases not valid excel file
    if (!workbook.Workbook) {
      console.warn('imported file have different structure than a valid excel document');
      dispatch(updateElementParams(elementId, { importError: 'File is not a valid excel file or damaged' }, true));
      setTimeout(() => {
        dispatch(updateElementParams(elementId, { importError: '' }, true));
      }, 5000);
      return undefined;
    }
    const importedEntries: Record<string, unknown[]> = toJson(workbook, range); // convert data to JSON
    if (!importedEntries.sheet0) {
      // providing empty excel file will result in file be readen, but sheets will be empty objects.
      dispatch(updateElementParams(elementId, { importError: 'File is empty or damaged' }, true));
      setTimeout(() => {
        dispatch(updateElementParams(elementId, { importError: '' }, true));
      }, 5000);
      return undefined;
    }
    dispatch(afterCheckFunction(importedEntries, elementId));
    return undefined;
  };
