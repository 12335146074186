import Konva from 'konva';
import { DEFAULT_CURVE_LINE_CONFIG, CABLE_BREAKER_LINE_STROKE_WIDTH } from 'curves/Constants';
import { CurveCoord } from 'types/curves';
import GridSizeConfig from 'curves/GridSizeConfig';

export default class Line extends Konva.Line {
  curveShapeName: string;

  deviceId?: string;

  constructor(
    points: CurveCoord[],
    sizeConfig: GridSizeConfig,
    changeWidth: boolean,
    lineColor: string,
    offX: number,
    offY: number,
    deviceId?: string
  ) {
    const pts = sizeConfig.adjustPoints(points, offX, offY);
    const stw = changeWidth ? CABLE_BREAKER_LINE_STROKE_WIDTH : DEFAULT_CURVE_LINE_CONFIG.strokeWidth;
    super({
      ...DEFAULT_CURVE_LINE_CONFIG,
      strokeWidth: stw,
      stroke: lineColor,
      points: pts,
      deviceId
    });
    this.curveShapeName = 'Line';
  }
}
