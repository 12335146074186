import { ActionCreator } from 'types/known-actions';
import { PROJECT } from 'types/constants.d';
import { ApplicationState } from 'types/store';
import { IReport } from 'types/drawingArea/reports';
import { IGetProjectInformationResponse } from 'types/api/partnerHubApi';
import { IMvMacroConversionResponse } from 'types/api/mvMacroConversion';
import { IImportedFeeders } from 'types/modals/feederImport';
import { INeoGearWizardData } from 'types/api/neoGearWizard';
import { IDeleteMessagePayload, IMessage } from './messages';
import { EProjectType } from './enums.d';
/* eslint-disable @typescript-eslint/no-empty-interface */

export enum EPaperList {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A0V = 'A0V',
  A1V = 'A1V',
  A2V = 'A2V',
  LVA309 = 'LV-A309',
  LVA306 = 'LV-A306',
  LVA306PE = 'LV-A306PE',
  MV07 = 'MV-07',
}

export enum EPaperSize {
  A0 = 'A0 - 84.1x118.9 cm',
  A1 = 'A1 - 59.4x84.1 cm',
  A2 = 'A2 - 42.0x59.4 cm',
  A3 = 'A3 - 29.7x42.0 cm',
  A4 = 'A4 - 21.0x29.7 cm',
}
export enum EPaperAlign {
  Horizontal = 'Horizontal layout',
  Vertical = 'Vertical layout',
}

export enum EPaperLayout {
  Columns = 'columns',
  Free = 'free',
}
// TODO: Fill interfaces

export interface PropagateErrors {}

export interface ComputeWarnings {}

export interface PropagateWarnings {}

export interface ProjectErrors {
  [key: string]: string;
}

export interface MacroConversionData extends IMvMacroConversionResponse {}

export interface PartnerHub {
  toolUrl?: string;
  bomId?: string | null;
  hookUrlInsertDevices: string;
  hookUrlUploadDocuments: string;
  projectId: string;
  singleLineDiagramId: string;
  user: string;
  userName: string;
  projectName: string;
  customerName: string;
  singleLineDiagramName: string;
  hookUrlUploadSettings: string;
  projectSettings: string;
}

export interface State {
  [key: string]: unknown;
  id?: string | null;
  name: string;
  Customer: string;
  description: string;
  CurrentStamp: EPaperList;
  appVersion: string;
  autoCompute: boolean;
  selectedDeviceId?: string;
  dirty: boolean;
  ProjectIsComputed: boolean;
  propagateDirty: boolean;
  propagateId: string;
  sessionId: string;
  userCountry: string;
  type: EProjectType;
  computeErrors: Record<string, IMessage>;
  propagateErrors: Record<string, IMessage>;
  computeWarnings: Record<string, IMessage>;
  propagateWarnings: Record<string, IMessage>;
  clientErrors: Array<IMessage>;
  clientWarnings: Array<IMessage>;
  curvesErrors: Array<IMessage>;
  curvesWarnings: Array<IMessage>;
  curvesNotifications: Array<IMessage>;
  projectErrors: Record<string, ProjectErrors>;
  pageOrder: Array<string>;
  openedPages: Array<string>;
  selectedPage: string;
  pageCounter: number;
  printReportFormat: string;
  printing: boolean;
  paperAlign: EPaperAlign;
  paperSize: EPaperSize;
  paperAlignList: Array<EPaperAlign>;
  paperSizeList: Array<EPaperSize>;
  paperSettingSizeList: Array<EPaperSize>;
  multipleSelection: string;
  partnerHub: PartnerHub;
  macroConversionData: MacroConversionData;
  neoGearWizard: INeoGearWizardData;
  SwitchBoardList: string[];
  SwitchBoard: string;
  Designer: string;
  reports: IReport;
  singleLineDiagramId?: string;
  importList?: IImportedFeeders[];
  motorCoordinationData?: IMotorCoordinationData[];
  energyEfficiencyData: IEfficiencyData[];
  diagramsForPrint: Record<string, { checked: boolean; svgContent?: string }>;

  // Plant information
  PlantDate: string;
  PlantDrafter: string;
  PlantNote1: string;
  PlantNote2: string;
  PlantNote3: string;
  PlantFile: string;
  PlantDesigner: string;
  PlantBoard: string;
  PlantProject: string;
  PlantRev1: string;
  PlantRev2: string;
  PlantRev3: string;
  PlantSerial: string;
  PlantApproval: string;
  PlantDrawingNumber: string;
  PlantMainCode: string;

  // energy efficiency info
  totalLossW?: number; // LossW
  sourcePower?: number; // P
  busbarDemandFactor: number; // Import Feeders
}

export interface IEfficiencyData {
  cosphi: number;
  Description: string;
  LossP: number;
  LossW: number;
  Name: string;
  Sign: string;
  Sn: number;
  Switchboard: string;
  deviceId?: string;
  deviceType?: string;
}

export interface IMotorCoordinationData {
  CableSymbol: string;
  Contactor: string;
  CoordinationType: string;
  MotorCoordinationType: string;
  MotorStart: string;
  MotorStarter: string;
  MotorSymbol: string;
  OverDim: number;
  OverloadRelay: string;
  ProtectionDevice: string;
  StarterType: string;
  Symbols: Array<string>;
  bStarContactor: boolean;
}

export type TImportFeedersList = {
  Switchgear: string;
  ['Bus Segment']: number;
  Tag: string;
  Description: string;
  Type: string;
  Poles: string;
  In: number;
  ['Load Cosphi']: number;
  ['Type of Protection']: string;
  Length: number;
  ['Type of Cable']: string;
  Insulator: string;
  Conductor: string;
  ['Installation Method']: number;
  on: boolean;
  id: string;
};
export interface SetProjectInfo {
  [key: string]: string | boolean;
}

interface IDiagramPrint {
  name: string;
  checked: boolean;
  svgContent?: string;
}
export interface Actions {
  setAutoCompute: ActionCreator<PROJECT.SET_AUTO_COMPUTE, { autoCompute: boolean }>;
  setSelectedDevice: ActionCreator<PROJECT.SET_SELECTED_DEVICE, { id: string }>;
  setProjectData: ActionCreator<PROJECT.SET_PROJECT_DATA, { id: string; name: string; sessionId: string }>;
  setProjectName: ActionCreator<PROJECT.SET_PROJECT_NAME, { name: string }>;
  setMacroConversionData: ActionCreator<PROJECT.SET_MACROCONVERSIONDATA, { macroConversionData: MacroConversionData }>;
  setProjectError: ActionCreator<PROJECT.SET_PROJECT_ERROR, { tabName: string; name: string; error: string }>;
  setAppVersion: ActionCreator<PROJECT.SET_APP_VERSION, { version: string }>;
  setProjectDirty: ActionCreator<
    PROJECT.SET_PROJECT_DIRTY,
    { dirty: boolean; ProjectIsComputed?: boolean; propagateId?: string }
  >;
  setProjectInfo: ActionCreator<PROJECT.SET_PROJECT_INFO, { info: Partial<State> }>;
  setProjectPropagateDirty: ActionCreator<PROJECT.SET_PROJECT_PROPAGATE_DIRTY, { propagateDirty: boolean; propagateId: string }>;
  setProjectMessages: ActionCreator<
    PROJECT.SET_PROJECT_MESSAGES,
    { category: string; message: Record<string, IMessage> | Array<IMessage> }
  >;
  loadProject: ActionCreator<PROJECT.LOAD_PROJECT, { projectState: ApplicationState }>;
  selectPage: ActionCreator<PROJECT.SELECT_PAGE, { id: string }>;
  openPage: ActionCreator<PROJECT.OPEN_PAGE, { openedPages: Array<string> }>;
  closePage: ActionCreator<PROJECT.CLOSE_PAGE, { openedPages: Array<string> }>;
  updatePageCounter: ActionCreator<PROJECT.UPDATE_PAGE_COUNTER, { pageCounter: number }>;
  updatePagesOrder: ActionCreator<PROJECT.UPDATE_PAGES_ORDER, { order: Array<string> }>;
  setReportForced: ActionCreator<PROJECT.SET_REPORT_FORCED, { name: string; value: boolean }>;
  setPartnerHubData: ActionCreator<PROJECT.SET_PARTNER_HUB_DATA, { partnerHubData: Partial<IGetProjectInformationResponse> }>;
  updateProjectParam: ActionCreator<PROJECT.UPDATE_PROJECT_PARAM, Partial<State>>;
  triggerMultipleSelection: ActionCreator<PROJECT.TRIGGER_MULTIPLE_SELECTION, { multipleSelection: string }>;
  removeProjectMessages: ActionCreator<PROJECT.REMOVE_PROJECT_MESSAGES, IDeleteMessagePayload>;
  setNeoGearWizard: ActionCreator<PROJECT.SET_NEOGEARWIZARD, INeoGearWizardData>;
  updateDiagramForPrinting: ActionCreator<PROJECT.UPDATE_DIAGRAM_FOR_PRINTING, IDiagramPrint>;
}
