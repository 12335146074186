import { defaultElement } from '../default-element';
import { EDeviceObjectType, EDeviceType } from '../enums.d';

export default {
  ...defaultElement,
  symbol: 'VEHICLE_CHARGER_FEEDER',
  objectType: EDeviceObjectType.FEEDER,
  Locked: false,
  deviceType: EDeviceType.VEHICLE_CHARGER_FEEDER,

  protection: 'TMRCR',
  rcd: 'NO',
  cable: 'CABLE_LLLNPE',
  load: 'LOAD',
  initialized: false,
};
